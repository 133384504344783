import { Address } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address as AddressType } from "viem";

export const sharesWrapperKick: FunctionConfig<{ depositor: AddressType }> = {
  signature: "function kick(address depositor)",

  Description({ inputs }) {
    return (
      <>
        Force Redemption from Shares Wrapper
        <ul>
          <Address address={inputs.depositor} />
        </ul>
      </>
    );
  },

  Label() {
    return <>Force Redemption</>;
  },
};
