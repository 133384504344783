import { useEffect, useRef, useState } from "react";

export function useCountdown(timer: number | undefined) {
  const handleRef = useRef(0);
  const [counter, setCounter] = useState(timer);

  useEffect(() => {
    if (timer === undefined) {
      return;
    }

    handleRef.current = window.setInterval(() => {
      setCounter((current) => (current ?? 1) - 1);

      if ((counter ?? 0) <= 0) {
        clearInterval(handleRef.current);
      }
    }, 1000);

    return () => window.clearInterval(handleRef.current);
  }, [timer]);

  return counter;
}
