export function safeStringifyJSON(data: any, space?: string | number) {
  return JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v), space);
}

function parseBigInt(_: string, value: any) {
  if (typeof value === "string" && /^\d+n$/.test(value)) {
    return BigInt(value.slice(0, -1));
  }
  return value;
}

export function safeParseJSON(data: string) {
  return JSON.parse(data, parseBigInt);
}
