import type { PrimitiveAsset } from "@enzymefinance/environment";
import type { Truthy } from "@enzymefinance/utils";
import { entranceRateBurnFee } from "components/vault/config/fees/EntranceBurnFee";
import { entranceRateDirectFee } from "components/vault/config/fees/EntranceDirectFee";
import { exitRateBurnFee } from "components/vault/config/fees/ExitBurnFee";
import { exitRateDirectFee } from "components/vault/config/fees/ExitDirectFee";
import { managementFee } from "components/vault/config/fees/ManagementFee";
import { performanceFee } from "components/vault/config/fees/PerformanceFee";
import { unknownPolicy } from "components/vault/config/policies/UnknownPolicy";
import type { VaultFeesQuery, VaultPoliciesQuery } from "queries/core";
import type { ReactNode } from "react";

import { sharesActionTimelock } from "./SharesActionTimelock";
import type { VaultConfig, VaultConfigSettings } from "./VaultConfigTypes";
import { VaultConfigType } from "./VaultConfigTypes";
import { minSharesSupplyFee } from "./fees/MinSharesSupplyFee";
import { unknownFee } from "./fees/UnknownFee";
import { allowedAdapterIncomingAssetsPolicy } from "./policies/AllowedAdapterIncomingAssetsPolicy";
import { allowedAdaptersPerManagerPolicy } from "./policies/AllowedAdaptersPerManagerPolicy";
import { allowedAdaptersPolicy } from "./policies/AllowedAdaptersPolicy";
import { allowedAssetsForRedemptionPolicy } from "./policies/AllowedAssetsForRedemptionPolicy";
import { allowedDepositRecipientsPolicy } from "./policies/AllowedDepositRecipientsPolicy";
import { allowedExternalPositionTypesPerManagerPolicy } from "./policies/AllowedExternalPositionTypesPerManagerPolicy";
import { allowedExternalPositionTypesPolicy } from "./policies/AllowedExternalPositionTypesPolicy";
import { allowedRedeemersForSpecificAssetsPolicy } from "./policies/AllowedRedeemersForSpecificAssetsPolicy";
import { allowedSharesTransferRecipientsPolicy } from "./policies/AllowedSharesTransferRecipientsPolicy";
import { cumulativeSlippageTolerancePolicy } from "./policies/CumulativeSlippageTolerancePolicy";
import { disallowedAdapterIncomingAssetsPolicy } from "./policies/DisallowedAdapterIncomingAssetsPolicy";
import { minAssetBalancesPostRedemptionPolicy } from "./policies/MinAssetBalancesPostRedemptionPolicy";
import { minMaxDepositPolicy } from "./policies/MinMaxDepositPolicy";
import { noDepegOnRedeemSharesForSpecificAssetsPolicy } from "./policies/NoDepegOnRedeemSharesForSpecificAssetsPolicy";
import { onlyRemoveDustExternalPositionPolicy } from "./policies/OnlyRemoveDustExternalPositionPolicy";
import { onlyUntrackDustOrPricelessAssetsPolicy } from "./policies/OnlyUntrackDustOrPricelessAssetsPolicy";

export const vaultConfigs: Record<VaultConfigType, VaultConfig<any>> = {
  // Fees
  [VaultConfigType.MANAGEMENT_FEE]: managementFee,
  [VaultConfigType.PERFORMANCE_FEE]: performanceFee,
  [VaultConfigType.ENTRANCE_BURN_FEE]: entranceRateBurnFee,
  [VaultConfigType.ENTRANCE_DIRECT_FEE]: entranceRateDirectFee,
  [VaultConfigType.EXIT_BURN_FEE]: exitRateBurnFee,
  [VaultConfigType.EXIT_DIRECT_FEE]: exitRateDirectFee,
  [VaultConfigType.MIN_SHARES_SUPPLY_FEE]: minSharesSupplyFee,
  [VaultConfigType.UNKNOWN_FEE]: unknownFee,
  // Policies
  [VaultConfigType.ALLOWED_DEPOSIT_RECIPIENTS_POLICY]: allowedDepositRecipientsPolicy,
  [VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY]: allowedAdaptersPerManagerPolicy,
  [VaultConfigType.MIN_MAX_DEPOSIT_POLICY]: minMaxDepositPolicy,
  [VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY]: allowedSharesTransferRecipientsPolicy,
  [VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY]: allowedAssetsForRedemptionPolicy,
  [VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY]: allowedRedeemersForSpecificAssetsPolicy,
  [VaultConfigType.SHARES_ACTION_TIMELOCK]: sharesActionTimelock,
  [VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY]: minAssetBalancesPostRedemptionPolicy,
  [VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY]: noDepegOnRedeemSharesForSpecificAssetsPolicy,
  [VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]: allowedAdapterIncomingAssetsPolicy,
  [VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]: disallowedAdapterIncomingAssetsPolicy,
  [VaultConfigType.ALLOWED_ADAPTERS_POLICY]: allowedAdaptersPolicy,
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY]: allowedExternalPositionTypesPerManagerPolicy,
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY]: allowedExternalPositionTypesPolicy,
  [VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY]: cumulativeSlippageTolerancePolicy,
  [VaultConfigType.ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY]: onlyRemoveDustExternalPositionPolicy,
  [VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY]: onlyUntrackDustOrPricelessAssetsPolicy,
  [VaultConfigType.UNKNOWN_POLICY]: unknownPolicy,
};

interface VaultConfigDisplayProps<TVaultConfigType extends VaultConfigType> {
  children?: ReactNode;
  denominationAsset?: PrimitiveAsset;
  settings: VaultConfigSettings[TVaultConfigType];
  vaultConfigType: TVaultConfigType;
}

export function VaultConfigDisplay<TVaultConfigType extends VaultConfigType>({
  vaultConfigType,
  ...props
}: VaultConfigDisplayProps<TVaultConfigType>) {
  const { display: Display } = vaultConfigs[vaultConfigType];

  return <Display {...props} />;
}

export const queryTypeToVaultConfig = {
  EntranceRateBurnFee: vaultConfigs[VaultConfigType.ENTRANCE_BURN_FEE],
  EntranceRateDirectFee: vaultConfigs[VaultConfigType.ENTRANCE_DIRECT_FEE],
  ExitRateBurnFee: vaultConfigs[VaultConfigType.EXIT_BURN_FEE],
  ExitRateDirectFee: vaultConfigs[VaultConfigType.EXIT_DIRECT_FEE],
  ManagementFee: vaultConfigs[VaultConfigType.MANAGEMENT_FEE],
  PerformanceFee: vaultConfigs[VaultConfigType.PERFORMANCE_FEE],
  UnknownFee: vaultConfigs[VaultConfigType.UNKNOWN_FEE],
  AllowedAdapterIncomingAssetsPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  DisallowedAdapterIncomingAssetsPolicy: vaultConfigs[VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  AllowedAdaptersPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTERS_POLICY],
  AllowedAdaptersPerManagerPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY],
  AllowedExternalPositionTypesPerManagerPolicy:
    vaultConfigs[VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY],
  AllowedExternalPositionTypesPolicy: vaultConfigs[VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY],
  CumulativeSlippageTolerancePolicy: vaultConfigs[VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY],
  OnlyRemoveDustExternalPositionPolicy: vaultConfigs[VaultConfigType.ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY],
  OnlyUntrackDustOrPricelessAssetsPolicy: vaultConfigs[VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY],
  AllowedAssetsForRedemptionPolicy: vaultConfigs[VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY],
  MinAssetBalancesPostRedemptionPolicy: vaultConfigs[VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY],
  NoDepegOnRedeemSharesForSpecificAssetsPolicy:
    vaultConfigs[VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY],
  AllowedDepositRecipientsPolicy: vaultConfigs[VaultConfigType.ALLOWED_DEPOSIT_RECIPIENTS_POLICY],
  AllowedRedeemersForSpecificAssetsPolicy: vaultConfigs[VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY],
  AllowedSharesTransferRecipientsPolicy: vaultConfigs[VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY],
  MinMaxDepositPolicy: vaultConfigs[VaultConfigType.MIN_MAX_DEPOSIT_POLICY],
  UnknownPolicy: vaultConfigs[VaultConfigType.UNKNOWN_POLICY],
} as const;

export enum VaultConfigFieldName {
  ENTRANCE_BURN_FEE = "entranceBurnFee",
  ENTRANCE_DIRECT_FEE = "entranceDirectFee",
  EXIT_BURN_FEE = "exitBurnFee",
  EXIT_DIRECT_FEE = "exitDirectFee",
  MANAGEMENT_FEE = "managementFee",
  PERFORMANCE_FEE = "performanceFee",
  MIN_SHARES_SUPPLY_FEE = "minSharesSupplyFee",
  UNKNOWN_FEE = "unknownFee",
  ALLOWED_ADAPTERS_PER_MANAGER_POLICY = "allowedAdaptersPerManagerPolicy",
  ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY = "allowedAdapterIncomingAssetsPolicy",
  ALLOWED_ADAPTERS_POLICY = "allowedAdaptersPolicy",
  ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY = "allowedExternalPositionTypesPerManagerPolicy",
  ALLOWED_EXTERNAL_POSITION_TYPES_POLICY = "allowedExternalPositionTypesPolicy",
  ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY = "allowedRedeemersForSpecificAssetsPolicy",
  CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY = "cumulativeSlippageTolerancePolicy",
  DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY = "disallowedAdapterIncomingAssetsPolicy",
  SHARES_ACTION_TIMELOCK = "sharesActionTimelock",
  ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY = "onlyRemoveDustExternalPositionPolicy",
  ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY = "onlyUntrackDustOrPricelessAssetsPolicy",
  ALLOWED_ASSETS_FOR_REDEMPTION_POLICY = "allowedAssetsForRedemptionPolicy",
  MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY = "minAssetBalancesPostRedemptionPolicy",
  NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY = "noDepegOnRedeemSharesForSpecificAssetsPolicy",
  ALLOWED_DEPOSIT_RECIPIENTS_POLICY = "allowedDepositRecipientsPolicy",
  ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY = "allowedSharesTransferRecipientsPolicy",
  MIN_MAX_DEPOSIT_POLICY = "minMaxDepositPolicy",
  UNKNOWN_POLICY = "unknownPolicy",
}

const fieldNameToVaultConfigMapping: Record<VaultConfigFieldName, VaultConfig<any>> = {
  entranceBurnFee: vaultConfigs[VaultConfigType.ENTRANCE_BURN_FEE],
  entranceDirectFee: vaultConfigs[VaultConfigType.ENTRANCE_DIRECT_FEE],
  exitBurnFee: vaultConfigs[VaultConfigType.EXIT_BURN_FEE],
  exitDirectFee: vaultConfigs[VaultConfigType.EXIT_DIRECT_FEE],
  managementFee: vaultConfigs[VaultConfigType.MANAGEMENT_FEE],
  performanceFee: vaultConfigs[VaultConfigType.PERFORMANCE_FEE],
  minSharesSupplyFee: vaultConfigs[VaultConfigType.MIN_SHARES_SUPPLY_FEE],
  unknownFee: vaultConfigs[VaultConfigType.UNKNOWN_FEE],
  allowedAdapterIncomingAssetsPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  allowedAdaptersPerManagerPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY],
  allowedAdaptersPolicy: vaultConfigs[VaultConfigType.ALLOWED_ADAPTERS_POLICY],
  allowedExternalPositionTypesPerManagerPolicy:
    vaultConfigs[VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY],
  allowedExternalPositionTypesPolicy: vaultConfigs[VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY],
  allowedRedeemersForSpecificAssetsPolicy: vaultConfigs[VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY],
  cumulativeSlippageTolerancePolicy: vaultConfigs[VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY],
  disallowedAdapterIncomingAssetsPolicy: vaultConfigs[VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  sharesActionTimelock: vaultConfigs[VaultConfigType.SHARES_ACTION_TIMELOCK],
  onlyRemoveDustExternalPositionPolicy: vaultConfigs[VaultConfigType.ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY],
  onlyUntrackDustOrPricelessAssetsPolicy: vaultConfigs[VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY],
  allowedAssetsForRedemptionPolicy: vaultConfigs[VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY],
  minAssetBalancesPostRedemptionPolicy: vaultConfigs[VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY],
  noDepegOnRedeemSharesForSpecificAssetsPolicy:
    vaultConfigs[VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY],
  allowedDepositRecipientsPolicy: vaultConfigs[VaultConfigType.ALLOWED_DEPOSIT_RECIPIENTS_POLICY],
  allowedSharesTransferRecipientsPolicy: vaultConfigs[VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY],
  minMaxDepositPolicy: vaultConfigs[VaultConfigType.MIN_MAX_DEPOSIT_POLICY],
  unknownPolicy: vaultConfigs[VaultConfigType.UNKNOWN_POLICY],
};

export function fieldNameToVaultConfig(fieldName: VaultConfigFieldName): VaultConfig<any> | undefined {
  if (fieldName in fieldNameToVaultConfigMapping) {
    return fieldNameToVaultConfigMapping[fieldName];
  }

  return undefined;
}

type VaultFeesQueryTypes = Truthy<VaultFeesQuery["comptroller"]>["fees"][number];
export type VaultFeeQueryType<TName extends VaultFeesQueryTypes["__typename"]> = Extract<
  VaultFeesQueryTypes,
  { __typename: TName }
>;

export function isVaultFeeQueryType<TName extends VaultFeesQueryTypes["__typename"]>(typeName: TName) {
  return (feeData: VaultFeesQueryTypes): feeData is VaultFeeQueryType<TName> => feeData.__typename === typeName;
}

type VaultPoliciesQueryTypes = Truthy<VaultPoliciesQuery["comptroller"]>["policies"][number];
export type VaultPolicyQueryType<TName extends VaultPoliciesQueryTypes["__typename"]> = Extract<
  VaultPoliciesQueryTypes,
  { __typename: TName }
>;

export function isVaultPolicyQueryType<TName extends VaultPoliciesQueryTypes["__typename"]>(typeName: TName) {
  return (policyData: VaultPoliciesQueryTypes): policyData is VaultPolicyQueryType<TName> =>
    policyData.__typename === typeName;
}
