import { Menu as MenuBase } from "@headlessui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type {
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  HTMLAttributes,
  HtmlHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from "react";
import { createContext, createElement, useContext } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { Icon, defaultIconSize } from "../elements/Icon.js";
import { Portal } from "../layout/Portal.js";
import type { PopperProps } from "../overlays/popper/Popper.js";
import { Popper } from "../overlays/popper/Popper.js";
import type { PopperConfig } from "../overlays/popper/PopperProvider.js";
import { PopperProvider, usePopper } from "../overlays/popper/PopperProvider.js";

interface MenuContextValues {
  isOpen: boolean;
  size?: "xl";
}

const MenuContext = createContext<MenuContextValues | undefined>(undefined);

export function useMenu() {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error("Missing menu context");
  }

  return context;
}

interface MenuProviderProps {
  children?: ReactNode;
  value: MenuContextValues;
}

function MenuProvider(props: MenuProviderProps) {
  return <MenuContext.Provider {...props} />;
}

export type MenuProps<TProps = {}> = PopperConfig &
  TProps & {
    as?: ElementType;
    children: ReactNode;
    size?: "xl";
  };

export function Menu<TProps = {}>({
  children,
  distance,
  modifiers,
  placement = "bottom-start",
  size,
  skidding,
  ...props
}: MenuProps<TProps>) {
  return (
    <MenuBase {...props}>
      {({ open: isOpen }) => (
        <MenuProvider value={{ isOpen, size }}>
          <PopperProvider distance={distance} modifiers={modifiers} placement={placement} skidding={skidding}>
            {children}
          </PopperProvider>
        </MenuProvider>
      )}
    </MenuBase>
  );
}

type MenuButtonProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">> = Omit<
  TProps,
  "as" | "children"
> &
  PropsWithChildren<{ as?: ElementType }>;

function menuButton<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">>(
  props: MenuButtonProps<TProps>,
) {
  const { reference } = usePopper();
  const { size } = useMenu();

  return <MenuBase.Button size={size} {...props} {...reference} />;
}

Menu.Button = menuButton;

Menu.BaseButton = function MenuBaseButton<
  TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">,
>(props: MenuButtonProps<TProps>) {
  const { size } = useMenu();

  return <MenuBase.Button size={size} {...props} />;
};

export type MenuItemsProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"nav">> = TProps & {
  arrow?: PopperProps["arrow"];
  as?: ElementType;
};

export function MenuItems<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"nav">>({
  arrow,
  as = "nav",
  className,
  ...props
}: MenuItemsProps<TProps>) {
  const motionPresets = useMotionPresets();
  const { isOpen } = useMenu();

  const classes = classNames(
    "rounded-lg shadow-lg bg-base-200 border border-base-300 divide-y divide-base-400 focus:outline-none overflow-hidden",
    className,
  );

  return (
    <>
      {/* Overlay */}
      <AnimatePresence>
        {isOpen ? (
          <Portal>
            <motion.div {...motionPresets.opacity} className="fixed inset-0" aria-hidden={true} />
          </Portal>
        ) : null}
      </AnimatePresence>

      {/* Menu Items */}
      <Popper arrow={arrow} className="z-30" isOpen={isOpen}>
        <MenuBase.Items as={as} className={classes} static={true} {...props} />
      </Popper>
    </>
  );
}

Menu.Items = MenuItems;

export type MenuGroupProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = TProps & {
  as?: ComponentType<TProps> | "div";
};

export function MenuGroup({ as = "div", children, className, ...props }: MenuGroupProps) {
  const classes = classNames("py-2", className);

  return createElement<any>(as, { className: classes, role: "none", ...props }, children);
}

Menu.Group = MenuGroup;

export type MenuItemProps<TProps extends HtmlHTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"a">> = TProps & {
  as?: ComponentType<TProps> | "a" | "button";
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
};

export function MenuItem<TProps extends {} = {}>({
  as = "a",
  children,
  className,
  disabled = false,
  icon,
  ...props
}: MenuItemProps<TProps>) {
  const { size } = useMenu();

  return (
    <MenuBase.Item>
      {({ active }) =>
        createElement<any>(
          as,
          {
            ...props,
            className: classNames(
              "flex space-x-3 transition focus:outline-none w-full",
              {
                "px-4 py-2 text-sm": size !== "xl",
                "px-6 py-3": size === "xl",
                "text-base-neutral": disabled,
                "text-gray-700 dark:text-gray-200": !(disabled || active),
                "text-heading-content bg-black bg-opacity-4 dark:bg-white dark:bg-opacity-4": !disabled && active,
              },
              className,
            ),
            disabled,
          },
          <>
            {icon ? (
              <Icon
                aria-hidden={true}
                className={classNames("flex-none transition", {
                  "text-base-neutral": !active,
                  "text-base-content": active,
                })}
                icon={icon}
                size={size === "xl" ? 6 : defaultIconSize}
              />
            ) : null}
            <span className="flex-1 text-left">{children}</span>
          </>,
        )
      }
    </MenuBase.Item>
  );
}

Menu.Item = MenuItem;
