import { Address as AddressComp } from "@enzymefinance/ethereum-ui";
import { ProtocolSelectPerAddress } from "@enzymefinance/hook-form";
import { Badge } from "@enzymefinance/ui";
import { address, externalPosition } from "@enzymefinance/validation";
import classNames from "classnames";
import { useExternalPositionOptions } from "utils/hooks/useExternalPositionOptions";
import { z } from "zod";

import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { VaultConfigFieldName } from "../VaultConfig";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { PolicyExternalPositionList } from "./PolicyExternalPositionList";

export const allowedExternalPositionTypesPerManagerPolicySchema = z
  .array(
    z.object({
      address: z.object({ value: address({ caseInsensitive: true }) }),
      protocols: z.array(externalPosition()).nonempty(),
    }),
  )
  .superRefine((values, context) => {
    const addresses = values.map((value) => value.address.value.toLowerCase());

    addresses.forEach((element, _, array) => {
      const firstIndex = array.indexOf(element);
      const lastIndex = array.lastIndexOf(element);

      if (firstIndex !== lastIndex) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Manager addresses should be unique.",
          path: [`[${lastIndex}].address]`],
        });
      }
    });
  })
  .optional();

function allowedExternalPositionTypesPerManagerPolicyFormFields() {
  const defaultOptions = useExternalPositionOptions();

  return (
    <ProtocolSelectPerAddress
      label={allowedExternalPositionTypesPerManagerPolicy.label}
      name={VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY}
      options={defaultOptions}
      isMulti={true}
      isExpandable={true}
    />
  );
}

function allowedExternalPositionTypesPerManagerPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY>) {
  return (
    <div className="space-y-4 divide-y divide-gray-300 dark:divide-gray-700">
      {settings.map((setting, index) => {
        return (
          <div key={setting.address.value} className={classNames("space-y-2", { "pt-4": index !== 0 })}>
            <AddressComp appearance="card" address={setting.address.value} icon={true} iconSize={8} />
            <PolicyExternalPositionList
              ids={setting.protocols.map((protocol) => protocol.externalPositionIdentifier.toString())}
            />
          </div>
        );
      })}
    </div>
  );
}

function allowedExternalPositionTypesPerManagerPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY>) {
  return (
    <div className="space-y-4 divide-y divide-gray-300 dark:divide-gray-700">
      {settings.userUintLists.map((userUintList, index) => {
        return (
          <div key={userUintList.id} className={classNames("space-y-2", { "pt-4": index !== 0 })}>
            <AddressComp appearance="card" address={userUintList.userAddress} icon={true} iconSize={8} />
            <PolicyExternalPositionList ids={userUintList.uintLists.flatMap((uintList) => uintList.items)} />
          </div>
        );
      })}
    </div>
  );
}

export const allowedExternalPositionTypesPerManagerPolicy: VaultConfig<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY> =
  {
    address: (contracts) => contracts.AllowedExternalPositionTypesPerManagerPolicy,
    disableable: true,
    display: allowedExternalPositionTypesPerManagerPolicyDisplay,
    displaySubgraph: allowedExternalPositionTypesPerManagerPolicyDisplaySubgraph,
    editable: true,
    encode: (settings) => {
      const args = Policies.AllowedExternalPositionTypesPerManager.encodeSettings({
        users: settings.map((setting) => setting.address.value),
        listsData: settings.map((setting) => {
          return {
            existingListIds: [],
            newListsArgs: [
              {
                initialItems: setting.protocols.map((protocol) => BigInt(protocol.externalPositionIdentifier)),
                updateType: Constants.UintListUpdateType.AddAndRemove,
              },
            ],
          };
        }),
      });

      return args;
    },
    fetch: async () => undefined,
    formInitialValues: [],
    formFields: allowedExternalPositionTypesPerManagerPolicyFormFields,
    label: "Limit Allowed External Position Types For Manager To A Specified List",
    managerDescription: (
      <div className="space-y-4">
        <p>
          Restricts the protocols with which a manager can interact to trade, lend, borrow, supply liquidity, et cetera.
        </p>
        <p>
          Enable this policy and select which external position types each manager will be allowed to interact with.
        </p>
        <Badge appearance="success">Editable Setting</Badge>
      </div>
    ),
    type: VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY,
    validationSchema: allowedExternalPositionTypesPerManagerPolicySchema,
  };
