import { Policy } from "@enzymefinance/sdk/Configuration";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const usePricelessAssetBypassTimelock = createUseValidatedClientQuery(
  ["policy-priceless-asset-bypass-timelock"],
  {
    paramsSchema: z.object({
      policy: address({ allowZeroAddress: false }),
    }),
    responseSchema: bigint(),
    queryFn: (params, client) => Policy.getPricelessAssetBypassTimelock(client, { policy: params.policy }),
  },
);
