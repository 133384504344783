import type { ImageProps } from "@enzymefinance/ui";
import { Image, Skeleton } from "@enzymefinance/ui";
import type { FixedSize } from "@enzymefinance/utils";
import { sizeAttributes, sizeClasses } from "@enzymefinance/utils";
import classNames from "classnames";
import type { ReactElement } from "react";
import { isValidElement } from "react";

export interface BaseIconProps extends Omit<ImageProps, "height" | "loading" | "src" | "width"> {
  className?: string;
  loading?: ReactElement | boolean | string;
  size?: FixedSize;
  url?: string;
}

export function BaseIcon({ alt, className, loading = false, size = 9, title, url, ...props }: BaseIconProps) {
  const classes = classNames("flex-none", sizeClasses[size], className);
  const skeletonWrapperClasses = classNames("inline-flex items-center justify-center", classes);

  if (isValidElement(loading) || loading === true) {
    return (
      <span aria-hidden={true} className={skeletonWrapperClasses} title={title}>
        {loading === true ? <Skeleton className="h-full w-full" shape="circle" /> : loading}
      </span>
    );
  }

  return (
    <Image
      alt={alt ?? title ?? "Unknown Token"}
      src={url}
      title={title}
      {...sizeAttributes[size]}
      className={classes}
      {...props}
    />
  );
}
