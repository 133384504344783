import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { NumberDisplay, SectionHeading, Tooltip } from "@enzymefinance/ui";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";

interface TransactionGasOptionsProps {
  error?: boolean;
  expectedGasCost?: bigint;
  loading?: boolean;
}

export function TransactionGasOptions({ error = false, expectedGasCost, loading = false }: TransactionGasOptionsProps) {
  const { environment } = useGlobals();
  const currency = useCurrency();
  const { assetPrices } = useAssetPrices();

  const { nativeTokenWrapper } = environment.namedTokens;

  const expectedGasCurrencyCost = useMemo(
    () => findTokenValue({ assetPrices, token: nativeTokenWrapper, value: expectedGasCost }),
    [assetPrices, nativeTokenWrapper, expectedGasCost],
  );

  return (
    <div className="space-y-1">
      <Tooltip label={<SectionHeading.Subtitle>Expected Transaction Fee</SectionHeading.Subtitle>}>
        This is the estimated fee that you pay to submit the transaction to the network.
      </Tooltip>
      <ul className="flex flex-col text-lg font-bold">
        <li>
          <BigIntDisplay
            error={error}
            fallback=""
            loading={loading}
            numberFormat={{
              currency: environment.network.currency.nativeToken.symbol,
              maximumSignificantDigits: 1,
              minimumFractionDigits: 4,
            }}
            value={expectedGasCost}
          />
        </li>
        <li className="text-base-content">
          <NumberDisplay
            error={error}
            appearance="simple"
            loading={loading}
            numberFormat={{ currency }}
            value={expectedGasCurrencyCost}
          />
        </li>
      </ul>
    </div>
  );
}
