import type { Address } from "@enzymefinance/environment";
import type { AddressCreatableProps as AddressCreatablePropsBase } from "@enzymefinance/ethereum-ui";
import { AddressCreatable as AddressCreatableBase } from "@enzymefinance/ethereum-ui";
import { isTruthy } from "@enzymefinance/utils";
import { useCallback } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface AddressCreatableProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<AddressCreatablePropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
  afterOnChange?: () => void;
}

export function AddressCreatable<TFieldValues extends FieldValues = FieldValues>({
  isDisabled,
  onChange: onChangeBase,
  afterOnChange,
  ...props
}: AddressCreatableProps<TFieldValues>) {
  const {
    field: { onChange, ref: _, value, ...field },
    fieldState,
    formState,
  } = useController({ name: props.name });

  const handleChange = useCallback<NonNullable<AddressCreatableProps["onChange"]>>(
    (newValue, actionMeta) => {
      // Required because when address is cleared, it gets replaced by null instead of undefined
      onChangeBase?.(newValue, actionMeta);

      // TODO: We should use proper types here.
      if (newValue === null) {
        onChange(newValue as any);
      } else {
        onChange("value" in newValue ? newValue.value : (newValue.map((item) => item.value) as any));
      }

      afterOnChange?.();
    },
    [onChange, onChangeBase],
  );

  return (
    <AddressCreatableBase
      error={fieldState.error?.message}
      isDisabled={isDisabled || formState.isSubmitting}
      onChange={handleChange}
      value={
        Array.isArray(value)
          ? value
              .map((address: Address | undefined) =>
                address === undefined ? undefined : { label: address, value: address },
              )
              .filter(isTruthy)
          : value === undefined || value === "" || value === null
            ? undefined
            : { label: value, value }
      }
      {...props}
      id={props.name}
      {...field}
    />
  );
}
