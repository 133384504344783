import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const sharesWrapperDepositAllFromQueue: FunctionConfig<{ depositAsset: Address }> = {
  signature: "function depositAllFromQueue(address depositAsset)",

  Description() {
    return <>Execute deposit requests</>;
  },
  Label() {
    return <>Execute deposit on Shares Wrapper</>;
  },
};
