import { toAddress } from "@enzymefinance/environment";
import { Badge, Tooltip } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import type { VaultDetailsFragment } from "queries/core";
import { useReconfigurationDetails } from "utils/hooks/useReconfigurationDetails";

import { InlineLink } from "../../routing/Link";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface VaultReconfigurationPendingProps {
  id: string;
  isOwner: boolean;
  vault: VaultDetailsFragment;
}

export function VaultReconfigurationPending({ id, isOwner, vault }: VaultReconfigurationPendingProps) {
  const { client } = useNetwork();
  const { contracts } = useGlobals();

  const { data: reconfigurationRequestDetails } = useReconfigurationDetails(client, {
    fundDeployer: contracts.FundDeployer,
    release: toAddress(vault.release.id),
    vaultProxy: toAddress(id),
  });

  const executableTimestamp = reconfigurationRequestDetails?.executableTimestamp;

  const timelockRemaining =
    typeof executableTimestamp === "bigint" ? Number(executableTimestamp) - new Date().getTime() / 1000 : undefined;
  const reconfigurationExecutable = timelockRemaining !== undefined && timelockRemaining < 0;

  const durationRemaining = timelockRemaining ? dayjs.duration(timelockRemaining, "seconds").humanize() : "N/A";

  const depositorReconfigurationPendingMessage = (
    <>
      This vault has a reconfiguration pending. Vault reconfigurations require no action from depositors. If you wish to
      opt out of this reconfiguration, you may redeem your shares. You can{" "}
      <InlineLink appearance="tertiary" to={`/vault/${id}/compare`}>
        <b>review the reconfiguration here</b>
      </InlineLink>
      .
    </>
  );
  const managerReconfigurationPendingMessage = reconfigurationExecutable ? (
    <>
      This vault has a reconfiguration pending. The reconfiguration is ready to be executed. Go to Vault {">"} Settings
      to execute the reconfiguration.
    </>
  ) : (
    <>
      This vault has a reconfiguration pending. You will be able to execute the reconfiguration in {durationRemaining}
    </>
  );

  return (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge>Reconfiguration Pending</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <span className="block max-w-xs text-sm">
          {isOwner ? managerReconfigurationPendingMessage : depositorReconfigurationPendingMessage}
        </span>
      </Tooltip.Panel>
    </Tooltip.Provider>
  );
}
