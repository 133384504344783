import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

// TODO: Retrieve name of policy from address
export const disablePolicyForFund: FunctionConfig<{ comptrollerProxy: Address; policy: Address }> = {
  signature: "function disablePolicyForFund(address comptrollerProxy, address policy)",

  Description({ inputs }) {
    const policyAddress = inputs.policy;

    return <>Disable policy at address {policyAddress}.</>;
  },

  Label() {
    return <>Disable Policy</>;
  },
};
