import { RadioGroup as RadioGroupBase } from "@headlessui/react";
import classNames from "classnames";
import type { ReactElement, ReactNode } from "react";

import { ErrorMessage } from "../feedback/ErrorMessage.js";

export interface RadioGroupOption {
  label: string;
  value: string | number;
  description?: ReactNode | string;
}

export interface RadioGroupProps<TOption extends RadioGroupOption = RadioGroupOption> {
  children?: ReactNode;
  className?: string;
  optionContainerClassName?: string;
  error?: string[] | boolean | string;
  id: string;
  label: string | null;
  labelHidden?: boolean;
  options: readonly TOption[];
  optionRenderer: (props: TOption & { active: boolean; checked: boolean; disabled: boolean }) => ReactElement;
  onChange: (value: TOption) => void;
  value?: TOption;
}

export function RadioGroup<TOption extends RadioGroupOption = RadioGroupOption>({
  children,
  className,
  optionContainerClassName,

  error,
  id,
  label,
  labelHidden,
  optionRenderer,
  options,
  ...props
}: RadioGroupProps<TOption>) {
  const classes = classNames("flex-1 space-y-2", className);
  const wrapperClasses = classNames({ "space-y-1": label && !labelHidden });
  const labelClasses = classNames("block font-medium text-sm text-base-content", {
    "sr-only": labelHidden,
  });
  const optionContainerClasses = classNames("-m-1", optionContainerClassName);
  const errorId = `${id}-error`;

  return (
    <RadioGroupBase {...props} className={classes} value={props.value ?? options[0]}>
      <div className={wrapperClasses}>
        <RadioGroupBase.Label className={labelClasses}>{label}</RadioGroupBase.Label>
        <div>
          <div className={optionContainerClasses}>
            {children ??
              options.map((option, index) => (
                <div className="p-1" key={index}>
                  <RadioGroupBase.Option
                    className={({ active }) =>
                      classNames(
                        "bg-base-300/60 border-base-400 hover:border-primary-focus relative cursor-pointer rounded-lg border px-6 py-4 shadow-sm transition focus:outline-none",
                        {
                          "ring-primary ring-offset-base-100 ring-1 ring-offset-2": active,
                        },
                      )
                    }
                    value={option}
                  >
                    {(optionProps) => (
                      <>
                        {optionRenderer({ ...option, ...optionProps })}
                        <div
                          className={classNames("pointer-events-none absolute -inset-px rounded-lg border-2", {
                            "border-transparent": !optionProps.checked,
                            "border-primary": optionProps.checked,
                          })}
                          aria-hidden={true}
                        />
                      </>
                    )}
                  </RadioGroupBase.Option>
                </div>
              ))}
          </div>
        </div>
      </div>
      {typeof error === "string" ||
      (Array.isArray(error) && error.every((item): item is string => typeof item === "string")) ? (
        <ErrorMessage id={errorId} appearance="simple">
          {error}
        </ErrorMessage>
      ) : null}
    </RadioGroupBase>
  );
}

RadioGroup.Description = RadioGroupBase.Description;
RadioGroup.Label = RadioGroupBase.Label;
