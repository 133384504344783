import { InformationCircleIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";
import { forwardRef, useCallback, useState } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { ProgressRing } from "../feedback/ProgressRing.js";
import { Navigation } from "../navigation/Navigation.js";
import { Tooltip, TooltipProvider } from "../overlays/Tooltip.js";
import { Markdown } from "../typography/Markdown.js";
import { FieldGroup, useFieldGroup } from "./FieldGroup.js";

export interface BaseTextareaProps extends ComponentPropsWithoutRef<"textarea"> {
  resize?: boolean;
}

export const BaseTextarea = forwardRef<HTMLTextAreaElement, BaseTextareaProps>(function BaseTextarea(
  { className, disabled, maxLength, onChange, resize = true, ...props }: BaseTextareaProps,
  ref,
) {
  const [count, setCount] = useState(props.value?.toString().length ?? props.defaultValue?.toString().length ?? 0);
  const { ariaProps, isError } = useFieldGroup();
  const motionPresets = useMotionPresets();

  const classes = classNames(
    "bg-base-300 text-heading-content border-gray-200/50 dark:border-gray-850 block w-full sm:text-sm transition rounded-lg min-h-[38px] max-h-[320px]",
    {
      "border-gray-200/50 dark:border-gray-850 focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:border-primary":
        !isError,
      "border-error dark:border-error focus:border-error dark:focus:border-error focus:ring-error dark:focus:ring-error text-error dark:text-error placeholder-error":
        isError,
      "opacity-50": disabled,
      "resize-none": !resize,
    },
    className,
  );
  const handleChange = useCallback<NonNullable<TextareaProps["onChange"]>>(
    (event) => {
      onChange?.(event);
      setCount(event.target.value.length);
    },
    [onChange],
  );

  const element = (
    <textarea
      className={classes}
      disabled={disabled}
      {...ariaProps}
      maxLength={maxLength}
      onChange={handleChange}
      ref={ref}
      {...props}
    />
  );

  if (maxLength !== undefined && !disabled && !props.readOnly) {
    const isThreshold = maxLength - 1 < count + 20;
    const progress = count / maxLength;
    const isCountThreshold = maxLength - 1 < count + 10;
    const wrapperClasses = classNames("bg-base-200 rounded-full transition origin-right pointer-events-auto", {
      "scale-75": !isThreshold,
    });
    const progressCountClasses = classNames("absolute inset-0 flex items-center justify-center text-xs", {
      "text-base-content": !isCountThreshold,
      "text-error dark:text-error": isCountThreshold,
    });
    const progressRingClasses = classNames({
      "scale-100": isThreshold,
      "scale-75": !isThreshold,
      "text-error": isCountThreshold,
      "text-primary-dark dark:text-primary-light": !isCountThreshold,
    });

    return (
      <div className="relative">
        {element}
        <div className="pointer-events-none absolute bottom-0 right-0 px-2 py-[3px]">
          <Tooltip.Provider>
            <Tooltip.Item className={wrapperClasses}>
              <AnimatePresence>
                {isThreshold ? (
                  <motion.span className={progressCountClasses} {...motionPresets.scale}>
                    {maxLength - count}
                  </motion.span>
                ) : null}
              </AnimatePresence>
              <ProgressRing className={progressRingClasses} progress={progress} size={8} />
            </Tooltip.Item>
            <Tooltip.Panel className="text-xs">
              {count} / {maxLength} characters
            </Tooltip.Panel>
          </Tooltip.Provider>
        </div>
      </div>
    );
  }

  return element;
});

export interface TextareaFieldProps extends Pick<ComponentPropsWithoutRef<"div">, "children" | "className"> {
  cornerHint?: ReactElement | string | null;
  description?: ReactElement | string | null;
  error?: string[] | boolean | string;
  id: string;
  label: ReactElement | string | null;
  labelHidden?: boolean;
  appearance?: "default" | "rich-text";
}

export type TextareaProps = BaseTextareaProps & TextareaFieldProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { appearance = "default", cornerHint, description, error, label, labelHidden = false, ...props }: TextareaProps,
  ref,
) {
  if (appearance === "rich-text") {
    return (
      <RichTextarea
        cornerHint={cornerHint}
        error={error}
        description={description}
        label={label}
        labelHidden={labelHidden}
        {...props}
        ref={ref}
      />
    );
  }

  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseTextarea {...props} ref={ref} />
    </FieldGroup>
  );
});

interface RichTextareaProps extends TextareaProps {
  dummy?: ReactNode;
}

const RichTextarea = forwardRef<HTMLTextAreaElement, RichTextareaProps>(function RichTextarea(
  { cornerHint, description, error, label: originalLabel, labelHidden, ...props }: RichTextareaProps,
  ref,
) {
  const [preview, setPreview] = useState(false);
  const { value, disabled } = props;
  const isDirty = value !== undefined && value.toString().length > 0;

  const navigation = disabled ? null : (
    <div className="flex items-center space-x-2">
      <Navigation className="bg-base-300 rounded-lg" direction="horizontal" size="sm">
        <Navigation.Item
          as="button"
          className="flex-1"
          isCurrent={!preview}
          onClick={() => setPreview(false)}
          type="button"
        >
          Write
        </Navigation.Item>
        <Navigation.Item
          as="button"
          className="flex-1"
          disabled={!isDirty}
          isCurrent={preview}
          onClick={() => setPreview(true)}
          type="button"
        >
          Preview
        </Navigation.Item>
      </Navigation>
      <TooltipProvider>
        <Tooltip.Button className="focus-visible:ring-primary dark:text-primary-lighter text-primary-darker focus-visible:ring-offset-base-100 rounded-full transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2">
          <Tooltip.Icon icon={InformationCircleIcon} />
        </Tooltip.Button>
        <Tooltip.Panel>
          <div className="space-y-2 text-left">
            <p>Markdown syntax supported.</p>
            <div>
              <Markdown>Here is a _\_simple\__ **\*\*example\*\***</Markdown>
            </div>
          </div>
        </Tooltip.Panel>
      </TooltipProvider>
    </div>
  );

  const label = preview ? (
    <>
      {originalLabel} <span className="text-base-content italic">(Preview)</span>
    </>
  ) : (
    originalLabel
  );
  const content =
    preview || disabled ? (
      <div className="text-heading-content bg-base-300 max-h-[320px] min-h-[128px] overflow-auto rounded-lg border border-transparent px-3 py-2 text-sm">
        {isDirty ? <Markdown>{`${value}`}</Markdown> : null}
      </div>
    ) : (
      <BaseTextarea {...props} ref={ref} />
    );

  return (
    <div className="space-y-2">
      <FieldGroup
        cornerHint={cornerHint}
        description={description}
        error={error}
        label={label}
        labelHidden={labelHidden}
        id={props.id}
      >
        {content}
      </FieldGroup>
      {navigation}
    </div>
  );
});
