import type { ExternalPositionType, ReleaseContracts } from "@enzymefinance/utils";

import { externalPositions } from "./externalPositions";
import type { ExternalPositionOption } from "./hooks/useExternalPositionOptions";

export function parseExternalPositionTypes({
  externalPositionTypeIds,
  contracts,
  externalPositionTypes,
}: {
  externalPositionTypeIds: string[];
  externalPositionTypes: { id: string; label: string }[];
  contracts: ReleaseContracts;
}): ExternalPositionOption[] {
  return externalPositionTypeIds.map((externalPositionType) => {
    const found = externalPositionTypes.find((type) => type.id === externalPositionType);

    if (found === undefined) {
      throw new Error("External position type not found");
    }

    const externalPosition = externalPositions[found.label as ExternalPositionType];

    return {
      externalPositionIdentifier: Number(externalPositionType),
      id: externalPosition.address(contracts),
      name: externalPosition.label,
      type: externalPosition.type,
      url: externalPosition.icon,
    };
  });
}
