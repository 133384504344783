import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay, useFormatBigInt } from "@enzymefinance/ethereum-ui";
import { Form, FormErrorMessage, NumberInput, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { Depositor } from "@enzymefinance/sdk";
import { Button, Modal, SectionHeading } from "@enzymefinance/ui";
import { bigIntInput, bigint, refine } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import type { StartSdkFunction } from "components/transactions/TransactionModal";
import { log } from "logger/logger";
import type { ReactNode } from "react";
import { useSharesBalance } from "utils/hooks/useSharesBalance";
import { maxUint256 } from "viem";
import { z } from "zod";

const schema = z
  .object({
    redeemQuantity: bigIntInput({ decimals: 18 }),
    shareBalance: bigint(),
  })
  .transform(
    refine(
      (values) => {
        return values.redeemQuantity <= values.shareBalance;
      },
      {
        message: "The value must be less than or equal to the share balance.",
        path: ["redeemQuantity"],
      },
    ),
  );

interface VaultRedeemSharesInKindFormProps {
  children?: ReactNode;
  comptrollerProxy: string;
  close: () => void;
  exitFeeWarning: ReactNode;
  startTransaction: StartSdkFunction;
  vaultProxy: Address;
}

export function VaultRedeemSharesInKindForm({
  children,
  comptrollerProxy,
  close,
  exitFeeWarning,
  startTransaction,
  vaultProxy,
}: VaultRedeemSharesInKindFormProps) {
  const [signerAddress] = useSigner();
  const { client } = useNetwork();

  const form = useForm({
    defaultValues: {
      redeemQuantity: "",
      shareBalance: 0n,
    },
    onSubmit: (values, helpers) => {
      if (!signerAddress) {
        return helpers.setError("form", {
          message: "Wallet not connected. You must connect your wallet to perform this action.",
        });
      }

      // If the user wants to redeem all shares, we have to mass MaxInt256 to the method.
      // This will make sure that any shares that are allocated as part of the redemption (e.g. management fees)
      // are also included in the redemption.
      const redeemQuantity = values.redeemQuantity < values.shareBalance ? values.redeemQuantity : maxUint256;

      try {
        const fn = Depositor.redeemSharesInKind({
          comptrollerProxy: toAddress(comptrollerProxy),
          sharesQuantity: redeemQuantity,
          recipient: signerAddress,
          additionalAssets: [],
          assetsToSkip: [],
        });

        startTransaction(fn, signerAddress);
      } catch (error) {
        log.error({ category: "VaultRedeemSharesInKind", message: error.message });

        return helpers.setError("form", {
          message: `Error submitting the transaction: ${error.message}`,
        });
      }
    },
    schema,
  });

  const { setValue, watch } = form;

  const balanceQuery = useSharesBalance(
    client,
    {
      account: signerAddress,
      vault: vaultProxy,
    },
    {
      onSuccess(data) {
        setValue("shareBalance", data);
      },
    },
  );
  const [shareBalance] = watch(["shareBalance"]);
  const shareBalanceFormatted = useFormatBigInt(shareBalance);

  return (
    <Form form={form}>
      <Modal.Body className="space-y-6">
        <div className="space-y-1">
          <SectionHeading.Subtitle>Your Current Balance:</SectionHeading.Subtitle>
          <div className="text-base-content text-lg font-bold">
            <BigIntDisplay
              loading={balanceQuery.isLoading && balanceQuery.isFetching}
              numberFormat={{ currency: "shares", maximumFractionDigits: 20 }}
              value={balanceQuery.data}
            />
          </div>
        </div>
        <NumberInput
          appearance="extended"
          wrapperclassname="rounded-lg"
          balance={shareBalanceFormatted.value}
          description="The number of shares you would like to redeem."
          name="redeemQuantity"
          label="Quantity of shares to redeem"
        />
        {exitFeeWarning}
        {children}
        <FormErrorMessage />
      </Modal.Body>
      <Modal.Actions>
        <SubmitButton>Continue</SubmitButton>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
      </Modal.Actions>
    </Form>
  );
}
