import { Integrations } from "@enzymefinance/sdk/Portfolio";
import type { IntegrationHandler } from "./types";

export const threeOneThirdTakeOrder: IntegrationHandler<Integrations.ThreeOneThird.takeOrderArgs> = {
  Description() {
    return <span>Trade on 31Third</span>;
  },
  Label() {
    return <>31Third</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.ThreeOneThird.takeOrderDecode(encodedCallArgs),
};
