import { hexToString, isHex, stringToBytes, toHex } from "viem";

export const defaultRevertError = {
  message: "The call was reverted without providing further details.",
  title: "Error",
} as const;

export function extractCallError(error: { details: string; shortMessage: string }) {
  const humanizedRevertMessage = humanizeRevertMessage(error.details);

  if (humanizedRevertMessage.message === defaultRevertError.message) {
    return { message: error.details, title: error.shortMessage };
  }

  return humanizedRevertMessage;
}

export function humanizeRevertMessage(rawError: string) {
  const error = decodeMessage(rawError);

  if (error.endsWith("BAL#505")) {
    return {
      message: "Exit would yield fewer than the user-supplied minimum tokens out",
      title: "Too low slippage",
    };
  }

  if (error.endsWith("BAL#208")) {
    return {
      message: "Slippage/front-running protection check failed on a pool join. Try to increase your slippage.",
      title: "Too low slippage",
    };
  }

  if (rawError.endsWith("__addTrackedAssetsToVault: Unsupported asset")) {
    return { message: "Did you select an asset that is not supported by Enzyme?", title: "Unsupported asset" };
  }

  if (rawError.endsWith("Only the contract owner can call this function")) {
    return {
      message: "Only the contract owner can call this function. Are you connected to the vault owner wallet?",
      title: "Disallowed action",
    };
  }

  if (
    rawError.endsWith("__buyShares: Shares received < _minSharesQuantity") ||
    rawError.endsWith("exchangeAndBuyShares: _minInvestmentAmount not met")
  ) {
    return {
      message:
        "The actual shares received are lower than expected. Consider trying again with a higher slippage setting.",
      title: "Shares amount lower than expected",
    };
  }

  if (rawError.endsWith("__buyShares: Pending migration or reconfiguration")) {
    return {
      message:
        "This vault has a pending migration or reconfiguration. Please wait until the migration or reconfiguration process is finished before buying shares.",
      title: "Disallowed action",
    };
  }

  if (rawError.includes("MigrationOutHook.PreMigrate")) {
    return {
      message:
        "Your vault upgrade cannot be performed automatically. If you are aware of the reason for this validation failure, you may wish to bypass validation under Advanced Settings. In doubt, please reach out to the team via email at migration@enzyme.finance.",
      title: "Automatic migration failed",
    };
  }

  return defaultRevertError;
}

function decodeMessage(code: string) {
  try {
    const input = isHex(code) ? code : toHex(stringToBytes(code));
    let output: string = input;

    // This is a poor-mans check to see if there's a significant amount of whitespace somewhere in the string.
    // If so, assume it's an ugly encoded hex string. This is not great but honestly I've no idea what's going
    // on here ...
    if (/0{16}/.test(output)) {
      output = input.substring(2 + 16 + 128).replace(/0+$/, "");

      if (output.length % 2 === 1) {
        output += "0";
      }
    }

    return hexToString(`0x${output}`).replace(/^Error: /, "");
  } catch {
    return code.replace(/^Error: /, "");
  }
}
