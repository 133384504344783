import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay, TokenWithCurrency, useFormatBigInt } from "@enzymefinance/ethereum-ui";
import { Form, FormErrorMessage, NumberInput, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { CalendarIcon } from "@enzymefinance/icons/solid";
import { Depositor } from "@enzymefinance/sdk";
import { Accordion, Alert, Button, Card, DateDisplay, DurationDisplay, Icon, Modal } from "@enzymefinance/ui";
import { bigIntInput, safeParse } from "@enzymefinance/validation";
import { captureException } from "@sentry/react";
import { useSigner } from "components/connection/Connection";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency } from "components/settings/Settings";
import type { StartSdkFunction } from "components/transactions/TransactionModal";
import { useCallback, useMemo, useState } from "react";
import { findTokenValue } from "utils/currency.js";
import type { SharedSharesWrapperRedemptionInfo } from "utils/hooks/useSharesWrapperRedemptionInfo";
import { useSpecificAssetsRedemptionExpectedAmounts } from "utils/hooks/useSpecificAssetsRedemptionExpectedAmounts";
import { z } from "zod";

const schema = z
  .object({
    depositedShares: z.bigint(),
    redemptionApprovedShares: z.bigint(),
    noRedemptionLimit: z.boolean(),
    redeemQuantity: bigIntInput({
      decimals: 18,
      schema: z.bigint().refine((value) => value > 0n, {
        message: "The share quantity cannot be zero.",
      }),
    }),
  })
  .refine(
    (value) => (typeof value.redeemQuantity === "bigint" ? value.depositedShares >= value.redeemQuantity : true),
    {
      message: "The shares amount exceeds your current balance.",
      path: ["redeemQuantity"],
    },
  )
  .refine(
    (value) =>
      typeof value.redeemQuantity === "bigint" && !value.noRedemptionLimit
        ? value.depositedShares >= value.redeemQuantity
        : true,
    {
      message: "The shares amount exceeds your approved for redemption balance.",
      path: ["redeemQuantity"],
    },
  );

interface VaultRedeemSharesWrapperFormProps {
  startTransaction: StartSdkFunction;
  close: () => void;
  sharesWrapperRedemptionInfo: SharedSharesWrapperRedemptionInfo;
  comptrollerProxy: string;
}

export function VaultRedeemSharesWrapperForm({
  startTransaction,
  comptrollerProxy,
  sharesWrapperRedemptionInfo,
}: VaultRedeemSharesWrapperFormProps) {
  const { client, environment } = useNetwork();
  const [signerAddress] = useSigner();
  const { assetPrices, loading: assetPricesLoading } = useAssetPrices();
  const currency = useCurrency();

  const depositedShares = sharesWrapperRedemptionInfo.depositedShares;
  const redemptionApprovedShares = sharesWrapperRedemptionInfo.redemptionApprovedShares;
  const redemptionRequestedShares = sharesWrapperRedemptionInfo.redemptionRequestedShares;

  const redemptionAsset = useMemo(() => {
    return environment.getAsset(sharesWrapperRedemptionInfo.redemptionAssetAddress);
  }, [environment, sharesWrapperRedemptionInfo.redemptionAssetAddress]);

  const maxPossibleRedeemQuantity = useMemo(() => {
    if (sharesWrapperRedemptionInfo.noRedemptionLimit) {
      return depositedShares - redemptionRequestedShares;
    }

    if (depositedShares < redemptionApprovedShares) {
      return depositedShares;
    }

    return redemptionApprovedShares;
  }, [
    depositedShares,
    redemptionApprovedShares,
    redemptionRequestedShares,
    sharesWrapperRedemptionInfo.noRedemptionLimit,
  ]);

  const maxPossibleRedeemQuantityFormatted = useFormatBigInt(maxPossibleRedeemQuantity).value;

  const form = useForm({
    defaultValues: {
      depositedShares,
      redemptionApprovedShares,
      noRedemptionLimit: sharesWrapperRedemptionInfo.noRedemptionLimit,
      redeemQuantity: maxPossibleRedeemQuantityFormatted,
    },
    mode: "onTouched",
    onSubmit(values, helpers) {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        const fn = Depositor.sharesWrapperRequestRedeem({
          sharesWrapper: sharesWrapperRedemptionInfo.sharesWrapperAddress,
          amount: values.redeemQuantity,
        });

        startTransaction(fn, signerAddress);
      } catch (error) {
        captureException(error);

        return helpers.setError("form", {
          message: "Something went wrong.",
          type: "report",
        });
      }
    },
    schema,
  });

  const cancelRequestRedeem = useCallback(() => {
    if (!signerAddress) {
      return;
    }

    const fn = Depositor.sharesWrapperCancelRequestRedeem({
      sharesWrapper: sharesWrapperRedemptionInfo.sharesWrapperAddress,
    });

    startTransaction(fn, signerAddress);
  }, [sharesWrapperRedemptionInfo.sharesWrapperAddress, signerAddress, startTransaction]);

  const { watch } = form;

  const [redeemQuantity] = watch(["redeemQuantity"]);

  const [redeemValue, setRedeemValue] = useState<bigint | null>(null);

  const redeemQuantityBigInt = useMemo(() => {
    return safeParse(bigIntInput({ decimals: 18 }), redeemQuantity) ?? 0n;
  }, [redeemQuantity]);

  const specificAssetsRedemptionQuery = useSpecificAssetsRedemptionExpectedAmounts(
    client,
    {
      comptrollerProxy: toAddress(comptrollerProxy),
      payoutAssets: [sharesWrapperRedemptionInfo.redemptionAssetAddress],
      payoutPercentages: [10000],
      redeemQuantity: redeemQuantityBigInt,
      recipient: sharesWrapperRedemptionInfo.sharesWrapperAddress,
    },
    {
      cacheTime: 0,
      onSettled(data, error) {
        if (!error && data?.[0] !== undefined) {
          setRedeemValue(data[0]);
        }
      },
    },
  );

  const redemptionTokenWithCurrencyValue = useMemo(() => {
    if (typeof redeemValue !== "bigint") {
      return undefined;
    }

    const currencyValue = findTokenValue({
      assetPrices,
      token: redemptionAsset,
      value: redeemValue,
    });

    return {
      amount: redeemValue,
      asset: redemptionAsset,
      currencyValue,
    };
  }, [assetPrices, redeemValue, redemptionAsset]);

  const isFormDisabled =
    assetPricesLoading ||
    Boolean(sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened) ||
    (!sharesWrapperRedemptionInfo.noRedemptionLimit && redemptionApprovedShares === 0n);

  const isSubmitDisabled = isFormDisabled;

  const showCancel = redemptionRequestedShares > 0n;

  const showIncrease = Boolean(showCancel && sharesWrapperRedemptionInfo.noRedemptionLimit);

  return (
    <Form form={form}>
      <Modal.Body className="space-y-4 ">
        <Card appearance="secondary">
          <Card.Content className="space-y-2 divide-y divide-gray-300 dark:divide-gray-700">
            <div className="flex flex-row items-baseline justify-between">
              <span className="text-base-content text-md font-medium">Your Current Balance</span>
              <div className="text-base-content text-md font-medium">
                <BigIntDisplay numberFormat={{ maximumFractionDigits: 20 }} value={depositedShares} />{" "}
                <span className="text-sm">shares</span>
              </div>
            </div>
            {!sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened &&
            (redemptionApprovedShares > 0n || Boolean(sharesWrapperRedemptionInfo.noRedemptionLimit)) ? (
              <div className="flex flex-row items-baseline justify-between pt-2">
                <span className="text-base-content text-md font-medium">
                  Quantity pre-approved
                  <br /> by manager to redeem
                </span>
                <div className="text-base-content text-md font-medium">
                  {sharesWrapperRedemptionInfo.noRedemptionLimit ? (
                    <span>
                      <span className="text-high-emphasis">Unlimited</span> <span className="text-sm">shares</span>
                    </span>
                  ) : (
                    <span>
                      <BigIntDisplay numberFormat={{ maximumFractionDigits: 20 }} value={redemptionApprovedShares} />{" "}
                      <span className="text-sm">shares</span>
                    </span>
                  )}
                </div>
              </div>
            ) : null}
          </Card.Content>
        </Card>
        {redemptionRequestedShares > 0n ? (
          <Alert
            appearance="info"
            title={
              <span>
                Your request to redeem{" "}
                <BigIntDisplay numberFormat={{ maximumFractionDigits: 20 }} value={redemptionRequestedShares} /> shares
                is pending
              </span>
            }
          >
            {sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened ? (
              <span>It is not possible to change your request at this moment</span>
            ) : (
              <div className="space-y-3">
                <div>
                  {showIncrease
                    ? "You can increase or delete your redemption request until"
                    : showCancel
                      ? "You can delete your redemption request until"
                      : "You can request redemption until"}{" "}
                  <DateDisplay value={sharesWrapperRedemptionInfo.nextManagerExecuteWindowStart * 1000} format="lll" />
                </div>
                <Button appearance="destructive" onClick={cancelRequestRedeem}>
                  Delete request
                </Button>
              </div>
            )}
          </Alert>
        ) : null}
        {!sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened &&
        redemptionApprovedShares === 0n &&
        !sharesWrapperRedemptionInfo.noRedemptionLimit ? (
          <Alert appearance="info">
            You are currently not allowed to request redemption. Please contact the vault&apos;s manager to initialise
            your redemption.
          </Alert>
        ) : null}
        {Boolean(sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened) &&
        (showIncrease || redemptionApprovedShares === 0n) ? null : (
          <>
            <NumberInput
              appearance="extended"
              wrapperclassname="rounded-lg"
              balance={maxPossibleRedeemQuantityFormatted}
              name="redeemQuantity"
              label="Request shares to redeem"
              disabled={isFormDisabled}
            />
            {redemptionApprovedShares > redeemQuantityBigInt &&
            !sharesWrapperRedemptionInfo.noRedemptionLimit &&
            maxPossibleRedeemQuantity > redeemQuantityBigInt ? (
              <Alert appearance="warning" title="Quantity smaller than pre-approved amount by manager">
                The amount of shares you entered is less than the amount pre-approved by the manager for redemption.
                Please note that you will need to request a redemption again next cycle, as the unused balance will not
                roll from one cycle to the next.
              </Alert>
            ) : null}
          </>
        )}
        {typeof redeemValue === "bigint" &&
        redeemValue > 0n &&
        form.formState.isValid &&
        redemptionTokenWithCurrencyValue !== undefined &&
        !sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened ? (
          <div className="text-sm">
            You will receive about{" "}
            <TokenWithCurrency
              loading={specificAssetsRedemptionQuery.isLoading || specificAssetsRedemptionQuery.isFetching}
              reverse={true}
              currency={currency}
              value={redemptionTokenWithCurrencyValue}
            />
          </div>
        ) : null}

        <FormErrorMessage />
        <Accordion>
          <Accordion.Item
            isCard={false}
            title={
              <div className="flex flex-row items-center space-x-1 ">
                <Icon icon={CalendarIcon} size={8} />
                {sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened ? (
                  <span className="text-high-emphasis">
                    {redemptionRequestedShares > 0n ? (
                      <span>
                        The Investment Manager should execute this request by{" "}
                        <DateDisplay
                          appearance="simple"
                          className="text-high-emphasis"
                          value={sharesWrapperRedemptionInfo.managerExecuteWindowEnd * 1000} // manager - end / request - start
                        />
                      </span>
                    ) : (
                      <span>
                        You will be able to request a redemption during the next request redemption window, which starts{" "}
                        <DateDisplay
                          appearance="simple"
                          className="text-high-emphasis"
                          value={sharesWrapperRedemptionInfo.managerExecuteWindowEnd * 1000} // request - start
                          format="lll"
                        />{" "}
                        and ends{" "}
                        <DateDisplay
                          appearance="simple"
                          className="text-high-emphasis"
                          value={sharesWrapperRedemptionInfo.nextManagerExecuteWindowStart * 1000} // request - end
                          format="lll"
                        />
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="text-high-emphasis">
                    {showIncrease
                      ? "You can change your redemption request until"
                      : showCancel
                        ? "You can delete your redemption request until"
                        : "You can request redemption until"}{" "}
                    <DateDisplay
                      appearance="simple"
                      className="text-high-emphasis"
                      value={sharesWrapperRedemptionInfo.nextManagerExecuteWindowStart * 1000}
                      format="lll"
                    />
                  </span>
                )}
              </div>
            }
          >
            <p>
              To redeem your investment, you need to make a request to the Investment Manager, who will conduct
              compliance checks.
            </p>
            &nbsp;
            <p>
              The redemption cycle occurs every <DurationDisplay seconds={sharesWrapperRedemptionInfo.frequency} />.
            </p>
            &nbsp;
            <p>
              1. During the first part of the cycle, which lasts{" "}
              <DurationDisplay seconds={sharesWrapperRedemptionInfo.frequency - sharesWrapperRedemptionInfo.duration} />{" "}
              you may submit a redemption request, and you may change any existing redemption requests you have made.
            </p>
            <p>
              2. During the second part, which lasts <DurationDisplay seconds={sharesWrapperRedemptionInfo.duration} />,
              you cannot make any changes to your redemption request.
            </p>
            &nbsp;
            <p>
              At the end of the redemption cycle, the Investment Manager may execute the redemption requests, and the
              funds will be sent to your wallet.
            </p>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      {sharesWrapperRedemptionInfo.isManagerExecuteWindowOpened ? null : (
        <Modal.Actions>
          {showIncrease ? <SubmitButton disabled={isSubmitDisabled}>Increase request</SubmitButton> : null}
          {showCancel ? null : <SubmitButton disabled={isSubmitDisabled}>Request redemption</SubmitButton>}
        </Modal.Actions>
      )}
    </Form>
  );
}
