import { safeStringifyJSON } from "@enzymefinance/utils";
import { useEffect } from "react";
import type { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";

export function useUpdateField<TValues extends FieldValues, TPath extends Path<TValues> & string>(
  form: UseFormReturn<TValues>,
  name: TPath,
  value: PathValue<TValues, TPath>,
  shouldValidate = false,
) {
  useEffect(() => {
    const isTouched = (form.formState.touchedFields as Record<TPath, boolean>)[name];

    if (!isTouched) {
      form.setValue(name, value, { shouldValidate });
    }
  }, [safeStringifyJSON(value)]);
}
