import { ChevronDownIcon, ChevronUpIcon } from "@enzymefinance/icons/solid";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { CardAppearance } from "./Card.js";
import { Card } from "./Card.js";
import { Icon } from "./Icon.js";

export interface AccordionProps {
  children: ReactNode;
  isCard?: boolean;
  appearance?: CardAppearance;
}

export function Accordion({ children, isCard = true, appearance = "secondary" }: AccordionProps) {
  return (
    <>
      {isCard ? (
        <Card full={true} rounded="2xl" appearance={appearance}>
          <Card.Content>{children}</Card.Content>
        </Card>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}

interface AccordionItemProps {
  children: ReactNode;
  collapsible?: boolean;
  isCard?: boolean;
  initialOpen?: boolean;
  title: ReactNode;
}

function accordionItem({
  children,
  collapsible = true,
  isCard = true,
  initialOpen = false,
  title,
}: AccordionItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(collapsible ? initialOpen : true);
  }, [ref, initialOpen, collapsible]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const displayContent = useMemo(
    () => (
      <>
        <div className="space-b flex w-full flex-row justify-between">
          <div>{title}</div>
          <button
            onClick={handleToggle}
            type="button"
            className="focus-visible:ring-offset-base-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
          >
            <Icon icon={isOpen ? ChevronUpIcon : ChevronDownIcon} size={8} aria-hidden={true} />
          </button>
        </div>
        <div ref={ref}>{isOpen ? <div>{children}</div> : null}</div>
      </>
    ),
    [children, handleToggle, isOpen, title],
  );

  return (
    <>
      {isCard ? (
        <Card.Content className="flex flex-col items-start space-y-4">{displayContent}</Card.Content>
      ) : (
        <div className="flex flex-col items-start space-y-4">{displayContent}</div>
      )}
    </>
  );
}

Accordion.Item = accordionItem;
