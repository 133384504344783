import { useGlobals } from "components/providers/GlobalsProvider";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

interface CreateNewFundInputs {
  fundOwner: Address;
  fundName: string;
  fundSymbol: string;
  denominationAsset: Address;
  sharesActionTimelock: bigint;
  feeManagerConfigData: Hex;
  policyManagerConfigData: Hex;
}

export const createNewFund: FunctionConfig<CreateNewFundInputs> = {
  signature:
    "function createNewFund(address fundOwner, string fundName, string fundSymbol, address denominationAsset, uint256 sharesActionTimelock, bytes feeManagerConfigData, bytes policyManagerConfigData) payable returns (address comptrollerProxy, address vaultProxy)",

  Description({ inputs }) {
    const { environment } = useGlobals();
    const denominationAsset = environment.getAsset(inputs.denominationAsset);

    return (
      <>
        {`Create a new vault named ${inputs.fundName} with symbol ${inputs.fundSymbol} and denomination asset ${denominationAsset.symbol}`.trim()}
        .
      </>
    );
  },

  Label() {
    return <>Create Vault</>;
  },
};
