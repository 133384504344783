import { Network } from "@enzymefinance/environment";
import { address, network as networkSchema, numberish } from "@enzymefinance/validation";
import { env } from "utils/config";
import { z } from "zod";

import { createUseValidatedQuery } from "./useValidatedQuery";

export const gasRelayerDataSchema = z.object({
  chainId: numberish(z.number()),
  minGasPrice: z.coerce.bigint(),
  maxAcceptanceBudget: z.coerce.bigint(),
  networkId: numberish(z.number()),
  ownerAddress: address({ caseInsensitive: true, outputChecksum: true }), // caseInsensitive is required because gas relayer validates checksummed address
  ready: z.boolean(),
  relayHubAddress: address({ caseInsensitive: true, outputChecksum: true }), // caseInsensitive is required because gas relayer validates checksummed address
  relayManagerAddress: address(),
  relayWorkerAddress: address(),
  version: z.string(),
});

export type GasRelayerResponseData = z.infer<typeof gasRelayerDataSchema>;

export const useGasRelayerData = createUseValidatedQuery(["gas-relayer-data"], {
  paramsSchema: networkSchema(),
  responseSchema: gasRelayerDataSchema,
  queryFn: async (network) => {
    const gsn1Relayer = network === Network.ETHEREUM ? env.gsn1RelayerMainnet : env.gsn1RelayerMatic;

    return (await fetch(`${gsn1Relayer}/gsn1/getaddr`)).json();
  },
});
