import type { Modifier } from "react-popper";

// Reference: https://github.com/popperjs/popper-core/issues/794#issuecomment-640747771
export const sameWidthModifier: Modifier<string> = {
  effect({ state }) {
    state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
  },
  enabled: true,
  fn({ state }) {
    if (typeof state.styles.popper?.width === "string") {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    }
  },
  name: "sameWidth",
  phase: "beforeWrite",
  requires: ["computeStyles"],
};
