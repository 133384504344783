import { ColorScheme } from "queries/backend";
import { useLayoutEffect } from "react";

const defaultColorScheme = ColorScheme.DARK;

function colorSchemeChangeHandler(event: MediaQueryListEvent) {
  const classList = document.documentElement.classList;

  if (event.matches && !classList.contains("dark")) {
    return classList.add("dark");
  }

  if (!event.matches && classList.contains("dark")) {
    return classList.remove("dark");
  }
}

export function useColorScheme(scheme: ColorScheme = defaultColorScheme) {
  useLayoutEffect(() => {
    if (typeof document === "undefined" || !("documentElement" in document)) {
      return;
    }

    const classList = document.documentElement.classList;
    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const isDarkColorScheme = colorSchemeQuery.matches;

    if (scheme === ColorScheme.SYSTEM) {
      colorSchemeQuery.addEventListener("change", colorSchemeChangeHandler);
    }

    if (
      (scheme === ColorScheme.DARK || (scheme === ColorScheme.SYSTEM && isDarkColorScheme)) &&
      !classList.contains("dark")
    ) {
      classList.add("dark");
    }

    if (
      (scheme === ColorScheme.LIGHT || (scheme === ColorScheme.SYSTEM && !isDarkColorScheme)) &&
      classList.contains("dark")
    ) {
      classList.remove("dark");
    }

    return () => colorSchemeQuery.removeEventListener("change", colorSchemeChangeHandler);
  }, [scheme]);
}
