import { Currency, CurrencySlug, getCurrency, isCurrencyIdentifier } from "@enzymefinance/environment";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

export const defaultCurrency = Currency.USD;

interface SettingsContextValues {
  currency: Currency;
  currencySlug: CurrencySlug;
  resetSelectedVaultId: () => void;
  selectedVaultId?: string;
  setCurrency: (currency: Currency) => void;
  setSelectedVaultId: (id: string) => void;
  setVaultMetricsInDA: (displayVaultMetricsInDa: boolean) => void;
  vaultMetricsInDA: boolean;
}

const SettingsContext = createContext<SettingsContextValues>({
  currency: Currency.USD,
  currencySlug: CurrencySlug.USD,
  resetSelectedVaultId: () => {},
  setCurrency: () => {},
  setSelectedVaultId: () => {},
  setVaultMetricsInDA: () => {},
  vaultMetricsInDA: false,
});

export function useSettings() {
  return useContext(SettingsContext);
}

export function useCurrency() {
  const { currency } = useSettings();

  return currency;
}

export function useCurrencySlug() {
  const { currencySlug } = useSettings();

  return currencySlug;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

function useCurrencySettings() {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamKey = "currency";
  const searchParamValue = searchParams.get(searchParamKey);

  const currencyDefinition = useMemo(
    () => getCurrency(isCurrencyIdentifier(searchParamValue) ? searchParamValue : defaultCurrency),
    [searchParamValue],
  );

  useEffect(() => {
    if (searchParamValue === defaultCurrency) {
      searchParams.delete(searchParamKey);
      setSearchParams(searchParams, { replace: true, state });
    }
  }, []);

  function setCurrency(newCurrency: Currency) {
    if (newCurrency === currencyDefinition.id) {
      return;
    }

    if (newCurrency === defaultCurrency) {
      searchParams.delete(searchParamKey);
    } else {
      searchParams.set(searchParamKey, newCurrency);
    }
    setSearchParams(searchParams, { replace: true, state });
  }

  return {
    setCurrency,
    currencyDefinition,
  };
}

function useVaultSettings() {
  const [selectedVaultId, setSelectedVaultId, resetSelectedVaultId] =
    useLocalStorage<string>("settings.selectedVaultId");

  return {
    selectedVaultId,
    setSelectedVaultId,
    resetSelectedVaultId,
  };
}
export const vaultMetricSearchKey = "vault-metrics-in-denomination-asset";

function useVaultMetricsSettings() {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamValue = searchParams.get(vaultMetricSearchKey);

  const defaultValue = false;

  useEffect(() => {
    if (searchParamValue === defaultValue.toString()) {
      searchParams.delete(vaultMetricSearchKey);
      setSearchParams(searchParams, { replace: true, state });
    }
  }, []);

  const setVaultMetricsInDA = (displayVaultMetricsInDa: boolean) => {
    if (displayVaultMetricsInDa === defaultValue) {
      searchParams.delete(vaultMetricSearchKey);
    } else {
      searchParams.set(vaultMetricSearchKey, displayVaultMetricsInDa.toString());
    }
    setSearchParams(searchParams, { replace: true, state });
  };

  return {
    setVaultMetricsInDA,
    vaultMetricsInDA: searchParamValue === "true",
  };
}

function useSettingsProvider() {
  const { setCurrency, currencyDefinition } = useCurrencySettings();

  const { selectedVaultId, setSelectedVaultId, resetSelectedVaultId } = useVaultSettings();

  const { setVaultMetricsInDA, vaultMetricsInDA } = useVaultMetricsSettings();

  return {
    currencyDefinition,
    resetSelectedVaultId,
    selectedVaultId,
    setCurrency,
    setSelectedVaultId,
    setVaultMetricsInDA,
    vaultMetricsInDA,
  };
}

export function SettingsProvider(props: SettingsProviderProps) {
  const {
    setCurrency,
    selectedVaultId,
    setSelectedVaultId,
    resetSelectedVaultId,
    currencyDefinition,
    setVaultMetricsInDA,
    vaultMetricsInDA,
  } = useSettingsProvider();

  return (
    <SettingsContext.Provider
      {...props}
      value={{
        currency: currencyDefinition.id,
        currencySlug: currencyDefinition.slug,
        resetSelectedVaultId,
        selectedVaultId,
        setCurrency,
        setSelectedVaultId,
        setVaultMetricsInDA,
        vaultMetricsInDA,
      }}
    />
  );
}
