import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperSetDepositMode: FunctionConfig<{ mode: number }> = {
  signature: "function setDepositMode(uint8 mode)",

  Description({ inputs }) {
    return <>Do you want to change the deposit mode to {inputs.mode === 0 ? "Direct" : "Request"}?</>;
  },
  Label() {
    return <>Set Deposit Mode</>;
  },
};
