import { Feature, useMeQuery } from "queries/backend";
import { useMemo } from "react";
import { client } from "utils/backend";

export function useArbitraryLoanAvailable() {
  const meQuery = useMeQuery({ client });

  const available = useMemo(() => {
    return meQuery.data?.me?.features.includes(Feature.ARBITRARY_LOAN) === true;
  }, [meQuery.data?.me?.features]);

  return available;
}
