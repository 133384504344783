import dayjs from "dayjs";

export const ethVault = "0x16770d642e882e1769ce4ac8612b8bc0601506fc";
export const stEthVault = "0x1ce8aafb51e79f6bdc0ef2ebd6fd34b00620f6db";
export const vaults = [ethVault, stEthVault] as const;

export const mainnetLaunch = dayjs.unix(1746050400); // 1st of May 2025
export const stakingDays = 183;
export const stakingBeforeMainnetLaunchDays = 30;
export const cooldownDays = 30;
export const stakingStart = mainnetLaunch.subtract(stakingBeforeMainnetLaunchDays, "day");
export const stakingEnd = stakingStart.add(stakingDays, "day");
export const cooldownEnd = mainnetLaunch.add(cooldownDays, "day");

export const tranchesConfig = [
  { divaPerEthPerDay: 2.5, threshold: 10_000 },
  { divaPerEthPerDay: 2.25, threshold: 20_000 },
  { divaPerEthPerDay: 2, threshold: 30_000 },
  { divaPerEthPerDay: 1.9, threshold: 40_000 },
  { divaPerEthPerDay: 1.75, threshold: 50_000 },
  { divaPerEthPerDay: 1.6, threshold: 60_000 },
  { divaPerEthPerDay: 1.55, threshold: 70_000 },
  { divaPerEthPerDay: 1.5, threshold: 80_000 },
  { divaPerEthPerDay: 1.4, threshold: 90_000 },
  { divaPerEthPerDay: 1.3, threshold: 100_000 },
] as const;

export const divaStakingSubgraph = "7BwBCYDVsVViTKQjRtbGA63SoCkxQe7XsMYtQYijQyfn";
