import type {
  ProtocolSelectPerAddressError,
  ProtocolSelectPerAddressProps as ProtocolSelectPerAddressPropsBase,
} from "@enzymefinance/ethereum-ui";
import { ProtocolSelectPerAddress as ProtocolSelectPerAddressBase } from "@enzymefinance/ethereum-ui";
import { useMemo } from "react";
import type { FieldError, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface ProtocolSelectPerAddressProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<ProtocolSelectPerAddressPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function ProtocolSelectPerAddress<TFieldValues extends FieldValues = FieldValues>(
  props: ProtocolSelectPerAddressProps<TFieldValues>,
) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  const errors = useMemo<ProtocolSelectPerAddressError[]>(
    () =>
      (
        fieldState.error as unknown as ({ protocols?: FieldError; address?: FieldError } | undefined)[] | undefined
      )?.map((error) => ({
        protocols: error?.protocols?.message,
        address: error?.address?.message,
      })) ?? [],
    [fieldState.error],
  );

  return (
    <ProtocolSelectPerAddressBase error={errors} {...props} id={props.name} {...field} onChange={onChange as any} />
  );
}
