import type { Size } from "@enzymefinance/utils";
import { sizeClasses } from "@enzymefinance/utils";
import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import { useMemo } from "react";

const radius = 12;

export interface ProgressRingProps extends ComponentPropsWithoutRef<"svg"> {
  size?: Size;
  strokeWidth?: number;
  progress?: number;
}

export function ProgressRing({ className, progress = 0, strokeWidth = 2, size = 6, ...props }: ProgressRingProps) {
  const normalizedRadius = useMemo(() => radius - strokeWidth * 2, [strokeWidth]);
  const circumference = useMemo(() => normalizedRadius * 2 * Math.PI, [normalizedRadius]);
  const strokeDashoffset = useMemo(() => (1 - progress) * circumference, [circumference, progress]);
  const classes = classNames("-rotate-90 transition", sizeClasses[size], className);

  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      className={classes}
      fill="transparent"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={radius}
        cy={radius}
        opacity={0.25}
        r={normalizedRadius}
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={radius}
        cy={radius}
        r={normalizedRadius}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
      />
    </svg>
  );
}
