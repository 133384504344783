import type { ReactElement, ReactNode } from "react";
import { createPortal } from "react-dom";

import { useDomElement } from "../../hooks/useDomElement.js";

export interface PortalProps {
  children?: ReactNode;
}

export function Portal({ children }: PortalProps): ReactElement | null {
  const element = useDomElement();

  return element ? createPortal(children, element) : null;
}
