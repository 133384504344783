import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useCallback } from "react";
import { externalPositions } from "utils/externalPositions";

export function useGetExternalPositionById() {
  const { externalPositionTypes } = useExternalPositions();

  return useCallback(
    (id: string) => {
      const foundExternalPositionType = externalPositionTypes.find((position) => position.id === id);

      if (foundExternalPositionType === undefined) {
        return undefined;
      }

      const label = foundExternalPositionType.label;

      return externalPositions[label];
    },
    [externalPositionTypes],
  );
}
