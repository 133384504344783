import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { maxUint256 } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const aaveV2Borrow: ExternalPositionHandler<Integrations.AaveV2.BorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Borrowing{" "}
        <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} /> on
        Aave V2
      </>
    );
  },
  Label() {
    return <>Borrow on Aave V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV2.borrowDecode(encodedCallArgs),
};

export const aaveV2RepayBorrow: ExternalPositionHandler<Integrations.AaveV2.RepayBorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);
    const isRepayingAll = assetAmount === maxUint256;

    return (
      <>
        Repaying{" "}
        {isRepayingAll ? (
          "all"
        ) : (
          <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />
        )}{" "}
        owed to Aave V2
      </>
    );
  },
  Label() {
    return <>Repay Debt on Aave V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV2.repayBorrowDecode(encodedCallArgs),
};

export const aaveV2AddCollateral: ExternalPositionHandler<Integrations.AaveV2.AddCollateralArgs> = {
  Description({
    args: {
      aTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Adding <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />{" "}
        as collateral on Aave V2
      </>
    );
  },
  Label() {
    return <>Add Collateral on Aave V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV2.addCollateralDecode(encodedCallArgs),
};

export const aaveV2RemoveCollateral: ExternalPositionHandler<Integrations.AaveV2.RemoveCollateralArgs> = {
  Description({
    args: {
      aTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Removing{" "}
        <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} /> as
        collateral on Aave V2
      </>
    );
  },
  Label() {
    return <>Remove Collateral on Aave V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV2.removeCollateralDecode(encodedCallArgs),
};

export const aaveV2ClaimRewards: ExternalPositionHandler = {
  Description() {
    return <>Claiming rewards on Aave V2</>;
  },
  Label() {
    return <>Borrow on Aave V2</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const createAaveV2ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V2 external position</>;
    }
    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <aaveV2AddCollateral.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <aaveV2AddCollateral.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V2 external position</>;
    }

    const Summary = getDefaultExtensionSummary(aaveV2AddCollateral.Label, aaveV2AddCollateral.Description);

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <Summary args={args} />;
  },
};
