import { SwatchIcon } from "@enzymefinance/icons/solid";
import type { ButtonProps } from "@enzymefinance/ui";
import { Button, Popover } from "@enzymefinance/ui";

import { UpdateThemeForm } from "./UpdateThemeForm";

export function UpdateTheme() {
  return (
    <Popover placement="top-start">
      <div className="rounded-full shadow-lg lg:bottom-6 lg:left-6">
        <Popover.Button<ButtonProps> as={Button} circular={true} leadingIcon={SwatchIcon} appearance="secondary">
          Customize Theme
        </Popover.Button>
      </div>
      <Popover.Panel>{({ close }) => <UpdateThemeForm close={close} />}</Popover.Panel>
    </Popover>
  );
}
