import type { FunctionConfig } from "utils/functionsTypes";

export const claimOwnership: FunctionConfig = {
  signature: "function claimOwnership()",

  Description() {
    return <>Claim ownership for this vault.</>;
  },

  Label() {
    return <>Claim Ownership</>;
  },
};
