import { NumberInput, TokenSelect, useFieldArray } from "@enzymefinance/hook-form";
import { useFormContext } from "@enzymefinance/hook-form";
import { PlusIcon, TrashIcon } from "@enzymefinance/icons/solid";
import { Button, Card } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";

import { VaultConfigFieldName } from "../VaultConfig";
import type { NoDepegOnRedeemSharesForSpecificAssetsPolicyFormFieldsValues } from "./NoDepegOnRedeemSharesForSpecificAssetsPolicy";

export function NoDepegPolicyAssetInputs() {
  const { getAssets } = useGlobals();
  const assets = useMemo(() => getAssets({ registered: true }), [getAssets]);
  const { control, trigger } = useFormContext<NoDepegOnRedeemSharesForSpecificAssetsPolicyFormFieldsValues>();
  const name = VaultConfigFieldName.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name,
    rules: {
      minLength: 1,
    },
  });

  const defaultAsset = assets[0];
  const defaultReferenceAsset = assets[1];

  if (defaultAsset === undefined || defaultReferenceAsset === undefined) {
    return null;
  }

  return (
    <>
      {fields.map((field, rowIndex) => {
        return (
          <Card key={`${field.id}`} appearance="tertiary">
            <Card.Content>
              <div className="flex items-center justify-between">
                <h4 className="title mb-4">Depeg asset</h4>
                <Button appearance="text-secondary" onClick={() => remove(rowIndex)} size="sm" leadingIcon={TrashIcon}>
                  Delete
                </Button>
              </div>
              <div className="flex flex-col lg:flex-row items-start space-x-0 lg:space-x-3">
                <div className="w-full">
                  <TokenSelect
                    className="w-full"
                    label={<span>Asset</span>}
                    name={`${name}.${rowIndex}.asset`}
                    options={assets}
                    isClearable={false}
                    onBlur={() => trigger(name)}
                    onFocus={() => trigger(name)}
                  />
                </div>
                <div className="w-full">
                  <TokenSelect
                    className="w-full"
                    label={<span>Reference Asset</span>}
                    name={`${name}.${rowIndex}.referenceAsset`}
                    options={assets}
                    isClearable={false}
                    onBlur={() => trigger(name)}
                    onFocus={() => trigger(name)}
                  />
                </div>
                <div className="w-full">
                  <NumberInput
                    label="Deviation Tolerance"
                    numberFormat={{ style: "percent", maximumFractionDigits: 2 }}
                    name={`${name}.${rowIndex}.deviationTolerance`}
                    onBlur={() => trigger(name)}
                    onFocus={() => trigger(name)}
                  />
                </div>
              </div>
            </Card.Content>
          </Card>
        );
      })}
      <Button
        appearance="text-secondary"
        leadingIcon={PlusIcon}
        onClick={() => append({ asset: defaultAsset, referenceAsset: defaultReferenceAsset, deviationTolerance: "10" })}
      >
        Add depeg asset
      </Button>
    </>
  );
}
