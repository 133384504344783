import type { FieldArrayPath, FieldValues, UseFieldArrayProps } from "react-hook-form";
import { useFieldArray as useFieldArrayBase } from "react-hook-form";

export function useFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = "id",
>(props: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>) {
  return useFieldArrayBase<TFieldValues, TFieldArrayName, TKeyName>(props);
}
