import { XMarkIcon } from "@enzymefinance/icons/solid";
import { Button, useMotionPresets } from "@enzymefinance/ui";
import { motion } from "framer-motion";
import type { ReactElement, ReactNode } from "react";

interface BannerProps {
  actions?: ReactElement | null;
  children: string;
  icon?: ReactNode;
  onDismiss?: () => void;
}

export function Banner({ actions, children, icon, onDismiss }: BannerProps) {
  const motionPresets = useMotionPresets();

  return (
    <motion.div
      animate={{ ...motionPresets.opacity.animate, y: 0 }}
      className="from-primary-light to-primary via-primary-dark flex flex-none items-center bg-gradient-to-r px-4 py-2"
      initial={{ ...motionPresets.opacity.exit, y: "-100%" }}
    >
      <div className="flex-1 items-center justify-center space-y-4 lg:flex lg:space-x-4 lg:space-y-0">
        <div className="flex items-center space-x-3">
          {icon}
          <p className="text-xs font-medium text-high-emphasis">{children}</p>
        </div>
        {actions ? <div className="flex flex-col lg:block">{actions}</div> : null}
      </div>
      <Button circular={true} icon={XMarkIcon} onClick={onDismiss} size="xs">
        Dismiss
      </Button>
    </motion.div>
  );
}
