import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { maxUint256 } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const compoundV2Borrow: ExternalPositionHandler<Integrations.CompoundV2.BorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Borrowing{" "}
        <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} /> on
        Compound V2 Borrowing{" "}
      </>
    );
  },
  Label() {
    return <>Borrow on Compound V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.CompoundV2.borrowDecode(encodedCallArgs),
};

export const compoundV2RepayBorrow: ExternalPositionHandler<Integrations.CompoundV2.RepayBorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Repaying{" "}
        <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} /> owed
        to Compound V2
      </>
    );
  },
  Label() {
    return <>Repay Debt on Compound V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.CompoundV2.repayBorrowDecode(encodedCallArgs),
};

export const compoundV2AddCollateral: ExternalPositionHandler<Integrations.CompoundV2.AddCollateralArgs> = {
  Description({
    args: {
      cTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    return (
      <>
        Adding <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />{" "}
        as collateral on Compound V2
      </>
    );
  },
  Label() {
    return <>Add Collateral on Compound V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.CompoundV2.addCollateralDecode(encodedCallArgs),
};

export const compoundV2RemoveCollateral: ExternalPositionHandler<Integrations.CompoundV2.RemoveCollateralArgs> = {
  Description({
    args: {
      cTokens: [assetAddress],
      amounts: [assetAmount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(assetAddress !== undefined, "assetAddress is undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(assetAddress);

    const isRepayingAll = assetAmount === maxUint256;

    return (
      <>
        Removing{" "}
        {isRepayingAll ? (
          "all"
        ) : (
          <BigIntDisplay value={assetAmount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />
        )}{" "}
        as collateral on Compound V2
      </>
    );
  },
  Label() {
    return <>Remove Collateral on Compound V2</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.CompoundV2.removeCollateralDecode(encodedCallArgs),
};

export const compoundV2ClaimComp: ExternalPositionHandler = {
  Description() {
    return <>Claiming rewards on Compound V2</>;
  },
  Label() {
    return <>Borrow on Compound V2</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const createCompoundV2ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Compound V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = compoundV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <compoundV2AddCollateral.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Compound V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = compoundV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <compoundV2AddCollateral.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Compound V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = compoundV2AddCollateral.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(compoundV2AddCollateral.Label, compoundV2AddCollateral.Description);

    return <Summary args={args} />;
  },
};
