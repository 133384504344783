import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { Utils } from "@enzymefinance/sdk";
import { Alert, Button, DurationDisplay, Modal, NumberDisplay, Toggle } from "@enzymefinance/ui";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { TransactionModal, useTransactionModal } from "components/transactions/TransactionModal";
import dayjs from "dayjs";
import type { FeeFragment, VaultDetailsFragment } from "queries/core";
import { useVaultAllowedAssetsForRedemptionQuery, useVaultFeesQuery } from "queries/core";
import { useCallback, useMemo, useState } from "react";
import { useInterval } from "react-use";
import type { SubsetOf } from "types/graphql";
import { useIsExternalPositionsValuesZero } from "utils/hooks/useIsExternalPositionsValuesZero";
import { useLastSharesBoughtTimestampForAccount } from "utils/hooks/useLastSharesBoughtTimestampForAccount";
import type { SharedSharesWrapperRedemptionInfo } from "utils/hooks/useSharesWrapperRedemptionInfo";

import type { Viem } from "@enzymefinance/sdk/Utils";
import { useSharesBalance } from "../../../utils/hooks/useSharesBalance";
import type { SharedRedemptionQueueRedemptionInfo } from "../../../utils/hooks/useSingleAssetRedemptionQueueInfo";
import { VaultRedeemRedemptionQueueForm } from "./VaultRedeemRedemptionQueueForm";
import { VaultRedeemSharesInKindForm } from "./VaultRedeemSharesInKindForm";
import { VaultRedeemSharesSpecifiedAssetsForm } from "./VaultRedeemSharesSpecifiedAssetsForm";
import { VaultRedeemSharesWrapperForm } from "./VaultRedeemSharesWrapperForm";

interface VaultRedeemSharesModalProps {
  comptrollerProxy: string;
  vault: VaultDetailsFragment;
  isOpen: boolean;
  signerAddress: Address;
  close: () => void;
  sharesWrapperRedemptionInfo?: SharedSharesWrapperRedemptionInfo | null;
  redemptionQueueRedemptionInfo?: SharedRedemptionQueueRedemptionInfo | null;
  loading?: boolean;
}

export function VaultRedeemSharesModal({
  close,
  comptrollerProxy,
  isOpen,
  signerAddress,
  vault,
  sharesWrapperRedemptionInfo,
  redemptionQueueRedemptionInfo,
  loading,
}: VaultRedeemSharesModalProps) {
  const { client } = useNetwork();
  const { currentReleaseId, contracts, environment } = useGlobals();
  const transaction = useTransactionModal();

  const { data: allowedAssetsData, loading: allowedAssetsDataLoading } = useVaultAllowedAssetsForRedemptionQuery({
    variables: { comptroller: comptrollerProxy },
  });

  const vaultFeesQuery = useVaultFeesQuery({
    variables: { comptroller: vault.comptroller.id },
  });

  const vaultProxy = toAddress(vault.id);

  const vaultExitFee = useMemo(() => {
    const exitRateBurnFee = vaultFeesQuery.data?.comptroller?.fees.find(
      (fee) => fee.__typename === "ExitRateBurnFee",
    ) as SubsetOf<FeeFragment, "ExitRateBurnFee"> | undefined;
    const exitRateDirectFee = vaultFeesQuery.data?.comptroller?.fees.find(
      (fee) => fee.__typename === "ExitRateDirectFee",
    ) as SubsetOf<FeeFragment, "ExitRateDirectFee"> | undefined;

    // Note: a vault should only ever have one exit fee
    return exitRateBurnFee ?? exitRateDirectFee;
  }, [vaultFeesQuery.data]);

  const specificAssetsRedemptionsAllowed = useMemo(() => {
    const isCurrentRelease = Utils.Address.safeSameAddress(currentReleaseId, vault.release.id);

    if (!isCurrentRelease) {
      return false;
    }

    const policySettings = allowedAssetsData?.allowedAssetsForRedemptionPolicies[0];

    // If there is no policy, or if it is disabled, all specific assets redemptions are allowed
    if (!policySettings?.enabled) {
      return true;
    }

    const allowedAssets = policySettings.addressLists.flatMap((addressList) => addressList.items);

    return allowedAssets.length > 0;
  }, [allowedAssetsData?.allowedAssetsForRedemptionPolicies, currentReleaseId, vault.release.id]);

  const [inKindRedemption, setInKindRedemption] = useState(!specificAssetsRedemptionsAllowed);

  const startFn = transaction.start;
  const start = useCallback(
    (fn: Viem.PopulatedTransaction<any, any>, address: Address) => {
      startFn(fn, address, vaultProxy);
      close();
    },
    [close, startFn, vaultProxy],
  );

  const { data: lastDepositData } = useLastSharesBoughtTimestampForAccount(client, {
    account: signerAddress,
    comptrollerProxy: toAddress(vault.comptroller.id),
  });

  const sharesActionTimelock = Number(vault.comptroller.sharesActionTimelock);

  const exitFeeWarning = vaultExitFee ? (
    inKindRedemption && Number(vaultExitFee.inKindRate) > 0 ? (
      <Alert
        title={
          <p>
            This vault has an exit fee of{" "}
            <NumberDisplay value={Number(vaultExitFee.inKindRate)} numberFormat={{ style: "percent" }} /> for in kind
            redemptions.
          </p>
        }
        className="space-x-4"
        appearance="info"
      />
    ) : !inKindRedemption && Number(vaultExitFee.specificAssetsRate) > 0 ? (
      <Alert
        title={
          <p>
            This vault has an exit fee of{" "}
            <NumberDisplay value={Number(vaultExitFee.specificAssetsRate)} numberFormat={{ style: "percent" }} /> for
            redemptions in specific assets.
          </p>
        }
        className="space-x-4"
        appearance="info"
      />
    ) : null
  ) : null;

  const lastSharesDepositTimestamp = useMemo(
    () => (typeof lastDepositData === "bigint" ? Number(lastDepositData) : 0),
    [lastDepositData],
  );

  const timelockRemaining = useMemo(() => {
    const now = dayjs().unix();
    const secondsSinceLastSharesAction = now - lastSharesDepositTimestamp;

    return Math.max(sharesActionTimelock - secondsSinceLastSharesAction, 0);
  }, [lastSharesDepositTimestamp, sharesActionTimelock]);
  const [sharesActionTimelockRemaining, setSharesActionTimelockRemaining] = useState(timelockRemaining);

  useInterval(() => {
    const now = dayjs().unix();
    const secondsSinceLastSharesAction = now - lastSharesDepositTimestamp;
    const remaining = Math.max(sharesActionTimelock - secondsSinceLastSharesAction, 0);

    setSharesActionTimelockRemaining(remaining);
  }, 1000);

  const isExternalPositionsValuesZero = useIsExternalPositionsValuesZero(client, {
    valueInterpreter: contracts.ValueInterpreter,
    vault: vaultProxy,
    weth: environment.namedTokens.weth,
  });

  const sharesBalanceQuery = useSharesBalance(client, {
    account: signerAddress,
    vault: vaultProxy,
  });

  // If the connected wallet holds non-wrapped shares, allow them to redeem directly (necessary for redeeming accrued fees which are non-wrapped)
  const bypassSharesWrapper = (sharesBalanceQuery.data ?? 0n) > 0n;
  const supportsSharesWrapper = sharesWrapperRedemptionInfo !== null;

  const supportsRedemptionQueue =
    redemptionQueueRedemptionInfo !== null &&
    (redemptionQueueRedemptionInfo?.shutdown === false ||
      (redemptionQueueRedemptionInfo?.shutdown === true && redemptionQueueRedemptionInfo.requests.length > 0));

  const advancedSection = useMemo(
    () =>
      specificAssetsRedemptionsAllowed && isExternalPositionsValuesZero.data ? (
        <div className="space-y-4">
          <div className="align-center flex justify-between space-x-4">
            <span className="text-base-content text-sm font-medium">Redeem Shares in Kind</span>
            <Toggle
              altText="Redeem Shares in Kind"
              on={inKindRedemption}
              onClick={() => setInKindRedemption(!inKindRedemption)}
            />
          </div>
        </div>
      ) : null,
    [inKindRedemption, isExternalPositionsValuesZero, specificAssetsRedemptionsAllowed],
  );

  const isLoading = allowedAssetsDataLoading || loading;

  return (
    <>
      <TransactionModal {...transaction} />
      <Modal isOpen={isOpen} dismiss={close} title="Redeem">
        {isLoading ? (
          <LoadingScreen />
        ) : sharesActionTimelockRemaining ? (
          <>
            <Modal.Body>
              <Alert appearance="info">
                This vault requires you to hold your shares for at least{" "}
                <DurationDisplay seconds={sharesActionTimelock} /> before redeeming. You will be able to redeem in:
                <div className="font-medium">
                  <DurationDisplay seconds={sharesActionTimelockRemaining} />
                </div>
              </Alert>
            </Modal.Body>
            <Modal.Actions>
              <Button onClick={close}>Dismiss</Button>
            </Modal.Actions>
          </>
        ) : supportsSharesWrapper && sharesWrapperRedemptionInfo && !bypassSharesWrapper ? (
          <VaultRedeemSharesWrapperForm
            close={close}
            comptrollerProxy={comptrollerProxy}
            startTransaction={start}
            sharesWrapperRedemptionInfo={sharesWrapperRedemptionInfo}
          />
        ) : supportsRedemptionQueue && redemptionQueueRedemptionInfo ? (
          <VaultRedeemRedemptionQueueForm
            close={close}
            startTransaction={transaction.start}
            vaultProxy={vaultProxy}
            comptrollerProxy={toAddress(comptrollerProxy)}
            state={transaction.state}
            redemptionQueueRedemptionInfo={redemptionQueueRedemptionInfo}
          />
        ) : inKindRedemption ? (
          <VaultRedeemSharesInKindForm
            close={close}
            exitFeeWarning={exitFeeWarning}
            comptrollerProxy={comptrollerProxy}
            startTransaction={start}
            vaultProxy={vaultProxy}
          >
            {advancedSection}
          </VaultRedeemSharesInKindForm>
        ) : (
          <VaultRedeemSharesSpecifiedAssetsForm
            close={close}
            exitFeeWarning={exitFeeWarning}
            comptrollerProxy={comptrollerProxy}
            startTransaction={start}
            vaultProxy={vaultProxy}
          >
            {advancedSection}
          </VaultRedeemSharesSpecifiedAssetsForm>
        )}
      </Modal>
    </>
  );
}
