import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Notification, Skeleton, useBoolean } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { VaultDepositModal } from "components/vault/deposit/VaultDepositModal";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address, maxUint256 } from "viem";
import { useAssetInfo } from "../../../utils/hooks/useAssetInfo";
import { useNetwork } from "../../providers/NetworkProvider";

export const approve: FunctionConfig<{ spender: Address; amount: Address }> = {
  signature: "function approve(address spender, uint256 amount) returns (bool)",

  Actions({ inputs, isFinished }) {
    const [signerAddress] = useSigner();
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: inputs.spender.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;
    const [isOpen, open, dismiss] = useBoolean();

    if (!(vault && isFinished && signerAddress)) {
      return null;
    }

    return (
      <>
        <Notification.Button onClick={open}>Deposit</Notification.Button>

        <VaultDepositModal
          close={dismiss}
          isOpen={isOpen}
          vault={vault}
          signerAddress={signerAddress}
          showDenominationInvest={true}
        />
      </>
    );
  },

  Description({ address, inputs }) {
    const { client } = useNetwork();
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: inputs.spender.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;
    const tokenQuery = useAssetInfo(client, { asset: address });
    const amount = inputs.amount ? BigInt(inputs.amount) : undefined;
    const isUintMax256 = !!(amount === maxUint256);

    const token = tokenQuery.data;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    if (token === undefined) {
      return <Skeleton className="h-4 w-full" />;
    }

    const decimals = Number(token.decimals);

    const tokens = isUintMax256 ? (
      "all"
    ) : (
      <BigIntDisplay
        numberFormat={{ currency: token.symbol, maximumSignificantDigits: decimals }}
        decimals={decimals}
        value={amount}
      />
    );

    return (
      <>
        Allow{" "}
        {vault?.name ? `the vault ${vault.name} to deposit` : `the contract ${inputs.spender.toLowerCase()} to spend`}{" "}
        {tokens} on your behalf.
      </>
    );
  },

  Label() {
    return <>Approve Spending</>;
  },
};
