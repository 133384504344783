import { Twitter } from "@enzymefinance/icons";
import { ShareIcon, Square2StackIcon } from "@enzymefinance/icons/solid";
import { useToasts } from "@enzymefinance/toast";
import type { ButtonProps } from "@enzymefinance/ui";
import { Button, Menu } from "@enzymefinance/ui";
import { useCallback } from "react";
import { useCopyToClipboard } from "react-use";

import { Link } from "../../routing/Link";
import { useVault } from "../VaultProvider";

export function VaultShare() {
  const [, copyToClipboard] = useCopyToClipboard();
  const { vault } = useVault();
  const { toast } = useToasts();
  const url = location.href;
  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(url);
    toast("Copied to clipboard!", {
      description: "The link to the vault page was copied to the clipboard.",
      kind: "info",
    });
  }, [copyToClipboard, toast, url]);

  const formattedTwitterText = `Check out ${vault.name}!\nAn on-chain vault deployed to @ethereum, powered by @enzymefinance.\n\n${url}`;

  return (
    <Menu placement="bottom-end">
      <Menu.Button<ButtonProps> as={Button} icon={ShareIcon} appearance="tertiary" size="xl">
        Share Vault
      </Menu.Button>
      <Menu.Items>
        <Menu.Group>
          <Menu.Item
            as={Link}
            icon={Twitter}
            to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(formattedTwitterText)}`}
            title="Share vault with the world on Twitter"
          >
            Share on Twitter
          </Menu.Item>
          <Menu.Item as="button" icon={Square2StackIcon} onClick={handleCopyToClipboard}>
            Copy link
          </Menu.Item>
        </Menu.Group>
      </Menu.Items>
    </Menu>
  );
}
