import { ExternalPositionAction, IntegrationAdapterAction } from "@enzymefinance/sdk/Portfolio";
import { Card, SectionHeading, Textarea } from "@enzymefinance/ui";
import { safeStringifyJSON } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useEffect, useState } from "react";
import { useExternalPositionType } from "utils/hooks/useExternalPositionTypeByAddress";
import type { Hex } from "viem";
import type { Inputs } from "../../../../utils/functionsTypes";
import { addTrackedAssets } from "./addTrackedAssets";
import { createExternalPosition } from "./externalPositions/createExternalPosition";
import { reactivateExternalPosition } from "./externalPositions/reactivateExternalPosition";
import { removeExternalPosition } from "./externalPositions/removeExternalPosition";
import { decodeCallOnExternalPositionArgs, getExternalPositionHandler } from "./externalPositions/utils";
import { decodeCallOnIntegrationArgs, getIntegrationHandler } from "./integrations";
import { removeTrackedAssets } from "./removeTrackedAssets";
import type { ExtensionHandler, GetExtensionHandlerDependencies } from "./types";

interface CallOnExtensionArgs {
  extension: string;
  actionId: bigint;
  callArgs: Hex;
}
export function callOnExtensionSummary<TArgs>(
  Label: ExtensionHandler<TArgs>["Label"],
  Description: ExtensionHandler<TArgs>["Description"],
) {
  type Summary = Required<ExtensionHandler<TArgs>>["Summary"];
  const DefaultSummary: Summary = ({ args }) => (
    <div>
      <SectionHeading.Subtitle>
        <Label args={args} />
      </SectionHeading.Subtitle>

      <div className="text-base-content text-sm">
        <Description args={args} />
      </div>
    </div>
  );

  return DefaultSummary;
}

export function getDefaultExtensionSummary<TArgs>(
  Label: ExtensionHandler<TArgs>["Label"],
  Description: ExtensionHandler<TArgs>["Description"],
) {
  type Summary = Required<ExtensionHandler<TArgs>>["Summary"];
  const DefaultSummary: Summary = ({ args }) => (
    <Card appearance="secondary">
      <Card.Content className="flex flex-col space-y-4">
        <SectionHeading.Subtitle>
          <Label args={args} />
        </SectionHeading.Subtitle>

        <div className="text-base-content text-sm">
          <Description args={args} />
        </div>
      </Card.Content>
    </Card>
  );

  return DefaultSummary;
}

async function getExtensionHandler(
  { extension, actionId, callArgs }: CallOnExtensionArgs,
  dependencies: GetExtensionHandlerDependencies,
): Promise<ExtensionHandler> {
  if (!dependencies.contracts) {
    return getUnknownExtensionHandler(actionId);
  }

  switch (extension.toLowerCase()) {
    case dependencies.contracts.IntegrationManager.toLowerCase():
      switch (actionId) {
        case IntegrationAdapterAction.CallOnIntegration: {
          const callOnIntegrationArgs = decodeCallOnIntegrationArgs(callArgs);

          const { decodeIntegrationArgs, ...integrationHandler } = getIntegrationHandler(
            callOnIntegrationArgs,
            dependencies,
          );

          const extensionHandler: ExtensionHandler = {
            ...integrationHandler,
            decodeExtensionArgs: (args) => decodeIntegrationArgs(decodeCallOnIntegrationArgs(args).encodedCallArgs),
          };

          return extensionHandler;
        }
        case IntegrationAdapterAction.AddTrackedAssets:
          return addTrackedAssets;
        case IntegrationAdapterAction.RemoveTrackedAssets:
          return removeTrackedAssets;

        default:
          return getUnknownExtensionHandler(actionId);
      }

    case dependencies.contracts.ExternalPositionManager.toLowerCase():
      switch (actionId) {
        case ExternalPositionAction.CreateExternalPosition:
          return createExternalPosition;
        case ExternalPositionAction.ReactivateExternalPosition:
          return reactivateExternalPosition;
        case ExternalPositionAction.RemoveExternalPosition:
          return removeExternalPosition;
        case ExternalPositionAction.CallOnExternalPosition: {
          const callOnExternalPositionArgs = decodeCallOnExternalPositionArgs(callArgs);
          const { decodeExternalPositionArgs, ...externalPositionHandler } = await getExternalPositionHandler(
            callOnExternalPositionArgs,
            dependencies,
          );
          const extensionHandler: ExtensionHandler = {
            ...externalPositionHandler,
            decodeExtensionArgs: () => decodeExternalPositionArgs(callOnExternalPositionArgs.actionArgs),
          };

          return extensionHandler;
        }
        default:
          return getUnknownExtensionHandler(actionId);
      }

    default:
      return getUnknownExtensionHandler(actionId);
  }
}

interface WrappedExtensionHandler {
  loading: boolean;
  error?: Error;
  handler?: ExtensionHandler;
}

export function useWrappedExtensionHandler(inputs: Inputs): WrappedExtensionHandler {
  const [status, setStatus] = useState<{
    loading: boolean;
    error?: Error;
    handler?: ExtensionHandler;
  }>({
    loading: false,
  });

  const extension = inputs.extension;
  const actionId = inputs.actionId;
  const callArgs = inputs.callArgs;

  const getExternalPositionTypeByAddress = useExternalPositionType();
  const { contracts, getAdapterByAddress } = useGlobals();

  useEffect(() => {
    const dependencies: GetExtensionHandlerDependencies = {
      contracts,
      getAdapterByAddress,
      getExternalPositionTypeByAddress,
    };

    async function getExtension() {
      setStatus({ loading: true });

      try {
        const handler = await getExtensionHandler({ actionId, callArgs, extension }, dependencies);

        setStatus({ handler, loading: false });
      } catch (error) {
        setStatus({ error, handler: undefined, loading: false });
      }
    }

    getExtension();
  }, [actionId, callArgs, contracts, extension, getAdapterByAddress, getExternalPositionTypeByAddress]);

  return status;
}

function getUnknownExtensionHandler(actionId: bigint): ExtensionHandler<{ actionId: bigint; rawArgs: any }> {
  return {
    Description({ args: { rawArgs } }) {
      return (
        <Textarea
          label="Raw args"
          id="default-extension-handler"
          readOnly={true}
          rows={8}
          value={safeStringifyJSON(rawArgs, 2)}
        />
      );
    },
    Label({ args }) {
      return <>Unknown actionId={args.actionId.toString()} for callOnExtension</>;
    },
    decodeExtensionArgs: (rawArgs) => ({
      actionId,
      rawArgs,
    }),
  };
}
