import { Performance } from "@enzymefinance/sdk";
import { getActiveExternalPositions, getExternalPositionAssets } from "@enzymefinance/sdk/Portfolio";
import { address as addressSchema, asset } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useIsExternalPositionsValuesZero = createUseValidatedClientQuery(
  ["active-external-positions-total-value"],
  {
    paramsSchema: z.object({
      weth: asset(),
      vault: addressSchema(),
      valueInterpreter: addressSchema(),
    }),
    responseSchema: z.boolean(),
    queryFn: async (params, client) => {
      const activeExternalPositions = await getActiveExternalPositions(client, {
        vaultProxy: params.vault,
      });
      const values = await Promise.all(
        activeExternalPositions.map(async (address) => {
          const { managedAssets, debtAssets } = await getExternalPositionAssets(client, {
            externalPosition: address,
          });

          const [managedValue, debtValue] = await Promise.all([
            Performance.calcCanonicalAssetsTotalValue(client, {
              valueInterpreter: params.valueInterpreter,
              baseAssets: managedAssets.map((asset) => asset.asset),
              amounts: managedAssets.map((asset) => asset.amount),
              quoteAsset: params.weth.id,
            }),
            Performance.calcCanonicalAssetsTotalValue(client, {
              valueInterpreter: params.valueInterpreter,
              baseAssets: debtAssets.map((asset) => asset.asset),
              amounts: debtAssets.map((asset) => asset.amount),
              quoteAsset: params.weth.id,
            }),
          ]);

          if (typeof managedValue === "bigint" && typeof debtValue === "bigint" && managedValue > debtValue) {
            return managedValue - debtValue;
          }

          return 0n;
        }),
      );

      const totalValue = values.reduce((acc, value) => acc + value, 0n);

      return totalValue === 0n;
    },
  },
);
