import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperRedeemFromQueue: FunctionConfig<{ startIndex: bigint; endIndex: bigint }> = {
  signature: "function redeemFromQueue(uint256 startIndex, uint256 endIndex)",

  Description() {
    return <>Execute redemption requests</>;
  },

  Label() {
    return <>Execute redemptions on Shares Wrapper</>;
  },
};
