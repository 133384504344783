import { Title } from "@enzymefinance/ui";
import { PageLayout } from "components/layout/PageLayout";
import type { ComponentPropsWithoutRef, ComponentType, ReactElement } from "react";

export interface FeedbackPageProps {
  actions?: ReactElement | null;
  description?: ReactElement | string;
  image?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  title?: string;
}

export function FeedbackPage({
  actions,
  description,
  image: Image,
  title = "Oops! Something went wrong!",
}: FeedbackPageProps) {
  return (
    <PageLayout className="flex flex-1 flex-col justify-center">
      <div className="space-y-4 px-4 sm:space-y-8">
        {Image ? (
          <div className="flex justify-center">
            <Image className="h-auto max-w-xs sm:max-w-sm" />
          </div>
        ) : null}
        <Title className="text-center">{title}</Title>
        <div className="px-4">
          {description === undefined ? null : (
            <div className="text-base-content mx-auto max-w-4xl text-center font-medium sm:text-lg">{description}</div>
          )}
        </div>
        {actions ? (
          <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            {actions}
          </div>
        ) : null}
      </div>
    </PageLayout>
  );
}
