import type { IconLabelProps } from "@enzymefinance/ui";
import { IconLabel } from "@enzymefinance/ui";

import type { BaseIconProps } from "../base-icon/BaseIcon.js";
import { BaseIcon } from "../base-icon/BaseIcon.js";

export interface ProtocolLabelProps
  extends Omit<IconLabelProps, "size" | "title">,
    Pick<BaseIconProps, "id" | "size" | "url"> {
  kind?: "default" | "definition" | "option";
  name: string;
  reverse?: boolean;
  url?: string;
}

export function ProtocolLabel({
  description,
  id,
  kind,
  loading,
  name,
  reverse = false,
  size,
  url,
  ...props
}: ProtocolLabelProps) {
  return (
    <IconLabel
      reverse={reverse}
      description={
        kind === "definition" || kind === "option" ? <span aria-hidden={true}>{description}</span> : description
      }
      icon={
        <BaseIcon
          alt={kind === "definition" || kind === "option" ? "" : undefined}
          className="bg-base-300 overflow-hidden rounded-full"
          loading={loading}
          size={size}
          title={name}
          url={url}
        />
      }
      loading={loading}
      space={size === 4 || size === 5 ? 2 : 4}
      title={kind === "option" ? <span id={`option-${id}-label`}>{name}</span> : name}
      {...props}
    />
  );
}
