import { isNonZeroAddress } from "@enzymefinance/environment";
import { Address } from "@enzymefinance/ethereum-ui";
import { Utils } from "@enzymefinance/sdk";
import { Skeleton } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { entranceRateDirectFee } from "components/vault/config/fees/EntranceDirectFee";
import { exitRateDirectFee } from "components/vault/config/fees/ExitDirectFee";
import { managementFee } from "components/vault/config/fees/ManagementFee";
import { performanceFee } from "components/vault/config/fees/PerformanceFee";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import { useMemo } from "react";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address as AddressType } from "viem";

export const setRecipientForFund: FunctionConfig<{ comptrollerProxy: AddressType; recipient: AddressType }> = {
  signature: "function setRecipientForFund(address comptrollerProxy, address recipient)",

  Description({ address, inputs }) {
    const { contracts } = useGlobals();
    const { network } = useNetwork();
    const feeConfig = useMemo(() => {
      return [managementFee, performanceFee, entranceRateDirectFee, exitRateDirectFee].find((fee) =>
        Utils.Address.safeSameAddress(fee.address(contracts), address),
      );
    }, [address, contracts]);
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: inputs.comptrollerProxy.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;
    const recipient = isNonZeroAddress(inputs.recipient) ? inputs.recipient : undefined;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Set recipient for <b>{feeConfig?.label ?? "Unknown fee"}</b> on <b>{vault?.name ?? "this vault"}</b> to{" "}
        {isNonZeroAddress(recipient) ? <Address address={recipient} network={network} /> : "the vault owner"}
      </>
    );
  },

  Label() {
    return <>Set recipient for vault</>;
  },
};
