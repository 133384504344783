import type { Address } from "@enzymefinance/environment";
import { Badge, Button, NumberDisplay, Tooltip } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency, useCurrencySlug } from "components/settings/Settings";
import { useRewardsForWalletQuery, useVaultPerformanceLatestQuery } from "queries/backend";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { client } from "utils/backend";
import { untrackedValueThreshold } from "utils/vault";

interface UnclaimedFeesWarningProps {
  vaultId: Address;
}

export function UnclaimedFeesWarning({ vaultId }: UnclaimedFeesWarningProps) {
  const { deployment } = useNetwork();

  const currency = useCurrency();
  const currencySlug = useCurrencySlug();

  const vaultPerformanceLatest = useVaultPerformanceLatestQuery({
    client,
    variables: { currency: currencySlug, network: deployment, vault: vaultId },
  });
  const rewardsForWalletQuery = useRewardsForWalletQuery({
    client,
    variables: { address: vaultId, currency: currencySlug, network: deployment },
  });

  const totalRewards = useMemo(() => {
    const allRewards = rewardsForWalletQuery.data?.rewardsForWallet ?? [];

    const currencyValues = allRewards.flatMap((reward) => {
      const claimable = reward.globalRewards?.map((globalReward) => globalReward.value);

      return claimable ?? [];
    });

    const totalCurrencyValue = currencyValues.reduce((previous, current) => previous + current, 0);

    return totalCurrencyValue;
  }, [rewardsForWalletQuery.data]);

  const aum = vaultPerformanceLatest.data?.vaultPerformanceLatest?.grossAssetValue || Number.POSITIVE_INFINITY;

  const rewardsAumPercentage = totalRewards / aum;
  const rewardsExceedThreshold = rewardsAumPercentage > untrackedValueThreshold;

  return rewardsExceedThreshold ? (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge appearance="warning">High Unclaimed Rewards</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="flex max-w-xs flex-col space-y-4 p-4 text-sm font-normal">
          <div className="space-y-2">
            <span>
              Your vault has <NumberDisplay value={totalRewards} numberFormat={{ currency }} /> in unclaimed rewards (
              <NumberDisplay numberFormat={{ style: "percent" }} value={rewardsAumPercentage} /> of the vault&apos;s
              AUM).
            </span>
            <p>
              Unclaimed rewards are not included in the share price, and therefore make the vault vulnerable to
              arbitrage.
            </p>
            <p>We recommend you claim your rewards in the relevant section.</p>
          </div>

          <Button as={Link} to={`/vault/${vaultId}/protocols`}>
            DeFi Protocols
          </Button>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}
