import type { Address, CurrencySlug, PrimitiveAsset } from "@enzymefinance/environment";
import { AssetType, isAddress, toAddress } from "@enzymefinance/environment";
import { Utils } from "@enzymefinance/sdk";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useSettings } from "components/settings/Settings";
import { useAssetPricesQuery } from "queries/backend";
import type { VaultDetailsFragment } from "queries/core";
import { useVaultAssetManagersQuery, useVaultDetailsQuery } from "queries/core";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { client } from "utils/backend";
import type { AssetPrice } from "../providers/AssetPricesProvider";

interface VaultContextValues {
  comptrollerProxy: Address;
  denominationAsset: PrimitiveAsset;
  id: Address;
  isAssetManager: boolean;
  isVaultManager: boolean;
  isOwner: boolean;
  ownerAddress: Address;
  vault: VaultDetailsFragment;
  vaultCurrency: CurrencySlug | Address;
  vaultCurrencyLabel: string;
  vaultAssetPrices: AssetPrice[];
}

const VaultContext = createContext<VaultContextValues | undefined>(undefined);

export function useVault() {
  const context = useContext(VaultContext);

  if (!context) {
    throw new Error("Missing vault context");
  }

  return context;
}

interface VaultProviderProps {
  children?: ReactNode;
  fallback: ReactNode;
}

export function VaultProvider({ children, fallback }: VaultProviderProps) {
  const [signerAddress, signerIsLoading] = useSigner();
  const { environment } = useGlobals();
  const { deployment } = useNetwork();

  const { id: rawId } = useParams<"id">();
  const lowerCaseId = rawId?.toLowerCase();
  const id = isAddress(lowerCaseId) ? lowerCaseId : undefined;

  const vaultQuery = useVaultDetailsQuery({ skip: !id, variables: id ? { id: id.toLowerCase() } : undefined });
  const assetManagersQuery = useVaultAssetManagersQuery({
    skip: !id,
    variables: id ? { id: id.toLowerCase() } : undefined,
  });

  const { vaultMetricsInDA, currencySlug, currency } = useSettings();

  const denominationAssetAddress = vaultQuery.data?.vault?.comptroller.denomination.id;

  const assetPricesQuery = useAssetPricesQuery({
    client,
    variables: denominationAssetAddress
      ? {
          currency: vaultMetricsInDA ? toAddress(denominationAssetAddress) : currencySlug,
          network: deployment,
        }
      : undefined,
    skip: !denominationAssetAddress,
  });

  const isLoading = assetManagersQuery.loading || vaultQuery.loading || signerIsLoading || assetPricesQuery.loading;
  const vaultContext = useMemo(() => {
    const vault = vaultQuery.data?.vault;

    if (!(vault && id)) {
      return null;
    }

    const ownerAddress = toAddress(vault.owner.id);
    const comptrollerProxy = toAddress(vault.comptroller.id);

    const isOwner = Utils.Address.safeSameAddress(signerAddress, ownerAddress);
    const isAssetManager = !!assetManagersQuery.data?.vault?.assetManagers.some((assetManager) =>
      Utils.Address.safeSameAddress(signerAddress, assetManager.id),
    );

    const denominationAsset = environment.getAsset(vault.comptroller.denomination.id);

    if (denominationAsset.type !== AssetType.PRIMITIVE) {
      throw new Error("Asset is not primitive");
    }

    const vaultCurrency = vaultMetricsInDA ? denominationAsset.id : currencySlug;
    const vaultCurrencyLabel = vaultMetricsInDA ? denominationAsset.symbol : currency;

    const vaultAssetPrices =
      assetPricesQuery.data?.assetPrices.map((assetPrice) => ({
        change24h: assetPrice.change24h ?? undefined,
        id: toAddress(assetPrice.id),
        price: assetPrice.price ?? undefined,
      })) ?? [];

    return {
      vaultAssetPrices,
      vaultCurrency,
      vaultCurrencyLabel,
      comptrollerProxy,
      denominationAsset,
      id,
      isAssetManager,
      isOwner,
      isVaultManager: isOwner || isAssetManager,
      ownerAddress,
      vault: vault as VaultDetailsFragment,
    };
  }, [
    assetPricesQuery.data,
    assetManagersQuery.data?.vault?.assetManagers,
    environment,
    id,
    signerAddress,
    vaultQuery.data?.vault,
    currency,
    vaultMetricsInDA,
  ]);

  const compromised =
    Utils.Address.safeSameAddress(vaultContext?.vault.id, "0xeef8f079d4149be5125d5fe36c7be8810c02d6ef") &&
    (vaultContext?.isOwner || vaultContext?.isAssetManager);

  if (isLoading || compromised) {
    return <LoadingScreen />;
  }

  if (!vaultContext) {
    return <>{fallback}</>;
  }

  return <VaultContext.Provider value={vaultContext}>{children}</VaultContext.Provider>;
}
