import type { ApolloClient } from "@apollo/client";
import type { QueryClient } from "@tanstack/react-query";
import type { Observable } from "rxjs";
import { EMPTY, defer, firstValueFrom, timer } from "rxjs";
import { expand, skipWhile, switchMapTo, take } from "rxjs/operators";
import type { Interpreter, MachineConfig, MachineOptions } from "xstate";
import { assign, createMachine, sendParent } from "xstate";

import { toAddress } from "@enzymefinance/environment";
import { Utils } from "@enzymefinance/sdk";
import type { PublicClient, TransactionReceipt } from "viem";
import type { Transaction } from "./TransactionManagerMachine";
import { EventType as ParentEventType, TransactionStatus } from "./TransactionManagerMachine";

export type MachineInterpreter = Interpreter<Context, any, Events>;

type Context = Transaction;
type Events = AcknowledgeEvent | RetryEvent;

export enum EventType {
  ACKNOWLEDGED = "ACKNOWLEDGED",
  RETRY = "RETRY",
}

interface AcknowledgeEvent {
  type: EventType.ACKNOWLEDGED;
}

interface RetryEvent {
  type: EventType.RETRY;
}

const machineDeclaration: MachineConfig<Context, any, Events> = {
  id: "transaction",
  initial: "unknown",
  states: {
    acknowledged: {
      entry: ["setAcknowledged", "sendRemove"],
      type: "final",
    },
    error: {
      on: {
        [EventType.RETRY]: "pending",
      },
    },
    finished: {
      entry: ["setFinished", "sendUpdate"],
      on: {
        [EventType.ACKNOWLEDGED]: "acknowledged",
      },
    },
    indexing: {
      always: {
        cond: "skipIndexing",
        target: "finished",
      },
      entry: ["setPassed", "sendUpdate"],
      invoke: {
        onDone: {
          actions: ["invalidateApolloCache"],
          target: "finished",
        },
        onError: "error",
        src: "waitForIndexing",
      },
      on: {
        [EventType.ACKNOWLEDGED]: "acknowledged",
      },
    },
    pending: {
      entry: ["setPending", "sendUpdate"],
      invoke: [
        {
          onDone: {
            actions: ["invalidateReactQueryCache"],
            target: "replaced",
          },
          onError: "error",
          src: "waitForReplaced",
        },
        {
          onDone: [
            {
              actions: ["assignBlock", "invalidateReactQueryCache"],
              cond: "isReverted",
              target: "reverted",
            },
            {
              actions: ["assignBlock", "assignLogs", "invalidateReactQueryCache"],
              target: "indexing",
            },
          ],
          onError: "error",
          src: "waitForReceipt",
        },
      ],
      on: {
        [EventType.ACKNOWLEDGED]: "acknowledged",
      },
    },
    replaced: {
      entry: ["setReplaced", "sendUpdate"],
      on: {
        [EventType.ACKNOWLEDGED]: "acknowledged",
      },
    },
    reverted: {
      entry: ["setReverted", "sendUpdate"],
      on: {
        [EventType.ACKNOWLEDGED]: "acknowledged",
      },
    },
    unknown: {
      always: [
        {
          cond: "isStatusReplaced",
          target: "replaced",
        },
        {
          cond: "isStatusReverted",
          target: "reverted",
        },
        {
          cond: "isStatusPassed",
          target: "indexing",
        },
        {
          cond: "isStatusFinished",
          target: "finished",
        },
        {
          cond: "isStatusAcknowledged",
          target: "acknowledged",
        },
        {
          target: "pending",
        },
      ],
    },
  },
};

export function createTransactionMachine(
  client: PublicClient,
  transaction: Transaction,
  subgraphObservable: Observable<number>,
  apolloObservable: Observable<ApolloClient<any>>,
  reactQueryObservable: Observable<QueryClient>,
) {
  const machineOptions: Partial<MachineOptions<Context, Events>> = {
    actions: {
      assignBlock: assign<Context, any>({
        block: (_, event) => {
          return (event.data as TransactionReceipt).blockNumber;
        },
      }),
      assignLogs: assign<Context, any>({
        logs: (_, event) => {
          return (event.data as TransactionReceipt).logs;
        },
      }),
      invalidateApolloCache: () => {
        apolloObservable.pipe(take(1)).subscribe(async (cache) => cache.reFetchObservableQueries());
      },
      invalidateReactQueryCache: () => {
        reactQueryObservable.pipe(take(1)).subscribe(async (cache) =>
          cache.invalidateQueries({
            type: "all",
          }),
        );
      },
      sendRemove: sendParent((context) => ({ transaction: context, type: ParentEventType.REMOVE })),
      sendUpdate: sendParent((context) => ({ transaction: context, type: ParentEventType.UPDATE })),
      setAcknowledged: assign<Context, Events>({ status: TransactionStatus.ACKNOWLEDGED }),
      setFinished: assign<Context, Events>({ status: TransactionStatus.FINISHED }),
      setPassed: assign<Context, Events>({ status: TransactionStatus.PASSED }),
      setPending: assign<Context, Events>({ status: TransactionStatus.PENDING }),
      setReplaced: assign<Context, Events>({ status: TransactionStatus.REPLACED }),
      setReverted: assign<Context, Events>({ status: TransactionStatus.REVERTED }),
    },
    guards: {
      isReverted: (_, event: any) => {
        const value = event.data as TransactionReceipt;

        return !value.status;
      },

      isStatusAcknowledged: (context) => context.status === TransactionStatus.ACKNOWLEDGED,

      isStatusFinished: (context) => context.status === TransactionStatus.FINISHED,

      isStatusPassed: (context) => context.status === TransactionStatus.PASSED,

      isStatusReplaced: (context) => context.status === TransactionStatus.REPLACED,

      isStatusReverted: (context) => context.status === TransactionStatus.REVERTED,
      // TODO: Implement logic to skip indexing in case of untracked transactions (e.g. approve()).
      skipIndexing: () => false,
    },
    services: {
      waitForIndexing: async (context) => {
        const block = context.block;

        if (!block) {
          throw new Error("Missing block number");
        }

        const observable = subgraphObservable.pipe(
          skipWhile((indexed) => indexed < block),
          take(1),
        );

        await firstValueFrom(observable);
      },
      waitForReceipt: (context) => client.waitForTransactionReceipt({ hash: Utils.Hex.asHex(context.hash) }),
      waitForReplaced: async (context) => {
        const replaced$ = defer(async () => {
          const count = await client.getTransactionCount({ address: toAddress(context.from) });

          if (!context.isGasRelayer && count - 1 > context.nonce) {
            return true;
          }

          return false;
        });

        const polling$ = replaced$.pipe(
          expand((replaced) => {
            return replaced ? EMPTY : timer(30000).pipe(switchMapTo(replaced$));
          }),
        );

        return polling$.pipe(skipWhile((replaced) => !replaced)).toPromise();
      },
    },
  };

  return createMachine<Context, Events>(machineDeclaration, machineOptions).withContext(transaction);
}
