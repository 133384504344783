import { QuestionMarkCircleIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import type {
  ComponentPropsWithoutRef,
  ComponentType,
  Dispatch,
  ElementType,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
} from "react";
import { createContext, createElement, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useUnmount } from "react-use";

import { Icon } from "../elements/Icon.js";
import type { PopperProps } from "./popper/Popper.js";
import { Popper } from "./popper/Popper.js";
import { PopperArrow } from "./popper/PopperArrow.js";
import type { PopperConfig } from "./popper/PopperProvider.js";
import { PopperProvider, usePopper } from "./popper/PopperProvider.js";

interface TooltipContextValues {
  delay: number;
  disabled: boolean;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  timeout: MutableRefObject<number | undefined>;
}

const TooltipContext = createContext<TooltipContextValues | undefined>(undefined);

export function useTooltip() {
  const context = useContext(TooltipContext);

  if (!context) {
    throw new Error("Missing tooltip context");
  }

  return context;
}

export interface TooltipProviderProps extends PopperConfig {
  children: ReactNode;
  delay?: number;
  disabled?: boolean;
}

export function TooltipProvider({
  children,
  delay = 500,
  distance,
  disabled = false,
  modifiers,
  placement = "top",
  skidding,
  ...props
}: TooltipProviderProps) {
  const [isOpen, setOpen] = useState(false);
  const timeout = useRef<number | undefined>(undefined);
  const value = useMemo(() => ({ delay, disabled, isOpen, setOpen, timeout }), [delay, disabled, isOpen]);

  return (
    <TooltipContext.Provider value={value} {...props}>
      <PopperProvider distance={distance} modifiers={modifiers} placement={placement} skidding={skidding}>
        {children}
      </PopperProvider>
    </TooltipContext.Provider>
  );
}

Tooltip.Provider = TooltipProvider;

Tooltip.Icon = Icon;

interface TooltipProps extends PopperConfig {
  children: ReactNode;
  delay?: number;
  disabled?: boolean;
  icon?: ComponentType<ComponentPropsWithoutRef<"svg">> | null;
  iconClassName?: string;
  label?: ReactNode;
}

export function Tooltip({
  children,
  icon = QuestionMarkCircleIcon,
  iconClassName,
  distance,
  modifiers,
  placement = "top",
  skidding,
  label,
  ...props
}: TooltipProps) {
  return (
    <PopperProvider distance={distance} modifiers={modifiers} placement={placement} skidding={skidding}>
      <Tooltip.Provider {...props}>
        <Tooltip.Item className="inline-flex items-center space-x-1">
          {typeof label === "string" ? <p className="text-base-content truncate text-sm">{label}</p> : label}
          {icon ? <Tooltip.Icon icon={icon} className={iconClassName} /> : null}
        </Tooltip.Item>
        <Tooltip.Panel>
          <span className="block max-w-xs">{children}</span>
        </Tooltip.Panel>
      </Tooltip.Provider>
    </PopperProvider>
  );
}

export type TooltipItemProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = Omit<
  TProps,
  "as" | "children"
> &
  PropsWithChildren<{ as?: ElementType }>;

function tooltipItem<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  as = "div",
  children,
  ...props
}: TooltipItemProps<TProps>) {
  const { delay, setOpen, timeout } = useTooltip();
  const { reference } = usePopper();
  const handleMouseEnter = useCallback<NonNullable<TooltipItemProps["onMouseEnter"]>>(() => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => setOpen(true), delay);
  }, [delay, setOpen, timeout]);

  const handleMouseLeave = useCallback<NonNullable<TooltipItemProps["onMouseLeave"]>>(() => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => setOpen(false), delay);
  }, [delay, setOpen, timeout]);

  useUnmount(() => window.clearTimeout(timeout.current));

  return createElement(
    as,
    {
      ...props,
      ...reference,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
    children,
  );
}

Tooltip.Item = tooltipItem;

export type TooltipButtonProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">> = Omit<
  TProps,
  "as"
> &
  PropsWithChildren<{ as?: ElementType }>;

function tooltipButton<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">>({
  as = "button",
  children,
  onClick,
  onMouseLeave,
  ...props
}: TooltipButtonProps<TProps>) {
  const { delay, setOpen, timeout, isOpen } = useTooltip();
  const { reference } = usePopper();
  const handleClick = useCallback<NonNullable<TooltipButtonProps["onClick"]>>(
    (event) => {
      onClick?.(event);
      setOpen(!isOpen);

      window.clearTimeout(timeout.current);
    },
    [isOpen, onClick, setOpen, timeout],
  );
  const handleMouseLeave = useCallback<NonNullable<TooltipButtonProps["onClick"]>>(
    (event) => {
      onMouseLeave?.(event);

      timeout.current = window.setTimeout(() => setOpen(false), delay);
    },
    [delay, onMouseLeave, setOpen, timeout],
  );

  useUnmount(() => window.clearTimeout(timeout.current));

  return createElement(
    as,
    {
      className: "focus:outline-none",
      ...props,
      type: "button",
      ...reference,
      onClick: handleClick,
      onMouseLeave: handleMouseLeave,
    },
    children,
  );
}

Tooltip.Button = tooltipButton;

export type TooltipCardProps = ComponentPropsWithoutRef<"div">;

function tooltipCard({ className, ...props }: TooltipCardProps) {
  const classes = classNames(
    "bg-primary text-sm font-medium text-white overflow-hidden shadow-lg rounded-lg px-3 py-2",
    className,
  );

  return <div className={classes} {...props} />;
}

Tooltip.Card = tooltipCard;

export type TooltipPanelProps<TProps extends HTMLAttributes<HTMLElement> = TooltipCardProps> = Omit<TProps, "as"> & {
  arrow?: PopperProps["arrow"];
  as?: ElementType;
  children: ReactNode;
};

function tooltipPanel<TProps extends HTMLAttributes<HTMLElement> = TooltipCardProps>({
  arrow = <PopperArrow className="border-primary dark:border-primary" />,
  as = tooltipCard,
  onMouseEnter: _,
  onMouseLeave: __,
  children,
  ...props
}: TooltipPanelProps<TProps>) {
  const { timeout, delay, disabled, isOpen, setOpen } = useTooltip();

  const handleMouseEnter = useCallback<NonNullable<TooltipItemProps["onMouseEnter"]>>(() => {
    window.clearTimeout(timeout.current);
  }, [timeout]);

  const handleMouseLeave = useCallback<NonNullable<TooltipItemProps["onMouseLeave"]>>(() => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => setOpen(false), delay);
  }, [delay, setOpen, timeout]);

  useUnmount(() => window.clearTimeout(timeout.current));

  return (
    <Popper
      arrow={arrow}
      className="z-30"
      onClick={(event) => event.stopPropagation()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isOpen={isOpen && !disabled}
    >
      {createElement(as, { ...props, role: "tooltip" }, children)}
    </Popper>
  );
}

Tooltip.Panel = tooltipPanel;
