import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

// TODO: Retrieve name of policy from address, and possibly decode settings data
export const enablePolicyForFund: FunctionConfig<{ comptrollerProxy: Address; policy: Address; settingsData: Hex }> = {
  signature: "function enablePolicyForFund(address comptrollerProxy, address policy, bytes calldata settingsData)",

  Description({ inputs }) {
    const policyAddress = inputs.policy;

    return <>Enable policy at address {policyAddress}.</>;
  },

  Label() {
    return <>Enable Policy</>;
  },
};
