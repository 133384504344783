import type { LinkProps } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function getQuery(network: string | null) {
  if (network) {
    return `?network=${network}`;
  }

  return "";
}

export function useNetworkSearchParam(to: LinkProps["to"]) {
  const [searchParams] = useSearchParams();
  const network = searchParams.get("network");

  const query = getQuery(network);

  if (!query || typeof to !== "string") {
    return "";
  }

  return query;
}
