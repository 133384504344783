import classNames from "classnames";
import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";

import { WhiteLabelCoverForm } from "./WhiteLabelCoverForm";
import { WhiteLabelLogoForm } from "./WhiteLabelLogoForm";

export const defaultAppearance = "default";
const thresHold = 72;

export interface WhiteLabelNavigationWrapperProps {
  appearance?: "default" | "home";
  children?: ReactNode;
}

export function WhiteLabelNavigationWrapper({
  appearance = defaultAppearance,
  children,
}: WhiteLabelNavigationWrapperProps) {
  const [isScrollTop, setIsScrollTop] = useState(document.documentElement.scrollTop < thresHold);
  const scrollHandler = useCallback(() => {
    if (appearance === "home") {
      return;
    }

    // Handle scroll top state
    const isTop = document.documentElement.scrollTop < thresHold;

    if (isTop !== isScrollTop) {
      setIsScrollTop(isTop);
    }
  }, [appearance, isScrollTop]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, [appearance, isScrollTop, scrollHandler]);

  const classes = classNames("grid-cols-24 grid gap-1 py-3 lg:gap-4 lg:py-4 translate", {
    "lg:h-48": appearance === "default",
    "lg:h-80": appearance === "home",
  });

  return (
    <div className={classes}>
      <WhiteLabelCoverForm appearance={appearance} isScrollTop={isScrollTop}>
        <div className="col-span-8 row-span-2 flex items-center space-x-3 lg:col-span-6 lg:items-end">
          <div className="relative">
            <WhiteLabelLogoForm appearance={appearance} isScrollTop={isScrollTop} />
          </div>
        </div>
        {children}
      </WhiteLabelCoverForm>
    </div>
  );
}
