import type { WalletDetailsParams } from "@rainbow-me/rainbowkit";
import { type Address, type EIP1193RequestFn, type Transport, type WalletRpcSchema, custom, getAddress } from "viem";
import { createConnector, normalizeChainId } from "wagmi";

export type MockParameters = {
  accounts: readonly [Address, ...Address[]];
  chainId?: number;
  connected?: boolean;
  walletDetails: WalletDetailsParams;
  onChainChanged?: (chainId: number) => void;
  onAccountsChanged?: (accounts: readonly Address[]) => void;
  onConnectedChanged?: (connected: boolean) => void;
};

// inspired by mock from wagmi/connectors
mockConnector.type = "mock" as const;
export function mockConnector(parameters: MockParameters) {
  type Provider = ReturnType<Transport<"custom", {}, EIP1193RequestFn<WalletRpcSchema>>>;
  let connected = parameters.connected ?? false;
  let connectedChainId: number;
  let connectedAccounts = [...parameters.accounts];

  return createConnector<Provider>((config) => ({
    id: "mock",
    name: "Mock Connector",
    type: mockConnector.type,
    async setup() {
      const defaultChainId = config.chains[0].id;
      connectedChainId =
        parameters.chainId === undefined
          ? defaultChainId
          : config.chains.find((item) => item.id === parameters.chainId)?.id ?? defaultChainId;
    },
    async connect({ chainId } = {}) {
      const accounts = await this.getAccounts();

      let currentChainId = await this.getChainId();
      if (chainId && currentChainId !== chainId) {
        if (this.switchChain === undefined) {
          throw new Error("Switch chain is undefined");
        }
        const chain = await this.switchChain({ chainId });
        currentChainId = chain.id;
      }

      connected = true;
      if (parameters.onConnectedChanged) {
        parameters.onConnectedChanged(connected);
      }

      return { accounts, chainId: currentChainId };
    },
    // biome-ignore lint/suspicious/useAwait: <explanation>
    async disconnect() {
      this.onDisconnect();
    },
    // biome-ignore lint/suspicious/useAwait: <explanation>
    async getAccounts() {
      return connectedAccounts;
    },
    // biome-ignore lint/suspicious/useAwait: <explanation>
    async getChainId() {
      return connectedChainId;
    },
    async isAuthorized() {
      if (!connected) {
        return false;
      }
      const accounts = await this.getAccounts();
      return !!accounts.length;
    },
    async switchChain({ chainId }) {
      const chain = config.chains.find((x) => x.id === chainId);
      if (chain === undefined) {
        throw new Error(`ChainId not found: ${chainId}`);
      }
      connectedChainId = chainId;

      this.onChainChanged(chainId.toString());

      return chain;
    },
    onAccountsChanged(accounts) {
      connectedAccounts = accounts.map((x) => getAddress(x));
      if (connectedAccounts.length === 0) {
        this.onDisconnect();
      } else {
        config.emitter.emit("change", {
          accounts: connectedAccounts,
        });
      }
      if (parameters.onAccountsChanged) {
        parameters.onAccountsChanged(connectedAccounts);
      }
    },
    onChainChanged(chain) {
      const chainId = normalizeChainId(chain);
      config.emitter.emit("change", { chainId });
      if (parameters.onChainChanged) {
        parameters.onChainChanged(chainId);
      }
    },
    // biome-ignore lint/suspicious/useAwait: <explanation>
    async onDisconnect(_error) {
      config.emitter.emit("disconnect");
      connected = false;
      if (parameters.onConnectedChanged) {
        parameters.onConnectedChanged(connected);
      }
    },
    async getProvider() {
      const request = () => {
        throw new Error("Not implemented");
      };
      return custom({ request })({ retryCount: 0 });
    },
    ...parameters.walletDetails,
  }));
}

export type MockConnector = ReturnType<ReturnType<typeof mockConnector>>;
