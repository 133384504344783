import { XMarkIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ComponentType, ReactNode, RefObject } from "react";
import { createContext, useContext, useRef } from "react";
import { useKeyPressEvent } from "react-use";

import { useBodyScrollLock } from "../../hooks/useBodyScrollLock.js";
import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { Button } from "../elements/Button.js";
import { Icon } from "../elements/Icon.js";
import { Portal } from "../layout/Portal.js";
import { ScreenReaderText } from "../typography/ScreenReaderText.js";

type ModalAppearance = "default" | "small" | "wide";
interface ModalContextValues {
  dismiss: () => void;
  icon?: ReactNode;
  isOpen: boolean;
  appearance: ModalAppearance;
}

const ModalContext = createContext<ModalContextValues | undefined>(undefined);

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("Missing modal context");
  }

  return context;
}

export interface ModalProps {
  children: ReactNode;
  className?: string;
  appearance?: ModalAppearance;
  dismiss: () => void;
  icon?: ReactNode;
  initialFocus?: RefObject<HTMLElement>;
  isOpen: boolean;
  onExitComplete?: () => void;
  title: string;
  allowOutsideClick?: boolean;
}

export function Modal({
  children,
  className,
  dismiss,
  icon,
  initialFocus,
  isOpen = false,
  appearance = "default",
  onExitComplete,
  allowOutsideClick = true,
  title,
}: ModalProps) {
  // Dismiss on Escape
  useKeyPressEvent("Escape", dismiss);

  // Dismiss on click away
  const panelRef = useRef(null);

  // Scroll lock
  useBodyScrollLock(isOpen);

  const motionPresets = useMotionPresets();
  const classes = classNames("space-y-4 sm:space-y-6", className);
  const panelClasses = classNames(
    "relative inline-block align-bottom bg-base-200 rounded-xl text-left overflow-hidden shadow-xl sm:my-8 py-4 sm:py-6 sm:align-middle w-full",
    {
      "sm:max-w-lg": appearance === "default",
      "sm:max-w-sm": appearance === "small",
      "sm:max-w-3xl": appearance === "wide",
    },
  );

  return (
    <ModalContext.Provider value={{ dismiss, icon, isOpen, appearance }}>
      <AnimatePresence onExitComplete={onExitComplete}>
        {isOpen ? (
          <Portal>
            {/* Portal overlay */}
            <div className="fixed inset-0 z-20 overflow-y-auto">
              {/* Overlay content */}
              <div className="flex items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                {/* Background overlay */}
                <motion.div {...motionPresets.opacity} className="fixed inset-0" aria-hidden={true}>
                  <div
                    className="absolute inset-0 bg-gray-500/25 backdrop-blur-md dark:bg-black/25"
                    onClick={dismiss}
                    onKeyDown={dismiss}
                    role="button"
                    tabIndex={0}
                  />
                </motion.div>

                {/* This contains a zero width space to trick the browser into centering the modal contents. */}
                {/* Ref: https://www.fileformat.info/info/unicode/char/200b/index.htm */}
                <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden={true}>
                  {"\u200b"}
                </span>

                <FocusTrap focusTrapOptions={{ allowOutsideClick, initialFocus: initialFocus?.current ?? undefined }}>
                  {/* Panel */}
                  <motion.div
                    {...motionPresets.default}
                    className={panelClasses}
                    ref={panelRef}
                    role="dialog"
                    aria-modal={true}
                    aria-labelledby="modal-headline"
                  >
                    <div className="flex flex-col-reverse space-y-4 space-y-reverse sm:space-y-6 sm:space-y-reverse">
                      <div className={classes}>{children}</div>

                      {appearance === "small" ? (
                        <div className="space-y-3 px-4 sm:space-y-6 sm:px-6">
                          {icon}
                          <h3 id="modal-headline" className="title-lg text-center">
                            {title}
                          </h3>
                          <ScreenReaderText>
                            <Button circular={true} icon={XMarkIcon} appearance="tertiary" onClick={dismiss} size="lg">
                              Close
                            </Button>
                          </ScreenReaderText>
                        </div>
                      ) : (
                        <div className="bg-base-200 flex items-center justify-between space-x-4 px-4 sm:px-6">
                          <h3 className="title-lg" id="modal-headline">
                            {title}
                          </h3>
                          <Button circular={true} icon={XMarkIcon} appearance="tertiary" onClick={dismiss} size="lg">
                            Close
                          </Button>
                        </div>
                      )}
                    </div>
                  </motion.div>
                </FocusTrap>
              </div>
            </div>
          </Portal>
        ) : null}
      </AnimatePresence>
    </ModalContext.Provider>
  );
}

export type ModalBodyProps = ComponentPropsWithoutRef<"div">;

export function ModalBody({ className, ...props }: ModalBodyProps) {
  const { appearance } = useModal();
  const classes = classNames(
    "bg-base-200 px-4",
    {
      "sm:px-6": appearance === "default" || appearance === "wide",
    },
    className,
  );

  return <div className={classes} {...props} />;
}

Modal.Body = ModalBody;

export interface ModalActionsProps extends ComponentPropsWithoutRef<"div"> {
  align?: "left" | "right";
}

export function ModalActions({ align = "right", className, ...props }: ModalActionsProps) {
  const { appearance } = useModal();
  const classes = classNames(
    "bg-base-200 px-4 flex flex-col",
    {
      "sm:px-6": appearance === "default",
      "space-y-2": appearance === "small",
      "space-y-3 sm:space-y-0 sm:space-x-3": appearance !== "small",
      "sm:flex-row": appearance !== "small" && align === "left",
      "sm:flex-row-reverse sm:space-x-reverse": appearance !== "small" && align === "right",
    },
    className,
  );

  return <div className={classes} {...props} />;
}

Modal.Actions = ModalActions;

export interface ModalIconProps {
  animated?: boolean;
  className?: string;
  icon: ComponentType<ComponentPropsWithoutRef<"svg">>;
  appearance?: "neutral" | "success";
}

export function ModalIcon({ animated = true, className, icon, appearance = "success" }: ModalIconProps) {
  const motionPresets = useMotionPresets();
  const classes = classNames(
    "mx-auto flex items-center justify-center h-12 w-12 rounded-full",
    {
      "bg-base-100 text-primary": appearance === "neutral",
      "bg-green-100 dark:bg-green-900/25 text-success": appearance === "success",
    },
    className,
  );

  if (animated) {
    return (
      <motion.div
        className={classes}
        {...motionPresets.scale}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { bounce: 0.4, delay: 0.4, type: "spring" },
        }}
      >
        <motion.div
          {...motionPresets.scale}
          animate={{ opacity: 1, scale: 1, transition: { delay: 1, duration: 0.1, ease: "easeOut" } }}
        >
          <Icon icon={icon} size={6} />
        </motion.div>
      </motion.div>
    );
  }

  return (
    <div className={classes}>
      <Icon icon={icon} size={6} />
    </div>
  );
}

Modal.Icon = ModalIcon;
