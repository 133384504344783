import { Network } from "@enzymefinance/environment";
import { defaultTransactionSlippage } from "utils/constants";

export const minTick = -887272;
export const maxTick = -minTick;
export const tokenRatioPrecision = 8;
export const liquidityAmountsPrecision = 18;

export const defaultLiquiditySlippage = defaultTransactionSlippage;
export const liquiditySlippageTooLowWarningThreshold = 0.0005; // 0.05%
export const liquiditySlippageTooHighWarningThreshold = defaultTransactionSlippage;
export const maxLiquiditySlippage = 0.15; // 15%

// From https://github.com/Uniswap/interface/blob/8eaf1f4964e1086caed9cf070fce85bac456ef86/src/constants/chains.ts#L21
export const uniswapNetworkSlugs: {
  [TNetwork in Network]: string;
} = {
  [Network.ARBITRUM]: "arbitrum",
  [Network.BASE]: "base",
  [Network.ETHEREUM]: "mainnet",
  [Network.POLYGON]: "polygon",
} as const;

export const uniswapBaseUrl = "https://app.uniswap.org";
