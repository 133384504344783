import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const claimComp: FunctionConfig<{ claimer: Address }> = {
  signature: "function claimComp(address claimer)",

  Description({ inputs }) {
    const query = useVaultDetailsQuery({
      variables: { id: inputs.claimer.toLowerCase() },
    });

    const vault = query.data?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Claim COMP rewards for {vault?.name ?? "this vault"}.</>;
  },
  Label() {
    return <>Claim COMP Rewards</>;
  },
};
