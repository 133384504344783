import type { SelectProps as SelectPropsBase } from "@enzymefinance/select";
import { Select as BaseSelect } from "@enzymefinance/select";
import type { Option } from "@enzymefinance/ui/src";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface SelectProps<
  TOption extends Record<string, any> = Option,
  TMulti extends boolean = boolean,
  TFieldValues extends FieldValues = FieldValues,
> extends Omit<SelectPropsBase<TOption, TMulti>, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function Select<
  TOption extends Record<string, any> = Option,
  TMulti extends boolean = boolean,
  TFieldValues extends FieldValues = FieldValues,
>(props: SelectProps<TOption, TMulti, TFieldValues>) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <BaseSelect error={fieldState.error?.message} {...props} id={props.name} {...field} onChange={onChange as any} />
  );
}
