import type { Network } from "@enzymefinance/environment";
import { ArrowTopRightOnSquareIcon } from "@enzymefinance/icons/solid";
import { CopyToClipboard, Icon, Jazzicon, Tooltip } from "@enzymefinance/ui";
import type { FixedSize } from "@enzymefinance/utils";
import classNames from "classnames";

import { useAddressLike } from "../../hooks/useAddressLike.js";
import { useTrimmedAddress } from "../../hooks/useTrimmedAddress.js";
import { EtherscanLink } from "../etherscan-link/EtherscanLink.js";

type AddressAppearance = "card" | "simple";
export interface AddressProps {
  appearance?: AddressAppearance;
  address?: string;
  className?: string;
  color?: "default" | "emphasis";
  copy?: boolean;
  etherscan?: boolean;
  icon?: boolean;
  iconSize?: Extract<FixedSize, 4 | 5 | 6 | 8 | 9 | 10 | 12 | 14 | 16>;
  network?: Network;
  start?: number;
  trimmed?: boolean;
}

export function Address({
  address,
  appearance,
  className,
  color = "default",
  copy = true,
  etherscan = true,
  icon = false,
  iconSize = 9,
  network,
  start,
  trimmed = false,
}: AddressProps) {
  const classes = classNames(
    "text-sm font-mono min-w-0 flex items-center justify-between space-x-2",
    { "inline-flex py-3 px-4 max-w-full bg-base-300 rounded-lg": appearance === "card" },
    className,
  );
  const addressClasses = classNames("truncate", { "text-high-emphasis": color === "emphasis" });
  const etherScanLinkClasses = classNames(
    "focus-visible:ring-primary hover:text-base-content focus-visible:ring-offset-base-100 rounded-md transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
    {
      "text-high-emphasis": color === "emphasis",
      "text-base-neutral": color === "default",
    },
  );

  const resolved = useAddressLike(address);
  const trimmedAddress = useTrimmedAddress(address, start);

  if (!resolved) {
    return null;
  }

  const addr = (trimmed ? trimmedAddress : resolved) ?? null;
  const title = trimmed ? resolved : undefined;

  return (
    <span className={classes}>
      <Tooltip.Provider>
        <Tooltip.Item as="span" className="inline-flex min-w-0 items-center space-x-4">
          {icon ? <Jazzicon id={resolved} size={iconSize} /> : null}
          <span className={addressClasses}>{addr}</span>
        </Tooltip.Item>
        {title === undefined ? null : <Tooltip.Panel>{title}</Tooltip.Panel>}
      </Tooltip.Provider>
      {(etherscan && network !== undefined) || copy ? (
        <span className="flex items-center space-x-1">
          {copy ? (
            <CopyToClipboard value={resolved}>
              <CopyToClipboard.Icon title="Copy address to clipboard" appearance="emphasis" />
            </CopyToClipboard>
          ) : null}
          {etherscan && network !== undefined ? (
            <EtherscanLink className={etherScanLinkClasses} hash={resolved} network={network}>
              <Icon icon={ArrowTopRightOnSquareIcon} />
            </EtherscanLink>
          ) : null}
        </span>
      ) : null}
    </span>
  );
}
