import { VersionUpdateBanner } from "components/banner/VersionUpdateBanner";
import { useState } from "react";

import { useVersionUpdate } from "./useVersionUpdate";

export function useHeaderBanner() {
  const [didDismissBanner, setDidDismissBanner] = useState(false);

  // Version Update
  const { isUpdateAvailable } = useVersionUpdate();

  if (!isUpdateAvailable || didDismissBanner) {
    return null;
  }

  return <VersionUpdateBanner onDismiss={() => setDidDismissBanner(true)} />;
}
