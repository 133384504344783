export class AsyncLoadingError extends Error {
  readonly originalException: Error;

  constructor(error: Error) {
    super("Async loading error");
    this.originalException = error;
  }
}

export function catchAsyncLoadingError(error: Error): Promise<never> {
  throw new AsyncLoadingError(error);
}
