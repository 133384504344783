import { UserIcon } from "@enzymefinance/icons/solid";
import { Avatar, Button, Skeleton } from "@enzymefinance/ui";
import classNames from "classnames";
import { useAuthentication } from "components/providers/AuthenticationProvider";
import { useLocation } from "react-use";

import { InlineLink, Link } from "../routing/Link";

export function UserMenu() {
  const { account, authenticated, loading } = useAuthentication();
  const location = useLocation();

  if (loading) {
    return <Skeleton className="h-[38px] w-40" />;
  }

  const email = account?.email ?? undefined;
  const displayName = account?.accountProfile?.name || "Anonymous";

  const isSignInPage = location.pathname === "/sign-in";
  const isProfilePage = !!location.pathname?.startsWith("/account");

  const classes = classNames("group flex-none flex py-px px-1 text-left", {
    "bg-base-300": isProfilePage,
    "hover:bg-base-400": !isProfilePage,
  });
  const displayNameClasses = classNames("text-sm font-medium truncate", {
    "text-gray-800 dark:text-gray-100 group-hover:text-heading-content transition": !isProfilePage,
    "text-heading-content": isProfilePage,
  });
  const emailClasses = classNames("text-xs font-medium truncate", {
    "text-base-neutral group-hover:text-base-content transition": !isProfilePage,
    "text-base-content": isProfilePage,
  });

  if (!authenticated) {
    // The "key" prop prevents from keeping the focus state when switching between sign in and sign up
    return isSignInPage ? (
      <Button as={Link} key="sign-up-button" appearance="secondary" leadingIcon={UserIcon} to="/sign-up">
        Sign Up
      </Button>
    ) : (
      <Button as={Link} key="sign-in-button" appearance="secondary" leadingIcon={UserIcon} to="/sign-in">
        Sign In
      </Button>
    );
  }

  return (
    <InlineLink className={classes} id="user-menu-button" rounded="lg" to="/account">
      <div aria-hidden={true} className="flex max-w-xs flex-1 items-center overflow-hidden">
        <Avatar displayName={displayName} size={8} />
        <div className="min-w-0 px-3">
          <p className={displayNameClasses}>{displayName}</p>
          {email ? <p className={emailClasses}>{email}</p> : null}
        </div>
      </div>
    </InlineLink>
  );
}
