import { toAddress } from "@enzymefinance/environment";
import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { AddressCreatable, BigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Utils } from "@enzymefinance/sdk";
import { Fee, Fees } from "@enzymefinance/sdk/Configuration";
import { Alert, Badge, StackedList } from "@enzymefinance/ui";
import { address, bigint } from "@enzymefinance/validation";
import { useNetwork } from "components/providers/NetworkProvider";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { supportEmailAddress } from "utils/constants";
import { parseEther } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { ManagementFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

// 10%
const rateThreshold = parseEther("0.1");

export const managementFeeSchema = z.object({
  rate: bigint()
    // Higher than 0%
    .refine((value?: ManagementFeeSettings["rate"]) => typeof value === "bigint" && value > 0n, {
      message: "The rate must be greater than 0%.",
    })

    // Less than 100%
    .refine((value?: ManagementFeeSettings["rate"]) => typeof value === "bigint" && value < parseEther("1"), {
      message: "The rate must be less than 100%.",
    }),
  recipient: address({ caseInsensitive: true }).optional(),
});

interface ManagementFeeFormFieldsValues {
  [VaultConfigFieldName.MANAGEMENT_FEE]?: Partial<ManagementFeeSettings>;
}

function managementFeeFormFields() {
  const { getFieldState, setValue, watch } = useFormContext<ManagementFeeFormFieldsValues>();
  const fieldName = VaultConfigFieldName.MANAGEMENT_FEE;
  const { error } = getFieldState(fieldName);
  const [value] = watch([fieldName]) as [ManagementFeeFormFieldsValues[VaultConfigFieldName.MANAGEMENT_FEE]];
  const isAboveThreshold = useMemo(() => value?.rate && value.rate > rateThreshold, [value?.rate]);

  // This effect is required to have the underlying components update when DefaultFieldValues is triggered in the FeesStep
  useEffect(() => {
    if (value?.rate !== undefined) {
      setValue("managementFee.rate", value.rate as never);
    }

    if (value?.recipient !== undefined) {
      setValue("managementFee.recipient", value.recipient as never);
    }
  }, [value]);

  return (
    <div className="space-y-3">
      <BigIntInput label="Management Fee Rate" name={`${fieldName}.rate`} numberFormat={{ style: "percent" }} />
      <AddressCreatable
        name={`${fieldName}.recipient`}
        label="Recipient Address (optional)"
        cornerHint="By default, the fee recipient is the vault owner"
      />
      <AnimatePresence>
        {isAboveThreshold === true && error === undefined ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <Alert appearance="warning">
              This fee setting is considerably higher than average and may deter potential depositors. Consider setting
              a lower fee.
            </Alert>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function managementFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.MANAGEMENT_FEE>) {
  const { network } = useNetwork();

  return (
    <div className="flex flex-col space-y-3">
      <BigIntDisplay numberFormat={{ style: "percent" }} value={settings.rate ?? undefined} />
      {settings.recipient ? (
        <div>
          Recipient: <Address address={settings.recipient} network={network} icon={true} iconSize={5} />
        </div>
      ) : null}
    </div>
  );
}

function managementFeeDisplaySubgraph({ settings }: VaultConfigDisplaySubgraphProps<VaultConfigType.MANAGEMENT_FEE>) {
  const rate = useMemo(() => {
    return Utils.Rates.convertScaledPerSecondRateToRate({
      scaledPerSecondRate: BigInt(settings.scaledPerSecondRate),
      adjustInflation: true,
    });
  }, [settings]);

  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Rate">
        <BigIntDisplay numberFormat={{ style: "percent" }} value={rate} />
      </StackedList.ItemDataBox>
    </StackedList.ItemDataBoxList>
  );
}

export const managementFee: VaultConfig<VaultConfigType.MANAGEMENT_FEE> = {
  address: (contracts) => contracts.ManagementFee,
  disableable: false,
  display: managementFeeDisplay,
  displaySubgraph: managementFeeDisplaySubgraph,
  editable: false,
  encode: (settings) => {
    const scaledPerSecondRate = Utils.Rates.convertRateToScaledPerSecondRate({
      perAnnumRate: settings.rate ?? 0n,
      adjustInflation: true,
    });

    return Fees.Management.encodeSettings({
      recipient: settings.recipient ? toAddress(settings.recipient) : undefined,
      scaledPerSecondRate,
    });
  },
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const [info, recipient] = await Promise.all([
        Fees.Management.getInfo(client, {
          managementFee: vaultConfigAddress,
          comptrollerProxy: comptroller,
        }),
        Fee.getRecipient(client, { fee: vaultConfigAddress, comptrollerProxy: comptroller }),
      ]);

      const rate = Utils.Rates.convertScaledPerSecondRateToRate({
        scaledPerSecondRate: info.scaledPerSecondRate,
        adjustInflation: true,
      });

      return { rate, recipient };
    } catch {
      return undefined;
    }
  },
  formFields: managementFeeFormFields,
  label: "Management Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>
        If enabled, a flat fee measured as an annual percent of total assets under management. The management fee
        accrues continuously and is automatically paid out with every deposit and redemption. The fee recipient is the
        vault manager by default, or any other wallet. If you wish to split fee amounts among several wallets, please
        contact our sales team at{" "}
        <InlineLink appearance="tertiary" to={`mailto:${supportEmailAddress}`}>
          {supportEmailAddress}
        </InlineLink>
        . For an in-depth description of how this fee is calculated, please refer to the{" "}
        <InlineLink to="https://docs.enzyme.finance/managers/setup/fees#management-fees" appearance="link">
          User Documentation
        </InlineLink>
        .
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.MANAGEMENT_FEE,
  validationSchema: managementFeeSchema,
};
