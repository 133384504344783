import { zodResolver } from "@hookform/resolvers/zod";
import type {
  DefaultValues,
  FieldErrors,
  FieldValues,
  UseFormProps as UseFormPropsBase,
  UseFormReturn as UseFormReturnBase,
} from "react-hook-form";
import { useForm as useFormBase } from "react-hook-form";
import type { ZodSchema, ZodTypeDef } from "zod";

import type { BaseSyntheticEvent } from "react";
import type { FormFieldValue } from "../types";

export type DefaultFormValues<TFieldValues> = DefaultValues<TFieldValues>;

export type FormHelpers<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any> = Pick<
  UseFormReturn<TInput, TOutput, TContext>,
  "clearErrors" | "reset" | "resetField" | "setError"
>;

type FormSubmitHandler<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any> = (
  data: TOutput,
  helpers: FormHelpers<TInput, TOutput, TContext>,
  event?: BaseSyntheticEvent,
) => void;

type FormSubmitErrorHandler<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any> = (
  errors: FieldErrors<TInput>,
  helpers: FormHelpers<TInput, TOutput, TContext>,
  event?: BaseSyntheticEvent,
) => void;

export interface UseFormProps<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>
  extends UseFormPropsBase<FormFieldValue & TInput, TContext> {
  onSubmit: FormSubmitHandler<FormFieldValue & TInput, TOutput>;
  onSubmitError?: FormSubmitErrorHandler<FormFieldValue & TInput, TOutput>;
  schema: ZodSchema<TOutput, ZodTypeDef, TInput>;
  resolverMode?: "async" | "sync";
}

export interface UseFormReturn<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>
  extends UseFormReturnBase<TInput, TContext> {
  mode?: UseFormProps<TInput, TOutput, TContext>["mode"];
  onSubmit: FormSubmitHandler<TInput, TOutput>;
  onSubmitError?: FormSubmitErrorHandler<TInput, TOutput>;
  reValidateMode?: UseFormProps<TInput, TOutput, TContext>["reValidateMode"];
}

export function useForm<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>({
  mode = "onTouched",
  onSubmit,
  onSubmitError,
  reValidateMode = "onChange",
  schema,
  resolverMode = "sync",
  ...props
}: UseFormProps<TInput, TOutput, TContext>): UseFormReturn<DefaultValues<TInput> & FormFieldValue, TOutput, TContext> {
  const form = useFormBase({
    mode,
    reValidateMode,
    resolver: zodResolver(schema, undefined, {
      mode: resolverMode,
    }),
    ...props,
  }) as any;

  return { ...form, mode, reValidateMode, onSubmit, onSubmitError };
}
