import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { useSharesWrapperDepositQuery } from "queries/core";
import { bigDecimalMaxUint256 } from "utils/constants";
import { getSharesWrapper } from "utils/sharesWrapper";
import { parseUnits } from "viem";

interface SharesWrapperDepositInfoProps {
  vaultId: Address;
  comptroller: Address;
  account: Address;
}

export function useSharesWrapperDepositInfo({ vaultId, comptroller, account }: SharesWrapperDepositInfoProps) {
  const { data, loading } = useSharesWrapperDepositQuery({
    variables: {
      vaultId,
      comptroller,
      account,
    },
  });

  if (data === undefined) {
    return null;
  }

  const {
    allowedDepositRecipientsPolicies,
    gatedRedemptionQueueSharesWrappers,
    gatedRedemptionQueueSharesWrapperDepositApprovals,
  } = data;

  if (!allowedDepositRecipientsPolicies[0]) {
    return null;
  }

  const sharesWrapper = getSharesWrapper({
    allowedDepositRecipientsPolicy: allowedDepositRecipientsPolicies[0],
    gatedRedemptionQueueSharesWrappers,
  });

  if (sharesWrapper === null) {
    return null;
  }

  const depositAmount = gatedRedemptionQueueSharesWrapperDepositApprovals[0]
    ? parseUnits(
        gatedRedemptionQueueSharesWrapperDepositApprovals[0].amount,
        gatedRedemptionQueueSharesWrapperDepositApprovals[0].asset.decimals,
      )
    : null;

  const isUnlimited =
    gatedRedemptionQueueSharesWrapperDepositApprovals[0] &&
    gatedRedemptionQueueSharesWrapperDepositApprovals[0].amount === bigDecimalMaxUint256;

  return {
    loading,
    data: {
      hasDepositLimit: Boolean(sharesWrapper.useDepositApprovals) && !isUnlimited,
      depositAmountLimit: depositAmount,
      sharesWrapperAddress: toAddress(sharesWrapper.id),
      depositMode: sharesWrapper.depositMode,
      depositAssetId: gatedRedemptionQueueSharesWrapperDepositApprovals[0]
        ? toAddress(gatedRedemptionQueueSharesWrapperDepositApprovals[0].asset.id)
        : undefined,
    },
  };
}
