import type { Asset } from "@enzymefinance/environment";
import { TokenLabel } from "@enzymefinance/ethereum-ui";
import { Badge } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";

interface PolicyTokenListProps {
  addresses: string[];
  isDelegated?: boolean;
  listDescription?: string;
  listId?: string;
}

export function PolicyTokenList({ addresses, isDelegated, listDescription, listId }: PolicyTokenListProps) {
  const { getAssetsByAddresses } = useGlobals();
  const tokens = getAssetsByAddresses(addresses);

  if (typeof listId === "string") {
    return (
      <div className="space-y-2">
        {listDescription ? <Badge>{listDescription}</Badge> : null}
        <p>List ID: {listId}</p>
      </div>
    );
  }

  if (addresses.length === 0) {
    return <p className="text-sm">All assets allowed.</p>;
  }

  return (
    <div className="space-y-2">
      {isDelegated ? <Badge>Using council-maintained list{listDescription ? `: ${listDescription}` : ""}</Badge> : null}
      <div className="flex flex-col space-y-2">
        {tokens.map((token) => (
          <TokenLabel key={token.id} asset={token} />
        ))}
      </div>
    </div>
  );
}

export function InlinePolicyTokenList({ assets }: { assets: Asset[] }) {
  return (
    <div className="-m-3 flex-row">
      {assets.map((asset) => {
        return (
          <span className="inline-flex p-3" key={asset.id}>
            <TokenLabel size={5} key={asset.id} asset={asset} />
          </span>
        );
      })}
    </div>
  );
}
