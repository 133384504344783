import { toAddress } from "@enzymefinance/environment";
import { NumberInput, TokenSelect, useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { ErrorMessage, RadioGroup, Skeleton } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import { asset, refine } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import { type ReactNode, useCallback, useMemo } from "react";
import { isAddressEqual, zeroAddress } from "viem";
import { z } from "zod";
import { useKnownAssetLists } from "../../../../utils/hooks/useKnownAssetLists";
import { VaultConfigFieldName } from "../VaultConfig";
import type { DisallowedAdapterIncomingAssetsPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigContext, VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { InlinePolicyTokenList, PolicyTokenList } from "./PolicyTokenList";

export const disallowedAdapterIncomingAssetsPolicySchema = z
  .object({
    items: z.array(asset()),
    listId: z.string().optional(),
    selectedOptionType: z.nativeEnum(VaultConfigPolicyListOption).optional(),
  })
  .refine(
    (value) => {
      return value.selectedOptionType !== VaultConfigPolicyListOption.CUSTOM_LIST || value.items.length > 0;
    },
    {
      message: 'Please specify some values or choose "Allow All"',
      path: ["items"],
    },
  )
  .transform(
    refine(
      (value) => {
        return (
          value.selectedOptionType === VaultConfigPolicyListOption.EMPTY_LIST ||
          value.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST ||
          value.listId !== ""
        );
      },
      {
        message: 'Please specify a value or choose "Allow All"',
        path: ["listId"],
      },
    ),
  )
  .optional();

interface DisallowedAdapterIncomingAssetsPolicyFormFieldsValues {
  [VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]?: Partial<DisallowedAdapterIncomingAssetsPolicySettings>;
}

function disallowedAdapterIncomingAssetsPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  const { getAssets } = useGlobals();
  const { getFieldState, setValue, watch } = useFormContext<DisallowedAdapterIncomingAssetsPolicyFormFieldsValues>();
  const knownAssetsLists = useKnownAssetLists();
  const { error } = getFieldState(VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY);
  const [value] = watch([VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]) as [
    DisallowedAdapterIncomingAssetsPolicyFormFieldsValues[VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  ];

  const defaultOptions = useMemo(
    () => getAssets({ registered: listOptions?.action === "add" || undefined }),
    [getAssets, listOptions?.action],
  );

  // If we add items to the list, we should not display the provided options
  // If we remove items from the list, we should only display the provided options
  const options = useMemo(
    () =>
      listOptions?.action === "add"
        ? defaultOptions.filter(
            (defaultOption) => !listOptions.options.items.find((option) => isAddressEqual(defaultOption.id, option.id)),
          )
        : listOptions?.action === "remove"
          ? listOptions.options.items.filter((option) => !isAddressEqual(option.id, zeroAddress))
          : defaultOptions.filter((option) => !isAddressEqual(option.id, zeroAddress)),
    [defaultOptions, listOptions?.action, listOptions?.options],
  );

  const handleSelectListOption = useCallback(
    ({ optionType, value: newValue }: ListSelectOption) => {
      const knownListData =
        newValue === knownAssetsLists?.nonStandardPriceFeedAssets.id
          ? knownAssetsLists.nonStandardPriceFeedAssets
          : undefined;

      const newFieldValue: DisallowedAdapterIncomingAssetsPolicySettings = {
        listId: optionType === VaultConfigPolicyListOption.DELEGATED ? newValue : "",
        selectedOptionType: optionType,
        items: newValue === VaultConfigPolicyListOption.DELEGATED ? knownListData?.items ?? value?.items ?? [] : [],
      };

      setValue(VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY, newFieldValue, { shouldValidate: true });
    },
    [setValue, value?.items, knownAssetsLists],
  );

  const tokenSelectElement = useMemo(
    () => (
      <div className="space-y-4">
        <TokenSelect
          isExpandable={true}
          isMulti={true}
          label={disallowedAdapterIncomingAssetsPolicy.label}
          labelHidden={true}
          name={`${VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY}.items`}
          options={options}
        />
      </div>
    ),
    [options],
  );

  const listIdElement = useMemo(
    () => (
      <div className="space-y-4">
        <NumberInput
          label={disallowedAdapterIncomingAssetsPolicy.label}
          labelHidden={true}
          name={`${VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY}.listId`}
        />
      </div>
    ),
    [],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () =>
      [knownAssetsLists?.nonStandardPriceFeedAssets]
        .map((assetListItem) =>
          assetListItem?.id
            ? {
                description: <InlinePolicyTokenList assets={assetListItem.items} />,
                label: `Use council-maintained list - ${assetListItem.description}`,
                optionType: VaultConfigPolicyListOption.DELEGATED,
                value: assetListItem.id,
              }
            : undefined,
        )
        .concat([
          {
            value: VaultConfigPolicyListOption.CUSTOM_LIST,
            label: "Specify your own asset list",
            description:
              value?.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST ? tokenSelectElement : <></>,
            optionType: VaultConfigPolicyListOption.CUSTOM_LIST,
          },
          {
            value: VaultConfigPolicyListOption.CUSTOM_LIST_ID,
            label: "Specify your own asset list ID (advanced)",
            description:
              value?.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ? listIdElement : <></>,
            optionType: VaultConfigPolicyListOption.CUSTOM_LIST_ID,
          },
          {
            value: VaultConfigPolicyListOption.EMPTY_LIST,
            label: "Allow all assets",
            description: <></>,
            optionType: VaultConfigPolicyListOption.EMPTY_LIST,
          },
        ])
        .filter(isTruthy),
    [listIdElement, knownAssetsLists?.nonStandardPriceFeedAssets, tokenSelectElement, value?.selectedOptionType],
  );

  const selectedListOption = useMemo(
    () =>
      listSelectOptions.find((option) => option.value === value?.listId) ??
      listSelectOptions.find((option) => option.optionType === value?.selectedOptionType) ??
      listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [listSelectOptions, value?.listId, value?.selectedOptionType],
  );

  if (!knownAssetsLists) {
    return <Skeleton className="h-4 w-full" />;
  }

  if (listOptions?.action) {
    // If this FormFields component is used from PolicyEditList, render just the component that handles the list items
    return tokenSelectElement;
  }

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label="Use an existing council-maintained list of assets or specify your own"
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function disallowedAdapterIncomingAssetsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  const knownAssetsLists = useKnownAssetLists({
    skip: settings.selectedOptionType !== VaultConfigPolicyListOption.DELEGATED,
  });

  let listDescription: string | undefined;

  if (knownAssetsLists && settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED) {
    if (settings.listId === knownAssetsLists.nonStandardPriceFeedAssets.id) {
      listDescription = knownAssetsLists.nonStandardPriceFeedAssets.description;
    }
  }

  if (settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID) {
    listDescription = "Custom list ID (advanced)";
  }

  return (
    <PolicyTokenList
      addresses={settings.items.map((setting) => setting.id)}
      isDelegated={settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED}
      listDescription={listDescription}
      listId={
        settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ||
        settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED
          ? settings.listId
          : undefined
      }
    />
  );
}

function disallowedAdapterIncomingAssetsPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  const { environment } = useGlobals();

  // Assume there can be only delegated list
  const listId = settings.addressLists[0]?.id;
  const isDelegated = listId
    ? [environment.knownAddressLists.nonStandardPriceFeedAssets].includes(BigInt(listId))
    : false;

  const addresses = settings.addressLists.flatMap((list) => list.items);

  return (
    <PolicyTokenList
      isDelegated={isDelegated}
      addresses={addresses}
      listDescription={settings.addressLists[0]?.description ?? undefined}
    />
  );
}

export const disallowedAdapterIncomingAssetsPolicy: VaultConfig<VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY> =
  {
    address: (contracts) => contracts.DisallowedAdapterIncomingAssetsPolicy,
    disableable: false,
    display: disallowedAdapterIncomingAssetsPolicyDisplay,
    displaySubgraph: disallowedAdapterIncomingAssetsPolicyDisplaySubgraph,
    editable: true,
    encode: (settings, encodeArgs) => {
      const unique = [...new Set(settings.items.map((item) => toAddress(item.id)))];

      // List already exists -> Point to that list ID
      if (
        (settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ||
          settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED) &&
        settings.listId
      ) {
        return Policies.DisallowedAdapterIncomingAssets.encodeSettings({
          existingListIds: [BigInt(settings.listId)],
          newListsArgs: [],
        });
      }

      if (
        encodeArgs?.context === VaultConfigContext.RECONFIGURATION ||
        encodeArgs?.context === VaultConfigContext.MIGRATION
      ) {
        const previousSettingsSet = new Set(
          encodeArgs.previousSettings?.items.map((item) => item.id.toLowerCase()) ?? [],
        );

        if (
          encodeArgs.previousSettings?.listId &&
          previousSettingsSet.size === unique.length &&
          unique.every((item) => previousSettingsSet.has(item)) &&
          // Where a vault was delegating to a council-maintained list and now "detaches" but keeps the same adapters,
          // we should create a copy of the list and not reference it. This condition covers that edge case
          !(encodeArgs.previousSettings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED)
        ) {
          // New and old values contain the same items => reuse the list ID

          return Policies.DisallowedAdapterIncomingAssets.encodeSettings({
            existingListIds: [BigInt(encodeArgs.previousSettings.listId)],
            newListsArgs: [],
          });
        }
      }

      const args = Policies.DisallowedAdapterIncomingAssets.encodeSettings({
        existingListIds: [],
        newListsArgs: [
          {
            initialItems: unique,
            updateType: Constants.AddressListUpdateType.RemoveOnly,
          },
        ],
      });
      return args;
    },
    fetch: async () => undefined,
    formInitialValues: { selectedOptionType: undefined, items: [], listId: "" },
    formFields: disallowedAdapterIncomingAssetsPolicyFormFields,
    label: "Disallow Certain Incoming Assets",
    managerDescription: (
      <div className="space-y-4">
        <p>Restricts the assets that the vault can receive via an interaction with a third-party protocol.</p>
        <p>
          Assets can enter a vault in various ways. This policy only limits the assets that can enter the vault via an
          interaction with a third-party protocol. It does not, for example, limit the assets that the vault can receive
          via an airdrop or other DeFi-native event.
        </p>
      </div>
    ),
    type: VaultConfigType.DISALLOWED_ADAPTER_INCOMING_ASSETS_POLICY,
    validationSchema: disallowedAdapterIncomingAssetsPolicySchema,
  };

interface ListSelectOption {
  description: ReactNode;
  label: string;
  optionType: VaultConfigPolicyListOption;
  value: string;
}
