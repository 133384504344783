import "@sentry/tracing";

import type { History } from "history";
import type { ReactNode } from "react";
import { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";

interface BrowserRouterProps {
  basename?: string;
  children?: ReactNode;
  navigator: History;
}

export function BrowserRouter({ children, ...props }: BrowserRouterProps) {
  const [state, setState] = useState({
    action: props.navigator.action,
    location: props.navigator.location,
  });

  useLayoutEffect(() => props.navigator.listen(setState), [props.navigator]);

  return (
    <Router location={state.location} navigationType={state.action} {...props}>
      {children}
    </Router>
  );
}
