import { type Asset, toAddress } from "@enzymefinance/environment";
import { isTruthy } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useAddressListsByIdsQuery } from "queries/core";
import { useMemo } from "react";
import { isAddressEqual } from "viem";

export interface AssetListData {
  id: string;
  description: string;
  items: Asset[];
}

interface KnownAssetListMap {
  nonStandardPriceFeedAssets: AssetListData;
}

export function useKnownAssetLists(params?: { skip?: boolean }): KnownAssetListMap | undefined {
  const { getAssets } = useGlobals();
  const { environment } = useGlobals();

  const allAssets = getAssets();

  const addressListsByIdsQuery = useAddressListsByIdsQuery({
    variables: {
      ids: [`${environment.knownAddressLists.nonStandardPriceFeedAssets}`],
    },
    skip: !!params?.skip,
  });

  const data = useMemo(() => {
    if (!addressListsByIdsQuery.data) {
      return undefined;
    }

    const assetList = addressListsByIdsQuery.data.addressLists.find(
      (list) => list.id === `${environment.knownAddressLists.nonStandardPriceFeedAssets}`,
    );

    const assets =
      assetList?.items
        .map((item) => allAssets.find((asset) => isAddressEqual(asset.id, toAddress(item))))
        .filter(isTruthy) ?? [];

    return {
      nonStandardPriceFeedAssets: {
        id: `${environment.knownAddressLists.nonStandardPriceFeedAssets ?? 0n}`,
        items: assets,
        description: assetList?.description ?? "Non standard price feed assets",
      },
    };
  }, [allAssets, environment.knownAddressLists.nonStandardPriceFeedAssets, addressListsByIdsQuery.data]);

  return data;
}
