import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useBaseFee = createUseValidatedClientQuery(["gas-base-fee"], {
  paramsSchema: z.undefined(),
  responseSchema: z.bigint(),
  queryFn: async (_, client) => {
    const baseFee = (await client.getBlock({ blockTag: "latest" })).baseFeePerGas;

    if (typeof baseFee === "bigint") {
      return baseFee;
    }

    const gasPrice = await client.getGasPrice();

    return gasPrice;
  },
});
