import { BigIntDisplay, TokenIcon } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Card, NumberDisplay } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { liquidityAmountsPrecision, uniswapV3FeeAmountToDecimal } from "components/vault/protocols/uniswap-v3/utils";
import { useUniswapV3NftQuery } from "queries/backend";
import { useMemo } from "react";
import { client } from "utils/backend";
import { formatUnits, parseUnits } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const uniswapV3Mint: ExternalPositionHandler<Integrations.UniswapV3.MintArgs> = {
  Description({ args: { amount0Desired, amount1Desired, token0, token1, fee } }) {
    const { environment } = useGlobals();

    const mint = useMemo(() => {
      const token0Asset = environment.getAsset(token0);
      const token1Asset = environment.getAsset(token1);

      return {
        token0: token0Asset,
        token1: token1Asset,
      };
    }, [environment, token0, token1]);

    return (
      <div className="space-y-2">
        <span>
          You are creating a UniswapV3 position with {mint.token0.name} and {mint.token1.name} with a{" "}
          <NumberDisplay
            className="text-base-content inline-flex"
            numberFormat={{ maximumFractionDigits: 2, minimumFractionDigits: 2, style: "percent" }}
            value={uniswapV3FeeAmountToDecimal(fee)}
          />{" "}
          fee.
        </span>
        <div className="flex flex-row space-x-4 pt-2">
          <div className="flex items-center space-x-2">
            <TokenIcon size={6} asset={mint.token0} />
            <BigIntDisplay
              decimals={mint.token0.decimals}
              numberFormat={{ currency: mint.token0.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
              value={amount0Desired}
            />
          </div>
          <div className="flex items-center space-x-2">
            <TokenIcon size={6} asset={mint.token1} />
            <BigIntDisplay
              decimals={mint.token1.decimals}
              numberFormat={{ currency: mint.token1.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
              value={amount1Desired}
            />
          </div>
        </div>
      </div>
    );
  },
  Label() {
    return <>Create new Uniswap v3 Position</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.UniswapV3.mintDecode(encodedCallArgs),
};

export const uniswapV3Collect: ExternalPositionHandler<Integrations.UniswapV3.CollectArgs> = {
  Description({ args: { nftId } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();
    const currentNftQuery = useUniswapV3NftQuery({ client, variables: { deployment, nftId: nftId.toString() } });
    const nftDetails = currentNftQuery.data?.uniswapV3NftDetails;

    const nft = useMemo(() => {
      return {
        pendingFees0: nftDetails?.pendingFees0 ? BigInt(nftDetails.pendingFees0) : undefined,
        pendingFees1: nftDetails?.pendingFees1 ? BigInt(nftDetails.pendingFees1) : undefined,
        token0: nftDetails?.token0 ? environment.getAsset(nftDetails.token0) : undefined,
        token1: nftDetails?.token0 ? environment.getAsset(nftDetails.token1) : undefined,
      };
    }, [environment, nftDetails?.pendingFees0, nftDetails?.pendingFees1, nftDetails?.token0, nftDetails?.token1]);

    return (
      <div className="flex flex-col space-y-4">
        <span>
          Collecting Uniswap V3 position fees for pool {nftId.toString()} will withdraw currently available fees for
          you.
        </span>
        <Card appearance="secondary">
          <Card.Content className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <p>{nft.token0?.symbol} fees earned</p>
              <div className="flex items-center space-x-2">
                <BigIntDisplay
                  decimals={nft.token0?.decimals}
                  numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                  value={nft.pendingFees0}
                />
                <TokenIcon size={6} asset={nft.token0} />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p>{nft.token1?.symbol} fees earned</p>
              <div className="flex items-center space-x-2">
                <BigIntDisplay
                  decimals={nft.token1?.decimals}
                  numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                  value={nft.pendingFees1}
                />
                <TokenIcon size={6} asset={nft.token1} />
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label() {
    return <>Collect Fees</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.UniswapV3.collectDecode(encodedCallArgs),
};

export const uniswapV3Purge: ExternalPositionHandler<Integrations.UniswapV3.PurgeArgs> = {
  Description({ args: { nftId } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();
    const currentNftQuery = useUniswapV3NftQuery({ client, variables: { deployment, nftId: nftId.toString() } });
    const nftDetails = currentNftQuery.data?.uniswapV3NftDetails;

    const nft = useMemo(() => {
      const token0 = nftDetails?.token0 ? environment.getAsset(nftDetails.token0) : undefined;
      const token1 = nftDetails?.token0 ? environment.getAsset(nftDetails.token1) : undefined;

      if (!(nftDetails?.poolCurrentTick && token0 && token1)) {
        return null;
      }

      return {
        currentAmount0: BigInt(nftDetails.amount0),
        currentAmount1: BigInt(nftDetails.amount1),
        pendingFees0: nftDetails.pendingFees0 ? BigInt(nftDetails.pendingFees0) : undefined,
        pendingFees1: nftDetails.pendingFees1 ? BigInt(nftDetails.pendingFees1) : undefined,
        token0,
        token1,
      };
    }, [environment, nftDetails]);

    return nft ? (
      <div className="space-y-6">
        <div className="space-y-2 pt-2">
          <span>Purging pool {nftId.toString()} will relinquish its current positions.</span>
          <div className="flex flex-row space-x-4">
            <div className="flex items-center space-x-2">
              <TokenIcon size={6} asset={nft.token0} />
              <BigIntDisplay
                numberFormat={{ currency: nft.token0.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                decimals={nft.token0.decimals}
                value={nft.currentAmount0}
              />
            </div>
            <div className="flex items-center space-x-2">
              <TokenIcon size={6} asset={nft.token1} />
              <BigIntDisplay
                numberFormat={{ currency: nft.token1.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                decimals={nft.token1.decimals}
                value={nft.currentAmount1}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <span>You will also collect unclaimed fees earned from this position:</span>
          <div className="flex flex-row space-x-4">
            <div className="flex items-center space-x-2">
              <TokenIcon size={6} asset={nft.token0} />
              <BigIntDisplay
                decimals={nft.token0.decimals}
                numberFormat={{ currency: nft.token0.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                value={nft.pendingFees0}
              />
            </div>
            <div className="flex items-center space-x-2">
              <TokenIcon size={6} asset={nft.token1} />
              <BigIntDisplay
                decimals={nft.token1.decimals}
                numberFormat={{ currency: nft.token1.symbol, maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                value={nft.pendingFees1}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  },
  Label() {
    return <>Purge Pool</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.UniswapV3.purgeDecode(encodedCallArgs),
};

export const uniswapV3RemoveLiquidity: ExternalPositionHandler<Integrations.UniswapV3.RemoveLiquidityArgs> = {
  Description({ args: { nftId, liquidity } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();
    const currentNftQuery = useUniswapV3NftQuery({ client, variables: { deployment, nftId: nftId.toString() } });
    const nftDetails = currentNftQuery.data?.uniswapV3NftDetails;

    const nft = useMemo(() => {
      const token0 = nftDetails?.token0 ? environment.getAsset(nftDetails.token0) : undefined;
      const token1 = nftDetails?.token0 ? environment.getAsset(nftDetails.token1) : undefined;

      if (!(nftDetails?.poolCurrentTick && token0 && token1)) {
        return null;
      }

      const nftLiquidity = BigInt(nftDetails.liquidity);

      if (nftLiquidity === 0n) {
        // The form validation prevent submission in this case, just an extra check to avoid division by 0 below
        return null;
      }

      const liquidityPercentage = (liquidity * parseUnits("1", liquidityAmountsPrecision)) / nftLiquidity;

      const currentAmount0 = BigInt(nftDetails.amount0);
      const currentAmount1 = BigInt(nftDetails.amount1);

      const multiplier = liquidityPercentage;
      const remainingAmount0 = (currentAmount0 * multiplier) / parseUnits("1", liquidityAmountsPrecision);
      const remainingAmount1 = (currentAmount1 * multiplier) / parseUnits("1", liquidityAmountsPrecision);

      return {
        liquidityPercentageToRemove: liquidityPercentage,
        pendingFees0: nftDetails.pendingFees0 ? BigInt(nftDetails.pendingFees0) : undefined,
        pendingFees1: nftDetails.pendingFees1 ? BigInt(nftDetails.pendingFees1) : undefined,
        remainingAmount0: remainingAmount0 === 0n ? currentAmount0 : remainingAmount0,
        remainingAmount1: remainingAmount1 === 0n ? currentAmount1 : remainingAmount1,
        token0,
        token1,
      };
    }, [environment, liquidity, nftDetails]);

    return nft ? (
      <div className="space-y-6 pt-2">
        <div className="space-y-2">
          <span>
            You are removing{" "}
            <NumberDisplay
              className="text-base-content inline-flex"
              numberFormat={{ maximumFractionDigits: 0, minimumFractionDigits: 0, style: "percent" }}
              value={Number(formatUnits(nft.liquidityPercentageToRemove, 18))}
            />{" "}
            of liquidity from pool {nftId.toString()}.
          </span>
          <Card appearance="secondary">
            <Card.Content className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <p>Pooled {nft.token0.symbol}</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    decimals={nft.token0.decimals}
                    value={nft.remainingAmount0}
                  />
                  <TokenIcon size={6} asset={nft.token0} />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p>Pooled {nft.token1.symbol}</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    decimals={nft.token1.decimals}
                    value={nft.remainingAmount1}
                  />
                  <TokenIcon size={6} asset={nft.token1} />
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
        <div className="space-y-2">
          <span>You will also collect unclaimed fees earned from this position:</span>
          <Card appearance="secondary">
            <Card.Content className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <p>{nft.token0?.symbol} fees earned</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    decimals={nft.token0?.decimals}
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    value={nft.pendingFees0}
                  />
                  <TokenIcon size={6} asset={nft.token0} />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p>{nft.token1?.symbol} fees earned</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    decimals={nft.token1?.decimals}
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    value={nft.pendingFees1}
                  />
                  <TokenIcon size={6} asset={nft.token1} />
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
      </div>
    ) : null;
  },
  Label() {
    return <>Remove Liquidity</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.UniswapV3.removeLiquidityDecode(encodedCallArgs),
};

export const uniswapV3AddLiquidity: ExternalPositionHandler<Integrations.UniswapV3.AddLiquidityArgs> = {
  Description({ args: { nftId, amount0Desired, amount1Desired } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();
    const currentNftQuery = useUniswapV3NftQuery({ client, variables: { deployment, nftId: nftId.toString() } });
    const nftDetails = currentNftQuery.data?.uniswapV3NftDetails;

    const nft = useMemo(() => {
      return {
        amount0Desired: BigInt(amount0Desired),
        amount1Desired: BigInt(amount1Desired),
        token0: nftDetails?.token0 ? environment.getAsset(nftDetails.token0) : undefined,
        token1: nftDetails?.token0 ? environment.getAsset(nftDetails.token1) : undefined,
      };
    }, [amount0Desired, amount1Desired, environment, nftDetails?.token0, nftDetails?.token1]);

    return (
      <div className="space-y-4">
        <span>Adding liquidity to pool {nftId.toString()}.</span>
        <Card appearance="secondary">
          <Card.Content className="flex flex-col space-y-4">
            {nft.token0 && nft.amount0Desired < 0n ? (
              <div className="flex items-center justify-between">
                <p>{nft.token0?.symbol}</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    decimals={nft.token0?.decimals}
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    value={nft.amount0Desired}
                  />
                  <TokenIcon size={6} asset={nft.token0} />
                </div>
              </div>
            ) : null}
            {nft.token1 && nft.amount1Desired < 0n ? (
              <div className="flex items-center justify-between">
                <p>{nft.token1?.symbol}</p>
                <div className="flex items-center space-x-2">
                  <BigIntDisplay
                    decimals={nft.token1?.decimals}
                    numberFormat={{ maximumFractionDigits: 8, minimumFractionDigits: 8 }}
                    value={nft.amount1Desired}
                  />
                  <TokenIcon size={6} asset={nft.token1} />
                </div>
              </div>
            ) : null}
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label() {
    return <>Add Liquidity</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.UniswapV3.addLiquidityDecode(encodedCallArgs),
};

export const createUniswapV3ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Uniswap V3</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = uniswapV3Mint.decodeExternalPositionArgs(actionArgs);

    return <uniswapV3Mint.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Uniswap V3</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = uniswapV3Mint.decodeExternalPositionArgs(actionArgs);

    return <uniswapV3Mint.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Uniswap V3</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = uniswapV3Mint.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(uniswapV3Mint.Label, uniswapV3Mint.Description);

    return <Summary args={args} />;
  },
};
