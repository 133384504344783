import type { Asset } from "@enzymefinance/environment";
import { IconLabel, NumberDisplay } from "@enzymefinance/ui";
import { maxUint256 } from "viem";

import { BigIntDisplay } from "../big-int-display/BigIntDisplay.js";
import type { TokenIconProps } from "../token-icon/TokenIcon.js";
import { TokenIcon } from "../token-icon/TokenIcon.js";

export type AssetWithCurrencyValue = {
  asset: Asset;
  amount: bigint;
  currencyValue: number;
};

export type PartialAssetWithCurrencyValue = Partial<AssetWithCurrencyValue>;

export interface TokenWithCurrencyProps {
  appearance?: "card" | "column" | "default";
  className?: string;
  currency: string;
  loading?: boolean;
  size?: TokenIconProps["size"];
  reverse?: boolean;
  titleClasses?: string;
  value?: PartialAssetWithCurrencyValue;
  amountMaxDecimals?: number;
}

export function TokenWithCurrency({
  appearance = "default",
  className,
  currency,
  loading,
  size,
  reverse = false,
  value,
  titleClasses = "",
  amountMaxDecimals,
}: TokenWithCurrencyProps) {
  const { amount, asset, currencyValue } = value ?? {};
  const isAmountMaxUint256 = maxUint256 === (amount ?? 0n);

  return (
    <IconLabel
      appearance={appearance}
      className={className}
      reverse={reverse}
      description={
        isAmountMaxUint256 ? undefined : (
          <NumberDisplay
            className="text-base-content"
            appearance="simple"
            numberFormat={{ currency }}
            value={currencyValue}
          />
        )
      }
      icon={<TokenIcon asset={asset} size={size} />}
      title={
        isAmountMaxUint256 ? (
          "All"
        ) : (
          <BigIntDisplay
            className={titleClasses}
            decimals={asset?.decimals}
            numberFormat={{ currency: asset?.symbol, maximumFractionDigits: amountMaxDecimals }}
            value={amount}
          />
        )
      }
      loading={loading}
    />
  );
}
