import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useArbitraryLoanPositionsQuery } from "queries/core";
import { useMemo } from "react";
import type { FunctionConfig } from "utils/functionsTypes";

export const arbitraryLoanBorrow: FunctionConfig<{ amount: bigint }> = {
  signature: "function borrow(uint256 amount)",

  Description({ inputs, address }) {
    const { environment } = useGlobals();
    const arbitraryLoanPositionsQuery = useArbitraryLoanPositionsQuery({ variables: { id: address.toLowerCase() } });

    const loanAsset = useMemo(() => {
      const loanAssetAddress = arbitraryLoanPositionsQuery.data?.arbitraryLoanPositions[0]?.loanAsset?.id;

      if (loanAssetAddress === undefined) {
        return undefined;
      }

      return environment.getAsset(loanAssetAddress);
    }, [arbitraryLoanPositionsQuery.data?.arbitraryLoanPositions, environment]);

    if (arbitraryLoanPositionsQuery.loading || loanAsset === undefined) {
      return null;
    }

    return (
      <>
        Borrow{" "}
        <BigIntDisplay
          decimals={loanAsset.decimals}
          numberFormat={{ currency: loanAsset.symbol }}
          value={inputs.amount}
        />{" "}
        from loan address <Address appearance="simple" address={address} />{" "}
      </>
    );
  },

  Label() {
    return <>Borrow</>;
  },
};
