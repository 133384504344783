import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Skeleton } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import { useVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { useSharesSplitterTokenBalClaimableForUser } from "utils/hooks/useSharesSplitterTokenBalClaimableForUser";
import type { Address } from "viem";

export const sharesSplitterClaimToken: FunctionConfig<{ token: Address }> = {
  signature: "function claimToken(address token) returns (uint256 claimedAmount)",

  Description({ inputs, address }) {
    const { client } = useNetwork();
    const [signerAddress] = useSigner();
    const vaultId = toAddress(inputs.token);

    const query = useVaultDetailsQuery({
      variables: { id: vaultId },
    });

    const balanceClaimableQuery = useSharesSplitterTokenBalClaimableForUser(client, {
      tokenAddress: vaultId,
      splitterId: toAddress(address),
      userAddress: signerAddress,
    });

    if (balanceClaimableQuery.isLoading || query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    const vault = query.data?.vault;

    return (
      <>
        Claim splitter fees from <b>{vault?.name ?? "vault"}</b> to receive an estimated{" "}
        <BigIntDisplay
          value={balanceClaimableQuery.data}
          numberFormat={{ currency: vault?.symbol ?? "shares", maximumFractionDigits: 8 }}
        />
        .
      </>
    );
  },

  Label() {
    return <>Claim fees from Fee Splitter</>;
  },
};
