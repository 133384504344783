import { Portal } from "@enzymefinance/ui";
import classNames from "classnames";
import type { AnimatePresenceProps } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import { useCallback, useEffect, useState } from "react";

interface ToastsContainerProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactElement[];
}

export function ToastsContainer({ children, className, ...props }: ToastsContainerProps) {
  const [active, setActive] = useState(false);
  const hasToasts = children.length > 0;

  useEffect(() => {
    if (hasToasts && !active) {
      setActive(true);
    }
  }, [active, hasToasts]);
  const handleExitComplete = useCallback<NonNullable<AnimatePresenceProps["onExitComplete"]>>(() => {
    if (!hasToasts) {
      setActive(false);
    }
  }, [hasToasts]);

  const classes = classNames(
    "fixed inset-x-2 md:inset-x-4 bottom-4 z-30 pointer-events-none space-y-2 md:space-y-4",
    className,
  );

  if (!active) {
    return null;
  }

  return (
    <Portal>
      <div className={classes} {...props}>
        <AnimatePresence onExitComplete={handleExitComplete}>{children}</AnimatePresence>
      </div>
    </Portal>
  );
}
