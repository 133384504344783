// @ts-nocheck
import { useMemo } from "react";

import { validateSupportedIsoCurrency } from "../utils/currency.js";
import { defaultLocale } from "../utils/locale.js";

const placeholder = "XYZ";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
export interface NumberFormatConfig extends Intl.NumberFormatOptions {
  compactDisplay?: "long" | "short";
  currencyDisplay?: "code" | "name" | "narrowSymbol" | "symbol";
  currencySign?: "accounting" | "standard";
  localeMatcher?: "best fit" | "lookup";
  locales?: string[] | string;
  notation?: "compact" | "engineering" | "scientific" | "standard";
  signDisplay?: "always" | "auto" | "never";
  style?: "currency" | "decimal" | "percent" | "unit";
  unitDisplay?: "long" | "narrow" | "short";
}

export function createNumberFormat({
  currency,
  locales = defaultLocale,
  style,
  maximumFractionDigits,
  minimumFractionDigits,
  ...options
}: NumberFormatConfig = {}): Intl.NumberFormat {
  const isSupportedIsoCurrency = validateSupportedIsoCurrency(currency);
  const isNonIsoCurrency = currency !== undefined && !isSupportedIsoCurrency;
  const numberFormat = new Intl.NumberFormat(locales, {
    // Either pass three uppercase alphabetic characters or the placeholder to the currency property
    currency: currency === undefined ? undefined : /^[A-Z]{3}$/.test(currency) ? currency : placeholder,

    // Defaults to
    // - Percent:                             min 2, max 2
    // - Supported ISO Currency (except JPY): min 2, max 2
    // - JPY Currency:                        min 0, max 0
    // - Other Currency (except BTC):         min 0, max 4
    // - BTC Currency:                        min 0, max 5
    // - Others:                              min 0, max 0
    maximumFractionDigits:
      currency === "JPY"
        ? 0
        : maximumFractionDigits ??
          (style === "percent" || isSupportedIsoCurrency ? 2 : isNonIsoCurrency ? (currency === "BTC" ? 5 : 4) : 0),
    minimumFractionDigits:
      currency === "JPY" ? 0 : minimumFractionDigits ?? (style === "percent" || isSupportedIsoCurrency ? 2 : 0),
    style:
      currency !== undefined
        ? "currency"
        : options.unit !== undefined
          ? "unit"
          : style === "percent" || style === "decimal"
            ? style
            : undefined,
    unitDisplay: options.unit === undefined ? undefined : "long",
    ...options,
  });

  return {
    ...numberFormat,
    format(...params: Parameters<Intl.NumberFormat["format"]>) {
      const value = numberFormat.format(...params);

      if (isNonIsoCurrency) {
        const currencyValue = currency && value.includes(placeholder) ? value.replace(placeholder, currency) : value;

        // Move currency symbol behind the amount
        return currencyValue.replace(/^([\w\s\-\+]*?)\s([\d\s.,]+)$/i, "$2 $1");
      }

      return value;
    },
    formatToParts(...params: Parameters<Intl.NumberFormat["formatToParts"]>) {
      const parts = numberFormat.formatToParts(...params);

      return parts.map((part) => ({
        ...part,
        value: (part.type === "currency" && part.value === placeholder ? currency : undefined) ?? part.value,
      }));
    },
    resolvedOptions(...params: Parameters<Intl.NumberFormat["resolvedOptions"]>) {
      const numberFormatOptions = numberFormat.resolvedOptions(...params);

      return {
        ...numberFormatOptions,
        currency: numberFormatOptions.currency === placeholder ? currency : numberFormatOptions.currency,
      };
    },
  };
}

export interface NumberFormatOptions extends NumberFormatConfig {
  value?: bigint | number;
}

export function useNumberFormat({ currency, value, ...props }: NumberFormatOptions) {
  const numberFormat = useMemo(() => createNumberFormat({ currency, ...props }), [currency, props]);
  const formattedValue = useMemo(
    () => (value !== undefined ? numberFormat.format(value) : undefined),
    [numberFormat, value],
  );

  return { formattedValue, instance: numberFormat };
}
