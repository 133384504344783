import type { Currency } from "@enzymefinance/environment";
import { currencies } from "@enzymefinance/environment";
import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import { useToasts } from "@enzymefinance/toast";
import type { ButtonProps, SelectProps } from "@enzymefinance/ui";
import { Button, Menu, ScreenReaderText, Select } from "@enzymefinance/ui";
import { defaultCurrency, useSettings } from "components/settings/Settings";
import { useCallback } from "react";

interface CurrencyOption {
  value: Currency;
  label: Currency;
}

const currencyOptions: CurrencyOption[] = Object.values(currencies).map((currency) => ({
  label: currency.id,
  value: currency.id,
}));

type CurrencyMenuProps =
  | {
      appearance?: "menu";
      onCurrencySelect?: () => void;
      size?: "xl";
    }
  | {
      appearance?: "select";
      onCurrencySelect?: () => void;
      size?: undefined;
    };

export function CurrencyMenu({ appearance = "menu", onCurrencySelect, size }: CurrencyMenuProps) {
  const {
    currency,
    setCurrency,
    // setVaultMetricsInDA, vaultMetricsInDA
  } = useSettings();
  const { toast } = useToasts();

  // Mobile handler
  const handleCurrencyChange = useCallback<NonNullable<SelectProps<CurrencyOption>["onChange"]>>(
    (event) => {
      const selectedCurrency = (event.target.value || defaultCurrency) as Currency;

      setCurrency(selectedCurrency);
      onCurrencySelect?.();
      toast("Switched Currency", { description: `Selected currency is now "${selectedCurrency}"`, kind: "info" });
    },
    [onCurrencySelect, setCurrency, toast],
  );

  // Desktop handler
  const switchToNetwork = useCallback(
    (selectedCurrency: Currency) => () => {
      setCurrency(selectedCurrency);
      onCurrencySelect?.();
      toast("Switched Currency", { description: `Selected currency is now "${selectedCurrency}"`, kind: "info" });
    },
    [onCurrencySelect, setCurrency, toast],
  );

  if (appearance === "select") {
    return (
      <Select<CurrencyOption>
        onChange={handleCurrencyChange}
        id="select-currency"
        label="Select Currency"
        options={currencyOptions}
        value={currency}
      />
    );
  }

  return (
    <Menu placement="bottom-end" size={size}>
      <Menu.Button<ButtonProps> as={Button} appearance="secondary" size={size} trailingIcon={ChevronDownIcon}>
        <span className="inline-flex">
          <ScreenReaderText>Select Currency</ScreenReaderText>
          <span className="text-sm font-medium" aria-hidden={true}>
            {currency}
          </span>
        </span>
      </Menu.Button>
      <Menu.Items>
        <Menu.Group>
          <p className="px-4 text-sm">Switch to:</p>
        </Menu.Group>
        <Menu.Group>
          {currencyOptions.map((option) => (
            <Menu.Item as="button" key={option.value} onClick={switchToNetwork(option.value)}>
              <span className="font-medium">{option.label}</span>
            </Menu.Item>
          ))}
          {/*
            TODO: uncomment this code when data pipeline will work properly
           <Menu.Item>
            <Checkbox
              className="text-high-emphasis"
              checked={vaultMetricsInDA}
              id="vault-metrics"
              onChange={() => setVaultMetricsInDA(!vaultMetricsInDA)}
              label={
                <span className="font-medium text-high-emphasis">
                  Vault Metrics in
                  <br />
                  Denomination
                  <br />
                  Asset
                </span>
              }
            />
          </Menu.Item> */}
        </Menu.Group>
      </Menu.Items>
    </Menu>
  );
}
