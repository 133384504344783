import { Relayer } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { isAddressEqual, zeroAddress } from "viem";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useGasRelayerBalance = createUseValidatedClientQuery(["gas-relayer-balance"], {
  paramsSchema: address({ allowZeroAddress: false }),
  responseSchema: z.bigint().nullable(),
  queryFn: async (comptrollerProxy, client) => {
    const gasRelayPaymaster = await Relayer.getGasRelayPaymaster(client, { comptrollerProxy });

    if (isAddressEqual(gasRelayPaymaster, zeroAddress)) {
      return null;
    }

    return Relayer.getRelayerBalance(client, { gasRelayPaymaster });
  },
});
