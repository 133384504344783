import { Address } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address as AddressType } from "viem";

interface AddManagersInput {
  managers: AddressType[];
}

export const addManagers: FunctionConfig<AddManagersInput> = {
  Description({ inputs }) {
    const address = inputs.managers[0];

    return (
      <>
        <div className="flex">
          <span className="mr-1">Add</span>
          <span className="hidden md:block">
            <Address address={address} />
          </span>
          <div className="md:hidden">
            <Address address={address} trimmed={true} />
          </div>
        </div>
        <div>as a manager</div>
      </>
    );
  },
  Label() {
    return <>Add Manager</>;
  },
  signature: "function addManagers(address[] managers)",
};
