import type { Address, Asset, Hex } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import {
  Currency,
  CurrencySlug,
  Deployment,
  Environment,
  Exchange,
  Network,
  NetworkSlug,
  Version,
  getCurrency,
  getNetwork,
} from "@enzymefinance/environment";
import { ExternalPositionType } from "@enzymefinance/utils";
import { getAddress, isAddress, isAddressEqual, isHex, maxUint256, parseUnits, zeroAddress } from "viem";
import type { ZodBigInt, ZodEffects, ZodNumber, ZodSchema, ZodType, ZodTypeAny } from "zod";
import { z } from "zod";

import { hasAsset } from "./utils/hasAsset.js";
import { hasValue } from "./utils/hasValue.js";
import { loadImage } from "./utils/image.js";

const defaultDecimals = 18;

export interface TokenNumberInputValue<TAsset = Asset> {
  token: TAsset;
  value: string;
}

export interface TokenNumberOutputValue<TValue = bigint> {
  token: Asset;
  value: TValue;
}

export interface TokenInputValue {
  token: Asset;
  value: bigint;
}

export enum ColorScheme {
  DARK = "DARK",
  LIGHT = "LIGHT",
  SYSTEM = "SYSTEM",
}

export function datish() {
  return z
    .string()
    .or(z.number())
    .or(z.instanceof(Date))
    .transform((value) => new Date(value));
}

export function maybe<T extends ZodTypeAny, TDefault>(schema: T, or: TDefault) {
  return schema.nullish().transform((value) => value ?? or);
}

export function boolish() {
  return z.preprocess((value) => (value === 0 ? false : value === 1 ? true : value), z.boolean());
}

export function adapter() {
  return z.object({ id: address({ caseInsensitive: true }), name: z.string(), url: z.optional(z.string()) });
}

export function externalPosition() {
  return z.object({
    id: address({ caseInsensitive: true }),
    name: z.string(),
    url: z.optional(z.string()),
    externalPositionIdentifier: z.number(),
  });
}

export function address({ caseInsensitive = false, allowZeroAddress = true, outputChecksum = false } = {}) {
  return z
    .custom<string>(
      (value) => {
        if (typeof value !== "string") {
          return false;
        }

        const isAddressValue = caseInsensitive
          ? value.startsWith("0x") && isAddress(value)
          : /^0x[0-9a-f]{40}$/.test(value);

        return allowZeroAddress ? isAddressValue : isAddressValue && !isAddressEqual(toAddress(value), zeroAddress);
      },
      { message: "The value must be a valid address." },
    )
    .transform((value) => {
      const address = getAddress(value);
      if (outputChecksum) {
        return address;
      }

      return address.toLowerCase() as Address;
    });
}

export function hex() {
  return z
    .string()
    .regex(/^0x[0-9a-z]?/i, {
      message: "The value must be a hex.",
    })
    .transform((value) => value as Hex);
}

export function asset() {
  return z.object(
    {
      decimals: z.number(),
      id: address({ caseInsensitive: true }),
      name: z.string(),
      symbol: z.string(),
      network: z.number(),
      registered: z.boolean(),
    },
    { invalid_type_error: "Invalid asset" },
  ) as unknown as ZodSchema<Asset>;
}

export function authenticatorSecret() {
  return z.string().regex(/^[a-zA-Z0-9]+$/, {
    message: "Can only contain alphanumeric characters.",
  });
}

export function authenticatorCode() {
  return z.string().length(6, "The verification code should be exact 6 digits long.");
}

export function backupCode() {
  return z.string().length(12, "The backup code should be exact 12 digits long.");
}

export function bigint() {
  return z.bigint({
    invalid_type_error: "The value must be a valid number.",
    required_error: "The value cannot be empty.",
  });
}

export function bigIntInput({
  decimals,
  allowZero = false,
  schema = bigint(),
}: {
  decimals: number;
  allowZero?: boolean;
  schema?: ZodBigInt | ZodEffects<any, bigint, bigint>;
}) {
  const s = bigIntish(schema, decimals).transform(
    refine((value) => maxUint256 > value, {
      message: `The value must be less than or equal to ${maxUint256}.`,
    }),
  );

  if (allowZero) {
    return s;
  }

  return s.transform(
    refine((value) => 0n < value, {
      message: "The value must greater than zero.",
    }),
  );
}

export function bigIntish(schema: ZodType<bigint>, decimals = defaultDecimals) {
  return z.preprocess((value) => {
    try {
      if (typeof value === "bigint") {
        return value;
      }

      if ((typeof value === "string" && value !== "") || typeof value === "number") {
        // Logic for parsing the value into a BigInt
        // This may be moved else where or be replaced by:
        // https://github.com/ethers-io/ethers.js/blob/v6-beta-exports/src.ts/utils/units.ts
        const [integer, fraction] = `${value}`.split(".");

        return fraction === undefined || fraction === ""
          ? typeof integer === "string" && integer.length > 0
            ? BigInt(integer) * 10n ** BigInt(decimals)
            : BigInt(integer ?? 0)
          : BigInt(`${integer ?? ""}${fraction.slice(0, decimals).padEnd(decimals, "0")}`);
      }

      return undefined;
    } catch {
      return undefined;
    }
  }, schema);
}

export function colorScheme() {
  return z.nativeEnum(ColorScheme);
}

export function contactInfo() {
  return z.string().max(160);
}

export function cuid() {
  return z.string();
}

export function currency() {
  return z.nativeEnum(Currency);
}

export function currencySlug() {
  return z.nativeEnum(CurrencySlug);
}

export function currencyOrSlug() {
  return z.union([currency(), currencySlug()]).transform((value) => getCurrency(value).id);
}

export function dateTime() {
  return z.coerce.date();
}

export function deployment() {
  return z.nativeEnum(Deployment);
}

export function externalPositionType() {
  return z.nativeEnum(ExternalPositionType);
}

export function description() {
  return z.string().min(3).max(2000);
}

export function email() {
  return z.string().email().max(320);
}

export function environment<TVersion extends Version = Version.SULU>(version?: TVersion) {
  return z.custom<Environment<TVersion>>(
    (data) => data instanceof Environment && Environment.isVersion(version ?? Version.SULU, data),
  );
}

export function exchange() {
  return z.nativeEnum(Exchange);
}

interface FileOptions {
  /** Maximum file size in bytes */
  max?: number;
  /** Image dimensions in pixels */
  dimensions?: {
    /** Image width in pixels */
    x: number;
    /** Image height in pixels */
    y: number;
  };
}

const bytesInKiloByte = 10 ** 3;

const bytesInMegaByte = 10 ** 6;

// This schema validation differs from the node implementation.
// TODO: Make these isomorphic
export function file(options?: FileOptions) {
  let divider = 1;
  let measurement = "bytes";

  if (options?.max !== undefined) {
    if (options.max >= bytesInKiloByte) {
      divider = bytesInKiloByte;
      measurement = "KB";
    }

    if (options.max >= bytesInMegaByte) {
      divider = bytesInMegaByte;
      measurement = "MB";
    }
  }

  return (
    z
      .any()
      // Needs manual check for instance because sometimes the PNG is not recognized as a file
      .refine((value) => value instanceof File || value === undefined, { message: "Invalid file type" })
      .refine(
        (value: File | undefined) =>
          options?.max !== undefined && value?.size !== undefined ? value.size <= options.max : true,
        {
          message:
            options?.max !== undefined
              ? `File too big, it must not be bigger than ${options.max / divider} ${measurement}.`
              : undefined,
        },
      )
      .refine(
        async (value: File | undefined) => {
          const image = value ? await loadImage(URL.createObjectURL(value)) : undefined;

          return (
            // Skip validating dimensions on SVGs
            value?.type === "image/svg+xml" ||
            (options?.dimensions && image
              ? image.width <= options.dimensions.x && image.height <= options.dimensions.y
              : true)
          );
        },
        {
          message: options?.dimensions
            ? `Invalid image dimensions. It must not be bigger than ${options.dimensions.x}x${options.dimensions.y} px.`
            : undefined,
        },
      )
  );
}

// Reference: https://developers.google.com/identity/protocols/oauth2#size
export function googleAuthCode() {
  return z.string().max(2048);
}

export function hexColor() {
  return z.string().regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/, {
    message: "Invalid Hex Color",
  });
}

export function messageHash() {
  return z.string().refine((value) => isHex(value), {
    message: "The value must be a valid message hash",
  });
}

export function name() {
  return z
    .string()
    .min(3, "Name must be at least three characters long.")
    .max(50, "Name must be at maximum 50 characters long.")
    .refine((value) => !/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#.]?[\w-]+)*\/?/.test(value), {
      message: "Name cannot contain a link.",
    });
}

export function network() {
  return z.nativeEnum(Network);
}

export function maxSlippage(maxPercentage = 15, errorMessage = "The slippage must be less than 15%.") {
  return numberInput(percentage(z.number().max(maxPercentage, errorMessage)));
}

export function networkSlug() {
  return z.nativeEnum(NetworkSlug);
}

export function networkOrSlug() {
  return z.union([network(), networkSlug()]).transform((value) => getNetwork(value).id);
}

export function numberInput(
  schema: ZodEffects<any, number, number> | ZodNumber,
): ZodEffects<ZodNumber, z.infer<typeof schema>, string> {
  // TODO: Fix typing
  return numberish(schema as ZodNumber) as ZodEffects<ZodNumber, z.infer<typeof schema>, string>;
}

export function numberish<T extends ZodTypeAny>(schema: T) {
  return z.preprocess((value) => {
    if ((typeof value === "string" && value !== "" && !Number.isNaN(Number(value))) || typeof value === "bigint") {
      return Number(value);
    }

    if (typeof value === "number") {
      return value;
    }

    return undefined;
  }, schema);
}

export function numberishRange({ min, max }: { min: number; max: number }) {
  return z.string().pipe(z.coerce.number().min(min).max(max));
}

export function percentage(schema: ZodEffects<any, number, number> | ZodNumber) {
  return schema.transform((value) => value / 100);
}

export function percentageRange({ min, max }: { min: number; max: number }) {
  return (
    z
      // Input is number.
      .string()
      // Parse string to number.
      .pipe(z.coerce.number())
      // Convert to percentage.
      .transform((number) => number / 100)
      // Clamp to min - max.
      .pipe(z.number().min(min).max(max))
  );
}

export function password() {
  return z
    .string()
    .min(8, "Password must be at least eight characters long.")
    .max(128, "Password must be at maximum 128 characters long.");
}

export function reCaptchaToken() {
  // reCAPTCHA string can be any length
  // https:// groups.google.com/g/recaptcha/c/6aebJpJ6I3c/m/LZSxLdaa12YJ
  return z.string();
}

export function resetPasswordCode() {
  return z.string().length(12);
}

export function signature() {
  return z.string().max(2048);
}

export function slippage() {
  return z.number().min(0).max(1);
}

export function subdomain() {
  return (
    z
      .string()
      .min(5, { message: "Too short. Minimum 5 characters allowed." })
      .max(32, { message: "Too long. Maximum 32 characters allowed." })
      // Allow alphanumeric and dash
      .regex(/^[a-z0-9][a-z0-9\-]{0,30}[a-z0-9]$/, {
        message: "Can only contain (lowercase) alphanumeric characters and dash in-between.",
      })
      .refine((value) => !(value.startsWith("-") || value.endsWith("-")), {
        message: "Subdomains cannot start or and with a dash",
      })
      .refine((value) => !value.includes("--"), { message: "Subdomains cannot have subsequent dashes (eg. --)." })
  );
}

export function telegram() {
  // Source: https://limits.tginfo.me/en
  return (
    z
      .string()
      // Allow alphanumeric and underscore
      .regex(/^[a-zA-Z0-9_]+$/, {
        message: "Username can only contain alphanumeric characters and underscores.",
      })
      .min(5)
      .max(32)
  );
}

export function tagline() {
  return z.string().max(160);
}

export function timestamp() {
  return z.number().min(1000000).max(9999999999999);
}

export function twitter() {
  // Source: https://help.twitter.com/en/managing-your-account/twitter-username-rules#:~:text=Your%20username%20cannot%20be%20longer,for%20the%20sake%20of%20ease.
  return (
    z
      .string()
      // Allow alphanumeric and underscore
      .regex(/^[a-zA-Z0-9_]+$/, {
        message: "Username can only contain alphanumeric characters and underscores.",
      })
      .min(4)
      .max(15)
  );
}

function isPlainUrl(value: string) {
  try {
    const url = new URL(value);
    if (url.protocol !== "http:" && url.protocol !== "https:" && url.protocol !== "blob:") {
      return false;
    }

    if (url.password || url.username || url.port || url.search || url.hash) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
}

export function url() {
  return z
    .string()
    .url()
    .max(2048)
    .transform((value, ctx) => {
      if (isPlainUrl(value)) {
        return value;
      }

      ctx.addIssue({
        message: "Invalid url",
        fatal: true,
        code: z.ZodIssueCode.custom,
      });

      return z.NEVER;
    });
}

// Enzyme username
export function username() {
  return (
    z
      .string()
      // Allow alphanumeric and underscore
      .regex(/^[a-zA-Z0-9_]+$/, {
        message: "Username can only contain alphanumeric characters and underscores.",
      })
      .min(3)
      .max(20)
  );
}

export function sessionToken() {
  return z.string().regex(/^[a-f0-9]+\.[a-f0-9]+$/, {
    message: "Invalid session token",
  });
}

export function tokenBigIntInput(
  schema = z.object({
    token: asset(),
    value: z.bigint(),
  }),
) {
  return z
    .preprocess((data) => {
      if (!hasAsset(data)) {
        return undefined;
      }

      if (!hasValue(data, "string")) {
        return { token: data.token };
      }

      try {
        return {
          ...data,
          value: bigIntInput({ decimals: data.token.decimals, schema: schema.shape.value }).parse(data.value),
        };
      } catch {
        return { token: data.token, ...(data.value === "" || data.value === "0" ? { value: 0n } : {}) };
      }
    }, schema)
    .refine((value) => maxUint256 > value.value, {
      message: `The value must be a less than or equal to ${maxUint256}.`,
    }) as ZodEffects<any, TokenNumberOutputValue, TokenNumberInputValue>;
}

export const tokenValue = z.object({
  token: asset(),
  value: z.bigint(),
});

export function transformTokenValue<TAssset extends { decimals: number }>({
  values,
  ctx,
}: { values: { value: string; token: TAssset }; ctx: z.RefinementCtx }) {
  try {
    const stripped = values.value
      .split(".", 2)
      .map((value) => value || "0")
      .join(".");
    const value = parseUnits(stripped, values.token.decimals);

    if (value <= maxUint256) {
      return {
        ...values,
        // TOOD: use viem parseUnits once it will be introduced
        value,
      };
    }
  } catch (_error: unknown) {
    const fractionalDecimalsError = "Fractional component exceeds decimals";
    if (
      typeof _error === "object" &&
      _error !== null &&
      "reason" in _error &&
      typeof _error.reason === "string" &&
      _error.reason === fractionalDecimalsError.toLowerCase()
    ) {
      ctx.addIssue({
        fatal: true,
        code: z.ZodIssueCode.custom,
        message: fractionalDecimalsError,
      });
      return z.NEVER;
    }
  }

  ctx.addIssue({
    fatal: true,
    code: z.ZodIssueCode.custom,
    message: `The value must be a less than or equal to ${maxUint256}.`,
  });

  return z.NEVER;
}

export const tokenInput = z
  .object({
    token: asset(),
    value: z.string().nonempty(),
  })
  .transform((values, ctx) => transformTokenValue({ values, ctx }));

export const tokenInputOptional = z
  .object({
    token: asset(),
    value: z.string().optional(),
  })
  .transform((values, ctx) => {
    const value = values.value;
    if (value === undefined || value === "") {
      return {
        ...values,
        value: 0n,
      };
    }

    return transformTokenValue({ values: { token: values.token, value }, ctx });
  });

export function refine<TValue>(condition: (value: TValue) => boolean, options: Omit<z.IssueData, "code" | "fatal">) {
  return (value: TValue, ctx: z.RefinementCtx) => {
    if (condition(value)) {
      return value;
    }

    ctx.addIssue({
      ...options,
      fatal: true,
      code: z.ZodIssueCode.custom,
    });

    return z.NEVER;
  };
}
