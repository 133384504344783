import type { InputProps as InputPropsBase } from "@enzymefinance/ui";
import { Input as InputBase } from "@enzymefinance/ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface InputProps<TFieldValues extends FieldValues = FieldValues> extends Omit<InputPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function Input<TFieldValues extends FieldValues = FieldValues>(props: InputProps<TFieldValues>) {
  const { field, fieldState, formState } = useController<TFieldValues>({ name: props.name });

  return (
    <InputBase
      error={fieldState.error?.message}
      readOnly={formState.isSubmitting}
      {...props}
      id={props.name}
      {...field}
    />
  );
}
