import type { FieldValues, UseFormReturn as UseFormReturnBase } from "react-hook-form";
import { useFormContext as useFormContextBase } from "react-hook-form";

import type { FormFieldValue } from "../types";
import type { UseFormProps } from "./useForm.js";

export interface UseFormContextReturn<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>
  extends UseFormReturnBase<TInput, TContext> {
  mode?: UseFormProps<TInput, TOutput, TContext>["mode"];
  reValidateMode?: UseFormProps<TInput, TOutput, TContext>["reValidateMode"];
}

export function useFormContext<TInput extends FieldValues = FieldValues>(): UseFormContextReturn<
  FormFieldValue & TInput
> {
  return useFormContextBase<FormFieldValue & TInput>();
}
