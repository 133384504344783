import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useMemo } from "react";
import { externalPositions } from "utils/externalPositions";

export function useExternalPositionById(externalPositionId: string) {
  const { externalPositionTypes } = useExternalPositions();

  const externalPosition = useMemo(() => {
    if (externalPositionTypes.length === 0) {
      return undefined;
    }

    const foundExternalPositionType = externalPositionTypes.find((position) => position.id === externalPositionId);

    if (foundExternalPositionType === undefined) {
      throw new Error("External position type not found");
    }

    return externalPositions[foundExternalPositionType.label];
  }, [externalPositionId, externalPositionTypes]);

  return externalPosition;
}
