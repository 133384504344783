import { LifeCycle, Utils } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useSignaledReconfiguration = createUseValidatedClientQuery(["signaled-reconfiguration"], {
  paramsSchema: z.object({
    fundDeployer: address({ allowZeroAddress: false }),
    release: address({ allowZeroAddress: false }),
    vaultProxy: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: ({ fundDeployer, vaultProxy, release }, client) => {
    const isCurrentRelease = Utils.Address.safeSameAddress(fundDeployer, release);

    if (!isCurrentRelease) {
      return false;
    }

    return LifeCycle.hasReconfigurationRequest(client, { fundDeployer, vaultProxy });
  },
});
