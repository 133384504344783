import { hexColor } from "@enzymefinance/validation";
import { parseToRgb, shade, tint } from "polished";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";

export const defaultPrimaryColor = "#6f56fd";
export const defaultSecondaryColor = "#6b7280";

function parseToRgbAndStringify(color: string) {
  const { red, green, blue } = parseToRgb(color);

  return `${red} ${green} ${blue}`;
}

function createSecondaryColorVariables(color: string) {
  const colorPalette: Record<500 | 600, string> = {
    500: color,
    600: shade(0.2, color),
  };

  return [
    `--color-secondary: ${parseToRgbAndStringify(colorPalette[500])}`,
    `--color-primary-focus: ${parseToRgbAndStringify(colorPalette[600])}`,
  ];
}

function createPrimaryColorVariables(color: string) {
  const colorPalette: Record<200 | 400 | 500 | 600 | 800, string> = {
    200: tint(0.6, color),
    400: tint(0.2, color),
    500: color,
    600: shade(0.2, color),
    800: shade(0.6, color),
  };

  return [
    `--color-primary: ${parseToRgbAndStringify(colorPalette[500])}`,
    `--color-primary-focus: ${parseToRgbAndStringify(colorPalette[600])}`,
    `--color-primary-lighter: ${parseToRgbAndStringify(colorPalette[200])}`,
    `--color-primary-light: ${parseToRgbAndStringify(colorPalette[400])}`,
    `--color-primary-dark: ${parseToRgbAndStringify(colorPalette[600])}`,
    `--color-primary-darker: ${parseToRgbAndStringify(colorPalette[800])}`,
  ];
}

function useThemeColors(primaryColor?: string, secondaryColor?: string) {
  const primaryColorVariables = useMemo(() => {
    if (!primaryColor || primaryColor === defaultPrimaryColor || !hexColor().safeParse(primaryColor).success) {
      return [];
    }

    return createPrimaryColorVariables(primaryColor);
  }, [primaryColor]);
  const secondaryColorVariables = useMemo(() => {
    if (!secondaryColor || secondaryColor === defaultSecondaryColor || !hexColor().safeParse(secondaryColor).success) {
      return [];
    }

    return createSecondaryColorVariables(secondaryColor);
  }, [secondaryColor]);

  return [...primaryColorVariables, ...secondaryColorVariables];
}

export interface ThemeColorsProps {
  primaryColor?: string;
  secondaryColor?: string;
}

export function ThemeColors({ primaryColor, secondaryColor }: ThemeColorsProps) {
  const cssVariables = useThemeColors(primaryColor, secondaryColor);

  if (!(primaryColor || secondaryColor)) {
    return null;
  }

  const styles = `:root {${cssVariables.join(";")}}`;

  return (
    <Helmet>
      <style>{styles}</style>
    </Helmet>
  );
}
