import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const buyPrincipalToken: ExternalPositionHandler<Integrations.PendleV2.BuyPrincipleTokenArgs> = {
  Description({ args: { depositTokenAddress, depositAmount, market } }) {
    const { environment } = useGlobals();

    const depositAsset = environment.getAsset(depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={depositAmount}
            numberFormat={{ currency: depositAsset.symbol }}
            decimals={depositAsset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Fixed Yield</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.PendleV2.buyPrincipleTokenDecode(encodedCallArgs),
};

export const sellPrincipalToken: ExternalPositionHandler<Integrations.PendleV2.SellPrincipleTokenArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.withdrawalTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.withdrawalAmount}
            numberFormat={{ currency: asset.symbol }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Withdraw Fixed Yield</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.PendleV2.sellPrincipleTokenDecode(encodedCallArgs),
};

export const addLiquidity: ExternalPositionHandler<Integrations.PendleV2.AddLiquidityArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.depositAmount}
            numberFormat={{ currency: asset.symbol }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Liquidity</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.PendleV2.addLiquidityDecode(encodedCallArgs),
};

export const removeLiquidity: ExternalPositionHandler<Integrations.PendleV2.RemoveLiquidityArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.withdrawalTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.withdrawalAmount}
            numberFormat={{ currency: asset.symbol }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Withdraw Liquidity</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.PendleV2.removeLiquidityDecode(encodedCallArgs),
};

export const claimRewards: ExternalPositionHandler<Integrations.PendleV2.ClaimRewardsArgs> = {
  Description({ args }) {
    return (
      <div>
        Claim Rewads on Markets:
        <div>
          {args.marketAddresses.map((market) => (
            <Address key={market} address={market} trimmed={true} />
          ))}
        </div>
      </div>
    );
  },
  Label() {
    return <>Claim Rewards</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.PendleV2.claimRewardsDecode(encodedCallArgs),
};

export const createPendleV2ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs, actionId } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);

    if (actionId === Integrations.PendleV2.Action.BuyPrincipalToken) {
      return <buyPrincipalToken.Description args={buyPrincipalToken.decodeExternalPositionArgs(actionArgs)} />;
    }

    if (actionId === Integrations.PendleV2.Action.AddLiquidity) {
      return <addLiquidity.Description args={addLiquidity.decodeExternalPositionArgs(actionArgs)} />;
    }

    throw new Error(`Unknown actionId: ${actionId}`);
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs, actionId } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);

    if (actionId === Integrations.PendleV2.Action.BuyPrincipalToken) {
      return <buyPrincipalToken.Label args={buyPrincipalToken.decodeExternalPositionArgs(actionArgs)} />;
    }

    if (actionId === Integrations.PendleV2.Action.AddLiquidity) {
      return <addLiquidity.Label args={addLiquidity.decodeExternalPositionArgs(actionArgs)} />;
    }

    throw new Error(`Unknown actionId: ${actionId}`);
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs, actionId } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);

    if (actionId === Integrations.PendleV2.Action.BuyPrincipalToken) {
      const args = buyPrincipalToken.decodeExternalPositionArgs(actionArgs);

      const Summary = getDefaultExtensionSummary(buyPrincipalToken.Label, buyPrincipalToken.Description);

      return <Summary args={args} />;
    }

    if (actionId === Integrations.PendleV2.Action.AddLiquidity) {
      const args = addLiquidity.decodeExternalPositionArgs(actionArgs);

      const Summary = getDefaultExtensionSummary(addLiquidity.Label, addLiquidity.Description);

      return <Summary args={args} />;
    }

    throw new Error(`Unknown actionId: ${actionId}`);
  },
};
