import { Navbar } from "components/layout/Navbar";
import { useCallback, useEffect } from "react";
import { useVersionUpdate } from "utils/hooks/useVersionUpdate";

import { useErrorBoundary } from "./ErrorBoundary";
import { ErrorBox } from "./ErrorBox";
import { ErrorPage } from "./ErrorPage";

interface ErrorFeedbackProps {
  error: Error;
  onReset: () => void;
}

export function ErrorFeedback({ error: _, onReset, ...props }: ErrorFeedbackProps) {
  const { isError, isLoading, isFetching, isUpdateAvailable } = useVersionUpdate();

  const { appearance, resetError, setResetError } = useErrorBoundary();
  const handleReset = useCallback(() => {
    if (resetError) {
      setResetError(undefined);
    }

    onReset();
  }, [onReset, resetError, setResetError]);

  useEffect(() => {
    if (resetError) {
      return;
    }

    setResetError(() => handleReset);
  }, [handleReset, resetError, setResetError]);

  if (appearance === "page") {
    return (
      <>
        {isUpdateAvailable ? null : <Navbar />}
        <main className="m-auto flex h-full max-w-4xl flex-1 flex-col justify-center space-y-6 p-4 lg:space-y-12 lg:p-12">
          <ErrorPage
            isOffline={isError}
            isLoading={isLoading && isFetching}
            isUpdateAvailable={isUpdateAvailable}
            onReset={handleReset}
            {...props}
          />
        </main>
      </>
    );
  }

  return <ErrorBox onReset={handleReset} {...props} />;
}
