import { Alert } from "@enzymefinance/ui";

export function VaultDepositUnknownFeeOrPolicyAlert() {
  return (
    <Alert appearance="warning" title="Unknown fee or policy">
      This vault has an unknown fee or policy. Please make sure to only deposit into this vault if you know and
      understand in detail how the unknown fee or policy works, otherwise you may potentially lose your whole deposit.
    </Alert>
  );
}
