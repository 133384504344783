import { createId } from "@paralleldrive/cuid2";
import type { ReactElement, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useList } from "react-use";
import type { ListActions } from "react-use/lib/useList";

export interface ToastItem {
  actions?: ReactElement | null;
  /**
   * Delay in ms
   */
  delay?: number;
  description?: string;
  icon?: ReactElement;
  id: string;
  message: string;
  kind?: "error" | "info" | "success" | "warning";
}

export interface ToastsOptions extends Pick<ToastItem, "delay" | "description" | "icon" | "kind"> {
  actions?: ReactElement | null;
}

export interface ToastsContextValues extends Pick<ListActions<ToastItem>, "filter"> {
  toast: {
    (message: string, options?: ToastsOptions): void;
    error: (options?: { title?: string; message?: string }) => void;
  };
  toasts: ToastItem[];
}

export const ToastsContext = createContext<ToastsContextValues | undefined>(undefined);

export function useToasts() {
  const context = useContext(ToastsContext);

  if (!context) {
    throw new Error("Missing toasts context");
  }

  return context;
}

interface ToastsProps {
  children: ReactNode;
}

export function ToastsProvider({ children }: ToastsProps) {
  const [toasts, actions] = useList<ToastItem>([]);
  const toast = useMemo(() => {
    const fn = (message: string, options?: ToastsOptions) => actions.push({ ...options, id: createId(), message });

    fn.error = (options?: { message?: string; title?: string }) =>
      toast(options?.title ?? "Something went wrong!", {
        description: options?.message ?? "Please try reload the page and retry the action.",
        kind: "error",
      });

    return fn;
  }, [actions]);

  return <ToastsContext.Provider value={{ filter: actions.filter, toast, toasts }}>{children}</ToastsContext.Provider>;
}
