import type { NumberDisplayProps } from "@enzymefinance/ui";
import { NumberDisplay } from "@enzymefinance/ui";

import { useFormatBigInt } from "../../hooks/useFormatBigInt.js";

export interface BigIntDisplayProps extends Omit<NumberDisplayProps, "value"> {
  decimals?: number;
  value?: bigint;
}

export function BigIntDisplay({ decimals, value: valueBase, ...props }: BigIntDisplayProps) {
  const { floatValue } = useFormatBigInt(valueBase, decimals);

  return <NumberDisplay value={floatValue} {...props} />;
}
