import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const setNominatedOwner: FunctionConfig<{ nextNominatedOwner: Address }> = {
  signature: "function setNominatedOwner(address nextNominatedOwner)",

  Description({ inputs }) {
    const nextOwner = inputs.nextNominatedOwner;

    return <>Nominate a new vault owner at address {nextOwner}.</>;
  },

  Label() {
    return <>Nominate New Owner</>;
  },
};
