import type { ReactNode } from "react";
import { createContext, useContext } from "react";

interface CustomPropsContextValues {
  isExpandable: boolean;
  itemSize: number;
  label: ReactNode;
}

const CustomPropsContext = createContext<CustomPropsContextValues | undefined>(undefined);

export function useCustomProps() {
  const context = useContext(CustomPropsContext);

  if (!context) {
    throw new Error("Missing custom props context");
  }

  return context;
}

export interface CustomPropsProviderProps {
  children: ReactNode;
  value: CustomPropsContextValues;
}

export function CustomPropsProvider(props: CustomPropsProviderProps) {
  return <CustomPropsContext.Provider {...props} />;
}
