import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Skeleton } from "@enzymefinance/ui";
import { useSharesWrapperVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const sharesWrapperDepositRequest: FunctionConfig<{
  depositAssetContract: Address;
  depositAssetAmount: bigint;
}> = {
  signature: "function requestDeposit(address depositAssetContract, uint256 depositAssetAmount) returns (uint256)",

  Description({ inputs, address }) {
    const depositAmount = BigInt(inputs.depositAssetAmount);

    const data = useSharesWrapperVaultDetailsQuery({
      variables: { id: toAddress(address) },
    });

    const details = data.data?.gatedRedemptionQueueSharesWrapper?.vault;

    if (data.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Request deposit{" "}
        <BigIntDisplay
          numberFormat={{
            currency: details?.comptroller.denomination.symbol,
            maximumFractionDigits: details?.comptroller.denomination.decimals,
          }}
          decimals={details?.comptroller.denomination.decimals}
          value={depositAmount}
        />{" "}
        into {details?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Request deposit</>;
  },
};
