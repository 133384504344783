import { useCallback, useState } from "react";

/**
 * Encapsulates a boolean variable and its memoized callbacks to set it to true and false
 * @param initialValue initial state value
 * @returns [value, setTrue, setFalse, setValue]
 */
export function useBoolean(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse, setValue] as const;
}
