import type { NotFoundPageProps } from "components/error/NotFoundPage";
import { NotFoundPage } from "components/error/NotFoundPage";
import { PageLayout } from "components/layout/PageLayout";

type NotFoundRouteProps = NotFoundPageProps;

export function NotFoundRoute(props: NotFoundRouteProps) {
  return (
    <PageLayout className="flex flex-1 flex-col justify-center">
      <NotFoundPage {...props} />
    </PageLayout>
  );
}

export default NotFoundRoute;
