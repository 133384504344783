import type { ButtonProps } from "@enzymefinance/ui";
import { Button } from "@enzymefinance/ui";
import { forwardRef } from "react";
import { useFormState } from "react-hook-form";

export interface SubmitButtonProps
  extends Omit<ButtonProps, "children" | "type">,
    Partial<Pick<ButtonProps, "children">> {}

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(function SubmitButton(
  { children = "Submit", disabled, loading, ...props },
  ref,
) {
  const formState = useFormState();

  return (
    <Button
      type="submit"
      disabled={disabled || formState.isSubmitting || !formState.isValid || formState.isValidating}
      loading={loading || formState.isSubmitting || formState.isValidating}
      ref={ref}
      {...props}
    >
      {children}
    </Button>
  );
});
