import { Depositor } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useLastSharesBoughtTimestampForAccount = createUseValidatedClientQuery(
  ["last-shares-bought-timestamp-for-account"],
  {
    paramsSchema: z.object({
      comptrollerProxy: address({ allowZeroAddress: false }),
      account: address({ allowZeroAddress: false }),
    }),
    responseSchema: bigint(),
    queryFn: (params, client) =>
      Depositor.getLastSharesBoughtTimestamp(client, {
        comptrollerProxy: params.comptrollerProxy,
        depositor: params.account,
      }),
  },
);
