import { toAddress } from "@enzymefinance/environment";
import type { ReleaseContracts } from "@enzymefinance/utils";
import { vaultConfigs } from "components/vault/config/VaultConfig";
import { useMemo } from "react";
import { isAddressEqual } from "viem";

export function useVaultConfigsByAddress(contracts: ReleaseContracts, addresses?: string[]) {
  return useMemo(() => {
    if (!addresses) {
      return [];
    }

    return Object.values(vaultConfigs).filter((item) => {
      const feeAddress = item.address(contracts);

      return addresses.some((address) => isAddressEqual(toAddress(address), feeAddress));
    });
  }, [contracts, addresses]);
}
