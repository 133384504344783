import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

export const erc4626Lend: IntegrationHandler<Integrations.Erc4626.LendArgs> = {
  Description({ args: { erc4626Vault, underlyingAssetAmount, minIncomingSharesAmount } }) {
    const { environment } = useGlobals();

    const erc4626Token = environment.getAssetAs(erc4626Vault, AssetType.ERC_4626);

    const underlyingAsset = environment.getAsset(erc4626Token.underlying);

    return (
      <>
        Lending on {erc4626Token.protocol}{" "}
        <BigIntDisplay
          value={underlyingAssetAmount}
          decimals={underlyingAsset.decimals}
          numberFormat={{ currency: underlyingAsset.symbol }}
        />{" "}
        and receiving a minimum of{" "}
        <BigIntDisplay
          value={minIncomingSharesAmount}
          decimals={erc4626Token.decimals}
          numberFormat={{ currency: erc4626Token.symbol }}
        />
        .
      </>
    );
  },
  Label({ args: { erc4626Vault } }) {
    const { environment } = useGlobals();

    const erc4626Token = environment.getAssetAs(erc4626Vault, AssetType.ERC_4626);

    return <>Lend on {erc4626Token.protocol}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Erc4626.lendDecode(encodedCallArgs),
};

export const erc4626Redeem: IntegrationHandler<Integrations.Erc4626.RedeemArgs> = {
  Description({ args: { erc4626Vault, sharesAmount, minIncomingUnderlyingAssetAmount } }) {
    const { environment } = useGlobals();

    const erc4626Token = environment.getAssetAs(erc4626Vault, AssetType.ERC_4626);

    const underlyingAsset = environment.getAsset(erc4626Token.underlying);

    return (
      <>
        Redeeming on {erc4626Token.protocol}{" "}
        <BigIntDisplay
          value={sharesAmount}
          decimals={erc4626Token.decimals}
          numberFormat={{ currency: erc4626Token.symbol }}
        />{" "}
        and receiving a minimum of{" "}
        <BigIntDisplay
          value={minIncomingUnderlyingAssetAmount}
          decimals={underlyingAsset.decimals}
          numberFormat={{ currency: underlyingAsset.symbol }}
        />
        .
      </>
    );
  },
  Label({ args: { erc4626Vault } }) {
    const { environment } = useGlobals();

    const erc4626Token = environment.getAssetAs(erc4626Vault, AssetType.ERC_4626);

    return <>Redeem on {erc4626Token.protocol}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Erc4626.redeemDecode(encodedCallArgs),
};
