import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";

// Check if an element is truncated
// http://stackoverflow.com/a/27155342/2131573
// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
function isTruncated(element: HTMLElement | null) {
  return !!(element && element.scrollWidth > element.clientWidth);
}

export function useTruncated<TElement extends HTMLElement = HTMLElement>(initialValue: TElement | null = null) {
  const ref = useRef<TElement>(initialValue);
  const { width, height } = useWindowSize();
  const [truncated, setTruncated] = useState(false);

  useEffect(() => {
    const state = isTruncated(ref.current);

    if (truncated !== state) {
      setTruncated(state);
    }
  }, [width, height]);

  return { ref, truncated };
}
