
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Activity": [
      "AaveDebtPositionChange",
      "AaveV3DebtPositionChange",
      "AlicePositionChange",
      "ArbitraryLoanPositionChange",
      "AssetAdded",
      "AssetRemoved",
      "CompoundDebtPositionChange",
      "ConvexVotingPositionChange",
      "ExternalPositionAddedEvent",
      "ExternalPositionRemovedEvent",
      "FeeSharesAllocationChangedEvent",
      "FeeSharesBurnedEvent",
      "FeeSharesPaidEvent",
      "FeeSharesReceivedEvent",
      "FreelyTransferableSharesSetEvent",
      "GMXV2LeverageTradingPositionChange",
      "GasRelayerDeposited",
      "GasRelayerWithdrawn",
      "KilnStakingPositionChange",
      "LidoWithdrawalsPositionChange",
      "LiquityDebtPositionChange",
      "MapleLiquidityPositionChange",
      "NetworkReleaseChanged",
      "PendleV2PositionChange",
      "PolicyDisabledForVault",
      "PolicyEnabledForVault",
      "PricelessAssetBypass",
      "PricelessAssetTimelock",
      "ProtocolFeeBurned",
      "ProtocolFeePaid",
      "SharesBoughtEvent",
      "SharesRedeemedEvent",
      "SharesTransferEvent",
      "SharesTransferredInEvent",
      "SharesTransferredOutEvent",
      "StakeWiseStakingPositionChange",
      "TheGraphDelegationPositionChange",
      "TrackedAssetAdded",
      "TrackedAssetRemoved",
      "Trade",
      "UniswapV3LiquidityPositionChange",
      "UnknownExternalPositionChange",
      "VaultCallDeregisteredEvent",
      "VaultCallRegisteredEvent",
      "VaultCreated",
      "VaultMigrationCancelled",
      "VaultMigrationExecuted",
      "VaultMigrationSignalled",
      "VaultNominatedOwnerRemoved",
      "VaultNominatedOwnerSet",
      "VaultOwnershipTransferred",
      "VaultReconfigurationCancelled",
      "VaultReconfigurationExecuted",
      "VaultReconfigurationSignalled"
    ],
    "DepositorActivity": [
      "FeeSharesAllocationChangedEvent",
      "FeeSharesPaidEvent",
      "FeeSharesReceivedEvent",
      "SharesBoughtEvent",
      "SharesRedeemedEvent",
      "SharesTransferredInEvent",
      "SharesTransferredOutEvent"
    ],
    "ExternalPosition": [
      "AaveDebtPosition",
      "AaveV3DebtPosition",
      "AlicePosition",
      "ArbitraryLoanPosition",
      "CompoundDebtPosition",
      "ConvexVotingPosition",
      "GMXV2LeverageTradingPosition",
      "KilnStakingPosition",
      "LidoWithdrawalsPosition",
      "LiquityDebtPosition",
      "MapleLiquidityPosition",
      "PendleV2Position",
      "StakeWiseStakingPosition",
      "TheGraphDelegationPosition",
      "UniswapV3LiquidityPosition",
      "UnknownExternalPosition"
    ],
    "ExternalPositionChange": [
      "AaveDebtPositionChange",
      "AaveV3DebtPositionChange",
      "AlicePositionChange",
      "ArbitraryLoanPositionChange",
      "CompoundDebtPositionChange",
      "ConvexVotingPositionChange",
      "GMXV2LeverageTradingPositionChange",
      "KilnStakingPositionChange",
      "LidoWithdrawalsPositionChange",
      "LiquityDebtPositionChange",
      "MapleLiquidityPositionChange",
      "PendleV2PositionChange",
      "StakeWiseStakingPositionChange",
      "TheGraphDelegationPositionChange",
      "UniswapV3LiquidityPositionChange",
      "UnknownExternalPositionChange"
    ],
    "Fee": [
      "EntranceRateBurnFee",
      "EntranceRateDirectFee",
      "ExitRateBurnFee",
      "ExitRateDirectFee",
      "ManagementFee",
      "MinSharesSupplyFee",
      "PerformanceFee",
      "UnknownFee"
    ],
    "GatedRedemptionQueueSharesWrapperSharesChange": [
      "GatedRedemptionQueueSharesWrapperDeposit",
      "GatedRedemptionQueueSharesWrapperKick",
      "GatedRedemptionQueueSharesWrapperRedemption",
      "GatedRedemptionQueueSharesWrapperTransferIn",
      "GatedRedemptionQueueSharesWrapperTransferInForced",
      "GatedRedemptionQueueSharesWrapperTransferOut",
      "GatedRedemptionQueueSharesWrapperTransferOutForced"
    ],
    "NetworkActivity": [
      "AssetAdded",
      "AssetRemoved",
      "NetworkReleaseChanged",
      "VaultCallDeregisteredEvent",
      "VaultCallRegisteredEvent"
    ],
    "Policy": [
      "AdapterBlacklistPolicy",
      "AdapterWhitelistPolicy",
      "AllowedAdapterIncomingAssetsPolicy",
      "AllowedAdaptersPerManagerPolicy",
      "AllowedAdaptersPolicy",
      "AllowedAssetsForRedemptionPolicy",
      "AllowedDepositRecipientsPolicy",
      "AllowedExternalPositionTypesPerManagerPolicy",
      "AllowedExternalPositionTypesPolicy",
      "AllowedRedeemersForSpecificAssetsPolicy",
      "AllowedSharesTransferRecipientsPolicy",
      "AssetBlacklistPolicy",
      "AssetWhitelistPolicy",
      "BuySharesCallerWhitelistPolicy",
      "CumulativeSlippageTolerancePolicy",
      "DepositorWhitelistPolicy",
      "DisallowedAdapterIncomingAssetsPolicy",
      "GuaranteedRedemptionPolicy",
      "MaxConcentrationPolicy",
      "MinAssetBalancesPostRedemptionPolicy",
      "MinMaxDepositPolicy",
      "NoDepegOnRedeemSharesForSpecificAssetsPolicy",
      "OnlyRemoveDustExternalPositionPolicy",
      "OnlyUntrackDustOrPricelessAssetsPolicy",
      "UnknownPolicy"
    ],
    "SharesChange": [
      "FeeSharesAllocationChangedEvent",
      "FeeSharesBurnedEvent",
      "FeeSharesPaidEvent",
      "FeeSharesReceivedEvent",
      "ProtocolFeeBurned",
      "ProtocolFeePaid",
      "SharesBoughtEvent",
      "SharesRedeemedEvent",
      "SharesTransferredInEvent",
      "SharesTransferredOutEvent"
    ],
    "VaultActivity": [
      "AaveDebtPositionChange",
      "AaveV3DebtPositionChange",
      "AlicePositionChange",
      "ArbitraryLoanPositionChange",
      "CompoundDebtPositionChange",
      "ConvexVotingPositionChange",
      "ExternalPositionAddedEvent",
      "ExternalPositionRemovedEvent",
      "FeeSharesAllocationChangedEvent",
      "FeeSharesBurnedEvent",
      "FeeSharesPaidEvent",
      "FeeSharesReceivedEvent",
      "FreelyTransferableSharesSetEvent",
      "GMXV2LeverageTradingPositionChange",
      "GasRelayerDeposited",
      "GasRelayerWithdrawn",
      "KilnStakingPositionChange",
      "LidoWithdrawalsPositionChange",
      "LiquityDebtPositionChange",
      "MapleLiquidityPositionChange",
      "PendleV2PositionChange",
      "PolicyDisabledForVault",
      "PolicyEnabledForVault",
      "PricelessAssetBypass",
      "PricelessAssetTimelock",
      "ProtocolFeeBurned",
      "ProtocolFeePaid",
      "SharesBoughtEvent",
      "SharesRedeemedEvent",
      "SharesTransferEvent",
      "StakeWiseStakingPositionChange",
      "TheGraphDelegationPositionChange",
      "TrackedAssetAdded",
      "TrackedAssetRemoved",
      "Trade",
      "UniswapV3LiquidityPositionChange",
      "UnknownExternalPositionChange",
      "VaultCreated",
      "VaultMigrationCancelled",
      "VaultMigrationExecuted",
      "VaultMigrationSignalled",
      "VaultNominatedOwnerRemoved",
      "VaultNominatedOwnerSet",
      "VaultOwnershipTransferred",
      "VaultReconfigurationCancelled",
      "VaultReconfigurationExecuted",
      "VaultReconfigurationSignalled"
    ]
  }
};
      export default result;
    