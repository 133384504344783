import { ErrorBoundary as ErrorBoundaryBase } from "@sentry/react";
import { useLoadingBoundary } from "components/common/LoadingBoundary";
import type { ComponentPropsWithoutRef, Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useCallback, useContext, useState } from "react";

import { ErrorFallback } from "./ErrorFallback";

type ErrorBoundaryAppearance = "box" | "page";

interface ErrorBoundaryContextValues {
  appearance: ErrorBoundaryAppearance;
  resetError?: () => void;
  setResetError: Dispatch<SetStateAction<(() => void) | undefined>>;
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextValues>({
  appearance: "page",
  resetError: () => {},
  setResetError: () => {},
});

export function useErrorBoundary() {
  return useContext(ErrorBoundaryContext);
}

interface ErrorBoundaryProps
  extends Pick<ComponentPropsWithoutRef<typeof ErrorBoundaryBase>, "fallback" | "onError" | "onError"> {
  appearance?: ErrorBoundaryAppearance;
  children?: ReactNode;
}

export function ErrorBoundary({
  appearance = "page",
  fallback = ErrorFallback,
  onError,
  ...props
}: ErrorBoundaryProps) {
  const [resetError, setResetError] = useState<() => void>();
  const { hideLoading } = useLoadingBoundary();
  const handleError = useCallback<NonNullable<ErrorBoundaryProps["onError"]>>(
    (...args) => {
      hideLoading();
      onError?.(...args);
    },
    [hideLoading, onError],
  );

  return (
    <ErrorBoundaryContext.Provider value={{ appearance, resetError, setResetError }}>
      <ErrorBoundaryBase fallback={fallback} onError={handleError} {...props} />
    </ErrorBoundaryContext.Provider>
  );
}
