import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactElement } from "react";

export interface SectionHeadingProps extends ComponentPropsWithoutRef<"h3"> {
  actions?: ReactElement | null;
  wrap?: boolean;
  fontWeight?: "heavy" | "medium";
}

export function SectionHeading({ actions, wrap = true, fontWeight = "heavy", ...props }: SectionHeadingProps) {
  const wrapperClasses = classNames("pb-6 px-4 sm:px-0", {
    "flex items-center justify-between": !wrap,
    "space-y-3 sm:space-y-0 sm:space-x-4 sm:flex sm:items-center sm:justify-between": wrap,
  });

  return (
    <div className={wrapperClasses}>
      <SectionHeading.Title fontWeight={fontWeight} {...props} />
      {actions ? <div className="flex items-center space-x-3">{actions}</div> : null}
    </div>
  );
}

type SectionHeadingTitleProps = ComponentPropsWithoutRef<"h3"> & { fontWeight?: "heavy" | "medium" };

function sectionHeadingTitle({ children, className, fontWeight = "heavy", ...props }: SectionHeadingTitleProps) {
  const classes = classNames(
    {
      "heading-sm": fontWeight === "heavy",
      "title-xl": fontWeight === "medium",
    },
    className,
  );

  return (
    <h3 className={classes} {...props}>
      {children}
    </h3>
  );
}

SectionHeading.Title = sectionHeadingTitle;

type SectionHeadingSubtitleProps = ComponentPropsWithoutRef<"h4">;

function sectionHeadingSubtitle({ children, className, ...props }: SectionHeadingSubtitleProps) {
  const classes = classNames("title-lg", className);

  return (
    <h4 className={classes} {...props}>
      {children}
    </h4>
  );
}

SectionHeading.Subtitle = sectionHeadingSubtitle;
