import { LifeCycle } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useSignaledMigration = createUseValidatedClientQuery(["signaled-migration"], {
  paramsSchema: z.object({
    dispatcher: address({ allowZeroAddress: false }),
    vaultProxy: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: ({ vaultProxy, dispatcher }, client) => LifeCycle.hasMigrationRequest(client, { vaultProxy, dispatcher }),
});
