import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay, TokenIcon } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Card } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

export const aaveLend: IntegrationHandler<Integrations.AaveV2.LendArgs> = {
  Description({ args: { aToken: aTokenAddress, depositAmount } }) {
    const { environment } = useGlobals();

    const aToken = environment.getAssetAs(aTokenAddress, AssetType.AAVE_V2);
    const underlyingToken = environment.getAsset(aToken.underlying);

    return (
      <div className="space-y-4">
        <span>
          Supply on Aave V2 (converting{" "}
          <BigIntDisplay
            value={depositAmount}
            decimals={underlyingToken.decimals}
            numberFormat={{ currency: underlyingToken.symbol }}
          />{" "}
          into {aToken.symbol})
        </span>
        <Card appearance="secondary">
          <Card.Content className="flex justify-between items-center">
            <span>{underlyingToken.symbol}</span>
            <span className="flex space-x-2">
              <BigIntDisplay
                value={depositAmount}
                decimals={underlyingToken.decimals}
                numberFormat={{ maximumFractionDigits: 4 }}
              />
              <TokenIcon asset={underlyingToken} size={5} />
            </span>
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label() {
    return <>Supply</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.AaveV2.lendDecode(encodedCallArgs),
};

export const aaveRedeem: IntegrationHandler<Integrations.AaveV2.RedeemArgs> = {
  Description({ args: { aToken: aTokenAddress, redeemAmount } }) {
    const { environment } = useGlobals();

    const aToken = environment.getAssetAs(aTokenAddress, AssetType.AAVE_V2);
    const underlyingToken = environment.getAsset(aToken.underlying);

    return (
      <div className="space-y-4">
        <span>
          Redeeming on Aave V2 (converting{" "}
          <BigIntDisplay value={redeemAmount} decimals={aToken.decimals} numberFormat={{ currency: aToken.symbol }} />{" "}
          into {underlyingToken.symbol})
        </span>
        <Card appearance="secondary">
          <Card.Content className="flex justify-between items-center">
            <span>{aToken.symbol}</span>
            <span className="flex space-x-2">
              <BigIntDisplay
                value={redeemAmount}
                decimals={aToken.decimals}
                numberFormat={{ maximumFractionDigits: 4 }}
              />
              <TokenIcon asset={underlyingToken} size={5} />
            </span>
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label() {
    return <>Redeem</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.AaveV2.redeemDecode(encodedCallArgs),
};
