import { CheckIcon, ChevronUpIcon, XCircleIcon } from "@enzymefinance/icons/solid";
import type { Option } from "@enzymefinance/ui";
import {
  Icon,
  Popper,
  PopperProvider,
  ScreenReaderText,
  sameWidthModifier,
  useFieldGroup,
  usePopper,
} from "@enzymefinance/ui";
import classNames from "classnames";
import type { ComponentType, ReactElement } from "react";
import { Children, memo, useEffect, useMemo, useRef } from "react";
import type { OptionProps, Props } from "react-select";
import { components as componentsBase } from "react-select";
import { FixedSizeList as List } from "react-window";

import { useCustomProps } from "./context.js";

type Components<TOption = Option, TMulti extends boolean = boolean> = NonNullable<
  Props<TOption, TMulti>["components"]
> &
  Required<Pick<NonNullable<Props<TOption, TMulti>["components"]>, "Control" | "MultiValue">>;

const MenuOption: ComponentType<OptionProps<Option> & { isOptimizedMenu?: boolean }> = memo(
  function MenuOption({ children, isOptimizedMenu = false, ...props }) {
    const classes = classNames("cursor-default select-none relative py-2 pl-3 pr-9 text-heading-content", {
      group: isOptimizedMenu,
      "hover:bg-black dark:hover:bg-white hover:bg-opacity-4 dark:hover:bg-opacity-4": !props.isDisabled,
      "opacity-50": props.isDisabled,
      "text-base-content": isOptimizedMenu || !props.isFocused,
      "text-white bg-primary-focus": !isOptimizedMenu && props.isFocused,
    });
    const textClasses = classNames("block truncate", {
      "font-normal": !props.isSelected,
      "font-semibold": props.isSelected,
    });
    const checkMarkClasses = classNames("absolute inset-y-0 right-0 flex items-center pr-4", {
      "group-hover:text-white dark:group-hover:text-white": isOptimizedMenu,
      "text-primary-dark dark:text-primary-light": isOptimizedMenu || !props.isFocused,
      "text-white": !isOptimizedMenu && props.isFocused,
    });
    const innerProps = isOptimizedMenu
      ? {
          ...props.innerProps,
          onMouseMove: () => {},
          onMouseOver: () => {},
        }
      : props.innerProps;

    return (
      <componentsBase.Option
        {...props}
        className={classes}
        aria-selected={props.isSelected}
        innerProps={{
          ...innerProps,
          ...{ role: "option" },
        }}
      >
        <span className={textClasses} title={typeof children === "string" ? children : undefined}>
          {children}
        </span>
        {props.isSelected ? (
          <span className={checkMarkClasses}>
            <Icon icon={CheckIcon} />
          </span>
        ) : null}
      </componentsBase.Option>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isFocused === nextProps.isFocused && prevProps.isSelected === nextProps.isSelected,
);

export const components: Components = {
  ClearIndicator(props) {
    return (
      <componentsBase.ClearIndicator {...props}>
        <div className="p-2">
          <ScreenReaderText>Clear</ScreenReaderText>
          <Icon
            icon={XCircleIcon}
            className="hover:text-base-content-inverse cursor-pointer text-gray-300 transition dark:hover:text-gray-500"
            size={4}
            aria-hidden={true}
          />
        </div>
      </componentsBase.ClearIndicator>
    );
  },
  Control(props) {
    const { isError } = useFieldGroup();
    const { reference, update } = usePopper();
    const controlRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (controlRef.current?.clientHeight !== undefined) {
        // Recompute popper position if the height of the select control wrapper updates
        update?.();
      }
    }, [controlRef.current?.clientHeight]);

    const classes = classNames(
      "bg-base-300 relative border rounded-lg shadow-sm pl-3 text-base sm:text-sm text-heading-content transition",
      {
        "border-error": !props.isFocused && isError,
        "border-error dark:border-error ring-error dark:ring-error": props.isFocused && isError,
        "border-transparent": !(props.isFocused || isError),
        "border-primary ring-primary": props.isFocused && !isError,
        "outline-none ring-1": props.isFocused,
        "pr-10": !props.selectProps.isClearable,
        "pr-16": props.selectProps.isClearable,
        "sm:pl-2": props.isMulti && props.hasValue,
      },
    );

    return (
      <div {...reference}>
        <componentsBase.Control {...props} className={classes} innerRef={controlRef} />
      </div>
    );
  },
  DropdownIndicator(props) {
    if (props.options.length === 0) {
      return null;
    }

    return (
      <componentsBase.DropdownIndicator {...props}>
        <Icon icon={ChevronUpIcon} className="text-base-content" aria-hidden={true} />
      </componentsBase.DropdownIndicator>
    );
  },
  IndicatorSeparator() {
    return null;
  },
  IndicatorsContainer(props) {
    return (
      <componentsBase.IndicatorsContainer {...props} className="absolute inset-y-0 right-0 flex items-center pr-2" />
    );
  },
  Input(props) {
    const classes = classNames("block", {
      "focus:ring-0 bg-transparent border-none text-sm p-0 w-full": props.selectProps.isSearchable,
      "mx-0.5": props.isMulti && props.hasValue,
    });

    return <componentsBase.Input {...props} className="flex-1" inputClassName={classes} />;
  },
  Menu({ className, ...props }) {
    const classes = classNames(
      "rounded-lg bg-base-200 shadow-lg text-base sm:text-sm border border-base-200/50",
      className,
    );

    return (
      <Popper className="z-30" isOpen={props.selectProps.menuIsOpen}>
        <componentsBase.Menu
          {...props}
          className={classes}
          innerProps={{ ...props.innerProps, ...{ role: "listbox" } }}
        />
      </Popper>
    );
  },
  MenuList({ getValue, maxHeight, options, ...props }) {
    const { itemSize } = useCustomProps();
    const children = Children.toArray(props.children);
    const itemCount = children.length;
    const height = useMemo(() => Math.min(maxHeight, itemCount * itemSize + 8), [itemCount, itemSize, maxHeight]);
    const isOptimizedMenu = children.length > 50;

    if (isOptimizedMenu) {
      const [value] = getValue();
      const initialScrollOffset = typeof value === "number" ? options.indexOf(value) * itemSize : undefined;

      return (
        <List
          height={height}
          initialScrollOffset={initialScrollOffset}
          itemCount={itemCount}
          itemSize={itemSize}
          width="100%"
        >
          {({ index, style }) => {
            const element = children[index] as ReactElement<OptionProps<Option>>;

            return (
              <div className="py-1" style={style}>
                <MenuOption key={element.key} {...element.props} isOptimizedMenu={true} />
              </div>
            );
          }}
        </List>
      );
    }

    return (
      <componentsBase.MenuList
        {...props}
        className="max-h-[320px] overflow-y-auto py-1 text-base focus:outline-none sm:text-sm"
        getValue={getValue}
        maxHeight={maxHeight}
        options={options}
      />
    );
  },
  MultiValue({ children, isFocused, removeProps, data }) {
    const removeClasses = classNames("flex items-center justify-center focus:outline-none rounded-full transition", {
      "ring-2 ring-primary ring-offset-base-400": isFocused,
    });
    const removeIconClasses = classNames(" transition", {
      "text-base-content hover:text-base-neutral transition": !isFocused,
      "text-primary-dark dark:text-primary-light": isFocused,
    });

    return (
      <div className="bg-base-400 text-heading-content m-0.5 inline-flex h-6 items-center justify-between space-x-1 rounded-md px-2 text-base transition sm:text-sm">
        <span>{children}</span>
        <div className={removeClasses} {...removeProps}>
          <ScreenReaderText>Remove {getOptionLabel(data)}</ScreenReaderText>
          <Icon icon={XCircleIcon} size={4} className={removeIconClasses} aria-hidden={true} />
        </div>
      </div>
    );
  },
  Option: MenuOption,
  Placeholder({ children, ...props }) {
    return (
      <componentsBase.Placeholder {...props} className="absolute inset-0 flex items-center">
        {typeof children === "string" ? (
          <span className="text-base-neutral truncate" title={children}>
            {children}
          </span>
        ) : (
          children
        )}
      </componentsBase.Placeholder>
    );
  },
  SelectContainer({ children, className, ...props }) {
    const classes = classNames(
      "custom-select relative transition",
      { "opacity-50": props.selectProps.isDisabled },
      className,
    );

    return (
      <PopperProvider modifiers={[sameWidthModifier]} placement="bottom">
        <componentsBase.SelectContainer
          {...props}
          className={classes}
          innerProps={{ ...props.innerProps, ...{ "aria-haspopup": "listbox" } }}
        >
          {children}
        </componentsBase.SelectContainer>
      </PopperProvider>
    );
  },
  SingleValue({ children, ...props }) {
    const { error } = useFieldGroup();
    const classes = classNames("absolute inset-0 flex items-center", {
      "text-error dark:text-error": error,
    });

    return (
      <componentsBase.SingleValue {...props} className={classes}>
        {typeof children === "string" ? (
          <span className="truncate" title={children}>
            {children}
          </span>
        ) : (
          children
        )}
      </componentsBase.SingleValue>
    );
  },
  ValueContainer(props) {
    const { isExpandable } = useCustomProps();
    const classes = classNames("relative flex items-center overflow-y-auto", {
      "flex-wrap ": props.isMulti,
      "h-10 sm:h-9": !(props.isMulti && isExpandable),
      "min-h-[40px] sm:min-h-[36px] max-h-[80px] sm:max-h-[72px]": props.isMulti && isExpandable,
      "py-1.5 sm:py-1": props.isMulti && props.hasValue,
      "py-2": !(props.isMulti && props.hasValue),
    });

    const forward = { ...props, className: classes };

    return <componentsBase.ValueContainer {...forward} />;
  },
};

function getOptionLabel(data: Partial<Option> & { symbol?: string; name?: string; id?: string }) {
  if (typeof data.label === "string") {
    return data.label;
  }

  return data.value ?? data.symbol ?? data.name ?? data.id ?? "";
}
