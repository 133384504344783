import { Form, Input, Select, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { UserCircleIcon } from "@enzymefinance/icons/solid";
import { Button, Modal } from "@enzymefinance/ui";
import { invariant } from "@enzymefinance/utils";
import { address } from "@enzymefinance/validation";
import { useState } from "react";
import type { Address } from "viem";
import { useConfig } from "wagmi";

import { z } from "zod";
import type { MockConnector } from "../mockConnector";

export function ImpersonatorModal({
  connector,
  account,
  chain,
}: {
  connector: MockConnector;
  account?: Address;
  chain?: number;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} appearance="tertiary" leadingIcon={UserCircleIcon}>
        Impersonate
      </Button>
      <Modal title="Impersonate" isOpen={open} dismiss={() => setOpen(false)}>
        <ImpersonatorForm connector={connector} account={account} chain={chain} dismiss={() => setOpen(false)} />
      </Modal>
    </>
  );
}

const schema = z.object({
  account: address({ caseInsensitive: true, allowZeroAddress: true }),
  chain: z.object({ value: z.number(), label: z.string() }),
});

function ImpersonatorForm({
  dismiss,
  connector,
  account,
  chain,
}: {
  dismiss: () => void;
  connector: MockConnector;
  account?: Address;
  chain?: number;
}) {
  const config = useConfig();
  const current = config.chains.find((item) => item.id === chain);
  const form = useForm({
    defaultValues: {
      account,
      chain: current ? { value: current.id, label: current.name } : undefined,
    },
    onSubmit: async (values, helpers) => {
      const chain = config.chains.find((item) => item.id === values.chain.value) ?? config.chains[0];

      invariant(chain !== undefined, "chain is undefined");

      connector.onAccountsChanged([values.account]);

      if (connector.switchChain) {
        await connector.switchChain({ chainId: chain.id });
      }

      helpers.reset();
      dismiss();
    },
    schema,
  });

  return (
    <Form form={form}>
      <Modal.Body>
        <Input name="account" label="Account" />
        <Select
          name="chain"
          label="Network"
          options={config.chains.map((item) => ({ value: item.id, label: item.name }))}
          isClearable={false}
        />
      </Modal.Body>
      <Modal.Actions>
        <SubmitButton>Submit</SubmitButton>
      </Modal.Actions>
    </Form>
  );
}
