import type { Asset } from "@enzymefinance/environment";
import { ArrowRightIcon } from "@enzymefinance/icons/solid";
import { Card, Divider, Icon, NumberDisplay } from "@enzymefinance/ui";

import { BaseIcon } from "../base-icon/BaseIcon";
import { TokenWithCurrency } from "../token-with-currency/TokenWithCurrency";

interface TokenSwapTransactionSummaryProps {
  title: string;
  exchangeIcon: string;
  currency: string;
  buyAsset: Asset;
  sellAsset: Asset;
  minIncomingAssetAmount: bigint;
  outgoingAssetAmount: bigint;
  buyValueInCurrency?: number;
  sellValueInCurrency?: number;
  slippage?: number;
  slippageAssetAmount?: string;
  slippageValueInCurrency?: number;
}

export function TokenSwapTransactionSummary({
  currency,
  buyAsset,
  sellAsset,
  minIncomingAssetAmount,
  outgoingAssetAmount,
  buyValueInCurrency,
  sellValueInCurrency,
  title,
  exchangeIcon,
  slippage,
  slippageAssetAmount,
  slippageValueInCurrency,
}: TokenSwapTransactionSummaryProps) {
  return (
    <div className="border-base-400 border-t-[2px]">
      <div className="mb-8 mt-4 flex items-center justify-between">
        <div className="flex flex-col flex-1 items-center space-y-2">
          <p>Selling</p>
          <TokenWithCurrency
            value={{
              amount: outgoingAssetAmount,
              asset: sellAsset,
              currencyValue: sellValueInCurrency,
            }}
            currency={currency}
            appearance="column"
            size={16}
            titleClasses="py-2"
            amountMaxDecimals={8}
          />
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="bg-base-300 rounded-full p-3">
            <Icon icon={ArrowRightIcon} />
          </div>
        </div>
        <div className="flex flex-col flex-1 items-center space-y-2">
          <p>Buying at least</p>
          <TokenWithCurrency
            value={{
              amount: minIncomingAssetAmount,
              asset: buyAsset,
              currencyValue: buyValueInCurrency,
            }}
            currency={currency}
            appearance="column"
            size={16}
            titleClasses="py-2"
            amountMaxDecimals={8}
          />
        </div>
      </div>
      <Card appearance="secondary">
        <Card.Content className="space-y-3">
          <div className="flex items-center justify-between">
            <p>Exchange</p>
            <div className="flex items-center justify-end">
              <BaseIcon className="mr-2 overflow-hidden rounded-full" alt={title} title={title} url={exchangeIcon} />
              <div className="flex flex-col text-left">
                <span className="title">{title}</span>
              </div>
            </div>
          </div>
          {slippage ? (
            <>
              <Divider />
              <div className="flex items-center justify-between">
                <span>Slippage</span>
                <div className="flex flex-col items-end justify-end text-right">
                  <NumberDisplay value={slippage} numberFormat={{ style: "percent" }} className="mb-2" />
                  <NumberDisplay
                    className="title-sm text-right"
                    appearance="simple"
                    numberFormat={{
                      currency: buyAsset.symbol,
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 6,
                    }}
                    value={Number(slippageAssetAmount)}
                    truncate={false}
                  />
                  <NumberDisplay
                    className="text-base-content text-sm"
                    appearance="simple"
                    numberFormat={{ currency }}
                    value={slippageValueInCurrency}
                  />
                </div>
              </div>
            </>
          ) : null}
        </Card.Content>
      </Card>
    </div>
  );
}
