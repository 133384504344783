import { Form, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { PhotoIcon, TrashIcon } from "@enzymefinance/icons/solid";
import { useToasts } from "@enzymefinance/toast";
import { Button, ErrorMessage, Icon, Image, Modal, useBoolean } from "@enzymefinance/ui";
import { url, file, safeParse } from "@enzymefinance/validation";
import classNames from "classnames";
import { log } from "logger/logger";
import {
  WhiteLabelByIdDocument,
  useDeleteWhiteLabelLogoMutation,
  useUpdateWhiteLabelLogoMutation,
} from "queries/backend";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { client } from "utils/backend";
import { z } from "zod";

import { Link } from "../../routing/Link";
import { useWhiteLabel } from "../WhiteLabelProvider";
import type { WhiteLabelNavigationWrapperProps } from "./WhiteLabelNavigationWrapper";
import { defaultAppearance } from "./WhiteLabelNavigationWrapper";

const formSchema = z.object({
  file: file({
    dimensions: {
      x: 1024,
      y: 1024,
    },
    // Maximum file size: 1 MB
    max: 1000 * 1000,
  }),
  preview: url(),
});

export interface WhiteLabelLogoFormProps {
  appearance?: WhiteLabelNavigationWrapperProps["appearance"];
  isScrollTop: boolean;
}

export function WhiteLabelLogoForm({ appearance = defaultAppearance, isScrollTop }: WhiteLabelLogoFormProps) {
  const { isOwner, id, logoUrl, rootPath } = useWhiteLabel();
  const [updateWhiteLabelLogo] = useUpdateWhiteLabelLogoMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: WhiteLabelByIdDocument, variables: { id } }],
  });
  const { toast } = useToasts();
  const form = useForm({
    defaultValues: { file: undefined, preview: undefined },
    async onSubmit(values, helpers) {
      if (!id) {
        log.error({ category: "WhiteLabelLogoForm", message: "No white label id" });
        toast.error();

        return;
      }

      try {
        const { data } = await updateWhiteLabelLogo({ variables: { input: { file: values.file, id } } });

        if (data?.updateWhiteLabelLogo.errors?.length) {
          data.updateWhiteLabelLogo.errors.forEach((error) => toast.error(error));

          return;
        }

        toast("Successfully updated!", { description: "Your logo has been updated." });
        helpers.reset();
      } catch (error) {
        log.error({ category: "WhiteLabelLogoForm", message: error });
        toast.error();
      }
    },
    resolverMode: "async",
    schema: formSchema,
  });
  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop(acceptedFiles) {
      if (acceptedFiles[0]) {
        form.setValue("file", acceptedFiles[0], { shouldValidate: true, shouldDirty: true });

        const objectUrl = URL.createObjectURL(acceptedFiles[0]);
        form.setValue("preview", objectUrl, { shouldValidate: true, shouldDirty: true });
      }
    },
    onDropRejected() {
      toast.error({ title: "File type not supported" });
    },
  });
  const [isDeleteOpen, openDelete, dismissDelete] = useBoolean(false);
  const [deleteWhiteLabelLogo, deleteWhiteLabelLogoResult] = useDeleteWhiteLabelLogoMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: WhiteLabelByIdDocument, variables: { id } }],
  });
  const handleDelete = useCallback(async () => {
    if (!id) {
      log.error({ category: "WhiteLabelLogoForm", message: "No white label id" });
      toast.error();
      dismissDelete();

      return;
    }

    try {
      const { data } = await deleteWhiteLabelLogo({ variables: { input: { id } } });

      if (data?.deleteWhiteLabelLogo.errors?.length) {
        data.deleteWhiteLabelLogo.errors.forEach((error) => toast.error(error));
        dismissDelete();

        return;
      }

      toast("Successfully removed!", { description: "Your logo has been removed." });
    } catch (error) {
      log.error({ category: "WhiteLabelLogoForm", message: error.message });
      toast.error();
    }

    dismissDelete();
  }, [deleteWhiteLabelLogo, dismissDelete, id, toast]);

  const preview = form.watch("preview");

  const parsedPreview = useMemo(() => safeParse(formSchema.shape.preview, preview), [preview]);

  const logoClasses = classNames(
    "group flex items-center justify-center transition-[color,width,height] duration-150 relative",
    {
      "focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition":
        parsedPreview,
      "w-56": !(parsedPreview || logoUrl) && appearance === "home",
      "w-40": !(parsedPreview || logoUrl) && appearance === "default",
      "xl:w-72": !(parsedPreview || logoUrl) && appearance === "default" && isScrollTop,
      "xl:w-80": !(parsedPreview || logoUrl) && appearance === "home" && isScrollTop,
      "h-12 rounded-3xl": appearance === "home",
      "h-9 rounded-2xl": appearance === "default",
      "max-w-[224px]": (parsedPreview || logoUrl) && appearance === "home",
      "max-w-[160px]": (parsedPreview || logoUrl) && appearance === "default",
      "xl:h-16 xl:max-w-[288px]": appearance === "default" && isScrollTop,
      "xl:h-20 xl:max-w-xs": appearance === "home" && isScrollTop,
    },
  );

  const imageClasses = classNames("h-full w-full object-contain object-left transition", {
    "opacity-25": isDragActive,
    "group-hover:opacity-25": parsedPreview,
  });
  const iconClasses = classNames(
    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-base-content transition",
    {
      "opacity-0": !isDragActive,
      "opacity-100": isDragActive,
      "group-hover:opacity-100": parsedPreview,
    },
  );

  return (
    <>
      {isOwner ? (
        parsedPreview ? (
          <button {...getRootProps({ className: logoClasses })} onClick={open} type="button">
            <Image className={imageClasses} src={parsedPreview} height="100%" width="100%" alt="Logo" />
            <Icon className={iconClasses} icon={PhotoIcon} />
          </button>
        ) : logoUrl ? (
          <Link to={rootPath}>
            <div {...getRootProps({ className: logoClasses })}>
              <Image className={imageClasses} src={logoUrl} height="100%" width="100%" alt="Logo" />
              <Icon className={iconClasses} icon={PhotoIcon} />
            </div>
          </Link>
        ) : (
          <Button
            {...getRootProps({ className: logoClasses })}
            appearance="quaternary"
            leadingIcon={isDragActive ? undefined : PhotoIcon}
            icon={isDragActive ? PhotoIcon : undefined}
            onClick={open}
          >
            <span className="text-base-content text-sm font-medium transition group-hover:text-gray-700 dark:group-hover:text-gray-300">
              Upload Logo
            </span>
          </Button>
        )
      ) : logoUrl ? (
        <Link to={rootPath}>
          <div {...getRootProps({ className: logoClasses })}>
            <Image className={imageClasses} src={logoUrl} height="100%" width="100%" alt="Logo" />
            <Icon className={iconClasses} icon={PhotoIcon} />
          </div>
        </Link>
      ) : null}

      {isScrollTop && isOwner ? (
        <Form className="absolute bottom-0 left-full w-80 px-3 transition lg:flex lg:space-x-3" form={form}>
          {parsedPreview ? (
            <div className="flex items-end space-x-3 lg:space-x-2 lg:space-y-3">
              <div className="flex space-x-3">
                <Button appearance="secondary" disabled={form.formState.isSubmitting} onClick={() => form.reset()}>
                  Cancel
                </Button>
                <SubmitButton>Save</SubmitButton>
              </div>
              {typeof form.formState.errors.file?.message === "string" ? (
                <ErrorMessage>{form.formState.errors.file.message}</ErrorMessage>
              ) : null}
            </div>
          ) : logoUrl ? (
            <>
              <Button leadingIcon={PhotoIcon} appearance="secondary" onClick={open}>
                Change Logo
              </Button>
              <Button icon={TrashIcon} appearance="destructive" onClick={openDelete}>
                Remove Logo
              </Button>
            </>
          ) : null}

          {/* Hidden file input */}
          <input {...getInputProps()} />
        </Form>
      ) : null}
      <Modal title="Delete Logo" isOpen={isDeleteOpen} dismiss={dismissDelete}>
        <Modal.Body>
          <p className="text-sm">Are you sure you want to remove the logo of your page?</p>
        </Modal.Body>
        <Modal.Actions>
          <Button
            disabled={deleteWhiteLabelLogoResult.loading}
            appearance="destructive"
            loading={deleteWhiteLabelLogoResult.loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button disabled={deleteWhiteLabelLogoResult.loading} appearance="secondary" onClick={dismissDelete}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
