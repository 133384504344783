export function loadImage(src: string): Promise<HTMLImageElement> {
  const image = new Image();

  image.src = src;

  return new Promise((resolve, reject) => {
    image.onerror = (error) => reject(error);
    image.onload = () => resolve(image);
  });
}
