import classNames from "classnames";
import type { ComponentPropsWithoutRef, ElementType, HTMLAttributes, ReactNode } from "react";
import { createContext, createElement, useContext } from "react";

export type CardAppearance = "primary" | "secondary" | "tertiary";
type CardRounded = "2xl" | "lg";

interface CardContextValues {
  appearance: CardAppearance;
}

const CardContext = createContext<CardContextValues | undefined>(undefined);

export function useCard() {
  const context = useContext(CardContext);

  if (!context) {
    throw new Error("Missing card context");
  }

  return context;
}

export type CardProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = TProps & {
  as?: ElementType | "button" | "div" | "nav" | "section";
  children?: ReactNode;
  className?: string;
  full?: boolean;
  appearance?: CardAppearance;
  rounded?: CardRounded;
};

export const Card = function Card<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  as = "div",
  children,
  full = false,
  appearance = "primary",
  rounded = "lg",
  className,
  ...props
}: CardProps<TProps>) {
  const classes = classNames(
    "divide-y",
    {
      "bg-base-200 divide-base-100": appearance === "primary",
      "bg-base-300 divide-base-200": appearance === "secondary",
      "bg-base-400 divide-base-300": appearance === "tertiary",
      "rounded-2xl": !full && rounded === "2xl",
      "rounded-lg": !full && rounded === "lg",
      "sm:rounded-2xl": full && rounded === "2xl",
      "sm:rounded-lg": full && rounded === "lg",
    },
    className,
  );

  return (
    <CardContext.Provider value={{ appearance }}>
      {createElement<any>(as, { className: classes, ...props }, children)}
    </CardContext.Provider>
  );
};

export type CardContentProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = TProps & {
  as?: ElementType | "button" | "div" | "span";
  className?: string;
  children?: ReactNode;
};

function cardContent<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  as = "div",
  children,
  className,
  ...props
}: CardContentProps<TProps>) {
  const classes = classNames(
    {
      "px-4 py-5 sm:p-6": !className?.includes("p-0"),
    },
    className,
  );

  return createElement(as, { className: classes, ...props }, children);
}

Card.Content = cardContent;
