import type { AccountInfoFragment } from "queries/backend";
import { useMeQuery } from "queries/backend";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { client } from "utils/backend";

export interface AuthenticationContextValue {
  authenticated: boolean;
  loading: boolean;
  account?: AccountInfoFragment;
}

const AuthenticationContext = createContext<AuthenticationContextValue>({
  account: undefined,
  authenticated: false,
  loading: false,
});

export function useAuthentication() {
  return useContext(AuthenticationContext);
}

interface AuthenticationProps {
  children?: ReactNode;
}

export function AuthenticationProvider({ children }: AuthenticationProps) {
  const me = useMeQuery({ client, pollInterval: 0 });
  const value = useMemo<AuthenticationContextValue>(
    () => ({
      account: me.data?.me ?? undefined,
      authenticated: !!me.data?.me,
      loading: me.loading,
    }),
    [me.data, me.loading],
  );

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}
