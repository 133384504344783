import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactElement } from "react";

export interface PageHeadingProps extends NonNullable<ComponentPropsWithoutRef<"h2">> {
  actions?: ReactElement | null;
  wrap?: boolean;
}

export function PageHeading({ actions, children, className, wrap = true, ...props }: PageHeadingProps) {
  const wrapperClasses = classNames("px-4 sm:px-0", {
    "flex items-center justify-between": !wrap,
    "space-y-4 md:space-y-0 md:space-x-4 md:flex md:items-start md:justify-between": wrap,
  });
  const classes = classNames("heading", className);

  return (
    <div className={wrapperClasses}>
      <div className="min-w-0 flex-1">
        {typeof children === "string" ? (
          <h2 className={classes} {...props}>
            {children}
          </h2>
        ) : (
          children
        )}
      </div>
      {actions ? <div className="flex items-center space-x-3">{actions}</div> : null}
    </div>
  );
}
