import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

export interface DividerProps extends ComponentPropsWithoutRef<"div"> {
  appearance?: "default" | "subtle";
  direction?: "horizontal" | "vertical";
}

export function Divider({
  appearance = "default",
  children,
  direction = "horizontal",
  className,
  ...props
}: DividerProps) {
  const wrapperClasses = classNames("flex-1 relative space-x-2", {
    "flex items-center": direction === "horizontal",
    "block h-full": direction === "vertical",
  });
  const classes = classNames(
    "flex-1",
    {
      "bg-base-400": appearance === "default",
      "bg-base-300": appearance === "subtle",
      "flex items-center justify-center h-px": direction === "horizontal",
      "block h-full w-px": direction === "vertical",
    },
    className,
  );

  return (
    <span className={wrapperClasses} {...props}>
      <span className={classes} />
      {children === undefined ? null : (
        <>
          <p className="text-base-neutral truncate text-center text-sm">{children}</p>
          <span className={classes} />
        </>
      )}
    </span>
  );
}
