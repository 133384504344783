import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperUseDepositApprovals: FunctionConfig<{ nextUseDepositApprovals: boolean }> = {
  signature: "function setUseDepositApprovals(bool nextUseDepositApprovals)",

  Description({ inputs }) {
    return <>{inputs.nextUseDepositApprovals ? "Enable" : "Disable"} deposit approvals in Shares Wrapper</>;
  },

  Label() {
    return <>Reconfigure Shares Wrapper</>;
  },
};
