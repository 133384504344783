import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address, maxUint256 } from "viem";

export const sharesWrapperSetRedemptionApprovals: FunctionConfig<{ users: Address[]; amounts: bigint[] }> = {
  signature: "function setRedemptionApprovals(address[] calldata users, uint256[] calldata amounts)",

  Description({ inputs }) {
    const wallets = inputs.users;
    const quantityOfShares = inputs.amounts;

    if (wallets[0] === undefined || quantityOfShares[0] === undefined) {
      throw new Error("Invalid inputs");
    }

    const isUnlimited = quantityOfShares[0] === maxUint256;

    return (
      <>
        Approve wallet {wallets[0]} to redeem{" "}
        {isUnlimited ? (
          "unlimited amount of shares"
        ) : (
          <BigIntDisplay numberFormat={{ currency: "shares", maximumFractionDigits: 20 }} value={quantityOfShares[0]} />
        )}
      </>
    );
  },

  Label() {
    return <>Approving redemption on Shares Wrapper</>;
  },
};
