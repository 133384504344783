import classNames from "classnames";
import type { ComponentPropsWithoutRef, ElementType, HTMLAttributes } from "react";
import { createContext, createElement, useContext } from "react";

import type { usePopper } from "./PopperProvider.js";

type PopperArrowContextValues = ReturnType<typeof usePopper>["arrow"];

export const PopperArrowContext = createContext<PopperArrowContextValues | undefined>(undefined);

function usePopperArrow() {
  const context = useContext(PopperArrowContext);

  if (!context) {
    throw new Error("Missing popper arrow context");
  }

  return context;
}

type PopperArrowProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = TProps & {
  as?: ElementType;
};

export function PopperArrow<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  as = "div",
  children,
  className,
  ...props
}: PopperArrowProps<TProps>) {
  const popperArrow = usePopperArrow();
  const classes = classNames("w-0 h-0 border-4", { "border-gray-50 dark:border-gray-850": !className }, className);

  return createElement(as, { className: classes, "data-popper-arrow": true, ...props, ...popperArrow }, children);
}
