import type { FunctionConfig } from "utils/functionsTypes";

export const redemptionQueueShutdown: FunctionConfig = {
  signature: "function shutdown()",

  Description() {
    return <>Shutdown Redemption Queue.</>;
  },

  Label() {
    return <>Shutdown Redemption Queue</>;
  },
};
