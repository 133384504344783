import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

interface RedeemSharesInKindInputs {
  recipient: Address;
  sharesQuantity: bigint;
  additionalAssets: Address[];
  assetsToSkip: Address[];
}

export const redeemSharesInKind: FunctionConfig<RedeemSharesInKindInputs> = {
  signature:
    "function redeemSharesInKind(address recipient, uint256 sharesQuantity, address[] additionalAssets, address[] assetsToSkip) returns (address[] payoutAssets, uint256[] payoutAmounts)",

  Description({ address }) {
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: address.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Redeem shares in kind of {vault?.name ?? "this vault"}.</>;
  },

  Label() {
    return <>Redeem Shares</>;
  },
};
