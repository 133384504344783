import type { Asset } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { BigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Badge, ErrorMessage, NumberDisplay, RadioGroup, Toggle } from "@enzymefinance/ui";
import { getDisplayFractionDigits } from "@enzymefinance/utils";
import { bigint } from "@enzymefinance/validation";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { maxUint256 } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { MinMaxDepositPolicySettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";

interface MinMaxDepositPolicyFormFieldsValues {
  [VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY]?: Partial<MinMaxDepositPolicySettings>;
}

export const minMaxDepositPolicySchema = z
  .object({
    isRejectAll: z.boolean(),
    isMinEnabled: z.boolean(),
    isMaxEnabled: z.boolean(),
    maxInvestmentAmount: bigint()
      .optional()
      // Check if value is uint256 at maximum
      .refine((value) => !value || (value && value <= maxUint256), {
        message: "The maximum deposit amount is invalid.",
      }),
    minInvestmentAmount: bigint()
      .optional()
      // Check if value is uint256 at maximum
      .refine((value) => !value || (value && value <= maxUint256), {
        message: "The minimum deposit amount is invalid.",
      }),
  })
  .superRefine((value, context) => {
    if (value.isRejectAll) {
      return;
    }

    if (!(value.isMinEnabled || value.isMaxEnabled)) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Must specify either minimum or maximum amount, select "Reject all deposits", or disable the policy to unrestrict deposits.',
        path: ["isMinEnabled"],
      });
    }

    if (!(value.isMinEnabled || value.isMaxEnabled)) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Must specify either minimum or maximum amount, select "Reject all deposits", or disable the policy to unrestrict deposits.',
        path: ["isMaxEnabled"],
      });
    }

    if (value.isMinEnabled && (!value.minInvestmentAmount || value.minInvestmentAmount === 0n)) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Minimum deposit amount cannot be zero. Disable the switch instead",
        path: ["minInvestmentAmount"],
      });
    }

    if (value.isMaxEnabled && (!value.maxInvestmentAmount || value.maxInvestmentAmount === 0n)) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Maximum deposit amount cannot be zero. Select "Reject all deposits" instead.',
        path: ["maxInvestmentAmount"],
      });
    }

    if (
      value.isMinEnabled &&
      value.isMaxEnabled &&
      value.minInvestmentAmount &&
      value.maxInvestmentAmount &&
      value.minInvestmentAmount >= value.maxInvestmentAmount
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The minimum deposit amount must be less than the maximum deposit amount.",
        path: ["minInvestmentAmount"],
      });
    }

    // Same validation as above but with the maxInvestmentAmount path, otherwise validation does not trigger when max is edited.
    if (
      value.isMinEnabled &&
      value.isMaxEnabled &&
      value.minInvestmentAmount &&
      value.maxInvestmentAmount &&
      value.minInvestmentAmount >= value.maxInvestmentAmount
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The maximum deposit amount must be greater than the maximum deposit amount.",
        path: ["maxInvestmentAmount"],
      });
    }
  });

function minMaxDepositFormFields() {
  const { getFieldState, setValue, trigger, watch } = useFormContext<
    MinMaxDepositPolicyFormFieldsValues & { denominationAsset: Asset }
  >();
  const { error } = getFieldState(VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY);
  const { error: minEnabledError, isTouched: isMinTouched } = getFieldState(
    `${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.isMinEnabled`,
  );
  const { error: maxEnabledError, isTouched: isMaxTouched } = getFieldState(
    `${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.isMaxEnabled`,
  );
  const [value, denominationAsset] = watch([VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY, "denominationAsset"]) as [
    MinMaxDepositPolicyFormFieldsValues[VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY],
    Asset,
  ];

  useUpdateEffect(() => {
    trigger();
  }, [value]);

  const isRejectAll = !!value?.isRejectAll;
  const isMinEnabled = !!value?.isMinEnabled;
  const isMaxEnabled = !!value?.isMaxEnabled;

  const toggleMin = useCallback(() => {
    setValue(`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.isMinEnabled`, !isMinEnabled as never, {
      shouldTouch: true,
    });

    if (isMinEnabled) {
      setValue(`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.minInvestmentAmount`, 0n as never, {
        shouldTouch: true,
      });
    }
    trigger();
  }, [setValue, isMinEnabled, trigger]);

  const toggleMax = useCallback(() => {
    setValue(`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.isMaxEnabled`, !isMaxEnabled as never, {
      shouldTouch: true,
    });

    if (isMaxEnabled) {
      setValue(`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.maxInvestmentAmount`, 0n as never, {
        shouldTouch: true,
      });
    }
    trigger();
  }, [setValue, isMaxEnabled, trigger]);

  const triggerErrors = useCallback(() => {
    trigger([
      `${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.maxInvestmentAmount`,
      `${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.minInvestmentAmount`,
    ]);
  }, [trigger]);

  const contents = useMemo(
    () => (
      <>
        <div className="flex flex-col space-y-3">
          <div className="mt-4 flex space-x-3">
            <div className="mt-7 pt-0.5">
              <Toggle altText="Toggle Minimum Deposit Amount" on={isMinEnabled} onClick={toggleMin} />
            </div>
            <div className="flex-1">
              <BigIntInput
                decimals={denominationAsset.decimals}
                disabled={!isMinEnabled}
                label="Minimum Deposit Amount"
                name={`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.minInvestmentAmount`}
                numberFormat={{ currency: denominationAsset.symbol }}
                onValueChange={triggerErrors}
              />
              {typeof minEnabledError?.message === "string" && isMinTouched ? (
                <ErrorMessage>{minEnabledError.message}</ErrorMessage>
              ) : null}
            </div>
          </div>
          <div className="flex space-x-3">
            <div className="mt-7 pt-0.5">
              <Toggle altText="Toggle Maximum Deposit Amount" on={isMaxEnabled} onClick={toggleMax} />
            </div>
            <div className="flex-1">
              <BigIntInput
                decimals={denominationAsset.decimals}
                disabled={!isMaxEnabled}
                name={`${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.maxInvestmentAmount`}
                label="Maximum Deposit Amount"
                numberFormat={{ currency: denominationAsset.symbol }}
                onValueChange={triggerErrors}
              />
              {typeof maxEnabledError?.message === "string" && isMaxTouched ? (
                <ErrorMessage>{maxEnabledError.message}</ErrorMessage>
              ) : null}
            </div>
          </div>
          {/* If there is both a minEnabledError and maxEnabledError and both aren't touched, we are dealing with the initial error and should display it */}
          {typeof minEnabledError?.message === "string" &&
          typeof maxEnabledError?.message === "string" &&
          !isMinTouched &&
          !isMaxTouched ? (
            <ErrorMessage>{maxEnabledError.message}</ErrorMessage>
          ) : null}
        </div>
      </>
    ),
    [
      denominationAsset.decimals,
      denominationAsset.symbol,
      isMaxEnabled,
      isMaxTouched,
      isMinEnabled,
      isMinTouched,
      maxEnabledError?.message,
      minEnabledError?.message,
      toggleMax,
      toggleMin,
      triggerErrors,
    ],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () => [
      {
        value: VaultConfigPolicyListOption.CUSTOM_LIST,
        label: "Specify deposit limits",
        description: isRejectAll ? <></> : contents,
      },
      {
        value: VaultConfigPolicyListOption.EMPTY_LIST,
        label: "Reject all deposits",
        description: (
          <p className="text-sm">
            If you choose to reject all deposits, no one (including yourself) will be able to invest in the vault. This
            setting can be changed later.
          </p>
        ),
      },
    ],
    [contents, isRejectAll],
  );

  const handleSelectListOption = useCallback(
    ({ value: newValue }: ListSelectOption) => {
      setValue(
        `${VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}.isRejectAll`,
        (newValue === VaultConfigPolicyListOption.EMPTY_LIST) as never,
      );
      trigger();
    },
    [setValue, trigger],
  );

  const selectedListOption = useMemo(
    () =>
      isRejectAll
        ? listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.EMPTY_LIST)
        : listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [isRejectAll, listSelectOptions],
  );

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.MIN_MAX_DEPOSIT_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label="Deposit limits"
        labelHidden={true}
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function minMaxDepositPolicyDisplay({
  settings,
  denominationAsset,
}: VaultConfigDisplayProps<VaultConfigType.MIN_MAX_DEPOSIT_POLICY>) {
  return (
    <span className="flex items-center">
      <span className="flex flex-col">
        {settings.isRejectAll ? (
          <span>Deposits Disallowed</span>
        ) : (
          <>
            {settings.isMinEnabled ? (
              <span className="flex space-x-1">
                <p>Minimum: </p>
                <BigIntDisplay
                  value={settings.minInvestmentAmount}
                  numberFormat={{
                    currency: denominationAsset?.symbol,
                    maximumFractionDigits: denominationAsset?.decimals,
                  }}
                  decimals={denominationAsset?.decimals}
                />
              </span>
            ) : null}
            {settings.isMaxEnabled ? (
              <span className="flex space-x-1">
                <p>Maximum: </p>
                <BigIntDisplay
                  value={settings.maxInvestmentAmount}
                  numberFormat={{
                    currency: denominationAsset?.symbol,
                    maximumFractionDigits: denominationAsset?.decimals,
                  }}
                  decimals={denominationAsset?.decimals}
                />
              </span>
            ) : null}
          </>
        )}
      </span>
    </span>
  );
}

function minMaxDepositPolicyDisplaySubgraph({
  settings,
  denominationAsset,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.MIN_MAX_DEPOSIT_POLICY>) {
  const minDepositAmount = Number(settings.minDepositAmount);
  const maxDepositAmount = Number(settings.maxDepositAmount);

  const isMinDisabled = !minDepositAmount;
  const isMaxDisabled = !maxDepositAmount;

  return (
    <span className="flex flex-col">
      {isMinDisabled && isMaxDisabled ? (
        <span>Deposits Disallowed</span>
      ) : (
        <>
          {isMinDisabled ? null : (
            <span className="flex space-x-1">
              <p>Minimum: </p>
              <NumberDisplay
                value={minDepositAmount}
                numberFormat={{
                  currency: denominationAsset?.symbol,
                  maximumFractionDigits: getDisplayFractionDigits(minDepositAmount, 0),
                }}
              />
            </span>
          )}
          {isMaxDisabled ? null : (
            <span className="flex space-x-1">
              <p>Maximum: </p>
              <NumberDisplay
                value={maxDepositAmount}
                numberFormat={{
                  currency: denominationAsset?.symbol,
                  maximumFractionDigits: getDisplayFractionDigits(maxDepositAmount, 0),
                }}
              />
            </span>
          )}
        </>
      )}
    </span>
  );
}

export const minMaxDepositPolicy: VaultConfig<VaultConfigType.MIN_MAX_DEPOSIT_POLICY> = {
  address: (contracts) => contracts.MinMaxInvestmentPolicy,
  disableable: true,
  display: minMaxDepositPolicyDisplay,
  displaySubgraph: minMaxDepositPolicyDisplaySubgraph,
  editable: true,
  encode: (settings) => {
    return Policies.MinMaxInvestment.encodeSettings({
      maxInvestmentAmount:
        settings.isRejectAll || !settings.isMaxEnabled || !settings.maxInvestmentAmount
          ? 0n
          : settings.maxInvestmentAmount,
      minInvestmentAmount:
        settings.isRejectAll || !settings.isMinEnabled || !settings.minInvestmentAmount
          ? 0n
          : settings.minInvestmentAmount,
    });
  },
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    const result = await Policies.MinMaxInvestment.getSettings(client, {
      minMaxInvestmentPolicy: vaultConfigAddress,
      comptrollerProxy: comptroller,
    });

    return {
      maxInvestmentAmount: result.maxInvestmentAmount,
      minInvestmentAmount: result.minInvestmentAmount,
      isMinEnabled: result.minInvestmentAmount > 0n,
      isMaxEnabled: result.maxInvestmentAmount > 0n,
      isRejectAll: result.minInvestmentAmount === 0n && result.maxInvestmentAmount === 0n,
    };
  },
  formFields: minMaxDepositFormFields,
  formInitialValues: {
    maxInvestmentAmount: 0n,
    minInvestmentAmount: 0n,
    isMinEnabled: false,
    isMaxEnabled: false,
    isRejectAll: false,
  },
  label: "Deposit Limits",
  managerDescription: (
    <div className="space-y-4">
      <p>Restricts the amount of a single deposit with either a minimum, a maximum, or both.</p>

      <p>
        You can also reject all deposits into this vault using{" "}
        <InlineLink to="https://specs.enzyme.finance/topics/policies#minmaxinvestmentpolicy" appearance="link">
          this policy
        </InlineLink>
        .
      </p>
      <Badge appearance="success">Editable Setting</Badge>
    </div>
  ),

  type: VaultConfigType.MIN_MAX_DEPOSIT_POLICY,
  validationSchema: minMaxDepositPolicySchema,
};

interface ListSelectOption {
  description: ReactNode;
  label: string;
  value: string;
}
