import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperUseRedemptionApprovals: FunctionConfig<{ nextUseRedemptionApprovals: boolean }> = {
  signature: "function setUseRedemptionApprovals(bool nextUseRedemptionApprovals)",

  Description({ inputs }) {
    return <>{inputs.nextUseRedemptionApprovals ? "Enable" : "Disable"} redemption approvals in Shares Wrapper</>;
  },
  Label() {
    return <>Reconfigure Shares Wrapper</>;
  },
};
