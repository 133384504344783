import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const sharesWrapperCancelRequestDeposit: FunctionConfig<{ depositAssetContract: Address }> = {
  signature: "function cancelRequestDeposit(address depositAssetContract)",

  Description() {
    return <>Delete deposit request</>;
  },

  Label() {
    return <>Delete deposit request</>;
  },
};
