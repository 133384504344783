import { Compass } from "@enzymefinance/icons";
import { Utils } from "@enzymefinance/sdk";
import { Breadcrumbs } from "@enzymefinance/ui";
import { Routes } from "components/routing/Routes";
import { useMemo } from "react";
import { Route, useLocation, useParams } from "react-router-dom";
import { Link } from "../routing/Link";
import { useWhiteLabel } from "./WhiteLabelProvider";

export function WhiteLabelBreadcrumbs() {
  const { id } = useParams<"id">();
  const { loading, rootPath, vaults } = useWhiteLabel();
  const vault = useMemo(() => vaults.find(({ address }) => Utils.Address.safeSameAddress(address, id)), [id, vaults]);
  const location = useLocation();

  if (loading || !vault) {
    return null;
  }

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item as={Link} icon={Compass} to={rootPath}>
        Home
      </Breadcrumbs.Item>
      <Breadcrumbs.Item
        as={Link}
        to={`${rootPath}vault/${vault.address}`}
        current={location.pathname.endsWith(vault.address)}
      >
        {vault.name}
      </Breadcrumbs.Item>
      <Routes>
        <Route
          path="portfolio"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Portfolio
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="financials"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Financials
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="fees"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Fees
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="policies"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Policies
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="depositors"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Depositors
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="activity"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Activity
            </Breadcrumbs.Item>
          }
        />
      </Routes>
    </Breadcrumbs>
  );
}
