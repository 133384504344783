import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ReactNode } from "react";

import { useMotionPresets } from "../../../hooks/useMotionPresets.js";
import { Portal } from "../../layout/Portal.js";
import { PopperArrow, PopperArrowContext } from "./PopperArrow.js";
import { usePopper } from "./PopperProvider.js";

export interface PopperProps extends Omit<ComponentPropsWithoutRef<"div">, "ref"> {
  children: ReactNode;
  arrow?: ReactNode | true;
  isOpen: boolean;
}

export function Popper({ arrow: arrowElement, children, isOpen, style, ...props }: PopperProps) {
  const {
    arrow,
    popper: { className, ...popper },
  } = usePopper();
  const motionPresets = useMotionPresets();

  return (
    <AnimatePresence>
      {isOpen ? (
        <Portal>
          <div {...popper} {...props} style={{ ...popper.style, ...style }}>
            <motion.div className={className} {...motionPresets.popper}>
              <PopperArrowContext.Provider value={arrow}>
                {arrowElement === true ? <PopperArrow /> : arrowElement}
              </PopperArrowContext.Provider>
              {children}
            </motion.div>
          </div>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}

Popper.Arrow = PopperArrow;
