import type { TokenInputProps as TokenInputPropsBase, TokenInputValue } from "@enzymefinance/ethereum-ui";
import { TokenInput as TokenInputBase } from "@enzymefinance/ethereum-ui";
import { useCallback } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface TokenInputProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TokenInputPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function TokenInput<TFieldValues extends FieldValues = FieldValues>({
  disabled,
  onChange: onChangeBase,
  ...props
}: TokenInputProps<TFieldValues>) {
  const {
    field: { ref: _, onChange: fieldOnChange, ...field },
    fieldState,
    formState,
  } = useController<TFieldValues>({ name: props.name });

  const onChange = useCallback(
    (value: TokenInputValue) => {
      onChangeBase?.(value);
      // TODO: We should use proper types here.
      fieldOnChange(value as any);
    },
    [fieldOnChange, onChangeBase],
  );

  return (
    <TokenInputBase
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      disabled={disabled || formState.isSubmitting}
      onChange={onChange}
    />
  );
}

TokenInput.LocalValue = TokenInputBase.LocalValue;
