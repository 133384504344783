import { PuffLoader } from "@enzymefinance/icons";
import { ExclamationCircleIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ComponentType } from "react";
import { forwardRef } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { Icon } from "../elements/Icon.js";
import { Tooltip } from "../overlays/Tooltip.js";
import type { FieldGroupProps } from "./FieldGroup.js";
import { FieldGroup, useFieldGroup } from "./FieldGroup.js";

export interface BaseInputProps extends ComponentPropsWithoutRef<"input"> {
  appearance?: "default" | "pill";
  leadingAddOn?: string;
  leadingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  loading?: boolean;
  trailingAddOn?: string;
  trailingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(function BaseInput(
  {
    appearance = "default",
    className,
    disabled = false,
    id,
    leadingAddOn,
    leadingIcon,
    loading,
    name,
    trailingAddOn,
    trailingIcon,
    type = "text",
    ...props
  },
  ref,
) {
  const { ariaProps, error, isError } = useFieldGroup();
  const motionPresets = useMotionPresets();
  const hasLeadingAddOn = leadingAddOn !== undefined;
  const hasTrailingAddOn = trailingAddOn !== undefined;
  const classes = classNames(
    "peer block w-full bg-base-300 sm:text-sm transition",
    {
      "border-error/50 focus:border-error focus:ring-error text-error placeholder-error/50": isError,
      "border-transparent focus:border-primary focus:ring-primary text-heading-content placeholder-base-neutral disabled:text-heading-content/50 disabled:bg-opacity-50 dark:disabled:bg-opacity-50":
        !isError,
      "pl-10": leadingIcon,
      "pr-10": trailingIcon || isError,
      "min-w-0 px-3 py-2": hasTrailingAddOn || hasLeadingAddOn,
      "rounded-none": (hasTrailingAddOn || hasLeadingAddOn) && appearance === "default",
      "rounded-l-md": hasTrailingAddOn && !hasLeadingAddOn && appearance === "default",
      "rounded-lg": !(hasLeadingAddOn || hasTrailingAddOn) && appearance === "default",
      "rounded-r-md": hasLeadingAddOn && !hasTrailingAddOn && appearance === "default",
      "rounded-full": appearance === "pill",
    },
    className,
  );
  const wrapperClasses = classNames("flex-1 relative", {
    relative: hasTrailingAddOn || hasLeadingAddOn || isError,
  });

  return (
    <div className="flex rounded-lg shadow-sm">
      {hasLeadingAddOn ? (
        <span className="bg-base-200 border-base-300 text-base-neutral inline-flex items-center rounded-l-md border border-r-0 px-3 dark:text-gray-300 sm:text-sm">
          {leadingAddOn}
        </span>
      ) : null}
      <div className={wrapperClasses}>
        {leadingIcon ? (
          <span className="text-base-neutral peer-focus:text-base-content pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 transition">
            <Icon icon={leadingIcon} aria-hidden={true} />
          </span>
        ) : null}
        <input
          className={classes}
          disabled={disabled}
          id={id}
          name={name ?? id}
          type={type}
          {...ariaProps}
          {...props}
          ref={ref}
        />
        <AnimatePresence>
          {trailingIcon || isError || loading ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <motion.div {...motionPresets.scale}>
                {loading ? (
                  <Icon icon={PuffLoader} className="text-base-content-inverse" aria-hidden={true} />
                ) : isError ? (
                  <Tooltip.Provider>
                    <Tooltip.Item>
                      <Icon icon={ExclamationCircleIcon} className="text-error" aria-hidden={true} />
                    </Tooltip.Item>
                    <Tooltip.Panel>{error}</Tooltip.Panel>
                  </Tooltip.Provider>
                ) : trailingIcon ? (
                  <Icon icon={trailingIcon} className="text-base-content-inverse" aria-hidden={true} />
                ) : null}
              </motion.div>
            </div>
          ) : null}
        </AnimatePresence>
      </div>
      {hasTrailingAddOn ? (
        <span className="bg-base-200 border-base-300 inline-flex items-center rounded-r-md border border-l-0 px-3 text-gray-500 dark:text-gray-300 sm:text-sm">
          {trailingAddOn}
        </span>
      ) : null}
    </div>
  );
});

export interface InputProps extends Omit<BaseInputProps, "id">, Omit<FieldGroupProps, "appearance"> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { cornerHint, error, description, label, labelHidden = false, ...props },
  ref,
) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      id={props.id}
      label={label}
      labelHidden={labelHidden}
    >
      <BaseInput {...props} ref={ref} />
    </FieldGroup>
  );
});
