import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { useSigner } from "components/connection/Connection.js";
import { useSharesWrapperRedemptionInfoQuery } from "queries/core";
import { bigDecimalMaxUint256 } from "utils/constants";
import type { RedemptionTimeline } from "utils/sharesWrapper";
import { getRedemptionTimeline, getSharesWrapper } from "utils/sharesWrapper";
import { parseUnits } from "viem";

interface SharesWrapperRedemptionInfoProps {
  vaultId: Address;
  comptroller: Address;
  account: Address;
}

export interface SharedSharesWrapperRedemptionInfo extends RedemptionTimeline {
  duration: number;
  frequency: number;
  sharesWrapperAddress: Address;
  redemptionApprovedShares: bigint;
  depositedShares: bigint;
  noRedemptionLimit: boolean;
  redemptionRequestedShares: bigint;
  redemptionAssetAddress: Address;
}

export function useSharesWrapperRedemptionInfo({ vaultId, comptroller, account }: SharesWrapperRedemptionInfoProps): {
  data: SharedSharesWrapperRedemptionInfo;
  loading: boolean;
} | null {
  const [signerAddress] = useSigner();

  const { data: redemptionInfo, loading } = useSharesWrapperRedemptionInfoQuery({
    variables: {
      vault: vaultId,
      comptroller,
      account,
    },
    skip: signerAddress === undefined,
  });

  if (redemptionInfo === undefined) {
    return null;
  }

  if (!redemptionInfo.allowedDepositRecipientsPolicies[0]) {
    return null;
  }

  if (!redemptionInfo.gatedRedemptionQueueSharesWrappers[0]) {
    return null;
  }

  const allowedDepositRecipientsPolicy = redemptionInfo.allowedDepositRecipientsPolicies[0];

  const sharesWrapper = getSharesWrapper({
    allowedDepositRecipientsPolicy,
    gatedRedemptionQueueSharesWrappers: redemptionInfo.gatedRedemptionQueueSharesWrappers,
  });

  if (sharesWrapper === null) {
    return null;
  }

  const depositorBalances = redemptionInfo.gatedRedemptionQueueSharesWrappers[0].balances;
  const redemptionRequests = redemptionInfo.gatedRedemptionQueueSharesWrappers[0].redemptionRequests;
  const redemptionApprovals = redemptionInfo.gatedRedemptionQueueSharesWrappers[0].redemptionApprovals;

  const depositedShares = depositorBalances[0] ? parseUnits(depositorBalances[0].shares, 18) : 0n;

  const redemptionRequestedShares = redemptionRequests[0] ? parseUnits(redemptionRequests[0].shares, 18) : 0n;

  const redemptionApprovedShares = redemptionApprovals[0] ? parseUnits(redemptionApprovals[0].amount, 18) : 0n;

  const frequency = Number(sharesWrapper.frequency);
  const duration = Number(sharesWrapper.duration);

  const redemptionTimeline = getRedemptionTimeline({
    duration,
    frequency,
    firstWindowStart: Number(sharesWrapper.firstWindowStart),
  });

  const isUnlimited = redemptionApprovals[0] && redemptionApprovals[0].amount === bigDecimalMaxUint256;

  return {
    loading,
    data: {
      ...redemptionTimeline,
      duration,
      frequency,
      sharesWrapperAddress: toAddress(sharesWrapper.id),
      depositedShares,
      redemptionRequestedShares,
      redemptionApprovedShares,
      noRedemptionLimit: !sharesWrapper.useRedemptionApprovals || Boolean(isUnlimited),
      redemptionAssetAddress: toAddress(sharesWrapper.redemptionAsset.id),
    },
  };
}
