import { networks } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { EtherscanLink } from "@enzymefinance/ethereum-ui";
import { ArrowTopRightOnSquareIcon } from "@enzymefinance/icons/solid";
import { Utils } from "@enzymefinance/sdk";
import { Notification } from "@enzymefinance/transaction";
import { Icon, Tooltip, useMotionPresets } from "@enzymefinance/ui";
import { useActor } from "@xstate/react";
import { useNetwork } from "components/providers/NetworkProvider";
import { motion } from "framer-motion";
import { useCallback, useMemo } from "react";
import { decodeTransactionData } from "utils/functions";
import type { FunctionMetadataWithInputs } from "utils/functionsTypes";
import type { Address } from "viem";
import type { MachineInterpreter } from "./manager/TransactionMachine";
import { EventType } from "./manager/TransactionMachine";

interface TransactionToastProps {
  className?: string;
  interpreter: MachineInterpreter;
}

export function TransactionToast({ interpreter }: TransactionToastProps) {
  const motionPresets = useMotionPresets();
  const [state] = useActor(interpreter);
  const { network } = useNetwork();
  const hide = useCallback(() => interpreter.send(EventType.ACKNOWLEDGED), [interpreter]);
  const metadata = useMemo(() => decodeTransactionData(Utils.Hex.asHex(state.context.data)), [state.context.data]);

  return (
    <motion.div className="pointer-events-auto ml-auto flex max-w-sm flex-col" layout={true} {...motionPresets.default}>
      <Notification
        actions={
          <TransactionToast.Actions
            metadata={metadata}
            isFinished={state.matches("finished")}
            to={toAddress(state.context.to)}
          />
        }
        description={
          <TransactionToast.Description
            metadata={metadata}
            isFinished={state.matches("finished")}
            to={toAddress(state.context.to)}
          />
        }
        dismiss={hide}
        state={state.value}
      >
        <EtherscanLink
          className="text-primary focus-visible:ring-primary  dark:hover:text-primary-light hover:text-primary-dark focus-visible:ring-offset-base-100 mt-4 flex items-center space-x-1 text-sm transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
          hash={state.context.hash}
          network={network}
        >
          <span>View on {networks[network].explorer.label}</span>
          <Icon icon={ArrowTopRightOnSquareIcon} />
        </EtherscanLink>
      </Notification>
    </motion.div>
  );
}

interface TransactionToastDescriptionProps {
  isFinished: boolean;
  metadata: FunctionMetadataWithInputs | undefined;
  to: Address;
}

function transactionToastDescription({ metadata, to, isFinished }: TransactionToastDescriptionProps) {
  if (!metadata) {
    return null;
  }

  const { Label, Description, inputs } = metadata;

  return (
    <Tooltip
      label={
        <p className="truncate text-sm">
          <Label address={to} inputs={inputs} isFinished={isFinished} />
        </p>
      }
    >
      <Description address={to} inputs={inputs} />
    </Tooltip>
  );
}

TransactionToast.Description = transactionToastDescription;

interface TransactionToastActionsProps {
  isFinished: boolean;
  metadata: FunctionMetadataWithInputs | undefined;
  to: Address;
}

function transactionToastActions({ metadata, isFinished, to }: TransactionToastActionsProps) {
  const { Actions, inputs } = { ...metadata };

  if (!(Actions && inputs)) {
    return null;
  }

  return <Actions address={to} inputs={inputs} isFinished={isFinished} />;
}

TransactionToast.Actions = transactionToastActions;
