import { Replay, init, reactRouterV6Instrumentation } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AsyncLoadingError } from "error/catchAsyncLoadingError";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { isLocal, isPreview, isProduction, isStaging } from "utils/constants";

import { env } from "./config";

export const replay = new Replay({
  maskAllInputs: true,
  maskAllText: false,
  blockAllMedia: false,
});

export function initializeSentry() {
  init({
    beforeSend(event, hint) {
      // Do not send events for async asset loading errors (e.g. after a new version was deployed).
      if (hint.originalException instanceof AsyncLoadingError) {
        return null;
      }

      return event;
    },
    debug: isLocal,
    enabled: !isLocal,
    dsn: "https://47ee53d443444f98807d55db32e0e4e9@o490110.ingest.sentry.io/5954902",
    dist: "enzyme-app",
    environment: isLocal
      ? "development"
      : isPreview
        ? "preview"
        : isStaging
          ? "staging"
          : isProduction
            ? "production"
            : undefined,
    ignoreErrors: [
      // https://github.com/getsentry/sentry-javascript/issues/2514
      "Non-Error promise rejection captured with value: Timeout",
      "AbortError",
      "cancelled",
      // https://stackoverflow.com/questions/62461559/cannot-read-property-query-of-undefined-in-vanilla-chrome-extension
      `Cannot read properties of undefined (reading 'query')`,
      // https://github.com/google/site-kit-wp/issues/3255
      "Failed to initialize NumberFormat since used feature is not supported in the linked ICU version",
      // error from the extension
      `Cannot assign to read only property 'solana' of object '#<Window>'`,
      // safari issue
      `document.getElementsByTagName('video')[0].webkitExitFullScreen()`,
      "missing revert data in call exception",
    ],
    integrations: [
      replay,
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    release: env.releaseVersion,
    tracesSampleRate: isProduction ? 0.01 : 1,
    replaysOnErrorSampleRate: 1,
    // TODO: Consider recording a couple replay session (1%?) for user behavior research?
    replaysSessionSampleRate: 0,
  });
}
