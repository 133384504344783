import { Skeleton } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { useVaultConfigsByAddress } from "utils/hooks/useVaultConfigByAddress";
import type { Address } from "viem";

export const invokeContinuousFeeHookAndPayoutSharesOutstandingForFund: FunctionConfig<{
  comptrollerProxy: Address;
  fees: Address[];
}> = {
  signature:
    "function invokeContinuousFeeHookAndPayoutSharesOutstandingForFund(address comptrollerProxy, address[] fees)",

  Description({ inputs }) {
    const { contracts } = useGlobals();
    const fees = useVaultConfigsByAddress(contracts, inputs.fees);
    const query = useVaultDetailsFromComptrollerQuery({
      variables: { id: inputs.comptrollerProxy.toLowerCase() },
    });

    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Claim the following fees for {vault?.name ?? "this vault"}: {fees.map((fee) => fee.label).join(", ")}.
      </>
    );
  },

  Label() {
    return <>Claim Fees</>;
  },
};
