import type { Environment, Network, NetworkSlug, Version } from "@enzymefinance/environment";
import { Deployment, getDeployment, isDeployment } from "@enzymefinance/environment";
import { getEnvironment } from "@enzymefinance/environment/deployments/all";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import type { PublicClient } from "viem";
import { usePublicClient } from "wagmi";

const safeNetworkSwitchRoutes = [
  /^\/discover\/?$/,
  /^\/discover\/assets$/,
  /^\/discover\/vaults$/,
  /^\/dashboard\/deposits$/,
  /^\/dashboard\/vaults$/,
  /^\/network\/information/,
];

function shouldAvoidHardReload(currentPath: string) {
  return safeNetworkSwitchRoutes.some((path) => path.test(currentPath));
}

const redirectHomeNetworkSwitchRoutes = [/^\/vault\/.+/];

function shouldRedirectHome(currentPath: string) {
  return redirectHomeNetworkSwitchRoutes.some((path) => path.test(currentPath));
}

interface NetworkContextValues {
  deployment: Deployment;
  environment: Environment<Version.SULU>;
  network: Network;
  client: PublicClient;
  setDeployment: (deployment: Deployment) => void;
  slug: NetworkSlug;
}

const NetworkContext = createContext<NetworkContextValues | undefined>(undefined);

export function useNetwork() {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error("Missing network context");
  }

  return context;
}

interface NetworkProviderProps {
  children?: ReactNode;
}

const defaultNetwork = Deployment.ETHEREUM;

export function NetworkProvider({ children }: NetworkProviderProps) {
  const { state, pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParam = searchParams.get("network");
  const searchParamDeployment = isDeployment(searchParam) ? getDeployment(searchParam).slug : defaultNetwork;
  const [deployment, setDeploymentValue] = useState<Deployment>(searchParamDeployment);

  useEffect(() => {
    if (deployment === searchParamDeployment && searchParam !== defaultNetwork) {
      return;
    }

    if (deployment === defaultNetwork) {
      searchParams.delete("network");
    } else {
      searchParams.set("network", deployment);
    }

    setSearchParams(searchParams, { replace: true, state });
    setDeploymentValue(deployment);
  }, [deployment, setDeploymentValue, setSearchParams, searchParams, searchParamDeployment, state]);

  const setDeployment = useCallback(
    (newDeployment: Deployment) => {
      if (newDeployment === deployment) {
        return;
      }

      // Redirect to home instantly in certain cases, to avoid errors caused by fetching data for the previous network under the new environment context
      if (newDeployment === defaultNetwork) {
        searchParams.delete("network");
      } else {
        searchParams.set("network", newDeployment);
      }

      // Remove `?` if there are no params
      const searchSuffix = `?${searchParams}`.replace(/^\?$/, "");

      // 1 - Vault subroutes: will break and need to redirect home
      if (shouldRedirectHome(pathname)) {
        setDeploymentValue(newDeployment);
        setSearchParams(searchParams, { state });

        navigate({
          pathname: "/",
          search: searchSuffix,
        });

        return;
      }

      // 2 - Safe pages: no hard reload
      if (shouldAvoidHardReload(pathname)) {
        setDeploymentValue(newDeployment);
        setSearchParams(searchParams, { state });

        return;
      }

      // Default - Force reload
      if (typeof window !== "undefined") {
        window.location.assign(`${window.location.pathname}${searchSuffix}`);
      }
    },
    [deployment, searchParams, pathname, state, setSearchParams, navigate],
  );

  const environment = useMemo(() => getEnvironment(deployment), [deployment]);
  const network = environment.network.id;
  const slug = environment.network.slug;
  const client = usePublicClient({ chainId: network });

  if (client === undefined) {
    return null;
  }

  return (
    <NetworkContext.Provider value={{ deployment, environment, network, setDeployment, slug, client }}>
      {children}
    </NetworkContext.Provider>
  );
}
