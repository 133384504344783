import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import introspection from "queries/core.introspection";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { env } from "../../utils/config";
import { useGlobals } from "./GlobalsProvider";

interface SubgraphEnzymeCoreProviderProps {
  children?: ReactNode;
}

export function EnzymeCoreSubgraphProvider({ children }: SubgraphEnzymeCoreProviderProps) {
  const { environment } = useGlobals();

  const client = useMemo(() => {
    const uri =
      env.subgraphMode === "ALCHEMY"
        ? `${window.location.origin}/subgraph/${environment.deployment.subgraphs.core.slug}`
        : env.subgraphMode === "DEV"
          ? `${window.location.origin}/subgraph/${environment.deployment.subgraphs.core.slug}/${environment.deployment.subgraphs.core.devVersion}`
          : `${window.location.origin}/subgraph/${environment.deployment.subgraphs.core.id}`;
    const cache = new InMemoryCache({ addTypename: true, possibleTypes: introspection.possibleTypes });
    const link = ApolloLink.from([new RetryLink(), new HttpLink({ uri })]);

    return new ApolloClient({
      cache,
      defaultOptions: {
        query: {
          pollInterval: 300_000,
        },
        watchQuery: {
          pollInterval: 300_000,
        },
      },
      link,
    });
  }, [environment]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
