import { ArrowPathIcon, DocumentTextIcon, HomeIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import { LoadingScreen } from "components/common/LoadingScreen";
import { RocketCrash } from "components/images/RocketCrash";
import { RocketLaunch } from "components/images/RocketLaunch";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { supportEmailAddress } from "utils/constants";

import { FeedbackPage } from "../feedback/FeedbackPage";

interface ErrorPageProps {
  isOffline?: boolean;
  isLoading?: boolean;
  isUpdateAvailable?: boolean;
  onReset?: () => void;
}

export function ErrorPage({
  isOffline = false,
  isLoading = false,
  isUpdateAvailable = false,
  onReset,
}: ErrorPageProps) {
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isOffline) {
    return (
      <FeedbackPage
        actions={
          <>
            <Button as={Link} appearance="secondary" leadingIcon={HomeIcon} size="xl" to="/">
              Take me Home
            </Button>
            {onReset ? (
              <Button appearance="secondary" leadingIcon={ArrowPathIcon} onClick={onReset} size="xl">
                Retry
              </Button>
            ) : null}
          </>
        }
        description={<p>It looks like the application is currently offline. Please try again later.</p>}
        image={RocketCrash}
        title="Application offline"
      />
    );
  }

  if (isUpdateAvailable) {
    const refresh = () => {
      if (typeof location !== "undefined") {
        location.reload();
      }
    };

    return (
      <FeedbackPage
        actions={
          <Button leadingIcon={ArrowPathIcon} onClick={refresh} size="xl">
            Refresh
          </Button>
        }
        description={<p>A new version of our application is available. Please reload the page to update.</p>}
        image={RocketLaunch}
        title="New version available"
      />
    );
  }

  return (
    <FeedbackPage
      actions={
        <>
          <Button<LinkProps> as={Link} appearance="secondary" leadingIcon={HomeIcon} size="xl" to="/">
            Take me Home
          </Button>
          {onReset ? (
            <Button appearance="secondary" leadingIcon={ArrowPathIcon} onClick={onReset} size="xl">
              Retry
            </Button>
          ) : null}
          <Button as={Link} leadingIcon={DocumentTextIcon} size="xl" to={`mailto:${supportEmailAddress}`}>
            File Bug Report
          </Button>
        </>
      }
      description={
        <>
          <p>It looks like something did not work as expected.</p>
          <p>Help us improve your experience by filing a bug report.</p>
        </>
      }
      image={RocketCrash}
    />
  );
}
