import type { FunctionConfig } from "utils/functionsTypes";

export const setAutoProtocolFeeSharesBuyback: FunctionConfig<{ nextAutoProtocolFeeSharesBuyback: boolean }> = {
  signature: "function setAutoProtocolFeeSharesBuyback(bool nextAutoProtocolFeeSharesBuyback) returns ()",

  Description({ inputs }) {
    const nextValue = inputs.nextAutoProtocolFeeSharesBuyback;

    return <>{nextValue ? "Enable Auto-Access Protocol for Vault" : "Disable Auto-Access Protocol for Vault"}</>;
  },

  Label({ inputs }) {
    const nextValue = inputs.nextAutoProtocolFeeSharesBuyback;

    return <>{nextValue ? "Enable Auto-Access Protocol" : "Disable Auto-Access Protocol"}</>;
  },
};
