import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { IntegrationHandler } from "./types";

export const zeroExV4TakeOrder: IntegrationHandler<Integrations.ZeroExV4.TakeOrderArgs> = {
  Description({
    args: {
      order: { makerAmount, makerToken, takerAmount, takerToken },
      takerAssetFillAmount,
    },
  }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAsset = environment.getAsset(takerToken);
    const buyAsset = environment.getAsset(makerToken);

    const title = "Private Market Maker";
    const exchangeIcon = getIntegrationIconUrl("0x.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: BigInt(takerAmount.toString()),
        }),
      [assetPrices, takerAmount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: BigInt(makerAmount.toString()),
        }),
      [assetPrices, buyAsset, makerAmount],
    );

    const minIncomingAssetAmount = (makerAmount * takerAssetFillAmount) / takerAmount;

    return (
      <>
        <TokenSwapTransactionSummary
          currency={currency}
          buyAsset={buyAsset}
          sellAsset={sellAsset}
          minIncomingAssetAmount={minIncomingAssetAmount}
          outgoingAssetAmount={takerAssetFillAmount}
          buyValueInCurrency={buyValueInCurrency}
          sellValueInCurrency={sellValueInCurrency}
          title={title}
          exchangeIcon={exchangeIcon}
        />
      </>
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.ZeroExV4.takeOrderDecode(encodedCallArgs),
};
