import classNames from "classnames";
import type { HTMLAttributes } from "react";
import { createElement } from "react";

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  as?: "div" | "section";
  appearance?: "default" | "narrow";
  padded?: boolean;
}

export function Container({
  as = "section",
  className,
  children,
  appearance = "default",
  padded = false,
  ...props
}: ContainerProps) {
  const classes = classNames(
    "mx-auto sm:px-6 lg:px-8",
    {
      "max-w-screen-2xl": appearance === "default",
      "max-w-screen-md lg:max-screen-lg": appearance === "narrow",
      "px-4": padded,
    },
    className,
  );

  return createElement(as, { className: classes, ...props }, children);
}
