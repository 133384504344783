import type { ApolloClient } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { useQueryClient } from "@tanstack/react-query";
import { useActor, useMachine } from "@xstate/react";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import type { BlockQuery } from "queries/core";
import { BlockDocument } from "queries/core";
import type { ReactNode } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";
import { TransactionManagerContext } from "utils/contexts";
import { useAsObservable } from "utils/hooks/useAsObservable";

import type { MachineInterpreter } from "./TransactionMachine";
import type { Transaction } from "./TransactionManagerMachine";
import { EventType, createTransactionManagerMachine } from "./TransactionManagerMachine";

export type AddTransactionFn = (transaction: Transaction) => void;

export function useTransactionManager() {
  const context = useContext(TransactionManagerContext);

  if (context === undefined) {
    throw new Error("Missing transaction manager context");
  }

  return context;
}

export function useTransactionManagerTransactions() {
  const [manager] = useTransactionManager();
  const [state] = useActor(manager);

  return state.context.transactions as unknown as MachineInterpreter[];
}

interface TransactionManagerProps {
  children: ReactNode;
}

function useBlockObservable(client: Observable<ApolloClient<any>>) {
  const [block] = useState(() => {
    const subject = new BehaviorSubject(0);

    return new Observable<number>((subscriber) => {
      const query = client.pipe(
        switchMap((clientSwitchMap) => {
          return new Observable<number>((subscriberSwitchMap) => {
            const querySwitchMap = clientSwitchMap.watchQuery<BlockQuery>({
              fetchPolicy: "network-only",
              pollInterval: 10000,
              query: BlockDocument,
            });

            const subscription = querySwitchMap.subscribe((result) => {
              const number = result.data._meta?.block.number;

              if (number !== undefined) {
                subscriberSwitchMap.next(number);
              }
            });

            return () => subscription.unsubscribe();
          });
        }),
      );

      const querySubscription = query.subscribe(subject);
      const blockSubscription = subject.subscribe(subscriber);

      return () => {
        querySubscription.unsubscribe();
        blockSubscription.unsubscribe();
      };
    }).pipe(shareReplay(1));
  });

  return block;
}

export function TransactionManager({ children }: TransactionManagerProps) {
  const [signer] = useSigner();
  const { client } = useNetwork();
  const apollo = useAsObservable(useApolloClient());
  const queries = useAsObservable(useQueryClient());
  const block = useBlockObservable(apollo);
  const [, , service] = useMachine(() => createTransactionManagerMachine(client, block, apollo, queries));
  const { send } = service;

  useEffect(() => {
    send({ signer, type: EventType.AUTH });
  }, [send, signer]);

  const add: AddTransactionFn = useCallback(
    (transaction) => {
      send({ transaction, type: EventType.ADD });
    },
    [send],
  );

  return <TransactionManagerContext.Provider value={[service, add]}>{children}</TransactionManagerContext.Provider>;
}
