import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

export const compoundV2Lend: IntegrationHandler<Integrations.CompoundV2.LendArgs> = {
  Description({ args: { cToken: cTokenAddress, depositAmount, minCTokenAmount } }) {
    const { environment } = useGlobals();

    const cToken = environment.getAsset(cTokenAddress);
    const underlyingToken = cToken.type === AssetType.COMPOUND_V2 ? environment.getAsset(cToken.underlying) : undefined;

    return (
      <>
        Lending on Compound V2 (converting{" "}
        <BigIntDisplay
          value={depositAmount}
          decimals={underlyingToken?.decimals}
          numberFormat={{ currency: underlyingToken?.symbol }}
        />{" "}
        into{" "}
        <BigIntDisplay value={minCTokenAmount} decimals={cToken.decimals} numberFormat={{ currency: cToken.symbol }} />
      </>
    );
  },
  Label() {
    return <>Lend on Compound V2</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.CompoundV2.lendDecode(encodedCallArgs),
};

export const compoundV2Redeem: IntegrationHandler<Integrations.CompoundV2.RedeemArgs> = {
  Description({ args: { cToken: cTokenAddress, redeemAmount, minUnderlyingAmount } }) {
    const { environment } = useGlobals();

    const cToken = environment.getAsset(cTokenAddress);
    const underlyingToken = cToken.type === AssetType.COMPOUND_V2 ? environment.getAsset(cToken.underlying) : undefined;

    return (
      <>
        Redeeming on Compound V2 (converting{" "}
        <BigIntDisplay value={redeemAmount} decimals={cToken.decimals} numberFormat={{ currency: cToken.symbol }} />{" "}
        into{" "}
        <BigIntDisplay
          value={minUnderlyingAmount}
          decimals={underlyingToken?.decimals}
          numberFormat={{ currency: underlyingToken?.symbol }}
        />
      </>
    );
  },
  Label() {
    return <>Redeem on Compound V2</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.CompoundV2.redeemDecode(encodedCallArgs),
};
