import type { Asset } from "@enzymefinance/environment";
import type { StackedProps } from "@enzymefinance/ui";
import { Stacked, useStacked } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import type { ReactNode } from "react";

import { BaseIcon } from "../base-icon/BaseIcon.js";
import type { TokenIconProps } from "../token-icon/TokenIcon.js";
import { TokenIcon } from "../token-icon/TokenIcon.js";

export function useTokenIconStacked() {
  const { displayed, undisplayed } = useStacked<TokenIconProps>();

  return {
    displayed: displayed.map(({ props }) => props.asset).filter(isTruthy),
    undisplayed: undisplayed.map(({ props }) => props.asset).filter(isTruthy),
  };
}

export interface TokenIconStackedProps extends Omit<StackedProps, "content"> {
  children?: ReactNode;
  size?: TokenIconProps["size"];
  loading?: boolean;
  tokens: Asset[];
}

export function TokenIconStacked({
  children,
  tokens,
  size,
  limit = 3,
  loading = false,
  ...props
}: TokenIconStackedProps) {
  return tokens.length > 0 ? (
    <Stacked
      content={children}
      limit={limit}
      distance={typeof size === "number" && size < 6 ? "default" : "narrow"}
      {...props}
    >
      {loading
        ? Array.from({ length: limit }, (_, index) => (
            <Stacked.Item
              as={BaseIcon}
              className="bg-base-300 overflow-hidden rounded-full"
              key={index}
              loading={loading}
              size={size}
            />
          ))
        : tokens.map((asset) => (
            <Stacked.Item
              as={TokenIcon}
              className="bg-base-300 overflow-hidden rounded-full"
              key={asset.id}
              asset={asset}
              size={size}
            />
          ))}
    </Stacked>
  ) : null;
}
