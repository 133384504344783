import { Popover as PopoverBase } from "@headlessui/react";
import classNames from "classnames";
import type {
  ComponentPropsWithoutRef,
  ElementType,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
} from "react";
import { Fragment, createContext, forwardRef, useCallback, useContext } from "react";

import type { PopperProps } from "./popper/Popper.js";
import { Popper } from "./popper/Popper.js";
import type { PopperConfig } from "./popper/PopperProvider.js";
import { PopperProvider, usePopper } from "./popper/PopperProvider.js";

interface PopoverContextValues {
  isOpen: boolean;
  close: () => void;
}

const PopoverContext = createContext<PopoverContextValues | undefined>(undefined);

export function usePopover() {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error("Missing popover context");
  }

  return context;
}

interface PopoverProviderProps {
  children: ReactNode;
  isOpen: boolean;
}

function PopoverProvider({ isOpen, ...props }: PopoverProviderProps) {
  const { referenceElement } = usePopper();
  // Workaround for manually closing the popover
  // https://github.com/tailwindlabs/headlessui/issues/427#issuecomment-826916025
  const close = useCallback(() => referenceElement?.click(), [referenceElement]);

  return <PopoverContext.Provider value={{ close, isOpen }} {...props} />;
}

export interface PopoverProps extends PopperConfig {
  as?: ElementType;
  children: ReactNode;
}

export function Popover({ as = Fragment, distance, modifiers, placement, skidding, ...props }: PopoverProps) {
  return (
    <PopoverBase as={as}>
      {({ open: isOpen }) => (
        <PopperProvider distance={distance} modifiers={modifiers} placement={placement} skidding={skidding}>
          <PopoverProvider isOpen={isOpen} {...props} />
        </PopperProvider>
      )}
    </PopoverBase>
  );
}

export type PopoverButtonProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">> = Omit<
  TProps,
  "as" | "children"
> & {
  as?: ElementType;
  children: ReactNode;
};

const PopoverButton = function PopoverButton<
  TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">,
>({ className, ...props }: PopoverButtonProps<TProps>) {
  const classes = classNames("focus:outline-none", className);
  const { reference } = usePopper();

  return <PopoverBase.Button className={classes} {...props} {...reference} />;
};

Popover.Button = PopoverButton;

export type PopoverCardProps = ComponentPropsWithoutRef<"div">;

const PopoverCard = forwardRef<HTMLDivElement, PopoverCardProps>(function PopoverCard({ className, ...props }, ref) {
  const classes = classNames(
    "bg-base-200/50 dark:bg-base-200/75 backdrop-blur-md text-heading-content border border-base-300/25 dark:border-base-300/25 overflow-hidden shadow rounded-lg px-5 py-4",
    className,
  );

  return <div className={classes} {...props} ref={ref} />;
});

Popover.Card = PopoverCard;

export type PopoverPanelProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = Omit<
  TProps,
  "as" | "children"
> & {
  arrow?: PopperProps["arrow"];
  as?: ElementType;
  children:
    | ReactNode
    | ((props: {
        open: boolean;
        close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;
      }) => ReactElement);
};

function popoverPanel<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  arrow,
  as = PopoverCard,
  ...props
}: PopoverPanelProps<TProps>) {
  const { isOpen } = usePopover();

  return (
    <Popper arrow={arrow} className="z-30" isOpen={isOpen}>
      <PopoverBase.Panel as={as} {...props} static={true} />
    </Popper>
  );
}

Popover.Panel = popoverPanel;
