import { Network, networks } from "@enzymefinance/environment";
import { Button } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import type { ReactNode } from "react";

import { zeroAddress } from "viem";
import { Link } from "../routing/Link";
import type { TxData } from "./TransactionModalMachine";

interface GenerateTenderlyParamsArgs {
  gasPrice?: bigint;
  gasLimit?: bigint;
  network?: Network;
  block?: number;
  from?: string;
  txData: TxData;
}

export function generateTenderlyParams({
  from = zeroAddress,
  gasLimit,
  gasPrice,
  network = Network.ETHEREUM,
  block,
  txData,
}: GenerateTenderlyParamsArgs) {
  const networkId = networks[network].id;

  const valueOrZero = txData.value ?? 0n;
  const value = valueOrZero === 0n ? "" : valueOrZero.toString();

  const gasLimitOrZero = gasLimit ?? 0n;
  const gas = gasLimitOrZero === 0n ? "" : gasLimitOrZero.toString();

  const gasPriceOrZero = gasPrice ?? 0n;
  const price = gasPriceOrZero === 0n ? "" : gasPriceOrZero.toString();

  const contract = txData.to;

  const params = Object.entries({
    from,
    gas,
    gasPrice: price,
    value,
    contractAddress: contract,
    rawFunctionInput: txData.data,
    network: networkId,
    block,
  });

  return params
    .filter(([, val]) => val !== undefined && val !== "")
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
}

interface TenderlyTransactionLinkProps {
  children?: ReactNode;
  gasPrice?: bigint;
  gasLimit?: bigint;
  from?: string;
  txData: TxData;
}

export const tenderlyOrganizationLink = "https://dashboard.tenderly.co/avantgardefinance/enzymefinance/simulator/new?";

export function TenderlyTransactionLink({ children, from, gasPrice, gasLimit, txData }: TenderlyTransactionLinkProps) {
  const { network } = useNetwork();
  const args = generateTenderlyParams({
    from,
    gasLimit,
    gasPrice,
    network,
    txData,
  });

  return (
    <Button
      appearance="destructive"
      as={Link}
      target="_blank"
      disabled={!args}
      to={`${tenderlyOrganizationLink}${args}`}
    >
      {children}
    </Button>
  );
}
