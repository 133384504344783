import { ChevronRightIcon } from "@enzymefinance/icons/solid";
import { Disclosure as DisclosureBase } from "@headlessui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ElementType, ReactNode } from "react";
import { createContext, useContext } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { SectionHeading } from "../headings/SectionHeading.js";
import type { IconProps } from "./Icon.js";
import { Icon } from "./Icon.js";

interface DisclosureContextValues {
  isOpen: boolean;
}

const DisclosureContext = createContext<DisclosureContextValues | undefined>(undefined);

function useDisclosure() {
  const context = useContext(DisclosureContext);

  if (!context) {
    throw new Error("Missing disclosure context");
  }

  return context;
}

interface DisclosureButtonProps {
  as?: ElementType;
  className?: string;
  children: ReactNode;
}

function DisclosureButton({ className, ...props }: DisclosureButtonProps) {
  const classes = classNames("w-full", className);

  return <DisclosureBase.Button className={classes} {...props} />;
}

interface DisclosurePanelProps {
  as?: ElementType;
  children: ReactNode;
}

function DisclosurePanel(props: DisclosurePanelProps) {
  const { isOpen } = useDisclosure();
  const motionPresets = useMotionPresets();

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div {...motionPresets.disclosure}>
          <DisclosureBase.Panel className="text-base-content space-y-6 overflow-hidden" static={true} {...props} />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

type DisclosureIconProps = Partial<IconProps>;

function DisclosureIcon({ className, icon = ChevronRightIcon, ...props }: DisclosureIconProps) {
  const { isOpen } = useDisclosure();
  const classes = classNames(
    "group-hover:text-gray-700 dark:group-hover:text-gray-200 transition",
    {
      "rotate-90": isOpen,
    },
    className,
  );

  return <Icon className={classes} icon={icon} size={6} {...props} />;
}

export interface DisclosureProps {
  children?: ReactNode;
  initialOpen?: boolean;
  title?: ReactNode;
}

export function Disclosure({ children, initialOpen, title }: DisclosureProps) {
  return (
    <DisclosureBase defaultOpen={initialOpen}>
      {({ open: isOpen }) => (
        <DisclosureContext.Provider value={{ isOpen }}>
          <DisclosureButton className="focus-visible:ring-primary dark:focus-visible:ring-primary text-heading-content focus-visible:ring-offset-base-100 group flex items-center justify-between rounded-md py-3 text-xl font-medium transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2">
            <SectionHeading.Title className="transition group-hover:text-gray-700 dark:group-hover:text-gray-200">
              {title}
            </SectionHeading.Title>
            <DisclosureIcon />
          </DisclosureButton>
          <DisclosurePanel>{children}</DisclosurePanel>
        </DisclosureContext.Provider>
      )}
    </DisclosureBase>
  );
}
