import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { formatUnits } from "viem";

export const buyShares: FunctionConfig<{ investmentAmount: bigint; minSharesQuantity: bigint }> = {
  signature: "function buyShares(uint256 investmentAmount, uint256 minSharesQuantity)",

  Description({ address, inputs }) {
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: address.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;
    const amount = formatUnits(inputs.investmentAmount, vault?.comptroller.denomination.decimals ?? 18);

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Deposit {amount} {vault?.comptroller.denomination.symbol ?? "tokens"} to buy shares for{" "}
        {vault?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Buy Shares</>;
  },
};
