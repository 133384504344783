import type { Props } from "react-select";

// Reset default styles
export const styles: Props["styles"] = {
  clearIndicator: () => ({}),
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  indicatorsContainer: () => ({}),
  input: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
};
