import type { IconLabelProps, JazziconProps } from "@enzymefinance/ui";
import { IconLabel, Jazzicon } from "@enzymefinance/ui";
import type { ReactNode } from "react";

export interface AddressWithLabelProps
  extends Omit<IconLabelProps, "size" | "title">,
    Pick<JazziconProps, "id" | "size"> {
  name: ReactNode;
  reverse?: boolean;
}

export function AddressWithLabel({
  description,
  id,
  kind,
  name,
  reverse = false,
  size,
  ...props
}: AddressWithLabelProps) {
  return (
    <IconLabel
      reverse={reverse}
      description={
        kind === "definition" || kind === "option" ? <span aria-hidden={true}>{description}</span> : description
      }
      icon={<Jazzicon id={id} size={size} />}
      space={size === 3 || size === 4 || size === 5 ? 2 : 4}
      title={name}
      {...props}
    />
  );
}
