import type { FixedSize } from "@enzymefinance/utils";
import { sizeAttributes } from "@enzymefinance/utils";
import type { ComponentPropsWithoutRef } from "react";

export interface EnzymeLoaderProps extends ComponentPropsWithoutRef<"svg"> {
  size?: Extract<FixedSize, 12 | 16>;
}

export function EnzymeLoader({ size = 16, ...props }: EnzymeLoaderProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 30" fill="none" {...sizeAttributes[size]} {...props}>
      <defs>
        <linearGradient
          id="enzyme-loader-gradient-0"
          x1="28.038"
          y1="3.1831"
          x2="28.038"
          y2="10.4335"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-1"
          x1="20.2656"
          y1="26.7545"
          x2="20.2656"
          y2="19.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-2"
          x1="13.8159"
          y1="7.07434"
          x2="13.8158"
          y2="11.9079"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-3"
          x1="8.0965"
          y1="20.3467"
          x2="8.0965"
          y2="16.9348"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-4"
          x1="43.5646"
          y1="18.6869"
          x2="43.5646"
          y2="16.3123"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-5"
          x1="2.43537"
          y1="11.0429"
          x2="2.43537"
          y2="13.4175"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-6"
          x1="34.3459"
          y1="23.7796"
          x2="34.3459"
          y2="18.2352"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
        <linearGradient
          id="enzyme-loader-gradient-7"
          x1="39.0387"
          y1="6.84312"
          x2="39.0387"
          y2="11.8189"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6f56fd" />
          <stop offset="1" stopColor="#00cabe" />
          <stop offset="1" stopColor="#00cabe" />
        </linearGradient>
      </defs>
      <style>{`
  @keyframes enzyme-loader-a0_d { 0% { d: path('M24.2251,3.8129C24.2251,1.70709,25.9322,0,28.038,0L28.038,0C30.1438,0,31.8509,1.70709,31.8509,3.8129L31.8509,14.8545L24.2251,14.8545L24.2251,3.8129Z'); } 28.5714% { d: path('M24.2251,3.8129C24.2251,1.70709,25.9322,0,28.038,0L28.038,0C30.1438,0,31.8509,1.70709,31.8509,3.8129L31.8509,14.8545L24.2251,14.8545L24.2251,3.8129Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 42.8571% { d: path('M24.2251,11.8129C24.2251,9.7071,25.9322,8,28.038,8L28.038,8C30.1438,8,31.8509,9.7071,31.8509,11.8129L31.8509,14.8545L24.2251,14.8545L24.2251,11.8129Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 66.6667% { d: path('M24.2251,3.8129C24.2251,1.70709,25.9322,0,28.038,0L28.038,0C30.1438,0,31.8509,1.70709,31.8509,3.8129L31.8509,14.8545L24.2251,14.8545L24.2251,3.8129Z'); } 100% { d: path('M24.2251,3.8129C24.2251,1.70709,25.9322,0,28.038,0L28.038,0C30.1438,0,31.8509,1.70709,31.8509,3.8129L31.8509,14.8545L24.2251,14.8545L24.2251,3.8129Z'); } }
  @keyframes enzyme-loader-a1_d { 0% { d: path('M24.2251,26.0405C24.2251,28.2273,22.4524,30,20.2656,30L20.2656,30C18.0788,30,16.306,28.2273,16.306,26.0405L16.306,14.8543L24.2251,14.8543L24.2251,26.0405Z'); } 14.2857% { d: path('M24.2251,26.0405C24.2251,28.2273,22.4524,30,20.2656,30L20.2656,30C18.0788,30,16.306,28.2273,16.306,26.0405L16.306,14.8543L24.2251,14.8543L24.2251,26.0405Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 28.5714% { d: path('M24.2251,17.0405C24.2251,19.2273,22.4524,21,20.2656,21L20.2656,21C18.0788,21,16.306,19.2273,16.306,17.0405L16.306,14.8543L24.2251,14.8543L24.2251,17.0405Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 47.619% { d: path('M24.2251,26.0405C24.2251,28.2273,22.4524,30,20.2656,30L20.2656,30C18.0788,30,16.306,28.2273,16.306,26.0405L16.306,14.8543L24.2251,14.8543L24.2251,26.0405Z'); } 100% { d: path('M24.2251,26.0405C24.2251,28.2273,22.4524,30,20.2656,30L20.2656,30C18.0788,30,16.306,28.2273,16.306,26.0405L16.306,14.8543L24.2251,14.8543L24.2251,26.0405Z'); } }
  @keyframes enzyme-loader-a2_d { 0% { d: path('M11.3228,7.44532C11.3228,6.06845,12.439,4.95227,13.8159,4.95228L13.8159,4.95228C15.1927,4.95228,16.3089,6.06845,16.3089,7.44533L16.3089,14.8552L11.3228,14.8552L11.3228,7.44532Z'); } 9.5238% { d: path('M11.3228,7.44532C11.3228,6.06845,12.439,4.95227,13.8159,4.95228L13.8159,4.95228C15.1927,4.95228,16.3089,6.06845,16.3089,7.44533L16.3089,14.8552L11.3228,14.8552L11.3228,7.44532Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 19.0476% { d: path('M11.3228,12.4453C11.3228,11.0684,12.439,9.95227,13.8159,9.95228L13.8159,9.95228C15.1927,9.95228,16.3089,11.0684,16.3089,12.4453L16.3089,14.8552L11.3228,14.8552L11.3228,12.4453Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 42.8571% { d: path('M11.3228,7.44532C11.3228,6.06845,12.439,4.95227,13.8159,4.95228L13.8159,4.95228C15.1927,4.95228,16.3089,6.06845,16.3089,7.44533L16.3089,14.8552L11.3228,14.8552L11.3228,7.44532Z'); } 100% { d: path('M11.3228,7.44532C11.3228,6.06845,12.439,4.95227,13.8159,4.95228L13.8159,4.95228C15.1927,4.95228,16.3089,6.06845,16.3089,7.44533L16.3089,14.8552L11.3228,14.8552L11.3228,7.44532Z'); } }
  @keyframes enzyme-loader-a3_d { 0% { d: path('M11.3228,18.6184C11.3228,20.4002,9.87834,21.8447,8.0965,21.8447L8.0965,21.8447C6.31466,21.8447,4.8702,20.4002,4.8702,18.6184L4.8702,14.8543L11.3228,14.8543L11.3228,18.6184Z'); } 4.7619% { d: path('M11.3228,18.6184C11.3228,20.4002,9.87834,21.8447,8.0965,21.8447L8.0965,21.8447C6.31466,21.8447,4.8702,20.4002,4.8702,18.6184L4.8702,14.8543L11.3228,14.8543L11.3228,18.6184Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 19.0476% { d: path('M11.3228,15.6184C11.3228,17.4002,9.87834,18.8447,8.0965,18.8447L8.0965,18.8447C6.31466,18.8447,4.8702,17.4002,4.8702,15.6184L4.8702,14.8543L11.3228,14.8543L11.3228,15.6184Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 38.0952% { d: path('M11.3228,18.6184C11.3228,20.4002,9.87834,21.8447,8.0965,21.8447L8.0965,21.8447C6.31466,21.8447,4.8702,20.4002,4.8702,18.6184L4.8702,14.8543L11.3228,14.8543L11.3228,18.6184Z'); } 100% { d: path('M11.3228,18.6184C11.3228,20.4002,9.87834,21.8447,8.0965,21.8447L8.0965,21.8447C6.31466,21.8447,4.8702,20.4002,4.8702,18.6184L4.8702,14.8543L11.3228,14.8543L11.3228,18.6184Z'); } }
  @keyframes enzyme-loader-a4_d { 0% { d: path('M46,17.294C46,18.639,44.9096,19.7293,43.5646,19.7293L43.5646,19.7293C42.2196,19.7293,41.1292,18.639,41.1292,17.294L41.1292,14.8645L46,14.8645L46,17.294Z'); } 61.9048% { d: path('M46,17.294C46,18.639,44.9096,19.7293,43.5646,19.7293L43.5646,19.7293C42.2196,19.7293,41.1292,18.639,41.1292,17.294L41.1292,14.8645L46,14.8645L46,17.294Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 76.1905% { d: path('M46,14.794C46,16.139,44.9096,17.2293,43.5646,17.2293L43.5646,17.2293C42.2196,17.2293,41.1292,16.139,41.1292,14.794L41.1292,14.8645L46,14.8645L46,14.794Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 100% { d: path('M46,17.294C46,18.639,44.9096,19.7293,43.5646,19.7293L43.5646,19.7293C42.2196,19.7293,41.1292,18.639,41.1292,17.294L41.1292,14.8645L46,14.8645L46,17.294Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } }
  @keyframes enzyme-loader-a5_d { 0% { d: path('M0,12.4358C0,11.0908,1.09035,10.0005,2.43537,10.0005L2.43537,10.0005C3.78039,10.0005,4.87074,11.0908,4.87074,12.4358L4.87074,14.8653L0,14.8653L0,12.4358Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 14.2857% { d: path('M0,14.4358C0,13.0908,1.09035,12.0005,2.43537,12.0005L2.43537,12.0005C3.78039,12.0005,4.87074,13.0908,4.87074,14.4358L4.87074,14.8653L0,14.8653L0,14.4358Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 28.5714% { d: path('M0,12.4358C0,11.0908,1.09035,10.0005,2.43537,10.0005L2.43537,10.0005C3.78039,10.0005,4.87074,11.0908,4.87074,12.4358L4.87074,14.8653L0,14.8653L0,12.4358Z'); } 100% { d: path('M0,12.4358C0,11.0908,1.09035,10.0005,2.43537,10.0005L2.43537,10.0005C3.78039,10.0005,4.87074,11.0908,4.87074,12.4358L4.87074,14.8653L0,14.8653L0,12.4358Z'); } }
  @keyframes enzyme-loader-a6_d { 0% { d: path('M36.839,23.7207C36.839,25.0976,35.7228,26.2138,34.3459,26.2138L34.3459,26.2138C32.9691,26.2138,31.8529,25.0976,31.8529,23.7207L31.8529,14.8545L36.839,14.8545L36.839,23.7207Z'); } 33.3333% { d: path('M36.839,23.7207C36.839,25.0976,35.7228,26.2138,34.3459,26.2138L34.3459,26.2138C32.9691,26.2138,31.8529,25.0976,31.8529,23.7207L31.8529,14.8545L36.839,14.8545L36.839,23.7207Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 47.619% { d: path('M36.839,17.7207C36.839,19.0976,35.7228,20.2138,34.3459,20.2138L34.3459,20.2138C32.9691,20.2138,31.8529,19.0976,31.8529,17.7207L31.8529,14.8545L36.839,14.8545L36.839,17.7207Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 66.6667% { d: path('M36.839,23.7207C36.839,25.0976,35.7228,26.2138,34.3459,26.2138L34.3459,26.2138C32.9691,26.2138,31.8529,25.0976,31.8529,23.7207L31.8529,14.8545L36.839,14.8545L36.839,23.7207Z'); } 100% { d: path('M36.839,23.7207C36.839,25.0976,35.7228,26.2138,34.3459,26.2138L34.3459,26.2138C32.9691,26.2138,31.8529,25.0976,31.8529,23.7207L31.8529,14.8545L36.839,14.8545L36.839,23.7207Z'); } }
  @keyframes enzyme-loader-a7_d { 0% { d: path('M36.839,6.85839C36.839,5.64351,37.8238,4.65865,39.0387,4.65865L39.0387,4.65865C40.2536,4.65865,41.2385,5.64351,41.2385,6.8584L41.2385,14.8529L36.839,14.8529L36.839,6.85839Z'); } 47.619% { d: path('M36.839,6.85839C36.839,5.64351,37.8238,4.65865,39.0387,4.65865L39.0387,4.65865C40.2536,4.65865,41.2385,5.64351,41.2385,6.8584L41.2385,14.8529L36.839,14.8529L36.839,6.85839Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 62.4286% { d: path('M36.839,11.8584C36.839,10.6435,37.8238,9.6586,39.0387,9.6586L39.0387,9.6586C40.2536,9.6586,41.2385,10.6435,41.2385,11.8584L41.2385,14.8529L36.839,14.8529L36.839,11.8584Z'); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 80.9524% { d: path('M36.839,6.85839C36.839,5.64351,37.8238,4.65865,39.0387,4.65865L39.0387,4.65865C40.2536,4.65865,41.2385,5.64351,41.2385,6.8584L41.2385,14.8529L36.839,14.8529L36.839,6.85839Z'); } 100% { d: path('M36.839,6.85839C36.839,5.64351,37.8238,4.65865,39.0387,4.65865L39.0387,4.65865C40.2536,4.65865,41.2385,5.64351,41.2385,6.8584L41.2385,14.8529L36.839,14.8529L36.839,6.85839Z'); } }
      `}</style>
      <path
        d="M24.2251,3.8129C24.2251,1.70709,25.9322,0,28.038,0L28.038,0C30.1438,0,31.8509,1.70709,31.8509,3.8129L31.8509,14.8545L24.2251,14.8545L24.2251,3.8129Z"
        fill="url(#enzyme-loader-gradient-0)"
        transform="translate(28.038,7.42725) translate(-28.038,-7.42725)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a0_d" }}
      />
      <path
        d="M24.2251,26.0405C24.2251,28.2273,22.4524,30,20.2656,30L20.2656,30C18.0788,30,16.306,28.2273,16.306,26.0405L16.306,14.8543L24.2251,14.8543L24.2251,26.0405Z"
        fill="url(#enzyme-loader-gradient-1)"
        transform="translate(20.2655,22.4272) translate(-20.2655,-22.4272)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a1_d" }}
      />
      <path
        d="M11.3228,7.44532C11.3228,6.06845,12.439,4.95227,13.8159,4.95228L13.8159,4.95228C15.1927,4.95228,16.3089,6.06845,16.3089,7.44533L16.3089,14.8552L11.3228,14.8552L11.3228,7.44532Z"
        fill="url(#enzyme-loader-gradient-2)"
        transform="translate(13.8159,9.90374) translate(-13.8159,-9.90374)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a2_d" }}
      />
      <path
        d="M11.3228,18.6184C11.3228,20.4002,9.87834,21.8447,8.0965,21.8447L8.0965,21.8447C6.31466,21.8447,4.8702,20.4002,4.8702,18.6184L4.8702,14.8543L11.3228,14.8543L11.3228,18.6184Z"
        fill="url(#enzyme-loader-gradient-3)"
        transform="translate(8.0965,18.3495) translate(-8.0965,-18.3495)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a3_d" }}
      />
      <path
        d="M46,17.294C46,18.639,44.9096,19.7293,43.5646,19.7293L43.5646,19.7293C42.2196,19.7293,41.1292,18.639,41.1292,17.294L41.1292,14.8645L46,14.8645L46,17.294Z"
        fill="url(#enzyme-loader-gradient-4)"
        transform="translate(43.5646,17.2969) translate(-43.5646,-17.2969)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-§a4_d" }}
      />
      <path
        d="M0,12.4358C0,11.0908,1.09035,10.0005,2.43537,10.0005L2.43537,10.0005C3.78039,10.0005,4.87074,11.0908,4.87074,12.4358L4.87074,14.8653L0,14.8653L0,12.4358Z"
        fill="url(#enzyme-loader-gradient-5)"
        transform="translate(2.43537,12.4329) translate(-2.43537,-12.4329)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a5_d" }}
      />
      <path
        d="M36.839,23.7207C36.839,25.0976,35.7228,26.2138,34.3459,26.2138L34.3459,26.2138C32.9691,26.2138,31.8529,25.0976,31.8529,23.7207L31.8529,14.8545L36.839,14.8545L36.839,23.7207Z"
        fill="url(#enzyme-loader-gradient-6)"
        transform="translate(34.346,20.5342) translate(-34.346,-20.5342)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a6_d" }}
      />
      <path
        d="M36.839,6.85839C36.839,5.64351,37.8238,4.65865,39.0387,4.65865L39.0387,4.65865C40.2536,4.65865,41.2385,5.64351,41.2385,6.8584L41.2385,14.8529L36.839,14.8529L36.839,6.85839Z"
        fill="url(#enzyme-loader-gradient-7)"
        transform="translate(39.0388,9.75577) translate(-39.0388,-9.75577)"
        style={{ animation: "2.1s linear infinite both enzyme-loader-a7_d" }}
      />
    </svg>
  );
}
