import { Toast, ToastsContainer, useToasts } from "@enzymefinance/toast";
import { TransactionToast } from "components/transactions/TransactionToast";
import { useTransactionManagerTransactions } from "components/transactions/manager/TransactionManager";

export function Toasts() {
  const { toasts: toastsBase } = useToasts();
  const transactions = useTransactionManagerTransactions();

  const toasts = toastsBase.map((toast) => <Toast key={toast.id} {...toast} />);
  const transactionToasts = transactions.map((transaction) => (
    <TransactionToast key={transaction.id} interpreter={transaction} />
  ));

  return <ToastsContainer>{[...toasts, ...transactionToasts]}</ToastsContainer>;
}
