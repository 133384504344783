import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import { AnimatePresence, motion } from "framer-motion";
import { Button, type ButtonProps } from "./Button.js";

interface ExpandableButtonProps extends ButtonProps {
  appearance?: "primary" | "secondary";
  isOpen: boolean;
  label: string;
  handleExpand: () => void;
}

export function ExpandableButton({
  appearance = "primary",
  children,
  isOpen,
  label,
  handleExpand,
  ...props
}: ExpandableButtonProps) {
  return (
    <>
      <Button
        appearance={`text-${appearance}`}
        shouldIconRotate={isOpen}
        trailingIcon={ChevronDownIcon}
        onClick={handleExpand}
        {...props}
      >
        {label}
      </Button>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
