import type { Address } from "@enzymefinance/environment";

import type { ReleaseContracts } from "./types.js";
import { isSomeEnum } from "./types.js";

export interface ExternalPosition {
  id: Address;
  name: string;
  url: string;
}

// Note: The values of this enum match the types defined on chain and should not be changed
export enum ExternalPositionType {
  AAVE_DEBT = "AAVE_DEBT",
  AAVE_V3_DEBT = "AAVE_V3_DEBT",
  ALICE = "ALICE",
  ARBITRARY_LOAN = "ARBITRARY_LOAN",
  COMPOUND_DEBT = "COMPOUND_DEBT",
  CONVEX_VOTING = "CONVEX_VOTING",
  GMX_V2_LEVERAGE_TRADING = "GMX_V2_LEVERAGE_TRADING",
  KILN_STAKING = "KILN_STAKING",
  LIDO_WITHDRAWALS = "LIDO_WITHDRAWALS",
  LIQUITY_DEBT = "LIQUITY_DEBT",
  MAPLE_LIQUIDITY = "MAPLE_LIQUIDITY",
  MORPHO_BLUE = "MORPHO_BLUE",
  NOTIONAL_V2 = "NOTIONAL_V2", // Used only for Moab Vault 0x278c647f7cfb9d55580c69d3676938608c945ba8, UI for it never got into production
  PENDLE_V2 = "PENDLE_V2",
  STAKEWISE_V3 = "STAKEWISE_V3",
  THEGRAPH_DELEGATION = "THEGRAPH_DELEGATION",
  UNISWAP_V3_LIQUIDITY = "UNISWAP_V3_LIQUIDITY",
  UNKNOWN = "UNKNOWN",
}

export interface ExternalPositionDefinition<Type extends ExternalPositionType = ExternalPositionType> {
  label: string;
  icon: string;
  address: (deployment: ReleaseContracts) => Address;
  type: Type;
}

export type ExternalPositionRecord<T extends ExternalPositionType = ExternalPositionType> = {
  [P in T]: ExternalPositionDefinition<P>;
};

export const isExternalPositionTypeEnum = isSomeEnum(ExternalPositionType);
