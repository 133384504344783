import type { FunctionConfig } from "utils/functionsTypes";

export const setName: FunctionConfig<{ nextName: string }> = {
  signature: "function setName(string nextName)",

  Description({ inputs }) {
    const nextName = inputs.nextName;

    return <>Change vault name to {nextName}.</>;
  },

  Label() {
    return <>Change Vault Name</>;
  },
};
