/**
 * Calculates the ideal number of fraction digits for a number
 * Examples:
 *
 * -1 => 0 digits
 * 0 => 0 digits
 * 0.09423 => 7 digits
 * 0.00001 => 10 digits
 * 0.00005 => 10 digits
 * 0.000000000123456789 => 15 digits
 * 10001 => 1 digits
 * 23423424242 => 0 digits
 * 2043923049230492 => 0 digits
 */
export function getDisplayFractionDigits(value: number | undefined, baseDigits = 5) {
  if (!value || value < 0) {
    return baseDigits;
  }

  const log10 = Math.floor(Math.log10(value));

  return Math.max(0, Math.min(baseDigits - log10, 18));
}

export function absolute(value: bigint) {
  return value < 0n ? -value : value;
}
