import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Form, FormErrorMessage, NumberInput, useForm } from "@enzymefinance/hook-form";
import { Asset, Tools, Utils } from "@enzymefinance/sdk";
import { Alert, Button, Card, Modal } from "@enzymefinance/ui";
import { bigIntInput, refine, safeParse } from "@enzymefinance/validation";
import { captureException } from "@sentry/react";
import { useSigner } from "components/connection/Connection";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import type { StartSdkFunction } from "components/transactions/TransactionModal";
import { useCallback, useMemo, useState } from "react";
import type { SharedRedemptionQueueRedemptionInfo } from "utils/hooks/useSingleAssetRedemptionQueueInfo";
import { formatUnits, maxUint256 } from "viem";
import { z } from "zod";
import { decodeTransactionData } from "../../../utils/functions";
import { useAllowance } from "../../../utils/hooks/useAllowance";
import { useApproveTransactions } from "../../../utils/hooks/useApproveTransactions";
import { useNetwork } from "../../providers/NetworkProvider";
import type { MachineState } from "../../transactions/TransactionModalMachine";
import { VaultRedeemRedemptionQueueFormSubmitButton } from "./VaultRedeemRedemptionQueueFormSubmitButton";

const schema = z
  .object({
    depositedShares: z.bigint(),
    redeemQuantity: bigIntInput({
      decimals: 18,
    }),
  })
  .transform(
    refine((value) => value.depositedShares >= value.redeemQuantity, {
      message: "The shares amount exceeds your current balance.",
      path: ["redeemQuantity"],
    }),
  );

interface VaultRedeemRedemptionQueueFormProps {
  startTransaction: StartSdkFunction;
  close: () => void;
  redemptionQueueRedemptionInfo: SharedRedemptionQueueRedemptionInfo;
  vaultProxy: string;
  comptrollerProxy: string;
  state: MachineState;
}

export function VaultRedeemRedemptionQueueForm({
  startTransaction,
  vaultProxy,
  comptrollerProxy,
  redemptionQueueRedemptionInfo,
  state,
}: VaultRedeemRedemptionQueueFormProps) {
  const [signerAddress] = useSigner();
  const { loading: assetPricesLoading } = useAssetPrices();
  const { client } = useNetwork();

  const [savedRedemptionAmount, setSavedRedemptionAmount] = useState(0n);

  const depositedShares = redemptionQueueRedemptionInfo.depositedShares;
  const redemptionRequestedShares = redemptionQueueRedemptionInfo.requests.reduce(
    (carry, item) => carry + item.shares,
    0n,
  );

  const allowanceQuery = useAllowance(client, {
    account: signerAddress,
    spender: redemptionQueueRedemptionInfo.redemptionQueueAddress,
    token: toAddress(vaultProxy),
  });

  const defaultRedeemQuantity = useMemo(() => {
    if (savedRedemptionAmount) {
      return formatUnits(savedRedemptionAmount, 18);
    }

    const minAmount = Utils.BI.min(depositedShares ?? 0n, allowanceQuery.data ?? maxUint256);
    return formatUnits(minAmount, 18);
  }, [allowanceQuery.data, savedRedemptionAmount, depositedShares]);

  const form = useForm({
    defaultValues: {
      depositedShares,
      redeemQuantity: defaultRedeemQuantity,
    },
    mode: "onTouched",
    onSubmit(values, helpers) {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        if (allowanceQuery.data === undefined) {
          return helpers.setError("form", {
            message: "Error fetching the allowance",
          });
        }

        if (allowanceQuery.data < values.redeemQuantity) {
          const fn = Asset.approve({
            asset: toAddress(vaultProxy),
            amount: values.redeemQuantity,
            spender: redemptionQueueRedemptionInfo.redemptionQueueAddress,
          });
          startTransaction(fn, signerAddress);
          setSavedRedemptionAmount(values.redeemQuantity);
        } else {
          const fn = Tools.SingleAssetRedemptionQueue.requestRedeem({
            redemptionQueue: redemptionQueueRedemptionInfo.redemptionQueueAddress,
            sharesAmount: values.redeemQuantity,
          });
          startTransaction(fn, signerAddress);
          close();
        }
      } catch (error) {
        captureException(error);

        return helpers.setError("form", {
          message: "Something went wrong.",
          type: "report",
        });
      }
    },
    schema,
  });

  const withdrawRequestRedeem = (requestId: bigint) =>
    useCallback(() => {
      if (!signerAddress) {
        return;
      }

      const fn = Tools.SingleAssetRedemptionQueue.withdrawRequest({
        redemptionQueue: redemptionQueueRedemptionInfo.redemptionQueueAddress,
        requestId,
      });

      startTransaction(fn, signerAddress);
      close();
    }, [redemptionQueueRedemptionInfo.redemptionAssetAddress, signerAddress, startTransaction]);

  const isFormDisabled = assetPricesLoading;

  const [redeemQuantity] = form.watch(["redeemQuantity"]);

  const parsedRedeemQuantity = safeParse(bigIntInput({ decimals: 18 }), redeemQuantity) ?? 0n;

  const sufficientAllowance = (allowanceQuery.data ?? 0n) >= parsedRedeemQuantity;

  const approveTransactions = useApproveTransactions(comptrollerProxy);
  const isValid = !!(
    form.formState.isValid &&
    !form.formState.isSubmitting &&
    !form.formState.isValidating &&
    allowanceQuery.data
  );

  const isSubmittingApprove = approveTransactions.isSubmitting;
  const isPendingApprove = approveTransactions.isPending;
  const txData = state.context.txData?.data;
  const isRequestRedeem =
    txData !== undefined ? decodeTransactionData(txData)?.fragment.name === "requestRedeem" : false;
  const isSubmittingRequestRedeem = form.formState.isSubmitting && isRequestRedeem;

  const isShutdown = redemptionQueueRedemptionInfo.shutdown === true;

  return (
    <Form form={form}>
      <Modal.Body className="space-y-4 ">
        {isShutdown ? (
          <p>The redemption queue is shut down. Please withdraw your pending requests.</p>
        ) : (
          <p>
            To redeem your investment, please submit a request. It will be reviewed and executed alongside other
            redemption requests on a FIFO (First In, First Out) basis.
          </p>
        )}
        <Card appearance="secondary">
          <Card.Content className="space-y-2 divide-y divide-gray-300 dark:divide-gray-700">
            <div className="flex flex-row items-baseline justify-between">
              <span className="text-base-content text-md font-medium">Your Current Balance</span>
              <div className="text-base-content text-md font-medium">
                <BigIntDisplay numberFormat={{ maximumFractionDigits: 20 }} value={depositedShares} />{" "}
                <span className="text-sm">shares</span>
              </div>
            </div>
          </Card.Content>
        </Card>
        {redemptionRequestedShares > 0n
          ? redemptionQueueRedemptionInfo.requests.map((request) => (
              <Alert
                appearance="info"
                key={request.id}
                title={
                  <span>
                    Your request to redeem{" "}
                    <BigIntDisplay numberFormat={{ maximumFractionDigits: 20 }} value={request.shares} /> shares is
                    pending
                  </span>
                }
              >
                {redemptionQueueRedemptionInfo.shutdown ? (
                  <div className="space-y-3">
                    <Button appearance="destructive" onClick={withdrawRequestRedeem(request.requestId)}>
                      Withdraw request
                    </Button>
                  </div>
                ) : null}
              </Alert>
            ))
          : null}

        {isShutdown ? (
          <></>
        ) : (
          <NumberInput
            appearance="extended"
            wrapperclassname="rounded-lg"
            balance={formatUnits(depositedShares, 18)}
            name="redeemQuantity"
            label="Request shares to redeem"
            disabled={isFormDisabled}
          />
        )}

        <FormErrorMessage />
      </Modal.Body>
      {isShutdown ? (
        <></>
      ) : (
        <Modal.Actions>
          <VaultRedeemRedemptionQueueFormSubmitButton
            isPendingApprove={isPendingApprove}
            isSubmittingApprove={isSubmittingApprove}
            isSubmittingRequestRedeem={isSubmittingRequestRedeem}
            isValid={isValid}
            sufficientAllowance={sufficientAllowance}
          />
        </Modal.Actions>
      )}
    </Form>
  );
}
