import { toAddress } from "@enzymefinance/environment";
import { Badge } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import { useTokenBalance } from "utils/hooks/useTokenBalance";

interface VaultDepositedBadgeProps {
  accountId: string;
  vaultId: string;
}

export function VaultDepositedBadge({ accountId, vaultId }: VaultDepositedBadgeProps) {
  const { client } = useNetwork();
  const query = useTokenBalance(client, { account: toAddress(accountId), token: toAddress(vaultId) });
  const isDeposited = (query.data ?? 0n) > 0n;

  return isDeposited ? (
    <div className="flex items-center p-2">
      <Badge appearance="success">Deposited</Badge>
    </div>
  ) : null;
}
