import { BigIntDisplay } from "@enzymefinance/ethereum-ui";

import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useNetwork } from "components/providers/NetworkProvider";
import { useAliceInstrumentDetails } from "utils/hooks/useAliceInstrumentDetails";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const alicePlaceOrder: ExternalPositionHandler<Integrations.Alice.PlaceOrderArgs> = {
  Description({ args: { instrumentId, isBuyOrder, limitAmountToGet, quantityToSell } }) {
    const { environment, client } = useNetwork();
    const { aliceOrderManager } = environment.externalContracts;

    const { data: aliceInstrumentDetails } = useAliceInstrumentDetails(client, {
      aliceOrderManagerAddress: aliceOrderManager,
      instrumentId,
      mustBeActive: false,
    });

    if (aliceInstrumentDetails === undefined) {
      return <>Place LMAX order</>;
    }

    // Fetch the details from the instrument.
    const sellAsset = environment.getAsset(
      isBuyOrder ? aliceInstrumentDetails.quoteAssetAddress : aliceInstrumentDetails.baseAssetAddress,
    );
    const buyAsset = environment.getAsset(
      isBuyOrder ? aliceInstrumentDetails.baseAssetAddress : aliceInstrumentDetails.quoteAssetAddress,
    );

    return (
      <>
        Place order on LMAX, selling{" "}
        <BigIntDisplay
          value={quantityToSell}
          decimals={sellAsset.decimals}
          numberFormat={{ currency: sellAsset.symbol }}
        />{" "}
        for at least{" "}
        <BigIntDisplay
          value={limitAmountToGet}
          decimals={buyAsset.decimals}
          numberFormat={{ currency: buyAsset.symbol }}
        />
        .
      </>
    );
  },
  Label() {
    return <>Place LMAX order</>;
  },
  decodeExternalPositionArgs: Integrations.Alice.placeOrderDecode,
};

export const aliceRefundOrder: ExternalPositionHandler<Integrations.Alice.RefundOrderArgs> = {
  Description({ args: { orderId } }) {
    return (
      <>
        Cancel LMAX order <BigIntDisplay value={orderId} />
      </>
    );
  },
  Label() {
    return <>Cancel LMAX order</>;
  },
  decodeExternalPositionArgs: Integrations.Alice.refundOrderDecode,
};

export const aliceSweep: ExternalPositionHandler<Integrations.Alice.SweepArgs> = {
  Description() {
    return <>Sweep LMAX orders.</>;
  },
  Label() {
    return <>Sweep LMAX orders</>;
  },
  decodeExternalPositionArgs: Integrations.Alice.sweepDecode,
};

export const createAliceExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize LMAX external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = alicePlaceOrder.decodeExternalPositionArgs(actionArgs);

    return <alicePlaceOrder.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize LMAX external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = alicePlaceOrder.decodeExternalPositionArgs(actionArgs);

    return <alicePlaceOrder.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize LMAX external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = alicePlaceOrder.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(alicePlaceOrder.Label, alicePlaceOrder.Description);

    return <Summary args={args} />;
  },
};
