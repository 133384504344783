import dayjs from "dayjs";

export function getSharesWrapper<TSharesWrapper extends { id: string }>({
  gatedRedemptionQueueSharesWrappers,
  allowedDepositRecipientsPolicy,
}: {
  gatedRedemptionQueueSharesWrappers: TSharesWrapper[];
  allowedDepositRecipientsPolicy: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  };
}): TSharesWrapper | null {
  if (!allowedDepositRecipientsPolicy.enabled) {
    return null;
  }

  const sharesWrapperInPolicy = gatedRedemptionQueueSharesWrappers.filter((gatedRedemptionQueueSharesWrapper) =>
    allowedDepositRecipientsPolicy.addressLists.find((addressList) =>
      addressList.items.includes(gatedRedemptionQueueSharesWrapper.id),
    ),
  );

  return sharesWrapperInPolicy[0] ?? null;
}

export interface RedemptionTimeline {
  managerExecuteWindowStart: number;
  managerExecuteWindowEnd: number;
  nextManagerExecuteWindowStart: number;
  isManagerExecuteWindowOpened: boolean;
}

export function getRedemptionTimeline({
  duration,
  frequency,
  firstWindowStart,
}: {
  duration: number;
  frequency: number;
  firstWindowStart: number;
}): RedemptionTimeline {
  const now = dayjs().unix();

  const cyclesCompleted = Math.floor((now - firstWindowStart) / frequency);

  const managerExecuteWindowStart = firstWindowStart + cyclesCompleted * frequency;

  const managerExecuteWindowEnd = managerExecuteWindowStart + duration;

  const nextManagerExecuteWindowStart = managerExecuteWindowStart + frequency;

  const isInRange = now >= managerExecuteWindowStart && now <= managerExecuteWindowEnd;

  return {
    managerExecuteWindowStart,
    managerExecuteWindowEnd,
    nextManagerExecuteWindowStart,
    isManagerExecuteWindowOpened: isInRange,
  };
}
