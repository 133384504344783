import { toAddress } from "@enzymefinance/environment";
import { AddressCreatable, useFormContext } from "@enzymefinance/hook-form";
import { Utils } from "@enzymefinance/sdk";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { Alert, Badge, ErrorMessage, RadioGroup } from "@enzymefinance/ui";
import { address, refine } from "@enzymefinance/validation";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { isAddress } from "viem";
import { z } from "zod";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedRedeemersForSpecificAssetsPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigContext, VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { PolicyAddressList } from "./PolicyAddressList";

export const allowedRedeemersForSpecificAssetsPolicySchema = z
  .object({
    listId: z.string().optional(),
    items: z.array(address({ caseInsensitive: true, allowZeroAddress: false })),
    isDisallowAll: z.boolean().optional(),
  })
  .transform(
    refine(
      (value) => {
        return value.isDisallowAll || value.items.length > 0;
      },
      {
        message: 'Please specify some addresses or choose "Disallow All"',
        path: ["items"],
      },
    ),
  )
  .optional();

interface AllowedRedeemersForSpecificAssetsPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY]?: Partial<AllowedRedeemersForSpecificAssetsPolicySettings>;
}

function allowedRedeemersForSpecificAssetsPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY>) {
  const { getFieldState, setValue, trigger, watch } =
    useFormContext<AllowedRedeemersForSpecificAssetsPolicyFormFieldsValues>();

  const { error } = getFieldState(VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY);
  const [value] = watch([VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY]) as [
    AllowedRedeemersForSpecificAssetsPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY],
  ];
  const isDisallowAll = !!value?.isDisallowAll;

  useUpdateEffect(() => {
    trigger();
  }, [value]);

  const handleSelectListOption = useCallback(
    ({ value: newValue }: ListSelectOption) => {
      const newFieldValue: AllowedRedeemersForSpecificAssetsPolicySettings = {
        items: newValue === VaultConfigPolicyListOption.EMPTY_LIST ? [] : value?.items ?? [],
        listId: undefined,
        isDisallowAll: newValue === VaultConfigPolicyListOption.EMPTY_LIST,
      };

      setValue(VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY, newFieldValue);
      trigger();
    },
    [setValue, trigger, value?.items],
  );

  const listSelectOptions: ListSelectOption[] = [
    {
      value: VaultConfigPolicyListOption.CUSTOM_LIST,
      label: allowedRedeemersForSpecificAssetsPolicy.label,
      description: isDisallowAll ? (
        <></>
      ) : (
        <AddressCreatable
          isDisabled={isDisallowAll}
          isExpandable={true}
          isMulti={true}
          label="Set addresses"
          labelHidden={true}
          name={`${VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY}.items`}
        />
      ),
    },
    {
      value: VaultConfigPolicyListOption.EMPTY_LIST,
      label: "Disallow all depositor addresses",
      description: <p className="text-sm">This setting can be changed later</p>,
    },
  ];

  const selectedListOption = useMemo(
    () =>
      isDisallowAll
        ? listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.EMPTY_LIST)
        : listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [isDisallowAll, listSelectOptions],
  );

  if (listOptions?.action === "remove") {
    return (
      <AddressCreatable
        label="Select items to remove"
        labelHidden={true}
        isExpandable={true}
        isMulti={true}
        name={`${VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY}.items`}
        isValidNewOption={(inputValue) =>
          isAddress(inputValue) && listOptions.options.items.includes(toAddress(inputValue))
        }
        options={listOptions.options.items.map((option) => ({ label: option, value: option }))}
      />
    );
  }

  if (listOptions?.action === "add") {
    return (
      <>
        <AddressCreatable
          isDisabled={isDisallowAll}
          isExpandable={true}
          isMulti={true}
          label="Set addresses"
          labelHidden={true}
          name={`${VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY}.items`}
        />
        {listOptions.options.items.some((option) =>
          value?.items?.some((recipientAddress) => Utils.Address.safeSameAddress(option, recipientAddress)),
        ) ? (
          <Alert appearance="info">
            One of the selected addresses is already contained in the list, and will therefore not get added.
          </Alert>
        ) : null}
      </>
    );
  }

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label={allowedRedeemersForSpecificAssetsPolicy.label}
        labelHidden={true}
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function allowedRedeemersForSpecificAssetsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY>) {
  return <PolicyAddressList addresses={settings.items} />;
}

function allowedRedeemersForSpecificAssetsPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY>) {
  const addresses = settings.addressLists.flatMap((list) => list.items);

  return <PolicyAddressList addresses={addresses} columns={3} iconSize={12} />;
}

export const allowedRedeemersForSpecificAssetsPolicy: VaultConfig<VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY> =
  {
    address: (contracts) => contracts.AllowedRedeemersForSpecificAssetsPolicy,
    disableable: true,
    display: allowedRedeemersForSpecificAssetsPolicyDisplay,
    displaySubgraph: allowedRedeemersForSpecificAssetsPolicyDisplaySubgraph,
    editable: false,
    encode: (settings, encodeArgs) => {
      const unique = [...new Set(settings.items.map((item) => toAddress(item)))];

      if (encodeArgs?.context === VaultConfigContext.MIGRATION) {
        const previousSettingsSet = new Set(encodeArgs.previousSettings?.items.map((item) => item.toLowerCase()) ?? []);

        if (
          encodeArgs.previousSettings?.listId &&
          previousSettingsSet.size === unique.length &&
          unique.every((item) => previousSettingsSet.has(item))
        ) {
          // New and old values contain the same items => reuse the list ID
          return Policies.AllowedRedeemersForSpecificAssets.encodeSettings({
            existingListIds: [BigInt(encodeArgs.previousSettings.listId)],
            newListsArgs: [],
          });
        }
      }

      const args = Policies.AllowedRedeemersForSpecificAssets.encodeSettings({
        existingListIds: [],
        newListsArgs: [
          {
            initialItems: unique,
            updateType: Constants.AddressListUpdateType.AddOnly,
          },
        ],
      });

      return args;
    },
    fetch: async () => undefined,
    formFields: allowedRedeemersForSpecificAssetsPolicyFormFields,
    label: "Addresses allowed to redeem shares for specific assets",
    managerDescription: (
      <div className="space-y-4">
        <p>This policy restricts wallets permitted to receive redeem shares for specific assets.</p>
        <Badge appearance="warning">Semi-permanent Setting</Badge>
      </div>
    ),
    type: VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY,
    validationSchema: allowedRedeemersForSpecificAssetsPolicySchema,
  };

interface ListSelectOption {
  description: ReactNode;
  label: string;
  value: string;
}
