import { Switch as SwitchBase } from "@headlessui/react";
import classNames from "classnames";
import type { FormEventHandler } from "react";

import type { FieldGroupProps } from "./FieldGroup.js";
import { FieldGroup } from "./FieldGroup.js";

export interface SwitchProps extends Omit<FieldGroupProps, "cornerHint"> {
  checked?: boolean;
  className?: string;
  name?: string;
  disabled?: boolean;
  onChange: ((checked: boolean) => void) | (FormEventHandler<HTMLButtonElement> & ((checked: boolean) => void));
}

export function Switch({
  checked = false,
  className,
  description,
  error,
  id,
  label,
  labelHidden,
  name,
  disabled,
  onChange,
}: SwitchProps) {
  const classes = classNames(
    "shrink-0 relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary transition",
    className,
  );
  const backgroundClasses = classNames(
    "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors duration-200",
    { "bg-base-400": !checked, "bg-primary": checked },
  );
  const toggleClasses = classNames(
    "translate-x-0 pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200/50 dark:border-gray-850 rounded-full bg-white dark:bg-gray-200 shadow ring-0 transition-transform duration-200",
    { "translate-x-0": !checked, "translate-x-5": checked },
  );

  return (
    <SwitchBase.Group>
      <FieldGroup
        description={description}
        error={error}
        id={id}
        appearance="switch"
        label={<SwitchBase.Label>{label}</SwitchBase.Label>}
        labelHidden={labelHidden}
      >
        <SwitchBase disabled={disabled} checked={checked} className={classes} id={id} onChange={onChange} name={name}>
          <span className={backgroundClasses} />
          <span className={toggleClasses} />
        </SwitchBase>
      </FieldGroup>
    </SwitchBase.Group>
  );
}
