import type { Address } from "@enzymefinance/environment";
import { NumberDisplay } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";

interface PolicyTokenAmountsListProps {
  assetBalances: { asset: { id: Address | string }; amount: string }[];
}

export function PolicyTokenAmountsList({ assetBalances }: PolicyTokenAmountsListProps) {
  const { environment } = useGlobals();

  return (
    <div className="space-y-2">
      {assetBalances.length > 0 ? (
        assetBalances.map(({ amount, asset: { id } }) => {
          const asset = environment.getAsset(id);

          return (
            <div className="flex items-center space-x-4" key={asset.id}>
              <NumberDisplay value={Number(amount)} numberFormat={{ currency: asset.symbol }} />
            </div>
          );
        })
      ) : (
        <p>No assets configured for this policy</p>
      )}
    </div>
  );
}
