import { AssetType } from "@enzymefinance/environment";
import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import { DepositTokensDisplay, RedeemOneTokenDisplay, TokenDisplay, findCurveLpToken } from "./curveUtils";
import type { IntegrationHandler } from "./types";

export const curveTakeOrder: IntegrationHandler<Integrations.Curve.TakeOrderArgs> = {
  Description({ args: { outgoingAsset, outgoingAssetAmount, incomingAsset, minIncomingAssetAmount } }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAsset = environment.getAsset(outgoingAsset);
    const buyAsset = environment.getAsset(incomingAsset);

    const title = "Curve Swap";
    const exchangeIcon = getIntegrationIconUrl("curve.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: outgoingAssetAmount,
        }),
      [assetPrices, outgoingAssetAmount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: minIncomingAssetAmount,
        }),
      [assetPrices, buyAsset, minIncomingAssetAmount],
    );

    return (
      <TokenSwapTransactionSummary
        currency={currency}
        buyAsset={buyAsset}
        sellAsset={sellAsset}
        minIncomingAssetAmount={minIncomingAssetAmount}
        outgoingAssetAmount={outgoingAssetAmount}
        buyValueInCurrency={buyValueInCurrency}
        sellValueInCurrency={sellValueInCurrency}
        title={title}
        exchangeIcon={exchangeIcon}
      />
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.takeOrderDecode(encodedCallArgs),
};

export const curveClaimRewards: IntegrationHandler<Integrations.Curve.ClaimRewardsArgs> = {
  Description({ args: { stakingToken } }) {
    const { environment } = useNetwork();
    const token = environment.getAsset(stakingToken);

    return (
      <>
        Claim rewards for <strong>{token.name}</strong>
      </>
    );
  },
  Label() {
    return <>Claim rewards for Curve pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.claimRewardsDecode(encodedCallArgs),
};

export const curveDeposit: IntegrationHandler<Integrations.Curve.LendArgs> = {
  Description({ args: { minIncomingLpTokenAmount, pool, orderedOutgoingAssetAmounts, useUnderlyings } }) {
    const { environment } = useNetwork();
    const poolToken = findCurveLpToken(pool, environment);

    return (
      <>
        Deposit
        <DepositTokensDisplay
          poolOrStakingToken={poolToken}
          assetAmounts={orderedOutgoingAssetAmounts}
          useUnderlyings={useUnderlyings}
        />{" "}
        in <strong>{poolToken.name}</strong> and receive a minimum of{" "}
        <TokenDisplay token={poolToken} amount={minIncomingLpTokenAmount} />
      </>
    );
  },
  Label() {
    return <>Deposit into Curve Pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.lendDecode(encodedCallArgs),
};

export const curveDepositAndStake: IntegrationHandler<Integrations.Curve.LendAndStakeArgs> = {
  Description({
    args: { minIncomingStakingTokenAmount, pool, orderedOutgoingAssetAmounts, incomingStakingToken, useUnderlyings },
  }) {
    const { environment } = useNetwork();
    const stakingToken = environment.getAsset(incomingStakingToken);
    const poolToken = findCurveLpToken(pool, environment);

    return (
      <>
        Deposit
        <DepositTokensDisplay
          poolOrStakingToken={poolToken}
          assetAmounts={orderedOutgoingAssetAmounts}
          useUnderlyings={useUnderlyings}
        />
        in <strong>{poolToken.name}</strong> and stake to receive a minimum of
        <TokenDisplay token={stakingToken} amount={minIncomingStakingTokenAmount} />
      </>
    );
  },
  Label() {
    return <>Deposit in Curve and stake</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.lendAndStakeDecode(encodedCallArgs),
};

export const curveRedeem: IntegrationHandler<Integrations.Curve.RedeemArgs> = {
  Description({ args }) {
    const { environment } = useNetwork();
    const poolToken = findCurveLpToken(args.pool, environment);

    return (
      <>
        Withdraw
        <TokenDisplay token={poolToken} amount={args.outgoingLpTokenAmount} />
        from <strong>{poolToken.name}</strong> to receive a minimum of
        {args.redeemType === Integrations.Curve.RedeemType.Standard ? (
          <DepositTokensDisplay
            poolOrStakingToken={poolToken}
            assetAmounts={args.orderedMinIncomingAssetAmounts}
            useUnderlyings={args.useUnderlyings}
          />
        ) : (
          <RedeemOneTokenDisplay
            poolOrStakingToken={poolToken}
            incomingAssetPoolIndex={args.incomingAssetPoolIndex}
            minIncomingAssetAmount={args.minIncomingAssetAmount}
            useUnderlyings={args.useUnderlyings}
          />
        )}
      </>
    );
  },
  Label() {
    return <>Withdraw from Curve Pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.redeemDecode(encodedCallArgs),
};

export const curveUnstakeAndRedeem: IntegrationHandler<Integrations.Curve.UnstakeAndRedeemArgs> = {
  Description({ args }) {
    const { environment } = useNetwork();
    const stakingToken = environment.getAssetAs(args.outgoingStakingToken, AssetType.CURVE_POOL_GAUGE);

    return (
      <>
        Unstake
        <TokenDisplay token={stakingToken} amount={args.outgoingStakingTokenAmount} />
        and withdraw to recive a minimum of
        {args.redeemType === Integrations.Curve.RedeemType.Standard ? (
          <DepositTokensDisplay
            poolOrStakingToken={stakingToken}
            assetAmounts={args.orderedMinIncomingAssetAmounts}
            useUnderlyings={args.useUnderlyings}
          />
        ) : (
          <RedeemOneTokenDisplay
            poolOrStakingToken={stakingToken}
            incomingAssetPoolIndex={args.incomingAssetPoolIndex}
            minIncomingAssetAmount={args.minIncomingAssetAmount}
            useUnderlyings={args.useUnderlyings}
          />
        )}
      </>
    );
  },
  Label() {
    return <>Unstake and withdraw from Curve Pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.unstakeAndRedeemDecode(encodedCallArgs),
};

export const curveStake: IntegrationHandler<Integrations.Curve.StakeArgs> = {
  Description({ args: { pool, incomingStakingToken, amount } }) {
    const { environment } = useNetwork();
    const stakingToken = environment.getAsset(incomingStakingToken);
    const poolToken = findCurveLpToken(pool, environment);

    return (
      <>
        Stake
        <TokenDisplay token={poolToken} amount={amount} />
        in <strong>{stakingToken.name}</strong> to receive
        <TokenDisplay token={stakingToken} />
      </>
    );
  },
  Label() {
    return <>Stake in Curve Pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.stakeDecode(encodedCallArgs),
};

export const curveUnstake: IntegrationHandler<Integrations.Curve.UnstakeArgs> = {
  Description({ args: { outgoingStakingToken, amount, pool } }) {
    const { environment } = useNetwork();
    const stakingToken = environment.getAsset(outgoingStakingToken);
    const poolToken = findCurveLpToken(pool, environment);

    return (
      <>
        Unstake
        <TokenDisplay token={stakingToken} amount={amount} />
        from <strong>{stakingToken.name}</strong> to receive
        <TokenDisplay token={poolToken} />
      </>
    );
  },
  Label() {
    return <>Unstake from Curve Pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.Curve.unstakeDecode(encodedCallArgs),
};
