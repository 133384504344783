import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address as AddressType, maxUint256 } from "viem";

export const sharesWrapperTransferApproval: FunctionConfig<{
  users: AddressType[];
  recipients: AddressType[];
  amounts: bigint[];
}> = {
  signature:
    "function setTransferApprovals(address[] calldata users, address[] calldata recipients, uint256[] calldata amounts)",

  Description({ inputs }) {
    const sender = inputs.users[0];
    const recipient = inputs.recipients[0];
    const amount = inputs.amounts[0];
    const isUnlimited = amount === maxUint256;

    return (
      <>
        <div className="flex">
          <span className="mr-1">From</span>
          <span>
            <Address address={sender} />
          </span>
        </div>
        <div className="flex">
          <span className="mr-1">To</span>
          <span>
            <Address address={recipient} />
          </span>
        </div>
        <div className="flex">
          <span className="mr-1">for</span>
          <span>
            {isUnlimited ? (
              "unlimited amount"
            ) : (
              <BigIntDisplay numberFormat={{ currency: "shares", maximumFractionDigits: 20 }} value={amount} />
            )}{" "}
          </span>
        </div>
      </>
    );
  },

  Label() {
    return <>Approving transfer on Shares Wrapper</>;
  },
};
