import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";

export function useAsObservable<T>(value: T) {
  const [[behavior, subject]] = useState(() => {
    const behaviorSubject = new BehaviorSubject(value);
    const observable = behaviorSubject.asObservable();

    return [behaviorSubject, observable] as const;
  });

  useEffect(() => {
    if (behavior.value === value) {
      return;
    }

    behavior.next(value);
  }, [behavior, value]);

  return subject;
}
