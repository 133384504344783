import type { CurvePoolLpAsset, CurveStakingType } from "@enzymefinance/environment";
import { Network } from "@enzymefinance/environment";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { address, asset, bigint } from "@enzymefinance/validation";
import type { StartSdkFunction } from "components/transactions/TransactionModal";
import type { ReactNode } from "react";
import { createUseValidatedClientQuery } from "utils/hooks/useValidatedQuery";
import { getFunctionSelector } from "viem";
import { z } from "zod";

export interface CurvePoolFormProps {
  poolToken: CurvePoolLpAsset;
  stakingType: CurveStakingType;
  submitLabel?: ReactNode;
  start: StartSdkFunction;
}

export const useAllowSingleAssetRedemption = createUseValidatedClientQuery(
  ["curve-check-allowance-of-single-asset-redemption"],
  {
    paramsSchema: address({ allowZeroAddress: false }),
    responseSchema: z.boolean(),
    queryFn: (curvePool, client) => {
      return Integrations.Curve.isSingleAssetRedemptionAllowed(client, { pool: curvePool });
    },
  },
);

export const useExpectedGaugeTokens = createUseValidatedClientQuery(["curve-expected-gauge-tokens"], {
  paramsSchema: z.object({
    curvePool: address({ allowZeroAddress: false }),
    tokenAmounts: z.array(bigint()).refine((array) => array.some((value) => value > 0n)),
  }),
  responseSchema: bigint(),
  queryFn: async (params, client) =>
    Integrations.Curve.getExpectedGaugeTokens(client, {
      curvePool: params.curvePool,
      tokenAmounts: params.tokenAmounts,
      isDeposit: true,
    }),
});

export const useExpectedWithdrawalTokens = createUseValidatedClientQuery(["curve-expected-withdrawal-tokens"], {
  paramsSchema: z.object({
    gaugeTokenAmount: bigint(),
    curvePool: address({ allowZeroAddress: false }),
    singleTokenIndex: z.number(),
    equalProportion: z.boolean(),
    underlyingAssets: z.array(asset()),
    lpToken: asset(),
  }),
  responseSchema: z.object({ expectedTokens: z.array(bigint()), singleTokenAllowed: z.boolean() }),
  queryFn: async (params, client) =>
    Integrations.Curve.getExpectedWithdrawalTokens(client, {
      curvePool: params.curvePool,
      singleTokenIndex: BigInt(params.singleTokenIndex),
      underlyingAssetsDecimals: params.underlyingAssets.map((asset) => asset.decimals),
      lpToken: params.lpToken.id,
      lpTokenAmount: params.gaugeTokenAmount,
      equalProportion: params.equalProportion,
    }),
});

export const useCurveMintingApproval = createUseValidatedClientQuery(["curve-minting-approval"], {
  paramsSchema: z.object({
    vaultId: address({ allowZeroAddress: false }),
    adapter: address({ allowZeroAddress: false }),
    curveMinterAddress: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: (params, client) => {
    // Curve minting approval is only necessary on mainnet
    if (client.chain?.id !== Network.ETHEREUM) {
      return true;
    }

    return Integrations.Curve.isAllowedToMintFor(client, {
      vault: params.vaultId,
      adapter: params.adapter,
      curveMinter: params.curveMinterAddress,
    });
  },
});

export const mintSelector = getFunctionSelector("mint(address)");
