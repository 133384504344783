import type { ReactNode } from "react";
import { createContext, useContext } from "react";

const TitleContext = createContext<{
  title: string;
}>({
  title: "Enzyme",
});

export function useTitle() {
  return useContext(TitleContext);
}

interface TitleProviderProps {
  children?: ReactNode;
  title?: string;
}

export function TitleProvider({ children, title = "Enzyme" }: TitleProviderProps) {
  return <TitleContext.Provider value={{ title }}>{children}</TitleContext.Provider>;
}
