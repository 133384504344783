import { AddressCreatable, BigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Alert } from "@enzymefinance/ui";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { parseUnits } from "viem";
import { VaultConfigFieldName } from "../VaultConfig";
import type { EntranceBurnFeeSettings, EntranceDirectFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfigType } from "../VaultConfigTypes";

const rateThreshold = parseUnits("0.1", 4);

interface EntranceFeeFormValues {
  [VaultConfigFieldName.ENTRANCE_BURN_FEE]?: EntranceBurnFeeSettings;
  [VaultConfigFieldName.ENTRANCE_DIRECT_FEE]?: EntranceDirectFeeSettings;
}

export type EntranceFeeOption = VaultConfigType.ENTRANCE_BURN_FEE | VaultConfigType.ENTRANCE_DIRECT_FEE;

interface EntranceFeeFormFieldsProps {
  selected: EntranceFeeOption;
}

export function EntranceFeeFormFields({ selected }: EntranceFeeFormFieldsProps) {
  const { getFieldState, setValue, watch } = useFormContext<EntranceFeeFormValues>();
  const fieldName = VaultConfigFieldName[selected] as keyof EntranceFeeFormValues;
  const { error } = getFieldState(fieldName);
  const [value] = watch([fieldName]) as [
    | EntranceFeeFormValues[VaultConfigFieldName.ENTRANCE_BURN_FEE]
    | EntranceFeeFormValues[VaultConfigFieldName.ENTRANCE_DIRECT_FEE],
  ];
  const isAboveThreshold = useMemo(() => (value?.rate ? value.rate > rateThreshold : false), [value?.rate]);

  // This effect is required to have the underlying components update when DefaultFieldValues is triggered in the FeesStep
  useEffect(() => {
    if (value?.rate !== undefined) {
      setValue(`${fieldName}.rate`, value.rate as never);
    }

    if (
      fieldName === VaultConfigFieldName.ENTRANCE_DIRECT_FEE &&
      (value as EntranceDirectFeeSettings | undefined)?.recipient !== undefined &&
      (value as EntranceDirectFeeSettings | undefined)?.recipient !== null
    ) {
      setValue(`${fieldName}.recipient`, (value as EntranceDirectFeeSettings).recipient as never);
    }
  }, [value]);

  return (
    <div className="space-y-3">
      <BigIntInput
        label="Entrance Fee Rate"
        name={`${fieldName}.rate`}
        numberFormat={{ style: "percent" }}
        decimals={2}
      />

      <AnimatePresence>
        {isAboveThreshold && error === undefined ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <Alert appearance="warning">
              This fee setting is considerably higher than average and may deter potential depositors. Consider setting
              a lower fee.
            </Alert>
          </motion.div>
        ) : null}
      </AnimatePresence>

      {fieldName === VaultConfigFieldName.ENTRANCE_DIRECT_FEE ? (
        <AddressCreatable
          name={`${fieldName}.recipient`}
          label="Recipient Address (optional)"
          cornerHint="By default, the fee recipient is the vault owner"
        />
      ) : null}
    </div>
  );
}
