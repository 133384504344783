import type { Adapter } from "@enzymefinance/utils";
import { toAdapter } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";

export function useAdapterOptions(): Adapter[] {
  const { environment } = useGlobals();

  return useMemo(
    () =>
      Object.values(environment.adapters)
        .map(toAdapter)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [environment.adapters],
  );
}
