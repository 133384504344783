import { Address as AddressComponent } from "@enzymefinance/ethereum-ui";
import { ProtocolSelectPerAddress } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { Badge } from "@enzymefinance/ui";
import { adapter, address } from "@enzymefinance/validation";
import classNames from "classnames";
import { useAdapterOptions } from "utils/hooks/useAdapterOptions";
import { z } from "zod";
import { VaultConfigFieldName } from "../VaultConfig";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { PolicyAdapterList } from "./PolicyAdapterList";

export const allowedAdaptersPerManagerPolicySchema = z
  .array(
    z.object({
      address: z.object({ value: address({ caseInsensitive: true }) }),
      protocols: z.array(adapter()).nonempty(),
    }),
  )
  .superRefine((values, context) => {
    const addresses = values.map((value) => value.address.value.toLowerCase());

    addresses.forEach((element, _, array) => {
      const firstIndex = array.indexOf(element);
      const lastIndex = array.lastIndexOf(element);

      if (firstIndex !== lastIndex) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Manager addresses should be unique.",
          path: [`[${lastIndex}].address]`],
        });
      }
    });
  })
  .optional();

function allowedAdaptersPerManagerPolicyFormFields() {
  const defaultOptions = useAdapterOptions();

  return (
    <ProtocolSelectPerAddress
      label={allowedAdaptersPerManagerPolicy.label}
      name={VaultConfigFieldName.ALLOWED_ADAPTERS_PER_MANAGER_POLICY}
      options={defaultOptions}
      isMulti={true}
      isExpandable={true}
    />
  );
}

function allowedAdaptersPerManagerPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY>) {
  return (
    <div className="space-y-4 divide-y divide-gray-300 dark:divide-gray-700">
      {settings.map((setting, index) => {
        return (
          <div key={setting.address.value} className={classNames("space-y-2", { "pt-4": index !== 0 })}>
            <AddressComponent appearance="card" address={setting.address.value} icon={true} iconSize={8} />
            <PolicyAdapterList addresses={setting.protocols.map((protocol) => protocol.id)} />
          </div>
        );
      })}
    </div>
  );
}

function allowedAdaptersPerManagerPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY>) {
  return (
    <div className="space-y-4 divide-y divide-gray-300 dark:divide-gray-700">
      {settings.userAddressLists.map((userAddressList, index) => {
        return (
          <div key={userAddressList.id} className={classNames("space-y-2", { "pt-4": index !== 0 })}>
            <div className="flex flex-col items-start justify-between space-y-2">
              <span className="base-content">Manager</span>
              <AddressComponent appearance="card" address={userAddressList.userAddress} icon={true} iconSize={8} />
            </div>
            <PolicyAdapterList addresses={userAddressList.addressLists.flatMap((addressList) => addressList.items)} />
          </div>
        );
      })}
    </div>
  );
}

export const allowedAdaptersPerManagerPolicy: VaultConfig<VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY> = {
  address: (contracts) => contracts.AllowedAdaptersPerManagerPolicy,
  disableable: true,
  display: allowedAdaptersPerManagerPolicyDisplay,
  displaySubgraph: allowedAdaptersPerManagerPolicyDisplaySubgraph,
  editable: true,
  encode: (settings) => {
    const args = Policies.AllowedAdaptersPerManager.encodeSettings({
      users: settings.map((setting) => setting.address.value),
      listsData: settings.map((setting) => {
        return {
          existingListIds: [],
          newListsArgs: [
            {
              initialItems: setting.protocols.map((protocol) => protocol.id),
              updateType: Constants.AddressListUpdateType.AddAndRemove,
            },
          ],
        };
      }),
    });

    return args;
  },
  fetch: async () => undefined,
  formFields: allowedAdaptersPerManagerPolicyFormFields,
  formInitialValues: [],
  label: "Limit Allowed Adapters For Manager To A Specified List",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Restricts the protocols with which a manager can interact to trade, lend, borrow, supply liquidity, et cetera.
      </p>
      <p>Enable this policy and select which protocol adapters each manager will be allowed to interact with.</p>
      <Badge appearance="success">Editable Setting</Badge>
    </div>
  ),
  type: VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY,
  validationSchema: allowedAdaptersPerManagerPolicySchema,
};
