import type { FunctionConfig } from "utils/functionsTypes";

export const setFreelyTransferableShares: FunctionConfig = {
  signature: "function setFreelyTransferableShares() returns ()",

  Description() {
    return (
      <>
        Enable Freely Transferable Shares for Vault. This setting is permanent and persists throughout upgrades and
        reconfigurations.
      </>
    );
  },

  Label() {
    return <>Enable Freely Transferable Shares</>;
  },
};
