import { ApolloError } from "@apollo/client";
import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Form, NumberInput, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { Utils } from "@enzymefinance/sdk";
import { Hex as HexUtils } from "@enzymefinance/sdk/Utils";
import { Alert, Button, Modal, NumberDisplay, Skeleton } from "@enzymefinance/ui";
import {
  bigIntInput,
  maxSlippage as maxSlippageSchema,
  numberInput,
  percentage,
  refine,
  safeParse,
} from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency } from "components/settings/Settings";
import type { MachineState, TxData } from "components/transactions/TransactionModalMachine";
import { log } from "logger/logger";
import type { NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables } from "queries/backend";
import { NativeTokenDepositTransactionDocument, useTokenPriceInNativeCurrencyQuery } from "queries/backend";
import type { VaultDetailsFragment } from "queries/core";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { client as backendClient } from "utils/backend";
import { defaultTransactionSlippage } from "utils/constants";
import { findTokenValue } from "utils/currency";
import { useNativeTokenBalance } from "utils/hooks/useNativeTokenBalance";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { formatUnits } from "viem";
import { z } from "zod";
import { VaultFlexibleLoanAgreementModal } from "./VaultFlexibleLoanAgreementModal";
import { VaultDepositFormCommonPart } from "./components/VaultDepositFormCommonPart";

const schema = z
  .object({
    depositAmount: bigIntInput({ decimals: 18 }),
    investmentDenominationAmount: z.bigint(),
    maxDeposit: z.bigint().optional(),
    maxSlippage: maxSlippageSchema(),
    minDeposit: z.bigint().optional(),
    nativeTokenBalance: z.bigint(),
    route: z.any().optional(),
    termsAccepted: z
      .boolean()
      .transform(refine((value) => value, { message: "You must accept the Terms & Conditions." })),
    isFlexibleLoanVault: z.boolean(),
    flexLoansAccepted: z.boolean(),
  })
  .transform(
    refine((value) => value.depositAmount <= value.nativeTokenBalance, {
      message: "The deposit amount exceeds your balance.",
      path: ["depositAmount"],
    }),
  )
  .transform(
    refine(
      (value) =>
        typeof value.minDeposit === "bigint"
          ? value.minDeposit === 0n || value.investmentDenominationAmount >= value.minDeposit
          : true,
      {
        message: "The deposit amount is below the minimum deposit amount of the vault.",
        path: ["depositAmount"],
      },
    ),
  )
  .transform(
    refine(
      (value) =>
        typeof value.maxDeposit === "bigint" && typeof value.investmentDenominationAmount === "bigint"
          ? value.maxDeposit === 0n || value.investmentDenominationAmount <= value.maxDeposit
          : true,
      {
        message: "The deposit amount exceeds the maximum deposit amount of the vault",
        path: ["depositAmount"],
      },
    ),
  )
  .transform(
    refine((value) => (value.isFlexibleLoanVault ? value.flexLoansAccepted : true), {
      message: "You must accept the Flexible loans terms.",
      path: ["flexLoansAccepted"],
    }),
  );

interface VaultDepositNativeTokenFormProps {
  close: () => void;
  minDeposit?: bigint;
  maxDeposit?: bigint;
  vault: VaultDetailsFragment;
  startTxData: (
    txData: (originAddress?: Address) => Promise<TxData>,
    signerAddress: Address,
    vaultProxy?: Address,
  ) => void;
  state: MachineState;
  changeAssetNode: ReactNode;
  isFlexibleLoanVault: boolean;
  isFlexibleLoanModalOpen: boolean;
  openFlexibleLoanModal: () => void;
  vaultHasUnknownFeeOrPolicy: boolean;
  displayAllowedExternalPositionTypesWarning: boolean;
  vaultEntranceFeeRate: number | undefined;
}

export function VaultDepositNativeTokenForm({
  close,
  minDeposit,
  maxDeposit,
  startTxData,
  changeAssetNode,
  vault,
  isFlexibleLoanVault,
  isFlexibleLoanModalOpen,
  openFlexibleLoanModal,
  vaultHasUnknownFeeOrPolicy,
  vaultEntranceFeeRate,
  displayAllowedExternalPositionTypesWarning,
}: VaultDepositNativeTokenFormProps) {
  const [signerAddress] = useSigner();
  const { client, deployment } = useNetwork();
  const { environment } = useGlobals();
  const denominationAsset = vault.comptroller.denomination;
  const comptrollerProxy = toAddress(vault.comptroller.id);

  const currency = useCurrency();
  const { assetPrices } = useAssetPrices();

  const form = useForm({
    defaultValues: {
      depositAmount: "",
      maxDeposit,
      maxSlippage: `${2 * defaultTransactionSlippage * 100}`,
      minDeposit,
      nativeTokenBalance: 0n,
      termsAccepted: false,
      flexLoansAccepted: false,
      isFlexibleLoanVault,
    },
    mode: "onChange",
    onSubmit: (values, helpers) => {
      if (!signerAddress) {
        return helpers.setError("form", {
          message: "Wallet not connected. You must connect your wallet to perform this action.",
        });
      }

      if (tokenSwapPrice.error instanceof ApolloError) {
        log.error({ category: "VaultDepositNativeTokenForm", message: tokenSwapPrice.error.message });

        return helpers.setError("form", {
          message: "Error fetching token price. Try again later.",
        });
      }

      const txDataFn = async (originAddress?: Address) => {
        const transactionResult = await backendClient.query<
          NativeTokenDepositTransactionQuery,
          NativeTokenDepositTransactionQueryVariables
        >({
          fetchPolicy: "network-only",
          pollInterval: 0,
          query: NativeTokenDepositTransactionDocument,
          variables: {
            comptrollerProxy,
            expectedDenominationAmount: `${values.investmentDenominationAmount}`,
            incoming: toAddress(denominationAsset.id),
            maxSlippage: values.maxSlippage,
            network: deployment,
            originAddress: originAddress ?? signerAddress,
            quantity: `${values.depositAmount}`,
            userAddress: signerAddress,
          },
        });
        const txData = transactionResult.data.nativeTokenDepositTransaction.transaction;

        if (!txData?.value) {
          throw new Error(transactionResult.data.nativeTokenDepositTransaction.reason ?? undefined);
        }

        return { data: HexUtils.asHex(`${txData.data}`), to: txData.to, value: BigInt(txData.value) };
      };

      startTxData(txDataFn, signerAddress, toAddress(vault.id));
      close();

      return;
    },
    schema,
  });

  const {
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = form;
  const [depositAmount, maxSlippage, termsAccepted] = watch(["depositAmount", "maxSlippage", "termsAccepted"]);

  const parsedDepositAmount = safeParse(bigIntInput({ decimals: 18 }), depositAmount) ?? 0n;

  const parsedMaxSlippage = safeParse(numberInput(percentage(z.number())), maxSlippage);

  const nativeTokenBalanceQuery = useNativeTokenBalance(client, signerAddress, {
    cacheTime: 0,
    onSuccess: (data) => setValue("nativeTokenBalance", data),
  });

  const denominationBalanceQuery = useTokenBalance(client, {
    account: signerAddress,
    token: toAddress(denominationAsset.id),
  });

  const tokenSwapPrice = useTokenPriceInNativeCurrencyQuery({
    client: backendClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.tokenPriceInNativeCurrency.amount) {
        setValue("investmentDenominationAmount", BigInt(data.tokenPriceInNativeCurrency.amount));
        trigger("depositAmount");
      }
    },
    skip: parsedDepositAmount === undefined || parsedDepositAmount === 0n,
    variables: {
      network: deployment,
      quantity: `${parsedDepositAmount ?? ""}`,
      token: toAddress(denominationAsset.id),
    },
  });

  const incomingDenominationAssetAmount = useMemo(
    () =>
      tokenSwapPrice.data?.tokenPriceInNativeCurrency.amount
        ? BigInt(tokenSwapPrice.data.tokenPriceInNativeCurrency.amount)
        : undefined,
    [tokenSwapPrice.data?.tokenPriceInNativeCurrency.amount],
  );

  // 1% threshold
  const isSlippageBelowThreshold = parsedMaxSlippage !== undefined && parsedMaxSlippage < defaultTransactionSlippage;
  const hasEnoughDenominationBalance =
    typeof incomingDenominationAssetAmount === "bigint" &&
    typeof denominationBalanceQuery.data === "bigint" &&
    denominationBalanceQuery.data >= incomingDenominationAssetAmount;

  const investmentCurrencyValue = useMemo(
    () =>
      findTokenValue({
        assetPrices,
        token: environment.namedTokens.nativeTokenWrapper,
        value: parsedDepositAmount,
      }),
    [assetPrices, environment.namedTokens.nativeTokenWrapper, parsedDepositAmount],
  );

  const balance = formatUnits(nativeTokenBalanceQuery.data ?? 0n, environment.namedTokens.nativeTokenWrapper.decimals);

  const isNextButtonDisabled = !termsAccepted || errors.depositAmount !== undefined || parsedDepositAmount === 0n;

  return (
    <Form form={form}>
      {isFlexibleLoanModalOpen ? (
        <VaultFlexibleLoanAgreementModal
          submitButton={<SubmitButton disabled={tokenSwapPrice.loading}>Deposit</SubmitButton>}
        />
      ) : (
        <>
          <Modal.Body>
            <div className="space-y-6">
              <p className="text-sm">Choose amount and token to deposit:</p>
              <div className="flex">
                <div className="min-w-0 flex-1">
                  <NumberInput
                    appearance="extended"
                    wrapperclassname="rounded-l-lg rounded-r-none"
                    balance={balance}
                    description={
                      <span className="text-base-content">
                        <NumberDisplay
                          appearance="simple"
                          numberFormat={{ currency }}
                          value={investmentCurrencyValue ?? 0}
                        />
                      </span>
                    }
                    disabled={nativeTokenBalanceQuery.isLoading}
                    name="depositAmount"
                    label="Amount"
                    cornerHint={
                      <BigIntDisplay
                        className="text-base-content"
                        error={nativeTokenBalanceQuery.isError ? "Balance: N/A" : undefined}
                        fallback="Balance: -"
                        loading={nativeTokenBalanceQuery.isLoading && nativeTokenBalanceQuery.isFetching}
                        numberFormat={{ currency: environment.network.currency.nativeToken.symbol }}
                        value={nativeTokenBalanceQuery.data ?? undefined}
                      />
                    }
                  />
                </div>
                <div className="pt-5">{changeAssetNode}</div>
              </div>
              {tokenSwapPrice.loading ? (
                <Skeleton className="h-4 w-full" />
              ) : incomingDenominationAssetAmount ? (
                <div className="text-sm">
                  You will receive shares equivalent to a minimum of{" "}
                  <BigIntDisplay
                    decimals={denominationAsset.decimals}
                    numberFormat={{
                      currency: denominationAsset.symbol,
                      maximumFractionDigits: 4,
                    }}
                    value={Utils.Slippage.multiplyBySlippage({
                      slippage: parsedMaxSlippage,
                      value: incomingDenominationAssetAmount,
                    })}
                  />
                </div>
              ) : null}
              {hasEnoughDenominationBalance ? (
                <Alert appearance="info">
                  You own enough {denominationAsset.symbol} to deposit with {denominationAsset.symbol}
                </Alert>
              ) : null}
              <VaultDepositFormCommonPart
                vault={vault}
                minDeposit={minDeposit}
                maxDeposit={maxDeposit}
                vaultEntranceFeeRate={vaultEntranceFeeRate}
                displaySlippageBelowThresholdWarning={isSlippageBelowThreshold && !form.formState.errors.maxSlippage}
                displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
                vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
              />
            </div>
          </Modal.Body>
          <Modal.Actions>
            {isFlexibleLoanVault ? (
              <Button appearance="primary" onClick={openFlexibleLoanModal} disabled={isNextButtonDisabled}>
                Next
              </Button>
            ) : (
              <SubmitButton disabled={tokenSwapPrice.loading}>Deposit</SubmitButton>
            )}
          </Modal.Actions>
        </>
      )}
    </Form>
  );
}
