import type { ApolloError } from "@apollo/client";
import { network } from "@enzymefinance/validation";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrencySlug } from "components/settings/Settings";
import type { ColorScheme, VaultListItem, WhiteLabelInfoFragment } from "queries/backend";
import { useVaultsQuery } from "queries/backend";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { client } from "utils/backend";

interface WhiteLabelContextValues {
  colorScheme?: ColorScheme | null;
  contactInfo?: string | null;
  coverUrl?: string;
  description?: string | null;
  error?: ApolloError;
  id?: string;
  isOwner?: boolean;
  loading?: boolean;
  logoUrl?: string;
  name?: string;
  rootPath: string;
  subdomain?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  vaults: VaultListItem[];
}

const WhiteLabelContext = createContext<WhiteLabelContextValues>({
  rootPath: "/",
  vaults: [],
});

export function useWhiteLabel() {
  return useContext(WhiteLabelContext);
}

interface WhiteLabelProviderProps {
  children: ReactNode;
  data?: WhiteLabelInfoFragment & { isOwner: boolean };
  error?: ApolloError;
  loading: boolean;
  rootPath?: string;
}

export function WhiteLabelProvider({
  children,
  data,
  error: errorBase,
  loading: loadingBase,
  rootPath = "/",
}: WhiteLabelProviderProps) {
  // White label vaults
  const vaultAddresses = data?.vaults.map((vault) => vault.address) ?? [];
  const currencySlug = useCurrencySlug();
  const { deployment } = useNetwork();
  const vaultsQuery = useVaultsQuery({
    client,
    skip: !vaultAddresses.length,
    variables:
      vaultAddresses.length > 0
        ? { currency: currencySlug, first: vaultAddresses.length, network: deployment, vaultAddresses }
        : undefined,
  });

  const error = useMemo(() => errorBase || vaultsQuery.error, [errorBase, vaultsQuery.error]);
  const loading = useMemo(() => loadingBase || vaultsQuery.loading, [loadingBase, vaultsQuery.loading]);

  const values = useMemo<WhiteLabelContextValues>(() => {
    const coverUrl = data?.settings?.cover ? `/uploads${data.settings.cover}` : undefined;
    const logoUrl = data?.settings?.logo ? `/uploads${data.settings.logo}` : undefined;

    return {
      colorScheme: data?.settings?.colorScheme,
      contactInfo: data?.profile?.contactInfo,
      coverUrl,
      description: data?.profile?.description,
      error,
      id: data?.id,
      isOwner: data?.isOwner,
      loading,
      logoUrl,
      name: data?.name,
      primaryColor: data?.settings?.primaryColor,
      rootPath,
      secondaryColor: data?.settings?.secondaryColor,
      subdomain: data?.settings?.subdomain,
      vaults: vaultsQuery.data?.vaultList.nodes ?? [],
    };
  }, [
    data?.id,
    data?.isOwner,
    data?.name,
    data?.profile?.contactInfo,
    data?.profile?.description,
    data?.settings?.colorScheme,
    data?.settings?.cover,
    data?.settings?.logo,
    data?.settings?.primaryColor,
    data?.settings?.secondaryColor,
    data?.settings?.subdomain,
    error,
    loading,
    rootPath,
    vaultsQuery.data?.vaultList.nodes,
  ]);

  // Reload vaults on network switch
  useUpdateEffect(() => {
    vaultsQuery.refetch();
  }, [network]);

  return <WhiteLabelContext.Provider value={values}>{children}</WhiteLabelContext.Provider>;
}
