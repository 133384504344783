import { Form, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { PhotoIcon, TrashIcon } from "@enzymefinance/icons/solid";
import { useToasts } from "@enzymefinance/toast";
import { Button, ErrorMessage, Icon, Modal, useBoolean } from "@enzymefinance/ui";
import { url, file, safeParse } from "@enzymefinance/validation";
import classNames from "classnames";
import { log } from "logger/logger";
import {
  WhiteLabelByIdDocument,
  useDeleteWhiteLabelCoverMutation,
  useUpdateWhiteLabelCoverMutation,
} from "queries/backend";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { client } from "utils/backend";
import { z } from "zod";

import { useWhiteLabel } from "../WhiteLabelProvider";
import type { WhiteLabelNavigationWrapperProps } from "./WhiteLabelNavigationWrapper";
import { defaultAppearance } from "./WhiteLabelNavigationWrapper";

const threshold = 124;
const formSchema = z.object({
  file: file({
    dimensions: {
      x: 6000,
      y: 4000,
    },
    // Maximum file size: 4 MB
    max: 4000 * 1000,
  }),
  preview: url(),
});

interface WhiteLabelCoverFormProps {
  appearance?: WhiteLabelNavigationWrapperProps["appearance"];
  children?: ReactNode;
  isScrollTop: boolean;
}

export function WhiteLabelCoverForm({
  appearance = defaultAppearance,
  children,
  isScrollTop,
}: WhiteLabelCoverFormProps) {
  const { isOwner, id, coverUrl } = useWhiteLabel();

  // Background opacity
  const [opacity, setOpacity] = useState(1);
  const scrollHandler = useCallback(() => {
    if (appearance === "home") {
      return;
    }

    const { scrollTop } = document.documentElement;

    // Handle opacity
    if (scrollTop < threshold) {
      setOpacity(1 - scrollTop / threshold);
    }

    if (scrollTop >= threshold && opacity !== 0) {
      setOpacity(0);
    }
  }, [appearance, opacity]);
  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, [appearance, opacity, scrollHandler]);

  const [updateWhiteLabelCover] = useUpdateWhiteLabelCoverMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: WhiteLabelByIdDocument, variables: { id } }],
  });
  const { toast } = useToasts();

  const form = useForm({
    defaultValues: { file: undefined, preview: undefined },
    async onSubmit(values, helpers) {
      if (!id) {
        log.error({ category: "WhiteLabelCoverForm", message: "No white label id" });
        toast.error();

        return;
      }

      try {
        const { data } = await updateWhiteLabelCover({ variables: { input: { file: values.file, id } } });

        if (data?.updateWhiteLabelCover.errors?.length) {
          data.updateWhiteLabelCover.errors.forEach((error) => toast.error(error));

          return;
        }

        toast("Successfully updated!", { description: "Your background cover has been updated." });
        helpers.reset();
      } catch (error) {
        log.error({ category: "WhiteLabelCoverForm", message: error.message });
        toast.error();
      }
    },
    resolverMode: "async",
    schema: formSchema,
  });
  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    disabled: !isOwner,
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop(acceptedFiles) {
      if (acceptedFiles[0]) {
        form.setValue("file", acceptedFiles[0], { shouldValidate: true });

        const objectUrl = URL.createObjectURL(acceptedFiles[0]);
        form.setValue("preview", objectUrl, { shouldValidate: true });
      }
    },
    onDropRejected() {
      toast.error({ title: "File type not supported" });
    },
  });
  const [isDeleteOpen, openDelete, dismissDelete] = useBoolean(false);
  const [deleteWhiteLabelCover, deleteWhiteLabelCoverResult] = useDeleteWhiteLabelCoverMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: WhiteLabelByIdDocument, variables: { id } }],
  });
  const handleDelete = useCallback(async () => {
    if (!id) {
      log.error({ category: "WhiteLabelCoverForm", message: "No white label id" });
      toast.error();
      dismissDelete();

      return;
    }

    try {
      const { data } = await deleteWhiteLabelCover({ variables: { input: { id } } });

      if (data?.deleteWhiteLabelCover.errors?.length) {
        data.deleteWhiteLabelCover.errors.forEach((error) => toast.error(error));
        dismissDelete();

        return;
      }

      toast("Successfully removed!", { description: "Your background cover has been removed." });
    } catch (error) {
      log.error({ category: "WhiteLabelCoverForm", message: error.message });
      toast.error();
    }

    dismissDelete();
  }, [deleteWhiteLabelCover, dismissDelete, id, toast]);

  const preview = form.watch("preview");

  const parsedPreview = useMemo(() => safeParse(formSchema.shape.preview, preview), [preview]);

  const classes = classNames(
    "hidden pointer-events-none lg:relative col-span-16 lg:col-span-18 max-w-full items-end justify-end space-x-3 transition",
    {
      "lg:flex": isOwner,
      "opacity-0": !(isOwner && isScrollTop),
    },
  );
  const backgroundClasses = classNames("absolute inset-0 transition transition", {
    "bg-base-100 opacity-50": isDragActive,
    "group-hover:opacity-50": preview,
  });
  const contentClasses = classNames("flex items-center space-x-3 text-base-content transition", {
    "opacity-0": !isDragActive,
    "opacity-100": isDragActive,
    "group-hover:opacity-100": preview,
  });

  return (
    <>
      <div className={backgroundClasses}>
        <div
          {...getRootProps({
            className: "w-full h-full bg-cover bg-center relative flex items-center justify-center",
            ...(parsedPreview ?? coverUrl
              ? { style: { backgroundImage: `url(${parsedPreview ?? coverUrl})`, opacity } }
              : undefined),
          })}
        >
          {isOwner ? (
            <div className={contentClasses}>
              <Icon icon={PhotoIcon} />
              <p className="text-sm font-semibold">Upload Background</p>
            </div>
          ) : null}
        </div>
      </div>
      {children}

      {isOwner ? (
        <Form className={classes} form={form}>
          <div className="pointer-events-auto flex space-x-3">
            {preview ? (
              <>
                {typeof form.formState.errors.file?.message === "string" ? (
                  <ErrorMessage>{form.formState.errors.file.message}</ErrorMessage>
                ) : null}
                <div className="flex space-x-3">
                  <Button appearance="secondary" disabled={form.formState.isSubmitting} onClick={() => form.reset()}>
                    Cancel
                  </Button>
                  <SubmitButton>Save</SubmitButton>
                </div>
              </>
            ) : coverUrl ? (
              <>
                <Button leadingIcon={PhotoIcon} appearance="secondary" onClick={open}>
                  Change Background
                </Button>
                <Button icon={TrashIcon} appearance="destructive" onClick={openDelete}>
                  Remove Background
                </Button>
              </>
            ) : (
              <Button className="group" leadingIcon={PhotoIcon} appearance="quaternary" onClick={open}>
                <span className="text-base-content sr-only text-sm font-medium transition group-hover:text-gray-500 dark:group-hover:text-gray-300 lg:not-sr-only">
                  Upload Background
                </span>
              </Button>
            )}
          </div>

          {/* Hidden file input */}
          <input {...getInputProps()} />
        </Form>
      ) : null}
      <Modal title="Delete Background Cover" isOpen={isDeleteOpen} dismiss={dismissDelete}>
        <Modal.Body>
          <p className="text-sm">Are you sure you want to remove the background cover of your page?</p>
        </Modal.Body>
        <Modal.Actions>
          <Button
            disabled={deleteWhiteLabelCoverResult.loading}
            appearance="destructive"
            loading={deleteWhiteLabelCoverResult.loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button disabled={deleteWhiteLabelCoverResult.loading} appearance="secondary" onClick={dismissDelete}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
