import type { Size } from "@enzymefinance/utils";
import { sizeAttributes, sizeClasses } from "@enzymefinance/utils";
import classNames from "classnames";

import { Skeleton } from "../layout/Skeleton.js";
import type { ImageProps } from "./Image.js";
import { Image } from "./Image.js";

export interface JazziconProps extends Omit<ImageProps, "alt" | "height" | "src" | "width"> {
  id?: string;
  size?: Size;
  className?: string;
}

export function Jazzicon({ className, size = 10, id, ...props }: JazziconProps) {
  const prefix = window.location.hostname === "localhost" ? "https://app.enzyme.kitchen" : "";
  const classes = classNames("inline-flex rounded-full", className, sizeClasses[size]);

  if (!id) {
    return <Skeleton className={classes} shape="circle" />;
  }

  return <Image alt={id} className={classes} src={`${prefix}/jazzicon/${id}`} {...sizeAttributes.full} {...props} />;
}
