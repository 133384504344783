import { toAddress } from "@enzymefinance/environment";
import { Skeleton } from "@enzymefinance/ui";
import { useSharesWrapperVaultDetailsQuery } from "queries/core";
import { client } from "utils/backend.js";
import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperCancelRequestRedeem: FunctionConfig = {
  signature: "function cancelRequestRedeem()",

  Description({ address }) {
    const query = useSharesWrapperVaultDetailsQuery({
      client,
      variables: { id: toAddress(address) },
    });

    const details = query.data?.gatedRedemptionQueueSharesWrapper?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Delete Redemption Request from <b>{details?.name ?? "this vault"}</b>.
      </>
    );
  },
  Label() {
    return <>Delete Redemption Request</>;
  },
};
