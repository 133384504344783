import type { ExternalPositionType, Optional } from "@enzymefinance/utils";
import { isExternalPositionTypeEnum } from "@enzymefinance/utils";
import { useExternalPositionTypesQuery } from "queries/core";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

interface ExternalPositionSubgraph {
  __typename: "ExternalPositionType";
  id: string;
  label: ExternalPositionType;
}

const ExternalPositionsContext =
  createContext<
    Optional<{
      externalPositionTypes: ExternalPositionSubgraph[];
    }>
  >(undefined);

export function useExternalPositions() {
  const context = useContext(ExternalPositionsContext);

  if (!context) {
    throw new Error("Missing external positions context");
  }

  return context;
}

interface ExternalPositionsProviderProps {
  children?: ReactNode;
}

export function ExternalPositionsProvider({ children }: ExternalPositionsProviderProps) {
  const externalPositionTypesQuery = useExternalPositionTypesQuery();

  const externalPositionTypes = useMemo(() => {
    const types = externalPositionTypesQuery.data?.externalPositionTypes;

    if (types === undefined) {
      return [];
    }

    return types.filter((type): type is ExternalPositionSubgraph => isExternalPositionTypeEnum(type.label));
  }, [externalPositionTypesQuery.data?.externalPositionTypes]);

  return (
    <ExternalPositionsContext.Provider
      value={{
        externalPositionTypes,
      }}
    >
      {children}
    </ExternalPositionsContext.Provider>
  );
}
