import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Skeleton } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

interface ExchangeERC20AndBuySharesInputs {
  depositWrapper: Address;
  comptrollerProxy: Address;
  inputAsset: Address;
  maxInputAssetAmount: bigint;
  exchange: Address;
  exchangeApproveTarget: Address;
  exchangeData: Hex;
  exchangeMinReceived: bigint;
  minSharesQuantity: bigint;
}

export const exchangeERC20AndBuyShares: FunctionConfig<ExchangeERC20AndBuySharesInputs> = {
  signature:
    "function exchangeErc20AndBuyShares(address comptrollerProxy,uint256 minSharesQuantity,address inputAsset,uint256 maxInputAssetAmount,address exchange,address exchangeApproveTarget,bytes exchangeData,uint256 exchangeMinReceived)",

  Description({ inputs }) {
    const { environment } = useGlobals();
    const query = useVaultDetailsFromComptrollerQuery({
      variables: { id: inputs.comptrollerProxy.toLowerCase() },
    });
    const vault = query.data?.comptroller?.vault;
    const amount = inputs.maxInputAssetAmount;
    const asset = environment.getAsset(inputs.inputAsset);

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Deposit <BigIntDisplay numberFormat={{ currency: asset.symbol }} decimals={asset.decimals} value={amount} /> to
        buy shares for {vault?.name ?? "this vault"}.
      </>
    );
  },
  Label() {
    return <>Buy Shares</>;
  },
};
