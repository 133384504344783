import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { invariant } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { Fragment } from "react";
import type { IntegrationHandler } from "./types";

const balancerDepositLabel = "Deposit into Balancer Pool";

export const balancerDeposit: IntegrationHandler<Integrations.BalancerV2.LendArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    return (
      <div>
        <span>Deposit</span>{" "}
        {args.usedTokenAmounts.map((usedTokenAmount, index) => {
          if (usedTokenAmount === 0n) {
            return null;
          }

          const spendAssetAddress = args.usedTokens[index];

          invariant(spendAssetAddress !== undefined, "spendAssetAddress is undefined");

          const spendAsset = environment.getAsset(spendAssetAddress);

          const isLastItem = index === args.usedTokenAmounts.length - 1;

          return (
            <Fragment key={spendAsset.id}>
              <span key={spendAsset.id}>
                <BigIntDisplay
                  numberFormat={{ currency: spendAsset.symbol }}
                  decimals={spendAsset.decimals}
                  value={usedTokenAmount}
                />
                {isLastItem ? null : ","}
              </span>{" "}
            </Fragment>
          );
        })}
        <span>
          and get minimum <BigIntDisplay numberFormat={{ currency: "BPT" }} value={args.bptAmount} />
        </span>
      </div>
    );
  },
  Label() {
    return <>{balancerDepositLabel}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.lendDecode(encodedCallArgs),
};

const balancerDepositAndStakeLabel = "Deposit and stake into Balancer Pool";

export const balancerDepositAndStake: IntegrationHandler<Integrations.BalancerV2.LendAndStakeArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    return (
      <div>
        <span>Deposit and stake</span>{" "}
        {args.usedTokenAmounts.map((usedTokenAmount, index) => {
          if (usedTokenAmount === 0n) {
            return null;
          }

          const spendAssetAddress = args.usedTokens[index];

          invariant(spendAssetAddress !== undefined, "spendAssetAddress is undefined");

          const spendAsset = environment.getAsset(spendAssetAddress);

          const isLastItem = index === args.usedTokenAmounts.length - 1;

          return (
            <Fragment key={spendAsset.id}>
              <span key={spendAsset.id}>
                <BigIntDisplay
                  numberFormat={{ currency: spendAsset.symbol }}
                  decimals={spendAsset.decimals}
                  value={usedTokenAmount}
                />
                {isLastItem ? null : ","}
              </span>{" "}
            </Fragment>
          );
        })}
        <span>
          and get minimum <BigIntDisplay numberFormat={{ currency: "BPT" }} value={args.bptAmount} />
        </span>
      </div>
    );
  },
  Label() {
    return <>{balancerDepositAndStakeLabel}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.lendAndStakeDecode(encodedCallArgs),
};

export const balancerStake: IntegrationHandler<Integrations.BalancerV2.StakeArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const gauge = environment.getAsset(args.stakingToken);

    if (gauge.type !== AssetType.BALANCER_POOL_GAUGE) {
      throw new Error(`Staking token is not gauge: ${args.stakingToken}`);
    }

    const poolToken = environment.getAsset(gauge.pool);

    return (
      <div>
        <span>
          Stake{" "}
          <BigIntDisplay
            numberFormat={{ currency: poolToken.symbol }}
            decimals={poolToken.decimals}
            value={args.bptAmount}
          />{" "}
          into {gauge.name}
        </span>
      </div>
    );
  },
  Label() {
    return <>Stake into Balancer Pool Gauge</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.stakeDecode(encodedCallArgs),
};

export const balancerUnstake: IntegrationHandler<Integrations.BalancerV2.UnstakeArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const gauge = environment.getAsset(args.stakingToken);

    if (gauge.type !== AssetType.BALANCER_POOL_GAUGE) {
      throw new Error(`Staking token is not gauge: ${args.stakingToken}`);
    }

    const poolToken = environment.getAsset(gauge.pool);

    return (
      <div>
        <span>
          Unstake{" "}
          <BigIntDisplay
            numberFormat={{ currency: poolToken.symbol }}
            decimals={poolToken.decimals}
            value={args.bptAmount}
          />{" "}
          from {gauge.name}
        </span>
      </div>
    );
  },
  Label() {
    return <>Unstake from Balancer Pool Gauge</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.unstakeDecode(encodedCallArgs),
};

const balancerWithdrawLabel = "Withdraw from Balancer Pool";

export const balancerRedeem: IntegrationHandler<Integrations.BalancerV2.RedeemArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    return (
      <div>
        <span>Withdraw at least</span>{" "}
        {args.usedTokenAmounts.map((usedTokenAmount, index) => {
          if (usedTokenAmount === 0n) {
            return null;
          }

          const isLastItem = index === args.usedTokenAmounts.length - 1;

          const redeemAssetAddress = args.usedTokens[index];

          invariant(redeemAssetAddress !== undefined, "redeemAssetAddress is undefined");

          const redeemAsset = environment.getAsset(redeemAssetAddress);

          return (
            <Fragment key={redeemAsset.id}>
              <span key={redeemAsset.id}>
                <BigIntDisplay
                  numberFormat={{ currency: redeemAsset.symbol }}
                  decimals={redeemAsset.decimals}
                  value={usedTokenAmount}
                />
                {isLastItem ? null : ","}
              </span>{" "}
            </Fragment>
          );
        })}
        <span>
          for <BigIntDisplay numberFormat={{ currency: "BPT" }} value={args.bptAmount} />
        </span>
      </div>
    );
  },
  Label() {
    return <>{balancerWithdrawLabel}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.redeemDecode(encodedCallArgs),
};

const balancerUnstakeAndWithdrawLabel = "Unstake and withdraw from Balancer Pool";

export const balancerUnstakeAndRedeem: IntegrationHandler<Integrations.BalancerV2.UnstakeAndRedeemArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const gauge = environment.getAsset(args.stakingToken);

    if (gauge.type !== AssetType.BALANCER_POOL_GAUGE) {
      throw new Error(`Staking token is not gauge: ${args.stakingToken}`);
    }

    const poolToken = environment.getAsset(gauge.pool);

    return (
      <div>
        <span>Unstake and withdraw from {poolToken.name} at least</span>{" "}
        {args.usedTokenAmounts.map((usedTokenAmount, index) => {
          if (usedTokenAmount === 0n) {
            return null;
          }

          const isLastItem = index === args.usedTokenAmounts.length - 1;

          const redeemAssetAddress = args.usedTokens[index];

          invariant(redeemAssetAddress !== undefined, "redeemAssetAddress is undefined");

          const redeemAsset = environment.getAsset(redeemAssetAddress);

          return (
            <Fragment key={redeemAsset.id}>
              <span key={redeemAsset.id}>
                <BigIntDisplay
                  numberFormat={{ currency: redeemAsset.symbol }}
                  decimals={redeemAsset.decimals}
                  value={usedTokenAmount}
                />
                {isLastItem ? null : ","}
              </span>{" "}
            </Fragment>
          );
        })}
        <span>
          for{" "}
          <BigIntDisplay
            numberFormat={{ currency: poolToken.symbol }}
            decimals={poolToken.decimals}
            value={args.bptAmount}
          />
        </span>
      </div>
    );
  },
  Label() {
    return <>{balancerUnstakeAndWithdrawLabel}</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.unstakeAndRedeemDecode(encodedCallArgs),
};

export const balancerClaimRewards: IntegrationHandler<Integrations.BalancerV2.ClaimRewardsArgs> = {
  Description({ args: { stakingToken } }) {
    const { environment } = useNetwork();
    const token = environment.getAsset(stakingToken);

    return (
      <>
        Claim rewards for <strong>{token.name}</strong>
      </>
    );
  },
  Label() {
    return <>Claim rewards for Balancer pool</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.BalancerV2.claimRewardsDecode(encodedCallArgs),
};
