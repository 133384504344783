import { toAddress } from "@enzymefinance/environment";
import { LifeCycle, Utils } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useReconfigurationDetails = createUseValidatedClientQuery(["reconfiguration-details"], {
  paramsSchema: z.object({
    fundDeployer: address({ allowZeroAddress: false }),
    release: address({ allowZeroAddress: false }),
    vaultProxy: address({ allowZeroAddress: false }),
  }),
  responseSchema: z
    .object({ executableTimestamp: bigint(), nextComptrollerProxy: address({ caseInsensitive: true }) })
    .nullable(),
  queryFn: async (params, client) => {
    const isCurrentRelease = Utils.Address.safeSameAddress(params.fundDeployer, params.release);

    if (!isCurrentRelease) {
      return null;
    }

    const result = await LifeCycle.getReconfigurationRequestDetails(client, {
      fundDeployer: params.fundDeployer,
      vaultProxy: params.vaultProxy,
    });

    return {
      executableTimestamp: result.executableTimestamp,
      nextComptrollerProxy: toAddress(result.nextComptrollerProxy),
    };
  },
});
