import { getFunctionSelector } from "viem";

export enum DelegateVotesActionType {
  DELEGATE = "DELEGATE",
  UNDELEGATE = "UNDELEGATE",
  INACTIVE = "INACTIVE",
}

export const delegateSelector = getFunctionSelector("delegate(address)");

export const govAvantgardeDefiENS = "gov.avantgardefi.eth";
