import { ProtocolLabel } from "@enzymefinance/ethereum-ui";
import { Badge, CopyToClipboard } from "@enzymefinance/ui";
import type { Adapter } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { getAdapterForDisplayPurposes } from "utils/adapterHelper";

interface PolicyAdapterListProps {
  addresses: string[];
  isDelegated?: boolean;
  listDescription?: string;
  listId?: string;
}

export function PolicyAdapterList({ addresses, isDelegated, listDescription, listId }: PolicyAdapterListProps) {
  const { getAdapterByAddress } = useGlobals();

  if (typeof listId === "string") {
    return (
      <div className="space-y-2">
        {listDescription ? <Badge>{listDescription}</Badge> : null}
        <p>List ID: {listId}</p>
      </div>
    );
  }

  if (addresses.length === 0) {
    return <p className="text-sm">No adapters allowed.</p>;
  }

  return (
    <div className="space-y-2">
      {isDelegated ? <Badge>Using council-maintained list{listDescription ? `: ${listDescription}` : ""}</Badge> : null}
      <div className="flex flex-col space-y-2">
        {addresses.map((address) => {
          const adapter = getAdapterForDisplayPurposes(address, getAdapterByAddress);

          return (
            <CopyToClipboard key={address} value={address}>
              <div className="flex space-x-1 items-center">
                <CopyToClipboard.Content>
                  <ProtocolLabel name={adapter.label} url={adapter.icon} />
                </CopyToClipboard.Content>
                <CopyToClipboard.Icon />
              </div>
            </CopyToClipboard>
          );
        })}
      </div>
    </div>
  );
}

export function InlinePolicyAdapterList({ adapters }: { adapters: Adapter[] }) {
  return (
    <div className="-m-3 flex-row">
      {adapters.map((adapter) => {
        return (
          <span className="inline-flex p-3" key={adapter.id}>
            <ProtocolLabel size={5} name={adapter.name} url={adapter.url} />
          </span>
        );
      })}
    </div>
  );
}
