import { PuffLoader } from "@enzymefinance/icons";
import { CheckIcon, ExclamationTriangleIcon } from "@enzymefinance/icons/solid";
import type { NotificationProps as NotificationPropsBase } from "@enzymefinance/ui";
import { Icon, Notification as NotificationBase, ProgressBar, useMotionPresets } from "@enzymefinance/ui";
import classNames from "classnames";
import { motion } from "framer-motion";
import type { StateValue } from "xstate";

export interface NotificationProps extends Omit<NotificationPropsBase, "icon" | "message"> {
  state: StateValue;
}

export function Notification({ children, className, state = "pending", ...props }: NotificationProps) {
  const classes = classNames("relative pb-2", className);
  const motionPresets = useMotionPresets();
  const pending = state === "pending";
  const indexing = state === "indexing";
  const reverted = state === "reverted";
  const replaced = state === "replaced";
  const finished = state === "finished";

  return (
    <NotificationBase
      className={classes}
      {...props}
      icon={
        finished ? (
          <motion.div {...motionPresets.scale} className="text-success">
            <Icon icon={CheckIcon} />
          </motion.div>
        ) : reverted || replaced ? (
          <motion.div {...motionPresets.scale} className="text-error">
            <Icon icon={ExclamationTriangleIcon} />
          </motion.div>
        ) : (
          <motion.div {...motionPresets.scale} className="text-base-neutral">
            <Icon icon={PuffLoader} />
          </motion.div>
        )
      }
      message={
        finished
          ? "Transaction Complete!"
          : reverted
            ? "Transaction Error"
            : replaced
              ? "Transaction Replaced"
              : "Processing Transaction ..."
      }
    >
      {children}
      <ProgressBar
        className="absolute inset-x-0 bottom-0"
        rounded="indicator"
        state={reverted ? "error" : undefined}
        progress={finished || reverted || replaced ? 1 : pending ? 1 / 3 : indexing ? 2 / 3 : undefined}
      />
    </NotificationBase>
  );
}
