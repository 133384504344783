import type { ComponentPropsWithoutRef } from "react";

type RocketLaunchProps = ComponentPropsWithoutRef<"svg">;

export function RocketLaunch(props: RocketLaunchProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width="209" height="292" viewBox="0 0 209 292" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.8255 216.484C82.9744 216.204 83.9325 215.848 84.7118 215.435C84.0577 215.985 83.2286 216.399 82.1918 216.619C82.0716 216.568 81.9495 216.523 81.8255 216.484Z"
        fill="#215B6F"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.2808 216.793C80.8298 216.704 81.345 216.601 81.8258 216.483C81.9498 216.523 82.0719 216.568 82.1921 216.619C81.6202 216.739 80.9846 216.801 80.2808 216.793Z"
        fill="#15313D"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.4953 211.653C86.0859 205.256 101.101 215.63 99.6605 222.903C114.91 228.511 114.061 251.909 92.8857 250.831C98.0327 266.208 64.9279 270.994 59.6844 258.976C52.1656 267.897 33.4381 260.437 36.0807 249.362C24.412 250.397 17.4683 235.51 22.5839 231.902C11.9193 230.343 8.4234 205.739 31.9256 209.281C36.3431 201.582 48.7921 199.487 55.6003 210.937C60.0438 206.136 75.1424 205.265 77.4953 211.653Z"
        fill="#F7FBFB"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.45 233.088C111.999 241.558 106.886 251.543 92.8854 250.831C98.0325 266.208 64.9277 270.993 59.6833 258.976C52.1654 267.896 33.4378 260.437 36.0804 249.362C24.4117 250.397 17.468 235.51 22.5836 231.902C13.7473 230.61 9.83247 213.497 22.1203 209.682C9.75025 215.518 18.2178 229.837 26.3857 229.164C21.6067 232.535 25.3969 250.329 39.4661 246.853C36.9975 257.198 54.1131 265.447 62.0052 255.085C66.9029 266.31 95.4834 261.223 90.6746 246.858C106.902 248.87 109.235 240.14 109.45 233.088Z"
        fill="#DBE4E2"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7205 204.995C83.7951 198.977 98.0122 205.548 97.1749 211.921C106.601 215.054 108.965 227.933 90.582 228.191C94.1907 237.025 83.7382 238.842 76.8432 236.107C74.9275 239.238 68.5573 240.305 65.482 237.454C60.6833 242.185 42.7745 239.906 46.2858 230.739C36.8655 231.195 35.1904 224.033 39.4142 220.622C30.6452 220.182 24.0261 203.854 45.4096 203.671C48.7987 196.761 58.9701 196.381 63.8 202.536C67.3751 198.133 74.3251 199.6 76.7205 204.995Z"
        fill="#F7FBFB"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.308 216.752C106.633 222.115 102.595 228.688 90.5818 228.191C94.1905 237.025 83.738 238.842 76.8429 236.106C74.9273 239.238 68.5571 240.304 65.4818 237.454C60.683 242.185 42.7742 239.906 46.2856 230.739C36.8652 231.195 35.1904 224.032 39.414 220.622C31.7916 220.24 25.7975 207.854 38.4056 204.501C29.0223 208.07 32.1047 216.009 42.2404 219.853C37.1798 224.533 46.1698 230.964 49.7043 227.172C45.6337 236.36 61.3509 238.382 64.8432 232.523C69.1978 237.286 76.9073 236.99 77.0525 231.524C85.7142 235.514 90.9596 232.135 88.8536 225.482C97.7895 225.736 105.186 224.86 103.308 216.752Z"
        fill="#DBE4E2"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9134 170.793C90.2874 180.089 76.3344 201.114 84.9638 207.694C89.5915 211.223 88.2983 216.948 75.9078 217.149C72.435 220.384 57.2792 223.012 58.6774 213.64C48.6088 210.524 53.8466 205.799 59.5679 203.829C61.2152 203.261 63.1824 202.973 64.9735 201.76C74.4737 195.324 85.3622 180.353 93.9134 170.793Z"
        fill="#F7FBFB"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9133 170.793L93.8912 170.85C88.7178 178.003 75.8242 193.38 65.7511 202.345C64.1807 203.743 62.6988 204.764 60.8088 205.356C53.13 207.763 57.2236 212.234 62.0327 211.515C59.4751 217.019 70.5856 220.545 76.6829 216.265C84.875 218.374 86.948 213.763 86.8669 209.645C91.0754 214.733 87.6119 221.203 77.514 218.199C74.0402 221.434 57.2792 223.012 58.6774 213.64C48.6087 210.524 53.4721 202.115 58.7649 203.304C60.7233 203.743 63.6904 202.338 65.7738 200.843C75.0877 194.163 85.6077 180.078 93.9133 170.793Z"
        fill="#DBE4E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0544 193.612C79.9712 189.886 81.0486 176.493 82.1272 173.174L79.1694 176.122C81.127 161.735 84.69 158.323 95.1782 150.328L113.49 161.508C109.531 174.366 108.349 179.143 95.6357 186.895L97.1527 183.002C94.5432 185.321 84.1309 191.71 80.0544 193.612Z"
        fill="#E3692A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4098 183.631C86.3508 181 87.1117 171.547 87.8736 169.203L85.7848 171.285C87.1671 161.128 89.6823 158.719 97.0859 153.076L110.013 160.968C107.219 170.045 106.384 173.418 97.4099 178.89L98.481 176.142C96.6387 177.778 89.288 182.289 86.4098 183.631Z"
        fill="#EC933A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0132 176.426C90.9693 174.501 91.5264 167.582 92.0838 165.867L90.5549 167.39C91.5665 159.956 93.408 158.194 98.8269 154.063L108.288 159.839C106.243 166.482 105.632 168.952 99.0632 172.956L99.847 170.945C98.4986 172.143 93.1187 175.444 91.0132 176.426Z"
        fill="#F3C04B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9612 169.786C93.9234 168.085 94.4145 161.972 94.9073 160.457L93.5573 161.803C94.4513 155.234 96.0782 153.677 100.865 150.029L109.225 155.132C107.417 161.002 106.878 163.182 101.074 166.721L101.768 164.945C100.575 166.002 95.8229 168.919 93.9612 169.786Z"
        fill="#FCDC91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.2716 186.689C89.6667 185.923 90.3623 183.764 91.1701 181.008C92.2988 180.334 93.4608 179.616 94.5306 178.928C94.0199 180.289 93.5464 181.421 93.1745 182.172C93.878 181.565 95.6221 180.17 97.5143 178.623L97.4105 178.89C100.022 177.297 101.945 175.882 103.426 174.453L102.721 176.783C106.138 173.945 108.149 171.03 109.731 167.965C109.059 168.228 108.305 168.381 107.499 168.434C107.754 167.833 107.996 167.204 108.234 166.536C109.456 166.437 110.61 166.096 111.634 165.471C109.947 171.872 104.789 177.588 99.514 181.348L100.837 177.653C98.8711 180.16 91.6136 184.663 89.2716 186.689Z"
        fill="#ED9551"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.1702 181.008C91.6574 179.343 92.1868 177.459 92.7143 175.536C93.8341 174.912 95.1692 174.115 96.3983 173.339C95.7986 175.36 95.139 177.307 94.5307 178.929C93.461 179.617 92.2989 180.334 91.1702 181.008ZM97.4106 178.89L97.5144 178.623C100.14 176.477 103.053 174.036 103.87 172.992L103.426 174.453C101.945 175.883 100.022 177.297 97.4106 178.89ZM104.753 168.277C105.197 167.697 105.572 167.097 105.908 166.453C106.7 166.567 107.48 166.598 108.234 166.537C107.996 167.204 107.754 167.833 107.499 168.434C106.632 168.491 105.704 168.433 104.753 168.277Z"
        fill="#F3B25F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.7146 175.536C93.6404 172.164 94.5638 168.665 95.2628 165.991C95.097 167.665 94.9885 169.448 95.0048 170.189C95.6083 169.909 96.5887 169.36 97.6085 168.749C97.2713 170.248 96.8513 171.814 96.3985 173.339C95.1694 174.114 93.8343 174.912 92.7146 175.536ZM100.851 167.122C100.712 167.063 100.573 167.002 100.436 166.94C100.808 166.68 101.108 166.455 101.297 166.287L100.954 167.166C100.92 167.152 100.885 167.137 100.851 167.122ZM101.361 167.329C102.169 166.809 102.837 166.322 103.397 165.839C104.238 166.127 105.079 166.335 105.908 166.453C105.572 167.096 105.197 167.696 104.753 168.277C103.635 168.091 102.484 167.767 101.361 167.329Z"
        fill="#F7D36E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9607 169.786C93.9405 168.867 94.0752 166.654 94.2808 164.578C95.018 161.756 95.5535 159.674 95.7158 159.054C97.8697 161.086 100.396 162.761 102.991 163.861C103.45 164.056 103.912 164.234 104.373 164.389C103.677 164.989 102.849 165.593 101.846 166.238C101.676 166.173 101.509 166.105 101.341 166.035L101.767 164.945C101.532 165.153 101.16 165.433 100.699 165.755C99.7298 165.317 98.7898 164.812 97.9019 164.249C97.7283 165.427 97.4848 166.692 97.1909 167.999C95.9257 168.757 94.7094 169.438 93.9607 169.786Z"
        fill="#FCE7A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.2558 155.563C85.1351 152.176 88.9665 149.119 94.1756 145.147C88.9665 149.119 85.1351 152.176 82.2558 155.563Z"
        fill="#1A4443"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0356 158.539C80.1601 158.347 80.2869 158.157 80.4164 157.968C80.2869 158.157 80.1601 158.347 80.0356 158.539ZM81.9531 155.925C82.0024 155.867 82.051 155.807 82.1003 155.749C82.051 155.807 82.0024 155.867 81.9531 155.925Z"
        fill="#9D8A71"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.8582 158.816C79.917 158.724 79.9763 158.631 80.0351 158.539C79.9763 158.631 79.917 158.724 79.8582 158.816ZM82.0998 155.748C82.1428 155.697 82.1864 155.645 82.2294 155.594C82.1864 155.645 82.1428 155.697 82.0998 155.748Z"
        fill="#BA997A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.3439 159.666C79.5107 159.377 79.6812 159.095 79.8574 158.816C79.6812 159.095 79.5107 159.377 79.3439 159.666Z"
        fill="#175752"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.5034 166.368C77.2795 163.808 78.2078 161.631 79.3444 159.666C78.2078 161.631 77.2795 163.808 76.5034 166.368Z"
        fill="#194948"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4152 157.968C80.8932 157.268 81.4051 156.589 81.9519 155.925C81.4051 156.589 80.8938 157.266 80.4152 157.968Z"
        fill="#6C5649"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.0119 168.114C76.1685 167.512 76.3326 166.931 76.5039 166.368C76.3326 166.931 76.1685 167.512 76.0119 168.114Z"
        fill="#1B4442"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.2364 148.237L96.2158 146.392L99.2364 148.237Z"
        fill="#1A4443"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0545 193.612C80.0401 192.997 80.0586 192.121 80.1024 191.07C80.1189 190.677 80.1397 190.258 80.1633 189.821C80.3367 186.598 80.6961 182.305 81.1321 178.788C81.2047 178.207 81.2786 177.648 81.355 177.117C81.6003 175.393 81.8636 173.983 82.1273 173.174L79.1695 176.123C79.3729 174.622 79.5953 173.24 79.841 171.96C80.054 170.848 80.2849 169.812 80.5369 168.842C80.6631 168.356 80.7953 167.888 80.9334 167.434C81.5589 165.371 82.3072 163.616 83.2234 162.032C83.3578 161.799 83.4953 161.572 83.6373 161.347C83.6847 161.273 83.7325 161.198 83.7799 161.124C83.8803 160.968 83.9824 160.815 84.0868 160.663C84.4726 160.098 84.8847 159.552 85.3255 159.016C85.3653 158.97 85.4044 158.921 85.4442 158.874C85.4789 158.833 85.514 158.791 85.5487 158.75C85.5563 158.741 85.564 158.733 85.5701 158.725C87.891 155.994 90.9794 153.53 95.1782 150.328L96.8232 151.333L99.258 152.819C98.7538 153.032 98.266 153.262 97.7951 153.508L97.0862 153.076C91.3187 157.472 88.5181 159.906 86.9081 165.567C86.5472 166.259 86.2103 166.94 85.8909 167.603C86.0769 167.496 86.2658 167.398 86.4726 167.308C86.2117 168.493 85.9861 169.807 85.7851 171.286L87.8022 169.275C83.7842 177.387 81.727 184.889 80.0927 193.593L80.0545 193.612Z"
        fill="#BE482C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.7848 171.286C85.9858 169.807 86.2113 168.493 86.4723 167.308C87.0763 167.045 87.8364 166.843 89.1629 166.642C88.686 167.528 88.2316 168.406 87.8019 169.275L85.7848 171.286ZM86.9077 165.567C88.5178 159.906 91.3183 157.472 97.0859 153.076L97.7948 153.508C92.4907 156.279 89.2315 161.115 86.9077 165.567Z"
        fill="#C4602E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.1738 182.173C93.5457 181.421 94.0192 180.289 94.5298 178.928C96.2778 177.806 97.7784 176.765 98.4809 176.142L97.5135 178.623C95.6213 180.171 93.8773 181.566 93.1738 182.173ZM102.72 176.784L103.426 174.453C105.339 172.609 106.518 170.74 107.498 168.434C108.304 168.382 109.058 168.229 109.73 167.965C108.148 171.03 106.137 173.945 102.72 176.784Z"
        fill="#F2B774"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.5304 178.929C95.1387 177.307 95.7983 175.36 96.398 173.339C97.9095 172.386 99.2605 171.466 99.8467 170.945L99.063 172.956C101.835 171.265 103.547 169.849 104.753 168.277C105.703 168.433 106.632 168.491 107.499 168.434C106.518 170.74 105.339 172.61 103.426 174.453L103.869 172.992C103.052 174.036 100.14 176.477 97.5141 178.623L98.4815 176.142C97.779 176.766 96.2784 177.806 94.5304 178.929Z"
        fill="#F7CB81"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.3974 173.339C96.8501 171.814 97.2702 170.248 97.6073 168.749C98.6234 168.14 99.6767 167.469 100.435 166.94C100.572 167.002 100.71 167.063 100.85 167.122C100.884 167.137 100.919 167.152 100.952 167.166L100.737 167.718C100.953 167.587 101.161 167.457 101.36 167.329C102.483 167.767 103.634 168.091 104.752 168.277C103.546 169.848 101.834 171.265 99.0623 172.956L99.8461 170.945C99.2599 171.466 97.9089 172.385 96.3974 173.339Z"
        fill="#FAE28D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.1894 167.999C97.4833 166.692 97.7268 165.427 97.9004 164.249C98.7883 164.811 99.7283 165.317 100.697 165.754C99.7567 166.411 98.45 167.244 97.1894 167.999ZM101.072 166.72L101.339 166.035C101.507 166.104 101.675 166.172 101.845 166.238C101.598 166.396 101.34 166.557 101.072 166.72Z"
        fill="#FEF0B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.926 146.989C115.317 151.168 118.593 157.466 116.246 161.054C113.899 164.644 106.814 164.164 100.424 159.985C94.0351 155.804 90.7588 149.506 93.1058 145.918C95.4534 142.329 102.536 142.808 108.926 146.989Z"
        fill="#EC9358"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.886 159.068C123.646 161.067 121.397 163.075 118.967 165.147C118.774 165.295 118.579 165.436 118.382 165.568C118.383 165.568 118.383 165.568 118.383 165.568C112.312 169.66 104.527 166.289 98.8933 162.867C98.8667 162.851 98.8402 162.835 98.8137 162.818C94.9847 160.477 91.3168 157.155 89.2615 152.895C87.7813 149.85 87.5447 147.123 88.3548 144.999C88.4374 144.699 88.5208 144.401 88.605 144.104C89.8673 139.645 91.2976 135.453 92.7524 131.188C93.2292 129.791 93.7086 128.385 94.1855 126.96C93.9802 127.631 94.5926 127.988 95.2753 128.385C95.9411 128.772 96.6737 129.198 96.7798 129.993L96.7807 129.993L104.79 134.973C104.79 134.973 104.79 134.973 104.79 134.973L133.212 152.645C130.644 154.82 128.27 156.939 125.886 159.068Z"
        fill="#222626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.404 139.205C129.752 129.654 117.283 121.309 106.55 120.574C95.8152 119.839 90.8844 126.987 95.5363 136.538C100.185 146.097 112.659 154.435 123.393 155.168C134.126 155.905 139.056 148.754 134.404 139.205Z"
        fill="#222626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.376 139.133C128.969 130.088 117.159 122.184 106.993 121.487C96.8246 120.791 92.1562 127.562 96.5625 136.608C100.964 145.662 112.78 153.558 122.945 154.254C133.111 154.951 137.783 148.18 133.376 139.133Z"
        fill="#0E0F0D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.011 102.348C183.96 94.6185 186.973 86.283 188.839 77.9073C189.358 75.5845 189.785 73.241 190.11 70.8787C194.142 32.9373 186.066 18.3653 146.411 41.573C144.811 42.5088 143.265 43.5425 141.728 44.5953C134.882 49.2993 128.106 55.3864 122.23 62.1685C108.771 77.7047 96.9827 102.443 94.7713 122.871C94.489 125.265 94.4203 127.217 94.6053 129.076C94.7788 130.795 95.1526 132.426 96.1625 134.503C101.027 144.359 113.612 152.528 124.226 153.272C127.447 153.483 129.894 153.092 132.562 151.82C134.559 150.869 136.913 149.431 138.504 148.461C154.796 138.536 171.061 119.866 180.011 102.348Z"
        fill="url(#paint0_linear_5350_43002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.41 41.573C140.784 44.8643 134.649 49.7345 130.084 54.0077C126.299 57.5492 122.727 61.3833 119.513 65.4571C107.213 81.0464 96.8327 103.831 94.7701 122.871C94.4879 125.265 94.4191 127.217 94.6041 129.076C95.4436 137.473 103.82 145.096 110.75 148.956C115.123 151.391 119.843 152.965 124.225 153.272C127.424 153.482 128.749 153.423 132.504 151.847C122.065 154.326 109.225 146.144 103.127 138.233C100.031 112.954 116.552 65.1231 148.645 47.4418C150.699 49.7658 164.477 82.6798 179.318 77.5786L146.879 42.0851C147.718 41.5366 148.565 41.0033 149.428 40.4981C155.692 36.8319 161.165 34.1121 165.93 32.2756C160.499 34.0451 154.042 37.1071 146.41 41.573Z"
        fill="#8C95E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.343 149.76L135.006 149.387L134.285 149.79L134.343 149.76Z"
        fill="#7C7D7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.03 27.2003L187.15 27.294C186.957 27.4079 186.765 27.522 186.573 27.6361C186.932 27.8685 187.121 28.373 187.202 28.5885C187.207 28.6002 187.211 28.6111 187.215 28.6211C187.311 28.8827 187.408 29.1463 187.493 29.37C191.709 40.7913 191.481 55.9341 190.114 67.9719C189.973 69.2118 189.802 70.5276 189.357 71.7221C189.02 72.6237 188.454 73.5247 187.786 74.3071C186.747 75.5217 185.482 76.3883 184.089 76.998C181.257 78.2344 177.888 78.4657 174.629 78.1214C165.762 77.1851 155.027 71.3076 148.811 63.9826C148.48 63.5916 148.16 63.1958 147.857 62.797C145.611 60.0195 143.508 56.4911 142.677 52.8117C141.815 48.9924 142.348 45.0367 145.313 42.338C145.348 42.3055 145.383 42.273 145.419 42.2406C145.532 42.126 145.65 42.0134 145.771 41.903C146.261 41.4422 146.907 40.9914 147.583 40.5734C158.211 33.9918 173.062 27.6514 185.884 27.0452C185.911 27.044 185.943 27.042 185.978 27.0399C186.249 27.0236 186.713 26.9957 187.03 27.2003Z"
        fill="#8C95E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.065 80.5577C160.808 86.2787 163.368 97.8444 157.776 106.394C152.183 114.943 140.565 117.224 131.821 111.504C123.072 105.781 120.506 94.2197 126.098 85.6718C131.691 77.1226 143.315 74.8358 152.065 80.5577Z"
        fill="#B6DFE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.654 81.1856C143.447 75.817 132.441 78.106 127.074 86.3095C121.712 94.5067 124.02 105.514 132.229 110.883C140.431 116.249 151.441 113.954 156.804 105.757C162.17 97.5535 159.858 86.5524 151.654 81.1856ZM135.244 106.274C129.576 102.567 127.982 94.9824 131.684 89.3256C135.384 83.6662 142.978 82.0827 148.645 85.7888C154.304 89.492 155.895 97.0841 152.195 102.742C148.494 108.399 140.904 109.977 135.244 106.274Z"
        fill="#F0F8FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.243 106.274C140.885 109.965 148.505 108.378 152.193 102.741C155.884 97.0981 154.285 89.4794 148.643 85.7887C142.994 82.0931 135.375 83.6808 131.683 89.3255C127.995 94.9618 129.593 102.578 135.243 106.274Z"
        fill="#01CABE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.645 85.7887C154.305 89.4919 155.895 97.084 152.195 102.741C151.776 103.384 151.305 103.973 150.79 104.512C153.013 99.1687 151.193 92.835 146.179 89.5555C141.16 86.2722 134.628 87.1431 130.629 91.3251C130.911 90.6328 131.264 89.9673 131.685 89.3254C135.384 83.666 142.978 82.0826 148.645 85.7887Z"
        fill="#1F556A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.114 85.0722C149.715 85.4639 150.516 85.2997 150.909 84.6989C151.307 84.0918 151.136 83.2912 150.535 82.8981C149.931 82.5018 149.127 82.6663 148.731 83.2726C148.338 83.8734 148.508 84.6753 149.114 85.0722Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.348 109.173C133.947 109.565 134.755 109.392 135.148 108.793C135.541 108.192 135.374 107.385 134.774 106.992C134.168 106.595 133.361 106.767 132.967 107.367C132.576 107.967 132.742 108.776 133.348 109.173Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.092 104.638C154.698 105.238 153.891 105.41 153.285 105.013C152.686 104.62 152.514 103.808 152.905 103.208C153.298 102.607 154.117 102.432 154.717 102.827C155.322 103.222 155.485 104.037 155.092 104.638Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.969 88.8577C130.576 89.4585 129.763 89.6256 129.163 89.2331C128.557 88.8361 128.391 88.0279 128.784 87.4285C129.177 86.8278 129.989 86.6499 130.595 87.0454C131.194 87.438 131.363 88.257 130.969 88.8577Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.318 92.8223C157.466 93.5291 157.014 94.2185 156.312 94.3603C155.611 94.5128 154.916 94.0583 154.767 93.3509C154.621 92.6534 155.072 91.9634 155.774 91.8115C156.477 91.6717 157.172 92.1262 157.318 92.8223Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.115 98.7092C129.257 99.4117 128.811 100.095 128.109 100.247C127.401 100.395 126.706 99.9403 126.564 99.2378C126.416 98.5296 126.863 97.846 127.571 97.6984C128.272 97.5459 128.968 98.001 129.115 98.7092Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.164 111.403C144.462 111.555 143.767 111.101 143.619 110.392C143.477 109.688 143.924 109.006 144.626 108.854C145.334 108.707 146.029 109.161 146.171 109.864C146.318 110.572 145.871 111.255 145.164 111.403Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.257 83.2042C138.55 83.3538 137.854 82.8987 137.712 82.1949C137.56 81.4944 138.013 80.8037 138.719 80.6555C139.415 80.5107 140.111 80.9658 140.264 81.6662C140.406 82.3707 139.954 83.0601 139.257 83.2042Z"
        fill="#6BA6AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.589 134.424L145.436 134.03C138.32 163.772 128.167 177.293 114.677 198.761L114.671 198.756C122.161 189.335 138.561 180.41 144.564 170.179C147.24 165.618 149.324 160.837 150.718 155.464C152.504 148.585 152.971 141.763 152.589 134.424Z"
        fill="#01CABE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.335 154.707L145.435 134.03C146.848 160.92 135.776 173.861 122.772 189.059C120.124 192.154 117.396 195.342 114.677 198.762C115.033 197.719 115.378 196.685 115.712 195.659C120.724 180.276 123.313 166.828 124.335 154.707Z"
        fill="url(#paint1_linear_5350_43002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.495 101.657L105.723 108.051C81.3213 126.487 73.0019 141.206 58.7357 162.167C64.3673 151.53 65.9806 132.931 72.9504 123.332C76.0579 119.054 79.6036 115.23 83.9674 111.799C89.5544 107.406 95.6172 104.244 102.495 101.657Z"
        fill="#01CABE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.2324 135.668L105.722 108.05C80.5202 117.53 73.0994 132.859 64.3841 150.862C62.609 154.528 60.7803 158.306 58.7367 162.168C71.7442 150.263 83.8233 141.847 95.2324 135.668Z"
        fill="url(#paint2_linear_5350_43002)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5350_43002"
          x1="150.235"
          y1="92.6029"
          x2="85.5441"
          y2="63.3329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5350_43002"
          x1="141.417"
          y1="141.803"
          x2="118.871"
          y2="139.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5350_43002"
          x1="102.264"
          y1="115.606"
          x2="61.7159"
          y2="122.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
