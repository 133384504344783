import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

interface RedeemSharesForSpecificAssetsInputs {
  recipient: Address;
  sharesQuantity: bigint;
  payoutAssets: Address[];
  payoutAssetPercentages: bigint[];
}

export const redeemSharesForSpecificAssets: FunctionConfig<RedeemSharesForSpecificAssetsInputs> = {
  signature:
    "function redeemSharesForSpecificAssets(address recipient, uint256 sharesQuantity, address[] payoutAssets, uint256[] payoutAssetPercentages) returns (uint256[])",

  Description({ address }) {
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: address.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Redeem shares of {vault?.name ?? "this vault"} in specific assets.</>;
  },

  Label() {
    return <>Redeem Shares</>;
  },
};
