import { toAddress } from "@enzymefinance/environment";
import { Badge, Button, Tooltip } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useTimelockRemainingForMigrationRequest } from "utils/hooks/useTimelockRemainingForMigrationRequest";

import { supportEmailAddress } from "../../../utils/constants";
import { Link } from "../../routing/Link";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface VaultUpgradePendingProps {
  id: string;
  isOwner: boolean;
  upgradePending: boolean;
  upgradeExecutable: boolean;
}

export function VaultUpgradePending({ id, isOwner, upgradePending, upgradeExecutable }: VaultUpgradePendingProps) {
  const { client } = useNetwork();
  const { contracts } = useGlobals();
  const migrationTimelockRemainingQuery = useTimelockRemainingForMigrationRequest(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: toAddress(id),
  });

  const timelockRemaining = migrationTimelockRemainingQuery.data
    ? Number(migrationTimelockRemainingQuery.data)
    : undefined;

  const durationRemaining = timelockRemaining ? dayjs.duration(timelockRemaining, "seconds").humanize() : "N/A";

  const depositorVaultMessage = (
    <>
      <p>
        This vault has an upgrade pending. Vault upgrades require no action from depositors. If you are a depositor in
        this vault and you want to redeem your shares, please contact us at If you did not receive the code, please
        contact our support at{" "}
        <Link to={`mailto:${supportEmailAddress}`} className="text-link">
          {supportEmailAddress}
        </Link>
      </p>
      <div className="flex items-center space-x-4">
        <Button as={Link} appearance="secondary" size="xs" to={`/vault/${id}/compare`}>
          Review Upgrade
        </Button>
      </div>
    </>
  );
  const managerVaultMessage = upgradeExecutable ? (
    <>
      This vault has an upgrade pending. The upgrade is ready to be executed. Go to Vault {">"} Settings to execute the
      upgrade.
    </>
  ) : (
    <>This vault has an upgrade pending. You will be able to execute the upgrade in {durationRemaining}</>
  );

  return upgradePending ? (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge>Upgrade Pending</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="block max-w-xs space-y-4 text-sm">{isOwner ? managerVaultMessage : depositorVaultMessage}</div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}
