import type { NumberInputProps as NumberInputPropsBase } from "@enzymefinance/ui";
import { NumberInput as NumberInputBase } from "@enzymefinance/ui";
import { useController } from "react-hook-form";

export interface NumberInputProps extends Omit<NumberInputPropsBase, "id" | "ref"> {
  name: string;
}

export function NumberInput({ onMaxClick, onValueChange, ...props }: NumberInputProps) {
  const {
    field: { onChange, ref: _, ...field },
    fieldState,
    formState,
  } = useController({ name: props.name });

  return (
    <NumberInputBase
      error={fieldState.error?.message}
      onMaxClick={() => {
        onMaxClick?.();
        onChange(props.balance);
      }}
      onValueChange={(values, sourceInfo) => {
        onChange(values.value);
        onValueChange?.(values, sourceInfo);
      }}
      readOnly={formState.isSubmitting}
      {...props}
      id={props.name}
      {...field}
    />
  );
}
