import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay, TokenIconStacked } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";
import type { IntegrationHandler } from "./types";

export const compoundV3Lend: IntegrationHandler<Integrations.CompoundV3.LendArgs> = {
  Description({ args: { cToken: cTokenAddress, depositAmount } }) {
    const { environment } = useGlobals();

    const cToken = environment.getAsset(cTokenAddress);

    if (cToken.type !== AssetType.COMPOUND_V3) {
      throw new Error("Invalid cToken");
    }

    const underlyingAsset = environment.getAsset(cToken.underlying);

    return (
      <>
        Lending on Compound V3{" "}
        <BigIntDisplay
          value={depositAmount}
          decimals={underlyingAsset.decimals}
          numberFormat={{ currency: underlyingAsset.symbol }}
        />
      </>
    );
  },
  Label() {
    return <>Lend on Compound V3</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.CompoundV3.lendDecode(encodedCallArgs),
};

export const compoundV3Redeem: IntegrationHandler<Integrations.CompoundV3.RedeemArgs> = {
  Description({ args: { cToken: cTokenAddress, redeemAmount } }) {
    const { environment } = useGlobals();

    const cToken = environment.getAsset(cTokenAddress);

    if (cToken.type !== AssetType.COMPOUND_V3) {
      throw new Error("Invalid cToken");
    }

    return (
      <>
        Redeeming on Compound V3{" "}
        <BigIntDisplay value={redeemAmount} decimals={cToken.decimals} numberFormat={{ currency: cToken.symbol }} />
      </>
    );
  },
  Label() {
    return <>Redeem on Compound V3</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.CompoundV3.redeemDecode(encodedCallArgs),
};

export const compoundV3ClaimRewards: IntegrationHandler<Integrations.CompoundV3.ClaimRewardsArgs> = {
  Description({ args: { cTokens } }) {
    const { environment } = useGlobals();

    const compoundV3Assets = useMemo(
      () => cTokens.map((cToken) => environment.getAsset(cToken)),
      [cTokens, environment],
    );

    return (
      <>
        Claim Rewards for cTokens: <TokenIconStacked tokens={compoundV3Assets} />
      </>
    );
  },
  Label() {
    return <>Claim Rewards for Compound V3</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.CompoundV3.claimRewardsDecode(encodedCallArgs),
};
