import type { Asset } from "@enzymefinance/environment";
import type { IconLabelProps } from "@enzymefinance/ui";
import { IconLabel } from "@enzymefinance/ui";

import type { TokenIconProps } from "../token-icon/TokenIcon.js";
import { TokenIcon } from "../token-icon/TokenIcon.js";

export interface TokenLabelProps
  extends Omit<IconLabelProps, "description" | "id" | "name" | "network" | "size" | "symbol" | "title" | "url"> {
  asset: Asset | undefined;
  /**
   * Whether to hide the token's long name
   */
  hideName?: boolean;
  kind?: "default" | "definition" | "option";
  reverse?: boolean;
  size?: TokenIconProps["size"];
}

export function TokenLabel({ kind, loading, reverse = false, size, hideName, asset, ...props }: TokenLabelProps) {
  const description = hideName ? undefined : asset?.name;
  const isDefinitionOrOption = kind === "definition" || kind === "option";

  return (
    <IconLabel
      reverse={reverse}
      description={
        isDefinitionOrOption ? (
          <span className="text-base-content" aria-hidden={true}>
            {description}
          </span>
        ) : (
          description
        )
      }
      icon={
        <TokenIcon
          asset={asset}
          alt={isDefinitionOrOption ? "" : undefined}
          loading={loading}
          size={size}
          title={isDefinitionOrOption ? undefined : description}
        />
      }
      kind={kind}
      loading={loading}
      space={size === 4 || size === 5 ? 2 : 4}
      title={asset?.symbol}
      {...props}
    />
  );
}
