import { getExternalPositionType } from "@enzymefinance/sdk/Portfolio";
import type { ExternalPositionType } from "@enzymefinance/utils";
import { isExternalPositionTypeEnum } from "@enzymefinance/utils";
import { address as addressSchema, externalPositionType } from "@enzymefinance/validation";
import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useMemo } from "react";
import { externalPositions } from "utils/externalPositions";
import type { Address, PublicClient } from "viem";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

interface FetchExternalPositionIdArgs {
  address: Address;
  client: PublicClient;
  externalPositionTypes: {
    id: string;
    label: string;
  }[];
}

async function fetchExternalPositionType(args: FetchExternalPositionIdArgs) {
  try {
    const externalPositionIdentifier = await getExternalPositionType(args.client, {
      externalPosition: args.address,
    });

    const foundExternalPositionType = args.externalPositionTypes.find(
      (position) => position.id === externalPositionIdentifier.toString(),
    );

    if (foundExternalPositionType === undefined || !isExternalPositionTypeEnum(foundExternalPositionType.label)) {
      return null;
    }

    return externalPositions[foundExternalPositionType.label];
  } catch (_error) {
    return null;
  }
}

export function useExternalPositionType() {
  const { client } = useNetwork();

  const { externalPositionTypes } = useExternalPositions();

  return useMemo(
    () => (address: Address | undefined) =>
      address
        ? fetchExternalPositionType({ address, externalPositionTypes, client }).then((value) => value ?? undefined)
        : undefined,
    [externalPositionTypes, client],
  );
}

export const useExternalPositionTypeByAddress = createUseValidatedClientQuery(["external-position-by-address"], {
  paramsSchema: z.object({
    address: addressSchema(),
    externalPositionTypes: z.array(z.object({ id: z.string(), label: externalPositionType() })),
  }),
  responseSchema: z
    .object({
      icon: z.string().optional(),
      label: z.string(),
      type: externalPositionType(),
      address: z.function().args(z.any()).returns(addressSchema()),
    })
    .nullable(),
  queryFn: async (params, client) => {
    const externalPositionIdentifier = await getExternalPositionType(client, {
      externalPosition: params.address,
    });

    const foundExternalPositionType = params.externalPositionTypes.find(
      (position) => position.id === externalPositionIdentifier.toString(),
    );

    if (foundExternalPositionType === undefined) {
      return null;
    }

    return externalPositions[foundExternalPositionType.label as ExternalPositionType];
  },
});
