import classNames from "classnames";
import type { ConfigType } from "dayjs";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import type { ReactElement } from "react";
import { isValidElement, useMemo } from "react";

import { useTruncated } from "../../hooks/useTruncated.js";
import { defaultDateLocale } from "../../utils/locale.js";
import { Skeleton } from "../layout/Skeleton.js";
import { Tooltip } from "../overlays/Tooltip.js";
import { Popper } from "../overlays/popper/Popper.js";

dayjs.locale(defaultDateLocale.toLowerCase());
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

type DateDisplayAppearance = "neutral" | "simple";

export interface DateDisplayProps {
  className?: string;
  error?: boolean | string;
  fallback?: string;
  /**
   * Get the formatted date according to the string of tokens passed in.
   *
   * See https://day.js.org/docs/en/display/format#list-of-all-available-formats
   */
  format?: string;
  fromNow?: boolean;
  appearance?: DateDisplayAppearance;
  loading?: ReactElement | boolean | string;
  showFromSuffix?: boolean;
  tooltip?: boolean;
  truncate?: boolean;
  value: ConfigType;
}

export function DateDisplay({
  className,
  error: errorBase,
  fallback = "-",
  format = "L LT",
  fromNow = false,
  appearance = "neutral",
  loading = false,
  showFromSuffix = true,
  tooltip = true,
  truncate = true,
  value,
  ...props
}: DateDisplayProps) {
  const { ref } = useTruncated();
  const isValueValid = value !== null && value !== undefined;
  const formattedValue = useMemo(
    () => (isValueValid ? (fromNow ? dayjs(value).fromNow(!showFromSuffix) : dayjs(value).format(format)) : undefined),
    [format, fromNow, isValueValid, showFromSuffix, value],
  );

  const formattedFull = useMemo(() => (isValueValid ? dayjs(value).format("LLLL") : undefined), [isValueValid, value]);
  const skeletonClasses = classNames("h-5 w-24", className);

  if (isValidElement(loading) || loading === true) {
    return loading === true ? <Skeleton className={skeletonClasses} /> : <>{loading}</>;
  }

  const error = typeof errorBase === "boolean" ? (errorBase ? "N/A" : undefined) : errorBase;
  const output = error ?? formattedValue ?? fallback;
  const classes = classNames(
    { "text-base-content text-sm font-normal": appearance === "neutral", truncate },
    className,
  );
  const element = (
    <span className={classes} ref={ref} {...props}>
      {output}
    </span>
  );

  if (!tooltip || format === "LLLL") {
    return element;
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Item as="span" className="inline-flex min-w-0 max-w-full">
        {element}
      </Tooltip.Item>
      <Tooltip.Panel arrow={<Popper.Arrow className="dark:border-gray-850 border-gray-100" />}>
        {formattedFull}
      </Tooltip.Panel>
    </Tooltip.Provider>
  );
}
