import type { Network } from "@enzymefinance/environment";
import { networks } from "@enzymefinance/environment";
import type { ReactNode } from "react";

export function etherscanLink(hash: string, tx: boolean, network: Network): string {
  const host = networks[network].explorer.url;

  return tx ? `${host}/tx/${hash}` : `${host}/address/${hash}`;
}

export interface EtherscanLinkProps {
  hash: string;
  isTransaction?: boolean;
  network: Network;
  title?: string;
  className?: string;
  children?: ReactNode;
}

export function EtherscanLink({
  children,
  className,
  hash,
  isTransaction = hash.length > 42,
  network,
  title,
}: EtherscanLinkProps) {
  const explorerLabel = networks[network].explorer.label;

  return (
    <a
      className={className}
      href={etherscanLink(hash, isTransaction, network)}
      rel="noreferrer"
      title={title ?? `View on ${explorerLabel}`}
      target="_blank"
    >
      {children ?? hash}
    </a>
  );
}
