import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const sharesWrapperDepositFromQueue: FunctionConfig<{ depositAsset: Address; users: Address[] }> = {
  signature: "function depositFromQueue(address depositAsset, address[] users)",

  Description() {
    return <>Execute deposit requests</>;
  },
  Label() {
    return <>Execute deposit on Shares Wrapper</>;
  },
};
