import type { TokenBigIntInputProps as TokenBigIntInputPropsBase } from "@enzymefinance/ethereum-ui";
import { TokenBigIntInput as TokenBigIntInputBase } from "@enzymefinance/ethereum-ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface TokenBigIntInputProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TokenBigIntInputPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function TokenBigIntInput<TFieldValues extends FieldValues = FieldValues>({
  disabled,
  ...props
}: TokenBigIntInputProps<TFieldValues>) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
    formState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <TokenBigIntInputBase
      disabled={disabled || formState.isSubmitting}
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      onChange={onChange as any}
    />
  );
}

TokenBigIntInput.LocalValue = TokenBigIntInputBase.LocalValue;
