import type { Address } from "@enzymefinance/environment";
import { Badge, Tooltip } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import dayjs from "dayjs";
import { ActivityType, useVaultActivitiesQuery } from "queries/core";
import { useMemo } from "react";
import { usePricelessAssetBypassTimeLimit } from "utils/hooks/usePricelessAssetBypassTimeLimit";
import { usePricelessAssetBypassTimelock } from "utils/hooks/usePricelessAssetBypassTimelock";

interface BypassableCumulativeSlippagePolicyWarningProps {
  vaultId: Address;
}

export function BypassableCumulativeSlippagePolicyWarning({ vaultId }: BypassableCumulativeSlippagePolicyWarningProps) {
  const { contracts, environment } = useGlobals();
  const { client } = useNetwork();

  const { data: tradeActivity } = useVaultActivitiesQuery({
    variables: {
      activityTypes: [ActivityType.VAULTSETTINGS],
      vaults: [vaultId],
    },
  });

  // TODO: load for all policies (separate hook)
  const { data: cumulativeSlippageBypassTimelock } = usePricelessAssetBypassTimelock(client, {
    policy: contracts.CumulativeSlippageTolerancePolicy,
  });
  const { data: cumulativeSlippageBypassTimeLimit } = usePricelessAssetBypassTimeLimit(client, {
    policy: contracts.CumulativeSlippageTolerancePolicy,
  });
  const { data: onlyDustAssetsBypassTimelock } = usePricelessAssetBypassTimelock(client, {
    policy: contracts.OnlyUntrackDustOrPricelessAssetsPolicy,
  });
  const { data: onlyDustAssetsBypassTimeLimit } = usePricelessAssetBypassTimeLimit(client, {
    policy: contracts.OnlyUntrackDustOrPricelessAssetsPolicy,
  });
  const { data: onlyDustExternalPositionsBypassTimelock } = usePricelessAssetBypassTimelock(client, {
    policy: contracts.OnlyRemoveDustExternalPositionPolicy,
  });
  const { data: onlyDustExternalPositionsBypassTimeLimit } = usePricelessAssetBypassTimeLimit(client, {
    policy: contracts.OnlyRemoveDustExternalPositionPolicy,
  });

  const pricelessAssetTimelockActivities = useMemo(() => {
    return (
      tradeActivity?.vaultActivities
        .map((activity) => {
          if (activity.__typename === "PricelessAssetTimelock") {
            const { timestamp } = activity;

            const endingBypasses: number[] = [];

            if (cumulativeSlippageBypassTimelock !== undefined && cumulativeSlippageBypassTimeLimit !== undefined) {
              endingBypasses.push(
                timestamp + Number(cumulativeSlippageBypassTimelock) + Number(cumulativeSlippageBypassTimeLimit),
              );
            }

            if (onlyDustAssetsBypassTimelock !== undefined && onlyDustAssetsBypassTimeLimit !== undefined) {
              endingBypasses.push(
                timestamp + Number(onlyDustAssetsBypassTimelock) + Number(onlyDustAssetsBypassTimeLimit),
              );
            }

            if (
              onlyDustExternalPositionsBypassTimelock !== undefined &&
              onlyDustExternalPositionsBypassTimeLimit !== undefined
            ) {
              endingBypasses.push(
                timestamp +
                  Number(onlyDustExternalPositionsBypassTimelock) +
                  Number(onlyDustExternalPositionsBypassTimeLimit),
              );
            }

            if (dayjs.unix(Math.max(...endingBypasses)).isBefore(dayjs())) {
              // Filter out the expired dates
              return undefined;
            }

            return true;
          }

          return undefined;
        })
        .filter(isTruthy) ?? []
    );
  }, [
    tradeActivity?.vaultActivities,
    cumulativeSlippageBypassTimelock,
    cumulativeSlippageBypassTimeLimit,
    onlyDustAssetsBypassTimelock,
    onlyDustAssetsBypassTimeLimit,
    onlyDustExternalPositionsBypassTimelock,
    onlyDustExternalPositionsBypassTimeLimit,
    environment,
  ]);

  return pricelessAssetTimelockActivities.length > 0 ? (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge appearance="warning">Bypassable Policies</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="flex max-w-lg flex-col space-y-4 p-4 text-sm font-normal">
          <div className="space-y-2">
            <span>
              This vault has currently allowed one or more assets with invalid prices to bypass policies. You can{" "}
              <InlineLink appearance="tertiary" to={`/vault/${vaultId}/policies`}>
                <b>review the policies here.</b>
              </InlineLink>
            </span>
          </div>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}
