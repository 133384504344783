export function parseFloatValue(value?: bigint | number | string) {
  if (value === undefined || value === "") {
    return undefined;
  }

  const floatValue = typeof value === "number" ? value : Number(value);

  return Number.isNaN(floatValue) ? undefined : floatValue;
}

function safeMultiply<TValue>(factor: number) {
  return (value: TValue) => {
    if (typeof value !== "number") {
      return value;
    }

    return Number(value * factor);
  };
}

type SafeMultiply = <T>(value: T, fractionDigits?: number) => T | number;

export const toPercentage: SafeMultiply = safeMultiply(100);
export const fromPercentage: SafeMultiply = safeMultiply(0.01);
