import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address as AddressType } from "viem";

export const deploySharesSplitter: FunctionConfig<{ users: AddressType[]; splitPercentages: bigint[] }> = {
  signature: "function deploy(address[] calldata users, uint256[] calldata splitPercentages)",

  Description({ inputs }) {
    const percentages = inputs.splitPercentages;
    const userPercentages = inputs.users.map((address, index) => ({
      address,
      percentage: percentages[index],
    }));

    return (
      <>
        <div className="flex h-full flex-col items-center justify-between space-y-4">
          <div className="flex w-full items-center justify-between space-x-4 font-medium">
            <h4>Recipient address</h4>
            <h4>Allocation</h4>
          </div>
          {userPercentages.map((userPercentage) => (
            <div className="flex w-full items-center justify-between space-x-1" key={userPercentage.address}>
              <Address address={userPercentage.address} className="text-base-content text-sm" copy={false} />
              <BigIntDisplay
                decimals={4}
                value={userPercentage.percentage}
                numberFormat={{ style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }}
                className="text-base-content w-14 shrink-0 text-right"
              />
            </div>
          ))}
        </div>
      </>
    );
  },

  Label() {
    return <>Create Fee Splitter</>;
  },
};
