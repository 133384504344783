import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Fees } from "@enzymefinance/sdk/Configuration";
import { Badge, NumberDisplay, StackedList } from "@enzymefinance/ui";
import { bigint } from "@enzymefinance/validation";
import { parseUnits } from "viem";
import { z } from "zod";
import type { EntranceBurnFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { EntranceFeeFormFields } from "./EntranceFee";

export const entranceRateBurnFeeSchema = z.object({
  rate: bigint()
    .refine((value?: EntranceBurnFeeSettings["rate"]) => typeof value === "bigint" && value > 0n, {
      message: "The rate must be greater than 0%.",
    })
    .refine((value?: EntranceBurnFeeSettings["rate"]) => typeof value === "bigint" && value < parseUnits("1", 4), {
      message: "The rate must be less than 100%.",
    }),
});

function entranceRateBurnFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.ENTRANCE_BURN_FEE>) {
  return <BigIntDisplay numberFormat={{ style: "percent" }} value={settings.rate ?? undefined} decimals={4} />;
}

function entranceRateBurnFeeDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ENTRANCE_BURN_FEE>) {
  return (
    <StackedList.ItemDataBox label="Rate">
      <NumberDisplay value={Number(settings.rate)} numberFormat={{ style: "percent" }} />
    </StackedList.ItemDataBox>
  );
}

export const entranceRateBurnFee: VaultConfig<VaultConfigType.ENTRANCE_BURN_FEE> = {
  address: (contracts) => contracts.EntranceRateBurnFee,
  disableable: false,
  display: entranceRateBurnFeeDisplay,
  displaySubgraph: entranceRateBurnFeeDisplaySubgraph,
  editable: false,
  encode: (settings) => Fees.Entrance.encodeBurnFeeSettings({ ...settings, rateInBps: settings.rate ?? 0n }),
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const rate = await Fees.Entrance.getRate(client, {
        entranceRateFee: vaultConfigAddress,
        comptrollerProxy: comptroller,
      });

      return { rate };
    } catch {
      return undefined;
    }
  },
  formFields: () => <EntranceFeeFormFields selected={VaultConfigType.ENTRANCE_BURN_FEE} />,
  label: "Entrance Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>If enabled, entrance fees are charged with every new deposit.</p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.ENTRANCE_BURN_FEE,
  validationSchema: entranceRateBurnFeeSchema,
};
