import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactElement, ReactNode } from "react";
import { isValidElement } from "react";

import { Icon } from "../elements/Icon.js";
import { SectionHeading } from "../headings/SectionHeading.js";
import { Skeleton } from "../layout/Skeleton.js";
import type { NumberDisplayAppearance, NumberDisplayProps } from "./NumberDisplay.js";
import { NumberDisplay } from "./NumberDisplay.js";

export interface StatsProps extends Omit<ComponentPropsWithoutRef<"dl">, "title"> {
  title?: ReactElement | string;
}

export function Stats({ title, ...props }: StatsProps) {
  const stats = <dl className="space-y-4 md:flex md:justify-around md:space-x-4 md:space-y-0" {...props} />;

  return title === undefined ? (
    stats
  ) : (
    <div className="space-y-5">
      <SectionHeading>{title}</SectionHeading>
      {stats}
    </div>
  );
}

export interface StatsItemProps {
  children: ReactNode;
  className?: string;
  icon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  id: string;
  appearance?: "primary" | "secondary" | "tertiary";
  loading?: ReactElement | boolean | string;
  name: ReactNode;
  reverseLabel?: boolean;
}

export function StatsItem({
  children,
  className,
  icon,
  id,
  appearance = "secondary",
  loading = false,
  name,
  reverseLabel = true,
}: StatsItemProps) {
  const wrapperClassName = classNames(
    "p-4 sm:rounded-2xl flex items-center justify-start",
    {
      "bg-gradient-to-r bg-gradient-primary": appearance === "primary",
      "bg-base-200": appearance === "secondary",
    },
    className,
  );
  const titleClassName = classNames("text-sm font-medium truncate", {
    "ml-4": !!icon,
    "text-base-content": appearance === "secondary" || appearance === "tertiary",
    "text-heading-content": appearance === "primary",
  });

  const termId = `${id}-term`;
  const labelPositionClasses = classNames("flex", { "flex-col-reverse": reverseLabel, "flex-col": !reverseLabel });

  return (
    <div className={wrapperClassName}>
      {icon ? (
        <div className="to-primary dark:to-primary from-primary-lighter dark:from-primary-lighter max-h-12 rounded-lg bg-gradient-to-r p-3 text-high-emphasis">
          <Icon icon={icon} size={6} aria-hidden={true} />
        </div>
      ) : null}
      <div className={labelPositionClasses}>
        <dt id={termId}>
          <p className={titleClassName}>{name}</p>
        </dt>
        <dd className="flex space-x-2 pb-2" role="definition" aria-labelledby={termId}>
          {isValidElement(loading) || loading === true ? (
            loading === true ? (
              <Skeleton className="ml-4 h-8 flex-1" />
            ) : (
              loading
            )
          ) : (
            children
          )}
        </dd>
      </div>
    </div>
  );
}

Stats.Item = StatsItem;

export type StatsNumberItemProps = Omit<NumberDisplayProps, "appearance"> &
  Omit<StatsItemProps, "children"> & {
    numberDisplayKind?: NumberDisplayAppearance;
    numberIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  };

export function StatsNumberItem({
  className,
  icon,
  id,
  appearance,
  loading,
  name,
  numberDisplayKind,
  numberIcon,
  ...numberProps
}: StatsNumberItemProps) {
  const numberClassName = classNames("text-2xl font-semibold", { "ml-4": !!icon });

  return (
    <StatsItem className={className} icon={icon} id={id} appearance={appearance} loading={loading} name={name}>
      <div className="flex items-center">
        {numberIcon ? <Icon icon={numberIcon} size={6} aria-hidden={true} className="mr-2" /> : null}
        <NumberDisplay className={numberClassName} appearance={numberDisplayKind} {...numberProps} />
      </div>
    </StatsItem>
  );
}

Stats.NumberItem = StatsNumberItem;
