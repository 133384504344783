import { ChevronDownIcon, ChevronUpIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWindowSize } from "react-use";

import { useScrollTop } from "../../hooks/useScrollTop.js";
import { SectionHeading } from "../headings/SectionHeading.js";
import { Card } from "./Card.js";
import { Icon } from "./Icon.js";

export interface ShowMoreProps {
  children: ReactNode;
  isCard?: boolean;
}

export function ShowMore({ children, isCard = true }: ShowMoreProps) {
  return (
    <>
      {isCard ? (
        <Card full={true} rounded="2xl">
          {children}
        </Card>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}

interface ShowMoreItemProps {
  children: ReactNode;
  collapsible?: boolean;
  fontWeight?: "heavy" | "medium";
  footer?: ReactNode;
  isCard?: boolean;
  initialOpen?: boolean;
  header?: ReactNode;
  collapsedHeight?: 80 | 160;
  title?: ReactNode;
}

function showMoreItem({
  children,
  collapsible = true,
  footer,
  header,
  isCard = true,
  initialOpen = false,
  collapsedHeight = 80,
  title,
  fontWeight = "heavy",
}: ShowMoreItemProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const { scrollTop } = useScrollTop(ref.current);

  useEffect(() => {
    scrollTop();

    if (collapsible) {
      setIsOpen(initialOpen);
      setIsCollapsed(!!ref.current && ref.current.scrollHeight > collapsedHeight);
    }
  }, [width, ref, initialOpen, collapsedHeight, collapsible]);

  const handleToggle = useCallback(() => {
    scrollTop();
    setIsOpen(!isOpen);
  }, [isOpen, scrollTop]);

  const contentClassName = classNames("w-full relative scrollbar-hide", {
    "max-h-[160px]": !isOpen && isCollapsed && collapsedHeight === 160,
    "max-h-[80px]": !isOpen && isCollapsed && collapsedHeight === 80,
    "overflow-y-auto": isOpen && isCollapsed,
    "overflow-y-hidden h-full": !isOpen && isCollapsed,
  });

  const backgroundClassName = classNames("absolute inset-y-0 bottom-2 h-full w-full", {
    "dark:from-gray-850 bg-gradient-to-t from-base-200 dark:to-transparent": isCard,
  });

  const displayContent = useMemo(
    () => (
      <>
        {title === undefined ? null : <SectionHeading.Title fontWeight={fontWeight}>{title}</SectionHeading.Title>}
        {header}
        <div ref={ref} className={contentClassName}>
          <div>{children}</div>
          {isOpen || !isCollapsed ? null : <div className={backgroundClassName} />}
        </div>
        {isCollapsed ? (
          <div className="flex w-full justify-center">
            <button
              className="text-secondary focus-visible:ring-secondary hover:text-secondary/90 focus-visible:ring-offset-base-100 flex items-center justify-center  rounded text-sm font-bold transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
              onClick={handleToggle}
              type="button"
            >
              {isOpen ? "Show less" : "Show more"}{" "}
              <Icon
                className="stroke-secondary ml-1 stroke-2 pt-[2px]"
                size={4}
                icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
              />
            </button>
          </div>
        ) : null}
        {footer}
      </>
    ),
    [backgroundClassName, children, contentClassName, footer, handleToggle, header, isCollapsed, isOpen, title],
  );

  return (
    <>
      {isCard ? (
        <Card.Content className="flex flex-col items-start space-y-4">{displayContent}</Card.Content>
      ) : (
        <div className="flex flex-col items-start space-y-4 px-4 py-5 sm:p-6">{displayContent}</div>
      )}
    </>
  );
}

ShowMore.Item = showMoreItem;
