import { ErrorFeedback } from "./ErrorFeedback";

interface ErrorFallbackProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError: () => void;
}

export function ErrorFallback({ error, resetError }: ErrorFallbackProps) {
  return <ErrorFeedback error={error} onReset={resetError} />;
}
