import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

interface SharesWrapperDeployInputs {
  vaultProxy: Address;
  managers: Address[];
  redemptionAsset: Address;
  useDepositApprovals: boolean;
  useRedemptionApprovals: boolean;
  useTransferApprovals: boolean;
  depositMode: number;
  windowConfig: {
    firstWindowStart: number;
    frequency: number;
    duration: number;
    relativeSharesCap: number;
  };
}

export const sharesWrapperDeploy: FunctionConfig<SharesWrapperDeployInputs> = {
  signature:
    "function deploy(address vaultProxy, address[] calldata managers, address redemptionAsset, bool useDepositApprovals, bool useRedemptionApprovals, bool useTransferApprovals, uint8 depositMode, (uint64 firstWindowStart, uint32 frequency, uint32 duration, uint64 relativeSharesCap) calldata windowConfig) external returns (address wrapperProxy)",

  Description({ inputs }) {
    const { data, loading } = useVaultDetailsQuery({
      variables: {
        id: inputs.vaultProxy.toLowerCase(),
      },
    });

    if (loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Enable shares wrapper for vault {data?.vault?.name}</>;
  },

  Label() {
    return <>Shares Wrapper</>;
  },
};
