import type { NavigationItemProps, NavigationProps } from "@enzymefinance/ui";
import { MobileMenu as MobileMenuBase, Navigation } from "@enzymefinance/ui";
import { ConnectionAvatar } from "components/connection/ConnectionAvatar.js";
import { CurrencyMenu } from "components/menu/CurrencyMenu";
import { DeploymentMenu } from "components/menu/DeploymentMenu";
import { useMobileMenu } from "components/menu/MobileMenuProvider";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";

export function MobileMenu() {
  const location = useLocation();
  const { isOpen, toggle } = useMobileMenu();
  const close = useCallback(() => toggle(false), [toggle]);

  useUpdateEffect(() => {
    if (isOpen) {
      toggle(false);
    }
  }, [location.pathname]);

  return (
    <MobileMenuBase isOpen={isOpen}>
      {/* Network & Currency Menu */}
      <MobileMenuBase.Group>
        <div className="space-y-6 px-3">
          <ConnectionAvatar />
          <div className="space-y-3">
            <DeploymentMenu appearance="select" onDeploymentSelect={close} />
            <CurrencyMenu appearance="select" onCurrencySelect={close} />
          </div>
        </div>
      </MobileMenuBase.Group>
    </MobileMenuBase>
  );
}

type MobileMenuItemsProps = Omit<NavigationProps, "direction">;

function mobileMenuItems(props: MobileMenuItemsProps) {
  return <Navigation direction="vertical" {...props} />;
}

MobileMenu.Items = mobileMenuItems;

type MobileMenuItemProps<TProps extends {} = LinkProps> = NavigationItemProps<TProps>;

function mobileMenuItem<TProps extends {} = LinkProps>(props: MobileMenuItemProps<TProps>) {
  return <Navigation.Item as={Link} {...props} />;
}

MobileMenu.Item = mobileMenuItem;

MobileMenu.User = MobileMenuBase.User;
