import { SubmitButton } from "@enzymefinance/hook-form";
import { CheckIcon } from "@enzymefinance/icons/solid";
import { Icon } from "@enzymefinance/ui";
import classNames from "classnames";

interface VaultDepositFormSubmitButtonProps {
  asset: { symbol: string };
  isPendingApprove: boolean;
  isSubmittingApprove: boolean;
  isSubmittingBuyShares: boolean;
  isDepositModeRequest?: boolean;
  isUSDTLocked: boolean;
  isValid: boolean;
  sufficientAllowance?: boolean;
}

export function VaultDepositFormSubmitButton({
  asset,
  isPendingApprove,
  isSubmittingApprove,
  isSubmittingBuyShares,
  isDepositModeRequest = false,
  isUSDTLocked,
  isValid,
  sufficientAllowance,
}: VaultDepositFormSubmitButtonProps) {
  return (
    <div className="mt-4 flex flex-col space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
      <SubmitButton
        disabled={isSubmittingApprove || isPendingApprove || !!sufficientAllowance}
        loading={isSubmittingApprove || (isPendingApprove && !sufficientAllowance)}
      >
        {isUSDTLocked ? `Unlock ${asset.symbol}` : `Approve ${asset.symbol}`}
      </SubmitButton>
      <SubmitButton disabled={isUSDTLocked || !sufficientAllowance} loading={isSubmittingBuyShares}>
        {isDepositModeRequest ? "Request deposit" : "Deposit"}
      </SubmitButton>

      <div className="relative col-span-2 hidden grid-cols-2 justify-between md:grid">
        <span
          className={classNames(
            "border-primary-light dark:border-primary-dark absolute left-1/4 top-1/2 w-1/2 border-b",
            { "opacity-50": !isValid || isUSDTLocked || !sufficientAllowance },
          )}
        />
        <span className="bg-base-300 relative mx-auto h-5 w-5 rounded-full">
          <span
            className={classNames(
              "bg-primary-light dark:bg-primary-dark absolute inset-0 flex items-center justify-center rounded-full text-xs font-semibold",
              {
                "text-heading-content bg-transparent": sufficientAllowance,
                "opacity-25": !isValid,
                "text-high-emphasis": !sufficientAllowance,
              },
            )}
          >
            {sufficientAllowance ? <Icon icon={CheckIcon} size={4} /> : 1}
          </span>
        </span>
        <span className="bg-base-300 relative mx-auto h-5 w-5 rounded-full">
          <span
            className={classNames(
              "bg-primary-light dark:bg-primary-dark absolute inset-0 flex items-center justify-center rounded-full text-xs font-semibold text-high-emphasis",
              { "opacity-25": !isValid || isUSDTLocked || !sufficientAllowance },
            )}
          >
            2
          </span>
        </span>
      </div>
    </div>
  );
}
