/**
 * @deprecated Use the more accessible `Switch` component instead
 */
import classNames from "classnames";
import type { ButtonHTMLAttributes, ReactElement } from "react";

import { ScreenReaderText } from "../typography/ScreenReaderText.js";

export interface ToggleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  altText: string;
  label?: ReactElement | string | null;
  labelId?: string;
  labelPosition?: "left" | "right";
  on?: boolean;
}

export function Toggle({
  className,
  altText,
  on = false,
  onClick,
  label = null,
  labelId,
  labelPosition = "left",
}: ToggleProps) {
  const classes = classNames(
    "shrink-0 relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary transition",
    className,
  );
  const backgroundClasses = classNames(
    "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors duration-200",
    { "bg-base-400": !on, "bg-primary dark:bg-primary": on },
  );
  const toggleClasses = classNames(
    "translate-x-0 pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200/50 dark:border-gray-850 rounded-full bg-white dark:bg-gray-200 shadow ring-0 transition-transform duration-200",
    { "translate-x-0": !on, "translate-x-5": on },
  );

  const button = (
    <button aria-pressed={on} aria-labelledby={labelId} onClick={onClick} className={classes} type="button">
      <ScreenReaderText>{altText}</ScreenReaderText>
      <span aria-hidden={true} className={backgroundClasses} />
      <span aria-hidden={true} className={toggleClasses} />
    </button>
  );

  return label === null || labelId === undefined ? (
    button
  ) : (
    <div className="flex items-center space-x-3">
      {labelPosition === "left" && <span id={labelId}>{label}</span>}
      {button}
      {labelPosition === "right" && <span id={labelId}>{label}</span>}
    </div>
  );
}
