import { Address } from "@enzymefinance/ethereum-ui";
import type { FixedSize } from "@enzymefinance/utils";
import classNames from "classnames";
import { useNetwork } from "components/providers/NetworkProvider";
interface PolicyAddressListProps {
  addresses: string[];
  columns?: 1 | 2 | 3 | 4;
  iconSize?: Extract<FixedSize, 4 | 5 | 6 | 8 | 9 | 10 | 12 | 14 | 16>;
}

export function PolicyAddressList({ addresses, columns, iconSize = 5 }: PolicyAddressListProps) {
  const { network } = useNetwork();

  if (addresses.length === 0) {
    return <p className="text-sm">No addresses configured for this policy</p>;
  }

  const containerClasses = classNames("-m-1", {
    "grid gap-4 mr-2": columns !== undefined,
    "flex flex-wrap": columns === undefined,
    [`md:grid-cols-${columns}`]: columns !== undefined,
  });

  const addressClasses = classNames({
    "w-full": columns !== undefined,
  });

  return (
    <div className="scrollbar-hide max-h-96 overflow-auto">
      <div className="overflow-hidden">
        <div className={containerClasses}>
          {addresses.map((address) => (
            <div className="flex flex-col items-center justify-center p-1" key={address}>
              <Address
                className={addressClasses}
                appearance="card"
                address={address}
                icon={true}
                iconSize={iconSize}
                network={network}
                start={9}
                trimmed={true}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
