import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactElement, ReactNode } from "react";
import { Children, createContext, createElement, isValidElement, useContext } from "react";

export interface StackedContextValues<TProps extends {} = {}> {
  displayed: ReactElement<StackedItemProps<TProps>>[];
  undisplayed: ReactElement<StackedItemProps<TProps>>[];
}

const StackedContext = createContext<StackedContextValues | undefined>(undefined);

export function useStacked<TProps extends {} = {}>() {
  const context = useContext<StackedContextValues<TProps> | undefined>(StackedContext as any);

  if (!context) {
    throw new Error("Missing stacked context");
  }

  return context;
}

export interface StackedProps extends Omit<ComponentPropsWithoutRef<"div">, "content"> {
  content?: ReactNode;
  distance?: "default" | "narrow" | "wide";
  limit?: number;
  reverse?: boolean;
}

export function Stacked({
  children,
  content,
  className,
  distance = "default",
  limit,
  reverse = false,
  ...props
}: StackedProps) {
  const classes = classNames(
    "flex flex-none",
    {
      "-space-x-1": distance === "wide",
      "-space-x-2": distance === "default",
      "-space-x-3": distance === "narrow",
      "flex-row-reverse space-x-reverse justify-end": !reverse,
    },
    className,
  );
  const items = Children.toArray(children).filter((item) => isValidElement(item)) as ReactElement<StackedItemProps>[];
  const max = limit ?? items.length;
  const displayed = items.slice(0, max).reverse();
  const undisplayed = items.slice(max);

  return (
    <StackedContext.Provider value={{ displayed, undisplayed }}>
      <div className={classes} {...props}>
        {displayed.map(({ props: { className: itemClassName, ...itemProps } }, index) => (
          <Stacked.Item className={itemClassName} key={index} {...itemProps} />
        ))}
      </div>
      {content}
    </StackedContext.Provider>
  );
}

export type StackedItemProps<TProps extends {} = {}> = TProps & {
  as: ComponentType<TProps>;
  className?: string;
};

function stackedItem<TProps extends {} = {}>({ as, className, ...props }: StackedItemProps<TProps>) {
  const classes = classNames("ring-2 ring-white dark:ring-gray-800", className);

  return createElement<any>(as, { ...props, className: classes });
}

Stacked.Item = stackedItem;
