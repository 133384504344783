import type { Address, Asset, Environment, Version } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import type { Adapter, ReleaseContracts } from "@enzymefinance/utils";
import { invariant, isTruthy } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { parseExternalPositionTypes } from "utils/externalPositionHelper";
import type { ExternalPositionOption } from "utils/hooks/useExternalPositionOptions";

import { parseUnits } from "viem";
import { addressesToAdaptersIncludingLegacy } from "../../../utils/adapterHelper";
import type { VaultPolicyQueryType } from "./VaultConfig";
import { VaultConfigPolicyListOption } from "./VaultConfigTypes";

export interface ManagementFeeSettings {
  rate?: bigint | null;
  recipient?: string;
}

export interface PerformanceFeeSettings {
  rate?: bigint | null;
  recipient?: Address | null;
}

export interface EntranceBurnFeeSettings {
  rate?: bigint | null;
}

export interface EntranceDirectFeeSettings {
  rate?: bigint | null;
  recipient?: Address | null;
}

export interface ExitBurnFeeSettings {
  inKindRate?: bigint | null;
  specificAssetsRate?: bigint | null;
}

export interface ExitDirectFeeSettings {
  inKindRate?: bigint | null;
  specificAssetsRate?: bigint | null;
  recipient?: Address | null;
}

export interface AllowedDepositRecipientsPolicySettings {
  listId: string | undefined;
  items: Address[];
  isDisallowAll: boolean;
}

export interface MinMaxDepositPolicySettings {
  minInvestmentAmount: bigint | undefined;
  maxInvestmentAmount: bigint | undefined;
  isMinEnabled: boolean;
  isMaxEnabled: boolean;
  isRejectAll: boolean;
}

export interface AllowedSharesTransferRecipientsPolicySettings {
  listId: string | undefined;
  items: Address[];
  isDisallowAll: boolean;
}

export interface AllowedAssetsForRedemptionPolicySettings {
  listId: string | undefined;
  items: Asset[];
  inKindRedemptionOnly: boolean;
}

export interface AllowedRedeemersForSpecificAssetsPolicySettings {
  listId: string | undefined;
  items: Address[];
  isDisallowAll: boolean;
}

export type MinAssetBalancesPostRedemptionPolicySettings = { token: Asset; value: bigint }[];

export type AllowedAdaptersPerManagerPolicySettings = {
  address: { value: Address };
  protocols: Adapter[];
}[];

export type AllowedExternalPositionTypesPerManagerPolicySettings = {
  address: { value: Address };
  protocols: ExternalPositionOption[];
}[];

export type AllowedAdapterIncomingAssetsPolicySettings = Asset[];
export interface AllowedAdaptersPolicySettings {
  listId: string | undefined;
  selectedOptionType: VaultConfigPolicyListOption | undefined;
  items: Adapter[];
}
export interface AllowedAdaptersPolicyAlertProps {
  enabled: boolean;
  value?: AllowedAdaptersPolicySettings;
}
export interface AllowedExternalPositionTypesPolicySettings {
  items: ExternalPositionOption[];
  isDisallowAll: boolean;
}
export interface CumulativeSlippageTolerancePolicySettings {
  tolerance: bigint;
}
export type DisallowedAdapterIncomingAssetsPolicySettings = {
  listId: string | undefined;
  selectedOptionType: VaultConfigPolicyListOption | undefined;
  items: Asset[];
};
export interface DisallowedAdaptersPolicySettings {
  listId: string | undefined;
  selectedOptionType: VaultConfigPolicyListOption | undefined;
  items: Asset[];
}
export type NoDepegOnRedeemSharesForSpecificAssetsPolicySettings = {
  asset: Asset;
  referenceAsset: Asset;
  deviationTolerance: string;
}[];
export type OnlyRemoveDustExternalPositionPolicySettings = boolean;
export type OnlyUntrackDustOrPricelessAssetsPolicySettings = boolean;

export function parseAllowedAdaptersPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedAdaptersPolicy">,
  environment: Environment<Version.SULU>,
): AllowedAdaptersPolicySettings {
  // Assume there can be only one delegated list
  // TODO: handle cases with multiple address lists
  const listId = policyData.addressLists[0]?.id;

  const isDelegated = listId
    ? [environment.knownAddressLists.adapters, environment.knownAddressLists.noSlippageAdapters].includes(
        BigInt(listId),
      )
    : false;

  const items = addressesToAdaptersIncludingLegacy({
    addresses: policyData.addressLists.flatMap((addressList) => addressList.items.map(toAddress)),
    environment,
  });

  const settings: AllowedAdaptersPolicySettings = {
    listId,
    selectedOptionType: isDelegated
      ? VaultConfigPolicyListOption.DELEGATED
      : items.length === 0
        ? VaultConfigPolicyListOption.EMPTY_LIST
        : VaultConfigPolicyListOption.CUSTOM_LIST,
    items,
  };

  return settings;
}

export function parseAllowedDepositRecipientsPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedDepositRecipientsPolicy">,
): AllowedDepositRecipientsPolicySettings {
  // Assume there can be only one list
  const listId = policyData.addressLists[0]?.id;
  const items = policyData.addressLists.flatMap((addressList) => addressList.items).map((item) => toAddress(item));

  const settings: AllowedDepositRecipientsPolicySettings = {
    listId,
    items,
    isDisallowAll: items.length === 0,
  };

  return settings;
}

export function parseAllowedSharesTransferRecipientsPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedSharesTransferRecipientsPolicy">,
): AllowedSharesTransferRecipientsPolicySettings {
  // Assume there can be only one list
  const listId = policyData.addressLists[0]?.id;
  const items = policyData.addressLists.flatMap((addressList) => addressList.items).map((item) => toAddress(item));

  const settings: AllowedSharesTransferRecipientsPolicySettings = {
    listId,
    items,
    isDisallowAll: items.length === 0,
  };

  return settings;
}

export function parseAllowedRedeemersForSpecificAssetsPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedRedeemersForSpecificAssetsPolicy">,
): AllowedRedeemersForSpecificAssetsPolicySettings {
  // Assume there can be only one list
  const listId = policyData.addressLists[0]?.id;
  const items = policyData.addressLists.flatMap((addressList) => addressList.items).map((item) => toAddress(item));

  const settings: AllowedRedeemersForSpecificAssetsPolicySettings = {
    listId,
    items,
    isDisallowAll: items.length === 0,
  };

  return settings;
}

export function parseAllowedExternalPositionTypesPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedExternalPositionTypesPolicy">,
  externalPositionTypes: { id: string; label: string }[],
  contracts: ReleaseContracts,
): AllowedExternalPositionTypesPolicySettings {
  const items = parseExternalPositionTypes({
    contracts,
    externalPositionTypes,
    externalPositionTypeIds: policyData.externalPositionTypes,
  });

  const settings: AllowedExternalPositionTypesPolicySettings = {
    items,
    isDisallowAll: items.length === 0,
  };

  return settings;
}

export function parseAllowedAssetsForRedemptionPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedAssetsForRedemptionPolicy">,
  getAssetsByAddresses: (addresses: readonly (string | undefined)[] | undefined) => Asset[],
): AllowedAssetsForRedemptionPolicySettings {
  // Assume there can be only one list
  const listId = policyData.addressLists[0]?.id;
  const items = getAssetsByAddresses(policyData.addressLists.flatMap((addressList) => addressList.items));

  const settings: AllowedAssetsForRedemptionPolicySettings = {
    listId,
    items,
    inKindRedemptionOnly: items.length === 0,
  };

  return settings;
}

export function parseAllowedAdaptersPerManagerPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedAdaptersPerManagerPolicy">,
  environment: Environment<Version.SULU>,
): AllowedAdaptersPerManagerPolicySettings {
  const settings: AllowedAdaptersPerManagerPolicySettings = policyData.userAddressLists.map((userAddressList) => {
    return {
      address: {
        value: toAddress(userAddressList.userAddress),
      },
      protocols: addressesToAdaptersIncludingLegacy({
        addresses: userAddressList.addressLists.flatMap((addressList) => addressList.items.map(toAddress)),
        environment,
      }),
    };
  });

  return settings;
}

export function parseAllowedExternalPositionTypesPerManagerPolicySettings(
  policyData: VaultPolicyQueryType<"AllowedExternalPositionTypesPerManagerPolicy">,
  externalPositionOptions: ExternalPositionOption[],
): AllowedExternalPositionTypesPerManagerPolicySettings {
  const settings: AllowedExternalPositionTypesPerManagerPolicySettings = policyData.userUintLists.map(
    (userUintList) => {
      return {
        address: {
          value: toAddress(userUintList.userAddress),
        },
        protocols: userUintList.uintLists
          .flatMap((uintList) =>
            uintList.items.map((item) =>
              externalPositionOptions.find((option) => option.externalPositionIdentifier.toString() === item),
            ),
          )
          .filter(isTruthy),
      };
    },
  );

  return settings;
}

export function parseDisallowAdapterIncomingAssetsPolicySettings(
  policyData: VaultPolicyQueryType<"DisallowedAdapterIncomingAssetsPolicy">,
  environment: Environment<Version.SULU>,
): DisallowedAdapterIncomingAssetsPolicySettings {
  // Assume there can be only one delegated list
  // TODO: handle cases with multiple address lists
  const listId = policyData.addressLists[0]?.id;

  const isDelegated = listId
    ? [environment.knownAddressLists.nonStandardPriceFeedAssets].includes(BigInt(listId))
    : false;

  const assetsInLists = policyData.addressLists.flatMap((addressList) => addressList.items.map(toAddress));

  const items = environment.getAssets().filter((asset) => assetsInLists.includes(asset.id));

  const settings: DisallowedAdapterIncomingAssetsPolicySettings = {
    listId,
    selectedOptionType: isDelegated
      ? VaultConfigPolicyListOption.DELEGATED
      : items.length === 0
        ? VaultConfigPolicyListOption.EMPTY_LIST
        : VaultConfigPolicyListOption.CUSTOM_LIST,
    items,
  };

  return settings;
}

export function parseMinAssetBalancesPostRedemptionPolicySettings(
  policyData: VaultPolicyQueryType<"MinAssetBalancesPostRedemptionPolicy">,
  environment: Environment<Version.SULU>,
): MinAssetBalancesPostRedemptionPolicySettings {
  const settings: MinAssetBalancesPostRedemptionPolicySettings = policyData.assetBalances.map((assetBalance) => {
    const asset = environment.getAsset(assetBalance.asset.id);

    return {
      token: asset,
      value: parseUnits(assetBalance.amount, asset.decimals),
    };
  });

  return settings;
}

export function parseMinMaxDepositPolicySettings(
  policyData: VaultPolicyQueryType<"MinMaxDepositPolicy">,
  denominationAssetDecimals: number,
): MinMaxDepositPolicySettings {
  const maxInvestmentAmount = parseUnits(policyData.maxDepositAmount, denominationAssetDecimals);
  const minInvestmentAmount = parseUnits(policyData.minDepositAmount, denominationAssetDecimals);

  const settings: MinMaxDepositPolicySettings = {
    minInvestmentAmount,
    isMinEnabled: minInvestmentAmount > 0n,
    maxInvestmentAmount,
    isMaxEnabled: maxInvestmentAmount > 0n,
    isRejectAll: minInvestmentAmount === 0n && maxInvestmentAmount === 0n,
  };

  return settings;
}

export function parseNoDepegOnRedeemSharesForSpecificAssetsPolicySettings(
  policyData: VaultPolicyQueryType<"NoDepegOnRedeemSharesForSpecificAssetsPolicy">,
): NoDepegOnRedeemSharesForSpecificAssetsPolicySettings {
  const { getAssetsByAddresses } = useGlobals();
  const assetConfigs: NoDepegOnRedeemSharesForSpecificAssetsPolicySettings = policyData.assets.map((asset, i) => {
    const referenceAsset = policyData.referenceAssets[i];
    const deviationTolerance = policyData.deviationTolerances[i];

    invariant(referenceAsset !== undefined, "Reference asset is undefined");

    const [peggedAsset, peggedReferenceAsset] = getAssetsByAddresses([asset, referenceAsset]);

    invariant(asset !== undefined && peggedAsset !== undefined, "Pegged asset is undefined");
    invariant(peggedReferenceAsset !== undefined, "Reference asset is undefined");
    invariant(deviationTolerance !== undefined, "Deviation tolerance is undefined");

    return {
      asset: peggedAsset,
      referenceAsset: peggedReferenceAsset,
      deviationTolerance: (Number(deviationTolerance) * 100).toString(),
    };
  });

  return assetConfigs;
}
