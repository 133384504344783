import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";

import { type Hex, decodeAbiParameters, parseAbiParameters, slice } from "viem";
import type { IntegrationHandler } from "./types";

interface ZeroExV2TakeOrderArgs {
  expirationTimeSeconds: bigint;
  feeRecipientAddress: string;
  makerAddress: string;
  makerAssetAmount: bigint;
  makerAssetAddress: string;
  makerFee: bigint;
  salt: bigint;
  senderAddress: string;
  signature: Hex;
  takerAddress: string;
  takerAssetAmount: bigint;
  takerAssetAddress: string;
  takerAssetFillAmount: bigint;
  takerFee: bigint;
}

export const zeroExV2TakeOrder: IntegrationHandler<ZeroExV2TakeOrderArgs> = {
  Description({ args: { makerAssetAmount, makerAssetAddress, takerAssetAmount, takerAssetAddress } }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAsset = environment.getAsset(takerAssetAddress);
    const buyAsset = environment.getAsset(makerAssetAddress);

    const title = "Private Market Maker";
    const exchangeIcon = getIntegrationIconUrl("0x.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: BigInt(takerAssetAmount.toString()),
        }),
      [assetPrices, takerAssetAmount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: BigInt(makerAssetAmount.toString()),
        }),
      [assetPrices, buyAsset, makerAssetAmount],
    );

    return (
      <>
        <TokenSwapTransactionSummary
          currency={currency}
          buyAsset={buyAsset}
          sellAsset={sellAsset}
          minIncomingAssetAmount={BigInt(makerAssetAmount.toString())}
          outgoingAssetAmount={BigInt(takerAssetAmount.toString())}
          buyValueInCurrency={buyValueInCurrency}
          sellValueInCurrency={sellValueInCurrency}
          title={title}
          exchangeIcon={exchangeIcon}
        />
      </>
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => {
    const [signedOrder, takerAssetFillAmount] = decodeAbiParameters(
      parseAbiParameters("bytes, uint256"),
      encodedCallArgs,
    );
    const [
      [makerAddress, takerAddress, feeRecipientAddress, senderAddress],
      [makerAssetAmount, takerAssetAmount, makerFee, takerFee, expirationTimeSeconds, salt],
      [makerAssetData, takerAssetData],
      signature,
    ] = decodeAbiParameters(parseAbiParameters("address[4], uint256[6], bytes[2], bytes"), signedOrder);

    const takerAssetAddress = slice(takerAssetData, 16);
    const makerAssetAddress = slice(makerAssetData, 16);

    return {
      expirationTimeSeconds,
      feeRecipientAddress,
      makerAddress,
      makerAssetAddress,
      makerAssetAmount,
      makerFee,
      salt,
      senderAddress,
      signature,
      takerAddress,
      takerAssetAddress,
      takerAssetAmount,
      takerAssetFillAmount,
      takerFee,
    };
  },
};
