import type { ApolloClient } from "@apollo/client";
import { Utils } from "@enzymefinance/sdk";
import type { QueryClient } from "@tanstack/react-query";
import type { Observable } from "rxjs";
import { fromEvent } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import type { ActorRef, Interpreter, MachineConfig, MachineOptions } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import { z } from "zod";

import { safeStringifyJSON } from "@enzymefinance/utils";
import type { PublicClient } from "viem";
import { createTransactionMachine } from "./TransactionMachine";

const TransactionStatusSchema = z.enum(["PENDING", "REPLACED", "REVERTED", "PASSED", "FINISHED", "ACKNOWLEDGED"]);

const transactionLogSchema = z.array(
  z.object({
    address: z.string(),
    blockHash: z.string(),
    blockNumber: z.bigint(),
    data: z.string(),
    logIndex: z.number(),
    removed: z.boolean(),
    topics: z.array(z.string()),
    transactionHash: z.string(),
    transactionIndex: z.number(),
  }),
);

const TransactionSchema = z.object({
  block: z.optional(z.bigint()),
  data: z.string(),
  from: z.string(),
  hash: z.string(),
  isGasRelayer: z.boolean(),
  logs: z.optional(transactionLogSchema),
  nonce: z.number(),
  status: z.optional(TransactionStatusSchema),
  to: z.string(),
});

export const TransactionStatus = TransactionStatusSchema.Enum;
export type Transaction = z.infer<typeof TransactionSchema>;

export type MachineInterpreter = Interpreter<Context, any, Events, any, any>;

interface Context {
  signer?: string;
  transactions: ActorRef<any>[];
}

export enum EventType {
  AUTH = "AUTH",
  SYNC = "SYNC",
  ADD = "ADD",
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
}

interface AuthEvent {
  type: EventType.AUTH;
  signer?: string;
}

interface SyncEvent {
  type: EventType.SYNC;
  transactions: Transaction[];
}

interface AddEvent {
  type: EventType.ADD;
  transaction: Transaction;
}

interface RemoveEvent {
  type: EventType.REMOVE;
  transaction: Transaction;
}

interface UpdateEvent {
  type: EventType.UPDATE;
  transaction: Transaction;
}

type Events = AddEvent | AuthEvent | RemoveEvent | SyncEvent | UpdateEvent;

function deserialize(raw: any): Transaction[] {
  try {
    const deserialized = JSON.parse(raw);
    const values = Array.isArray(deserialized) ? deserialized : [];

    return values
      .filter((value) => TransactionSchema.safeParse(value).success)
      .map((value) => TransactionSchema.parse(value));
  } catch {
    return [];
  }
}

function serialize(transactions: Transaction[]): string {
  try {
    const values = transactions
      .filter((value) => TransactionSchema.safeParse(value).success)
      .map((value) => TransactionSchema.parse(value));

    return safeStringifyJSON(values);
  } catch {
    return safeStringifyJSON([]);
  }
}

const machineDeclaration: MachineConfig<Context, any, Events> = {
  context: {
    signer: undefined,
    transactions: [],
  },
  id: "transactionManager",
  initial: "unknown",
  on: {
    [EventType.AUTH]: {
      actions: ["setSigner"],
      cond: "isDifferentSigner",
      target: "unknown",
    },
    [EventType.ADD]: {
      actions: ["startMonitoring", "writeToStorage"],
    },
    [EventType.REMOVE]: {
      actions: ["stopMonitoring", "writeToStorage"],
    },
    [EventType.UPDATE]: {
      actions: ["writeToStorage"],
    },
  },
  states: {
    active: {
      exit: ["clearChildren"],
      invoke: {
        id: "storage",
        src: "watchStorage",
      },
      on: {
        [EventType.SYNC]: {
          actions: ["syncFromStorage"],
        },
      },
    },
    idle: {},
    unknown: {
      always: [{ cond: "hasSigner", target: "active" }, { target: "idle" }],
    },
  },
};

export function createTransactionManagerMachine(
  client: PublicClient,
  subgraphObservable: Observable<number>,
  apolloObservable: Observable<ApolloClient<any>>,
  reactQueryObservable: Observable<QueryClient>,
) {
  const machineOptions: Partial<MachineOptions<Context, Events>> = {
    actions: {
      clearChildren: assign({
        transactions: (context) => {
          context.transactions.forEach((item) => item.stop?.());

          return [];
        },
      }),
      invalidateQueries: (_, event) => {
        if (event.type !== EventType.UPDATE) {
          return;
        }

        // TODO: Implement this.
        console.info("Invalidating queries because of transaction status change", event.transaction);
      },
      setSigner: assign({
        signer: (context, event) => {
          if (event.type !== EventType.AUTH) {
            return context.signer;
          }

          return event.signer || undefined;
        },
      }),
      startMonitoring: assign({
        transactions: (context, event) => {
          if (event.type !== EventType.ADD) {
            return context.transactions;
          }

          // Check if this transaction is already being monitored.
          if (context.transactions.some((item) => item.id === event.transaction.hash)) {
            return context.transactions;
          }

          // Bail out if it belongs to a different signer.
          if (!Utils.Address.safeSameAddress(event.transaction.from, context.signer)) {
            return context.transactions;
          }

          // Otherwise, spawn a child machine for this transaction.
          const machine = createTransactionMachine(
            client,
            event.transaction,
            subgraphObservable,
            apolloObservable,
            reactQueryObservable,
          );

          const added = spawn(machine, event.transaction.hash);

          return [added, ...context.transactions];
        },
      }),
      stopMonitoring: assign({
        transactions: (context, event) => {
          if (event.type !== EventType.REMOVE) {
            return context.transactions;
          }

          return context.transactions.filter((item) => {
            if (item.id === event.transaction.hash) {
              item.stop?.();

              return false;
            }

            return true;
          });
        },
      }),
      syncFromStorage: assign({
        transactions: (context, event) => {
          if (event.type !== EventType.SYNC) {
            return context.transactions;
          }

          const next = event.transactions.map((item) => {
            const current = context.transactions.find((inner) => inner.id === item.hash);

            if (!current) {
              const machine = createTransactionMachine(
                client,
                item,
                subgraphObservable,
                apolloObservable,
                reactQueryObservable,
              );

              return spawn(machine, item.hash);
            }

            return current;
          });

          const removed = context.transactions.filter((item) => !next.some((inner) => inner.id === item.id));

          removed.forEach((item) => item.stop?.());

          return next;
        },
      }),
      writeToStorage: (_, event) => {
        if (!(event.type === EventType.UPDATE || event.type === EventType.ADD || event.type === EventType.REMOVE)) {
          return;
        }

        const transaction = event.transaction;
        const key = `${event.transaction.from.toLowerCase()}:transactions`;
        let array = deserialize(window.localStorage.getItem(key));

        if (event.type === EventType.REMOVE) {
          array = array.filter((item) => item.hash !== transaction.hash);
        } else {
          const index = array.findIndex((item) => item.hash === transaction.hash);

          if (index === -1) {
            array = [transaction, ...array];
          } else {
            array[index] = transaction;
          }
        }

        window.localStorage.setItem(key, serialize(array));
      },
    },
    guards: {
      hasSigner: (context) => !!context.signer,
      isDifferentSigner: (context, event) => {
        if (event.type === EventType.AUTH) {
          return context.signer?.toLowerCase() !== event.signer?.toLowerCase();
        }

        return false;
      },
    },
    services: {
      watchStorage: (context) => (callback) => {
        if (!context.signer) {
          return;
        }

        const key = `${context.signer.toLowerCase()}:transactions`;
        const initial = deserialize(window.localStorage.getItem(key));
        const observable = fromEvent<StorageEvent>(window, "storage").pipe(
          // Only process this event if it comes from a different browser tab.
          filter((event) => !document.hasFocus() && event.key === key),
          map((event) => deserialize(event.newValue)),
          startWith(initial),
        );

        const subscription = observable.subscribe((transactions) => {
          callback({
            transactions,
            type: EventType.SYNC,
          });
        });

        return () => subscription.unsubscribe();
      },
    },
  };

  return createMachine<Context, Events>(machineDeclaration, machineOptions);
}
