import type { Address } from "@enzymefinance/environment";
import { Badge, NumberDisplay, Tooltip } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency } from "components/settings/Settings";
import { useVaultPerformanceLatestQuery, useVaultUntrackedBalancesQuery } from "queries/backend";
import { useMemo } from "react";
import { client } from "utils/backend";
import { untrackedValueThreshold } from "utils/vault";
import { isAddressEqual } from "viem";

import { useVault } from "../VaultProvider";

interface UntrackedAssetsWarningProps {
  vaultId: Address;
}

export function UntrackedAssetsWarning({ vaultId }: UntrackedAssetsWarningProps) {
  const { deployment } = useNetwork();
  const currency = useCurrency();
  const { id, vaultCurrency, vaultAssetPrices } = useVault();

  const vaultUntrackedBalancesQuery = useVaultUntrackedBalancesQuery({
    client,
    variables: { currency: vaultCurrency, network: deployment, vault: id },
  });

  const untrackedAssets = useMemo(
    () =>
      vaultUntrackedBalancesQuery.data?.vaultUntrackedBalances
        .filter((item) => item.balance > 0)
        .filter((item) => item.valid) ?? [],
    [vaultUntrackedBalancesQuery],
  );

  const vaultPerformanceLatest = useVaultPerformanceLatestQuery({
    client,
    variables: { currency: vaultCurrency, network: deployment, vault: vaultId },
  });

  const totalUntrackedAssetsValue = useMemo(() => {
    const currencyValues = untrackedAssets.map((untrackedAsset) => {
      const balance = untrackedAsset.balance;
      const assetPrice = vaultAssetPrices.find((item) => isAddressEqual(item.id, untrackedAsset.asset))?.price ?? 0;

      return balance * assetPrice;
    });

    const totalCurrencyValue = currencyValues.reduce((previous, current) => previous + current, 0);

    return totalCurrencyValue;
  }, [vaultAssetPrices, untrackedAssets]);

  const isSharesPriceValid = vaultPerformanceLatest.data?.vaultPerformanceLatest?.valid;
  const aum = vaultPerformanceLatest.data?.vaultPerformanceLatest?.grossAssetValue ?? 0;

  const untrackedAssetsAumPercentage = aum > 0 ? totalUntrackedAssetsValue / aum : 0;
  const displayWarning = untrackedAssetsAumPercentage > untrackedValueThreshold && isSharesPriceValid;

  return displayWarning ? (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge appearance="warning">High Value in Untracked Assets</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="flex max-w-xs flex-col space-y-4 p-4 text-sm font-normal">
          <div className="space-y-2">
            <span>
              This vault holds <NumberDisplay value={totalUntrackedAssetsValue} numberFormat={{ currency }} /> in
              untracked assets, (
              <NumberDisplay numberFormat={{ style: "percent" }} value={untrackedAssetsAumPercentage} /> of the
              vault&apos;s AUM).
            </span>
            <p>Untracked assets are not included in the share price.</p>
          </div>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}
