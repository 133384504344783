import { TokenLabel } from "@enzymefinance/ethereum-ui";
import { type RemoveTrackedAssetsArgs, removeTrackedAssetsDecode } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { ExtensionHandler } from "./types";

export const removeTrackedAssets: ExtensionHandler<RemoveTrackedAssetsArgs> = {
  Description({ args }) {
    const { removeAssets: addresses } = args;
    const { getAssetsByAddresses } = useGlobals();
    const removedAssets = getAssetsByAddresses(addresses);

    return <>Remove tracked assets ({removedAssets.map((asset) => asset.symbol).join(", ")}) </>;
  },
  Label() {
    return <>Remove tracked assets</>;
  },
  Summary({ args }) {
    const { removeAssets: addresses } = args;
    const { getAssetsByAddresses } = useGlobals();
    const removedAssets = getAssetsByAddresses(addresses);

    return (
      <div>
        <p>Remove tracked assets:</p>
        <div className="space-y-2">
          {removedAssets.map((asset) => (
            <TokenLabel key={asset.id} asset={asset} />
          ))}
        </div>
      </div>
    );
  },
  decodeExtensionArgs: (encodedCallArgs) => removeTrackedAssetsDecode(encodedCallArgs),
};
