import { NumberInput } from "@enzymefinance/hook-form";
import { Badge, DurationDisplay } from "@enzymefinance/ui";
import { numberInput } from "@enzymefinance/validation";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import duration from "dayjs/plugin/duration.js";
import { z } from "zod";

import { Depositor } from "@enzymefinance/sdk";
import { zeroAddress } from "viem";
import { InlineLink } from "../../routing/Link";
import { VaultConfigFieldName } from "./VaultConfig";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "./VaultConfigTypes";
import { VaultConfigType } from "./VaultConfigTypes";

dayjs.extend(customParseFormat);

dayjs.extend(duration);

const maxHours = Math.floor(dayjs.duration({ years: 10 }).asHours());

export const sharesActionTimelockSchema = numberInput(
  z
    .number()
    .nonnegative({ message: "Timelock cannot be negative." })
    .max(maxHours, {
      message: `Timelock cannot be greater than ${maxHours} hours.`,
    }),
);

function sharesActionTimelockFormFields() {
  return (
    <NumberInput
      label={sharesActionTimelock.label}
      name={VaultConfigFieldName.SHARES_ACTION_TIMELOCK}
      numberFormat={{ unit: "hour" }}
    />
  );
}

export function SharesActionTimelockDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.SHARES_ACTION_TIMELOCK>) {
  return <DurationDisplay seconds={Number(settings.toString())} />;
}

export function SharesActionTimelockDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.SHARES_ACTION_TIMELOCK>) {
  return <DurationDisplay seconds={Number(settings)} />;
}

export const sharesActionTimelock: VaultConfig<VaultConfigType.SHARES_ACTION_TIMELOCK> = {
  // Does not have an address
  address: () => zeroAddress,
  disableable: false,
  display: SharesActionTimelockDisplay,
  displaySubgraph: SharesActionTimelockDisplaySubgraph,
  editable: false,
  encode: () => null as never,
  fetch: ({ comptroller, client }) => {
    return Depositor.getSharesActionTimelock(client, { comptrollerProxy: comptroller });
  },
  formFields: sharesActionTimelockFormFields,
  label: "Shares Lock-Up Period",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Defines the amount of time that must pass after a user&rsquo;s last receipt of shares via deposit before that
        user is allowed to either redeem or transfer any shares. This is an arbitrage protection, and funds that have
        untrusted investors should use a non-zero value. The recommended value is 24 hours.
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  publicDescription: (
    <p>
      The duration (defined as elapsed time since your last receipt of newly minted shares) you must hold this
      vault&rsquo;s shares before being able to redeem or transfer them.{" "}
      <InlineLink to="https://docs.enzyme.finance/managers/setup/redemptions#shares-lockup-up-period" appearance="link">
        Shares Lock-Up Period
      </InlineLink>{" "}
      prevents various types of arbitrage.
    </p>
  ),
  type: VaultConfigType.SHARES_ACTION_TIMELOCK,
  validationSchema: sharesActionTimelockSchema,
};
