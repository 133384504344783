import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

interface CreateMigrationRequest {
  vaultProxy: Address;
  denominationAsset: Address;
  sharesActionTimelock: bigint;
  feeManagerConfigData: Hex;
  policyManagerConfigData: Hex;
  bypassPrevReleaseFailure: boolean;
}

export const createMigrationRequest: FunctionConfig<CreateMigrationRequest> = {
  signature:
    "function createMigrationRequest(address vaultProxy, address denominationAsset, uint256 sharesActionTimelock, bytes feeManagerConfigData, bytes policyManagerConfigData, bool bypassPrevReleaseFailure) returns (address comptrollerProxy)",

  Description() {
    return <>Create a vault upgrade request.</>;
  },

  Label() {
    return <>Create Upgrade Request</>;
  },
};
