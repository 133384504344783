import { AssetType, Environment } from "@enzymefinance/environment";
import { BigIntDisplay, TokenLabel } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Alert } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { client } from "utils/backend";
import { getDefaultExtensionSummary } from "..";
import { useMapleConvertSharesToExitAssetsQuery } from "../../../../../queries/backend";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const mapleLend: ExternalPositionHandler<Integrations.Maple.LendV2Args> = {
  Description({ args: { pool, liquidityAssetAmount } }) {
    const { environment } = useGlobals();
    const maplePool = environment.getAsset(pool);

    if (maplePool.type !== AssetType.MAPLE_V2) {
      throw new Error(`Wrong pool address ${pool}`);
    }

    const liquidityAsset = environment.getAsset(maplePool.underlying);

    return (
      <>
        Lending{" "}
        <BigIntDisplay
          value={liquidityAssetAmount}
          decimals={liquidityAsset.decimals}
          numberFormat={{ currency: liquidityAsset.symbol }}
        />{" "}
        on {maplePool.name} pool
      </>
    );
  },
  Label() {
    return <>Lend on Maple</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Maple.lendV2Decode(encodedCallArgs),
};

export const mapleRequestRedeem: ExternalPositionHandler<Integrations.Maple.RequestRedeemV2Args> = {
  Description({ args: { pool, poolTokenAmount } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();

    const maplePool = environment.getAsset(pool);

    const mapleConvertSharesToExitAssetsQuery = useMapleConvertSharesToExitAssetsQuery({
      client,
      variables: {
        pool,
        shares: poolTokenAmount.toString(),
        network: deployment,
      },
    });

    if (poolTokenAmount === 0n) {
      return <>Re-request withdrawal of the same amount on {maplePool.name} pool</>;
    }

    if (mapleConvertSharesToExitAssetsQuery.loading) {
      return null;
    }

    if (maplePool.type !== AssetType.MAPLE_V2) {
      throw new Error(`Wrong pool address ${pool}`);
    }

    const liquidityAsset = environment.getAsset(maplePool.underlying);

    if (mapleConvertSharesToExitAssetsQuery.error !== undefined) {
      return <Alert appearance="error">{mapleConvertSharesToExitAssetsQuery.error.message}</Alert>;
    }

    return (
      <>
        Request additional redeem of approximately{" "}
        <BigIntDisplay
          value={
            mapleConvertSharesToExitAssetsQuery.data?.mapleConvertSharesToExitAssets?.assets
              ? BigInt(mapleConvertSharesToExitAssetsQuery.data.mapleConvertSharesToExitAssets.assets)
              : undefined
          }
          decimals={liquidityAsset.decimals}
          numberFormat={{ currency: liquidityAsset.symbol }}
        />{" "}
        on {maplePool.name} pool
      </>
    );
  },
  Label({ args: { poolTokenAmount } }) {
    if (poolTokenAmount === 0n) {
      return <>Re-request withdrawal</>;
    }

    return <>Request redeeem on Maple</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Maple.requestRedeemV2Decode(encodedCallArgs),
};

export const mapleCancelRedeem: ExternalPositionHandler<Integrations.Maple.CancelRedeemV2Args> = {
  Description({ args: { pool, poolTokenAmount } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();

    const maplePool = environment.getAsset(pool);

    const mapleConvertSharesToExitAssetsQuery = useMapleConvertSharesToExitAssetsQuery({
      client,
      variables: {
        pool,
        shares: poolTokenAmount.toString(),
        network: deployment,
      },
    });

    if (poolTokenAmount === 0n) {
      return <>Re-request withdrawal of the same amount on {maplePool.name} pool</>;
    }

    if (mapleConvertSharesToExitAssetsQuery.loading) {
      return null;
    }

    if (maplePool.type !== AssetType.MAPLE_V2) {
      throw new Error(`Wrong pool address ${pool}`);
    }

    const liquidityAsset = environment.getAsset(maplePool.underlying);

    if (mapleConvertSharesToExitAssetsQuery.error !== undefined) {
      return <Alert appearance="error">{mapleConvertSharesToExitAssetsQuery.error.message}</Alert>;
    }

    return (
      <>
        Decrease requested redeem amount redeem of approximately{" "}
        <BigIntDisplay
          value={
            mapleConvertSharesToExitAssetsQuery.data?.mapleConvertSharesToExitAssets?.assets
              ? BigInt(mapleConvertSharesToExitAssetsQuery.data.mapleConvertSharesToExitAssets.assets)
              : undefined
          }
          decimals={liquidityAsset.decimals}
          numberFormat={{ currency: liquidityAsset.symbol }}
        />{" "}
        on {maplePool.name} pool
      </>
    );
  },
  Label({ args: { poolTokenAmount } }) {
    if (poolTokenAmount === 0n) {
      return <>Re-request withdrawal</>;
    }

    return <>Decrease requested redeem amount on Maple</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Maple.cancelRedeemV2Decode(encodedCallArgs),
};

export const mapleRedeem: ExternalPositionHandler<Integrations.Maple.RedeemV2Args> = {
  Description({ args: { pool, poolTokenAmount } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();

    const maplePool = environment.getAsset(pool);

    const mapleConvertSharesToExitAssetsQuery = useMapleConvertSharesToExitAssetsQuery({
      client,
      variables: {
        pool,
        shares: poolTokenAmount.toString(),
        network: deployment,
      },
    });

    if (mapleConvertSharesToExitAssetsQuery.loading) {
      return null;
    }

    if (maplePool.type !== AssetType.MAPLE_V2) {
      throw new Error(`Wrong pool address ${pool}`);
    }

    const liquidityAsset = environment.getAsset(maplePool.underlying);

    if (mapleConvertSharesToExitAssetsQuery.error !== undefined) {
      return <Alert appearance="error">{mapleConvertSharesToExitAssetsQuery.error.message}</Alert>;
    }

    return (
      <>
        Try to redeem{" "}
        <BigIntDisplay
          value={
            mapleConvertSharesToExitAssetsQuery.data?.mapleConvertSharesToExitAssets?.assets
              ? BigInt(mapleConvertSharesToExitAssetsQuery.data.mapleConvertSharesToExitAssets.assets)
              : undefined
          }
          decimals={liquidityAsset.decimals}
          numberFormat={{ currency: liquidityAsset.symbol }}
        />{" "}
        on {maplePool.name} pool. Redeemed amount could be equal less, if pool doesn&apos;t have enough redeemable
        assets.
      </>
    );
  },
  Label() {
    return <>Redeem on Maple</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Maple.redeemV2Decode(encodedCallArgs),
};

export const mapleClaimRewards: ExternalPositionHandler<Integrations.Maple.ClaimRewardsV1Args> = {
  Description() {
    const { environment } = useGlobals();

    if (!Environment.isDeploymentEthereum(environment)) {
      return null;
    }

    return (
      <div className="flex items-center space-x-2">
        <span>Claim</span> <TokenLabel asset={environment.namedTokens.mpl} />
      </div>
    );
  },
  Label() {
    return <>Claim rewards on Maple</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Maple.claimRewardsV1Decode(encodedCallArgs),
};

export const createMapleExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Maple external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = mapleLend.decodeExternalPositionArgs(actionArgs);

    return <mapleLend.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Maple external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = mapleLend.decodeExternalPositionArgs(actionArgs);

    return <mapleLend.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Maple external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = mapleLend.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(mapleLend.Label, mapleLend.Description);

    return <Summary args={args} />;
  },
};
