import { Policies, Policy } from "@enzymefinance/sdk/Configuration";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useMinMaxInvestmentsPolicySettings = createUseValidatedClientQuery(
  ["min-max-investment-policy-settings"],
  {
    paramsSchema: z.object({
      comptrollerProxy: address({ allowZeroAddress: false }),
      minMaxInvestmentsPolicy: address({ allowZeroAddress: false }),
      policyManager: address({ allowZeroAddress: false }),
    }),
    responseSchema: z
      .object({
        minInvestmentAmount: bigint(),
        maxInvestmentAmount: bigint(),
      })
      .nullable(),
    queryFn: async (params, client) => {
      const hasPolicyEnabled = await Policy.isEnabled(client, {
        policy: params.minMaxInvestmentsPolicy,
        policyManager: params.policyManager,
        comptrollerProxy: params.comptrollerProxy,
      });

      if (!hasPolicyEnabled) {
        return null;
      }

      return Policies.MinMaxInvestment.getSettings(client, {
        minMaxInvestmentPolicy: params.minMaxInvestmentsPolicy,
        comptrollerProxy: params.comptrollerProxy,
      });
    },
  },
);
