import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { useSigner } from "components/connection/Connection.js";
import { useRedemptionQueueRedemptionInfoQuery } from "queries/core";
import { getRedemptionQueue } from "utils/redemptionQueue";
import { isAddressEqual, parseUnits } from "viem";
import { useNetwork } from "../../components/providers/NetworkProvider";
import { useTokenBalance } from "./useTokenBalance";

interface RedemptionQueueRedemptionInfoProps {
  vaultId: Address;
  comptroller: Address;
  account: Address;
}

export interface SharedRedemptionQueueRedemptionInfo {
  redemptionQueueAddress: Address;
  redemptionAssetAddress: Address;
  depositedShares: bigint;
  shutdown: boolean;
  requests: ReadonlyArray<{ id: string; requestId: bigint; shares: bigint }>;
}

export function useSingleAssetRedemptionQueueInfo({
  vaultId,
  comptroller,
  account,
}: RedemptionQueueRedemptionInfoProps): {
  data: SharedRedemptionQueueRedemptionInfo;
  loading: boolean;
} | null {
  const [signerAddress] = useSigner();
  const { client } = useNetwork();

  const { data: redemptionInfo, loading } = useRedemptionQueueRedemptionInfoQuery({
    variables: {
      vault: vaultId,
      comptroller,
      account,
    },
    skip: signerAddress === undefined,
  });

  const tokenBalanceQuery = useTokenBalance(client, { account: signerAddress, token: toAddress(vaultId) });

  if (redemptionInfo === undefined) {
    return null;
  }

  if (!redemptionInfo.allowedRedeemersForSpecificAssetsPolicies[0]) {
    // We skip the policy requirement for the Renzo ETH vault (0xc176bfb57398b4876ea6cf05d9de202120c0b03d)
    if (isAddressEqual(vaultId, "0xc176bfb57398b4876ea6cf05d9de202120c0b03d")) {
      const redemptionQueue = redemptionInfo.singleAssetRedemptionQueues[0];
      if (redemptionQueue === undefined) {
        return null;
      }
      return {
        loading,
        data: {
          redemptionQueueAddress: toAddress(redemptionQueue.id),
          depositedShares: tokenBalanceQuery.data ?? 0n,
          redemptionAssetAddress: toAddress(redemptionQueue.redemptionAsset),
          shutdown: redemptionQueue.shutdown,
          requests: redemptionQueue.requests.map((request) => ({
            id: request.id,
            requestId: BigInt(request.requestId),
            shares: BigInt(parseUnits(request.sharesAmount, 18)),
          })),
        },
      };
    }

    return null;
  }

  if (!redemptionInfo.singleAssetRedemptionQueues[0]) {
    return null;
  }

  const allowedRedeemersForSpecificAssetsPolicy = redemptionInfo.allowedRedeemersForSpecificAssetsPolicies[0];

  const redemptionQueue = getRedemptionQueue({
    allowedRedeemersForSpecificAssetsPolicy,
    singleAssetRedemptionQueues: redemptionInfo.singleAssetRedemptionQueues,
  });

  if (redemptionQueue === null) {
    return null;
  }

  const depositedShares = tokenBalanceQuery.data ?? 0n;

  return {
    loading,
    data: {
      redemptionQueueAddress: toAddress(redemptionQueue.id),
      depositedShares,
      redemptionAssetAddress: toAddress(redemptionQueue.redemptionAsset),
      shutdown: redemptionQueue.shutdown,
      requests: redemptionQueue.requests.map((request) => ({
        id: request.id,
        requestId: BigInt(request.requestId),
        shares: BigInt(parseUnits(request.sharesAmount, 18)),
      })),
    },
  };
}
