import type { FunctionConfig } from "utils/functionsTypes";

export const deployGasRelayPaymaster: FunctionConfig<{}> = {
  signature: "function deployGasRelayPaymaster() returns ()",

  Description() {
    return <>Enable Gas Relayer.</>;
  },

  Label() {
    return <>Enable Gas Relayer and deposit minimal amount for future gas usage.</>;
  },
};
