import type { Address } from "@enzymefinance/environment";
import { isActiveExternalPosition } from "@enzymefinance/sdk/Portfolio";
import { address } from "@enzymefinance/validation";
import type { PublicClient } from "viem";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

function fetchExternalPositionIsActive(params: { vault: Address; externalPosition: Address }, client: PublicClient) {
  return isActiveExternalPosition(client, {
    externalPosition: params.externalPosition,
    vaultProxy: params.vault,
  });
}

export const useExternalPositionIsActive = createUseValidatedClientQuery(["external-position-is-active"], {
  paramsSchema: z.object({
    externalPosition: address({ allowZeroAddress: false }),
    vault: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: (params, client) => fetchExternalPositionIsActive(params, client),
});

export const useMultiExternalPositionIsActive = createUseValidatedClientQuery(["multi-external-position-is-active"], {
  paramsSchema: z.object({
    externalPositions: z.array(address({ allowZeroAddress: false })),
    vault: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.array(z.boolean()),
  queryFn: (params, client) =>
    Promise.all(
      params.externalPositions.map((externalPosition) =>
        fetchExternalPositionIsActive({ externalPosition, vault: params.vault }, client),
      ),
    ),
});
