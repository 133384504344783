import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

export const yearnV2Lend: IntegrationHandler<Integrations.YearnV2.LendArgs> = {
  Description({ args: { yVault, depositAmount, minIncomingSharesAmount } }) {
    const { environment } = useGlobals();

    const yVaultToken = environment.getAsset(yVault);
    const underlyingToken =
      yVaultToken.type === AssetType.YEARN_VAULT_V2 ? environment.getAsset(yVaultToken.underlying) : undefined;

    return (
      <>
        Lending on Yearn (converting{" "}
        <BigIntDisplay
          value={depositAmount}
          decimals={underlyingToken?.decimals}
          numberFormat={{ currency: underlyingToken?.symbol }}
        />{" "}
        into{" "}
        <BigIntDisplay
          value={minIncomingSharesAmount}
          decimals={yVaultToken.decimals}
          numberFormat={{ currency: yVaultToken.symbol }}
        />
      </>
    );
  },
  Label() {
    return <>Lend on Yearn</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.YearnV2.lendDecode(encodedCallArgs),
};

export const yearnV2Redeem: IntegrationHandler<Integrations.YearnV2.RedeemArgs> = {
  Description({ args: { yVault, maxOutgoingSharesAmount, minIncomingUnderlyingAmount } }) {
    const { environment } = useGlobals();

    const yVaultToken = environment.getAsset(yVault);
    const underlyingToken =
      yVaultToken.type === AssetType.YEARN_VAULT_V2 ? environment.getAsset(yVaultToken.underlying) : undefined;

    return (
      <>
        Redeeming on Yearn (converting{" "}
        <BigIntDisplay
          value={maxOutgoingSharesAmount}
          decimals={yVaultToken.decimals}
          numberFormat={{ currency: yVaultToken.symbol }}
        />{" "}
        into{" "}
        <BigIntDisplay
          value={minIncomingUnderlyingAmount}
          decimals={underlyingToken?.decimals}
          numberFormat={{ currency: underlyingToken?.symbol }}
        />
      </>
    );
  },
  Label() {
    return <>Redeem on Yearn</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.YearnV2.redeemDecode(encodedCallArgs),
};
