import { ProtocolLabel } from "@enzymefinance/ethereum-ui";
import { useGetExternalPositionById } from "utils/hooks/useGetExternalPositionById";

interface PolicyExternalPositionListProps {
  ids: string[];
}

export function PolicyExternalPositionList({ ids }: PolicyExternalPositionListProps) {
  const getExternalPositionById = useGetExternalPositionById();

  return ids.length > 0 ? (
    <div className="space-y-2">
      {ids.map((id) => {
        const externalPosition = getExternalPositionById(id);

        return (
          <ProtocolLabel
            key={id}
            name={externalPosition?.label ?? "Unknown external position"}
            url={externalPosition?.icon}
          />
        );
      })}
    </div>
  ) : (
    <p>No external positions allowed.</p>
  );
}
