import type { Address } from "@enzymefinance/environment";
import { ExclamationCircleIcon } from "@enzymefinance/icons/solid";
import { Badge, Icon, Tooltip } from "@enzymefinance/ui";
import { useVaultFeesQuery } from "queries/core";

interface VaultUnknownFeeWarningProps {
  comptrollerId: Address;
}

export function VaultUnknownFeeWarning({ comptrollerId }: VaultUnknownFeeWarningProps) {
  const vaultFeesQuery = useVaultFeesQuery({
    variables: { comptroller: comptrollerId },
  });

  const hasUnknownFee = vaultFeesQuery.data?.comptroller?.fees.some((fee) => fee.feeType === "Unknown") ?? false;

  if (!hasUnknownFee) {
    return null;
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center space-x-1 p-2">
        <Icon className="text-warning" icon={ExclamationCircleIcon} />
        <Badge>Unknown Fee</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="flex max-w-xs flex-col space-y-4 p-4 text-sm">
          <span>
            This vault has an unknown fee. Please make sure to only deposit into this vault if you know and understand
            in detail how the unknown fee works, otherwise you may potentially lose your whole deposit.
          </span>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  );
}
