import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import type { DocumentNode } from "graphql";
import { Kind } from "graphql";
import introspection from "queries/backend.introspection";

function isMutation(query: DocumentNode) {
  const definition = getMainDefinition(query);

  if (definition.kind === Kind.OPERATION_DEFINITION) {
    // Check if this is a mutation.
    if (definition.operation === "mutation") {
      return true;
    }
  }

  return false;
}

const cache = new InMemoryCache({ addTypename: true, possibleTypes: introspection.possibleTypes });
const csrf = setContext((request, context) => {
  if (!isMutation(request.query)) {
    return context;
  }

  return {
    ...context,
    headers: {
      ...context.headers,
      "x-csrf": null,
    },
  };
});

const retryLink = new RetryLink({ attempts: { max: 2 } });
const uploadLink = createUploadLink({ uri: "/api/graphql" });
const link = ApolloLink.from([retryLink, csrf, uploadLink]);

export const client = new ApolloClient({
  cache,
  defaultOptions: {
    query: {
      pollInterval: 60000,
    },
    watchQuery: {
      pollInterval: 60000,
    },
  },
  link,
});
