import { Asset } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useAllowance = createUseValidatedClientQuery(["allowance"], {
  paramsSchema: z.object({
    account: address({ allowZeroAddress: false }),
    spender: address({ allowZeroAddress: false }),
    token: address({ allowZeroAddress: false }),
  }),
  responseSchema: bigint(),
  queryFn: async (params, client) =>
    Asset.getAllowance(client, { asset: params.token, spender: params.spender, owner: params.account }),
});
