import type { FunctionConfig } from "utils/functionsTypes";

export const shutdownGasRelayPaymaster: FunctionConfig = {
  signature: "function shutdownGasRelayPaymaster() returns ()",

  Description() {
    return <>Shutdown Gas Relayer.</>;
  },

  Label() {
    return <>Shutdown Gas Relayer</>;
  },
};
