import { CopyToClipboard } from "@enzymefinance/ui";
import { safeStringifyJSON } from "@enzymefinance/utils";
import { isAddress } from "viem";
import type { Inputs } from "../../utils/functionsTypes";

function parseInputValue(inputValue: unknown): string {
  if (Array.isArray(inputValue)) {
    return inputValue.map((value) => parseInputValue(value)).join(",");
  }

  if (typeof inputValue === "bigint" || typeof inputValue === "number" || typeof inputValue === "string") {
    return inputValue.toString();
  }

  return safeStringifyJSON(inputValue);
}

function formatInputValue(inputValue: unknown): string {
  if (Array.isArray(inputValue)) {
    return inputValue.map((value) => formatInputValue(value)).join(",");
  }

  if (typeof inputValue === "string" && isAddress(inputValue)) {
    return `${inputValue.slice(0, 10)}...${inputValue.slice(-5)}`;
  }

  if (typeof inputValue === "bigint" || typeof inputValue === "number" || typeof inputValue === "string") {
    return inputValue.toString();
  }

  return safeStringifyJSON(inputValue);
}

export function TransactionInputDetails({ inputs }: Inputs) {
  return (
    <div className="bg-base-300 space-y-4 rounded-lg p-4 font-mono">
      {inputs.length > 0 ? (
        Object.keys(inputs).map((key) => {
          return (
            <div className="flex justify-between" key={key}>
              <span>{key}:</span>
              <span className="flex items-center space-x-2">
                <CopyToClipboard value={parseInputValue(inputs[key])}>
                  <CopyToClipboard.Content>{formatInputValue(inputs[key])}</CopyToClipboard.Content>
                  <CopyToClipboard.Icon title="Copy input value to clipboard" />
                </CopyToClipboard>
              </span>
            </div>
          );
        })
      ) : (
        <p>This function has no inputs.</p>
      )}
    </div>
  );
}
