import { ChevronRightIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactNode } from "react";
import { createElement } from "react";

import { Icon } from "../elements/Icon.js";
import { ScreenReaderText } from "../typography/ScreenReaderText.js";

export interface BreadcrumbsProps {
  children: ReactNode;
}

export function Breadcrumbs({ children }: BreadcrumbsProps) {
  return (
    // "Breadcrumb" label identifies the structure as a breadcrumb trail and makes it a navigation landmark so that it is easy to locate.
    // See: https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">{children}</ol>
    </nav>
  );
}

type BreadcrumbsItemProps<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">> =
  TLinkProps & {
    as: ComponentType<TLinkProps>;
    current?: boolean;
    icon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  };

function breadcrumbsItem<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">>({
  as,
  children,
  current = false,
  icon,
  ...props
}: BreadcrumbsItemProps<TLinkProps>) {
  const classes = classNames("text-sm font-medium hover:text-gray-700 dark:hover:text-gray-300 transition", {
    "text-base-neutral": !icon,
    "text-base-content": icon,
  });
  const textClasses = classNames("text-sm font-medium transition hover:text-gray-700 dark:hover:text-gray-300", {
    "text-heading-content": current,
    "text-base-neutral": !current,
  });

  return (
    <li className="flex">
      <div className="flex items-center space-x-2">
        {icon ? (
          createElement<any>(
            as,
            { "aria-current": current ? "page" : undefined, className: classes, ...props },
            <>
              <Icon aria-hidden={true} icon={icon} className="flex-none" />
              <ScreenReaderText>{children}</ScreenReaderText>
            </>,
          )
        ) : (
          <>
            <Icon aria-hidden={true} icon={ChevronRightIcon} className="text-base-content-inverse flex-none" />
            {createElement<any>(
              as,
              {
                "aria-current": current ? "page" : undefined,
                className: textClasses,
                ...props,
              },
              children,
            )}
          </>
        )}
      </div>
    </li>
  );
}

Breadcrumbs.Item = breadcrumbsItem;
