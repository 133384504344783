import { z } from "zod";

import { createUseValidatedQuery } from "./useValidatedQuery";

export const useVersion = createUseValidatedQuery(["version"], {
  paramsSchema: z.undefined(),
  responseSchema: z.object({ version: z.string() }),
  queryFn: async () => {
    const res = await fetch("/version.json");

    return res.json();
  },
});
