import { Alert } from "@enzymefinance/ui";
import { zeroAddress } from "viem";
import { z } from "zod";
import type { VaultConfig } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

export const unknownPolicy: VaultConfig<VaultConfigType.UNKNOWN_POLICY> = {
  address: () => zeroAddress,
  disableable: false,
  display: () => null,
  displaySubgraph: unknownVaultConfigDisplaySubgraph,
  editable: false,
  encode: () => null as never,
  fetch: async () => undefined,
  formFields: null as never,
  label: "Unknown Policy",
  managerDescription: (
    <p>
      This vault has an enabled policy that has not been vetted by the Enzyme Council. Policies that have not been
      vetted can potentially be used for the benefit of malicious vault managers. Only deposit into this vault if you
      are sure of what this policy does.
    </p>
  ),
  type: VaultConfigType.UNKNOWN_POLICY,
  validationSchema: z.never(),
};

function unknownVaultConfigDisplaySubgraph() {
  return (
    <Alert appearance="warning" title="Warning">
      This vault has an unknown policy. Please make sure to only deposit into this vault if you know and understand in
      detail how the unknown policy works, otherwise you may potentially lose your whole deposit.
    </Alert>
  );
}
