import type { ProtocolSelectProps as ProtocolSelectPropsBase } from "@enzymefinance/ethereum-ui";
import { ProtocolSelect as ProtocolSelectBase } from "@enzymefinance/ethereum-ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface ProtocolSelectProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<ProtocolSelectPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function ProtocolSelect<TFieldValues extends FieldValues = FieldValues>(
  props: ProtocolSelectProps<TFieldValues>,
) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <ProtocolSelectBase
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      onChange={onChange as any}
    />
  );
}
