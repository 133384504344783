import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Fee, Fees } from "@enzymefinance/sdk/Configuration";
import { Badge, NumberDisplay, StackedList } from "@enzymefinance/ui";
import { address, bigint } from "@enzymefinance/validation";
import { useNetwork } from "components/providers/NetworkProvider";
import { supportEmailAddress } from "utils/constants";
import { parseUnits } from "viem";
import * as zod from "zod";
import { InlineLink } from "../../../routing/Link";
import type { ExitDirectFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { ExitFeeFormFields } from "./ExitFee";

// TODO: Check that at least one of these values isn't zero
export const exitRateDirectFeeSchema = zod.z.object({
  inKindRate: bigint().refine(
    (value?: ExitDirectFeeSettings["inKindRate"]) => typeof value === "bigint" && value < parseUnits("1", 4),
    {
      message: "The rate must be less than 100%.",
    },
  ),
  recipient: address({ caseInsensitive: true }).optional().nullable(),
  specificAssetsRate: bigint().refine(
    (value?: ExitDirectFeeSettings["specificAssetsRate"]) => typeof value === "bigint" && value < parseUnits("1", 4),
    { message: "The rate must be less than 100%." },
  ),
});

function exitRateDirectFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.EXIT_DIRECT_FEE>) {
  const { network } = useNetwork();

  return (
    <div className="flex-col space-y-4">
      <div>
        <BigIntDisplay numberFormat={{ style: "percent" }} value={settings.inKindRate ?? undefined} decimals={4} /> for
        in kind redemptions
      </div>
      <div>
        <BigIntDisplay
          numberFormat={{ style: "percent" }}
          value={settings.specificAssetsRate ?? undefined}
          decimals={4}
        />{" "}
        for specific assets redemptions
      </div>
      {settings.recipient ? (
        <div>
          Recipient: <Address address={settings.recipient} network={network} icon={true} iconSize={5} />
        </div>
      ) : null}
    </div>
  );
}

function exitRateDirectFeeDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.EXIT_DIRECT_FEE>) {
  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Rate (in kind)">
        <NumberDisplay value={Number(settings.inKindRate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>
      <StackedList.ItemDataBox label="Rate (specific asset)">
        <NumberDisplay value={Number(settings.specificAssetsRate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>
    </StackedList.ItemDataBoxList>
  );
}

export const exitRateDirectFee: VaultConfig<VaultConfigType.EXIT_DIRECT_FEE> = {
  address: (contracts) => contracts.ExitRateDirectFee,
  disableable: false,
  display: exitRateDirectFeeDisplay,
  displaySubgraph: exitRateDirectFeeDisplaySubgraph,
  editable: false,
  encode: (settings) =>
    Fees.Exit.encodeDirectFeeSettings({
      recipient: settings.recipient ?? undefined,
      inKindRateInBps: settings.inKindRate ?? undefined,
      specificAssetsRate: settings.specificAssetsRate ?? undefined,
    }),
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const [inKindRate, specificAssetsRate, recipient] = await Promise.all([
        Fees.Exit.getInKindRate(client, { exitRateFee: vaultConfigAddress, comptrollerProxy: comptroller }),
        Fees.Exit.getSpecificAssetsRate(client, {
          exitRateFee: vaultConfigAddress,
          comptrollerProxy: comptroller,
        }),
        Fee.getRecipient(client, { fee: vaultConfigAddress, comptrollerProxy: comptroller }),
      ]);

      return { inKindRate, recipient, specificAssetsRate };
    } catch {
      return undefined;
    }
  },
  formFields: () => <ExitFeeFormFields selected={VaultConfigType.EXIT_DIRECT_FEE} />,
  label: "Exit Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>
        If enabled, exit fees are charged with every redemption. This fee is set separately for in-kind redemptions
        (when a user redeems their shares for all the assets held by the vault) or for specific asset redemptions (when
        a user redeems their shares for one or a few of the tokens held by the vault).
      </p>
      <p>
        The fee recipient is the vault manager by default, or any other wallet. If you wish to split fee amounts among
        several wallets, please contact our sales team at{" "}
        <InlineLink appearance="tertiary" to={`mailto:${supportEmailAddress}`}>
          {supportEmailAddress}
        </InlineLink>
        .
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.EXIT_DIRECT_FEE,
  validationSchema: exitRateDirectFeeSchema,
};
