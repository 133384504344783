import type { ComponentPropsWithoutRef } from "react";

type NotFoundProps = ComponentPropsWithoutRef<"svg">;

export function NotFound(props: NotFoundProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width="440" height="235" viewBox="0 0 440 235" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M339.779 224.084C336.633 228.165 332.935 231.196 333.746 232.572C333.746 232.572 335.422 233.79 340.043 233.79C347.968 233.79 355.755 233.187 354.477 232.383C352.705 231.271 347.574 230.869 342.672 229.173C341.46 228.753 345.323 226.407 344.269 226.023C342.084 225.229 340.383 224.542 339.779 224.084Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.425 232.33C421.425 232.33 422.466 233.79 426.461 233.79C433.314 233.79 440.454 232.86 439.349 232.165C437.818 231.205 425.809 228.699 425.809 228.699L423.116 225.356L418.55 227.882L421.425 232.33Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M399.717 131.388C394.803 131.388 391.087 130.406 390.777 130.322C388.213 129.622 386.702 126.979 387.401 124.417C388.099 121.857 390.735 120.35 393.295 121.036C393.462 121.082 404.282 123.852 410.597 118.435C414.824 114.807 416.43 107.977 415.37 98.1307L415.033 94.9902L424.762 89.4797C427.078 88.1702 430.012 88.982 431.321 91.2931C432.631 93.6041 431.817 96.5397 429.505 97.8479L425.203 100.285C425.884 111.881 423.065 120.451 416.808 125.782C411.532 130.275 404.947 131.388 399.717 131.388Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M416.368 104.699C416.185 111.695 414.326 116.756 410.798 119.78C403.872 125.725 392.005 122.694 391.802 122.64C388.989 121.881 386.097 123.541 385.331 126.348C384.563 129.161 386.223 132.065 389.036 132.833C389.377 132.923 393.461 134.962 398.853 134.769C419.324 134.043 431.68 116.39 431.907 106.099L416.368 104.699Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M388.221 80.2204C386.386 79.3985 384.584 78.5088 382.809 77.5751C365.846 87.2314 344.173 99.6022 343.744 100.033C343.744 100.033 354.515 117.178 355.204 117.866C355.646 118.308 382.355 99.1549 401.57 85.2773C397.136 83.5619 392.565 82.1683 388.221 80.2204Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M388.122 79.5144C392.563 81.4723 397.23 82.8949 401.745 84.6681C412.324 77.0262 420.502 71.0783 420.502 71.0783L402.42 66C402.42 66 393.72 70.9375 383 77.0387C384.682 77.9159 386.385 78.7478 388.122 79.5144Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.327 57.2206L367.973 86.0301L388.783 93.6645L362.422 45.5284L357.327 57.2206Z"
        fill="#1E3A8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M389.916 100.741H374.497L388.221 124.706L409.141 133.523L389.916 100.741Z"
        fill="#2B587C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M361.506 52.2931L360.177 53.0723L356.568 108.978L384.983 92.326L361.506 52.2931ZM409.142 133.523L396.867 112.591L344.357 143.365L334.031 125.756L340.516 21.0695L364.113 7.24097L406.586 79.6675L420.877 71.2917L432.762 91.5582L418.471 99.9328L430.746 120.863L409.142 133.523Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.574 120.966L334.031 125.756L344.357 143.365L325.912 138.694L316.574 120.966Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.574 120.966L334.031 125.756L340.515 21.0695L324.499 17.2379L316.574 120.966Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.5 17.2379L340.516 21.0695L364.114 7.24098L347.894 2.52716L324.5 17.2379Z"
        fill="#0EA5E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M359.619 162.682C359.619 162.682 362.185 190.346 362.185 200.036C362.185 200.036 342.033 215.737 337.099 223.95C339.218 225.562 343.407 227.702 346.151 227.619C359.596 214.785 374.354 205.625 374.354 205.625C374.354 205.625 380.722 187.166 382.703 171.535L359.619 162.682Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.98 163.987C378.519 164.02 383.155 163.238 387.058 161.121C385.748 155.595 388.538 139.296 392.043 130.585C392.043 130.585 394.806 124.52 392.869 122.657C385.701 115.764 379.6 116.329 377.703 116.253C371.123 121.805 358.879 138.191 359.37 159.846C362.751 163.398 369.619 163.958 373.98 163.987Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.432 228.642C421.056 227.913 423.571 226.701 425.186 224.556C417.404 211.94 388.87 165.602 387.325 161.975C387.285 161.881 387.25 161.773 387.214 161.667C383.023 163.926 378.023 164.721 373.176 164.535C368.954 164.373 362.843 163.781 359.396 160.657C359.404 160.881 359.406 161.101 359.416 161.327C360.011 173.422 400.15 213.752 415.944 229.173C416.814 229.046 417.659 228.856 418.432 228.642Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.576 151.446C334.461 151.446 333.39 151.286 332.368 150.942C330.246 150.233 327.439 148.446 326.112 143.973C325.358 141.426 326.81 138.748 329.358 137.995C331.907 137.239 334.583 138.694 335.337 141.24C335.427 141.537 335.51 141.725 335.569 141.836C336.082 141.858 337.987 141.691 342.625 138.967C346.769 136.534 351.67 132.909 356.411 129.405C364.42 123.479 371.984 117.886 378.232 115.909C380.764 115.107 383.468 116.513 384.27 119.044C385.072 121.577 383.668 124.28 381.136 125.079C376.418 126.572 369.156 131.944 362.133 137.136C351.851 144.74 342.785 151.446 335.576 151.446Z"
        fill="#008FBE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.577 147.849C343.988 145.016 343.37 141.89 341.577 139.564C337.698 141.711 336.041 141.856 335.569 141.836C335.51 141.725 335.427 141.537 335.337 141.24C334.583 138.694 331.907 137.239 329.358 137.995C326.81 138.748 325.358 141.426 326.112 143.973C327.439 148.446 330.246 150.233 332.368 150.942C333.39 151.286 334.461 151.446 335.576 151.446C338.371 151.446 341.446 150.434 344.763 148.755C344.701 148.453 344.64 148.152 344.577 147.849Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.271 119.044C383.469 116.513 380.764 115.107 378.233 115.909C371.985 117.886 364.42 123.479 356.411 129.405C351.67 132.909 346.769 136.534 342.625 138.967C342.437 139.08 342.258 139.178 342.077 139.281C343.876 141.657 346.99 153.375 347.045 153.642C352.146 150.974 376.617 132.351 381.922 126.732C383.746 124.8 385.073 121.577 384.271 119.044Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M328.771 147.56C328.487 147.56 328.199 147.474 327.95 147.297C324.175 144.599 322.206 139.409 322.123 139.19C321.852 138.459 322.221 137.643 322.954 137.369C323.686 137.099 324.501 137.467 324.775 138.199C324.792 138.245 326.532 142.808 329.596 144.995C330.232 145.449 330.379 146.332 329.924 146.965C329.649 147.354 329.214 147.56 328.771 147.56Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M328.774 144.111C328.33 144.111 327.893 143.903 327.618 143.512C325.812 140.955 324.365 134.877 324.206 134.195C324.03 133.435 324.502 132.673 325.263 132.497C326.027 132.315 326.785 132.794 326.963 133.554C327.361 135.266 328.67 140.095 329.928 141.88C330.381 142.518 330.229 143.401 329.59 143.851C329.342 144.027 329.057 144.111 328.774 144.111Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M330.726 143.316C330.168 143.316 329.638 142.984 329.415 142.435C328.119 139.258 327.613 132.598 327.592 132.315C327.534 131.537 328.119 130.86 328.898 130.801C329.681 130.737 330.355 131.328 330.414 132.106C330.547 133.906 331.099 139.075 332.035 141.367C332.33 142.091 331.983 142.916 331.26 143.21C331.084 143.282 330.904 143.316 330.726 143.316Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.849 142.634C332.218 142.634 331.645 142.211 331.479 141.573C330.589 138.117 331.4 134.593 331.436 134.445C331.615 133.685 332.38 133.216 333.137 133.392C333.898 133.572 334.369 134.333 334.191 135.093C334.176 135.16 333.513 138.122 334.22 140.867C334.416 141.624 333.958 142.396 333.203 142.59C333.084 142.621 332.965 142.634 332.849 142.634Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M389.739 126.732C389.091 126.732 388.432 126.591 387.807 126.297C385.547 125.229 384.58 122.529 385.65 120.271C386.766 117.907 388.784 115.487 389.01 115.218C390.626 113.308 393.479 113.068 395.391 114.68C397.301 116.29 397.545 119.143 395.936 121.054C395.369 121.726 394.298 123.16 393.836 124.139C393.063 125.774 391.435 126.732 389.739 126.732Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M390.391 124.402C390.927 124.994 391.488 125.55 392.073 126.081C392.815 125.637 393.438 124.979 393.836 124.139C394.297 123.16 395.368 121.726 395.935 121.054C397.544 119.143 397.3 116.29 395.391 114.68C393.479 113.068 390.625 113.308 389.009 115.218C388.92 115.326 388.552 115.767 388.07 116.405C388.112 119.257 388.411 122.22 390.391 124.402Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M405.261 119.408C403.459 123.776 398.456 125.859 394.085 124.059C389.714 122.256 387.631 117.255 389.432 112.883L391.698 107.392C393.5 103.022 398.505 100.939 402.876 102.741C407.246 104.542 409.329 109.545 407.526 113.913L405.261 119.408Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M390.39 112.245C389.693 115.185 385.468 114.243 383.882 112.707C381.695 110.593 380.391 104.611 385.236 104.931C381.746 99.6651 393.581 93.9547 394.823 100.04C395.748 98.47 396.693 97.06 398.751 96.9268C400.233 96.8325 402.829 97.9786 402.614 99.7945C404.816 98.0553 408.021 98.1898 410.199 99.9479C410.895 100.511 412.151 101.763 411.522 102.675C411.177 103.179 409.393 103.333 408.797 103.514C409.827 103.048 414.044 103.988 413.213 105.741C412.827 106.558 410.763 105.824 410.16 105.649C410.16 105.649 410.153 105.842 410.16 105.834C412.413 107.068 413.34 108.32 413.07 111.124C412.946 112.412 412.336 114.847 410.802 115.031C410.016 115.124 409.11 114.628 409.123 113.807C411.006 115.684 408.339 120.711 405.963 118.231C405.032 117.26 405.481 115.63 406.364 114.791C404.959 113.983 401.909 109.71 404.079 108.566C403.064 108.59 398.369 106.998 399.862 105.647C400.257 107.213 398.317 109.241 396.774 108.436C397.734 110.524 394.911 112.082 393.176 112.157C394.238 113.492 390.964 115.077 389.965 113.141L390.39 112.245Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.544 95.1636C229.544 95.1636 226.796 84.6489 240.427 82.1506L239.389 94.7853L229.544 95.1636Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.926 121.715C171.941 121.715 169.166 121.11 166.608 119.9C154.226 114.052 152.85 96.4795 152.798 95.7342C152.612 93.0839 154.608 90.7854 157.26 90.5982C159.905 90.4147 162.202 92.4028 162.395 95.0468C162.664 98.5831 164.686 108.371 170.739 111.214C175.739 113.56 183.493 111.173 193.165 104.305C195.331 102.765 198.336 103.277 199.873 105.441C201.412 107.609 200.903 110.611 198.735 112.149C189.764 118.517 181.793 121.715 174.926 121.715Z"
        fill="#BAD6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.526 130.739C201.864 127.229 203.105 123.665 204.549 120.196C203.576 120.119 202.593 119.993 201.596 119.805C196.928 118.914 192.977 117.016 189.743 114.111C186.503 111.207 184.004 107.526 182.241 103.073C180.477 98.6183 179.452 93.5564 179.165 87.8799C178.875 82.2073 179.333 76.2179 180.538 69.9106C181.767 63.4801 183.556 57.7145 185.904 52.6073C188.253 47.5014 191.066 43.2023 194.35 39.7125C197.63 36.2227 201.311 33.7244 205.391 32.2163C209.47 30.7096 213.844 30.3979 218.511 31.2902C222.207 31.9964 225.462 33.3888 228.294 35.4448C229.658 32.724 231.124 30.0536 232.519 27.3894C234.169 24.2389 235.699 21.0293 237.164 17.7921C237.791 16.4073 238.334 14.9822 238.902 13.5684C234.171 10.8728 228.871 8.96015 222.994 7.83793C214.668 6.24822 206.83 6.54857 199.484 8.74023C192.134 10.9319 185.519 14.6001 179.633 19.7488C173.745 24.8987 168.749 31.3631 164.651 39.1382C160.548 46.9158 157.585 55.597 155.754 65.1779C153.921 74.7627 153.475 83.9251 154.423 92.6641C155.366 101.403 157.626 109.251 161.2 116.207C164.773 123.163 169.569 129.014 175.595 133.756C181.203 138.174 187.81 141.222 195.416 142.906C197.217 138.89 198.96 134.856 200.526 130.739Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.685 58.432C264.738 49.6918 262.481 41.8425 258.908 34.888C255.334 27.9323 250.535 22.0837 244.515 17.3384C242.893 16.0617 241.186 14.903 239.398 13.8549C238.834 15.2674 238.296 16.6913 237.658 18.0698C236.164 21.2957 234.668 24.5166 233.013 27.6659C231.603 30.3464 230.124 33.0357 228.753 35.7765C229.241 36.1485 229.716 36.5356 230.177 36.9478C233.412 39.8545 235.944 43.5391 237.77 48.0041C239.597 52.4691 240.662 57.5096 240.962 63.1207C241.261 68.7331 240.797 74.7526 239.569 81.183C238.365 87.4903 236.581 93.2271 234.221 98.3933C231.862 103.563 229.007 107.886 225.663 111.364C222.317 114.842 218.606 117.338 214.527 118.842C211.52 119.954 208.388 120.415 205.134 120.237C203.532 124.033 202.184 127.944 200.725 131.787C199.28 135.59 197.657 139.317 195.985 143.027C196.361 143.105 196.736 143.184 197.116 143.257C205.439 144.848 213.274 144.545 220.623 142.353C227.97 140.164 234.587 136.495 240.474 131.346C246.363 126.196 251.354 119.733 255.456 111.955C259.557 104.182 262.523 95.5005 264.355 85.917C266.185 76.336 266.628 67.1723 265.685 58.432Z"
        fill="#1E3A8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.299 124.8C220.839 125.666 225.148 125.347 229.228 123.837C233.307 122.333 237.019 119.836 240.364 116.359C243.707 112.88 246.562 108.559 248.923 103.389C251.284 98.2225 253.066 92.4844 254.271 86.1784C255.499 79.7467 255.963 73.7284 255.662 68.1148C255.363 62.5037 254.297 57.4644 252.473 52.9994C250.645 48.5331 248.112 44.8485 244.879 41.9431C241.64 39.0389 237.753 37.1513 233.214 36.2855C228.544 35.3932 224.17 35.7049 220.093 37.2104C216.011 38.7197 212.331 41.2167 209.05 44.7078C205.768 48.1976 202.953 52.4955 200.606 57.6014C198.256 62.7085 196.468 68.4742 195.24 74.9059C194.035 81.2132 193.576 87.2026 193.865 92.8753C194.153 98.5504 195.177 103.612 196.942 108.067C198.706 112.52 201.204 116.202 204.443 119.105C207.677 122.011 211.63 123.909 216.299 124.8ZM211.816 148.252C203.491 146.661 196.316 143.493 190.295 138.751C184.269 134.009 179.474 128.157 175.902 121.201C172.326 114.245 170.067 106.398 169.123 97.6569C168.177 88.9192 168.624 79.7567 170.454 70.1732C172.285 60.5923 175.248 51.9111 179.352 44.1335C183.452 36.3584 188.447 29.8927 194.334 24.7441C200.221 19.5955 206.835 15.9259 214.185 13.7343C221.532 11.5439 229.368 11.2423 237.694 12.832C246.018 14.4217 253.19 17.5898 259.214 22.3325C265.237 27.079 270.035 32.9264 273.609 39.8821C277.183 46.8379 279.439 54.6871 280.387 63.4261C281.329 72.1676 280.888 81.3301 279.056 90.911C277.225 100.495 274.258 109.174 270.157 116.951C266.055 124.728 261.063 131.192 255.176 136.34C249.29 141.49 242.671 145.16 235.324 147.349C227.975 149.538 220.14 149.842 211.816 148.252Z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.689 77.6278L297.056 95.3081C297.056 95.3081 292.005 99.8335 287.48 95.3081L289.149 75.3972L296.689 77.6278Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.857 218.668C275.568 218.439 273.597 217.522 273.324 217.293C272.266 218.879 271.525 219.753 270.253 221.068C273.371 223.785 272.568 227.087 272.568 227.087C272.568 227.087 270.363 228.984 269.5 229.412C266.749 230.766 261.673 232.649 261.673 232.649L274.454 233.65L283.934 223.942C280.575 222.067 277.999 220.362 275.857 218.668Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.78 219.361C223.745 219.004 221.175 220.563 219.518 219.361C219.135 222.914 219.208 224.853 219.208 224.853L200.298 232.649H224.448C224.665 230.076 224.204 227.607 224.551 225.224C224.728 223.999 225.558 220.541 225.78 219.361Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.792 133.564L226.425 174.151C226.425 174.151 235.083 181.473 243.607 183.913L259.626 136.093L247.792 133.564Z"
        fill="#BAD6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.19 222.085C272.471 220.774 273.53 219.211 274.585 217.623C269.057 212.887 266.353 208.008 258.841 199.612C255.735 196.136 263.967 176.235 265.964 172.897C269.556 166.899 270.11 162.55 272.919 152.282L252.05 160.075C247.083 177.862 241.295 195.652 247.098 204.137C253.229 213.109 264.11 220.334 269.361 223.942C269.561 224.081 271.015 221.952 271.19 222.085Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.052 93.0763C235.765 93.0763 235.479 92.9908 235.227 92.8111C234.593 92.3562 234.448 91.4715 234.903 90.8381C236.584 88.4957 236.953 86.5692 236.957 86.5503C237.099 85.7862 237.83 85.2735 238.599 85.4092C239.365 85.5462 239.878 86.2713 239.746 87.0379C239.727 87.1409 239.289 89.5814 237.204 92.4856C236.927 92.8714 236.492 93.0763 236.052 93.0763Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.436 156.583C277.648 139.533 282.952 112.78 284.938 102.103C283.828 101.642 282.739 101.295 281.876 101.055C280.033 100.547 277.824 99.9478 275.935 100.535C274.975 100.834 274.122 101.328 273.284 101.853C272.51 104.17 272.191 106.123 272.191 106.123C272.191 106.123 258.847 131.62 250.154 149.008C257.537 150.663 264.759 153.087 271.436 156.583Z"
        fill="#E5DD93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.905 149.53C249.901 149.528 249.898 149.528 249.895 149.525C249.125 151.067 248.394 152.543 247.715 153.924C247.714 153.924 247.709 153.924 247.709 153.924C245.999 156.954 244.156 159.753 242.371 162.444C225.202 188.32 218.718 208.946 217.572 218.707C219.212 219.986 225.412 220.71 227.477 221.068C230.645 204.889 238.887 194.56 253.655 182.356C258.597 178.272 262.294 173.083 265.542 168.776C267.478 166.209 269.399 162.092 271.243 157.108C264.55 153.601 257.302 151.18 249.905 149.53Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.244 156.648C231.25 156.648 229.387 155.402 228.698 153.414C227.828 150.903 229.157 148.163 231.668 147.295C237.506 145.273 246.458 137.295 252.495 128.739C264.744 111.376 273.344 101.75 273.705 101.347C275.479 99.371 278.521 99.2052 280.499 100.98C282.475 102.752 282.641 105.793 280.867 107.768C280.783 107.864 272.251 117.423 260.358 134.282C254.399 142.727 243.864 153.246 234.819 156.381C234.299 156.563 233.767 156.648 233.244 156.648Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M281.561 103.697C281.45 103.697 281.339 103.692 281.225 103.684C278.733 103.499 276.859 101.333 277.04 98.8382C277.231 96.2319 278.241 93.2472 278.357 92.9142C279.173 90.5516 281.75 89.2975 284.114 90.1143C286.477 90.9287 287.731 93.5023 286.918 95.8649C286.634 96.6968 286.151 98.4197 286.073 99.498C285.898 101.879 283.912 103.694 281.561 103.697Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.886 98.9211C283.687 99.841 284.56 100.66 285.487 101.408C285.813 100.839 286.021 100.194 286.073 99.498C286.151 98.4197 286.633 96.6968 286.918 95.8649C287.731 93.5023 286.476 90.9286 284.114 90.1143C282.153 89.4357 280.05 90.1872 278.921 91.8096C279.338 94.3657 281.426 97.246 282.886 98.9211Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M294.553 89.1215C294.447 93.8453 290.53 97.5903 285.802 97.4847C281.075 97.3779 277.33 93.4621 277.436 88.7369L277.569 82.7978C277.675 78.0714 281.593 74.3278 286.32 74.4333C291.047 74.5401 294.792 78.456 294.686 83.1811L294.553 89.1215Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.45 155.321C224.476 155.321 222.217 154.791 219.689 153.728C218.97 153.426 218.631 152.597 218.932 151.877C219.237 151.155 220.065 150.817 220.785 151.119C224.51 152.685 227.475 152.908 229.362 151.767C230.032 151.361 230.9 151.578 231.305 152.245C231.709 152.913 231.496 153.782 230.826 154.186C229.577 154.943 228.115 155.321 226.45 155.321Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.145 157.377C225.893 157.377 224.087 156.941 222.031 156.442L221.394 156.29C220.635 156.109 220.166 155.345 220.348 154.585C220.529 153.826 221.293 153.352 222.054 153.539L222.698 153.694C226.128 154.523 228.397 155.072 233.415 153.777C234.175 153.583 234.943 154.037 235.138 154.793C235.333 155.551 234.878 156.323 234.121 156.519C231.704 157.142 229.804 157.377 228.145 157.377Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.353 152.535C221.912 152.535 218.868 149.803 218.716 149.663C218.14 149.136 218.1 148.242 218.628 147.665C219.155 147.091 220.046 147.052 220.623 147.575C220.764 147.702 224.019 150.568 228.397 149.442C229.158 149.246 229.926 149.702 230.121 150.459C230.316 151.214 229.861 151.987 229.103 152.182C228.142 152.429 227.219 152.535 226.353 152.535Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.319 151.016C229.264 151.016 229.208 151.011 229.153 151.006C224.333 150.441 222.374 146.973 222.293 146.826C221.917 146.141 222.168 145.281 222.853 144.904C223.533 144.531 224.388 144.776 224.768 145.454C224.835 145.569 226.171 147.807 229.481 148.196C230.258 148.286 230.814 148.989 230.722 149.764C230.639 150.483 230.028 151.016 229.319 151.016Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.229 95.1171C246.154 95.1171 246.076 95.1109 245.998 95.097C243.654 94.71 237.248 93.7021 235.758 93.7021C234.976 93.7021 234.343 93.0688 234.343 92.2871C234.343 91.5067 234.976 90.8721 235.758 90.8721C237.75 90.8721 245.574 92.1589 246.459 92.3072C247.23 92.4341 247.753 93.1618 247.625 93.9334C247.51 94.6245 246.909 95.1171 246.229 95.1171Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.323 98.0867C245.2 98.0867 245.073 98.0716 244.95 98.0364C244.882 98.0188 238.153 96.1878 235.022 96.0094C234.242 95.9654 233.646 95.2969 233.691 94.5165C233.735 93.7361 234.409 93.1215 235.183 93.1856C238.611 93.3804 245.409 95.2302 245.695 95.3082C246.45 95.5143 246.893 96.2921 246.687 97.0449C246.516 97.6745 245.946 98.0867 245.323 98.0867Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.642 101.197C244.496 101.197 244.35 101.176 244.207 101.132C237.835 99.0745 236.264 98.9488 235.422 98.881C235.056 98.8521 234.711 98.8232 234.241 98.7C233.487 98.4989 233.036 97.7261 233.234 96.9708C233.435 96.2156 234.21 95.7669 234.965 95.9642C235.19 96.0245 235.353 96.0371 235.647 96.061C236.674 96.1427 238.39 96.2822 245.075 98.4386C245.818 98.6774 246.227 99.4754 245.988 100.219C245.794 100.818 245.239 101.197 244.642 101.197Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.842 103.463C241.809 103.463 241.774 103.46 241.74 103.458C235.359 103.002 235.094 99.5394 235.085 99.1448L237.915 99.0782C237.915 99.0782 237.919 99.0455 237.907 98.9865C237.91 99.0015 238.201 100.37 241.943 100.638C242.721 100.692 243.308 101.369 243.253 102.147C243.199 102.893 242.579 103.463 241.842 103.463Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.499 101.765C236.499 101.765 235.271 101.197 233.358 101.197C231.748 101.197 229.561 98.4675 229.561 95.6349C229.561 95.6349 228.775 87.283 237.595 91.0606L236.499 101.765Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.275 126.591L251.91 187.447C251.91 187.447 271.059 192.059 282.804 185.297L285.775 100.741C285.775 100.741 272.632 96.1426 264.275 126.591Z"
        fill="#BAD6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.137 160.075L278.982 159.439C278.982 159.439 279.266 177.546 272.191 176.839C272.191 176.839 262.508 178.954 267.137 160.075Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.009 99.2944C280.709 98.1282 279.032 97.4873 277.286 97.4873C275.28 97.4873 273.358 98.3419 272.02 99.8348C271.658 100.239 263.005 109.913 250.645 127.433C249.835 128.579 248.945 129.75 247.997 130.914L246.74 132.455L248.111 133.898C249.569 135.439 251.1 136.942 252.58 138.392C253.383 139.18 254.185 139.968 254.978 140.764L256.727 142.516L258.316 140.618C259.735 138.928 261.044 137.234 262.208 135.587C274.028 118.833 282.468 109.376 282.554 109.28C285.156 106.378 284.912 101.897 282.009 99.2944Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.19 143.515L244.739 132.333L249.519 127.016L261.971 138.196L257.19 143.515Z"
        fill="#F3FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.844 92.5184L279.723 96.7798C279.723 96.7798 284.349 74.2789 280.973 70.9047C280.973 70.9047 268.844 69.7561 268.844 92.5184Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M281.6 74.823C281.986 75.3973 290.427 81.0285 297.056 79.4702C297.056 79.4702 295.923 69.5236 280.45 70.9562L281.6 74.823Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.119 85.4306C288.119 88.0433 285.999 90.1608 283.387 90.1608C280.775 90.1608 278.656 88.0433 278.656 85.4306C278.656 82.8193 280.775 80.703 283.387 80.703C285.999 80.703 288.119 82.8193 288.119 85.4306Z"
        stroke="#C5CA69"
        strokeWidth="0.868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.803 86.3581C298.803 88.6302 296.962 90.4725 294.689 90.4725C292.416 90.4725 290.574 88.6302 290.574 86.3581C290.574 84.086 292.416 82.245 294.689 82.245C296.962 82.245 298.803 84.086 298.803 86.3581Z"
        stroke="#C5CA69"
        strokeWidth="0.755"
      />
      <path d="M288.119 85.4306C288.119 85.4306 289.689 84.8174 290.625 85.7523" stroke="#C5CA69" strokeWidth="0.868" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.473 93.7147C150.362 93.7147 150.253 93.7097 150.14 93.7009C147.645 93.5187 145.771 91.3497 145.954 88.8564C146.145 86.25 147.154 83.2654 147.269 82.9336C148.085 80.5711 150.663 79.3156 153.028 80.1325C155.389 80.9481 156.644 83.5205 155.831 85.8818C155.546 86.715 155.064 88.4379 154.985 89.5174C154.811 91.8976 152.824 93.7135 150.473 93.7147Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.785 219.333C155.284 222.794 161.439 222.722 161.936 222.794C166.825 214.095 181.556 190.54 168.155 171.208L149.201 161.025C149.201 161.025 168.155 180.793 147.785 219.333Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.596 227.033C157.596 227.033 159.347 223.481 160.715 221.096C159.688 220.97 155.098 220.784 153.213 220.059C151.442 223.672 147.586 228.967 144.861 232.597H170.331L157.596 227.033Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.104 172.34C185.936 166.194 162.931 147.686 160.845 137.055C160.661 136.115 160.503 135.148 160.366 134.159C156.671 136.113 151.875 136.642 148.074 136.837C143.763 137.055 141.187 137.322 137.015 135.292C137.369 141.89 136.294 148.26 140.145 154.943C147.786 168.202 173.175 179.556 176.007 183.937C181.592 192.582 183.523 211.347 185.425 222.794C188.414 222.794 195.502 222.664 198.415 221.63C197.432 215.759 199.027 191.595 189.104 172.34Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.032 226.005C197.032 226.005 196.206 222.657 195.959 221.096C193.045 222.119 191.349 221.088 188.351 221.096C188.806 223.935 188.469 229.242 188.731 232.356H212.881L197.032 226.005Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.251 140.309C151.93 140.14 159.655 139.116 162.755 136.019C162.12 130.973 159.863 122.853 160.658 116.32C163.351 94.1659 152.53 87.5256 152.53 87.5256C152.53 87.5256 147.548 84.0433 143.51 88.2029C137.423 94.47 131.485 112.193 132.079 138.395C136.276 140.56 143.841 140.511 148.251 140.309Z"
        fill="#BAD6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.746 147.302C203.387 147.302 203.021 147.263 202.655 147.177C185.217 143.131 170.45 132.688 159.947 116.972C152.079 105.198 149.361 94.6309 149.248 94.1872C148.598 91.611 150.161 88.9971 152.738 88.3499C155.312 87.7002 157.925 89.2585 158.577 91.831C158.627 92.0232 161.133 101.529 168.13 111.901C177.364 125.583 189.71 134.301 204.83 137.808C207.418 138.407 209.029 140.991 208.429 143.579C207.914 145.801 205.935 147.302 203.746 147.302Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.147 132.853C183.538 128.305 175.358 121.095 168.835 111.425C161.922 101.181 159.421 91.7128 159.4 91.6223C158.763 89.109 156.507 87.3534 153.918 87.3534C153.451 87.3534 152.984 87.4112 152.53 87.5256C151.064 87.895 149.829 88.8124 149.054 90.1106C148.278 91.4075 148.054 92.9293 148.424 94.3946L148.448 94.4989C149.192 97.7361 152.166 109.123 159.242 119.707C167.71 132.382 179.2 141.868 192.469 147.145C192.57 147.184 192.677 147.204 192.783 147.204C192.949 147.204 193.117 147.155 193.259 147.057C193.491 146.9 193.631 146.637 193.631 146.355V133.618C193.631 133.289 193.442 132.992 193.147 132.853Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.32 147.645C205.779 147.645 204.142 147.474 202.413 147.128C201.645 146.975 201.15 146.229 201.302 145.464C201.455 144.698 202.199 144.199 202.968 144.354C207.182 145.196 210.696 144.889 213.417 143.442C214.107 143.073 214.963 143.336 215.33 144.025C215.697 144.715 215.436 145.572 214.746 145.937C212.609 147.076 210.124 147.645 207.32 147.645Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.52 144.48C206.846 144.48 206.152 144.436 205.448 144.333C204.675 144.22 204.139 143.499 204.252 142.727C204.364 141.954 205.081 141.418 205.857 141.531C210.382 142.194 214.572 139.845 214.615 139.821C215.294 139.435 216.156 139.669 216.545 140.346C216.933 141.024 216.701 141.885 216.024 142.275C215.846 142.376 212.132 144.48 207.52 144.48Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.264 141.696C206.044 141.696 205.82 141.689 205.59 141.674C204.81 141.625 204.217 140.955 204.267 140.174C204.316 139.394 204.989 138.802 205.769 138.851C209.117 139.057 210.873 137.025 210.889 137.005C211.382 136.397 212.273 136.306 212.879 136.799C213.485 137.29 213.579 138.181 213.085 138.787C212.985 138.913 210.669 141.696 206.264 141.696Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.372 139.31C200.591 139.31 199.956 138.678 199.956 137.896C199.956 137.118 200.586 136.485 201.364 136.482C201.49 136.481 204.284 136.417 205.883 134.115C206.329 133.471 207.21 133.314 207.852 133.759C208.495 134.203 208.654 135.084 208.208 135.727C205.748 139.271 201.548 139.31 201.372 139.31Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.745 107.385C196.304 107.385 195.87 107.181 195.593 106.796C193.507 103.89 193.068 101.451 193.05 101.347C192.917 100.579 193.434 99.8474 194.204 99.7142C194.971 99.581 195.705 100.097 195.839 100.869C195.841 100.878 196.212 102.806 197.893 105.147C198.348 105.783 198.203 106.666 197.567 107.12C197.32 107.3 197.03 107.385 196.745 107.385Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.04 108.011C196.952 108.011 196.862 108.003 196.771 107.987C193.677 107.392 187.535 107.385 187.472 107.385C186.691 107.385 186.058 106.752 186.058 105.971C186.058 105.191 186.691 104.557 187.472 104.557C187.735 104.557 193.945 104.565 197.304 105.208C198.072 105.353 198.575 106.094 198.428 106.862C198.298 107.539 197.705 108.011 197.04 108.011Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.696 110.956C185.992 110.956 185.383 110.431 185.293 109.717C185.196 108.941 185.747 108.234 186.523 108.139C187.422 108.026 195.369 107.058 197.94 107.516C198.71 107.65 199.222 108.384 199.085 109.154C198.95 109.923 198.213 110.439 197.446 110.299C195.826 110.014 190.101 110.544 186.872 110.944C186.813 110.951 186.754 110.956 186.696 110.956Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.536 114.155C187.871 114.155 187.279 113.686 187.148 113.009C187 112.239 187.503 111.499 188.271 111.351C191.698 110.691 192.613 110.4 194.272 109.864C195.049 109.614 196.017 109.305 197.459 108.885C198.208 108.669 198.995 109.1 199.213 109.849C199.43 110.601 198.998 111.386 198.246 111.602C196.844 112.009 195.938 112.301 195.14 112.556C193.432 113.107 192.402 113.438 188.806 114.13C188.714 114.147 188.624 114.155 188.536 114.155Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.583 116.403C190.907 116.403 190.308 115.916 190.19 115.227C190.059 114.456 190.577 113.725 191.347 113.595C195.93 112.81 196.843 111.101 196.848 111.085C197.093 110.341 197.893 109.937 198.636 110.184C199.377 110.429 199.78 111.229 199.536 111.971C199.353 112.517 198.131 115.303 191.824 116.383C191.743 116.395 191.663 116.403 191.583 116.403Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.038 114.699L195.959 103.171C195.959 103.171 201.018 103.539 201.008 108.838C201.004 110.608 200.493 112.579 197.038 114.699Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.194 77.1416C166.472 81.9673 163.593 86.9174 158.764 88.1929C153.934 89.4697 148.985 86.5931 147.706 81.7649L146.101 75.6977C144.824 70.8707 147.702 65.9219 152.531 64.6451C157.361 63.3671 162.31 66.2449 163.589 71.0731L165.194 77.1416Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.346 73.9344C163.661 75.8484 162.687 77.746 161.195 79.0353C157.554 82.1758 151.972 83.0228 147.542 81.1403L147.707 81.7648C148.985 86.593 153.935 89.4696 158.765 88.1928C163.593 86.9173 166.472 81.9672 165.195 77.1415L164.346 73.9344Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.68 81.4319C144.284 79.2515 140.615 70.965 145.981 69.1503C149.301 68.0281 147.963 78.2575 147.963 81.1491L147.68 81.4319Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.954 69.8339C140.848 66.8606 145.935 58.4484 156.079 58.4484C162.35 58.4484 162.303 63.2502 157.967 65.8414C154.37 67.9916 148.789 66.7324 146.086 70.3994L144.954 69.8339Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.344 75.7894C162.906 73.6103 160.074 69.1955 158.973 66.7098C158.274 65.1314 157.317 62.4396 160.48 64.3523C163.129 65.952 164.566 71.5946 164.317 74.4548L164.344 75.7894Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.6589 95.9038C91.0975 97.3478 89.379 98.695 87.971 100.305L67.5449 142.958L82.7501 123.337L98.9006 89.9635C96.8351 91.9591 94.7684 93.9547 92.6589 95.9038Z"
        fill="#2B587C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.5439 88.9016C82.0261 86.8759 83.4517 84.4957 85.1375 82.4021L49.3844 64.953L15.4629 68.3486L74.8993 95.7468C76.8492 93.525 78.8078 91.2755 80.5439 88.9016Z"
        fill="#1E3A8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6464 82.6521C83.7456 85.0285 82.2043 87.8246 80.3789 90.0452C78.7421 92.0383 77.0978 94.0276 75.417 95.9855L102.438 108.441L112.249 95.6349L85.6464 82.6521Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6286 47.2212L84.2395 46.5551L35.8747 74.8582L65.5848 89.0776L85.6286 47.2212ZM44.9576 132.148L55.4384 110.264L0.535156 83.9918L9.3515 65.5826L99.7627 12.3557L124.434 24.1623L88.1706 99.885L103.112 107.037L92.9653 128.223L78.0229 121.074L67.5446 142.958L44.9576 132.148Z"
        fill="url(#paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.111 107.037L112.248 95.6349L105.079 111.526L92.6279 128.911L103.111 107.037Z"
        fill="#2B587C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.6134 89.3289L135.267 11.2033L124.434 24.1622L88.1709 99.885C89.1816 98.9111 99.0364 89.9057 99.6134 89.3289Z"
        fill="#1E3A8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35156 65.5826L25.4631 51.563L113.805 0.877136L99.7628 12.3557L9.35156 65.5826Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.7627 12.3557L124.434 24.1622L135.267 11.2033L113.805 0.877136L99.7627 12.3557Z"
        fill="#0EA5E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.651 79.3721C67.1186 78.5503 67.4392 77.9282 67.7384 77.3099C67.4844 77.9119 67.1287 78.598 66.651 79.3721ZM69.4846 69.4205C66.4498 67.264 64.3604 68.2078 62.9034 68.434C61.3081 68.6803 60.1264 67.8094 60.1201 67.8056C59.4651 67.3809 58.5876 67.5669 58.164 68.2228C57.7378 68.8776 57.9251 69.7535 58.5814 70.1782C58.6467 70.2222 59.8825 71.0064 62.0398 71.5732C59.6864 73.7171 57.2865 76.3058 57.1532 76.4504C56.6227 77.0247 56.6579 77.9194 57.2324 78.4497C57.504 78.7011 57.8497 78.8255 58.1916 78.8255C58.5738 78.8255 58.9535 78.6722 59.2313 78.3706C59.259 78.3417 60.931 76.5383 62.7978 74.74C61.0642 76.9078 59.4375 79.1635 59.1408 79.5782C58.6857 80.2141 58.8315 81.0976 59.4664 81.5525C59.7153 81.7322 60.0032 81.8164 60.2898 81.8164C60.7298 81.8164 61.1635 81.6128 61.4401 81.227C62.3654 79.9351 63.6728 78.1883 64.9211 76.6213C64.456 77.5386 63.8676 78.5779 62.6872 80.4768C62.2748 81.1403 62.4772 82.0124 63.1423 82.4246C63.3736 82.5691 63.6326 82.637 63.8865 82.637C64.3604 82.637 64.8218 82.4008 65.0908 81.9697C65.5761 81.1881 65.9683 80.5421 66.2952 79.9854C66.0488 80.5999 66.2612 81.3213 66.837 81.697C67.0746 81.8516 67.3424 81.9257 67.6076 81.9257C68.0703 81.9257 68.5241 81.6983 68.7956 81.2823C70.1207 79.2427 70.7643 77.5776 71.0409 76.2669L71.0987 76.3109C71.8857 72.3347 70.9278 70.4447 69.4846 69.4205Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1471 85.4633C21.6667 85.2094 15.166 93.9761 21.8175 96.3613C15.7958 96.2192 14.3237 104.727 19.8426 107.076C10.1777 105.196 11.3015 117.716 15.8738 122.246C12.7749 121.751 9.64337 124.711 10.1123 127.934C10.585 131.179 11.871 135.678 19.9029 138.088C27.9361 140.499 32.8452 135.985 36.8417 131.728C39.3999 136.953 52.0455 129.892 50.6438 123.641C55.4159 125.332 59.6915 119.918 59.7883 115.592C59.8813 111.371 56.9233 108.149 54.111 105.407C56.0923 103.593 56.4682 100.229 55.9465 97.6594C55.079 93.3904 49.7324 92.9657 49.7324 89.5676C49.7324 88.3788 49.9599 84.6854 48.9467 83.063C47.3325 80.4805 44.5241 77.9244 40.9902 79.1861C26.1471 72.1475 26.1471 85.4633 26.1471 85.4633Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8024 222.794C30.7192 222.083 30.0604 222.286 26.0275 221.302L16.6895 232.71H52.914L31.877 227.053L34.8024 222.794Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.249 226.064L100.108 223.252C98.1763 224.502 96.1648 225.57 93.9824 226.329L99.7024 232.71H120.927L102.249 226.064Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1924 222.83C26.2504 223.824 30.3072 224.858 34.4156 225.555L64.328 188.768L59.2341 174.151L38.2914 150.012L44.3244 184.222L22.1924 222.83Z"
        fill="#252A41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8746 139.883C54.6668 137.705 43.541 140.46 35.5557 143.471C35.5557 143.471 40.4535 162.962 43.668 168.776C53.0325 185.704 93.5301 227.956 93.5301 227.956C95.7539 227.2 100.04 224.063 102.002 222.794L62.8746 139.883Z"
        fill="#2F3658"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4708 151.094C42.3958 148.119 58.2924 143.102 66.45 145.139C66.45 145.139 55.6473 119.773 50.7432 114.176C47.4332 110.402 29.3643 99.7731 29.3643 113.668C29.3643 132.708 34.4708 151.094 34.4708 151.094Z"
        fill="#BAD6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5143 112.483C41.4087 112.52 41.3031 112.552 41.1937 112.584C38.78 113.234 36.2946 111.806 35.6434 109.393C34.9621 106.87 34.9294 103.718 34.9268 103.367C34.9155 100.866 36.9358 98.8319 39.4362 98.8218C41.9354 98.8106 43.9707 100.826 43.9833 103.323C43.9896 104.204 44.104 105.989 44.3856 107.032C45.0079 109.339 43.7331 111.708 41.5143 112.483Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5352 108.008C44.5189 107.684 44.4736 107.359 44.3856 107.032C44.104 105.989 43.9896 104.204 43.9833 103.323C43.9707 100.826 41.9354 98.8106 39.4362 98.8218C36.9358 98.8319 34.9155 100.866 34.9268 103.367C34.9268 103.424 34.9294 103.561 34.9331 103.751C37.1646 106.269 40.8417 107.454 44.5352 108.008Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9625 94.4386C50.4233 98.9337 47.9618 103.759 43.465 105.222C38.9682 106.681 34.1383 104.222 32.6775 99.7267L30.842 94.0766C29.3812 89.5815 31.8427 84.7533 36.3383 83.293C40.8351 81.8328 45.665 84.2921 47.1258 88.7885L48.9625 94.4386Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.3851 89.8969C99.2054 89.7461 98.9979 89.6531 98.7842 89.6053C98.9904 89.3804 99.2116 89.1378 99.4694 88.8589C99.9986 88.2845 99.9622 87.3898 99.3876 86.8607C99.316 86.7954 99.2406 86.7388 99.1614 86.6911C99.5561 86.1243 99.4907 85.3414 98.9715 84.8551C98.568 84.4793 98.0086 84.3888 97.5233 84.5635C98.1682 83.9113 98.5692 83.4601 98.617 83.4048C99.1337 82.818 99.0784 81.9245 98.4913 81.408C97.9055 80.8915 97.0104 80.948 96.495 81.5349C96.461 81.5713 93.3848 85.0373 90.2218 86.8419C88.849 84.7671 87.0073 84.0395 86.9017 83.9992C86.1763 83.724 85.3743 84.0885 85.0914 84.8098C84.8086 85.5312 85.1681 86.348 85.8859 86.6396C85.9488 86.6647 87.0299 87.1234 87.871 88.4165C87.7553 88.7369 87.6195 89.1919 87.5428 89.7247C66.7183 117.9 39.1064 107.898 37.8555 107.424C35.3827 106.478 32.6057 107.704 31.6465 110.176C30.6848 112.653 31.9155 115.438 34.3921 116.398C34.729 116.529 41.4887 119.097 51.1297 119.097C53.0682 119.097 55.1249 118.994 57.2671 118.744C67.6611 117.539 83.036 111.553 95.322 95.7266C95.5232 95.6123 95.8086 95.4351 96.1844 95.1611C96.4371 94.9978 96.6923 94.8193 96.9526 94.6032L96.9199 94.578C97.6465 93.9673 98.5428 93.1014 99.5599 91.8887C100.063 91.2893 99.9835 90.397 99.3851 89.8969Z"
        fill="#FDA4AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.0517 107.498L59.88 107.751C57.02 108.366 54.0607 108.681 51.0813 108.681C43.781 108.681 38.711 106.841 38.1603 106.632C37.5091 106.381 36.8265 106.256 36.1338 106.256C33.7779 106.256 31.7074 107.673 30.8551 109.869C30.3082 111.278 30.2114 112.88 30.9531 114.196C33.7163 119.097 41.3045 121.079 51.1303 121.079C53.2197 121.079 55.3166 120.958 57.3645 120.722C58.6166 120.578 59.9315 120.361 61.2717 120.08L62.0963 119.864C62.0963 119.864 60.9171 116.292 60.667 112.664C60.5702 111.283 61.0517 107.498 61.0517 107.498Z"
        fill="#D0EAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6513 98.6183C33.6232 96.7307 34.6503 93.1944 34.3234 89.6581C36.9295 91.4099 38.9912 90.3405 40.6192 87.332C45.556 94.2488 53.0825 83.9578 42.8204 80.2317C34.6113 77.2509 26.5668 85.3653 28.67 93.5249L30.6513 98.6183Z"
        fill="#2F3658"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="391.096"
          y1="75.8152"
          x2="297.177"
          y2="72.8614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="233.506"
          y1="81.0588"
          x2="126.777"
          y2="77.2793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="72.1461"
          y1="78.1482"
          x2="-45.6313"
          y2="73.3033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
