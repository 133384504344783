import { TokenLabel } from "@enzymefinance/ethereum-ui";
import { useNetwork } from "components/providers/NetworkProvider";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const setRedemptionAsset: FunctionConfig<{ nextRedemptionAsset: Address }> = {
  signature: "function setRedemptionAsset(address nextRedemptionAsset)",

  Description({ inputs }) {
    const { environment } = useNetwork();

    const redemptionAsset = environment.getAsset(inputs.nextRedemptionAsset);

    return (
      <div className="flex items-center justify-between pt-2">
        <p>Change redemption asset to: </p> <TokenLabel asset={redemptionAsset} />
      </div>
    );
  },

  Label() {
    return <>Reconfigure redemption asset</>;
  },
};
