import { Asset } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useSharesBalance = createUseValidatedClientQuery(["shares-balance"], {
  paramsSchema: z.object({
    account: address({ allowZeroAddress: false }),
    vault: address({ allowZeroAddress: false }),
  }),
  responseSchema: bigint(),
  queryFn: (params, client) => Asset.getBalanceOf(client, { owner: params.account, asset: params.vault }),
});
