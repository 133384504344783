import { useBreakpoints } from "./useBreakpoints.js";

export function useMotionPresets() {
  const { sm } = useBreakpoints();

  return {
    default: {
      animate: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: "easeOut" }, y: 0 },
      exit: { opacity: 0, scale: 0.95, transition: { duration: 0.2, ease: "easeIn" }, y: sm ? 0 : 16 },
      initial: { opacity: 0, scale: 0.95, y: sm ? 0 : 16 },
    },
    disclosure: {
      animate: { height: "auto", opacity: 1, scale: 1, transition: { duration: 0.1, ease: "easeOut" } },
      exit: { height: 0, opacity: 0, transition: { duration: 0.075, ease: "easeIn" } },
      initial: { height: 0, opacity: 0 },
    },
    dropdown: {
      animate: { opacity: 1, scale: 1, transition: { duration: 0.1, ease: "easeOut" } },
      exit: { opacity: 0, scale: 0.95, transition: { duration: 0.075, ease: "easeIn" } },
      initial: { opacity: 0, scale: 0.95 },
    },
    notification: {
      animate: { opacity: 1, transition: { duration: 0.3, ease: "easeOut" }, x: 0, y: 0 },
      exit: { opacity: 0, transition: { duration: 0.1, ease: "easeIn" }, x: sm ? 8 : 0, y: sm ? 0 : 8 },
      initial: { opacity: 0, x: sm ? 8 : 0, y: sm ? 0 : 8 },
    },
    opacity: {
      animate: { opacity: 1, transition: { duration: 0.1, ease: "easeOut" } },
      exit: { opacity: 0, transition: { duration: 0.075, ease: "easeIn" } },
      initial: { opacity: 0 },
    },
    popper: {
      animate: { opacity: 1, scale: 1, transition: { duration: 0.1, ease: "easeOut" }, y: 0 },
      exit: { opacity: 0, scale: 0.95, transition: { duration: 0.075, ease: "easeIn" }, y: sm ? 0 : 16 },
      initial: { opacity: 0, scale: 0.95, y: sm ? 0 : 16 },
    },
    scale: {
      animate: { opacity: 1, scale: 1, transition: { duration: 0.1, ease: "easeOut" } },
      exit: { opacity: 0, scale: 0.5, transition: { duration: 0.075, ease: "easeIn" } },
      initial: { opacity: 0, scale: 0.5 },
    },
  };
}
