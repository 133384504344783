import type { FunctionConfig } from "utils/functionsTypes";
import { formatEther } from "viem";

export const buyBackProtocolFeeShares: FunctionConfig<{ sharesAmount: bigint }> = {
  signature: "function buyBackProtocolFeeShares(uint256 sharesAmount)",

  Description({ inputs }) {
    return <>Buy back {formatEther(inputs.sharesAmount)} outstanding protocol fee shares with MLN.</>;
  },

  Label() {
    return <>Buy back protocol fee shares.</>;
  },
};
