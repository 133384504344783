import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import type { ControlProps } from "@enzymefinance/select";
import { components, sharedComponents } from "@enzymefinance/select";
import type { ButtonProps, FieldGroupProps, Option } from "@enzymefinance/ui";
import { Button, FieldGroup, Modal, NumberDisplay, Popover } from "@enzymefinance/ui";
import classNames from "classnames";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { TokenLabel } from "../token-label/TokenLabel.js";
import type { BaseTokenSelectProps, TokenSelectOption } from "../token-select/TokenSelect.js";
import { BaseTokenSelect } from "../token-select/TokenSelect.js";

const customComponents: NonNullable<BaseTokenSelectProps["components"]> = {
  Control(props) {
    const classes = classNames({ "sr-only": !props.selectProps.isSearchable });

    return (
      <Modal.Body className={classes}>
        <div className="space-y-1">
          <sharedComponents.Control {...(props as unknown as ControlProps<Option>)} />
          {props.selectProps.isClearable && props.hasValue ? (
            <div className="flex justify-end">
              <button
                type="button"
                className="text-primary dark:hover:text-primary-light hover:text-primary-dark text-sm font-medium focus:outline-none"
                onClick={() => props.clearValue()}
              >
                Clear selection
              </button>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    );
  },
  IndicatorsContainer() {
    return null;
  },
  Menu({ className, ...props }) {
    const classes = classNames("border-t border-base-200", className);

    return <components.Menu className={classes} {...props} />;
  },
};

type TokenPickerKind = "modal" | "popover";

interface TokenPickerValues {
  kind?: TokenPickerKind;
  open: boolean;
  setToken: Dispatch<SetStateAction<TokenSelectOption | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  token: TokenSelectOption | undefined;
}

const TokenPickerContext = createContext<TokenPickerValues | undefined>(undefined);

function useTokenPicker() {
  const context = useContext(TokenPickerContext);

  if (!context) {
    throw new Error("Missing token picker context");
  }

  return context;
}

export interface BaseTokenPickerProps extends Omit<BaseTokenSelectProps<false>, "label" | "value"> {
  children: ReactNode;
  id: string;
  kind?: "modal" | "popover";
  label?: string;
  value?: TokenSelectOption;
}

export function BaseTokenPicker({
  children,
  kind = "modal",
  label = "Select a token",
  onChange: onChangeBase,
  value,
  ...props
}: BaseTokenPickerProps) {
  const [token, setToken] = useState<TokenSelectOption | undefined>(value);
  const [open, setOpen] = useState(false);
  const onChange = useCallback<NonNullable<BaseTokenPickerProps["onChange"]>>(
    (tokenValue, action) => {
      switch (action.action) {
        case "select-option": {
          setToken(tokenValue ?? undefined);
          setOpen(false);
          break;
        }

        case "clear":
        case "deselect-option":
        case "remove-value": {
          setToken(undefined);
          break;
        }
        default:
          break;
      }

      onChangeBase?.(tokenValue, action);
    },
    [onChangeBase],
  );

  useEffect(() => {
    if (token?.id !== value?.id) {
      setToken(value);
    }
  }, [token?.id, value?.id]);

  const tokenSelect = (
    <BaseTokenSelect<false>
      autoFocus={true}
      controlShouldRenderValue={false}
      blurInputOnSelect={true}
      formatOptionLabel={(option) => (
        <div className="flex justify-between px-2">
          <TokenLabel kind="option" size={9} asset={option} />
          {!!option.value || !!option.balance ? (
            <div className="text-right">
              {!!option.balance && option.balance > 0 ? (
                <div>
                  <NumberDisplay
                    className="font-semibold"
                    value={option.balance}
                    numberFormat={{ maximumFractionDigits: 4 }}
                  />
                  <span className="title ml-1">{option.symbol}</span>
                </div>
              ) : null}
              {!!option.value && option.value > 0 ? (
                <NumberDisplay
                  className="text-base-content"
                  appearance="simple"
                  value={option.value}
                  numberFormat={{ currency: option.currency }}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      )}
      isSearchable={true}
      label={label}
      labelHidden={true}
      onChange={onChange}
      menuIsOpen={true}
      value={token}
      {...props}
      components={{ ...customComponents, ...props.components } as BaseTokenSelectProps["components"]}
    />
  );

  return (
    <TokenPickerContext.Provider value={{ kind, open, setOpen, setToken, token }}>
      {kind === "popover" && (
        <Popover placement="top-end">
          {children}
          <Popover.Panel
            as="div"
            className="bg-base-400 text-heading-content min-w-[320px] overflow-hidden rounded-lg shadow"
          >
            {tokenSelect}
          </Popover.Panel>
        </Popover>
      )}
      {kind === "modal" && (
        <>
          {children}
          <Modal isOpen={open} dismiss={() => setOpen(false)} title={label}>
            {tokenSelect}
          </Modal>
        </>
      )}
    </TokenPickerContext.Provider>
  );
}

type TokenPickerButtonProps = Omit<ButtonProps, "children"> & Partial<Pick<ButtonProps, "children">>;

function tokenPickerButton({
  children,
  trailingIcon = ChevronDownIcon,
  leadingIcon,
  ...props
}: TokenPickerButtonProps) {
  const { kind, setOpen, token } = useTokenPicker();

  const buttonProps: Required<Pick<TokenPickerButtonProps, "children">> & TokenPickerButtonProps = {
    children: token ? <TokenLabel size={5} asset={token} hideName={true} /> : children ?? "Select a token",
    className: "text-heading-content",
    appearance: "secondary",
    onClick() {
      setOpen(true);
    },
    leadingIcon,
    trailingIcon,
    ...props,
  };

  if (kind === "popover") {
    return <Popover.Button as={Button} {...buttonProps} />;
  }

  return <Button {...buttonProps} />;
}

BaseTokenPicker.Button = tokenPickerButton;

export type TokenPickerProps = FieldGroupProps &
  Omit<BaseTokenSelectProps<false>, "label" | "value"> & {
    kind?: TokenPickerKind;
    buttonText?: string;
  };

export function TokenPicker({
  error,
  description,
  label,
  labelHidden,
  id,
  kind,
  options,
  name,
  children,
  ...props
}: TokenPickerProps) {
  return (
    <FieldGroup error={error} description={description} label={label} labelHidden={labelHidden} id={id}>
      <BaseTokenPicker id={`${id}-picker`} name={name} kind={kind} options={options} {...props}>
        {children}
      </BaseTokenPicker>
    </FieldGroup>
  );
}

TokenPicker.Button = tokenPickerButton;
