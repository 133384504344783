import { LifeCycle } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useExecutableMigration = createUseValidatedClientQuery(["executable-migration"], {
  paramsSchema: z.object({
    vaultProxy: address({ allowZeroAddress: false }),
    dispatcher: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: (params, client) => {
    return LifeCycle.hasExecutableMigrationRequest(client, {
      vaultProxy: params.vaultProxy,
      dispatcher: params.dispatcher,
    });
  },
});
