import { Relayer } from "@enzymefinance/sdk";
import { safeStringifyJSON } from "@enzymefinance/utils";
import { wagmiConfig } from "components/providers/WagmiProvider";
import type { Address, Hex, PublicClient, WalletClient } from "viem";
import { signTypedData } from "wagmi/actions";
import type { TxData } from "./TransactionModalMachine";

interface CreateSignedGasRelayerTransactionArgs {
  baseRelayFee?: bigint;
  clientId?: bigint;
  gasLimit: bigint;
  gasPrice: bigint;
  maxAcceptanceBudget: bigint;
  paymasterData: Hex;
  pctRelayFee?: bigint;
  relayHub: Address;
  relayWorker: Address;
  txData: TxData;
  validUntil?: bigint;
  vaultPaymaster: Address;
  client: PublicClient;
  walletClient: WalletClient;
}

// Creates a ready-to-send signed gas relayer tx
export async function createSignedGasRelayerTransaction({
  baseRelayFee = 0n,
  clientId = 1n,
  gasLimit,
  gasPrice,
  maxAcceptanceBudget,
  paymasterData,
  pctRelayFee = 0n,
  relayHub,
  relayWorker,
  txData,
  validUntil,
  vaultPaymaster,
  client,
  walletClient,
}: CreateSignedGasRelayerTransactionArgs) {
  if (walletClient?.account === undefined) {
    throw new Error("Missing wallet account");
  }

  const forwarder = await Relayer.getTrustedForwarder(client, { gasRelayPaymaster: vaultPaymaster });

  const sender = walletClient.account.address;
  const nonce = await Relayer.getNonce(client, { trustedForwarder: forwarder, sender });
  const value = txData.value ?? 0n;

  const currentBlock = await client.getBlockNumber();

  const request = {
    data: txData.data,
    from: sender,
    gas: gasLimit,
    nonce: nonce,
    to: txData.to,
    validUntil: validUntil ?? currentBlock + 7200n, // 1 day
    value,
  };

  const relayData = {
    baseRelayFee,
    clientId,
    forwarder,
    gasPrice,
    paymaster: vaultPaymaster,
    paymasterData,
    pctRelayFee,
    relayWorker,
  };

  const domain = {
    chainId: client.chain?.id,
    name: "GSN Relayed Transaction",
    verifyingContract: forwarder,
    version: "2",
  };

  const signedRelayRequest = await signTypedData(wagmiConfig, {
    domain,
    types: Relayer.relayRequestTypes,
    primaryType: "RelayRequest",
    message: {
      ...request,
      relayData,
    },
  });

  const relayRequest = {
    request,
    relayData,
  };

  const metadata = {
    approvalData: "0x",
    relayHubAddress: relayHub,
    relayMaxNonce: 100000, // TODO: Set a sensible value for relayMaxNonce?
    signature: signedRelayRequest,
  } as const;

  const encodedRelayCallData = Relayer.encodeRelayCallData({
    maxAcceptanceBudget,
    relayRequest,
    signature: metadata.signature,
    approvalData: metadata.approvalData,
    gasLimit,
  });

  const gasRelayerTransaction = {
    data: encodedRelayCallData,
    from: relayWorker,
    to: relayHub,
  };

  const gasRelayerPayload = safeStringifyJSON({ metadata, relayRequest });

  return { gasRelayerPayload, gasRelayerTransaction };
}
