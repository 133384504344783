import type { Address, Asset } from "@enzymefinance/environment";
import { getExternalPositionAssets } from "@enzymefinance/sdk/Portfolio";
import { address, asset as assetSchema } from "@enzymefinance/validation";
import type { AssetPrice } from "components/providers/AssetPricesProvider";
import { type PublicClient, formatUnits } from "viem";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

async function fetchExternalPositionValue(
  params: {
    assetPrices: AssetPrice[];
    externalPosition: Address;
    getAsset: (address: string) => Asset | undefined;
  },
  client: PublicClient,
) {
  const { managedAssets, debtAssets } = await getExternalPositionAssets(client, {
    externalPosition: params.externalPosition,
  });

  const assetValue = managedAssets.reduce((previousValue, currentValue) => {
    const asset = params.getAsset(currentValue.asset);
    const assetPrice = params.assetPrices.find((item) => item.id === currentValue.asset)?.price;
    const assetValue =
      asset !== undefined && assetPrice && undefined
        ? Number(formatUnits(currentValue.amount, asset.decimals)) * assetPrice
        : 0;

    return previousValue + assetValue;
  }, 0);

  const debtValue = debtAssets.reduce((previousValue, currentValue) => {
    const asset = params.getAsset(currentValue.asset);
    const assetPrice = params.assetPrices.find((item) => item.id === currentValue.asset)?.price;
    const assetValue =
      asset !== undefined && assetPrice && undefined
        ? Number(formatUnits(currentValue.amount, asset.decimals)) * assetPrice
        : 0;
    return previousValue + assetValue;
  }, 0);

  return assetValue - debtValue;
}

export const useExternalPositionValue = createUseValidatedClientQuery(["external-position-value"], {
  paramsSchema: z.object({
    assetPrices: z.array(
      z.object({
        id: address({ allowZeroAddress: false }),
        price: z.number().optional(),
        change24h: z.number().optional(),
      }),
    ),
    getAsset: z.function().args(z.string()).returns(assetSchema().optional()),
    externalPosition: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.number(),
  queryFn: (params, client) => fetchExternalPositionValue(params, client),
});

export const useMultiExternalPositionValue = createUseValidatedClientQuery(["multi-external-position-value"], {
  paramsSchema: z.object({
    assetPrices: z.array(
      z.object({
        id: address({ allowZeroAddress: false }),
        price: z.number().optional(),
        change24h: z.number().optional(),
      }),
    ),
    getAsset: z.function().args(z.string()).returns(assetSchema().optional()),
    externalPositions: z.array(address({ allowZeroAddress: false })),
  }),
  responseSchema: z.array(z.number()),
  queryFn: (params, client) =>
    Promise.all(
      params.externalPositions.map((externalPosition) =>
        fetchExternalPositionValue(
          {
            assetPrices: params.assetPrices,
            externalPosition,
            getAsset: params.getAsset,
          },
          client,
        ),
      ),
    ),
});
