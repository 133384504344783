import type { Option } from "@enzymefinance/ui";
import type { ReactNode } from "react";
import { createFilter } from "react-select";

import type { BaseCreatableProps } from "../components/creatable/Creatable.js";
import type { BaseSelectProps } from "../components/select/Select.js";
import { components as sharedComponents } from "../shared/components.js";
import { styles as sharedStyles } from "../shared/styles.js";

interface Props<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean> {
  creatable: BaseCreatableProps<TOption, TMulti>;
  select: BaseSelectProps<TOption, TMulti>;
}

export function useProps<
  TType extends keyof Props = "select",
  TOption extends Record<string, any> = Option,
  TMulti extends boolean = boolean,
>({
  components,
  hideSelectedOptions = false,
  instanceId,
  id,
  isExpandable = false,
  isClearable = true,
  itemSize = 36,
  menuIsOpen,
  menuPlacement = "top",
  menuShouldScrollIntoView = false,
  name,
  isSearchable = false,
  placeholder,
  ...props
}: Props<TOption, TMulti>[TType]): Props<TOption, TMulti>[TType] & {
  isExpandable: boolean;
  itemSize: number;
  label: ReactNode;
} {
  return {
    "aria-label": props["aria-label"] ?? (typeof props.label === "string" ? props.label : undefined),
    filterOption: createFilter({ ignoreAccents: false }),
    hideSelectedOptions,
    instanceId: instanceId ?? id,
    isClearable,
    isExpandable,
    isSearchable,
    itemSize,
    maxMenuHeight: 320,
    menuIsOpen,
    menuPlacement,
    menuShouldScrollIntoView,
    minMenuHeight: 320,
    name: name ?? id,
    placeholder: isSearchable ? placeholder ?? "Search ..." : placeholder ?? "Select ...",
    ...props,
    closeMenuOnSelect: props.closeMenuOnSelect ?? !props.isMulti,
    components: { ...sharedComponents, ...components } as Props<TOption, TMulti>[TType]["components"],
    styles: sharedStyles as Props<TOption, TMulti>[TType]["styles"],
  };
}
