import { Network, isSupportedNetwork, networks, toAddress } from "@enzymefinance/environment";
import { Address as AddressDisplay, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { ArrowRightOnRectangleIcon, ExclamationTriangleIcon } from "@enzymefinance/icons/solid";
import { Button, Icon, Jazzicon, ScreenReaderText } from "@enzymefinance/ui";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import classNames from "classnames";
import { useNetwork } from "components/providers/NetworkProvider.js";
import type { ReactNode } from "react";
import type { Address } from "viem";
import { useAccount, useBalance } from "wagmi";
import { ImpersonatorModal } from "./impersonator/ImpersonatorModal";
import { type MockConnector, mockConnector } from "./mockConnector";

export interface ConnectionAvatarProps {
  children?: ReactNode;
  kind?: "compact" | "default" | "disconnect";
}

export function ConnectionAvatar({ kind = "default", children }: ConnectionAvatarProps) {
  return (
    <ConnectButton.Custom>
      {({ openConnectModal, openAccountModal, openChainModal, account, chain }) => {
        const address = account !== undefined ? toAddress(account.address) : undefined;
        const open = account !== undefined ? openAccountModal : openConnectModal;

        return (
          <ConnectionAvatarInternal
            kind={kind}
            chain={chain?.id}
            address={address}
            openModal={open}
            openChainModal={openChainModal}
          >
            {children}
          </ConnectionAvatarInternal>
        );
      }}
    </ConnectButton.Custom>
  );
}

interface ConnectionAvatarInternalProps {
  children?: ReactNode;
  kind: "compact" | "default" | "disconnect";
  openModal: () => void;
  openChainModal: () => void;
  address?: Address | undefined;
  chain?: number;
}

function ConnectionAvatarInternal({
  children,
  chain,
  address,
  openModal,
  openChainModal,
  kind,
}: ConnectionAvatarInternalProps) {
  const { client } = useNetwork();
  const { connector } = useAccount();

  const mock = connector?.id === mockConnector.type ? (connector as MockConnector) : undefined;

  const network = isSupportedNetwork(client.chain?.id)
    ? networks[client.chain?.id as Network]
    : networks[Network.ETHEREUM];

  const balance = useBalance({ address });
  const isNetworkSupported = client.chain?.id === chain;
  const balanceClasses = classNames("text-sm font-medium text-base-content", {
    "hidden xs:block": kind === "compact",
  });

  if (address !== undefined && typeof balance.data?.value === "bigint") {
    const buttonClasses = classNames("max-w-[8rem] sm:max-w-[8rem] xl:max-w-none overflow-hidden !justify-start", {
      "!p-2": kind === "disconnect",
      "!px-4 xl:!px-8": kind !== "disconnect",
    });

    return (
      <div className="flex items-center justify-between space-x-3">
        {mock !== undefined && kind !== "disconnect" ? (
          <ImpersonatorModal connector={mock} account={address} chain={chain} />
        ) : null}

        {isNetworkSupported ? (
          <>
            <Button aria-haspopup={true} appearance="tertiary" onClick={openModal} className={buttonClasses}>
              {kind === "disconnect" ? (
                <span className="flex items-center justify-center text-sm text-error">
                  <Icon icon={ArrowRightOnRectangleIcon} className="mr-2" />
                  Disconnect
                </span>
              ) : (
                <>
                  <span aria-hidden={true} className="flex items-center justify-center space-x-3">
                    <Jazzicon id={address} size={5} />
                    <AddressDisplay address={address} trimmed={true} etherscan={false} copy={false} />
                  </span>
                  <span className={balanceClasses}>
                    <BigIntDisplay
                      value={balance.data.value}
                      numberFormat={{ currency: network.currency.nativeToken.symbol }}
                    />
                  </span>
                </>
              )}
              <ScreenReaderText>Connected Wallet</ScreenReaderText>
            </Button>

            {children}
          </>
        ) : (
          <>
            <Button aria-haspopup={true} appearance="tertiary" onClick={openChainModal}>
              <span className="text-warning">
                <Icon icon={ExclamationTriangleIcon} />
              </span>
              <ScreenReaderText>Connected Wallet</ScreenReaderText>
              <span aria-hidden={true} className="text-warning">
                Switch to {network.label}
              </span>
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <Button
      appearance={balance.isLoading && balance.isFetching ? "tertiary" : "primary"}
      loading={balance.isLoading && balance.isFetching}
      onClick={openModal}
    >
      Connect Wallet
    </Button>
  );
}
