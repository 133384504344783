import type { Deployment } from "@enzymefinance/environment";
import type { ImageProps } from "@enzymefinance/ui";
import { Image, Skeleton } from "@enzymefinance/ui";
import type { FixedSize } from "@enzymefinance/utils";
import { sizeAttributes, sizeClasses, sizeEdgeLengths } from "@enzymefinance/utils";
import classNames from "classnames";

export interface VaultIconProps extends Omit<ImageProps, "alt" | "height" | "src" | "width"> {
  id?: string;
  size?: FixedSize;
  deployment: Deployment;
  className?: string;
}

export function VaultIcon({ className, deployment, id, size = 10, ...props }: VaultIconProps) {
  const classes = classNames("inline-flex rounded-full", className, sizeClasses[size]);

  return id ? (
    <Image
      alt={id}
      className={classes}
      src={`/vault/${deployment}/${id}/icon?size=${sizeEdgeLengths[size]}`}
      {...sizeAttributes.full}
      {...props}
    />
  ) : (
    <Skeleton className={classes} shape="circle" />
  );
}
