import { Relayer } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useGasRelayerEnabled = createUseValidatedClientQuery(["gas-relayer-enabled"], {
  paramsSchema: address({ allowZeroAddress: false }),
  responseSchema: z.boolean(),
  queryFn: async (comptrollerProxy, client) => Relayer.isRelayerEnabled(client, { comptrollerProxy }),
});
