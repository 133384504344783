import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const sharesWrapperForceTransfer: FunctionConfig<{ sender: Address; recipient: Address }> = {
  signature: "function forceTransfer(address sender, address recipient)",

  Description({ inputs }) {
    return (
      <>
        Force Transfer from {inputs.sender} to {inputs.recipient}
      </>
    );
  },

  Label() {
    return <>Force Transfer</>;
  },
};
