import { address, bigint } from "@enzymefinance/validation";

import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useNativeTokenBalance = createUseValidatedClientQuery(["external-position-by-address"], {
  paramsSchema: address({ allowZeroAddress: false }),
  responseSchema: bigint(),
  queryFn: async (addr, client) => {
    const balance = await client.getBalance({ address: addr });

    return balance;
  },
});
