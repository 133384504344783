import { Form, Input, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { useToasts } from "@enzymefinance/toast";
import { ButtonGroup, Card, ScreenReaderText } from "@enzymefinance/ui";
import { colorScheme, hexColor, safeParse } from "@enzymefinance/validation";
import { log } from "logger/logger";
import { ColorScheme, useUpdateWhiteLabelThemeMutation } from "queries/backend";
import { useCallback, useMemo } from "react";
import { HexColorPicker } from "react-colorful";
import { client } from "utils/backend";
import { useColorScheme } from "utils/hooks/useColorScheme";
import { z } from "zod";

import { ThemeColors } from "./ThemeColors";
import { useWhiteLabel } from "./WhiteLabelProvider";

const defaultPrimaryColor = "#6f56fd";
const defaultSecondaryColor = "#6b7280";

const formSchema = z.object({
  colorScheme: colorScheme(),
  primaryColor: hexColor(),
  secondaryColor: hexColor(),
});

export interface UpdateThemeFormProps {
  close: () => void;
}

export function UpdateThemeForm({ close }: UpdateThemeFormProps) {
  const { toast } = useToasts();
  const { id, colorScheme: scheme, primaryColor, secondaryColor } = useWhiteLabel();
  const [updateWhiteLabelTheme] = useUpdateWhiteLabelThemeMutation({ client });
  const form = useForm({
    defaultValues: {
      colorScheme: scheme ?? undefined,
      primaryColor: primaryColor ?? defaultPrimaryColor,
      secondaryColor: secondaryColor ?? defaultSecondaryColor,
    },
    async onSubmit(values) {
      if (!id) {
        log.error({ category: "UpdateThemeForm", message: "No white label id" });
        toast.error();
        close();

        return;
      }

      try {
        const { data } = await updateWhiteLabelTheme({
          variables: {
            input: {
              colorScheme: values.colorScheme,
              id,
              primaryColor: values.primaryColor,
              secondaryColor: values.secondaryColor,
            },
          },
        });
        const errors = data?.updateWhiteLabelTheme.errors;

        if (errors?.length) {
          errors.forEach((error) => toast.error(error));
          close();

          return;
        }

        toast("Successfully updated!", { description: "The theme has been updated." });
      } catch (error) {
        log.error({ category: "UpdateThemeForm", message: error.message });
        toast.error();
      }

      close();
    },
    schema: formSchema,
  });

  const [colorSchemeWatched, primaryColorWatched, secondaryColorColorWatched] = form.watch([
    "colorScheme",
    "primaryColor",
    "secondaryColor",
  ]);

  const parsedColorSchema = useMemo(() => safeParse(colorScheme(), colorSchemeWatched), [colorSchemeWatched]);
  const parsedPrimaryColor = useMemo(() => safeParse(hexColor(), primaryColorWatched), [primaryColorWatched]);
  const parsedSecondaryColor = useMemo(
    () => safeParse(hexColor(), secondaryColorColorWatched),
    [secondaryColorColorWatched],
  );

  useColorScheme(parsedColorSchema);

  const { setValue, resetField } = form;
  const handleChangePrimaryColor = useCallback((color: any) => setValue("primaryColor", color), [setValue]);
  const handleResetPrimaryColor = useCallback(() => resetField("primaryColor"), [resetField]);
  const handleChangeSecondaryColor = useCallback((color: any) => setValue("secondaryColor", color), [setValue]);
  const handleResetSecondaryColor = useCallback(() => resetField("secondaryColor"), [resetField]);
  const handleClickDark = useCallback(() => setValue("colorScheme", ColorScheme.DARK), [setValue]);
  const handleClickLight = useCallback(() => setValue("colorScheme", ColorScheme.LIGHT), [setValue]);
  const handleClickSystem = useCallback(() => setValue("colorScheme", ColorScheme.SYSTEM), [setValue]);

  return (
    <>
      <ThemeColors primaryColor={parsedPrimaryColor} secondaryColor={parsedSecondaryColor} />
      <Form form={form}>
        <div className="space-y-6">
          <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
            <div className="space-y-3">
              <div className="flex items-end space-x-2">
                <Input
                  className="w-[202px]"
                  cornerHint={
                    parsedPrimaryColor === defaultPrimaryColor ? null : (
                      <button
                        className="text-primary focus-visible:ring-primary dark:hover:text-primary-light hover:text-primary-dark focus-visible:ring-offset-base-100 max-w-full rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                        onClick={handleResetPrimaryColor}
                        type="button"
                      >
                        Reset
                      </button>
                    )
                  }
                  label="Primary Color"
                  name="primaryColor"
                  placeholder={defaultPrimaryColor}
                  spellCheck={false}
                />
                <div className="h-[38px] w-[38px] rounded-full" style={{ backgroundColor: parsedPrimaryColor }}>
                  <ScreenReaderText>Primary Color</ScreenReaderText>
                </div>
              </div>
              <Card appearance="tertiary">
                <Card.Content>
                  <HexColorPicker color={parsedPrimaryColor} onChange={handleChangePrimaryColor} />
                </Card.Content>
              </Card>
            </div>

            <div className="space-y-3">
              <div className="flex items-end space-x-2">
                <Input
                  className="w-[202px]"
                  cornerHint={
                    parsedSecondaryColor === defaultSecondaryColor ? null : (
                      <button
                        className="text-primary focus-visible:ring-primary dark:hover:text-primary-light hover:text-primary-dark focus-visible:ring-offset-base-100 max-w-full rounded text-right transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                        onClick={handleResetSecondaryColor}
                        type="button"
                      >
                        Reset
                      </button>
                    )
                  }
                  label="Secondary Color"
                  name="secondaryColor"
                  placeholder={defaultSecondaryColor}
                  spellCheck={false}
                />
                <div className="h-[38px] w-[38px] rounded-full" style={{ backgroundColor: parsedSecondaryColor }}>
                  <ScreenReaderText>Secondary Color</ScreenReaderText>
                </div>
              </div>
              <Card appearance="tertiary">
                <Card.Content>
                  <HexColorPicker color={parsedSecondaryColor} onChange={handleChangeSecondaryColor} />
                </Card.Content>
              </Card>
            </div>
          </div>
          <Card appearance="tertiary">
            <Card.Content>
              <div className="flex items-center justify-between space-x-3">
                <p className="text-sm font-semibold">Color Scheme</p>
                <ButtonGroup>
                  <ButtonGroup.Button active={parsedColorSchema === ColorScheme.DARK} onClick={handleClickDark}>
                    Dark
                  </ButtonGroup.Button>
                  <ButtonGroup.Button active={parsedColorSchema === ColorScheme.LIGHT} onClick={handleClickLight}>
                    Light
                  </ButtonGroup.Button>
                  <ButtonGroup.Button active={parsedColorSchema === ColorScheme.SYSTEM} onClick={handleClickSystem}>
                    System
                  </ButtonGroup.Button>
                </ButtonGroup>
              </div>
            </Card.Content>
          </Card>
          <div className="flex flex-col">
            <SubmitButton>Update</SubmitButton>
          </div>
        </div>
      </Form>
    </>
  );
}
