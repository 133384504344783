import type { Address, Asset, CurvePoolGaugeAsset, CurvePoolLpAsset, Environment } from "@enzymefinance/environment";
import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay, TokenIcon, TokenLabel } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { IconLabel } from "@enzymefinance/ui";
import { invariant } from "@enzymefinance/utils";
import { useNetwork } from "components/providers/NetworkProvider";
import { type Hex, decodeAbiParameters, isAddressEqual, parseAbiParameters } from "viem";

export function decodeIncomingAssetsDataRedeemArgs(
  redeemType: Integrations.Curve.RedeemType,
  incomingAssetData: Hex,
):
  | [typeof Integrations.Curve.RedeemType.OneCoin, { incomingAssetPoolIndex: number; minIncomingAssetAmount: bigint }]
  | [typeof Integrations.Curve.RedeemType.Standard, Readonly<bigint[]>] {
  if (redeemType === Integrations.Curve.RedeemType.OneCoin) {
    const [index, minIncomingAssetAmount] = decodeAbiParameters(
      parseAbiParameters("uint256, uint256"),
      incomingAssetData,
    );

    const incomingAssetPoolIndex = Number(index);

    return [Integrations.Curve.RedeemType.OneCoin, { incomingAssetPoolIndex, minIncomingAssetAmount }];
  }

  const [orderedMinIncomingAssetAmounts] = decodeAbiParameters(parseAbiParameters("uint256[]"), incomingAssetData);

  return [Integrations.Curve.RedeemType.Standard, orderedMinIncomingAssetAmounts];
}

export function findCurveLpToken(poolAddress: Address, environment: Environment): CurvePoolLpAsset {
  const poolToken = environment
    .getAssets({ types: [AssetType.CURVE_POOL_LP] })
    .find((asset) => isAddressEqual(asset.pool, poolAddress));

  if (!poolToken) {
    throw new Error("Pool token not found");
  }

  return poolToken;
}

export function DepositTokensDisplay({
  poolOrStakingToken,
  assetAmounts,
  useUnderlyings,
}: {
  poolOrStakingToken: CurvePoolGaugeAsset | CurvePoolLpAsset;
  assetAmounts: Readonly<bigint[]>;
  useUnderlyings: boolean;
}) {
  const { environment } = useNetwork();

  return (
    <div className="space-y-1 py-1">
      {assetAmounts.map((assetAmount, i) => {
        const underlying = poolOrStakingToken.underlyings[i];

        invariant(underlying !== undefined, "underlying is undefined");

        const token = environment.getAsset(underlying);

        if (assetAmount === 0n) {
          return null;
        }

        const depositToken = useUnderlyings && "underlying" in token ? environment.getAsset(token.underlying) : token;

        return <TokenDisplay key={underlying} token={depositToken} amount={assetAmount} className="" />;
      })}
    </div>
  );
}

export function TokenDisplay({
  token,
  amount,
  className = "py-1",
}: {
  token: Asset;
  amount?: bigint;
  className?: string;
}) {
  return (
    <div className={className}>
      {amount ? (
        <IconLabel
          space={2}
          icon={<TokenIcon asset={token} size={5} />}
          title={
            <BigIntDisplay value={amount} decimals={token.decimals} numberFormat={{ currency: `${token.symbol}` }} />
          }
        />
      ) : (
        <TokenLabel size={5} asset={token} hideName={true} />
      )}
    </div>
  );
}

export function RedeemOneTokenDisplay({
  poolOrStakingToken,
  useUnderlyings,
  incomingAssetPoolIndex,
  minIncomingAssetAmount,
}: {
  poolOrStakingToken: CurvePoolGaugeAsset | CurvePoolLpAsset;
  useUnderlyings: boolean;
  incomingAssetPoolIndex: bigint;
  minIncomingAssetAmount: bigint;
}) {
  const { environment } = useNetwork();

  const singleTokenAddress = poolOrStakingToken.underlyings[Number(incomingAssetPoolIndex)];

  invariant(singleTokenAddress !== undefined, "singleTokenAddress is undefined");

  const singleToken = environment.getAsset(singleTokenAddress);

  const token =
    useUnderlyings && "underlying" in singleToken ? environment.getAsset(singleToken.underlying) : singleToken;

  return <TokenDisplay token={token} amount={minIncomingAssetAmount} />;
}
