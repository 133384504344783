import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";
import { callOnExtensionSummary, useWrappedExtensionHandler } from "./extensions";

export const callOnExtension: FunctionConfig<{ extension: Address; actionId: bigint; callArgs: Hex }> = {
  signature: "function callOnExtension(address extension, uint256 actionId, bytes callArgs)",

  Description({ inputs }) {
    const { handler, loading } = useWrappedExtensionHandler(inputs);

    if (!handler || loading) {
      return null;
    }

    const { Description, decodeExtensionArgs } = handler;

    const args = decodeExtensionArgs(inputs.callArgs);

    return <Description args={args} />;
  },

  Label({ inputs }) {
    const { handler, loading } = useWrappedExtensionHandler(inputs);

    if (!handler || loading) {
      return null;
    }

    const { Label, decodeExtensionArgs } = handler;
    const args = decodeExtensionArgs(inputs.callArgs);

    return <Label args={args} />;
  },

  Summary({ inputs }) {
    const { handler, loading } = useWrappedExtensionHandler(inputs);

    if (!handler || loading) {
      return null;
    }

    const { Label, Description, decodeExtensionArgs } = handler;

    const Summary = handler.Summary ?? callOnExtensionSummary(Label, Description);

    const args = decodeExtensionArgs(inputs.callArgs);

    return <Summary args={args} />;
  },
};
