import type { Deployment, DeploymentDefinition } from "@enzymefinance/environment";
import { Kind, getNetwork } from "@enzymefinance/environment";
import { deployments } from "@enzymefinance/environment/deployments/all";
import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import type { SelectProps } from "@enzymefinance/select";
import { Select } from "@enzymefinance/select";
import { useToasts } from "@enzymefinance/toast";
import type { ButtonProps } from "@enzymefinance/ui";
import { Button, IconLabel, Menu, ScreenReaderText, sameWidthModifier } from "@enzymefinance/ui";
import { NetworkIcon } from "components/common/NetworkIcon";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCallback, useMemo } from "react";
import { debug } from "utils/config";

const options = Object.values(deployments);

type DeploymentMenuProps =
  | {
      appearance?: "menu";
      onDeploymentSelect?: () => void;
      size?: "xl";
    }
  | {
      appearance?: "select";
      onDeploymentSelect?: () => void;
      size?: undefined;
    };

export function DeploymentMenu({ appearance = "menu", onDeploymentSelect, size }: DeploymentMenuProps) {
  const { deployment, setDeployment } = useNetwork();
  const { toast } = useToasts();

  // Mobile handler
  const selected = useMemo(() => options.find((option) => deployment === option.slug), [deployment]);
  const handleDeploymentChange = useCallback<
    NonNullable<SelectProps<DeploymentDefinition<Deployment>, false>["onChange"]>
  >(
    (value) => {
      if (value?.slug !== undefined) {
        setDeployment(value.slug);
        onDeploymentSelect?.();
        toast("Switched Network", {
          description: `Selected network is now "${deployments[value.slug].label}"`,
          kind: "info",
        });
      }
    },
    [onDeploymentSelect, setDeployment, toast],
  );

  // Desktop handler
  const switchToDeployment = useCallback(
    (id: Deployment) => () => {
      setDeployment(id);
      onDeploymentSelect?.();
      toast("Switched Network", {
        description: `Selected network is now "${deployments[id].label}"`,
        kind: "info",
      });
    },
    [onDeploymentSelect, setDeployment, toast],
  );

  if (appearance === "select") {
    return (
      <Select<DeploymentDefinition<Deployment>, false>
        onChange={handleDeploymentChange}
        isClearable={false}
        id="select-network"
        getOptionValue={(option) => option.label}
        formatOptionLabel={(option) => (
          <IconLabel
            icon={<NetworkIcon network={getNetwork(option.network)} size={size === "xl" ? 6 : size} />}
            size={size}
            title={option.label}
          />
        )}
        label="Select Network"
        options={options.filter((option) => deployment !== option.slug && (debug || option.kind === Kind.LIVE))}
        value={selected}
      />
    );
  }

  const current = deployments[deployment];

  return (
    <Menu modifiers={[sameWidthModifier]} size={size}>
      <Menu.Button<ButtonProps> as={Button} appearance="tertiary" size={size} trailingIcon={ChevronDownIcon}>
        <span className="inline-flex">
          <ScreenReaderText>Select Network</ScreenReaderText>
          <IconLabel
            aria-hidden={true}
            icon={<NetworkIcon network={getNetwork(current.network)} size={size === "xl" ? 6 : size} />}
            size={size}
            space={3}
            title={size === "xl" ? <p className="text-base">{current.label}</p> : current.label}
          />
        </span>
      </Menu.Button>
      <Menu.Items>
        <Menu.Group>
          <p className="px-4 text-sm">Switch to:</p>
        </Menu.Group>
        <Menu.Group>
          {options
            .filter((option) => deployment !== option.slug && (debug || option.kind === Kind.LIVE))
            .map((option) => (
              <Menu.Item
                as="button"
                key={option.slug}
                onClick={switchToDeployment(option.slug)}
                className="hover:bg-base-100"
              >
                <IconLabel
                  icon={<NetworkIcon network={getNetwork(option.network)} size={size === "xl" ? 6 : size} />}
                  size={size}
                  space={3}
                  title={option.label}
                />
              </Menu.Item>
            ))}
        </Menu.Group>
      </Menu.Items>
    </Menu>
  );
}
