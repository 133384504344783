import { z } from "zod";

import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { address } from "@enzymefinance/validation";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useAliceInstrumentDetails = createUseValidatedClientQuery(["alice-instrument-details"], {
  paramsSchema: z.object({
    aliceOrderManagerAddress: address({ allowZeroAddress: false }),
    instrumentId: z.number(),
    mustBeActive: z.boolean(),
  }),
  responseSchema: z.object({
    id: z.number(),
    enabled: z.boolean(),
    baseAssetAddress: address(),
    quoteAssetAddress: address(),
  }),
  queryFn: async (params, client) => {
    const instrument = await Integrations.Alice.getInstrument(client, {
      aliceOrderManagerAddress: params.aliceOrderManagerAddress,
      instrumentId: params.instrumentId,
      mustBeActive: params.mustBeActive,
    });

    return instrument;
  },
});
