import type { AdapterDefinition, AdapterType, Address, Environment, Version } from "@enzymefinance/environment";

import { isTruthy } from "./utils/boolean.js";

/**
 * Represents an adapter usable in the context of an app, including icon URL
 */
export interface Adapter<TAdapterType extends AdapterType = AdapterType> {
  id: Address;
  name: string;
  url: string;
  type: TAdapterType;
}

interface AddressesToAdaptersArgs {
  addresses: string[];
  environment: Environment<Version.SULU>;
}

export function addressesToAdapters({ addresses, environment }: AddressesToAdaptersArgs): Adapter[] {
  const adapters = addresses
    .map((address) => environment.adapters[address.toLowerCase()])
    .filter(isTruthy)
    .map(toAdapter);

  return adapters;
}

export function getAdapterIconUrl(adapterType: AdapterType) {
  return `/adapters/${adapterType}.png`;
}

export function toAdapter(adapterDefinition: AdapterDefinition): Adapter {
  return {
    id: adapterDefinition.address,
    url: getAdapterIconUrl(adapterDefinition.type),
    name: adapterDefinition.name,
    type: adapterDefinition.type,
  };
}
