import { Depositor, Utils } from "@enzymefinance/sdk";
import { address } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useIsAllowedDepositor = createUseValidatedClientQuery(["is-allowed-depositor"], {
  paramsSchema: z.object({
    currentReleaseId: address({ allowZeroAddress: false }),
    release: address({ allowZeroAddress: false }),
    policyManager: address({ allowZeroAddress: false }),
    allowedDepositRecipientsPolicy: address({ allowZeroAddress: false }),
    comptrollerProxy: address({ allowZeroAddress: false }),
    recipient: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: (params, client) => {
    const isCurrentRelease = Utils.Address.safeSameAddress(params.currentReleaseId, params.release);

    if (!isCurrentRelease) {
      return false;
    }

    return Depositor.isAllowedDepositor(client, {
      allowedDepositRecipientsPolicy: params.allowedDepositRecipientsPolicy,
      policyManager: params.policyManager,
      comptrollerProxy: params.comptrollerProxy,
      depositor: params.recipient,
    });
  },
});
