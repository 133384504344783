import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

export const updatePolicySettingsForFund: FunctionConfig<{
  comptrollerProxy: Address;
  policy: Address;
  settingsData: Hex;
}> = {
  signature:
    "function updatePolicySettingsForFund(address comptrollerProxy, address policy, bytes calldata settingsData)",

  Description({ inputs }) {
    const policyAddress = inputs.policy;

    return <>Update policy at address {policyAddress}.</>;
  },

  Label() {
    return <>Update Policy</>;
  },
};
