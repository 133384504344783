import type { QueryFunctionContext, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { PublicClient } from "viem";
import type { SafeParseReturnType, SafeParseSuccess, TypeOf, ZodError, ZodType } from "zod";

export type QueryKeyWithParams<TQueryKey extends QueryKey, TParamsSchema extends ZodType> = [
  {
    key: TQueryKey;
    params: SafeParseReturnType<TypeOf<TParamsSchema>, TypeOf<TParamsSchema>>;
  },
];

export type QueryFunction<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TDependencies = undefined,
  TQueryKey extends QueryKey = QueryKey,
> = (
  params: TypeOf<TParamsSchema>,
  deps: TDependencies,
  context: QueryFunctionContext<QueryKeyWithParams<TQueryKey, TParamsSchema>>,
) => Promise<TypeOf<TResponseSchema>> | TypeOf<TResponseSchema>;

export type BaseQueryOptions<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<
  TypeOf<TResponseSchema>,
  TError | ZodError<TypeOf<TResponseSchema>>,
  TData,
  QueryKeyWithParams<TQueryKey, TParamsSchema>
>;

export interface UseValidatedQueryOptions<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TDependencies = undefined,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
> extends Omit<BaseQueryOptions<TParamsSchema, TResponseSchema, TError, TData, TQueryKey>, "queryFn" | "queryKey"> {
  dependencies?: TDependencies;
  params?: Partial<TypeOf<TParamsSchema>>;
  paramsSchema: TParamsSchema;
  responseSchema: TResponseSchema;
  queryKey: TQueryKey;
  queryFn: QueryFunction<TParamsSchema, TResponseSchema, TDependencies, TQueryKey>;
}

export function useValidatedQuery<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TDependencies = undefined,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
>({
  dependencies,
  params,
  paramsSchema,
  responseSchema,
  ...options
}: UseValidatedQueryOptions<TParamsSchema, TResponseSchema, TDependencies, TError, TData, TQueryKey>) {
  const input = paramsSchema.safeParse(params);
  const queryKey: QueryKeyWithParams<TQueryKey, TParamsSchema> = [
    {
      key: options.queryKey,
      params: input,
    },
  ];

  return useQuery({
    ...options,
    enabled: (options.enabled ?? true) && input.success,
    queryKey,
    queryFn: async (context) => {
      const parsed = input as SafeParseSuccess<TypeOf<TParamsSchema>>;
      const result = await options.queryFn(parsed.data, dependencies as TDependencies, context);
      const output = responseSchema.safeParse(result);

      if (!output.success) {
        throw output.error;
      }

      return output.data;
    },
  });
}

export type CreateUseValidatedQueryOptions<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TDependencies = undefined,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseValidatedQueryOptions<TParamsSchema, TResponseSchema, TDependencies, TError, TData, TQueryKey>,
  "params" | "queryKey"
>;

export function createUseValidatedQuery<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TDependencies = undefined,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  options: CreateUseValidatedQueryOptions<TParamsSchema, TResponseSchema, TDependencies, TError, TData, TQueryKey>,
) {
  return (
    params?: Partial<TypeOf<TParamsSchema>>,
    opts?: Partial<UseValidatedQueryOptions<TParamsSchema, TResponseSchema, TDependencies, TError, TData, TQueryKey>>,
  ) => {
    return useValidatedQuery<TParamsSchema, TResponseSchema, TDependencies, TError, TData, TQueryKey>({
      ...options,
      params,
      queryKey: key,
      ...opts,
    });
  };
}

export interface UseValidatedClientQueryOptions<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
> extends UseValidatedQueryOptions<TParamsSchema, TResponseSchema, PublicClient, TError, TData, TQueryKey> {
  dependencies: PublicClient;
}

export function useValidatedClientQuery<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseValidatedClientQueryOptions<TParamsSchema, TResponseSchema, TError, TData, TQueryKey>) {
  const network = options.dependencies.chain?.id;

  return useValidatedQuery({
    ...options,
    // TODO: Figure out how to fix this type.
    queryKey: [...options.queryKey, `network:${network}`] as any,
  });
}

export type CreateUseValidatedClientQueryOptions<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseValidatedClientQueryOptions<TParamsSchema, TResponseSchema, TError, TData, TQueryKey>,
  "dependencies" | "params" | "queryKey"
>;

export function createUseValidatedClientQuery<
  TParamsSchema extends ZodType,
  TResponseSchema extends ZodType,
  TError = Error,
  TData = TypeOf<TResponseSchema>,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  options: CreateUseValidatedClientQueryOptions<TParamsSchema, TResponseSchema, TError, TData, TQueryKey>,
) {
  return (
    client: PublicClient,
    params?: Partial<TypeOf<TParamsSchema>>,
    opts?: Partial<UseValidatedClientQueryOptions<TParamsSchema, TResponseSchema, TError, TData, TQueryKey>>,
  ) => {
    return useValidatedClientQuery({
      ...options,
      params,
      dependencies: client,
      queryKey: key,
      ...opts,
    });
  };
}
