export const IntegrationId = {
  AAVE_V2: "aave-v2",
  AAVE_V3: "aave-v3",
  ALICE: "alice",
  ARBITRARY_LOAN: "flexible-loan",
  BALANCER: "balancer",
  COMPOUND_V2: "compound-v2",
  COMPOUND_V3: "compound-v3",
  CONVEX: "convex",
  CURVE: "curve",
  DELEGATE_VOTES: "delegate-votes",
  GOLDFINCH: "goldfinch",
  GMX_V2: "gmx-v2",
  KILN: "kiln",
  LIDO: "lido",
  LIQUITY: "liquity",
  MAPLE: "maple",
  MORPHO: "morpho",
  NOTIONAL: "notional",
  ONE_INCH_V5: "1inch",
  PARASWAP: "paraswap",
  PENDLE_V2: "pendle-v2",
  SPARK: "spark",
  STAKEWISE: "stakewise",
  SWELL: "swell",
  TERM: "term",
  THE_GRAPH: "the-graph",
  THREE_ONE_THIRD: "three-one-third",
  UNISWAP_V2: "uniswap-v2",
  UNISWAP_V3: "uniswap-v3",
  VOTIUM: "votium",
  YEARN: "yearn",
  ZERO_X: "0x",
} as const;

export type IntegrationID = (typeof IntegrationId)[keyof typeof IntegrationId];

export interface Integration {
  description?: string;
  id: IntegrationID;
  title: string;
  icon: string;
  url: string;
  docsUrl?: string;
  /**
   * Slug for the `/vault/{vaultId}/protocols/{vaultSlug}` route.
   * If falsy, integration is not displayed on Vault integrations page / sidebar menu
   */
  vaultSlug?: string;
  isEthereum?: boolean;
  isPolygon?: boolean;
  isArbitrum?: boolean;
  isBase?: boolean;
}

export const integrations: Integration[] = [
  {
    description:
      "Aave V2 is an open source and non-custodial liquidity protocol for earning interest on deposits and borrowing assets.",
    icon: getIntegrationIconUrl("aave.svg"),
    id: IntegrationId.AAVE_V2,
    isEthereum: true,
    isPolygon: true,
    title: "Aave V2",
    url: "https://aave.com",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/aave",
    vaultSlug: "aave-v2",
  },
  {
    description:
      "Aave V3 is an open source and non-custodial liquidity protocol for earning interest. We offer support for V3 supply providers and borrowing.",
    icon: getIntegrationIconUrl("aave.svg"),
    id: IntegrationId.AAVE_V3,
    isArbitrum: true,
    isBase: true,
    isEthereum: true,
    isPolygon: true,
    title: "Aave V3",
    url: "https://aave.com",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/aave",
    vaultSlug: "aave-v3",
  },
  {
    description:
      "Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications.",
    icon: getIntegrationIconUrl("compound.svg"),
    id: IntegrationId.COMPOUND_V2,
    isEthereum: true,
    title: "Compound V2",
    url: "https://compound.finance",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/compound-v2",
    vaultSlug: "compound-v2",
  },
  {
    description:
      "Compound V3 is an EVM compatible protocol that enables supplying of crypto assets as collateral in order to borrow the base asset. Accounts can also earn interest by supplying the base asset to the protocol.",
    icon: getIntegrationIconUrl("compound.svg"),
    id: IntegrationId.COMPOUND_V3,
    isEthereum: true,
    isPolygon: true,
    title: "Compound V3",
    url: "https://compound.finance",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/compound-v3",
    vaultSlug: "compound-v3",
  },
  {
    description:
      "Convex Finance is a platform for CRV token holders and Curve liquidity providers to earn additional interest rewards and Curve trading fees on their tokens.",
    icon: getIntegrationIconUrl("convex.svg"),
    id: IntegrationId.CONVEX,
    isEthereum: true,
    title: "Convex",
    url: "https://www.convexfinance.com",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/convex",
    vaultSlug: "convex",
  },
  {
    description:
      "Curve is an exchange liquidity pool on Ethereum designed for extremely efficient stablecoin trading, low risk, supplemental fee income for liquidity providers, without an opportunity cost.",
    icon: getIntegrationIconUrl("curve.svg"),
    id: IntegrationId.CURVE,
    isEthereum: true,
    isPolygon: true,
    title: "Curve",
    url: "https://curve.fi",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/curve",
    vaultSlug: "curve",
  },
  {
    description:
      "Goldfinch is a decentralised credit protocol for crypto loans to real businesses. The protocol aims to provide the missing piece that finally unlocks crypto lending for most people in the world.",
    icon: getIntegrationIconUrl("goldfinch.svg"),
    id: IntegrationId.GOLDFINCH,
    isEthereum: true,
    title: "Goldfinch",
    url: "https://goldfinch.finance",
  },
  {
    description:
      "Lido is a leading liquid staking solution - providing a simple way to get rewards on your digital tokens.",
    icon: getIntegrationIconUrl("lido.svg"),
    id: IntegrationId.LIDO,
    isEthereum: true,
    title: "Lido",
    url: "https://lido.fi/",
    docsUrl: "https://docs.lido.fi/",
    vaultSlug: "lido",
  },
  {
    description:
      "Maple is a capital-efficient corporate debt marketplace allowing institutions to borrow from Liquidity Pools funded by the Defi ecosystem and managed by experienced investors.",
    icon: getIntegrationIconUrl("maple.svg"),
    id: IntegrationId.MAPLE,
    isEthereum: true,
    title: "Maple Finance",
    url: "https://www.maple.finance",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/maple-finance",
    vaultSlug: "maple",
  },
  {
    description:
      "Morpho is an on-chain peer-to-peer layer on top of lending pools. Rates are seamlessly improved for borrowers and lenders while preserving the same guarantees.",
    icon: getIntegrationIconUrl("morpho.svg"),
    id: IntegrationId.MORPHO,
    isEthereum: true,
    isBase: true,
    title: "Morpho",
    url: "https://morpho.org/",
    vaultSlug: "morpho",
  },
  {
    description:
      "Spark Protocol is the DAI centric money market protocol. Combining the best liquidity directly from Maker and vertically integrating with the best DeFi protocols.",
    icon: getIntegrationIconUrl("spark.svg"),
    id: IntegrationId.SPARK,
    isEthereum: true,
    title: "Spark",
    url: "https://sparkprotocol.io/",
    vaultSlug: "spark",
  },
  {
    description:
      "1inch is a decentralized exchange aggregator that connects multiple DEXs to offer users the best available prices and optimal liquidity for trading cryptocurrencies.",
    icon: getIntegrationIconUrl("1inch.svg"),
    id: IntegrationId.ONE_INCH_V5,
    isArbitrum: true,
    isBase: true,
    isEthereum: true,
    isPolygon: true,
    title: "1inch",
    url: "https://1inch.io/",
  },
  {
    description:
      "ParaSwap is a decentralized middleware aggregator on the Ethereum blockchain that offers the best prices across various DEXs.",
    icon: getIntegrationIconUrl("paraswap.svg"),
    id: IntegrationId.PARASWAP,
    isArbitrum: true,
    isBase: true,
    isEthereum: true,
    isPolygon: true,
    title: "ParaSwap",
    url: "https://paraswap.io",
  },
  {
    description:
      "Uniswap empowers developers, liquidity providers and traders to participate in a financial marketplace that is open and accessible to all.",
    icon: getIntegrationIconUrl("uniswap-v2.svg"),
    id: IntegrationId.UNISWAP_V2,
    isEthereum: true,
    title: "Uniswap v2",
    url: "https://uniswap.org",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/uniswap-v2",
    vaultSlug: "uniswap-v2",
  },
  {
    description:
      "Uniswap V3 is flexible and efficient, allowing liquidity providers to allocate capital in a much more granular manner than its predecessor AMMs.",
    icon: getIntegrationIconUrl("uniswap-v3.svg"),
    id: IntegrationId.UNISWAP_V3,
    isArbitrum: true,
    isEthereum: true,
    isPolygon: true,
    title: "Uniswap v3",
    url: "https://uniswap.org",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/uniswap-v3",
    vaultSlug: "uniswap-v3",
  },
  {
    description:
      "Yearn Finance is a suite of products in Decentralized Finance (DeFi) that provides lending aggregation, yield generation, and insurance on the Ethereum blockchain.",
    icon: getIntegrationIconUrl("yearn.svg"),
    id: IntegrationId.YEARN,
    isEthereum: true,
    title: "Yearn",
    url: "https://yearn.fi",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/yearn",
    vaultSlug: "yearn",
  },
  {
    description: "0x API is a professional grade liquidity aggregator enabling the future of DeFi applications.",
    icon: getIntegrationIconUrl("0x.svg"),
    id: IntegrationId.ZERO_X,
    isEthereum: true,
    title: "0x",
    url: "https://0x.org",
  },
  {
    description:
      "31Third enables seamless on-chain asset management, portfolio rebalancing, and on-chain ETF issuance and redemption with AML-compliant DEX and pre- and post-trade solutions.",
    icon: getIntegrationIconUrl("three-one-third.svg"),
    id: IntegrationId.THREE_ONE_THIRD,
    isEthereum: true,
    isPolygon: true,
    isArbitrum: true,
    title: "31Third",
    url: "https://31third.com/",
  },
  {
    description:
      "Flexible Loan allows for extra flexibility for vault managers and enables a number of potential use cases that can enhance the overall DeFi asset management experience. Funds are sent from the vault to an external borrower but are still accounted for in the vault holdings and need to be repaid by the borrower at some point in the future. Vault managers can keep track of their flexible loan positions (principal + interests) using one of the accounting modules.",
    icon: getIntegrationIconUrl("arbitrary-loan.svg"),
    id: IntegrationId.ARBITRARY_LOAN,
    isEthereum: true,
    isPolygon: true,
    title: "Flexible Loan",
    url: "",
    vaultSlug: "flexible-loan",
  },
  {
    description:
      "Votium is an incentives platform where vlCVX and veCRV holders can receive compensation from buyers interested in amassing voting power.",
    icon: getIntegrationIconUrl("votium.png"),
    id: IntegrationId.VOTIUM,
    title: "Votium",
    url: "https://votium.app",
    isEthereum: true,
  },
  {
    description:
      "Liquity is a decentralized borrowing protocol that allows you to draw 0% interest loans against Ether used as collateral.",
    icon: getIntegrationIconUrl("liquity.svg"),
    id: IntegrationId.LIQUITY,
    isEthereum: true,
    title: "Liquity",
    url: "https://www.liquity.org",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/liquity",
    vaultSlug: "liquity",
  },
  {
    description:
      "Balancer is an automated portfolio manager and trading platform. It turns the concept of an index fund on its head: instead of paying fees to portfolio managers to rebalance your portfolio, you collect fees from traders who rebalance your portfolio by following arbitrage opportunities.",
    icon: getIntegrationIconUrl("balancer.svg"),
    id: IntegrationId.BALANCER,
    isEthereum: true,
    isPolygon: true,
    title: "Balancer",
    url: "https://balancer.fi",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/balancer",
    vaultSlug: "balancer",
  },
  {
    description:
      "Enzyme allows governance token holders to vote by delegating the voting power to another wallet address.",
    icon: getIntegrationIconUrl("delegate-votes.svg"),
    id: IntegrationId.DELEGATE_VOTES,
    isEthereum: true,
    title: "Delegate votes",
    url: "https://docs.enzyme.finance/managers/trade/defi-protocols/delegated-voting",
    vaultSlug: "delegate-votes",
  },
  {
    description:
      "Kiln allows token holders to stake their crypto assets while maintaining custody of their funds. Enzyme's native Kiln integration lets asset managers (with a minimum of 32 ETH) access Kiln’s enterprise-grade ETH native staking pool. Please be aware you must stake ETH in multiples of 32.",
    icon: getIntegrationIconUrl("kiln.svg"),
    id: IntegrationId.KILN,
    isEthereum: true,
    title: "Kiln",
    url: "https://www.kiln.fi/",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/kiln",
    vaultSlug: "kiln",
  },
  {
    description:
      "The Graph is an indexing protocol for querying networks like Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data easily accessible.",
    icon: getIntegrationIconUrl("the-graph.svg"),
    id: IntegrationId.THE_GRAPH,
    isEthereum: true,
    isArbitrum: true,
    title: "The Graph",
    url: "https://thegraph.com/",
    docsUrl: "https://docs.enzyme.finance/managers/trade/defi-protocols/the-graph",
    vaultSlug: "the-graph",
  },
  {
    description: "StakeWise is an open-source protocol for capital-efficient staking on Ethereum.",
    icon: getIntegrationIconUrl("stakewise.svg"),
    id: IntegrationId.STAKEWISE,
    isEthereum: true,
    title: "StakeWise",
    url: "https://stakewise.io/",
    docsUrl: "https://docs.enzyme.finance/getting-started/start-trading/defi-protocols/stakewise-v3",
    vaultSlug: "stakewise",
  },
  {
    description: "Swell is a non-custodial ETH liquid staking protocol that helps you optimize yield in DeFi.",
    icon: getIntegrationIconUrl("swell.svg"),
    id: IntegrationId.SWELL,
    isEthereum: true,
    title: "Swell",
    url: "https://www.swellnetwork.io/",
    docsUrl: "https://docs.enzyme.finance/getting-started/start-trading/defi-protocols/swell",
    vaultSlug: "swell",
  },
  {
    description: "Pendle is a decentralized finance protocol that allows users to tokenize and sell future yields.",
    icon: getIntegrationIconUrl("pendle.svg"),
    id: IntegrationId.PENDLE_V2,
    isEthereum: true,
    title: "Pendle V2",
    url: "https://www.pendle.finance/",
    docsUrl: "https://docs.enzyme.finance/getting-started/start-trading/defi-protocols/pendle-v2",
    vaultSlug: "pendle-v2",
  },
  {
    description:
      "GMX is a decentralized spot and perpetual exchange that supports low swap fees and low price impact trades.",
    icon: getIntegrationIconUrl("gmx.svg"),
    id: IntegrationId.GMX_V2,
    isArbitrum: true,
    title: "GMX V2",
    url: "https://gmx.io/",
    docsUrl: "https://docs.enzyme.finance/getting-started/start-trading/defi-protocols/gmx",
    vaultSlug: "gmx-v2",
  },
  {
    description:
      "LMAX Digital is the institutional spot crypto currency exchange, part of the LMAX Group, which also operates a leading FCA regulated trading venue.",
    icon: getIntegrationIconUrl("lmax.jpg"),
    id: IntegrationId.ALICE,
    isEthereum: true,
    title: "LMAX",
    url: "https://www.lmaxdigital.com/",
    vaultSlug: "lmax",
  },
].sort((a, b) => a.title.localeCompare(b.title));

export const upcomingIntegrations: Integration[] = [
  {
    description: "Term is a decentralized protocol for fixed rate borrowing and lending of crypto assets.",
    icon: getIntegrationIconUrl("term.svg"),
    id: IntegrationId.TERM,
    isEthereum: true,
    title: "Term",
    url: "https://www.term.finance/",
  },
].sort((a, b) => a.title.localeCompare(b.title));

export function getIntegrationIconUrl(id: string) {
  return `/integrations/${id}`;
}
