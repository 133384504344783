import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { IntegrationHandler } from "./types";

export const oneInch5: IntegrationHandler<Integrations.OneInchV5.TakeOrderArgs> = {
  Description({ args: { orderDescription } }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAsset = environment.getAsset(orderDescription.srcToken.toString());
    const buyAsset = environment.getAsset(orderDescription.dstToken.toString());
    const title = "1inch V5";
    const exchangeIcon = getIntegrationIconUrl("1inch.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: BigInt(orderDescription.amount.toString()),
        }),
      [assetPrices, orderDescription.amount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: BigInt(orderDescription.minReturnAmount.toString()),
        }),
      [assetPrices, buyAsset, orderDescription.minReturnAmount],
    );

    return (
      <TokenSwapTransactionSummary
        currency={currency}
        buyAsset={buyAsset}
        sellAsset={sellAsset}
        minIncomingAssetAmount={BigInt(orderDescription.minReturnAmount.toString())}
        outgoingAssetAmount={BigInt(orderDescription.amount.toString())}
        buyValueInCurrency={buyValueInCurrency}
        sellValueInCurrency={sellValueInCurrency}
        title={title}
        exchangeIcon={exchangeIcon}
      />
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.OneInchV5.takeOrderDecode(encodedCallArgs),
};
