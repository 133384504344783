import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const addAssetManagers: FunctionConfig<{ managers: Address[] }> = {
  signature: "function addAssetManagers(address[] calldata managers)",

  Description({ inputs }) {
    const addresses = inputs.managers as string[];

    return (
      <>
        Add asset managers with addresses:
        <ul>
          {addresses.map((address) => (
            <li key={address}>{address}</li>
          ))}
        </ul>
      </>
    );
  },

  Label() {
    return <>Add Asset Managers</>;
  },
};
