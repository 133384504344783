import { AssetType, toAddress } from "@enzymefinance/environment";
import { Rates } from "@enzymefinance/sdk/Utils";
import { addressesToAdapters, invariant, isTruthy } from "@enzymefinance/utils";
import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import type {
  AllowedAdapterIncomingAssetsPolicySettings,
  AllowedAdaptersPolicySettings,
  AllowedAssetsForRedemptionPolicySettings,
  AllowedDepositRecipientsPolicySettings,
  AllowedExternalPositionTypesPerManagerPolicySettings,
  AllowedExternalPositionTypesPolicySettings,
  AllowedSharesTransferRecipientsPolicySettings,
  CumulativeSlippageTolerancePolicySettings,
  EntranceBurnFeeSettings,
  EntranceDirectFeeSettings,
  ExitBurnFeeSettings,
  ExitDirectFeeSettings,
  ManagementFeeSettings,
  MinAssetBalancesPostRedemptionPolicySettings,
  MinMaxDepositPolicySettings,
  NoDepegOnRedeemSharesForSpecificAssetsPolicySettings,
  OnlyRemoveDustExternalPositionPolicySettings,
  OnlyUntrackDustOrPricelessAssetsPolicySettings,
  PerformanceFeeSettings,
} from "components/vault/config/VaultConfigSettingsTypes";
import {
  parseAllowedAdaptersPerManagerPolicySettings,
  parseAllowedAdaptersPolicySettings,
  parseAllowedAssetsForRedemptionPolicySettings,
  parseAllowedDepositRecipientsPolicySettings,
  parseAllowedExternalPositionTypesPerManagerPolicySettings,
  parseAllowedExternalPositionTypesPolicySettings,
  parseAllowedRedeemersForSpecificAssetsPolicySettings,
  parseAllowedSharesTransferRecipientsPolicySettings,
  parseDisallowAdapterIncomingAssetsPolicySettings,
  parseMinAssetBalancesPostRedemptionPolicySettings,
  parseMinMaxDepositPolicySettings,
} from "components/vault/config/VaultConfigSettingsTypes";
import { useVaultDetailsFromComptrollerQuery, useVaultFeesQuery, useVaultPoliciesQuery } from "queries/core";
import { useMemo } from "react";
import { parseUnits } from "viem";
import type { AllowedAdaptersPerManagerPolicySettings } from "../../components/vault/config/VaultConfigSettingsTypes";
import { VaultConfigPolicyListOption } from "../../components/vault/config/VaultConfigTypes";
import { useExternalPositionOptions } from "./useExternalPositionOptions";
import { useSharesActionTimelock } from "./useSharesActionTimelock";

interface ExistingVaultConfigArgs {
  comptrollerProxy: string;
  convertPolicies?: boolean;
}

// Note: returns the vault settings.
// If convertPolicies is true, formats the settings so that they match a current release vault setup form (of current release)
export function useExistingVaultConfig({ comptrollerProxy, convertPolicies = false }: ExistingVaultConfigArgs) {
  const { environment, getAssetsByAddresses, contracts } = useGlobals();
  const { client } = useNetwork();
  const { externalPositionTypes } = useExternalPositions();

  const { data: vaultDetails } = useVaultDetailsFromComptrollerQuery({
    variables: { id: comptrollerProxy.toLowerCase() },
  });

  const denominationAsset = vaultDetails?.comptroller?.denomination.id
    ? environment.getAsset(vaultDetails.comptroller.denomination.id)
    : undefined;

  const existingVault = vaultDetails?.comptroller?.vault;

  const vaultProxy = existingVault?.id;
  const vaultName = existingVault?.name;

  const externalPositionOptions = useExternalPositionOptions();

  const vaultFeesQuery = useVaultFeesQuery({
    variables: { comptroller: comptrollerProxy.toLowerCase() },
  });

  const vaultFeesData = useMemo(
    () => vaultFeesQuery.data?.comptroller?.fees.filter((fee) => !!fee.settings) ?? [],
    [vaultFeesQuery.data],
  );

  const feeSettings = useMemo(() => {
    const feeSettingsArray = vaultFeesData
      .map((feeData) => {
        switch (feeData.__typename) {
          case "EntranceRateBurnFee":
            return { entranceBurnFee: { rate: parseUnits(feeData.rate, 4) } };
          case "EntranceRateDirectFee":
            return {
              entranceDirectFee: {
                rate: parseUnits(feeData.rate, 4),
                recipient: feeData.recipient?.id ?? undefined,
              },
            };
          case "ExitRateDirectFee":
            return {
              exitDirectFee: {
                inKindRate: parseUnits(feeData.inKindRate, 4),
                recipient: feeData.recipient?.id ?? undefined,
                specificAssetsRate: parseUnits(feeData.specificAssetsRate, 4),
              },
            };
          case "ExitRateBurnFee":
            return {
              exitBurnFee: {
                inKindRate: parseUnits(feeData.inKindRate, 4),
                specificAssetsRate: parseUnits(feeData.specificAssetsRate, 4),
              },
            };
          case "ManagementFee":
            return {
              managementFee: {
                rate: Rates.convertScaledPerSecondRateToRate({
                  scaledPerSecondRate: BigInt(feeData.scaledPerSecondRate),
                  adjustInflation: true,
                }),
                recipient: feeData.recipient?.id ?? undefined,
              },
            };
          case "PerformanceFee":
            return {
              performanceFee: {
                period: BigInt(feeData.period) / 86400n,
                rate: parseUnits(feeData.rate, 4),
                recipient: feeData.recipient?.id ?? undefined,
              },
            };
          default:
            return undefined;
        }
      })
      .filter(isTruthy);

    return Object.assign({}, ...feeSettingsArray) as {
      entranceBurnFee?: EntranceBurnFeeSettings;
      entranceDirectFee?: EntranceDirectFeeSettings;
      exitDirectFee?: ExitDirectFeeSettings;
      exitBurnFee?: ExitBurnFeeSettings;
      managementFee?: ManagementFeeSettings;
      performanceFee?: PerformanceFeeSettings;
    };
  }, [vaultFeesData]);

  const policySettingsQuery = useVaultPoliciesQuery({
    variables: { comptroller: comptrollerProxy.toLowerCase() },
  });

  const policySettingsData = useMemo(
    () => policySettingsQuery.data?.comptroller?.policies ?? [],
    [policySettingsQuery.data?.comptroller?.policies],
  );

  const policySettings = useMemo(() => {
    const policySettingsArray = policySettingsData
      .map((policyData) => {
        if (!policyData.enabled) {
          return undefined;
        }

        switch (policyData.__typename) {
          case "AdapterBlacklistPolicy":
            return convertPolicies ? undefined : { adapterBlacklistPolicy: policyData.adapters };

          case "AdapterWhitelistPolicy": {
            const items = addressesToAdapters({
              addresses: policyData.adapters,
              environment,
            });

            const adapterWhitelistPolicy: AllowedAdaptersPolicySettings = {
              listId: undefined,
              selectedOptionType:
                items.length === 0 ? VaultConfigPolicyListOption.EMPTY_LIST : VaultConfigPolicyListOption.CUSTOM_LIST,
              items,
            };

            return convertPolicies
              ? { allowedAdaptersPolicy: adapterWhitelistPolicy }
              : { adapterWhitelistPolicy: policyData.adapters };
          }

          case "AssetBlacklistPolicy":
            return convertPolicies ? undefined : { assetBlacklistPolicy: policyData.assets };

          case "AssetWhitelistPolicy":
            return convertPolicies ? undefined : { assetWhitelistPolicy: policyData.assets };

          case "BuySharesCallerWhitelistPolicy":
            return convertPolicies ? undefined : { buySharesCallerWhitelistPolicy: policyData.callers };

          case "DepositorWhitelistPolicy":
            return convertPolicies
              ? {
                  allowedDepositRecipientsPolicy: {
                    items: policyData.depositors,
                    isDisallowAll: policyData.depositors.length === 0,
                    listId: undefined,
                  },
                  allowedSharesTransferRecipientsPolicy: {
                    items: policyData.depositors,
                    isDisallowAll: policyData.depositors.length === 0,
                    listId: undefined,
                  },
                }
              : { depositorWhitelistPolicy: policyData.depositors };

          case "MaxConcentrationPolicy":
            return convertPolicies ? undefined : { maxConcentrationPolicy: policyData.maxConcentration };

          // V4 Policies
          case "AllowedAdapterIncomingAssetsPolicy": {
            const allowedAdapterIncomingAssetsPolicy: AllowedAdapterIncomingAssetsPolicySettings = getAssetsByAddresses(
              policyData.addressLists.flatMap((addressList) => addressList.items),
            );

            return { allowedAdapterIncomingAssetsPolicy };
          }

          case "AllowedAdaptersPolicy": {
            const allowedAdaptersPolicy = parseAllowedAdaptersPolicySettings(policyData, environment);

            return { allowedAdaptersPolicy };
          }

          case "AllowedAdaptersPerManagerPolicy": {
            const allowedAdaptersPerManagerPolicy = parseAllowedAdaptersPerManagerPolicySettings(
              policyData,
              environment,
            );

            return { allowedAdaptersPerManagerPolicy };
          }

          case "AllowedAssetsForRedemptionPolicy":
            return {
              allowedAssetsForRedemptionPolicy: parseAllowedAssetsForRedemptionPolicySettings(
                policyData,
                getAssetsByAddresses,
              ),
            };

          case "NoDepegOnRedeemSharesForSpecificAssetsPolicy": {
            const noDepegOnRedeemSharesForSpecificAssetsPolicy: NoDepegOnRedeemSharesForSpecificAssetsPolicySettings =
              policyData.assets.map((asset, i) => {
                const referenceAsset = policyData.referenceAssets[i];
                invariant(referenceAsset !== undefined, "Reference asset is undefined");

                const [peggedAsset, peggedReferenceAsset] = getAssetsByAddresses([asset, referenceAsset]);

                invariant(peggedAsset !== undefined, "Pegged asset is undefined");
                invariant(peggedReferenceAsset !== undefined, "Reference asset is undefined");

                return {
                  asset: peggedAsset,
                  referenceAsset: peggedReferenceAsset,
                  deviationTolerance: (Number(policyData.deviationTolerances[i]) * 100).toString(),
                };
              });

            return {
              noDepegOnRedeemSharesForSpecificAssetsPolicy,
            };
          }

          case "AllowedDepositRecipientsPolicy":
            return {
              allowedDepositRecipientsPolicy: parseAllowedDepositRecipientsPolicySettings(policyData),
            };

          case "AllowedExternalPositionTypesPolicy":
            return {
              allowedExternalPositionTypesPolicy: parseAllowedExternalPositionTypesPolicySettings(
                policyData,
                externalPositionTypes,
                contracts,
              ),
            };

          case "AllowedExternalPositionTypesPerManagerPolicy": {
            const allowedExternalPositionTypesPerManagerPolicy =
              parseAllowedExternalPositionTypesPerManagerPolicySettings(policyData, externalPositionOptions);

            return { allowedExternalPositionTypesPerManagerPolicy };
          }

          case "AllowedRedeemersForSpecificAssetsPolicy":
            return {
              allowedRedeemersForSpecificAssetsPolicy: parseAllowedRedeemersForSpecificAssetsPolicySettings(policyData),
            };

          case "AllowedSharesTransferRecipientsPolicy":
            return {
              allowedSharesTransferRecipientsPolicy: parseAllowedSharesTransferRecipientsPolicySettings(policyData),
            };

          case "CumulativeSlippageTolerancePolicy": {
            const cumulativeSlippageTolerancePolicy: CumulativeSlippageTolerancePolicySettings = {
              tolerance: parseUnits(policyData.tolerance, 18),
            };

            return {
              cumulativeSlippageTolerancePolicy,
            };
          }

          case "DisallowedAdapterIncomingAssetsPolicy": {
            return {
              disallowedAdapterIncomingAssetsPolicy: parseDisallowAdapterIncomingAssetsPolicySettings(
                policyData,
                environment,
              ),
            };
          }

          case "GuaranteedRedemptionPolicy":
            return convertPolicies
              ? undefined
              : {
                  guaranteedRedemptionPolicy: {
                    duration: policyData.duration,
                    startTimestamp: policyData.startTimestamp,
                  },
                };

          case "MinAssetBalancesPostRedemptionPolicy": {
            const minAssetBalancesPostRedemptionPolicy = parseMinAssetBalancesPostRedemptionPolicySettings(
              policyData,
              environment,
            );

            return { minAssetBalancesPostRedemptionPolicy };
          }

          case "MinMaxDepositPolicy":
            return {
              minMaxDepositPolicy: parseMinMaxDepositPolicySettings(policyData, denominationAsset?.decimals ?? 18),
            };

          case "OnlyRemoveDustExternalPositionPolicy": {
            const onlyRemoveDustExternalPositionPolicy: OnlyRemoveDustExternalPositionPolicySettings =
              policyData.enabled;

            return {
              onlyRemoveDustExternalPositionPolicy,
            };
          }

          case "OnlyUntrackDustOrPricelessAssetsPolicy": {
            const onlyUntrackDustOrPricelessAssetsPolicy: OnlyUntrackDustOrPricelessAssetsPolicySettings =
              policyData.enabled;

            return {
              onlyUntrackDustOrPricelessAssetsPolicy,
            };
          }
          case "UnknownPolicy":
            return undefined;
        }
      })
      .filter(isTruthy);

    return Object.assign({}, ...policySettingsArray) as {
      adapterBlacklistPolicy?: string[];
      adapterWhitelistPolicy?: string[];
      assetBlacklistPolicy?: string[];
      assetWhitelistPolicy?: string[];
      buySharesCallerWhitelistPolicy?: string[];
      depositorWhitelistPolicy?: string[];
      guaranteedRedemptionPolicy?: { duration: number; startTimestamp: number };
      maxConcentrationPolicy?: bigint;
      allowedAdapterIncomingAssetsPolicy?: AllowedAdapterIncomingAssetsPolicySettings;
      allowedAdaptersPolicy?: AllowedAdaptersPolicySettings;
      allowedAdaptersPerManagerPolicy?: AllowedAdaptersPerManagerPolicySettings;
      allowedAssetsForRedemptionPolicy?: AllowedAssetsForRedemptionPolicySettings;
      noDepegOnRedeemSharesForSpecificAssetsPolicy: NoDepegOnRedeemSharesForSpecificAssetsPolicySettings;
      allowedDepositRecipientsPolicy?: AllowedDepositRecipientsPolicySettings;
      allowedExternalPositionTypesPolicy?: AllowedExternalPositionTypesPolicySettings;
      allowedExternalPositionTypesPerManagerPolicy?: AllowedExternalPositionTypesPerManagerPolicySettings;
      allowedSharesTransferRecipientsPolicy?: AllowedSharesTransferRecipientsPolicySettings;
      cumulativeSlippageTolerancePolicy?: CumulativeSlippageTolerancePolicySettings;
      minAssetBalancesPostRedemptionPolicy?: MinAssetBalancesPostRedemptionPolicySettings;
      minMaxDepositPolicy?: MinMaxDepositPolicySettings;
      onlyRemoveDustExternalPositionPolicy?: OnlyRemoveDustExternalPositionPolicySettings;
      onlyUntrackDustOrPricelessAssetsPolicy?: OnlyUntrackDustOrPricelessAssetsPolicySettings;
    };
  }, [
    policySettingsData,
    convertPolicies,
    contracts,
    getAssetsByAddresses,
    externalPositionTypes,
    denominationAsset?.decimals,
    externalPositionOptions,
    environment,
  ]);

  const sharesActionTimelockQuery = useSharesActionTimelock(client, toAddress(comptrollerProxy));
  const sharesActionTimelock =
    typeof sharesActionTimelockQuery.data === "bigint" ? sharesActionTimelockQuery.data : undefined;

  const ready = !!(typeof sharesActionTimelock !== "undefined" && vaultName && vaultProxy && denominationAsset);

  if (!ready) {
    return undefined;
  }

  if (denominationAsset.type !== AssetType.PRIMITIVE) {
    throw new Error("Denomination asset is not a primitive asset");
  }

  const vaultSettings = {
    denominationAsset,
    sharesActionTimelock,
    vaultName,
    vaultProxy,
    ...feeSettings,
    ...policySettings,
  };

  return vaultSettings;
}
