import { toAddress } from "@enzymefinance/environment";
import { type ReactivateExternalPositionArgs, reactivateExternalPositionDecode } from "@enzymefinance/sdk/Portfolio";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useExternalPositionTypeByAddress } from "utils/hooks/useExternalPositionTypeByAddress";
import type { ExtensionHandler } from "../types";

export const reactivateExternalPosition: ExtensionHandler<ReactivateExternalPositionArgs> = {
  Description({ args }) {
    const { externalPositionProxy: address } = args;

    const { externalPositionTypes } = useExternalPositions();
    const { client } = useNetwork();

    const { data } = useExternalPositionTypeByAddress(client, {
      address: toAddress(address),
      externalPositionTypes,
    });

    if (!data) {
      return <LoadingScreen />;
    }

    const adapter = data.label;

    return <>Reactivate {adapter} position</>;
  },
  Label({ args }) {
    const { externalPositionProxy: address } = args;
    const { externalPositionTypes } = useExternalPositions();
    const { client } = useNetwork();

    const { data } = useExternalPositionTypeByAddress(client, {
      address: toAddress(address),
      externalPositionTypes,
    });

    if (!data) {
      return <LoadingScreen />;
    }

    const adapter = data.label;

    return <>Reactivate {adapter} position</>;
  },
  Summary({ args }) {
    const { externalPositionProxy: address } = args;

    const { externalPositionTypes } = useExternalPositions();
    const { client } = useNetwork();

    const { data } = useExternalPositionTypeByAddress(client, {
      address: toAddress(address),
      externalPositionTypes,
    });

    if (!data) {
      return null;
    }

    const adapter = data.label;

    return <>Reactivate {adapter} position</>;
  },
  decodeExtensionArgs: (encodedCallArgs) => reactivateExternalPositionDecode(encodedCallArgs),
};
