import type { Address, Asset } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import type { AssetPrice } from "components/providers/AssetPricesProvider";
import { formatUnits, isAddressEqual } from "viem";

interface FindTokenCurrencyValueProps {
  assetPrices?: AssetPrice[];
  token?: (Pick<Asset, "decimals"> & { id: Address | string }) | null;
  value?: bigint;
}

export function findTokenValue({ assetPrices, token, value }: FindTokenCurrencyValueProps) {
  if (
    assetPrices === undefined ||
    assetPrices.length === 0 ||
    token === undefined ||
    token === null ||
    value === undefined
  ) {
    return undefined;
  }

  const assetPrice = assetPrices.find((price) => isAddressEqual(price.id, toAddress(token.id)))?.price;

  if (!assetPrice) {
    return undefined;
  }

  const tokenValueInCurrency = Number(formatUnits(value, token.decimals)) * assetPrice;

  return tokenValueInCurrency;
}
