import type { CheckboxProps as CheckboxPropsBase } from "@enzymefinance/ui";
import { Checkbox as CheckboxBase } from "@enzymefinance/ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface CheckboxProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<CheckboxPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function Checkbox<TFieldValues extends FieldValues = FieldValues>(props: CheckboxProps<TFieldValues>) {
  const {
    field: { ref: _, value, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return <CheckboxBase checked={value} error={fieldState.error?.message} {...props} id={props.name} {...field} />;
}
