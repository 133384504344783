import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import type { FieldValues } from "react-hook-form";
import { FormProvider } from "react-hook-form";

import type { UseFormReturn } from "../../hooks/useForm.js";

export interface FormProps<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>
  extends Omit<ComponentPropsWithoutRef<"form">, "onSubmit"> {
  form: UseFormReturn<TInput, TOutput, TContext>;
}

export function Form<TInput extends FieldValues = FieldValues, TOutput = TInput, TContext = any>({
  className,
  form: { onSubmit, onSubmitError, ...form },
  ...props
}: FormProps<TInput, TOutput>) {
  const { clearErrors, reset, resetField, setError } = form;
  const classes = classNames("space-y-4 sm:space-y-6", className);

  return (
    <FormProvider<TInput, TContext> {...form}>
      <form
        {...props}
        className={classes}
        onSubmit={form.handleSubmit(
          (data, event) => onSubmit(data as unknown as TOutput, { clearErrors, reset, resetField, setError }, event),
          (errors, event) => onSubmitError?.(errors, { clearErrors, reset, resetField, setError }, event),
        )}
      />
    </FormProvider>
  );
}
