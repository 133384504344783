import { ToastsProvider } from "@enzymefinance/toast";
import { ErrorBoundary } from "components/error/ErrorBoundary";
import { MobileMenuProvider } from "components/menu/MobileMenuProvider";
import { AssetPricesProvider } from "components/providers/AssetPricesProvider";
import { EnzymeCoreSubgraphProvider } from "components/providers/EnzymeCoreSubgraphProvider";
import { GlobalsProvider } from "components/providers/GlobalsProvider";
import { NetworkProvider } from "components/providers/NetworkProvider";
import { QueryClientProvider } from "components/providers/QueryClientProvider";
import { SettingsProvider } from "components/settings/Settings";
import { TransactionManager } from "components/transactions/manager/TransactionManager";
import type { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { env } from "utils/config";

import { ExternalPositionsProvider } from "./ExternalPositionProvider";
import { WagmiProvider } from "./WagmiProvider.js";

interface CommonProvidersProps {
  children?: ReactNode;
  isWagmiDarkModeOn?: boolean;
}

export function CommonProviders({ children, isWagmiDarkModeOn = true }: CommonProvidersProps) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={env.reCaptchaKey}
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{ appendTo: "head", async: true }}
    >
      <HelmetProvider>
        <QueryClientProvider>
          <MobileMenuProvider>
            <ErrorBoundary>
              <WagmiProvider isDarkModeOn={isWagmiDarkModeOn}>
                <NetworkProvider>
                  <GlobalsProvider>
                    <EnzymeCoreSubgraphProvider>
                      <TransactionManager>
                        <SettingsProvider>
                          <AssetPricesProvider>
                            <ExternalPositionsProvider>
                              <ToastsProvider>{children}</ToastsProvider>
                            </ExternalPositionsProvider>
                          </AssetPricesProvider>
                        </SettingsProvider>
                      </TransactionManager>
                    </EnzymeCoreSubgraphProvider>
                  </GlobalsProvider>
                </NetworkProvider>
              </WagmiProvider>
            </ErrorBoundary>
          </MobileMenuProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  );
}
