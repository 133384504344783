import type { FieldGroupProps, Option } from "@enzymefinance/ui";
import { FieldGroup } from "@enzymefinance/ui";
import classNames from "classnames";
import type { Props } from "react-select";
import ReactSelect from "react-select";

import { useState } from "react";
import { useProps } from "../../hooks/useProps.js";
import { CustomPropsProvider } from "../../shared/context.js";

export interface BaseSelectProps<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>
  extends Props<TOption, TMulti>,
    Pick<FieldGroupProps, "label"> {
  isExpandable?: boolean;
  itemSize?: number;
}

export function BaseSelect<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>(
  props: BaseSelectProps<TOption, TMulti>,
) {
  const { itemSize, isExpandable, label, isDisabled, ...selectProps } = useProps<"select", TOption, TMulti>(props);
  const [open, setOpen] = useState(false);

  return (
    <CustomPropsProvider value={{ isExpandable, itemSize, label }}>
      <ReactSelect<TOption, TMulti>
        className={classNames({ "pointer-events-none": isDisabled })}
        onMenuOpen={() => {
          setOpen(true);
        }}
        onMenuClose={() => {
          setOpen(false);
        }}
        menuIsOpen={open}
        {...selectProps}
        components={{
          ...selectProps.components,
          DropdownIndicator: (props) => {
            if (selectProps.components?.DropdownIndicator) {
              return (
                <selectProps.components.DropdownIndicator {...props} className={classNames({ "rotate-180": open })} />
              );
            }

            return null;
          },
        }}
        isDisabled={isDisabled}
      />
    </CustomPropsProvider>
  );
}

export interface SelectProps<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>
  extends Omit<FieldGroupProps, "kind">,
    Omit<BaseSelectProps<TOption, TMulti>, "id"> {}

export function Select<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>({
  cornerHint,
  error,
  description,
  labelHidden = false,
  ...props
}: SelectProps<TOption, TMulti>) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={props.label}
      labelHidden={labelHidden}
      id={`react-select-${props.id}-input`}
    >
      <BaseSelect<TOption, TMulti> {...props} />
    </FieldGroup>
  );
}
