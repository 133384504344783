import type { FunctionConfig } from "utils/functionsTypes";

export const redemptionQueueWithdrawRequest: FunctionConfig = {
  signature: "function withdrawRequest(uint256 _id)",

  Description() {
    return <>Withdraw redemption request.</>;
  },

  Label() {
    return <>Withdraw Redemption Request</>;
  },
};
