import type { Address } from "@enzymefinance/environment";
import type { ReleaseContracts } from "@enzymefinance/utils";
import { useCallback, useMemo } from "react";
import { getExternalPositionsByAddress } from "utils/externalPositions";

export function useGetExternalPositionByAddress(contracts: ReleaseContracts) {
  const externalPositionsByAddress = useMemo(() => getExternalPositionsByAddress(contracts), [contracts]);

  return useCallback(
    (address: Address | string | undefined) =>
      address ? externalPositionsByAddress[address.toLowerCase()] : undefined,
    [externalPositionsByAddress],
  );
}
