
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ThreeOneThirdRebalanceResponse": [
      "ErrorResponse",
      "ThreeOneThirdRebalanceData"
    ],
    "UserError": [
      "ArgValidationError",
      "GenericUserError",
      "RateLimitError"
    ]
  }
};
      export default result;
    