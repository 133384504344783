import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperSetWindowConfig: FunctionConfig<{
  nextWindowConfig: {
    firstWindowStart: number;
    frequency: number;
    duration: number;
    relativeSharesCap: number;
  };
}> = {
  signature:
    "function setRedemptionWindowConfig((uint64 firstWindowStart, uint32 frequency, uint32 duration, uint64 relativeSharesCap) calldata nextWindowConfig)",

  Description() {
    return <>Edit redemption windows configurations in Shares Wrapper</>;
  },

  Label() {
    return <>Reconfigure Shares Wrapper</>;
  },
};
