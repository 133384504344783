import { z } from "zod";
import type { VaultConfig } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

export const minSharesSupplyFee: VaultConfig<VaultConfigType.MIN_SHARES_SUPPLY_FEE> = {
  address: (contracts) => contracts.MinSharesSupplyFee,
  disableable: false,
  editable: false,
  display: () => <></>,
  fetch: async () => undefined,
  formFields: () => <></>,
  label: "",
  displaySubgraph: () => <></>,
  encode: () => "0x",
  type: VaultConfigType.MIN_SHARES_SUPPLY_FEE,
  validationSchema: z.any(),
};
