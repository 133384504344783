import { useMedia } from "react-use";

export function useBreakpoints() {
  return {
    "2xl": useMedia("(min-width: 1536px)"),
    lg: useMedia("(min-width: 1024px)"),
    md: useMedia("(min-width: 768px)"),
    sm: useMedia("(min-width: 480px)"),
    xl: useMedia("(min-width: 1280px)"),
  };
}
