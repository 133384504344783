import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactElement, ReactNode } from "react";
import { isValidElement } from "react";

import { useTruncated } from "../../hooks/useTruncated.js";
import { Skeleton } from "../layout/Skeleton.js";
import { Tooltip } from "../overlays/Tooltip.js";

export interface IconLabelProps
  extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "title"> {
  appearance?: "card" | "column" | "default";
  description?: ReactElement | string;
  icon?: ReactNode;
  kind?: "default" | "definition" | "option";
  // When passed the default label truncation will be omitted
  lines?: 2;
  loading?: ReactElement | boolean | string;
  size?: "xl";
  space?: 2 | 3 | 4;
  title: ReactNode;
  reverse?: boolean;
  containerClasses?: string;
}

export function IconLabel({
  appearance = "default",
  className,
  containerClasses = "overflow-hidden",
  description,
  icon = null,
  kind = "default",
  lines,
  loading = false,
  size,
  space = 4,
  title,
  reverse = false,
  ...props
}: IconLabelProps) {
  const { ref: titleRef, truncated: titleTruncated } = useTruncated<HTMLSpanElement>();
  const { ref: descriptionRef, truncated: descriptionTruncated } = useTruncated<HTMLSpanElement>();
  const classes = classNames(
    "max-w-full flex items-center",
    {
      "flex-row-reverse space-x-reverse": reverse,
      "py-3 px-4 bg-base-300 rounded-lg": appearance === "card",
      "flex-col": appearance === "column",
      "space-x-2": space === 2 && appearance !== "column",
      "space-x-3": space === 3 && appearance !== "column",
      "space-x-4": space === 4 && appearance !== "column",
    },
    className,
  );

  const titleClasses = classNames("font-medium text-heading-content", {
    block: kind === "option",
    "line-clamp-2": lines === 2,
    "text-sm": size !== "xl",
    truncate: !lines,
  });

  const titleElement = (
    <span className="flex min-w-0">
      <span className={titleClasses} ref={titleRef}>
        {title}
      </span>
    </span>
  );

  const descriptionClasses = classNames("text-base-content text-right", {
    block: kind === "option",
    "group-hover:text-white dark:group-hover:text-white": kind === "option",
    "line-clamp-2": lines === 2,
    "text-sm": size !== "xl",
    truncate: !lines,
  });

  const descriptionElement =
    typeof description === "string" ? (
      <span aria-hidden={true} className={descriptionClasses} ref={descriptionRef}>
        {description}
      </span>
    ) : (
      description
    );

  const textContainerClasses = classNames(containerClasses, {
    "flex flex-col items-end": reverse,
    "flex flex-col items-center": appearance === "column",
  });

  if (isValidElement(loading) || loading === true) {
    return loading === true ? <Skeleton className="h-8 w-24 flex-1" /> : <>{loading}</>;
  }

  return (
    <span className={classes} {...props}>
      {icon}
      <span className={textContainerClasses}>
        {kind === "option" ? (
          titleElement
        ) : (
          <Tooltip.Provider disabled={!titleTruncated}>
            <Tooltip.Item className="flex items-center overflow-hidden">{titleElement}</Tooltip.Item>
            <Tooltip.Panel>{title}</Tooltip.Panel>
          </Tooltip.Provider>
        )}
        {description !== undefined ? (
          kind === "option" ? (
            descriptionElement
          ) : (
            <Tooltip.Provider disabled={!descriptionTruncated}>
              <Tooltip.Item className="flex items-center overflow-hidden">{descriptionElement}</Tooltip.Item>
              <Tooltip.Panel>{description}</Tooltip.Panel>
            </Tooltip.Provider>
          )
        ) : null}
      </span>
    </span>
  );
}
