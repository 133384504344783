// NOTE: This file isolates context creation from the actual implementation so that React Fast Refresh
// doesn't cause errors whenever those files are saved. Those files are commonly edited, so we want to
// make sure that edits in those don't cause too much of a headache.

import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import type { Optional } from "@enzymefinance/utils";
import type { AddTransactionFn } from "components/transactions/manager/TransactionManager";
import type { MachineInterpreter } from "components/transactions/manager/TransactionManagerMachine";
import { createContext } from "react";

function createOptionalContext<TContext>() {
  return createContext<Optional<TContext>>(undefined);
}

export const BackendContext = createOptionalContext<ApolloClient<NormalizedCacheObject>>();
export const TransactionManagerContext = createOptionalContext<readonly [MachineInterpreter, AddTransactionFn]>();
