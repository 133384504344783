import { parseFloatValue } from "@enzymefinance/ui";

import { defaultDecimals } from "../constants.js";

export function formatBigInt(value?: bigint, decimals: number = defaultDecimals) {
  if (value === undefined) {
    return { floatValue: undefined, value: "" };
  }

  const integer = decimals === 0 ? value : value / 10n ** BigInt(decimals);
  const unpaddedFraction = decimals === 0 ? 0n : value % 10n ** BigInt(decimals);

  // Left-padding with zeroes is required to handle small fractions
  // E.g. to differentiate `123` from `123000` (`x.000123` vs. `x.123`)
  const paddedFraction =
    unpaddedFraction === 0n ? `${unpaddedFraction}` : `${unpaddedFraction}`.padStart(decimals, "0").replace(/0+$/, "");

  return {
    floatValue: parseFloatValue(paddedFraction === "0" ? integer : `${integer}.${paddedFraction}`),
    value: `${paddedFraction === "0" ? integer : `${integer}.${paddedFraction}`}`,
  };
}
