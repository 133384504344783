import { PencilSquareIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { PageLayout } from "components/layout/PageLayout";
import { DeploymentMenu } from "components/menu/DeploymentMenu";
import { Link } from "components/routing/Link";
import { VaultProvider } from "components/vault/VaultProvider";
import { VaultNavigation } from "components/vault/heading/VaultNavigation";
import { VaultPageHeading } from "components/vault/heading/VaultPageHeading";
import { Outlet } from "react-router-dom";
import NotFoundRoute from "routes/NotFoundRoute";
import { supportEmailAddress } from "utils/constants";

export function WhiteLabelVaultLayout() {
  return (
    <VaultProvider
      // Need to be passed as prop so that suspense in the loading boundary fallbacks correctly
      fallback={
        <NotFoundRoute
          actions={
            <>
              <DeploymentMenu size="xl" />
              <Button as={Link} leadingIcon={PencilSquareIcon} size="xl" to={`mailto:${supportEmailAddress}`}>
                Report Bug
              </Button>
            </>
          }
          description={
            <>
              <p>We could not find the vault you were looking for.</p>
              <p>
                Try to switch to a different network or send a bug report if you think this should not have happened.
              </p>
            </>
          }
          title="Oh no! Vault not found."
        />
      }
    >
      <PageLayout
        header={
          <>
            <VaultPageHeading />
            <VaultNavigation />
          </>
        }
      >
        <LoadingBoundary>
          <Outlet />
        </LoadingBoundary>
      </PageLayout>
    </VaultProvider>
  );
}
