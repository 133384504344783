import { captureException } from "@sentry/react";
import type { QueryClientProviderProps as ReactQueryClientProviderProps } from "@tanstack/react-query";
import { QueryCache, QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import { hashFn } from "wagmi/query";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => captureException(error),
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
      staleTime: 60000,
      queryKeyHashFn: hashFn,
    },
  },
  queryCache: new QueryCache(),
});

export function QueryClientProvider(props: Partial<ReactQueryClientProviderProps>) {
  return <ReactQueryClientProvider client={client} {...props} />;
}
