import { MinusIcon, PlusIcon } from "@enzymefinance/icons/solid";
import type { FieldGroupProps } from "@enzymefinance/ui";
import { Button, FieldGroup } from "@enzymefinance/ui";
import type { FocusEventHandler, ReactNode } from "react";
import { useCallback, useMemo } from "react";

import type { AddressOption } from "../address-creatable/AddressCreatable.js";
import { AddressCreatable } from "../address-creatable/AddressCreatable.js";
import type { ProtocolSelectOption } from "../protocol-select/ProtocolSelect.js";
import { ProtocolSelect } from "../protocol-select/ProtocolSelect.js";

export interface ProtocolSelectPerAddressError {
  protocols?: string;
  address?: string | { value: string };
}

export interface ProtocolSelectPerAddressValue {
  address?: AddressOption;
  protocols: ProtocolSelectOption[];
}

interface BaseProtocolSelectPerAddressProps {
  error?: ProtocolSelectPerAddressError[];
  id: string;
  label?: ReactNode;
  limit?: number;
  options?: ProtocolSelectOption[];
  onChange?: (value: ProtocolSelectPerAddressValue[]) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: ProtocolSelectPerAddressValue[];
  isMulti?: boolean;
  isExpandable?: boolean;
}

function BaseProtocolSelectPerAddress({
  error,
  id,
  label,
  limit = 10,
  options = [],
  onChange,
  isMulti,
  isExpandable,
  value: valueBase = [],
  ...props
}: BaseProtocolSelectPerAddressProps) {
  // Show one field by default
  const values = useMemo(
    () => (valueBase.length === 0 ? [{ address: undefined, protocols: [] }] : valueBase),
    [valueBase],
  );

  const addNewRow = useCallback(() => {
    onChange?.([...values, { address: undefined, protocols: [] }]);
  }, [onChange, values]);

  const removeRowAtIndex = useCallback(
    (rowIndex: number) => {
      onChange?.(values.filter((_, index) => index !== rowIndex));
    },
    [onChange, values],
  );

  const onProtocolSelectChange = useCallback(
    (rowIndex: number, newValue: ProtocolSelectOption[]) => {
      onChange?.(values.map((value, index) => (index === rowIndex ? { ...value, protocols: newValue } : value)));
    },
    [onChange, values],
  );

  const onAddressChange = useCallback(
    (rowIndex: number, newValue: AddressOption | null) => {
      onChange?.(
        values.map((value, index) => (index === rowIndex ? { ...value, address: newValue ?? undefined } : value)),
      );
    },
    [onChange, values],
  );

  return (
    <div className="space-y-3">
      {values.map((value, rowIndex) => {
        const addressError = error?.[rowIndex]?.address;

        return (
          <div key={rowIndex} className="flex items-start space-x-3">
            <AddressCreatable
              {...props}
              placeholder="Enter manager address..."
              addressComponentProps={{ trimmed: true }}
              id={`${id}-${rowIndex}-address-creatable`}
              error={typeof addressError === "object" ? addressError.value : addressError}
              labelHidden={true}
              isClearable={true}
              value={value.address}
              label={
                <>
                  {label} {rowIndex} Address
                </>
              }
              className="w-64"
              onChange={(inputValue) => onAddressChange(rowIndex, inputValue as AddressOption | null)}
            />
            <div className="w-full">
              <ProtocolSelect
                {...props}
                error={error?.[rowIndex]?.protocols}
                id={`${id}-${rowIndex}-protocol-select`}
                labelHidden={true}
                label={
                  <>
                    {label} {rowIndex} Select
                  </>
                }
                isExpandable={isExpandable}
                isMulti={isMulti}
                options={options}
                onChange={(inputValue) => onProtocolSelectChange(rowIndex, inputValue as ProtocolSelectOption[])}
                value={value.protocols}
              />
            </div>
            <Button
              circular={true}
              disabled={values.length === 1}
              icon={MinusIcon}
              appearance="secondary"
              onClick={() => removeRowAtIndex(rowIndex)}
              size="sm"
            >
              Remove
            </Button>
          </div>
        );
      })}
      <Button circular={true} disabled={values.length + 1 > limit} icon={PlusIcon} onClick={addNewRow} size="lg">
        Add
      </Button>
    </div>
  );
}

export interface ProtocolSelectPerAddressProps
  extends Omit<BaseProtocolSelectPerAddressProps, "error" | "id" | "label">,
    Omit<FieldGroupProps, "error" | "kind"> {
  error?: ProtocolSelectPerAddressError[] | boolean | string;
}

export function ProtocolSelectPerAddress({
  cornerHint,
  error,
  description,
  label,
  labelHidden = false,
  ...props
}: ProtocolSelectPerAddressProps) {
  const isArrayError = Array.isArray(error);

  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={
        isArrayError
          ? error[0]?.protocols ||
            (typeof error[0]?.address === "string" ? error[0]?.address : error[0]?.address?.value)
          : error
      }
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseProtocolSelectPerAddress error={isArrayError ? error : undefined} label={label} {...props} />
    </FieldGroup>
  );
}
