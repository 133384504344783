import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Fees } from "@enzymefinance/sdk/Configuration";
import { Badge, NumberDisplay, StackedList } from "@enzymefinance/ui";
import { bigint } from "@enzymefinance/validation";
import { parseUnits } from "viem";
import { z } from "zod";
import type { ExitBurnFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { ExitFeeFormFields } from "./ExitFee";

// TODO: Check that at least one of these values isn't zero
export const exitRateBurnFeeSchema = z.object({
  inKindRate: bigint().refine(
    (value?: ExitBurnFeeSettings["inKindRate"]) => typeof value === "bigint" && value < parseUnits("1", 4),
    {
      message: "The rate must be less than 100%.",
    },
  ),
  specificAssetsRate: bigint().refine(
    (value?: ExitBurnFeeSettings["specificAssetsRate"]) => typeof value === "bigint" && value < parseUnits("1", 4),
    { message: "The rate must be less than 100%." },
  ),
});

function exitRateBurnFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.EXIT_BURN_FEE>) {
  return (
    <div className="space-y-4">
      <div>
        <BigIntDisplay numberFormat={{ style: "percent" }} value={settings.inKindRate ?? undefined} decimals={4} /> for
        in kind redemptions
      </div>
      <div>
        <BigIntDisplay
          numberFormat={{ style: "percent" }}
          value={settings.specificAssetsRate ?? undefined}
          decimals={4}
        />{" "}
        for specific assets redemptions
      </div>
    </div>
  );
}

function exitRateBurnFeeDisplaySubgraph({ settings }: VaultConfigDisplaySubgraphProps<VaultConfigType.EXIT_BURN_FEE>) {
  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Rate (in kind)">
        <NumberDisplay value={Number(settings.inKindRate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>
      <StackedList.ItemDataBox label="Rate (specific asset)">
        <NumberDisplay value={Number(settings.specificAssetsRate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>
    </StackedList.ItemDataBoxList>
  );
}

export const exitRateBurnFee: VaultConfig<VaultConfigType.EXIT_BURN_FEE> = {
  address: (contracts) => contracts.ExitRateBurnFee,
  disableable: false,
  display: exitRateBurnFeeDisplay,
  displaySubgraph: exitRateBurnFeeDisplaySubgraph,
  editable: false,
  encode: (settings) =>
    Fees.Exit.encodeBurnFeeSettings({
      inKindRateInBps: settings.inKindRate ?? undefined,
      specificAssetsRate: settings.specificAssetsRate ?? undefined,
    }),
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const [inKindRate, specificAssetsRate] = await Promise.all([
        Fees.Exit.getInKindRate(client, { exitRateFee: vaultConfigAddress, comptrollerProxy: comptroller }),
        Fees.Exit.getSpecificAssetsRate(client, {
          exitRateFee: vaultConfigAddress,
          comptrollerProxy: comptroller,
        }),
      ]);

      return { inKindRate, specificAssetsRate };
    } catch {
      return undefined;
    }
  },
  formFields: () => <ExitFeeFormFields selected={VaultConfigType.EXIT_BURN_FEE} />,
  label: "Exit Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>
        If enabled, exit fees are charged with every redemption. This fee is set separately for in-kind redemptions
        (when a user redeems their shares for all the assets held by the vault) or for specific asset redemptions (when
        a user redeems their shares for one or a few of the tokens held by the vault).
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.EXIT_BURN_FEE,
  validationSchema: exitRateBurnFeeSchema,
};
