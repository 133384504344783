import { AddressCreatable, BigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Alert } from "@enzymefinance/ui";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { parseUnits } from "viem";
import { VaultConfigFieldName } from "../VaultConfig";
import type { ExitBurnFeeSettings, ExitDirectFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfigType } from "../VaultConfigTypes";

const rateThreshold = parseUnits("0.05", 4);

interface ExitFeeFormValues {
  [VaultConfigFieldName.EXIT_BURN_FEE]?: ExitBurnFeeSettings;
  [VaultConfigFieldName.EXIT_DIRECT_FEE]?: ExitDirectFeeSettings;
}

export type ExitFeeOption = VaultConfigType.EXIT_BURN_FEE | VaultConfigType.EXIT_DIRECT_FEE;

interface ExitFeeFormFieldsProps {
  selected: ExitFeeOption;
}

export function ExitFeeFormFields({ selected }: ExitFeeFormFieldsProps) {
  const { getFieldState, setValue, watch } = useFormContext<ExitFeeFormValues>();
  const fieldName = VaultConfigFieldName[selected];
  const [value] = watch([fieldName]) as [
    ExitFeeFormValues[VaultConfigFieldName.EXIT_BURN_FEE] | ExitFeeFormValues[VaultConfigFieldName.EXIT_DIRECT_FEE],
  ];
  const { error } = getFieldState(fieldName);
  const inKindRateIsAboveThreshold = useMemo(
    () => value?.inKindRate && !!(value?.inKindRate > rateThreshold),
    [value?.inKindRate],
  );
  const specificAssetsRateIsAboveThreshold = useMemo(
    () => typeof value?.specificAssetsRate === "bigint" && value.specificAssetsRate > rateThreshold,
    [value?.specificAssetsRate],
  );

  // This effect is required to have the underlying components update when DefaultFieldValues is triggered in the FeesStep
  useEffect(() => {
    if (value?.inKindRate !== undefined) {
      setValue(`${fieldName}.inKindRate`, value.inKindRate as never);
    }

    if (value?.specificAssetsRate !== undefined) {
      setValue(`${fieldName}.specificAssetsRate`, value.specificAssetsRate as never);
    }

    if (
      fieldName === VaultConfigFieldName.EXIT_DIRECT_FEE &&
      (value as ExitDirectFeeSettings | undefined)?.recipient !== undefined &&
      (value as ExitDirectFeeSettings | undefined)?.recipient !== null
    ) {
      setValue(`${fieldName}.recipient`, (value as ExitDirectFeeSettings).recipient as never);
    }
  }, [value]);

  return (
    <div className="space-y-3">
      <BigIntInput
        label="Exit Fee Rate for in kind redemptions"
        name={`${fieldName}.inKindRate`}
        numberFormat={{ style: "percent" }}
        decimals={2}
      />

      <AnimatePresence>
        {inKindRateIsAboveThreshold && error === undefined ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <Alert appearance="warning">
              This fee setting is considerably higher than average and may deter potential depositors. Consider setting
              a lower fee.
            </Alert>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <BigIntInput
        label="Exit Fee Rate for redemptions in specific assets"
        name={`${fieldName}.specificAssetsRate`}
        numberFormat={{ style: "percent" }}
        decimals={2}
      />

      <AnimatePresence>
        {specificAssetsRateIsAboveThreshold && error === undefined ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <Alert appearance="warning">
              This fee setting is considerably higher than average and may deter potential depositors. Consider setting
              a lower fee.
            </Alert>
          </motion.div>
        ) : null}
      </AnimatePresence>

      {fieldName === VaultConfigFieldName.EXIT_DIRECT_FEE ? (
        <AddressCreatable
          name={`${fieldName}.recipient`}
          label="Recipient Address (optional)"
          cornerHint="By default, the fee recipient is the vault owner"
        />
      ) : null}
    </div>
  );
}
