import humanizeDuration from "humanize-duration";
import type { ComponentPropsWithoutRef } from "react";

import { defaultLocale } from "../../utils/locale.js";

export interface DurationDisplayProps extends ComponentPropsWithoutRef<"span"> {
  seconds: number;
}

export function DurationDisplay({ seconds, ...props }: DurationDisplayProps) {
  const [locale] = defaultLocale.split("-");
  const duration = humanizeDuration(seconds * 1000, { fallbacks: ["en"], language: locale });

  return <span {...props}>{duration}</span>;
}
