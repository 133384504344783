import type { TokenSelectProps as TokenSelectPropsBase } from "@enzymefinance/ethereum-ui";
import { TokenSelect as TokenSelectBase } from "@enzymefinance/ethereum-ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface TokenSelectProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TokenSelectPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function TokenSelect<TFieldValues extends FieldValues = FieldValues>(props: TokenSelectProps<TFieldValues>) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <TokenSelectBase
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      // TODO: We should use proper types here.
      onChange={onChange as any}
    />
  );
}
