import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

import type { FieldGroupProps } from "./FieldGroup.js";
import { FieldGroup, useFieldGroup } from "./FieldGroup.js";

export type BaseCheckboxProps = Omit<ComponentPropsWithoutRef<"input">, "type">;

function BaseCheckbox({ className, ...props }: BaseCheckboxProps) {
  const { ariaProps, isError } = useFieldGroup();
  const classes = classNames(
    "rounded-md h-4 w-4 bg-base-300 checked:bg-primary-light dark:checked:bg-primary-dark text-primary-dark dark:text-primary-light dark:checked:border-primary-light dark:checked:border-primary-dark ring-offset-white dark:ring-offset-gray-900 focus:ring-primary dark:focus:ring-primary transition",
    {
      "border-gray-200 dark:border-gray-700": !isError,
      "border-error": isError,
    },
    className,
  );

  return <input className={classes} type="checkbox" {...ariaProps} {...props} />;
}

export interface CheckboxProps extends Omit<FieldGroupProps, "cornerHint">, Omit<BaseCheckboxProps, "id"> {}

export function Checkbox({ description, error, label, labelHidden, ...props }: CheckboxProps) {
  return (
    <FieldGroup
      appearance="checkbox"
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseCheckbox {...props} />
    </FieldGroup>
  );
}
