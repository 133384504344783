import { TokenLabel } from "@enzymefinance/ethereum-ui";
import { useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Card, Divider, NumberDisplay } from "@enzymefinance/ui";
import { invariant, isTruthy } from "@enzymefinance/utils";
import { asset } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useUpdateEffect } from "react-use";
import { z } from "zod";

import { isAddressEqual } from "viem";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { NoDepegOnRedeemSharesForSpecificAssetsPolicySettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { NoDepegPolicyAssetInputs } from "./NoDepegPolicyAssetInputs";

export const noDepegOnRedeemSharesForSpecificAssetsPolicySchema = z
  .array(
    z.object({
      asset: asset(),
      referenceAsset: asset(),
      deviationTolerance: z.coerce.number().min(0.01).max(100),
    }),
  )
  .min(1, "Should have at least one asset selected")
  .superRefine((values, context) => {
    return values.every((value, index) => {
      if (isAddressEqual(value.asset.id, value.referenceAsset.id)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid reference asset. Reference asset cannot be the same as the pegged asset.",
          path: [`${index}.referenceAsset]`],
        });

        return false;
      }

      return true;
    });
  });

export interface NoDepegOnRedeemSharesForSpecificAssetsPolicyFormFieldsValues {
  [VaultConfigFieldName.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY]: NoDepegOnRedeemSharesForSpecificAssetsPolicySettings;
}

function noDepegOnRedeemSharesForSpecificAssetsPolicyFormFields() {
  const { trigger, watch } = useFormContext<NoDepegOnRedeemSharesForSpecificAssetsPolicyFormFieldsValues>();
  const assetConfigValues = watch(
    VaultConfigFieldName.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY,
  ) as NoDepegOnRedeemSharesForSpecificAssetsPolicyFormFieldsValues[VaultConfigFieldName.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY];

  useUpdateEffect(() => {
    trigger();
  }, [assetConfigValues]);

  return <NoDepegPolicyAssetInputs />;
}

function noDepegOnRedeemSharesForSpecificAssetsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY>) {
  const { environment } = useGlobals();
  const assetList = settings.map(({ asset, referenceAsset, deviationTolerance }) => {
    return {
      asset: environment.getAsset(asset.id),
      referenceAsset: environment.getAsset(referenceAsset.id),
      deviationTolerance: Number(deviationTolerance) / 100,
    };
  });

  if (!assetList.length) {
    return null;
  }

  const itemWrapperClasses = "flex flex-1 flex-col space-y-2 lg:min-w-[12rem] lg:pb-4";

  return (
    <>
      {assetList.map(({ asset, referenceAsset, deviationTolerance }) => (
        <Card
          key={`${asset}.${referenceAsset}.${deviationTolerance}`}
          appearance="tertiary"
          className="overflow-hidden mb-4"
        >
          <Card.Content className="w-full flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:justify-between lg:flex-wrap">
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Asset</h4>
              <TokenLabel asset={asset} />
            </div>
            <Divider className="lg:hidden !bg-white/30" />
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Reference Asset</h4>
              <TokenLabel asset={referenceAsset} />
            </div>
            <Divider className="lg:hidden !bg-white/30" />
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Deviation Tolerance</h4>
              <NumberDisplay
                className="text-sm"
                value={Number(deviationTolerance)}
                numberFormat={{ style: "percent" }}
              />
            </div>
          </Card.Content>
        </Card>
      ))}
    </>
  );
}

function noDepegOnRedeemSharesForSpecificAssetsPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY>) {
  const { environment } = useGlobals();
  const assetConfigs = settings.assets
    .map((asset, index) => {
      const referenceAsset = settings.referenceAssets[index];
      const deviationTolerance = settings.deviationTolerances[index];
      const registeredAsset = environment.getAsset(asset);
      const registeredReferenceAsset = referenceAsset === undefined ? undefined : environment.getAsset(referenceAsset);

      invariant(asset !== undefined, `Asset ${asset} not found`);
      invariant(referenceAsset !== undefined, `Reference asset ${referenceAsset} not found`);
      invariant(deviationTolerance !== undefined, `Deviation tolerance ${deviationTolerance} not found`);

      return {
        asset: registeredAsset,
        referenceAsset: registeredReferenceAsset,
        deviationTolerance: Number(deviationTolerance),
      };
    })
    .filter(isTruthy);

  const itemWrapperClasses =
    "flex flex-1 flex-row items-center justify-between space-y-2 lg:items-start lg:justify-start lg:flex-col lg:min-w-[12rem]";

  return (
    <>
      {assetConfigs.map(({ asset, referenceAsset, deviationTolerance }) => (
        <Card
          key={`${asset}.${referenceAsset}.${deviationTolerance}`}
          appearance="tertiary"
          className="overflow-hidden mb-4"
        >
          <Card.Content className="w-full flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:justify-between lg:flex-wrap">
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Asset</h4>
              <TokenLabel asset={asset} reverse={true} className="lg:hidden" />
              <div className="hidden lg:block">
                <TokenLabel asset={asset} />
              </div>
            </div>
            <Divider className="lg:hidden !bg-white/30" />
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Reference Asset</h4>
              <TokenLabel asset={referenceAsset} reverse={true} className="lg:hidden" />
              <div className="hidden lg:block">
                <TokenLabel asset={referenceAsset} />
              </div>
            </div>
            <Divider className="lg:hidden !bg-white/30" />
            <div className={itemWrapperClasses}>
              <h4 className="title-lg">Deviation Tolerance</h4>
              <NumberDisplay className="text-sm" value={deviationTolerance} numberFormat={{ style: "percent" }} />
            </div>
          </Card.Content>
        </Card>
      ))}
    </>
  );
}

export const noDepegOnRedeemSharesForSpecificAssetsPolicy: VaultConfig<VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY> =
  {
    address: (contracts) => contracts.NoDepegOnRedeemSharesForSpecificAssetsPolicy,
    disableable: true,
    display: noDepegOnRedeemSharesForSpecificAssetsPolicyDisplay,
    displaySubgraph: noDepegOnRedeemSharesForSpecificAssetsPolicyDisplaySubgraph,
    editable: true,
    encode: (settings) => {
      return Policies.NoDepegOnRedeemSharesForSpecificAssets.encodeSettings({
        assetConfigs: settings.map((setting) => ({
          asset: setting.asset.id,
          referenceAsset: setting.referenceAsset.id,
          deviationToleranceInBps: Number(setting.deviationTolerance) * 100,
        })),
      });
    },
    fetch: async () => undefined,
    formFields: noDepegOnRedeemSharesForSpecificAssetsPolicyFormFields,
    label: "Disallow Specific Asset Redemptions on Depeg",
    managerDescription: (
      <div className="space-y-4">
        <p>
          A policy that disallows specific asset redemptions when the price of one of a list of stable assets deviates
          significantly from its expected peg.
        </p>
        <p>
          If you disable specific-asset redemptions on a stable assets depeg, you can protect your vault&apos;s balances
          of those assets from outflows during a depeg event by configuring{" "}
          <InlineLink
            to="https://specs.enzyme.finance/topics/policies#nodepegonredeemsharesforspecificassetspolicy"
            appearance="link"
          >
            this policy
          </InlineLink>
          .
        </p>
      </div>
    ),
    publicDescription: (
      <p>
        <InlineLink
          to="https://specs.enzyme.finance/topics/policies#nodepegonredeemsharesforspecificassetspolicy"
          appearance="link"
        >
          This policy
        </InlineLink>{" "}
        disallows specific asset redemptions when the price of one of a list of stable assets deviates significantly
        from its expected peg.
      </p>
    ),
    type: VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY,
    validationSchema: noDepegOnRedeemSharesForSpecificAssetsPolicySchema,
  };
