import type {
  MultiTokenBigIntInputError,
  MultiTokenBigIntInputProps as MultiTokenBigIntInputPropsBase,
} from "@enzymefinance/ethereum-ui";
import { MultiTokenBigIntInput as MultiTokenBigIntInputBase } from "@enzymefinance/ethereum-ui";
import { useMemo } from "react";
import type { FieldError, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface MultiTokenBigIntInputProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<MultiTokenBigIntInputPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function MultiTokenBigIntInput<TFieldValues extends FieldValues = FieldValues>(
  props: MultiTokenBigIntInputProps<TFieldValues>,
) {
  const {
    field: { ref: _, onChange, value, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  const errors = useMemo<MultiTokenBigIntInputError[]>(
    () =>
      (fieldState.error as unknown as ({ value?: FieldError; token?: FieldError } | undefined)[] | undefined)?.map(
        (error) => ({
          value: error?.value?.message,
          token: error?.token?.message,
        }),
      ) ?? [],
    [fieldState.error],
  );

  return (
    <MultiTokenBigIntInputBase
      error={errors}
      {...props}
      id={props.name}
      value={value ?? undefined}
      {...field}
      onChange={onChange as any}
    />
  );
}
