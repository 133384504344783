import type { SwitchProps as SwitchPropsBase } from "@enzymefinance/ui";
import { Switch as SwitchBase } from "@enzymefinance/ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface SwitchProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<SwitchPropsBase, "id" | "onChange" | "ref"> {
  name: FieldPath<TFieldValues>;
  onChange?: SwitchPropsBase["onChange"];
  disabled?: SwitchPropsBase["disabled"];
}

export function Switch<TFieldValues extends FieldValues = FieldValues>(props: SwitchProps<TFieldValues>) {
  const {
    field: { ref: _, onChange, value, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <SwitchBase
      checked={value}
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      disabled={props.disabled}
      onChange={(checked: boolean) => {
        onChange(checked);
        props?.onChange?.(checked);
      }}
    />
  );
}
