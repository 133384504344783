import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { Badge, NumberDisplay, Tooltip } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency, useCurrencySlug } from "components/settings/Settings";
import { useVaultPerformanceLatestQuery } from "queries/backend";
import { ActivityType, useVaultActivitiesQuery } from "queries/core";
import { useMemo } from "react";
import { client as backendClient } from "utils/backend";
import { useMultiExternalPositionIsActive } from "utils/hooks/useExternalPositionIsActive";
import { useMultiExternalPositionValue } from "utils/hooks/useExternalPositionValue";
import { untrackedValueThreshold } from "utils/vault";

interface RemovedExternalPositionWarningProps {
  vaultId: Address;
}

export function RemovedExternalPositionWarning({ vaultId }: RemovedExternalPositionWarningProps) {
  // Query all external positions that have been removed
  const { deployment, client } = useNetwork();
  const { environment } = useGlobals();
  const currency = useCurrency();
  const currencySlug = useCurrencySlug();
  const { assetPrices } = useAssetPrices();
  const { data: tradeActivity } = useVaultActivitiesQuery({
    variables: {
      activityTypes: [ActivityType.VAULTSETTINGS],
      vaults: [vaultId],
    },
  });

  const removedExternalPositionsRaw = useMemo(() => {
    return (
      tradeActivity?.vaultActivities
        .map((activity) => {
          if (activity.__typename === "ExternalPositionRemovedEvent") {
            return activity.externalPosition.id;
          }

          return undefined;
        })
        .filter(isTruthy) ?? []
    );
  }, [tradeActivity?.vaultActivities]);

  const { data: externalPositionsIsActive } = useMultiExternalPositionIsActive(client, {
    externalPositions: removedExternalPositionsRaw.map((removedExternalPositionRaw) =>
      toAddress(removedExternalPositionRaw),
    ),
    vault: vaultId,
  });

  const removedExternalPositions = useMemo(() => {
    // Make sure that the externals positions are still inactive
    return removedExternalPositionsRaw.filter(
      (_, index) => externalPositionsIsActive?.[index] && !externalPositionsIsActive[index],
    );
  }, [removedExternalPositionsRaw, externalPositionsIsActive]);

  const { data: externalPositionValues } = useMultiExternalPositionValue(client, {
    assetPrices,
    externalPositions: removedExternalPositions.map((removedExternalPosition) => toAddress(removedExternalPosition)),
    getAsset: environment.getAsset,
  });

  const vaultPerformanceLatest = useVaultPerformanceLatestQuery({
    client: backendClient,
    variables: { currency: currencySlug, network: deployment, vault: vaultId },
  });

  const totalPositionsValue = useMemo(() => {
    const totalCurrencyValue = externalPositionValues?.reduce((previous, current) => previous + current, 0) ?? 0;

    return totalCurrencyValue;
  }, [externalPositionValues]);

  const aum = vaultPerformanceLatest.data?.vaultPerformanceLatest?.grossAssetValue ?? 0;

  const positionsAumPercentage = totalPositionsValue / aum;
  const displayWarning = positionsAumPercentage > untrackedValueThreshold;

  return displayWarning ? (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center p-2">
        <Badge appearance="warning">High Value in Untracked External Positions</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="flex max-w-xs flex-col space-y-4 p-4 text-sm font-normal">
          <div className="space-y-2">
            <span>
              This vault has <NumberDisplay value={totalPositionsValue} numberFormat={{ currency }} /> of value in
              untracked external positions (
              <NumberDisplay numberFormat={{ style: "percent" }} value={positionsAumPercentage} /> of the vault&apos;s
              AUM).
            </span>
            <p>Untracked external positions are not included in the share price</p>
          </div>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}
