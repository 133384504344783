import classNames from "classnames";
import type { ReactElement, ReactNode } from "react";

interface PageLayoutProps {
  className?: string;
  children?: ReactNode;
  header?: ReactElement | null;
}

export function PageLayout({ children, className, header }: PageLayoutProps) {
  const classes = classNames("h-full flex flex-col space-y-8 lg:space-y-16", className);

  return (
    <>
      {header ? <header className="space-y-8 px-4 pt-8 sm:px-0">{header}</header> : null}
      <div className={classes}>{children}</div>
    </>
  );
}
