import type { Address } from "@enzymefinance/environment";
import { Environment, toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";

import { Form, NumberInput, useForm } from "@enzymefinance/hook-form";
import { Asset, Depositor, Utils } from "@enzymefinance/sdk";
import type { Viem } from "@enzymefinance/sdk/Utils";
import { Button, Modal, NumberDisplay } from "@enzymefinance/ui";
import { bigIntInput, maxSlippage as maxSlippageSchema, refine, safeParse } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrency } from "components/settings/Settings";
import type { MachineState } from "components/transactions/TransactionModalMachine";
import type { VaultDetailsFragment } from "queries/core";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { defaultTransactionSlippage } from "utils/constants";
import { findTokenValue } from "utils/currency";
import { useAllowance } from "utils/hooks/useAllowance";
import { useApproveTransactions } from "utils/hooks/useApproveTransactions";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { minimumNumberOfShares } from "utils/vault";
import { formatUnits, isAddressEqual, maxUint256 } from "viem";
import { z } from "zod";
import { decodeTransactionData } from "../../../utils/functions";
import { useGlobals } from "../../providers/GlobalsProvider.js";
import { VaultFlexibleLoanAgreementModal } from "./VaultFlexibleLoanAgreementModal";
import { VaultDepositFormCommonPart } from "./components/VaultDepositFormCommonPart";
import { VaultDepositFormSubmitButton } from "./components/VaultDepositFormSubmitButton.js";

function createSchema(decimals: number) {
  return z
    .object({
      depositAmount: bigIntInput({ decimals }),
      maxDeposit: z.bigint().optional(),
      maxSlippage: maxSlippageSchema(),
      minDeposit: z.bigint().optional(),
      termsAccepted: z
        .boolean()
        .transform(refine((value) => value, { message: "You must accept the Terms & Conditions." })),
      tokenBalance: z.bigint(),
      isFlexibleLoanVault: z.boolean(),
      flexLoansAccepted: z.boolean(),
    })
    .transform(
      refine((value) => value.depositAmount <= value.tokenBalance, {
        message: "The deposit amount exceeds your balance.",
        path: ["depositAmount"],
      }),
    )
    .transform(
      refine(
        (value) =>
          typeof value.minDeposit === "bigint"
            ? value.minDeposit === 0n || value.depositAmount >= value.minDeposit
            : true,
        {
          message: "The deposit amount is below the minimum deposit amount of the vault.",
          path: ["depositAmount"],
        },
      ),
    )
    .transform(
      refine(
        (value) =>
          typeof value.maxDeposit === "bigint"
            ? value.maxDeposit === 0n || value.depositAmount <= value.maxDeposit
            : true,
        {
          message: "The deposit amount exceeds the maximum deposit amount of the vault",
          path: ["depositAmount"],
        },
      ),
    )
    .transform(
      refine((value) => (value.isFlexibleLoanVault ? value.flexLoansAccepted : true), {
        message: "You must accept the Flexible loans terms.",
        path: ["flexLoansAccepted"],
      }),
    );
}

interface VaultDepositDenominationAssetFormProps {
  close: () => void;
  minDeposit?: bigint;
  maxDeposit?: bigint;
  start: (fn: Viem.PopulatedTransaction<any, any>, signerAddress: Address, vaultProxy?: Address) => void;
  state: MachineState;
  changeAssetNode: ReactNode;
  vault: VaultDetailsFragment;
  isFlexibleLoanVault: boolean;
  isFlexibleLoanModalOpen: boolean;
  openFlexibleLoanModal: () => void;
  vaultHasUnknownFeeOrPolicy: boolean;
  depositAmount: bigint;
  setDepositAmount: (value: bigint) => void;
  displayAllowedExternalPositionTypesWarning: boolean;
  vaultEntranceFeeRate: number | undefined;
}

export function VaultDepositDenominationAssetForm({
  close,
  start,
  minDeposit,
  maxDeposit,
  vault,
  state,
  changeAssetNode,
  isFlexibleLoanVault,
  isFlexibleLoanModalOpen,
  openFlexibleLoanModal,
  vaultHasUnknownFeeOrPolicy,
  displayAllowedExternalPositionTypesWarning,
  vaultEntranceFeeRate,
  depositAmount: savedDepositAmount,
  setDepositAmount: setSavedDepositAmount,
}: VaultDepositDenominationAssetFormProps) {
  const { environment } = useGlobals();
  const [signerAddress] = useSigner();

  const { usdt } = environment.namedTokens;
  const { client } = useNetwork();
  const { id: comptrollerProxy, denomination: denominationAsset } = vault.comptroller;
  const balanceQuery = useTokenBalance(client, {
    account: signerAddress,
    token: toAddress(denominationAsset.id),
  });

  const schema = useMemo(() => createSchema(denominationAsset.decimals), [denominationAsset.decimals]);
  const currency = useCurrency();
  const { assetPrices } = useAssetPrices();

  const allowanceQuery = useAllowance(client, {
    account: signerAddress,
    spender: toAddress(comptrollerProxy),
    token: toAddress(denominationAsset.id),
  });

  const isEthereumUsdt =
    Environment.isDeploymentEthereum(environment) && isAddressEqual(toAddress(denominationAsset.id), usdt.id);

  const defaultDepositAmount = useMemo(() => {
    if (savedDepositAmount) {
      return formatUnits(savedDepositAmount, denominationAsset.decimals);
    }

    const minAmount = Utils.BI.min(balanceQuery.data ?? 0n, allowanceQuery.data ?? maxUint256);
    return formatUnits(minAmount, denominationAsset.decimals);
  }, [allowanceQuery.data, denominationAsset.decimals, savedDepositAmount]);

  const form = useForm({
    defaultValues: {
      depositAmount: defaultDepositAmount,
      maxDeposit,
      maxSlippage: `${defaultTransactionSlippage * 100}`,
      minDeposit,
      termsAccepted: false,
      tokenBalance: balanceQuery.data ?? 0n,
      flexLoansAccepted: false,
      isFlexibleLoanVault,
    },
    onSubmit: async (values, helpers) => {
      if (!signerAddress) {
        return helpers.setError("form", {
          message: "Wallet not connected. You must connect your wallet to perform this action.",
        });
      }

      if (allowanceQuery.data === undefined) {
        return helpers.setError("form", {
          message: "Error fetching the allowance",
        });
      }

      try {
        let fn: Viem.PopulatedTransaction<any, any>;

        if (isEthereumUsdt && !(allowanceQuery.data === 0n) && allowanceQuery.data < values.depositAmount) {
          fn = Asset.approve({
            asset: toAddress(denominationAsset.id),
            spender: toAddress(comptrollerProxy),
            amount: 0n,
          });
          start(fn, signerAddress, toAddress(vault.id));
          setSavedDepositAmount(values.depositAmount);
        } else if (allowanceQuery.data < values.depositAmount) {
          fn = Asset.approve({
            asset: toAddress(denominationAsset.id),
            spender: toAddress(comptrollerProxy),
            amount: values.depositAmount,
          });
          start(fn, signerAddress, toAddress(vault.id));
          setSavedDepositAmount(values.depositAmount);
        } else {
          const expectedShares = await Depositor.getExpectedSharesForDeposit(client, {
            comptrollerProxy: toAddress(comptrollerProxy),
            amount: values.depositAmount,
            depositor: signerAddress,
          });

          const minSharesQuantity = Utils.Slippage.multiplyBySlippage({
            slippage: values.maxSlippage,
            value: expectedShares > minimumNumberOfShares ? expectedShares : minimumNumberOfShares,
          });

          fn = Depositor.deposit({
            comptrollerProxy: toAddress(comptrollerProxy),
            amount: values.depositAmount,
            minSharesQuantity,
            depositor: signerAddress,
          });
          start(fn, signerAddress, toAddress(vault.id));
          close();
        }
      } catch (error) {
        return helpers.setError("form", {
          message: `Error submitting the transaction: ${error.message}`,
        });
      }
    },
    schema,
  });

  const {
    setValue,
    watch,
    formState: { errors },
  } = form;
  const [depositAmount, maxSlippage, termsAccepted] = watch(["depositAmount", "maxSlippage", "termsAccepted"]);

  const parsedDepositAmount = safeParse(bigIntInput({ decimals: denominationAsset.decimals }), depositAmount) ?? 0n;

  useTokenBalance(
    client,
    {
      account: signerAddress,
      token: toAddress(denominationAsset.id),
    },
    {
      onSuccess: (data) => {
        setValue("tokenBalance", data);
      },
    },
  );

  const investmentCurrencyValue = useMemo(
    () =>
      findTokenValue({
        assetPrices,
        token: denominationAsset,
        value: parsedDepositAmount,
      }),
    [assetPrices, denominationAsset, parsedDepositAmount],
  );

  const sufficientAllowance = useMemo(
    () =>
      allowanceQuery.data !== undefined &&
      typeof parsedDepositAmount === "bigint" &&
      parsedDepositAmount !== 0n &&
      allowanceQuery.data >= parsedDepositAmount,
    [allowanceQuery.data, parsedDepositAmount],
  );

  const isUsdtLocked = isEthereumUsdt && !(allowanceQuery.data === 0n) && !sufficientAllowance;

  const isSlippageBelowThreshold = Number(maxSlippage) / 100 < 0.01;

  const approveTransactions = useApproveTransactions(comptrollerProxy);
  const isValid = !!(
    form.formState.isValid &&
    !form.formState.isSubmitting &&
    !form.formState.isValidating &&
    allowanceQuery.data
  );

  const isSubmittingApprove = approveTransactions.isSubmitting;
  const isPendingApprove = approveTransactions.isPending;
  const txData = state.context.txData?.data;
  const isBuyShares = txData !== undefined ? decodeTransactionData(txData)?.fragment.name === "buyShares" : false;
  const isSubmittingBuyShares = form.formState.isSubmitting && isBuyShares;

  const balance = useMemo(
    () => (balanceQuery.data ? formatUnits(balanceQuery.data, denominationAsset.decimals) : undefined),
    [balanceQuery.data, denominationAsset.decimals],
  );

  const isNextButtonDisabled = !termsAccepted || errors.depositAmount !== undefined || parsedDepositAmount === 0n;

  return (
    <Form form={form}>
      {isFlexibleLoanModalOpen ? (
        <VaultFlexibleLoanAgreementModal
          submitButton={
            <VaultDepositFormSubmitButton
              asset={denominationAsset}
              isPendingApprove={isPendingApprove}
              isSubmittingApprove={isSubmittingApprove}
              isSubmittingBuyShares={isSubmittingBuyShares}
              isUSDTLocked={isUsdtLocked}
              isValid={isValid}
              sufficientAllowance={sufficientAllowance}
            />
          }
        />
      ) : (
        <>
          <Modal.Body className="space-y-6">
            <p className="text-sm">Choose amount and token to deposit:</p>
            <div className="flex">
              <div className="min-w-0 flex-1">
                <NumberInput
                  appearance="extended"
                  wrapperclassname="rounded-l-lg rounded-r-none"
                  balance={balance}
                  cornerHint={
                    <BigIntDisplay
                      className="text-base-content"
                      decimals={denominationAsset.decimals}
                      error={balanceQuery.isError ? "Balance: N/A" : undefined}
                      fallback="Balance: -"
                      loading={balanceQuery.isLoading && balanceQuery.isFetching}
                      numberFormat={{ currency: denominationAsset.symbol }}
                      value={balanceQuery.data}
                    />
                  }
                  description={
                    <span className="text-base-content">
                      <NumberDisplay
                        appearance="simple"
                        numberFormat={{ currency }}
                        value={investmentCurrencyValue ?? 0}
                      />
                    </span>
                  }
                  label="Amount"
                  name="depositAmount"
                />
              </div>
              <div className="pt-5">{changeAssetNode}</div>
            </div>
            <VaultDepositFormCommonPart
              vault={vault}
              minDeposit={minDeposit}
              maxDeposit={maxDeposit}
              vaultEntranceFeeRate={vaultEntranceFeeRate}
              displaySlippageBelowThresholdWarning={isSlippageBelowThreshold && !form.formState.errors.maxSlippage}
              displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
              vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
            />
          </Modal.Body>
          <Modal.Actions>
            {isFlexibleLoanVault ? (
              <Button appearance="primary" onClick={openFlexibleLoanModal} disabled={isNextButtonDisabled}>
                Next
              </Button>
            ) : (
              <VaultDepositFormSubmitButton
                asset={denominationAsset}
                isPendingApprove={isPendingApprove}
                isSubmittingApprove={isSubmittingApprove}
                isSubmittingBuyShares={isSubmittingBuyShares}
                isUSDTLocked={isUsdtLocked}
                isValid={isValid}
                sufficientAllowance={sufficientAllowance}
              />
            )}
          </Modal.Actions>
        </>
      )}
    </Form>
  );
}
