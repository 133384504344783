import { LifeCycle } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useTimelockRemainingForMigrationRequest = createUseValidatedClientQuery(["migration-timelock-remaining"], {
  paramsSchema: z.object({
    dispatcher: address({ allowZeroAddress: false }),
    vaultProxy: address({ allowZeroAddress: false }),
  }),
  responseSchema: bigint(),
  queryFn: (params, client) =>
    LifeCycle.getRemainingMigrationRequestTimelock(client, {
      vaultProxy: params.vaultProxy,
      dispatcher: params.dispatcher,
    }),
});
