import type { FunctionConfig } from "utils/functionsTypes";
import type { Address, Hex } from "viem";

interface CreateReconfigurationRequestInputs {
  vaultProxy: Address;
  denominationAsset: Address;
  sharesActionTimelock: bigint;
  feeManagerConfigData: Hex;
  policyManagerConfigData: Hex;
}

export const createReconfigurationRequest: FunctionConfig<CreateReconfigurationRequestInputs> = {
  signature:
    "function createReconfigurationRequest(address vaultProxy, address denominationAsset, uint256 sharesActionTimelock, bytes feeManagerConfigData, bytes policyManagerConfigData) returns (address comptrollerProxy)",

  Description() {
    return <>Create a vault reconfiguration request.</>;
  },

  Label() {
    return <>Create Reconfiguration Request</>;
  },
};
