import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@enzymefinance/icons/solid";
import { Icon, Notification, useMotionPresets } from "@enzymefinance/ui";
import classNames from "classnames";
import { motion } from "framer-motion";
import type { ComponentType } from "react";
import { useCallback, useEffect, useRef } from "react";

import type { ToastItem } from "../toasts-provider/ToastsProvider.js";
import { useToasts } from "../toasts-provider/ToastsProvider.js";

const icons: Record<NonNullable<ToastItem["kind"]>, ComponentType> = {
  error: XCircleIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
};

export interface ToastProps extends ToastItem {
  className?: string;
}

export function Toast({
  actions,
  className,
  delay = 5000,
  description,
  icon,
  id,
  kind = "success",
  message,
}: ToastProps) {
  const { filter } = useToasts();
  const motionPresets = useMotionPresets();
  const timeout = useRef<number | undefined>(undefined);
  const clearHideWithDelay = useCallback(() => window.clearTimeout(timeout.current), []);
  const hide = useCallback(() => filter((item) => item.id !== id), [filter, id]);
  const hideWithDelay = useCallback(() => timeout.current === window.setTimeout(hide, delay), [delay, hide]);
  const handleMouseEnter = useCallback(() => clearHideWithDelay(), [clearHideWithDelay]);
  const handleMouseLeave = useCallback(() => hideWithDelay(), [hideWithDelay]);

  useEffect(() => {
    hideWithDelay();

    return clearHideWithDelay;
  }, [clearHideWithDelay, hideWithDelay]);

  const classes = classNames("pointer-events-auto max-w-sm flex flex-col ml-auto", className);

  const iconClasses = classNames({
    "text-info": kind === "info",
    "text-success": kind === "success",
    "text-error": kind === "error",
    "text-warning": kind === "warning",
  });

  return (
    <motion.div className={classes} layout={true} {...motionPresets.default}>
      <Notification
        actions={actions}
        description={description}
        dismiss={hide}
        icon={icon ?? <Icon icon={icons[kind]} className={iconClasses} size={6} aria-hidden={true} />}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        message={message}
      />
    </motion.div>
  );
}
