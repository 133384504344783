import { Environment } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const liquityOpenTrove: ExternalPositionHandler<Integrations.Liquity.OpenTroveArgs> = {
  Description({ args: { collateralAmount, lusdAmount } }) {
    const { environment } = useGlobals();
    const { lusd } = Environment.isDeploymentEthereum(environment) ? environment.namedTokens : { lusd: undefined };
    const { weth } = environment.namedTokens;

    if (!lusd) {
      throw new Error("Liquity asset not found.");
    }

    return (
      <div>
        Sending{" "}
        <BigIntDisplay value={collateralAmount} decimals={weth.decimals} numberFormat={{ currency: weth.symbol }} /> as
        collateral and borrowing{" "}
        <BigIntDisplay value={lusdAmount} decimals={lusd.decimals} numberFormat={{ currency: lusd.symbol }} />
      </div>
    );
  },
  Label() {
    return <>Borrow from Liquity Trove</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Liquity.openTroveDecode(encodedCallArgs),
};

export const liquityBorrow: ExternalPositionHandler<Integrations.Liquity.BorrowArgs> = {
  Description({ args: { lusdAmount } }) {
    const { environment } = useGlobals();
    const { lusd } = Environment.isDeploymentEthereum(environment) ? environment.namedTokens : { lusd: undefined };

    if (!lusd) {
      throw new Error("Liquity asset not found.");
    }

    return (
      <>
        Borrowing <BigIntDisplay value={lusdAmount} decimals={lusd.decimals} numberFormat={{ currency: lusd.symbol }} />
      </>
    );
  },
  Label() {
    return <>Borrow from Liquity Trove</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Liquity.borrowDecode(encodedCallArgs),
};

export const liquityRepay: ExternalPositionHandler<Integrations.Liquity.RepayBorrowArgs> = {
  Description({ args: { lusdAmount } }) {
    const { environment } = useGlobals();
    const { lusd } = Environment.isDeploymentEthereum(environment) ? environment.namedTokens : { lusd: undefined };

    if (!lusd) {
      throw new Error("Liquity asset not found.");
    }

    return (
      <>
        Repaying <BigIntDisplay value={lusdAmount} decimals={lusd.decimals} numberFormat={{ currency: lusd.symbol }} />
      </>
    );
  },
  Label() {
    return <>Repay Borrow Amount in Liquity Trove</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Liquity.repayBorrowDecode(encodedCallArgs),
};

export const liquityAddCollateral: ExternalPositionHandler<Integrations.Liquity.AddCollateralArgs> = {
  Description({ args: { collateralAmount } }) {
    const { environment } = useGlobals();
    const { weth } = environment.namedTokens;

    return (
      <>
        Adding{" "}
        <BigIntDisplay value={collateralAmount} decimals={weth.decimals} numberFormat={{ currency: weth.symbol }} />
      </>
    );
  },
  Label() {
    return <>Add Collateral to Liquity Trove</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Liquity.addCollateralDecode(encodedCallArgs),
};

export const liquityRemoveCollateral: ExternalPositionHandler<Integrations.Liquity.RemoveCollateralArgs> = {
  Description({ args: { collateralAmount } }) {
    const { environment } = useGlobals();
    const { weth } = environment.namedTokens;

    return (
      <>
        Withdraw{" "}
        <BigIntDisplay value={collateralAmount} decimals={weth.decimals} numberFormat={{ currency: weth.symbol }} />
      </>
    );
  },
  Label() {
    return <>Withdraw Collateral from Liquity Trove</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Liquity.removeCollateralDecode(encodedCallArgs),
};

export const liquityCloseTrove: ExternalPositionHandler = {
  Description() {
    return <>Withdraw all collateral and repay all borrowed LUSD</>;
  },
  Label() {
    return <>Close Liquity Trove</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const createLiquityExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Liquity external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = liquityOpenTrove.decodeExternalPositionArgs(actionArgs);

    return <liquityOpenTrove.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Liquity external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = liquityOpenTrove.decodeExternalPositionArgs(actionArgs);

    return <liquityOpenTrove.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Liquity external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = liquityOpenTrove.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(liquityOpenTrove.Label, liquityOpenTrove.Description);

    return <Summary args={args} />;
  },
};

export const liquityClaimCollateral: ExternalPositionHandler = {
  Description() {
    return <>Claim for collateral left after liquidation</>;
  },
  Label() {
    return <>Claim Liquity Trove Collateral</>;
  },
  decodeExternalPositionArgs: () => null,
};
