import { Alert } from "@enzymefinance/ui";
import { zeroAddress } from "viem";
import { z } from "zod";
import type { VaultConfig } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

export const unknownFee: VaultConfig<VaultConfigType.UNKNOWN_FEE> = {
  address: () => zeroAddress,
  disableable: false,
  display: () => null,
  displaySubgraph: unknownFeeDisplaySubgraph,
  editable: false,
  encode: () => null as never,
  fetch: async () => undefined,
  formFields: null as never,
  label: "Unknown Fee",
  managerDescription: (
    <p>
      This vault has a fee that has not been vetted by the Enzyme Council. Vault configurations that have not been
      vetted can potentially be used for the benefit of malicious vault managers. Only deposit into this vault if you
      are sure of what this vault configuration does.
    </p>
  ),
  type: VaultConfigType.UNKNOWN_FEE,
  validationSchema: z.never(),
};

function unknownFeeDisplaySubgraph() {
  return (
    <Alert appearance="warning" title="Warning">
      This vault has an unknown fee. Please make sure to only deposit into this vault if you know and understand in
      detail how the unknown fee works, otherwise you may potentially lose your whole deposit.
    </Alert>
  );
}
