import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import { isValidElement } from "react";

import type { NumberFormatOptions } from "../../hooks/useNumberFormat.js";
import { useNumberFormat } from "../../hooks/useNumberFormat.js";
import { useTruncated } from "../../hooks/useTruncated.js";
import { Skeleton } from "../layout/Skeleton.js";
import { Tooltip } from "../overlays/Tooltip.js";
import { Popper } from "../overlays/popper/Popper.js";

export type NumberDisplayAppearance = "colorized" | "neutral" | "simple";

export interface NumberDisplayProps extends Omit<ComponentPropsWithoutRef<"span">, "children"> {
  error?: boolean | string;
  fallback?: string;
  appearance?: NumberDisplayAppearance;
  loading?: ReactElement | boolean | string;
  numberFormat?: Omit<NumberFormatOptions, "value">;
  truncate?: boolean;
  value?: number;
}

export function NumberDisplay({
  className,
  appearance = "neutral",
  error: errorBase,
  fallback = "-",
  loading = false,
  numberFormat,
  truncate = true,
  value,
  ...props
}: NumberDisplayProps) {
  const { formattedValue } = useNumberFormat({ ...numberFormat, value });
  const { ref, truncated } = useTruncated();
  const skeletonClasses = classNames("flex-1 h-5 w-24", className);

  if (isValidElement(loading) || loading === true) {
    return loading === true ? <Skeleton className={skeletonClasses} /> : <>{loading}</>;
  }

  const error = typeof errorBase === "boolean" ? (errorBase ? "N/A" : undefined) : errorBase;
  const output = error ?? formattedValue ?? fallback;
  const classes = classNames(
    {
      "text-heading-content": appearance === "neutral" || (appearance === "colorized" && value === 0),
      "text-success": appearance === "colorized" && value && value > 0,
      "text-error": appearance === "colorized" && value && value < 0,
      truncate,
    },
    className,
  );
  const element = (
    <span className={classes} ref={ref} {...props}>
      {output}
    </span>
  );

  if (!truncate) {
    return element;
  }

  return (
    <Tooltip.Provider disabled={!truncated}>
      <Tooltip.Item as="span" className="inline-flex min-w-0 max-w-full">
        {element}
      </Tooltip.Item>
      <Tooltip.Panel arrow={<Popper.Arrow className="border-base-200" />}>{output}</Tooltip.Panel>
    </Tooltip.Provider>
  );
}
