import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactNode } from "react";
import { createContext, createElement, useContext } from "react";

import { Icon } from "../elements/Icon.js";

type NavigationSize = "md" | "sm";
type NavigationDirection = "horizontal" | "vertical";

interface NavigationContextValues {
  size: NavigationSize;
  direction: NavigationDirection;
}

const NavigationContext = createContext<NavigationContextValues | undefined>(undefined);

export function useNavigation() {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error("Missing navigation context");
  }

  return context;
}

export interface NavigationProps extends NonNullable<ComponentPropsWithoutRef<"div">> {
  direction: NavigationDirection;
  size?: NavigationSize;
}

export function Navigation({ className, direction, size = "md", ...props }: NavigationProps) {
  const classes = classNames(
    {
      "flex flex-wrap gap-2": direction === "horizontal",
      "sm:gap-3": direction === "horizontal" && size === "sm",
      "sm:gap-4": direction === "horizontal" && size === "md",
      "space-y-1": direction === "vertical",
    },
    className,
  );

  return (
    <NavigationContext.Provider value={{ direction, size }}>
      <div className={classes} {...props} />
    </NavigationContext.Provider>
  );
}

export type NavigationItemProps<TProps extends {} = {}> = TProps & {
  as?: ComponentType<TProps> | "a" | "button";
  badge?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  icon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  isCurrent?: boolean;
  isActiveHidden?: boolean;
  isSidebar?: boolean;
};

function navigationItem<TProps extends {} = {}>({
  as = "a",
  badge,
  children,
  className,
  disabled = false,
  icon,
  isCurrent,
  isSidebar = false,
  isActiveHidden = false,
  ...props
}: NavigationItemProps<TProps>) {
  const { direction, size } = useNavigation();
  const classes = classNames(
    "tab group capitalize relative flex flex items-center font-medium gap-3 disabled:opacity-50 disabled:cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition",
    {
      "hover:text-heading-content/80 text-base-neutral": !(isCurrent || disabled),
      "text-base-neutral/60 pointer-events-none": disabled,
      "text-heading-content": isCurrent,
      "bg-white/10 rounded-lg": isCurrent && isSidebar,
      "text-xs p-2": size === "sm",
      "text-sm p-3": size === "md" && direction === "vertical",
      "text-sm px-6 py-3": size === "md" && direction === "horizontal",
    },
    className,
  );
  const verticalLineClasses = classNames("absolute -left-2 sm:-left-4 inset-y-0 w-1 rounded-r-lg bg-secondary");
  const horizontalLineClasses = classNames("absolute bottom-0 left-0 border-b-2 border-secondary w-full");
  const iconClasses = classNames("flex-none -translate-x-1 transition", {
    "text-base-content group-hover:text-gray-800 dark:group-hover:text-white": !isCurrent,
    "text-heading-content text-secondary": isCurrent,
  });

  return createElement<any>(
    as,
    { ...props, "aria-current": isCurrent ? "page" : false, className: classes, disabled },
    <>
      {isCurrent && isSidebar && !isActiveHidden ? <span className={verticalLineClasses} /> : null}
      {isCurrent && !isSidebar && !isActiveHidden ? <span className={horizontalLineClasses} /> : null}
      {icon ? <Icon icon={icon} className={iconClasses} size={6} aria-hidden={true} /> : null}
      <span className="flex-1 truncate">{children}</span>
      {badge}
    </>,
  );
}

Navigation.Item = navigationItem;
