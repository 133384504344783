import type { ReactNode } from "react";
import { useColorScheme } from "utils/hooks/useColorScheme";

import { ThemeColors } from "./ThemeColors";
import { useWhiteLabel } from "./WhiteLabelProvider";

interface WhiteLabelThemeProps {
  children?: ReactNode;
}

export function WhiteLabelTheme({ children }: WhiteLabelThemeProps) {
  const { colorScheme, loading, primaryColor, secondaryColor } = useWhiteLabel();
  useColorScheme(colorScheme ?? undefined);

  // Prevent flashing on light color scheme
  if (loading) {
    return null;
  }

  return (
    <>
      <ThemeColors primaryColor={primaryColor ?? undefined} secondaryColor={secondaryColor ?? undefined} />
      {children}
    </>
  );
}
