import type { TokenPickerProps as TokenPickerPropsBase } from "@enzymefinance/ethereum-ui";
import { TokenPicker as TokenPickerBase } from "@enzymefinance/ethereum-ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface TokenPickerProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TokenPickerPropsBase, "id" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function TokenPicker<TFieldValues extends FieldValues = FieldValues>(props: TokenPickerProps<TFieldValues>) {
  const {
    field: { ref: _, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  return (
    <TokenPickerBase
      error={fieldState.error?.message}
      id={props.name}
      {...props}
      {...field}
      onChange={onChange as any}
    />
  );
}

TokenPicker.Button = TokenPickerBase.Button;
