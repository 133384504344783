import { Asset } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useTokenBalance = createUseValidatedClientQuery(["token-balance"], {
  paramsSchema: z.object({
    token: address({ allowZeroAddress: false }),
    account: address({ allowZeroAddress: false }),
  }),
  responseSchema: bigint(),
  queryFn: (params, client) => Asset.getBalanceOf(client, { asset: params.token, owner: params.account }),
});

export const useMultiTokenBalance = createUseValidatedClientQuery(["multi-token-balance"], {
  paramsSchema: z.object({
    tokens: z.array(address({ allowZeroAddress: false })),
    account: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.array(
    z.object({ tokenAddress: address({ caseInsensitive: true, allowZeroAddress: false }), balance: bigint() }),
  ),
  queryFn: async (params, client) => {
    const result = await Asset.getBalancesOf(client, { owner: params.account, assets: params.tokens });

    return result.map((item) => ({ tokenAddress: item.asset, balance: item.amount }));
  },
});
