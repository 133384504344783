import { CheckIcon, Square2StackIcon } from "@enzymefinance/icons/outline";
import { sizeClasses } from "@enzymefinance/utils";
import classNames from "classnames";
import type { HTMLMotionProps } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, MouseEventHandler } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useCopyToClipboard as useCopyToClipboardBase } from "react-use";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import type { TooltipButtonProps, TooltipProviderProps } from "../overlays/Tooltip.js";
import { Tooltip, useTooltip } from "../overlays/Tooltip.js";
import type { IconProps } from "./Icon.js";
import { Icon, defaultIconSize } from "./Icon.js";

interface CopyToClipboardContextValues {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CopyToClipboardContext = createContext<CopyToClipboardContextValues | undefined>(undefined);

function useCopyToClipboard() {
  const context = useContext(CopyToClipboardContext);

  if (!context) {
    throw new Error("Missing copy to clipboard context");
  }

  return context;
}

export interface CopyToClipboardProps extends TooltipProviderProps {
  delay?: number;
  text?: string;
  value: string;
}

export function CopyToClipboard({ children, delay = 2000, text = "Copied!", value, ...props }: CopyToClipboardProps) {
  const [, copyToClipboard] = useCopyToClipboardBase();

  const onClick = useCallback(
    (event: any) => {
      // To avoid copying an empty string
      if (value) {
        event.preventDefault();
        copyToClipboard(value);
      }
    },
    [copyToClipboard, value],
  );

  return (
    <Tooltip.Provider delay={delay} {...props}>
      <CopyToClipboardContext.Provider value={{ onClick }}>{children}</CopyToClipboardContext.Provider>
      <Tooltip.Panel>{text}</Tooltip.Panel>
    </Tooltip.Provider>
  );
}

export interface CopyToClipboardIconProps extends Partial<IconProps> {
  appearance?: "default" | "emphasis";
  title?: string;
}

function copyToClipboardIcon({
  appearance = "default",
  className,
  icon = Square2StackIcon,
  size = defaultIconSize,
  title,
  ...props
}: CopyToClipboardIconProps) {
  const motionPresets = useMotionPresets();
  const { isOpen } = useTooltip();
  const { onClick } = useCopyToClipboard();
  const [clicked, setClicked] = useState(false);
  const handleClick = useCallback<NonNullable<TooltipButtonProps["onClick"]>>(
    (event) => {
      onClick(event);

      if (clicked) {
        return;
      }

      setClicked(true);
    },
    [clicked, onClick],
  );
  const wrapperProps = useMemo<HTMLMotionProps<"span">>(
    () => ({
      animate: motionPresets.scale.animate,
      className: "absolute inset-0",
      exit: motionPresets.scale.exit,
      initial: clicked ? motionPresets.scale.exit : { opacity: 1, scale: 1 },
    }),
    [clicked, motionPresets.scale.animate, motionPresets.scale.exit],
  );
  const classes = classNames(
    {
      "hover:text-base-content transition": !isOpen,
      "text-base-neutral": !isOpen && appearance === "default",
      "text-high-emphasis": !isOpen && appearance === "emphasis",
      "text-success": isOpen,
    },
    className,
  );
  const buttonClasses = classNames(
    "relative inline-flex rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition",
    sizeClasses[size],
  );

  return (
    <Tooltip.Button className={buttonClasses} onClick={handleClick} title={title}>
      <AnimatePresence>
        {isOpen ? (
          <motion.span key="check" {...wrapperProps}>
            <Icon className={classes} icon={CheckIcon} {...props} />
          </motion.span>
        ) : (
          <motion.span key="clipboard" {...wrapperProps}>
            <Icon className={classes} icon={icon} {...props} />
          </motion.span>
        )}
      </AnimatePresence>
    </Tooltip.Button>
  );
}

CopyToClipboard.Icon = copyToClipboardIcon;

export type CopyToClipboardContentProps = ComponentPropsWithoutRef<"button">;

function copyToClipboardContent({
  className,
  onClick: onClickBase,
  type = "button",
  ...props
}: CopyToClipboardContentProps) {
  const { setOpen } = useTooltip();
  const { onClick } = useCopyToClipboard();
  const handleClick = useCallback<CopyToClipboardContextValues["onClick"]>(
    (event) => {
      onClickBase?.(event);
      onClick(event);
      setOpen(true);
    },
    [onClick, onClickBase, setOpen],
  );
  const classes = classNames("focus:outline-none", className);

  return <button className={classes} onClick={handleClick} type={type} {...props} />;
}

CopyToClipboard.Content = copyToClipboardContent;
