import { parseUnits } from "viem";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useMaxPriorityFee = createUseValidatedClientQuery(["max-priority-fee"], {
  paramsSchema: z.undefined(),
  responseSchema: z.object({ fast: z.bigint(), slow: z.bigint(), standard: z.bigint() }),
  queryFn: async (_, client) => {
    try {
      const feeHistoryResult = await client.getFeeHistory({
        blockCount: 5,
        blockTag: "latest",
        rewardPercentiles: [25, 50, 75],
      });

      const maxPriorityFeeHistory = feeHistoryResult.reward ?? [[]];

      const slowPriorityFees = maxPriorityFeeHistory.map((item) => item[0]).filter((value) => value !== undefined);
      const standardPriorityFees = maxPriorityFeeHistory.map((item) => item[1]).filter((value) => value !== undefined);
      const fastPriorityFees = maxPriorityFeeHistory.map((item) => item[2]).filter((value) => value !== undefined);

      // Slow = 5-block priorityFee moving average - 25th Percentile
      // Standard = 5-block priorityFee moving average - 50th Percentile
      // Fast = 5-block priorityFee moving average - 75th Percentile

      const slow = slowPriorityFees.reduce((carry, value) => carry + value, 0n) / BigInt(slowPriorityFees.length);
      const standard =
        standardPriorityFees.reduce((carry, value) => carry + value, 0n) / BigInt(standardPriorityFees.length);
      const fast = fastPriorityFees.reduce((carry, value) => carry + value, 0n) / BigInt(fastPriorityFees.length);

      return { fast, slow, standard };
    } catch {
      return { fast: parseUnits("20", 9), slow: parseUnits("10", 9), standard: parseUnits("5", 9) };
    }
  },
});
