import { EnzymeLoader } from "@enzymefinance/icons";
import { useMotionPresets } from "@enzymefinance/ui";
import { motion } from "framer-motion";

export function LoadingScreen() {
  const motionPresets = useMotionPresets();

  return (
    <motion.div className="flex flex-1 flex-col items-center justify-center" {...motionPresets.scale}>
      <EnzymeLoader />
    </motion.div>
  );
}
