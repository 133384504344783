import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address, formatUnits } from "viem";

export const redeemSharesDetailed: FunctionConfig<{
  sharesQuantity: bigint;
  additionalAssets: Address[];
  assetsToSkip: Address[];
}> = {
  signature:
    "function redeemSharesDetailed(uint256 sharesQuantity, address[] additionalAssets, address[] assetsToSkip) returns (address[] payoutAssets, uint256[] payoutAmounts)",

  Description({ address, inputs }) {
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: address.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;
    const shares = formatUnits(inputs.sharesQuantity, 18);

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Redeem {shares} shares of the {vault?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Redeem Shares</>;
  },
};
