import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const cancelMigration: FunctionConfig<{ vaultProxy: Address; bypassFailure: boolean }> = {
  signature: "function cancelMigration(address vaultProxy, bool bypassFailure)",

  Description({ inputs }) {
    const query = useVaultDetailsQuery({
      variables: { id: inputs.vaultProxy.toLowerCase() },
    });

    const vault = query.data?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Cancel upgrade for {vault?.name ?? "this vault"}.</>;
  },

  Label() {
    return <>Cancel Upgrade</>;
  },
};
