import { BigIntDisplay, TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { invariant } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { IntegrationHandler } from "./types";

export const uniswapV2TakeOrder: IntegrationHandler<Integrations.UniswapV2.TakeOrderArgs> = {
  Description({ args: { path, outgoingAssetAmount, minIncomingAssetAmount } }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAssetAddress = path[0];
    const buyAssetAddress = path[path.length - 1];

    invariant(sellAssetAddress !== undefined, "sellAssetAddress is undefined");
    invariant(buyAssetAddress !== undefined, "buyAssetAddress is undefined");

    const sellAsset = environment.getAsset(sellAssetAddress);
    const buyAsset = environment.getAsset(buyAssetAddress);

    const title = "Uniswap V2";
    const exchangeIcon = getIntegrationIconUrl("uniswap-v2.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: BigInt(outgoingAssetAmount.toString()),
        }),
      [assetPrices, outgoingAssetAmount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: BigInt(minIncomingAssetAmount.toString()),
        }),
      [assetPrices, buyAsset, minIncomingAssetAmount],
    );

    return (
      <TokenSwapTransactionSummary
        currency={currency}
        buyAsset={buyAsset}
        sellAsset={sellAsset}
        minIncomingAssetAmount={BigInt(minIncomingAssetAmount.toString())}
        outgoingAssetAmount={BigInt(outgoingAssetAmount.toString())}
        buyValueInCurrency={buyValueInCurrency}
        sellValueInCurrency={sellValueInCurrency}
        title={title}
        exchangeIcon={exchangeIcon}
      />
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.UniswapV2.takeOrderDecode(encodedCallArgs),
};

export const uniswapV2Lend: IntegrationHandler<Integrations.UniswapV2.LendArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const a = environment.getAsset(args.tokenA);
    const b = environment.getAsset(args.tokenB);

    return (
      <>
        {/* Provide liquidity to Uniswap V2 ({a.symbol}-{b.symbol} pool; {aAmount} {a.symbol} and {bAmount} {b.symbol}) */}
        Provide liquidity to Uniswap V2 ({a.symbol}-{b.symbol} pool;{" "}
        <BigIntDisplay value={args.amountADesired} decimals={a.decimals} numberFormat={{ currency: a.symbol }} /> and{" "}
        <BigIntDisplay value={args.amountBDesired} decimals={b.decimals} numberFormat={{ currency: b.symbol }} />
      </>
    );
  },
  Label() {
    return <>Provide liquidity on Uniswap V2</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.UniswapV2.lendDecode(encodedCallArgs),
};

export const uniswapV2Redeem: IntegrationHandler<Integrations.UniswapV2.RedeemArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const a = environment.getAsset(args.tokenA);
    const b = environment.getAsset(args.tokenB);

    return (
      <>
        Remove liquidity from Uniswap V2 {a.symbol}-{b.symbol} pool. (Withdrawing{" "}
        <BigIntDisplay value={args.poolTokenAmount} /> LP Tokens for{" "}
        <BigIntDisplay value={args.amountAMin} decimals={a.decimals} numberFormat={{ currency: a.symbol }} /> and{" "}
        <BigIntDisplay value={args.amountBMin} decimals={b.decimals} numberFormat={{ currency: b.symbol }} />
      </>
    );
  },
  Label() {
    return <>Remove liquidity from Uniswap V2</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.UniswapV2.redeemDecode(encodedCallArgs),
};
