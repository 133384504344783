import { MinusIcon, PlusIcon } from "@enzymefinance/icons/solid";
import type { FieldGroupProps } from "@enzymefinance/ui";
import { Button, FieldGroup } from "@enzymefinance/ui";
import type { FocusEventHandler, ReactElement } from "react";
import { useCallback, useMemo } from "react";

import type { TokenBigIntInputValue } from "../../types.js";
import { TokenBigIntInput } from "../token-big-int-input/TokenBigIntInput.js";
import type { TokenSelectOption } from "../token-select/TokenSelect.js";

export interface MultiTokenBigIntInputError {
  value?: string;
  token?: string;
}

interface BaseMultiTokenBigIntInputProps {
  error?: MultiTokenBigIntInputError[];
  id: string;
  label?: ReactElement | string | null;
  limit?: number;
  tokens?: TokenSelectOption[];
  onChange?: (value: TokenBigIntInputValue[]) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: TokenBigIntInputValue[];
}

function BaseMultiTokenBigIntInput({
  error,
  id,
  label,
  limit = 10,
  tokens = [],
  onChange,
  onBlur,
  value: valueBase = [],
}: BaseMultiTokenBigIntInputProps) {
  // Show one field by default
  const values = useMemo(() => (valueBase.length === 0 ? [{ token: undefined }] : valueBase), [valueBase]);

  const selectedTokensIdLookup = useMemo(
    () =>
      values.reduce<Record<string, boolean>>(
        (acc, { token }) => (token?.id !== undefined ? { ...acc, [token.id]: true } : acc),
        {},
      ),
    [values],
  );

  const addNewRow = useCallback(() => {
    onChange?.([...values, { token: undefined }]);
  }, [onChange, values]);

  const removeRowAtIndex = useCallback(
    (rowIndex: number) => {
      onChange?.(values.filter((_, index) => index !== rowIndex));
    },
    [onChange, values],
  );

  const onInputChange = useCallback(
    (rowIndex: number, newValue: TokenBigIntInputValue) => {
      onChange?.(values.map((value, index) => (index === rowIndex ? newValue : value)));
    },
    [onChange, values],
  );

  return (
    <div className="space-y-3">
      {values.map((value, rowIndex) => {
        const availableTokens = tokens.filter(
          (token) =>
            // Exclude tokens already selected in other rows
            !selectedTokensIdLookup[token.id] ||
            // But include the one selected, if any, in this row
            value.token?.id === token.id,
        );

        return (
          <div key={rowIndex} className="flex items-center space-x-3">
            <TokenBigIntInput
              error={error?.[rowIndex]?.value ?? error?.[rowIndex]?.token}
              id={`${id}-${rowIndex}`}
              displayErrorLabel={false}
              isClearable={false}
              label={
                <>
                  {label} {rowIndex}
                </>
              }
              labelHidden={true}
              onBlur={onBlur}
              onChange={(inputValue) => onInputChange(rowIndex, inputValue)}
              tokens={availableTokens}
              value={value}
            />
            <Button
              circular={true}
              disabled={values.length === 1}
              icon={MinusIcon}
              appearance="secondary"
              onClick={() => removeRowAtIndex(rowIndex)}
              size="sm"
            >
              Remove
            </Button>
          </div>
        );
      })}
      <Button
        data-testid={`${id}-add`}
        circular={true}
        disabled={values.length + 1 > limit}
        onClick={addNewRow}
        icon={PlusIcon}
        size="lg"
      >
        Add
      </Button>
    </div>
  );
}

export interface MultiTokenBigIntInputProps
  extends Omit<BaseMultiTokenBigIntInputProps, "error" | "id" | "label">,
    Omit<FieldGroupProps, "error" | "kind"> {
  error?: MultiTokenBigIntInputError[] | boolean | string;
}

export function MultiTokenBigIntInput({
  cornerHint,
  error,
  description,
  label,
  labelHidden = false,
  ...props
}: MultiTokenBigIntInputProps) {
  const isArrayError = Array.isArray(error);

  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={isArrayError ? error[0]?.value || error[0]?.token : error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseMultiTokenBigIntInput error={isArrayError ? error : undefined} label={label} {...props} />
    </FieldGroup>
  );
}
