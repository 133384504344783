import { ArrowPathIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import { supportEmailAddress } from "utils/constants";

import { Link } from "../routing/Link";

interface ErrorBoxProps {
  onReset?: () => void;
}

export function ErrorBox({ onReset }: ErrorBoxProps) {
  return (
    <div className="max-w-sm space-y-6">
      <div className="space-y-2">
        <h2 className="text-heading-content/80 text-center text-lg font-bold">Oops! Something went wrong!</h2>
        <p className="text-center text-sm">
          It looks like something did not work as expected. Help us improve your experience by filing a bug report.
        </p>
      </div>
      <div className="flex items-center justify-center space-x-3">
        <Button appearance="secondary" leadingIcon={ArrowPathIcon} onClick={onReset} size="sm">
          Reload
        </Button>
        <Button as={Link} size="sm" to={`mailto:${supportEmailAddress}`}>
          File Bug Report
        </Button>
      </div>
    </div>
  );
}
