import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactElement } from "react";
import { createElement, isValidElement } from "react";

import type { IconProps } from "../elements/Icon.js";
import { Icon } from "../elements/Icon.js";

export type InlineLinkProps<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">> =
  TLinkProps & {
    as: ComponentType<TLinkProps>;
    appearance?: "link" | "primary" | "secondary" | "tertiary" | "quaternary";
    rounded?: "2xl" | "base" | "full" | "lg";
    leadingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">> | ReactElement<IconProps>;
    trailingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">> | ReactElement<IconProps>;
    transitionOnHover?: boolean;
  };

export function InlineLink<TLinkProps extends ComponentPropsWithoutRef<"a"> = ComponentPropsWithoutRef<"a">>({
  as,
  appearance = "primary",
  children,
  className,
  leadingIcon,
  rounded = "base",
  trailingIcon,
  transitionOnHover = false,
  ...props
}: InlineLinkProps<TLinkProps>) {
  const iconClasses = classNames({
    "absolute opacity-0 group-hover:opacity-100 transition": transitionOnHover,
    "group-hover:text-link/60": appearance === "link",
    "group-hover:text-secondary-focus": appearance === "secondary",
    "group-hover:text-gray-700 dark:group-hover:text-gray-200": appearance === "tertiary",
    "group-hover:text-gray-700 dark:group-hover:text-gray-300": appearance === "quaternary",
    "group-hover:text-primary-dark dark:group-hover:text-primary-light": appearance === "primary",
    "left-full": transitionOnHover && trailingIcon,
    "right-full": transitionOnHover && leadingIcon,
  });
  const leading = leadingIcon ? (
    isValidElement(leadingIcon) ? (
      leadingIcon
    ) : (
      <Icon className={iconClasses} icon={leadingIcon} />
    )
  ) : null;
  const trailing = trailingIcon ? (
    isValidElement(trailingIcon) ? (
      trailingIcon
    ) : (
      <Icon className={iconClasses} icon={trailingIcon} />
    )
  ) : null;

  const classes = classNames(
    "max-w-full focus:outline-none focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition",
    {
      "group relative": transitionOnHover,
      "hover:-translate-x-4": transitionOnHover && trailingIcon,
      "hover:translate-x-4": transitionOnHover && leadingIcon,
      "inline-flex items-center space-x-1": leading || trailing,
      rounded: rounded === "base",
      "rounded-2xl": rounded === "2xl",
      "rounded-full": rounded === "full",
      "rounded-lg": rounded === "lg",
      "text-base-content hover:text-gray-700 dark:hover:text-gray-300": appearance === "quaternary",
      "text-heading-content hover:text-gray-700 dark:hover:text-gray-200": appearance === "tertiary",
      "text-secondary hover:text-secondary-focus": appearance === "secondary",
      "text-primary hover:text-primary-dark dark:hover:text-primary-light": appearance === "primary",
      "text-link hover:text-link/80": appearance === "link",
    },
    className,
  );

  return createElement<any>(
    as,
    { className: classes, ...props },
    leading || trailing ? (
      <>
        {leading}
        <span>{children}</span>
        {trailing}
      </>
    ) : (
      children
    ),
  );
}
