import { Utils } from "@enzymefinance/sdk";
import type { ReleaseContracts } from "@enzymefinance/utils";

interface AddressToContractLabelArgs {
  address: string;
  comptrollerProxy?: string;
  contracts: ReleaseContracts;
  vaultName?: string;
  vaultProxy?: string;
}

export function addressToContractLabel({
  address,
  comptrollerProxy,
  contracts,
  vaultName,
  vaultProxy,
}: AddressToContractLabelArgs): string {
  if (!address) {
    return "Unknown contract";
  }

  if (comptrollerProxy && Utils.Address.safeSameAddress(comptrollerProxy, address)) {
    return vaultName ? `Comptroller Proxy (${vaultName})` : "Comptroller Proxy";
  }

  if (vaultProxy && Utils.Address.safeSameAddress(vaultProxy, address)) {
    return vaultName ? `Vault Proxy (${vaultName})` : "Vault Proxy";
  }

  const contractName = Object.entries(contracts).find(([, contractAddress]) => {
    return Utils.Address.safeSameAddress(address, contractAddress);
  })?.[0];

  return contractName ? contractName.charAt(0).toUpperCase() + contractName.slice(1) : "Unknown Contract";
}
