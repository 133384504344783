export function getRedemptionQueue<TRedemptionQueue extends { id: string }>({
  singleAssetRedemptionQueues,
  allowedRedeemersForSpecificAssetsPolicy,
}: {
  singleAssetRedemptionQueues: TRedemptionQueue[];
  allowedRedeemersForSpecificAssetsPolicy: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  };
}): TRedemptionQueue | null {
  if (!allowedRedeemersForSpecificAssetsPolicy.enabled) {
    return null;
  }

  const redemptionQueueInPolicy = singleAssetRedemptionQueues.filter((singleAssetRedemptionQueue) =>
    allowedRedeemersForSpecificAssetsPolicy.addressLists.find((addressList) =>
      addressList.items.includes(singleAssetRedemptionQueue.id),
    ),
  );

  return redemptionQueueInPolicy[0] ?? null;
}
