import type { Version, VersionContracts } from "@enzymefinance/environment";

export type Optional<T> = T | undefined;

export type ReleaseContracts = VersionContracts<Version.SULU>;

export type FunctionType<TType> = TType extends Function ? TType : never;

// https://stackoverflow.com/questions/58278652/generic-enum-type-guard
export const isSomeEnum =
  <T>(value: T) =>
  (token: any): token is T[keyof T] =>
    Object.values(value as unknown as ArrayLike<unknown>).includes(token as T[keyof T]);
