import { ArrowPathIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";

import { Banner } from "./Banner";

interface VersionUpdateBannerProps {
  onDismiss: () => void;
}

export function VersionUpdateBanner(props: VersionUpdateBannerProps) {
  const refresh = () => {
    if (typeof location !== "undefined") {
      location.reload();
    }
  };

  return (
    <Banner
      actions={
        <Button leadingIcon={ArrowPathIcon} onClick={refresh} size="xs">
          Refresh
        </Button>
      }
      icon={<div className="bg-success h-2.5 w-2.5 flex-none rounded-full" />}
      {...props}
    >
      A new version of our application is available. Please reload the page to update.
    </Banner>
  );
}
