import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Skeleton } from "@enzymefinance/ui";
import { useSharesWrapperVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperRequestRedeem: FunctionConfig<{ sharesAmount: bigint }> = {
  signature: "function requestRedeem(uint256 sharesAmount)",

  Description({ inputs, address }) {
    const sharesAmount = BigInt(inputs.sharesAmount);

    const query = useSharesWrapperVaultDetailsQuery({
      variables: { id: toAddress(address) },
    });

    const details = query.data?.gatedRedemptionQueueSharesWrapper?.vault;
    const redemptionAsset = query.data?.gatedRedemptionQueueSharesWrapper?.redemptionAsset;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Request Shares Redemption of{" "}
        <BigIntDisplay numberFormat={{ currency: "shares", maximumFractionDigits: 20 }} value={sharesAmount} /> from{" "}
        <b>{details?.name ?? "this vault"}</b> in {details ? redemptionAsset?.symbol : "redemption asset"}.
      </>
    );
  },

  Label() {
    return <>Request Shares Redemption</>;
  },
};
