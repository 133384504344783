import { Card, SectionHeading } from "@enzymefinance/ui";
import type { ReactElement } from "react";
import { useMemo } from "react";
import { decodeTransactionData } from "utils/functions";

import type { TxData } from "./TransactionModalMachine";

interface FunctionDetailsProps {
  label: ReactElement | string;
  description?: ReactElement | string;
}

function FunctionDetails({ label, description }: FunctionDetailsProps) {
  return (
    <Card appearance="secondary">
      <Card.Content>
        <SectionHeading.Subtitle>{label}</SectionHeading.Subtitle>
        {description === undefined ? null : <div className="text-base-content text-sm">{description}</div>}
      </Card.Content>
    </Card>
  );
}

interface TxDataDetailsProps {
  txData: TxData;
}

export function TxDataDetails({ txData }: TxDataDetailsProps) {
  const metadata = useMemo(() => decodeTransactionData(txData.data), [txData.data]);

  if (!metadata) {
    return <FunctionDetails label="Unknown Function" />;
  }

  const { Label, Description, Summary, inputs } = metadata;

  if (!Summary) {
    return (
      <FunctionDetails
        label={<Label address={txData.to} inputs={inputs} />}
        description={<Description address={txData.to} inputs={inputs} value={txData.value} />}
      />
    );
  }

  return (
    <Summary address={txData.to} inputs={inputs} value={typeof txData.value === "bigint" ? txData.value : undefined} />
  );
}
