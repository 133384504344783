import { CheckIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ComponentType, PropsWithChildren, ReactElement } from "react";
import { Children, createContext, createElement, isValidElement, useCallback, useContext, useMemo } from "react";
import { useStateList } from "react-use";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import type { SingleOrArray } from "../../types.js";
import { Icon } from "../elements/Icon.js";

interface StepItem {
  description?: string;
  id: string;
  state: "complete" | "current" | "incomplete";
  title: string;
}

interface StepsContextValues {
  current: string;
  currentIndex: number;
  next: () => void;
  prev: () => void;
  setCurrent: (id: string) => void;
  steps: StepItem[];
}

const StepsContext = createContext<StepsContextValues | undefined>(undefined);

export function useSteps() {
  const context = useContext(StepsContext);

  if (!context) {
    throw new Error("Missing steps context");
  }

  return context;
}

export interface StepsProps extends ComponentPropsWithoutRef<"nav"> {
  children: SingleOrArray<ReactElement<StepProps>>;
  label?: string;
}

export function Steps({ children, label = "Progress", ...props }: StepsProps) {
  const elements = (
    Children.toArray(children).filter((element) => isValidElement(element)) as ReactElement<StepProps>[]
  ).map((element) => element.props);
  const stepsList: string[] = useMemo(() => elements.map((step) => step.id), [elements]);
  const { state: current, currentIndex, next, prev, setState: setCurrent } = useStateList(stepsList);
  const steps: StepItem[] = useMemo(
    () =>
      elements.map((step, index) => ({
        description: step.description,
        id: step.id,
        state: currentIndex === index ? "current" : currentIndex > index ? "complete" : "incomplete",
        title: step.title,
      })),
    [currentIndex, elements],
  );
  const content = useMemo(() => elements.find((element) => element.id === current)?.children, [current, elements]);

  return (
    <StepsContext.Provider value={{ current, currentIndex, next, prev, setCurrent, steps }}>
      <nav aria-label={label} {...props}>
        <ol>
          {elements.map((elementProps) => (
            <Step key={elementProps.id} {...elementProps} />
          ))}
        </ol>
      </nav>
      {content}
    </StepsContext.Provider>
  );
}

export type StepProps<TProps extends {} = {}> = Pick<StepItem, "description" | "id" | "title"> &
  PropsWithChildren<{ as?: ComponentType<TProps> | "a" | "button" }> &
  TProps;

export function Step<TProps extends {} = {}>({ as = "a", description, id, title, ...props }: StepProps<TProps>) {
  const { current, setCurrent, steps } = useSteps();
  const currentStep = useMemo(() => steps.find((step) => step.id === id), [id, steps]);
  const stepIndex = useMemo(() => steps.findIndex((step) => step.id === id), [id, steps]);
  const isLast = stepIndex === steps.length - 1;
  const isComplete = currentStep?.state === "complete";
  const isIncomplete = currentStep?.state === "incomplete";
  const isCurrent = currentStep?.state === "current";
  const handleClick = useCallback(() => {
    if (current !== id) {
      setCurrent(id);
    }
  }, [current, id, setCurrent]);
  const barClasses = classNames("-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full", {
    "bg-gray-300 dark:bg-gray-600": !isLast,
  });
  const progressClasses = classNames("absolute inset-x-0 top-0 bg-primary-dark dark:bg-primary h-full origin-top", {
    "scale-y-0": !(isComplete || isLast) || isLast,
    "scale-y-1": isComplete,
  });
  const circleClasses = classNames(
    "relative z-10 w-8 h-8 flex items-center justify-center rounded-full border-2 transition",
    {
      "bg-primary-dark dark:bg-primary group-hover:bg-primary-darker dark:group-hover:bg-primary-focus border-primary-dark dark:border-primary group-hover:border-primary-darker dark:group-hover:border-primary-light":
        isComplete,
      "bg-white dark:bg-base-100 border-base-200 group-hover:border-gray-400": isIncomplete,
      "bg-white dark:bg-base-100 border-primary-dark dark:border-primary": isCurrent,
    },
  );
  const dotClasses = classNames(
    "absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 h-2.5 w-2.5 rounded-full transition",
    {
      "bg-transparent group-hover:bg-gray-300 dark:group-hover:bg-gray-700": isIncomplete,
      "bg-primary-dark dark:bg-primary": isCurrent,
    },
  );
  const titleClasses = classNames("text-xs font-semibold tracking-wide uppercase", {
    "text-base-neutral": isIncomplete,
    "text-heading-content": isComplete,
    "text-primary-dark dark:text-primary": isCurrent,
  });
  const motionPresets = useMotionPresets();
  const element = createElement<any>(
    as,
    {
      ...props,
      "aria-current": isCurrent ? "step" : undefined,
      className:
        "relative flex items-center group focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:border-primary transition",
      onClick: handleClick,
    },
    <>
      <span className="flex h-9 items-center">
        <span className={circleClasses}>
          <AnimatePresence>
            {isComplete ? (
              <motion.span {...motionPresets.default}>
                <Icon icon={CheckIcon} className="text-white" aria-hidden={true} />
              </motion.span>
            ) : null}
            {isCurrent || isIncomplete ? <span className={dotClasses} /> : null}
          </AnimatePresence>
        </span>
      </span>
      <span className="ml-4 flex min-w-0 flex-col">
        <span className={titleClasses}>{title}</span>
        {description === undefined ? null : <span className="text-base-content text-sm">{description}</span>}
      </span>
    </>,
  );

  return (
    <li className="relative pb-10">
      <div className={barClasses} aria-hidden={true}>
        <div className={progressClasses} aria-hidden={true} />
      </div>
      {element}
    </li>
  );
}

Steps.Step = Step;
