import type { FunctionConfig } from "utils/functionsTypes";

export const sharesWrapperUseTransferApprovals: FunctionConfig<{ nextUseTransferApprovals: boolean }> = {
  signature: "function setUseTransferApprovals(bool nextUseTransferApprovals)",

  Description({ inputs }) {
    return <>{inputs.nextUseTransferApprovals ? "Enable" : "Disable"} transfer approvals in Shares Wrapper</>;
  },
  Label() {
    return <>Reconfigure Shares Wrapper</>;
  },
};
