import { Bars3Icon, XMarkIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import type { ReactNode } from "react";

import classNames from "classnames";
import { useMobileMenu } from "./MobileMenuProvider";

interface NavbarMenuProps {
  children?: ReactNode;
  menu?: ReactNode;
}

export function NavbarMenu({ children, menu }: NavbarMenuProps) {
  const { isOpen, toggle } = useMobileMenu();

  return (
    <div className="flex min-w-0 items-center space-x-3">
      {/* mobile and desktop */}
      {menu}

      {/* Mobile */}
      <Button
        aria-expanded={isOpen}
        className="lg:hidden"
        icon={isOpen ? XMarkIcon : Bars3Icon}
        appearance="tertiary"
        onClick={() => toggle()}
      >
        Open menu
      </Button>

      {/* Desktop */}
      {children}
    </div>
  );
}

interface NavbarMenuItemProps {
  children: ReactNode;
  className?: string;
  hidden?: boolean;
}

export function NavbarMenuItem({ children, className = "", hidden = false }: NavbarMenuItemProps) {
  const classes = classNames(
    "hidden items-center space-x-3",
    {
      "lg:flex": !hidden,
    },
    className,
  );

  return <div className={classes}>{children}</div>;
}

NavbarMenu.Item = NavbarMenuItem;
