import { ExclamationCircleIcon } from "@enzymefinance/icons/solid";
import { Badge, Button, Icon, Tooltip } from "@enzymefinance/ui";
import { Link } from "components/routing/Link";
import { supportEmailAddress } from "utils/constants";

interface VaultUnmigratedWarningProps {
  upgradeable: boolean;
  isOwner: boolean;
  upgradePending: boolean;
  id: string;
}

export function VaultUnmigratedWarning({ upgradeable, id, isOwner, upgradePending }: VaultUnmigratedWarningProps) {
  if (!(upgradeable || upgradePending)) {
    return null;
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Item className="flex items-center space-x-1 p-2">
        <Icon className="text-warning" icon={ExclamationCircleIcon} />
        <Badge>Non-Sulu Vault</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <div className="max-w-xs space-y-4 text-sm">
          <p>
            This vault has not been upgraded to the latest version. If you are a depositor in this vault and you want to
            redeem your shares, please contact us at If you did not receive the code, please contact our support at{" "}
            <Link to={`mailto:${supportEmailAddress}`} className="text-link">
              {supportEmailAddress}
            </Link>
          </p>

          <div className="flex items-center space-x-4">
            {isOwner ? (
              <Button as={Link} size="xs" appearance="secondary" to={`/vault/${id}/settings/upgrade`}>
                Upgrade Vault
              </Button>
            ) : null}
          </div>
        </div>
      </Tooltip.Panel>
    </Tooltip.Provider>
  );
}
