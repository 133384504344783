import type { Placement } from "@popperjs/core";
import classNames from "classnames";
import type { CSSProperties, Dispatch, HTMLAttributes, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";
import type { Modifier } from "react-popper";
import { usePopper as usePopperBase } from "react-popper";

const origins: Record<Placement, HTMLAttributes<HTMLElement>["className"]> = {
  auto: undefined,
  "auto-end": undefined,
  "auto-start": undefined,
  bottom: "origin-top",
  "bottom-end": "origin-top-right",
  "bottom-start": "origin-top-left",
  left: "origin-right",
  "left-end": "origin-bottom-right",
  "left-start": "origin-top-right",
  right: "origin-left",
  "right-end": "origin-bottom-left",
  "right-start": "origin-top-left",
  top: "origin-bottom",
  "top-end": "origin-bottom-right",
  "top-start": "origin-bottom-left",
};

export interface PopperConfig {
  distance?: number;
  modifiers?: Modifier<string>[];
  placement?: Placement;
  skidding?: number;
}

const defaultPopperConfig: Required<PopperConfig> = {
  distance: 8,
  modifiers: [],
  placement: "top",
  skidding: 0,
};

export interface PopperContextValues {
  arrow: {
    ref: Dispatch<SetStateAction<HTMLElement | null>>;
    style?: CSSProperties;
  };
  arrowElement: HTMLElement | null;
  forceUpdate: (() => void) | null;
  popper: {
    className?: string;
    ref: Dispatch<SetStateAction<HTMLElement | null>>;
    style?: CSSProperties;
  };
  popperElement: HTMLElement | null;
  reference: { ref: Dispatch<SetStateAction<HTMLElement | null>> };
  referenceElement: HTMLElement | null;
  update: (() => void) | null;
}

export const PopperContext = createContext<PopperContextValues | undefined>(undefined);

export function usePopper() {
  const context = useContext(PopperContext);

  if (!context) {
    throw new Error("Missing toasts context");
  }

  return context;
}

interface PopperProviderProps extends PopperConfig {
  children?: ReactNode;
}

export function PopperProvider({
  children,
  distance = defaultPopperConfig.distance,
  modifiers = defaultPopperConfig.modifiers,
  placement = defaultPopperConfig.placement,
  skidding = defaultPopperConfig.skidding,
}: PopperProviderProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { attributes, forceUpdate, styles, update } = usePopperBase(referenceElement, popperElement, {
    modifiers: [
      { name: "offset", options: { offset: [skidding, distance] } },
      { name: "arrow", options: { element: arrowElement, padding: distance } },
      ...modifiers,
    ],
    placement,
  });
  const popperClasses = classNames(origins[placement]);

  return (
    <PopperContext.Provider
      value={{
        arrow: { ref: setArrowElement, style: styles.arrow, ...attributes.arrow },
        arrowElement,
        forceUpdate,
        popper: { className: popperClasses, ref: setPopperElement, style: styles.popper, ...attributes.popper },
        popperElement,
        reference: { ref: setReferenceElement },
        referenceElement,
        update,
      }}
    >
      {children}
    </PopperContext.Provider>
  );
}
