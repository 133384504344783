import { useLayoutEffect, useState } from "react";

export function useDomElement(parent = document.body) {
  const [domElement, setDomElement] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const element = document.createElement("div");

    parent.appendChild(element);
    setDomElement(element);

    return () => {
      parent.removeChild(element);
    };
  }, [parent]);

  return domElement;
}
