import type { BigIntInputProps as BigIntInputPropsBase } from "@enzymefinance/ethereum-ui";
import { BigIntInput as BigIntInputBase } from "@enzymefinance/ethereum-ui";
import { useCallback } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

interface BigIntInputProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<BigIntInputPropsBase, "id" | "onMaxClick" | "ref"> {
  name: FieldPath<TFieldValues>;
}

export function BigIntInput<TFieldValues extends FieldValues = FieldValues>({
  disabled,
  onValueChange,
  ...props
}: BigIntInputProps<TFieldValues>) {
  const {
    field: { onChange, ref: _, value, disabled: fieldDisabled, ...field },
    fieldState,
    formState,
  } = useController({ name: props.name });
  const handleMaxClick = useCallback(() => {
    if (props.balance) {
      onChange(props.balance as any);
    }
  }, [onChange, props.balance]);

  const handleValueChange = useCallback<NonNullable<BigIntInputProps["onValueChange"]>>(
    (values) => {
      if (values.bigIntValue === undefined) {
        onChange(null as any);
      } else if (!(value && BigInt(value) === values.bigIntValue)) {
        onChange(values.bigIntValue as any);
      }

      onValueChange?.(values);
    },
    [value, onChange, onValueChange],
  );

  return (
    <BigIntInputBase
      error={fieldState.error?.message}
      onMaxClick={handleMaxClick}
      onValueChange={handleValueChange}
      disabled={disabled || fieldDisabled || formState.isSubmitting}
      {...props}
      id={props.name}
      value={value ?? undefined}
      {...field}
    />
  );
}
