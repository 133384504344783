import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Fee, Fees } from "@enzymefinance/sdk/Configuration";
import { Badge, NumberDisplay, StackedList } from "@enzymefinance/ui";
import { address, bigint } from "@enzymefinance/validation";
import { useNetwork } from "components/providers/NetworkProvider";
import { supportEmailAddress } from "utils/constants";
import { parseUnits } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import type { EntranceDirectFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { EntranceFeeFormFields } from "./EntranceFee";

export const entranceRateDirectFeeSchema = z.object({
  rate: bigint()
    .refine((value?: EntranceDirectFeeSettings["rate"]) => typeof value === "bigint" && value > 0n, {
      message: "The rate must be greater than 0%.",
    })
    .refine((value?: EntranceDirectFeeSettings["rate"]) => typeof value === "bigint" && value < parseUnits("1", 4), {
      message: "The rate must be less than 100%.",
    }),
  recipient: address({ caseInsensitive: true }).optional().nullable(),
});

function entranceRateDirectFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.ENTRANCE_DIRECT_FEE>) {
  const { network } = useNetwork();

  return (
    <div className="flex flex-col space-y-3">
      <BigIntDisplay numberFormat={{ style: "percent" }} value={settings.rate ?? undefined} decimals={4} />
      {settings.recipient ? (
        <div>
          Recipient: <Address address={settings.recipient} network={network} icon={true} iconSize={5} />
        </div>
      ) : null}
    </div>
  );
}

function entranceRateDirectFeeDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ENTRANCE_DIRECT_FEE>) {
  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Rate">
        <NumberDisplay value={Number(settings.rate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>
    </StackedList.ItemDataBoxList>
  );
}

export const entranceRateDirectFee: VaultConfig<VaultConfigType.ENTRANCE_DIRECT_FEE> = {
  address: (contracts) => contracts.EntranceRateDirectFee,
  disableable: false,
  display: entranceRateDirectFeeDisplay,
  displaySubgraph: entranceRateDirectFeeDisplaySubgraph,
  editable: true,
  encode: (settings) =>
    Fees.Entrance.encodeDirectFeeSettings({
      recipient: settings.recipient ?? undefined,
      rateInBps: settings.rate ?? 0n,
    }),
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const [rate, recipient] = await Promise.all([
        Fees.Entrance.getRate(client, { entranceRateFee: vaultConfigAddress, comptrollerProxy: comptroller }),
        Fee.getRecipient(client, {
          fee: vaultConfigAddress,
          comptrollerProxy: comptroller,
        }),
      ]);

      return { rate, recipient };
    } catch {
      return undefined;
    }
  },
  formFields: () => <EntranceFeeFormFields selected={VaultConfigType.ENTRANCE_DIRECT_FEE} />,
  label: "Entrance Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>If enabled, entrance fees are charged with every new deposit.</p>
      <p>
        The fee recipient is the vault manager by default, or any other wallet. If you wish to split fee amounts among
        several wallets, please contact our sales team at{" "}
        <InlineLink appearance="tertiary" to={`mailto:${supportEmailAddress}`}>
          {supportEmailAddress}
        </InlineLink>
        .
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.ENTRANCE_DIRECT_FEE,
  validationSchema: entranceRateDirectFeeSchema,
};
