import { ThreeDotsLoader } from "@enzymefinance/icons";
import classNames from "classnames";
import type {
  ComponentPropsWithoutRef,
  ComponentType,
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  ReactElement as ReactReactElement,
  Ref as ReactRef,
  RefAttributes as ReactRefAttributes,
} from "react";
import { createElement, forwardRef } from "react";

import { motion } from "framer-motion";
import { ScreenReaderText } from "../typography/ScreenReaderText.js";
import type { IconSize } from "./Icon.js";
import { Icon } from "./Icon.js";

// Scoped augment forwardRef
// Ref: https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
declare module "react" {
  function forwardRef<TElement, TProps = {}>(
    render: (props: TProps, ref: ReactRef<TElement>) => ReactReactElement | null,
  ): (props: ReactRefAttributes<TElement> & TProps) => ReactReactElement | null;
}

const iconSizes: Record<ButtonSize, IconSize> = {
  lg: 6,
  md: 5,
  sm: 5,
  xl: 6,
  xs: 5,
};

const leadingTrailingIconSizes: Record<ButtonSize, IconSize> = {
  lg: 5,
  md: 5,
  sm: 4,
  xl: 5,
  xs: 4,
};

export type ButtonAppearance =
  | "destructive"
  | "primary"
  | "quaternary"
  | "secondary"
  | "tertiary"
  | "text-primary"
  | "text-secondary"
  | "text-tertiary"
  | "high-emphasis"
  | "text-destructive";
export type ButtonSize = "lg" | "md" | "sm" | "xl" | "xs";

export type ButtonProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">> = Omit<
  TProps,
  "as" | "ref"
> & {
  as?: ComponentType<TProps> | "a" | "button" | "span";
  children: ReactNode;
  circular?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  appearance?: ButtonAppearance;
  leadingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  loading?: boolean;
  shouldIconRotate?: boolean;
  size?: ButtonSize;
  trailingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  /**
   * Set this to `true` to display the leading or trailing icon on hover. This will only work when either the button or a parent container has the class name `group`.
   */
  transitionOnHover?: boolean;
  type?: "button" | "reset" | "submit";
};

export const Button = forwardRef(function Button<
  TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"button">,
>(
  {
    as = "button",
    className,
    children,
    circular = false,
    disabled = false,
    icon,
    leadingIcon,
    loading = false,
    appearance = "primary",
    shouldIconRotate = false,
    size = "md",
    trailingIcon,
    transitionOnHover = false,
    type = "button",
    ...props
  }: ButtonProps<TProps>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const isTextChildren = typeof children === "string";
  const isTextButton =
    appearance === "text-primary" || appearance === "text-secondary" || appearance === "text-tertiary";

  const classes = classNames(
    "shrink-0 inline-flex items-center justify-center disabled:opacity-50 disabled:cursor-default focus:outline-none transition",
    {
      "border focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-base-100": !isTextButton,
      "text-primary focus:text-primary-focus hover:text-primary-focus": appearance === "text-primary",
      "text-secondary focus:text-secondary-focus hover:text-secondary-focus": appearance === "text-secondary",
      "text-white focus:text-tertiary-focus hover:text-tertiary-focus": appearance === "text-tertiary",
      "font-medium": isTextChildren,
      "p-1": icon && size === "xs",
      "p-1.5": icon && size === "sm",
      "p-2": icon && (size === "md" || size === "lg"),
      "p-3": icon && size === "xl",
      "px-3 py-1 h-6": !icon && size === "xs" && !isTextButton,
      "px-4 py-2 h-8": !icon && size === "sm" && !isTextButton,
      "px-8 py-3.5 h-12": !icon && (size === "md" || size === "lg") && !isTextButton,
      "px-10 py-4.5 h-14": !icon && size === "xl" && !isTextButton,
      "pr-2.5": trailingIcon && size === "xs",
      "pr-3.5": trailingIcon && size === "sm",
      "pr-7.5": trailingIcon && (size === "md" || size === "lg"),
      "pr-9.5": trailingIcon && size === "xl",
      "pl-2.5": leadingIcon && size === "xs",
      "pl-3.5": leadingIcon && size === "sm",
      "pl-7.5": leadingIcon && (size === "md" || size === "lg"),
      "pl-9.5": leadingIcon && size === "xl",
      relative: loading || icon,
      "rounded-full": circular,
      "rounded-lg": !circular,
      "text-base": isTextChildren && (size === "lg" || size === "xl"),
      "bg-primary active:bg-primary border-transparent shadow-sm": appearance === "primary",
      "btn-secondary": appearance === "secondary",
      "btn-tertiary": appearance === "tertiary",
      "btn-high-emphasis": appearance === "high-emphasis",
      "border-transparent bg-base-200 text-base-neutral active:text-heading-content": appearance === "quaternary",
      "dark:hover:bg-white border-2 border-transparent text-error disabled:bg-transparent dark:border-transparent dark:text-error dark:hover:bg-opacity-5 hover:bg-black hover:bg-opacity-4":
        appearance === "destructive",
      "hover:bg-primary-focus dark:hover:bg-primary-focus": appearance === "primary" && !disabled,
      "hover:bg-base-300 dark:hover:bg-base-400":
        (appearance === "secondary" || appearance === "tertiary") && !disabled,
      "hover:text-gray-900 dark:hover:text-white": appearance === "quaternary" && !disabled,
      "text-sm": isTextChildren && (size === "sm" || size === "md"),
      "text-primary-content": appearance === "primary",
      "text-xs": isTextChildren && size === "xs",
    },
    className,
  );
  const iconClasses = classNames("flex-none", {
    "-translate-x-0.5": leadingIcon,
    "text-base-content": appearance === "secondary" && transitionOnHover,
    "transition opacity-0 group-hover:opacity-100": transitionOnHover,
    "translate-x-0.5": trailingIcon && !isTextButton,
    "translate-x-0": isTextButton,
  });
  const contentClasses = classNames("flex-1 flex items-center", {
    "justify-center": !isTextButton,
    "-translate-x-2.5": transitionOnHover && leadingIcon,
    invisible: loading,
    "space-x-1": !(transitionOnHover || icon) && size === "xs",
    "space-x-2": !(transitionOnHover || icon) && size !== "xs",
    "transition group-hover:translate-x-0": transitionOnHover,
    "translate-x-2.5": transitionOnHover && trailingIcon,
  });
  const content = (
    <>
      {loading ? (
        <span className="absolute inset-0 flex items-center justify-center">
          <ScreenReaderText>Loading</ScreenReaderText>
          <Icon icon={ThreeDotsLoader} aria-hidden={true} />
        </span>
      ) : null}

      <span className={contentClasses}>
        {leadingIcon ? (
          <Icon icon={leadingIcon} className={iconClasses} size={leadingTrailingIconSizes[size]} aria-hidden={true} />
        ) : null}
        {icon ? (
          <ScreenReaderText>{children}</ScreenReaderText>
        ) : (leadingIcon || trailingIcon) && isTextChildren ? (
          <span>{children}</span>
        ) : (
          children
        )}
        {icon ? <Icon icon={icon} size={iconSizes[size]} aria-hidden={true} /> : null}
        {trailingIcon ? (
          isTextButton ? (
            <motion.div animate={{ rotate: shouldIconRotate ? 180 : 0 }} transition={{ duration: 0.1 }}>
              <Icon icon={trailingIcon} className={iconClasses} size={4} />
            </motion.div>
          ) : (
            <Icon
              icon={trailingIcon}
              className={iconClasses}
              size={leadingTrailingIconSizes[size]}
              aria-hidden={true}
            />
          )
        ) : null}
      </span>
    </>
  );

  return createElement<any>(
    as,
    {
      className: classes,
      ...(as === "button" ? { disabled, type } : undefined),
      ...props,
      ref,
    },
    content,
  );
});
