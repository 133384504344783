import { toAddress } from "@enzymefinance/environment";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useCallback } from "react";
import { useAccount } from "wagmi";

export function useConnectionModal() {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();

  return {
    open: useCallback(
      () => (openConnectModal ?? openAccountModal ?? (() => {}))(),
      [openConnectModal, openAccountModal],
    ),
  };
}

export function useWallet() {
  const { address, ...account } = useAccount();

  return {
    address: address ? toAddress(address) : undefined,
    ...account,
  };
}

export function useSigner() {
  const { address, status } = useWallet();

  if (address !== undefined) {
    return [toAddress(address), false] as const;
  }

  return [undefined, status === "connecting" || status === "reconnecting"] as const;
}
