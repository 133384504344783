import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { useNetwork } from "components/providers/NetworkProvider";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address as AssetType, maxUint256 } from "viem";

export const sharesWrapperDepositApproval: FunctionConfig<{
  users: AssetType[];
  assets: AssetType[];
  amounts: bigint[];
}> = {
  signature:
    "function setDepositApprovals(address[] calldata users, address[] calldata assets, uint256[] calldata amounts)",

  Description({ inputs }) {
    const { environment } = useNetwork();

    if (inputs.users[0] === undefined || inputs.assets[0] === undefined || inputs.amounts[0] === undefined) {
      throw new Error("Invalid inputs");
    }

    const walletAddress = inputs.users[0];

    const depositAsset = environment.getAsset(inputs.assets[0]);

    const isUnlimited = BigInt(inputs.amounts[0]) === maxUint256;

    return (
      <>
        <div className="flex">
          <span className="mr-1">Approve</span>

          <span className="hidden md:block">
            <Address address={walletAddress} />
          </span>
          <div className="md:hidden">
            <Address address={walletAddress} trimmed={true} />
          </div>
        </div>

        {isUnlimited ? (
          <>
            <span className="text-heading-content">an unlimited amount of {depositAsset.symbol} </span>
          </>
        ) : (
          <BigIntDisplay
            numberFormat={{
              currency: depositAsset.symbol,
              maximumFractionDigits: 20,
            }}
            decimals={depositAsset.decimals}
            value={inputs.amounts[0]}
          />
        )}
      </>
    );
  },

  Label() {
    return <>Add Deposit Approval</>;
  },
};
