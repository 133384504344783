import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";

import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const stakeWiseStake: ExternalPositionHandler<Integrations.StakeWiseV3.StakeArgs> = {
  Description({ args: { assetAmount, stakeWiseVault } }) {
    const { environment } = useGlobals();
    const { weth } = environment.namedTokens;

    return (
      <>
        Staking <BigIntDisplay value={assetAmount} decimals={weth.decimals} numberFormat={{ currency: weth.symbol }} />{" "}
        into StakeWise vault at <Address address={stakeWiseVault} trimmed={true} />.
      </>
    );
  },
  Label() {
    return <>Stake WETH</>;
  },
  decodeExternalPositionArgs: Integrations.StakeWiseV3.stakeDecode,
};

export const stakeWiseRedeem: ExternalPositionHandler<Integrations.StakeWiseV3.RedeemArgs> = {
  Description({ args: { stakeWiseVault, sharesAmount } }) {
    return (
      <>
        Redeeming <BigIntDisplay value={sharesAmount} numberFormat={{ currency: "shares" }} /> from StakeWise vault at
        <Address address={stakeWiseVault} trimmed={true} />
      </>
    );
  },
  Label() {
    return <>Redeem shares from StakeWise.</>;
  },
  decodeExternalPositionArgs: Integrations.StakeWiseV3.redeemDecode,
};

export const stakeWiseEnterExitQueue: ExternalPositionHandler<Integrations.StakeWiseV3.EnterExitQueueArgs> = {
  Description({ args: { sharesAmount, stakeWiseVault } }) {
    return (
      <>
        Enter exit queue for <BigIntDisplay value={sharesAmount} numberFormat={{ currency: "shares" }} /> for StakeWise
        vault at address <Address address={stakeWiseVault} trimmed={true} />
      </>
    );
  },
  Label() {
    return <>Enter Exit Queue.</>;
  },
  decodeExternalPositionArgs: Integrations.StakeWiseV3.enterExitQueueDecode,
};

export const stakeWiseClaimExitedAssets: ExternalPositionHandler<Integrations.StakeWiseV3.ClaimExitedAssetsArgs> = {
  Description({ args: { stakeWiseVault } }) {
    return (
      <>
        Claim exited assets from <Address address={stakeWiseVault} trimmed={true} />
      </>
    );
  },
  Label() {
    return <>Claim StakeWise exited assets</>;
  },
  decodeExternalPositionArgs: Integrations.StakeWiseV3.claimExitedAssetsDecode,
};

export const createStakeWiseExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = stakeWiseStake.decodeExternalPositionArgs(actionArgs);

    return <stakeWiseStake.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = stakeWiseStake.decodeExternalPositionArgs(actionArgs);

    return <stakeWiseStake.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize StakeWise external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = stakeWiseStake.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(stakeWiseStake.Label, stakeWiseStake.Description);

    return <Summary args={args} />;
  },
};
