import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ReactElement, ReactNode } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import { Avatar } from "../elements/Avatar.js";
import { Portal } from "../layout/Portal.js";

export interface MobileMenuProps {
  actions?: ReactElement | null;
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
}

export function MobileMenu({ actions = null, children, className, isOpen }: MobileMenuProps) {
  const motionPresets = useMotionPresets();
  const classes = classNames("fixed inset-0 z-10 pt-[62px] lg:hidden flex flex-col bg-base-100", className);

  return (
    <AnimatePresence>
      {isOpen ? (
        <Portal>
          <motion.nav
            aria-label="Global"
            className={classes}
            key="mobile-menu"
            layout={true}
            {...motionPresets.opacity}
            initial={{ ...motionPresets.opacity.initial, y: -16 }}
            exit={{ ...motionPresets.opacity.exit, y: -16 }}
            animate={{ ...motionPresets.opacity.animate, y: 0 }}
          >
            <div className="bg-base-200 flex h-full flex-col justify-between space-y-4 backdrop-blur">
              <div className="divide-base-400 flex-1 divide-y overflow-y-auto py-4">{children}</div>
              {actions === null ? null : (
                <div className="flex-none">
                  <MobileMenu.Group>{actions}</MobileMenu.Group>
                </div>
              )}
            </div>
          </motion.nav>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}

interface MobileMenuUserProps {
  displayName: string;
  email: string;
  avatarUrl?: string;
}

export function MobileMenuUser({ displayName, email, avatarUrl }: MobileMenuUserProps) {
  return (
    <div className="flex flex-1 items-center space-x-3 px-2">
      <Avatar displayName={displayName} photo={avatarUrl} size={10} />
      <div className="min-w-0">
        <p className="title truncate text-base">{displayName}</p>
        <p className="text-base-neutral truncate text-sm font-medium">{email}</p>
      </div>
    </div>
  );
}

MobileMenu.User = MobileMenuUser;

interface MobileMenuGroupProps {
  children?: ReactNode;
}

export function MobileMenuGroup({ children }: MobileMenuGroupProps) {
  return <div className="mx-auto max-w-3xl px-2 py-4 sm:px-4">{children}</div>;
}

MobileMenu.Group = MobileMenuGroup;
