import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";

export const redeemShares: FunctionConfig<{}> = {
  signature: "function redeemShares() returns (address[] payoutAssets, uint256[] payoutAmounts)",

  Description({ address }) {
    const query = useVaultDetailsFromComptrollerQuery({ variables: { id: address.toLowerCase() } });
    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Redeem all shares of {vault?.name ?? "this vault"}.</>;
  },

  Label() {
    return <>Redeem All Shares</>;
  },
};
