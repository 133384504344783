import type { Address } from "@enzymefinance/environment";
import { Environment, toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Checkbox, Form, FormErrorMessage, NumberInput, TokenInput, useForm } from "@enzymefinance/hook-form";
import { Asset, Depositor, Utils } from "@enzymefinance/sdk";
import type { Viem } from "@enzymefinance/sdk/Utils";
import { Alert, Button, ExpandableButton, Modal, SectionHeading, Tooltip } from "@enzymefinance/ui";
import { maxSlippage as maxSlippageSchema, refine, safeParse, tokenInput } from "@enzymefinance/validation";
import { captureException } from "@sentry/react";
import { useSigner } from "components/connection/Connection.js";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import { useCurrency } from "components/settings/Settings";
import type { MachineState } from "components/transactions/TransactionModalMachine";
import { VaultDepositUnknownFeeOrPolicyAlert } from "components/vault/deposit/components/VaultDepositUnknownFeeOrPolicyAlert";
import { motion } from "framer-motion";
import type { VaultDetailsFragment } from "queries/core";
import { GatedRedemptionQueueSharesWrapperDepositMode } from "queries/core";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useToggle } from "react-use";
import { defaultTransactionSlippage } from "utils/constants";
import { findTokenValue } from "utils/currency";
import { useAllowance } from "utils/hooks/useAllowance";
import { useApproveTransactions } from "utils/hooks/useApproveTransactions";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { minimumNumberOfShares } from "utils/vault";
import { formatUnits, isAddressEqual } from "viem";
import { z } from "zod";
import { useGlobals } from "../../providers/GlobalsProvider";
import { VaultFlexibleLoanAgreementModal } from "./VaultFlexibleLoanAgreementModal";
import { VaultDepositFormSubmitButton } from "./components/VaultDepositFormSubmitButton.js";

function createSchema() {
  return z
    .object({
      maxDeposit: z.bigint().optional(),
      maxSlippage: maxSlippageSchema(),
      minDeposit: z.bigint().optional(),
      termsAccepted: z.boolean().transform(
        refine((value) => value, {
          message: "You must accept the Terms & Conditions.",
        }),
      ),
      tokenBalance: z.bigint(),
      isFlexibleLoanVault: z.boolean(),
      flexLoansAccepted: z.boolean(),
      depositAmount: tokenInput.transform(
        refine((value) => value.value > 0n, { message: "You must deposit more than 0." }),
      ),
    })
    .transform(
      refine((value) => value.depositAmount.value <= value.tokenBalance, {
        message: "The deposit amount exceeds your balance.",
        path: ["depositAmount"],
      }),
    )
    .transform(
      refine(
        (value) =>
          typeof value.minDeposit === "bigint"
            ? value.minDeposit === 0n || value.depositAmount.value >= value.minDeposit
            : true,
        {
          message: "The deposit amount is below the minimum deposit amount of the vault.",
          path: ["depositAmount"],
        },
      ),
    )
    .transform(
      refine(
        (value) =>
          typeof value.maxDeposit === "bigint"
            ? value.maxDeposit === 0n || value.depositAmount.value <= value.maxDeposit
            : true,
        {
          message: "The deposit amount exceeds the maximum deposit amount of the vault",
          path: ["depositAmount"],
        },
      ),
    )
    .transform(
      refine((value) => (value.isFlexibleLoanVault ? !!value.flexLoansAccepted : true), {
        message: "You must accept the Flexible loans terms.",
        path: ["flexLoansAccepted"],
      }),
    );
}

interface VaultDepositFormProps {
  children?: ReactNode;
  close: () => void;
  minDeposit?: bigint;
  maxDeposit?: bigint;
  start: (fn: Viem.PopulatedTransaction<any, any>, signerAddress: Address, vaultProxy?: Address) => void;
  state: MachineState;
  vault: VaultDetailsFragment;
  isFlexibleLoanVault: boolean;
  isFlexibleLoanModalOpen: boolean;
  openFlexibleLoanModal: () => void;
  sharesWrapper: {
    hasDepositLimit: boolean;
    depositAmountLimit: bigint | null;
    sharesWrapperAddress: Address;
    depositMode: GatedRedemptionQueueSharesWrapperDepositMode;
    depositAssetId?: Address;
  };
  vaultHasUnknownFeeOrPolicy: boolean;
  depositRequestValue: bigint;
  depositAmount: bigint;
  setDepositAmount: (value: bigint) => void;
}

export function VaultDepositSharesWrapperForm({
  children,
  close,
  start,
  minDeposit,
  maxDeposit,
  vault,
  isFlexibleLoanVault,
  isFlexibleLoanModalOpen,
  openFlexibleLoanModal,
  sharesWrapper,
  vaultHasUnknownFeeOrPolicy,
  depositRequestValue,
  depositAmount: savedDepositAmount,
  setDepositAmount: setSavedDepositAmount,
}: VaultDepositFormProps) {
  const { environment } = useGlobals();
  const [signerAddress] = useSigner();

  const { usdt } = environment.namedTokens;

  const { client } = useNetwork();
  const { denomination: denominationAsset } = vault.comptroller;
  const isDepositModeRequest = sharesWrapper.depositMode === GatedRedemptionQueueSharesWrapperDepositMode.REQUEST;

  const isDepositRequestExists = useMemo(
    () => depositRequestValue > 0n && isDepositModeRequest,
    [depositRequestValue, isDepositModeRequest],
  );

  const asset = environment.getAsset(denominationAsset.id);

  const schema = useMemo(() => createSchema(), []);
  const currency = useCurrency();
  const { assetPrices } = useAssetPrices();

  const sharesWrapperAddress = sharesWrapper.sharesWrapperAddress;

  const allowanceQuery = useAllowance(client, {
    account: signerAddress,
    spender: toAddress(sharesWrapperAddress),
    token: toAddress(denominationAsset.id),
  });

  const [advanced, toggleAdvanced] = useToggle(false);

  const hasDepositLimit = sharesWrapper.hasDepositLimit;

  const defaultDepositAmount = useMemo(() => {
    if (savedDepositAmount > 0n) {
      return formatUnits(savedDepositAmount, denominationAsset.decimals);
    }

    if (!hasDepositLimit) {
      return "";
    }

    return formatUnits(
      sharesWrapper.depositAmountLimit ? sharesWrapper.depositAmountLimit : savedDepositAmount,
      denominationAsset.decimals,
    );
  }, [denominationAsset.decimals, hasDepositLimit, savedDepositAmount, sharesWrapper.depositAmountLimit]);

  const isEthereumUsdt =
    Environment.isDeploymentEthereum(environment) && isAddressEqual(toAddress(denominationAsset.id), usdt.id);

  const form = useForm({
    defaultValues: {
      maxDeposit,
      maxSlippage: `${defaultTransactionSlippage * 100}`,
      minDeposit,
      termsAccepted: false,
      tokenBalance: undefined,
      flexLoansAccepted: false,
      isFlexibleLoanVault,
      depositAmount: {
        value: defaultDepositAmount,
        token: asset,
      },
    },
    reValidateMode: "onChange",
    onSubmit: async (values, helpers) => {
      if (!signerAddress) {
        return helpers.setError("form", {
          message: "Wallet not connected. You must connect your wallet to perform this action.",
        });
      }

      if (allowanceQuery.data === undefined) {
        return helpers.setError("form", {
          message: "Error fetching the allowance",
        });
      }

      try {
        let fn: Viem.PopulatedTransaction<any, any>;

        if (isEthereumUsdt && !(allowanceQuery.data === 0n) && allowanceQuery.data < values.depositAmount.value) {
          fn = Asset.approve({
            asset: toAddress(denominationAsset.id),
            spender: sharesWrapperAddress,
            amount: 0n,
          });
          setSavedDepositAmount(values.depositAmount.value);
          start(fn, signerAddress, toAddress(sharesWrapperAddress));
        } else if (allowanceQuery.data < values.depositAmount.value) {
          fn = Asset.approve({
            asset: toAddress(denominationAsset.id),
            spender: sharesWrapperAddress,
            amount: values.depositAmount.value,
          });
          setSavedDepositAmount(values.depositAmount.value);
          start(fn, signerAddress, toAddress(sharesWrapperAddress));
        } else {
          if (isDepositModeRequest) {
            fn = Depositor.sharesWrapperRequestDeposit({
              sharesWrapper: sharesWrapperAddress,
              depositAsset: toAddress(denominationAsset.id),
              depositAmount: values.depositAmount.value,
            });
          } else {
            const expectedShares = await Depositor.getExpectedSharesForSharesWrapperDeposit(client, {
              sharesWrapper: sharesWrapperAddress,
              depositAsset: toAddress(denominationAsset.id),
              depositAmount: values.depositAmount.value,
              depositor: signerAddress,
            });

            const minSharesAmount = Utils.Slippage.multiplyBySlippage({
              slippage: values.maxSlippage,
              value: expectedShares > minimumNumberOfShares ? expectedShares : minimumNumberOfShares,
            });

            fn = Depositor.sharesWrapperDeposit({
              sharesWrapper: sharesWrapperAddress,
              depositAsset: toAddress(denominationAsset.id),
              depositAmount: values.depositAmount.value,
              minSharesAmount,
            });
          }

          start(fn, signerAddress, toAddress(sharesWrapperAddress));
          close();
        }
      } catch (error) {
        captureException(error);

        return helpers.setError("form", {
          message: "Something went wrong",
          type: "report",
        });
      }
    },
    schema,
  });

  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = form;
  const [depositAmount, maxSlippage, termsAccepted] = watch(["depositAmount", "maxSlippage", "termsAccepted"]);

  const parsedDepositAmount = useMemo(() => safeParse(tokenInput, depositAmount), [depositAmount]);

  const balanceQuery = useTokenBalance(
    client,
    {
      account: signerAddress,
      token: toAddress(denominationAsset.id),
    },
    {
      onSuccess: (data) => {
        setValue("tokenBalance", data);
        trigger("depositAmount");
      },
    },
  );

  const investmentCurrencyValue = useMemo(
    () =>
      findTokenValue({
        assetPrices,
        token: denominationAsset,
        value: parsedDepositAmount?.value,
      }),
    [assetPrices, denominationAsset, parsedDepositAmount?.value],
  );

  const sufficientAllowance = useMemo(
    () =>
      typeof allowanceQuery.data === "bigint" &&
      typeof parsedDepositAmount?.value === "bigint" &&
      parsedDepositAmount?.value !== 0n &&
      allowanceQuery.data >= parsedDepositAmount?.value,
    [allowanceQuery.data, parsedDepositAmount?.value],
  );

  const isUsdtLocked = isEthereumUsdt && !(allowanceQuery.data === 0n) && !sufficientAllowance;

  const slippageIsBelowThreshold = Number(maxSlippage) / 100 < 0.01;

  const approveTransactions = useApproveTransactions(sharesWrapperAddress);
  const isValid = !!(
    form.formState.isValid &&
    !form.formState.isSubmitting &&
    !form.formState.isValidating &&
    allowanceQuery.data
  );

  const isSubmittingApprove = approveTransactions.isSubmitting;
  const isPendingApprove = approveTransactions.isPending;
  const isSubmittingDeposit = form.formState.isSubmitting;

  const isNextButtonDisabled = !(termsAccepted && Number(depositAmount) > 0) || !!errors.depositAmount;

  const cancelRequestDeposit = useCallback(() => {
    if (!signerAddress) {
      return;
    }

    const fn = Depositor.sharesWrapperCancelRequestDeposit({
      sharesWrapper: sharesWrapperAddress,
      depositAsset: toAddress(denominationAsset.id),
    });

    start(fn, signerAddress, toAddress(sharesWrapperAddress));
    close();
  }, [close, denominationAsset.id, sharesWrapperAddress, signerAddress, start]);

  useEffect(() => {
    if (hasDepositLimit) {
      trigger("depositAmount");
    }
  }, [sharesWrapper.hasDepositLimit, trigger]);

  if (
    (!isDepositRequestExists && sharesWrapper.depositAmountLimit === null && hasDepositLimit) ||
    (sharesWrapper.depositAssetId && !isAddressEqual(toAddress(denominationAsset.id), sharesWrapper.depositAssetId))
  ) {
    return (
      <Modal.Body className="space-y-6">
        <Alert appearance="info" title="Not possible to deposit at the moment">
          Your wallet address {signerAddress} is not on it. Please confirm you are connected with the correct wallet. If
          you believe you are receiving this message in error, please contact the vault manager.
        </Alert>
      </Modal.Body>
    );
  }

  if (isDepositRequestExists) {
    return (
      <Modal.Body className="space-y-6">
        <Alert
          appearance="info"
          title={
            <span>
              Your request to deposit{" "}
              <BigIntDisplay
                numberFormat={{
                  currency: asset.symbol,
                  maximumFractionDigits: 18,
                }}
                decimals={asset.decimals}
                value={depositRequestValue}
              />{" "}
              is pending
            </span>
          }
        >
          <div className="space-y-3">
            <div>You can delete your deposit request.</div>
          </div>
        </Alert>
        <div className="flex justify-end">
          <Button appearance="destructive" onClick={cancelRequestDeposit}>
            Delete request
          </Button>
        </div>
      </Modal.Body>
    );
  }

  return (
    <Form form={form}>
      {isFlexibleLoanModalOpen ? (
        <VaultFlexibleLoanAgreementModal
          submitButton={
            <VaultDepositFormSubmitButton
              isDepositModeRequest={isDepositModeRequest}
              asset={denominationAsset}
              isPendingApprove={isPendingApprove}
              isSubmittingApprove={isSubmittingApprove}
              isSubmittingBuyShares={isSubmittingDeposit}
              isUSDTLocked={isUsdtLocked}
              isValid={isValid}
              sufficientAllowance={sufficientAllowance}
            />
          }
        />
      ) : (
        <>
          <Modal.Body className="space-y-6">
            <>
              {isDepositModeRequest ? (
                <Alert appearance="info" title="Deposit request">
                  <span>
                    Depositing into this vault is a two step process: <p> 1. Submit a deposit request</p>{" "}
                    <p>2. The vault manager will then approve your deposit request once a day.</p> You can cancel your
                    deposit request at any time after it has been submitted and before it has been approved.{" "}
                  </span>
                </Alert>
              ) : null}
              {hasDepositLimit ? (
                sharesWrapper.depositAmountLimit ? (
                  <Alert
                    appearance="info"
                    title={
                      <span>
                        <BigIntDisplay
                          numberFormat={{
                            currency: asset.symbol,
                            maximumFractionDigits: 18,
                          }}
                          decimals={asset.decimals}
                          value={sharesWrapper.depositAmountLimit}
                        />{" "}
                        pre-approved by manager.
                      </span>
                    }
                  >
                    The manager has pre-approved a deposit of{" "}
                    <BigIntDisplay
                      numberFormat={{
                        currency: asset.symbol,
                        maximumFractionDigits: 18,
                      }}
                      decimals={asset.decimals}
                      value={sharesWrapper.depositAmountLimit}
                    />{" "}
                    for your wallet. You need to deposit this exact amount.
                  </Alert>
                ) : null
              ) : (
                <p className="text-sm">Choose amount:</p>
              )}
            </>
            <TokenInput
              label="Amount"
              name="depositAmount"
              tokens={[asset]}
              localValue={<TokenInput.LocalValue value={investmentCurrencyValue ?? 0} numberFormat={{ currency }} />}
              balance={balanceQuery.data}
              readOnly={hasDepositLimit}
            />
            {children}
            {typeof minDeposit === "bigint" || typeof maxDeposit === "bigint" ? (
              <div className="space-y-1">
                <Tooltip label={<SectionHeading.Subtitle>Deposit Limits</SectionHeading.Subtitle>}>
                  This vault has limits on the amounts of individual deposits.
                </Tooltip>
                <div>
                  {typeof minDeposit === "bigint" && minDeposit !== 0n ? (
                    <div className="text-base-content flex items-center space-x-2 text-sm">
                      <dt>Minimum:</dt>
                      <dd>
                        <BigIntDisplay
                          value={minDeposit}
                          numberFormat={{ currency: denominationAsset.symbol }}
                          decimals={denominationAsset.decimals}
                        />
                      </dd>
                    </div>
                  ) : null}
                  {typeof maxDeposit === "bigint" && maxDeposit !== 0n ? (
                    <div className="text-base-content flex items-center space-x-2 text-sm">
                      <dt>Maximum:</dt>
                      <dd>
                        <BigIntDisplay
                          value={maxDeposit}
                          numberFormat={{ currency: denominationAsset.symbol }}
                          decimals={denominationAsset.decimals}
                        />
                      </dd>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <ExpandableButton
              appearance="secondary"
              isOpen={advanced}
              handleExpand={toggleAdvanced}
              label="Advanced Settings"
            >
              <div className="space-y-4">
                <NumberInput label="Maximum Slippage" name="maxSlippage" numberFormat={{ style: "percent" }} />
                {slippageIsBelowThreshold && !form.getFieldState("maxSlippage").error ? (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{
                      height: "auto",
                      opacity: 1,
                      transition: { duration: 0.2 },
                    }}
                    exit={{
                      height: 0,
                      opacity: 0,
                      transition: { duration: 0.2 },
                    }}
                  >
                    <Alert appearance="warning">
                      The selected maximum slippage is low and may significantly increase the chances of a transaction
                      failure. Consider setting a higher maximum slippage.
                    </Alert>
                  </motion.div>
                ) : null}
              </div>
            </ExpandableButton>
            {vaultHasUnknownFeeOrPolicy ? <VaultDepositUnknownFeeOrPolicyAlert /> : null}
            <Checkbox
              autoFocus={hasDepositLimit}
              name="termsAccepted"
              label={
                <>
                  I have read &amp; agree to the{" "}
                  <InlineLink to="https://enzyme.finance/terms" appearance="link">
                    Terms &amp; Conditions
                  </InlineLink>
                  .
                </>
              }
            />
            <FormErrorMessage />
          </Modal.Body>
          <Modal.Actions>
            {isFlexibleLoanVault ? (
              <Button appearance="primary" onClick={openFlexibleLoanModal} disabled={isNextButtonDisabled}>
                Next
              </Button>
            ) : (
              <VaultDepositFormSubmitButton
                isDepositModeRequest={isDepositModeRequest}
                asset={denominationAsset}
                isPendingApprove={isPendingApprove}
                isSubmittingApprove={isSubmittingApprove}
                isSubmittingBuyShares={isSubmittingDeposit}
                isUSDTLocked={isUsdtLocked}
                isValid={isValid}
                sufficientAllowance={sufficientAllowance}
              />
            )}
          </Modal.Actions>
        </>
      )}
    </Form>
  );
}
