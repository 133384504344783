import type { CreatableProps, MultiValueProps, Options, SingleValueProps } from "@enzymefinance/select";
import { Creatable, components, sharedComponents } from "@enzymefinance/select";
import type { Option } from "@enzymefinance/ui";
import type { ReactNode } from "react";
import { isAddress } from "viem";

import type { AddressProps } from "../address/Address";
import { Address } from "../address/Address";

function customComponents(componentProps?: AddressProps) {
  return {
    MultiValue(props: MultiValueProps<Option>) {
      return (
        <sharedComponents.MultiValue {...props}>
          <Address address={props.data.value} copy={false} etherscan={false} trimmed={true} {...componentProps} />
        </sharedComponents.MultiValue>
      );
    },
    SingleValue(props: SingleValueProps<Option>) {
      return (
        <components.SingleValue {...props}>
          <Address
            address={props.data.value}
            copy={false}
            etherscan={false}
            icon={true}
            iconSize={5}
            {...componentProps}
          />
        </components.SingleValue>
      );
    },
  };
}

export interface AddressOption {
  label: ReactNode;
  value: string;
}

export type AddressCreatableProps<TMulti extends boolean = boolean> = CreatableProps<AddressOption, TMulti> & {
  addressComponentProps?: AddressProps;
};

export function AddressCreatable<TMulti extends boolean = boolean>({
  placeholder = "Enter address ...",
  addressComponentProps,
  ...props
}: AddressCreatableProps<TMulti>) {
  return (
    <Creatable<AddressOption, TMulti>
      formatCreateLabel={(inputValue) => `Add "${inputValue.trim()}"`}
      getNewOptionData={(inputValue, label) => ({
        label: typeof label === "string" ? label.trim() : label,
        value: inputValue.trim(),
      })}
      isValidNewOption={(inputValue, value) =>
        isAddress(inputValue.trim()) &&
        (props.isMulti
          ? !(value as Options<AddressOption>).map((option) => option.value).includes(inputValue.trim())
          : true)
      }
      noOptionsMessage={({ inputValue }) =>
        inputValue ? "Enter a valid Ethereum address that you have not already entered" : null
      }
      placeholder={placeholder}
      {...props}
      components={
        {
          ...customComponents(addressComponentProps),
          ...props.components,
        } as AddressCreatableProps<TMulti>["components"]
      }
    />
  );
}
