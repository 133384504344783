import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

interface RedemptionQueueDeployInputs {
  vaultProxy: Address;
  redemptionAssetAddress: Address;
  bypassableSharesThreshold: bigint;
  managers: Address[];
}

export const redemptionQueueDeploy: FunctionConfig<RedemptionQueueDeployInputs> = {
  signature:
    "function deployProxy(address vaultProxy, address redemptionAssetAddress, uint256 bypassableSharesThreshold, address[] calldata managers) external returns (address proxyAddress)",

  Description({ inputs }) {
    const { data, loading } = useVaultDetailsQuery({
      variables: {
        id: inputs.vaultProxy.toLowerCase(),
      },
    });

    if (loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return <>Enable redemption queue for vault {data?.vault?.name}</>;
  },

  Label() {
    return <>Redemption Queue</>;
  },
};
