import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactNode } from "react";

import type { CardContentProps, CardProps } from "../elements/Card.js";
import { Card } from "../elements/Card.js";

type StackedListProps = CardProps;

export function StackedList(props: StackedListProps) {
  return <Card full={true} rounded="2xl" {...props} />;
}

type StackedListItemrops = CardContentProps;

function stackedListItem(props: StackedListItemrops) {
  return <Card.Content className="grid grid-cols-12 gap-4" {...props} />;
}

type StackedListItemLabelProps = ComponentPropsWithoutRef<"dt">;

function stackedListItemLabel({ className, ...props }: StackedListItemLabelProps) {
  const classes = classNames("text-sm col-span-6 font-medium text-base-content", className);

  return <dt className={classes} {...props} />;
}

type StackedListItemDataProps = ComponentPropsWithoutRef<"dd">;

function stackedListItemData({ className, ...props }: StackedListItemDataProps) {
  const classes = classNames("text-sm col-span-6 text-base-content space-y-2", className);

  return <dd className={classes} {...props} />;
}

interface StackedListItemDataBoxProps {
  label?: string;
  children: ReactNode;
}

function stackedListItemDataBox({ children, label }: StackedListItemDataBoxProps) {
  return (
    <div className="flex flex-col space-y-2">
      {label === undefined ? null : <h4 className="text-base-content truncate text-sm font-medium">{label}</h4>}
      <div className="text-md text-heading-content">{children}</div>
    </div>
  );
}

interface StackedListItemDataBoxListProps {
  children: ReactNode;
}

function stackedListItemDataBoxList({ children }: StackedListItemDataBoxListProps) {
  return <div className="grid w-full grid-cols-2">{children}</div>;
}

StackedList.Item = stackedListItem;
StackedList.ItemLabel = stackedListItemLabel;
StackedList.ItemData = stackedListItemData;
StackedList.ItemDataBox = stackedListItemDataBox;
StackedList.ItemDataBoxList = stackedListItemDataBoxList;
