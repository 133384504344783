import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

import type { FieldGroupProps } from "./FieldGroup.js";
import { FieldGroup, useFieldGroup } from "./FieldGroup.js";

export interface Option {
  label: string;
  value: string;
}

export interface BaseSelectProps<TOption extends Record<string, any> = Option>
  extends Omit<ComponentPropsWithoutRef<"select">, "id"> {
  id: string;
  options: TOption[];
}

function BaseSelect<TOption extends Record<string, any> = Option>({
  className,
  id,
  options,
  name,
  ...props
}: BaseSelectProps<TOption>) {
  const { ariaProps, isError } = useFieldGroup();
  const classes = classNames(
    "block w-full pl-3 pr-10 py-2 bg-base-300 focus:outline-none font-medium text-sm rounded-lg transition",
    {
      "border-error focus:border-error dark:focus:border-error focus:ring-error dark:focus:ring-error text-error dark:text-error":
        isError,
      "border-transparent focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:ring-primary text-heading-content":
        !isError,
    },
    className,
  );

  return (
    <select id={id} name={name ?? id} className={classes} {...ariaProps} {...props}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export type SelectProps<TOption extends Record<string, any> = Option> = BaseSelectProps<TOption> &
  Omit<FieldGroupProps, "appearance">;

export function Select<TOption extends Record<string, any> = Option>({
  cornerHint,
  error,
  description,
  label,
  labelHidden = false,
  ...props
}: SelectProps<TOption>) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseSelect<TOption> {...props} />
    </FieldGroup>
  );
}
