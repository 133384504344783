import { Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const removeAuthUserForFund: FunctionConfig<{ comptrollerProxy: Address; who: Address }> = {
  signature: "function removeAuthUserForFund(address comptrollerProxy, address who)",

  Description({ inputs }) {
    const query = useVaultDetailsFromComptrollerQuery({
      variables: { id: inputs.comptrollerProxy.toLowerCase() },
    });
    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Remove authorization for user {inputs.who} to trade for {vault?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Remove Authorization</>;
  },
};
