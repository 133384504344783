import type { ComponentPropsWithoutRef } from "react";

import type { NavigationItemProps, NavigationProps } from "./Navigation.js";
import { Navigation } from "./Navigation.js";

export type SidebarNavigationProps = ComponentPropsWithoutRef<"nav">;

export function SidebarNavigation({ "aria-label": label = "Sidebar", ...props }: SidebarNavigationProps) {
  return <nav aria-label={label} {...props} />;
}

export type SidebarNavigationItemsProps = Omit<NavigationProps, "direction">;

export function SidebarNavigationItems(props: SidebarNavigationItemsProps) {
  return <Navigation {...props} direction="vertical" />;
}

SidebarNavigation.Items = SidebarNavigationItems;

export type SidebarNavigationItemProps<TProps extends {} = {}> = Omit<NavigationItemProps<TProps>, "icon"> &
  Required<Pick<NavigationItemProps<TProps>, "icon">>;

SidebarNavigation.Item = Navigation.Item;
