import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";

export type ButtonGroupProps = ComponentPropsWithoutRef<"span">;

export function ButtonGroup({ className, ...props }: ButtonGroupProps) {
  const classes = classNames("inline-flex shadow-sm rounded-lg space-x-px", className);

  return <span className={classes} {...props} />;
}

export interface ButtonGroupButtonProps extends ComponentPropsWithoutRef<"button"> {
  active?: boolean;
  disabled?: boolean;
}

export const ButtonGroupButton = forwardRef<HTMLButtonElement, ButtonGroupButtonProps>(function ButtonGroupButton(
  { active, children, disabled, type = "button", className, ...props }: ButtonGroupButtonProps,
  ref,
) {
  const isTextChildren = typeof children === "string";
  const classes = classNames(
    "inline-flex items-center font-medium text-sm py-2 first:rounded-l-lg last:rounded-r-lg border border-transparent focus-visible:border-primary focus-visible:ring-1 focus-visible:ring-primary-focus focus:outline-none transition disabled:cursor-default",
    {
      "bg-base-300 text-gray-700 dark:text-gray-200 disabled:opacity-50": !active,
      "bg-primary text-white": active,
      "hover:bg-gray-50 dark:hover:bg-gray-700": !(active || disabled),
      "px-2": !isTextChildren,
      "px-4": isTextChildren,
    },
    className,
  );

  return (
    <button type={type} className={classes} disabled={active || disabled} ref={ref} {...props}>
      {children}
    </button>
  );
});

ButtonGroup.Button = ButtonGroupButton;
