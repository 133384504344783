import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { Address } from "viem";
import type { IntegrationHandler } from "./types";

export const paraSwapV5: IntegrationHandler<Integrations.ParaSwapV5.TakeOrderArgs> = {
  Description({ args: decodedTakeOrderData }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAssetAddress = decodedTakeOrderData.outgoingAsset;

    let buyAssetAddress: Address;

    switch (decodedTakeOrderData.swapType) {
      case Integrations.ParaSwapV5.SwapType.Simple: {
        buyAssetAddress = decodedTakeOrderData.swapData.incomingAsset;
        break;
      }

      case Integrations.ParaSwapV5.SwapType.Multi: {
        const paths = decodedTakeOrderData.swapData;
        const lastPath = paths[paths.length - 1];
        Assertion.invariant(lastPath !== undefined, "lastPast is undefined");
        buyAssetAddress = lastPath.to;
        break;
      }

      case Integrations.ParaSwapV5.SwapType.Mega: {
        const paths = decodedTakeOrderData.swapData[0]?.path;
        Assertion.invariant(paths !== undefined, "paths is undefined");

        const lastPath = paths[paths.length - 1];
        Assertion.invariant(lastPath !== undefined, "lastPast is undefined");
        buyAssetAddress = lastPath.to;
        break;
      }
    }

    const sellAsset = environment.getAsset(sellAssetAddress);
    const buyAsset = environment.getAsset(buyAssetAddress);
    const title = "ParaSwap V5";
    const exchangeIcon = getIntegrationIconUrl("paraswap.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: decodedTakeOrderData.outgoingAssetAmount,
        }),
      [assetPrices, decodedTakeOrderData, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: decodedTakeOrderData.minIncomingAssetAmount,
        }),
      [assetPrices, buyAsset, decodedTakeOrderData],
    );

    return (
      <TokenSwapTransactionSummary
        currency={currency}
        buyAsset={buyAsset}
        sellAsset={sellAsset}
        minIncomingAssetAmount={decodedTakeOrderData.minIncomingAssetAmount}
        outgoingAssetAmount={decodedTakeOrderData.outgoingAssetAmount}
        buyValueInCurrency={buyValueInCurrency}
        sellValueInCurrency={sellValueInCurrency}
        title={title}
        exchangeIcon={exchangeIcon}
      />
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.ParaSwapV5.takeOrderDecode(encodedCallArgs),
};
