import type { NumberInputProps, NumberInputValues } from "@enzymefinance/ui";
import { NumberInput } from "@enzymefinance/ui";
import { useCallback } from "react";
import { parseUnits } from "viem";

import { defaultDecimals } from "../../constants.js";
import { useFormatBigInt } from "../../hooks/useFormatBigInt.js";

export interface BigIntInputValues extends NumberInputValues {
  bigIntValue?: bigint;
}

export interface BigIntInputProps
  extends Pick<
    NumberInputProps,
    | "className"
    | "cornerHint"
    | "customInput"
    | "description"
    | "disabled"
    | "error"
    | "id"
    | "label"
    | "labelHidden"
    | "maxLength"
    | "numberFormat"
    | "onBlur"
    | "onChange"
    | "onFocus"
    | "onMaxClick"
    | "readOnly"
  > {
  balance?: bigint;
  decimals?: number;
  onValueChange?: (values: BigIntInputValues) => void;
  value?: bigint;
}

export function BigIntInput({
  balance: balanceBase,
  decimals: decimalsBase,
  onValueChange,
  value: valueBase,
  numberFormat,
  ...props
}: BigIntInputProps) {
  const isPercentage = numberFormat?.style === "percent";
  const decimals = decimalsBase ?? (isPercentage ? 16 : undefined) ?? defaultDecimals;

  const { value } = useFormatBigInt(valueBase, decimals);

  const balance = useFormatBigInt(balanceBase, decimals);
  const handleValueChange = useCallback<NonNullable<NumberInputProps["onValueChange"]>>(
    (values) => {
      if (!values.value) {
        return onValueChange?.(values);
      }

      let [integer, fraction] = values.value.split(".");

      if (!integer) {
        integer = "0";
      }

      if (!fraction) {
        fraction = "0";
      }

      const bigIntValue = parseUnits(decimals === 0 ? integer : `${integer}.${fraction.slice(0, decimals)}`, decimals);

      return onValueChange?.({ ...values, bigIntValue });
    },
    [decimals, onValueChange],
  );

  return (
    <NumberInput
      balance={balance.value}
      numberFormat={numberFormat}
      onValueChange={handleValueChange}
      value={value}
      {...props}
    />
  );
}
