import { Checkbox, FormErrorMessage } from "@enzymefinance/hook-form";
import { InlineLink, Modal } from "@enzymefinance/ui";
import type { ReactNode } from "react";

import { Link } from "../../routing/Link";

interface VaultFlexibleLoanAgreementModalProps {
  submitButton: ReactNode;
}

export function VaultFlexibleLoanAgreementModal({ submitButton }: VaultFlexibleLoanAgreementModalProps) {
  return (
    <>
      <Modal.Body>
        <div className="flex flex-col space-y-6">
          <p>This vault uses Flexible loans and requires you to acknowledge its terms and conditions.</p>
          <ul className="list-disc pl-4">
            <li>This vault uses flexible loans which let the manager transfer funds off the vault.</li>
            <li>
              A malicious manager could use this feature to extract funds without your consent. Please proceed to
              deposit only if you fully trust the vault manager.
            </li>
          </ul>
        </div>
        <div className="mt-6">
          <Checkbox
            name="flexLoansAccepted"
            label={
              <>
                I understand & agree that this vault uses{" "}
                <InlineLink as={Link} className="text-info hover:text-info/60 transition" to="https://enzyme.finance">
                  Flexible Loans
                </InlineLink>
                .
              </>
            }
          />
        </div>
        <FormErrorMessage />
      </Modal.Body>
      <Modal.Actions>{submitButton}</Modal.Actions>
    </>
  );
}
