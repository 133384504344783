import { useGlobals } from "components/providers/GlobalsProvider";
import type { FunctionConfig } from "utils/functionsTypes";

export const depositToGasRelayPaymaster: FunctionConfig = {
  signature: "function depositToGasRelayPaymaster() returns ()",

  Description() {
    const {
      environment: {
        namedTokens: { nativeTokenWrapper },
      },
    } = useGlobals();

    return <>Deposit {nativeTokenWrapper.symbol} to Gas Relayer.</>;
  },

  Label() {
    return <>Deposit to Gas Relayer</>;
  },
};
