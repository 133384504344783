import type { Address, Asset } from "@enzymefinance/environment";
import { Network } from "@enzymefinance/environment";

import { maxTick, minTick } from "./constants";

export enum UniswapV3FeeAmount {
  LOWEST = 100,
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
}

const feeAmountToDecimalRatio = 1000000;

export function uniswapV3FeeAmountToDecimal(fee: UniswapV3FeeAmount) {
  return fee / feeAmountToDecimalRatio;
}

export function toUniswapV3FeeAmount(decimalFee: number): UniswapV3FeeAmount {
  const intFee = Math.floor(decimalFee * feeAmountToDecimalRatio);

  const amount = Object.values(UniswapV3FeeAmount).find((value) => value === intFee);

  if (amount === undefined) {
    throw Error(`UniswapV3FeeAmount not found for decimal input: ${decimalFee}`);
  }

  return amount as UniswapV3FeeAmount;
}

export interface PoolInfo {
  feeTier: UniswapV3FeeAmount;
  poolAddress: Address;
  token0: Asset;
  token1: Asset;
}

export interface UniswapV3FeeTierConfig<TFeeAmount extends UniswapV3FeeAmount = UniswapV3FeeAmount> {
  feeAmount: TFeeAmount;
  tickSpacing: number;
  label: string;
  description: string;

  /**
   * lower limit of the tick chart, multiplied by the current price
   */
  chartBoundaryLowMultiplier: number;
  /**
   * upper limit of the tick chart, multiplied by the current price
   */
  chartBoundaryHighMultiplier: number;

  /**
   * the lower price bound will be initialized to the pool's current price multiplied by this ratio
   */
  initialLowerBoundMultiplier: number;
  /**
   * the upper price bound will be initialized to the pool's current price multiplied by this ratio
   */
  initialUpperBoundMultiplier: number;
  /**
   * Minimum usable tick considering the tier's tickSpacing
   */
  minTick: number;
  /**
   * Minimum usable tick considering the tier's tickSpacing
   */
  maxTick: number;
  supportedNetworks: Network[];
}

type FeeConfigRecord<T extends UniswapV3FeeAmount = UniswapV3FeeAmount> = {
  [P in T]: UniswapV3FeeTierConfig<P>;
};

export const FeeTierDefinitions: FeeConfigRecord = {
  [UniswapV3FeeAmount.LOWEST]: {
    chartBoundaryHighMultiplier: 1.1,
    chartBoundaryLowMultiplier: 0.9,
    description: "Best for very stable pairs",
    feeAmount: UniswapV3FeeAmount.LOWEST,
    initialLowerBoundMultiplier: 0.99,
    initialUpperBoundMultiplier: 1.01,
    label: "0.01",
    maxTick,
    minTick,
    supportedNetworks: [Network.ARBITRUM, Network.ETHEREUM, Network.POLYGON],
    tickSpacing: 1,
  },
  [UniswapV3FeeAmount.LOW]: {
    chartBoundaryHighMultiplier: 1.25,
    chartBoundaryLowMultiplier: 0.8,
    description: "Best for stable pairs",
    feeAmount: UniswapV3FeeAmount.LOW,
    initialLowerBoundMultiplier: 0.95,
    initialUpperBoundMultiplier: 1.05,
    label: "0.05",
    maxTick: 887270,
    minTick: -887270,
    supportedNetworks: [Network.ARBITRUM, Network.ETHEREUM, Network.POLYGON],
    tickSpacing: 10,
  },
  [UniswapV3FeeAmount.MEDIUM]: {
    chartBoundaryHighMultiplier: 2,
    chartBoundaryLowMultiplier: 0.5,
    description: "Best for most pairs",
    feeAmount: UniswapV3FeeAmount.MEDIUM,
    initialLowerBoundMultiplier: 0.5,
    initialUpperBoundMultiplier: 1.5,
    label: "0.3",
    maxTick: 887220,
    minTick: -887220,
    supportedNetworks: [Network.ARBITRUM, Network.ETHEREUM, Network.POLYGON],
    tickSpacing: 60,
  },
  [UniswapV3FeeAmount.HIGH]: {
    chartBoundaryHighMultiplier: 5,
    chartBoundaryLowMultiplier: 0.2,
    description: "Best for exotic pairs",
    feeAmount: UniswapV3FeeAmount.HIGH,
    initialLowerBoundMultiplier: 0.5,
    initialUpperBoundMultiplier: 1.5,
    label: "1",
    maxTick: 887200,
    minTick: -887200,
    supportedNetworks: [Network.ARBITRUM, Network.ETHEREUM, Network.POLYGON],
    tickSpacing: 200,
  },
};

export function getFeeTierDefinitions(network: Network): UniswapV3FeeTierConfig[] {
  return Object.values(FeeTierDefinitions).filter((def) => def.supportedNetworks.includes(network));
}

export function getNearestUsableTick(tick: number, feeTier: UniswapV3FeeAmount) {
  const tickSpacing = FeeTierDefinitions[feeTier].tickSpacing;

  const rounded = Math.round(tick / tickSpacing) * tickSpacing;

  if (rounded < minTick) {
    return rounded + tickSpacing;
  }
  if (rounded > maxTick) {
    return rounded - tickSpacing;
  }

  return rounded;
}
