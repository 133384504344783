import { Asset } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useAssetInfo = createUseValidatedClientQuery(["asset-info"], {
  paramsSchema: z.object({
    asset: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.object({ name: z.string(), symbol: z.string(), decimals: bigint() }),
  queryFn: async (params, client) => Asset.getInfo(client, { asset: params.asset }),
});
