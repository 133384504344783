import type { BigIntDisplayProps } from "@enzymefinance/ethereum-ui";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { ExclamationCircleIcon } from "@enzymefinance/icons/solid";
import type { NumberDisplayProps, NumberInputMaxButtonProps, NumberInputProps } from "@enzymefinance/ui";
import {
  FieldGroup,
  Icon,
  NumberDisplay,
  NumberInput,
  NumberInputMaxButton,
  Skeleton,
  Tooltip,
  useFieldGroup,
  useMotionPresets,
} from "@enzymefinance/ui";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ReactElement } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { useLatest } from "react-use";

import { useFormatBigInt } from "../../hooks/useFormatBigInt.js";
import type { TokenInputValue } from "../../types.js";
import type { BigIntInputProps } from "../big-int-input/BigIntInput.js";
import { TokenLabel } from "../token-label/TokenLabel.js";
import type { BaseTokenPickerProps as TokenPickerProps } from "../token-picker/TokenPicker.js";
import { BaseTokenPicker as TokenPicker } from "../token-picker/TokenPicker.js";

interface TokenInputContextValues {
  error?: string[] | boolean | string;
  isError: boolean;
  onChange?: (value: TokenInputValue) => void;
  readOnly?: boolean;
  value?: TokenInputValue;
}

const TokenInputContext = createContext<TokenInputContextValues | undefined>(undefined);

function useTokenInput() {
  const context = useContext(TokenInputContext);

  if (!context) {
    throw new Error("Missing token input context");
  }

  return context;
}

export interface BaseTokenInputProps
  extends Pick<
      BigIntInputProps,
      | "disabled"
      | "error"
      | "id"
      | "label"
      | "labelHidden"
      | "maxLength"
      | "numberFormat"
      | "onBlur"
      | "onFocus"
      | "readOnly"
    >,
    Pick<TokenPickerProps, "kind"> {
  isClearable?: TokenPickerProps["isClearable"];
  loading?: boolean;
  tokens?: TokenPickerProps["options"];
  tokensModalLabel?: string;
}

export function BaseTokenInput({
  id,
  isClearable = false,
  kind,
  loading = false,
  onBlur: onBlurBase,
  onFocus: onFocusBase,
  tokens,
  tokensModalLabel,
  ...props
}: BaseTokenInputProps) {
  const { ariaProps } = useFieldGroup();
  const motionPresets = useMotionPresets();
  const [focus, setFocus] = useState(false);
  const { error, isError, onChange: onChangeBase, value } = useTokenInput();
  const onBlur = useCallback<NonNullable<TokenInputProps["onBlur"]>>(
    (event) => {
      setFocus(false);
      onBlurBase?.(event);
    },
    [onBlurBase],
  );

  const onFocus = useCallback<NonNullable<TokenInputProps["onFocus"]>>(
    (event) => {
      setFocus(true);
      onFocusBase?.(event);
    },
    [onFocusBase],
  );

  const valueRef = useLatest(value);
  const onTokenChange = useCallback<NonNullable<TokenPickerProps["onChange"]>>(
    (token) => {
      if (valueRef.current?.token === token || token === null) {
        return;
      }

      onChangeBase?.({
        token,
        value: props.readOnly ? undefined : valueRef.current?.value,
      });
    },
    [valueRef, onChangeBase, props.readOnly],
  );

  const onValueChange = useCallback<NonNullable<NumberInputProps["onValueChange"]>>(
    (values) => {
      onChangeBase?.({ token: valueRef?.current?.token, value: values.value });
    },
    [onChangeBase, valueRef],
  );

  const classes = classNames("flex items-center space-x-2 bg-base-300 border rounded-lg p-2 transition", {
    "border-error dark:border-error": !focus && isError,
    "border-error dark:border-error ring-error dark:ring-error": focus && isError,
    "border-transparent dark:border-transparent": !(focus || isError),
    "border-primary dark:border-primary ring-primary dark:ring-primary": focus && !isError,
    "ring-1": focus,
  });

  const inputClasses = classNames(
    "block w-full bg-transparent border-none focus:ring-0 text-xl font-semibold py-0 px-1",
    {
      "text-heading-content placeholder-gray-400 dark:placeholder-gray-500": !isError,
      "text-error dark:text-error placeholder-error dark:placeholder-error": isError,
    },
  );

  const { numberFormat } = props;
  return (
    <div className={classes}>
      {loading ? (
        <div className="flex min-w-0 flex-1 items-center">
          <Skeleton className="h-7 w-32" />
        </div>
      ) : (
        <>
          <NumberInput
            className={inputClasses}
            customInput={null}
            id={id}
            onBlur={onBlur}
            onFocus={onFocus}
            onValueChange={onValueChange}
            value={value?.value}
            {...ariaProps}
            {...props}
            numberFormat={{ maximumFractionDigits: value?.token?.decimals, ...numberFormat }}
            label={null}
          />
          <AnimatePresence>
            {isError ? (
              <motion.div {...motionPresets.scale}>
                <Tooltip.Provider>
                  <Tooltip.Item>
                    <Icon icon={ExclamationCircleIcon} className="text-error" aria-hidden={true} />
                  </Tooltip.Item>
                  <Tooltip.Panel>{error}</Tooltip.Panel>
                </Tooltip.Provider>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </>
      )}
      {(tokens?.length ?? 0) > 0 ? (
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        tokens?.length === 1 && "id" in tokens[0]! ? (
          <div className="bg-base-400 text-heading-content inline-flex shrink-0 items-center justify-center rounded-lg border border-transparent px-4 py-2 font-medium">
            <TokenLabel size={5} asset={tokens[0]} hideName={true} />
          </div>
        ) : (
          <TokenPicker
            id={`${id}-picker`}
            kind={kind}
            isClearable={isClearable}
            label={tokensModalLabel ? tokensModalLabel : "Select a token"}
            onChange={onTokenChange}
            onBlur={onBlur}
            options={tokens}
            value={value?.token}
          >
            <TokenPicker.Button aria-labelledby={id} className="bg-base-400 group" appearance="quaternary">
              <span className="text-base-content group-hover:text-heading-content text-sm font-medium transition">
                Select a token
              </span>
            </TokenPicker.Button>
          </TokenPicker>
        )
      ) : null}
    </div>
  );
}

export interface TokenInputProps extends BaseTokenInputProps {
  balance?: bigint;
  localValue?: ReactElement | string;
  onChange?: (value: TokenInputValue) => void;
  value?: TokenInputValue;
  displayErrorLabel?: boolean;
  showMaxButton?: boolean;
}

export function TokenInput({
  balance,
  error,
  labelHidden = false,
  localValue,
  onChange,
  value,
  displayErrorLabel = true,
  showMaxButton,
  ...props
}: TokenInputProps) {
  const isError = Array.isArray(error) ? error.length > 0 : error !== undefined && error !== false;

  return (
    <TokenInputContext.Provider value={{ error, isError, onChange, readOnly: props.readOnly, value }}>
      <FieldGroup
        cornerHint={
          balance === undefined ? null : (
            <TokenInput.Balance
              showMaxButton={showMaxButton}
              numberFormat={{ currency: value?.token?.symbol }}
              value={balance}
              decimals={value?.token?.decimals}
            />
          )
        }
        error={isError}
        description={localValue}
        label={props.label}
        labelHidden={labelHidden}
        id={props.id}
      >
        <BaseTokenInput {...props} />
      </FieldGroup>
      {displayErrorLabel && isError && typeof error !== "boolean" ? (
        <div className="pt-2">
          <p className="text-error text-sm">{error}</p>
        </div>
      ) : null}
    </TokenInputContext.Provider>
  );
}

export interface TokenInputBalanceProps extends BigIntDisplayProps {
  showMaxButton?: boolean;
}

function TokenInputBalance({ showMaxButton = true, ...props }: TokenInputBalanceProps) {
  const { onChange, readOnly, value } = useTokenInput();
  const balance = useFormatBigInt(props.value, props.decimals);
  const handleMaxClick = useCallback<NonNullable<NumberInputMaxButtonProps["onClick"]>>(() => {
    if (value?.token !== undefined) {
      onChange?.({ token: value.token, value: balance.value });
    }
  }, [balance.value, onChange, value?.token]);

  return (
    <span className="inline-flex max-w-full items-center space-x-2">
      <BigIntDisplay
        decimals={props.decimals}
        appearance="simple"
        numberFormat={{ currency: value?.token?.symbol }}
        value={props.value}
      />
      {/* No maxButton if field is readOnly */}
      {showMaxButton && !readOnly ? (
        <NumberInputMaxButton balance={balance.value} onClick={handleMaxClick} value={Number(value?.value ?? 0)} />
      ) : null}
    </span>
  );
}

TokenInput.Balance = TokenInputBalance;

export type TokenInputLocalValueProps = Pick<NumberDisplayProps, "className" | "loading" | "numberFormat" | "value">;

function tokenInputLocalValue({ loading, ...props }: TokenInputLocalValueProps) {
  const { isError } = useTokenInput();
  const wrapperClasses = classNames("flex w-full", {
    "text-base-content": !isError,
    "text-error dark:text-error": isError,
  });

  return (
    <span className={wrapperClasses}>
      <NumberDisplay
        appearance="simple"
        loading={loading === true ? <Skeleton className="h-5 w-16" /> : loading}
        {...props}
      />
    </span>
  );
}

TokenInput.LocalValue = tokenInputLocalValue;
