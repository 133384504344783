import MarkdownBase from "markdown-to-jsx";
import type { PropsWithChildren } from "react";
import { Children } from "react";

function paragraph(props: PropsWithChildren<{}>) {
  // Forces a div on ordered or unordered lists as a descendant of a paragraph tag
  return Children.toArray(props.children).every((child) => typeof child === "string") ? (
    <p {...props} />
  ) : (
    <div {...props} />
  );
}

const options = {
  disableParsingRawHTML: true,
  overrides: {
    a: {
      props: {
        className:
          "text-link hover:text-link/80 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 dark:focus-visible:ring-offset-gray-900 transition",
        rel: "noreferrer",
        target: "_blank",
      },
    },
    p: {
      component: paragraph,
      props: {
        className: "mb-4",
      },
    },
    ul: {
      props: {
        className: "list-disc list-inside",
      },
    },
    ol: {
      props: {
        className: "list-decimal list-inside",
      },
    },
  },
};

export interface MarkdownProps {
  children: string;
}

export function Markdown({ children }: MarkdownProps) {
  return children ? <MarkdownBase options={options}>{children}</MarkdownBase> : null;
}
