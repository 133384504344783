import { EyeIcon, PencilSquareIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ConnectionAvatar } from "components/connection/ConnectionAvatar.js";
import { ErrorBoundary } from "components/error/ErrorBoundary";
import { NotFoundPage } from "components/error/NotFoundPage";
import { Navbar } from "components/layout/Navbar";
import { PageLayout } from "components/layout/PageLayout";
import { CurrencyMenu } from "components/menu/CurrencyMenu";
import { DeploymentMenu } from "components/menu/DeploymentMenu";
import { NavbarMenu } from "components/menu/NavbarMenu";
import { UserMenu } from "components/menu/UserMenu";
import { MobileMenu } from "components/whiteLabel/menu/MobileMenu";
import { type ReactNode, useMemo } from "react";
import { supportEmailAddress } from "utils/constants";
import { useNetworkSearchParam } from "utils/hooks/useNetworkSearchParam";
import { isWithinIframe } from "utils/iframe";

import { Link } from "../../routing/Link";
import { UpdateTheme } from "../UpdateTheme";
import { WhiteLabelBreadcrumbs } from "../WhiteLabelBreadcrumbs";
import { useWhiteLabel } from "../WhiteLabelProvider";
import { WhiteLabelVaultFavicon } from "../WhiteLabelVaultFavicon";
import { WhiteLabelNavigationWrapper } from "./WhiteLabelNavigationWrapper";

interface WhiteLabelLayoutProps {
  appearance?: "default" | "home";
  children?: ReactNode;
}

export function WhiteLabelLayout({ appearance = "default", children }: WhiteLabelLayoutProps) {
  const { id, isOwner, subdomain } = useWhiteLabel();
  const isInIframe = useMemo(() => isWithinIframe(), [isWithinIframe]);

  const publicUrlPathname = `https://${subdomain}.enzyme.community`;
  const publicUrlSearch = useNetworkSearchParam(publicUrlPathname);

  if (!id) {
    return (
      <NotFoundPage
        description="The page you are looking for does not exist. Please review the page URL or send a bug report if you think this should not have happened."
        actions={
          <Button as={Link} leadingIcon={PencilSquareIcon} size="xl" to={`mailto:${supportEmailAddress}`}>
            Report Bug
          </Button>
        }
      />
    );
  }

  return (
    <>
      <WhiteLabelVaultFavicon />
      <div className="bg-base-100 flex min-h-screen min-w-[320px] flex-col">
        <ErrorBoundary>
          <Navbar className="sticky top-0 lg:top-[-124px]">
            <WhiteLabelNavigationWrapper appearance={appearance}>
              <div className="col-span-16 lg:col-span-18 pointer-events-none sticky top-0 z-10 flex max-w-full items-start justify-end">
                <div className="pointer-events-auto lg:sticky lg:top-4">
                  <NavbarMenu>
                    <NavbarMenu.Item hidden={isInIframe}>
                      <ConnectionAvatar kind="compact" />
                    </NavbarMenu.Item>
                    <NavbarMenu.Item hidden={isInIframe || !isOwner}>
                      <DeploymentMenu />
                    </NavbarMenu.Item>
                    <NavbarMenu.Item hidden={isInIframe}>
                      <CurrencyMenu />
                    </NavbarMenu.Item>
                    <NavbarMenu.Item hidden={isInIframe || !isOwner}>
                      <UserMenu />
                    </NavbarMenu.Item>
                  </NavbarMenu>
                </div>
              </div>
            </WhiteLabelNavigationWrapper>
          </Navbar>

          <main className="relative flex w-full flex-1 flex-col items-stretch">
            <div className="mx-auto flex w-full flex-1 flex-col items-stretch sm:px-6 lg:max-w-screen-2xl lg:px-8">
              {/* Mobile menu */}
              <MobileMenu />

              {/* Page Content */}
              <div className="pb-4 lg:relative">
                <LoadingBoundary>
                  <PageLayout header={appearance === "home" ? null : <WhiteLabelBreadcrumbs />}>{children}</PageLayout>
                </LoadingBoundary>
                {isOwner ? (
                  <div className="fixed bottom-4 left-4 flex flex-col gap-2">
                    <UpdateTheme />
                    <Button
                      as={Link}
                      circular={true}
                      leadingIcon={EyeIcon}
                      to={{ pathname: publicUrlPathname, search: publicUrlSearch }}
                    >
                      View Public Page
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </main>
        </ErrorBoundary>
      </div>
    </>
  );
}
