import { toAddress } from "@enzymefinance/environment";
import { TokenSelect, useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { ErrorMessage, RadioGroup } from "@enzymefinance/ui";
import { asset } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { isAddressEqual } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedAssetsForRedemptionPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigContext, VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { PolicyTokenList } from "./PolicyTokenList";

export const allowedAssetsForRedemptionPolicySchema = z
  .object({
    listId: z.string().optional(),
    items: z.array(asset()),
    inKindRedemptionOnly: z.boolean().optional(),
  })
  .refine(
    (value) => {
      return value.inKindRedemptionOnly || value.items.length > 0;
    },
    {
      message: "Please specify some assets or choose in-kind only redemption",
      path: ["items"],
    },
  )
  .optional();

interface AllowedAssetsForRedemptionPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY]?: Partial<AllowedAssetsForRedemptionPolicySettings>;
}

function allowedAssetsForRedemptionPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY>) {
  const { getAssets } = useGlobals();
  const { getFieldState, setValue, trigger, watch } =
    useFormContext<AllowedAssetsForRedemptionPolicyFormFieldsValues>();
  const { error } = getFieldState(VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY);
  const [value] = watch([VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY]) as [
    AllowedAssetsForRedemptionPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY],
  ];
  const inKindRedemptionOnly = !!value?.inKindRedemptionOnly;

  useUpdateEffect(() => {
    trigger();
  }, [value]);

  const defaultOptions = useMemo(
    () => getAssets({ registered: listOptions?.action === "add" || undefined }),
    [getAssets, listOptions?.action],
  );

  // If we add items to the list, we should not display the provided options
  // If we remove items from the list, we should only display the provided options
  const options = useMemo(
    () =>
      listOptions?.action === "add"
        ? defaultOptions.filter(
            (defaultOption) => !listOptions.options.items.find((option) => isAddressEqual(defaultOption.id, option.id)),
          )
        : listOptions?.action === "remove"
          ? listOptions.options.items
          : defaultOptions,
    [defaultOptions, listOptions?.action, listOptions?.options],
  );

  const handleSelectListOption = useCallback(
    ({ value: newValue }: ListSelectOption) => {
      const newFieldValue: AllowedAssetsForRedemptionPolicySettings = {
        listId: undefined,
        items: newValue === VaultConfigPolicyListOption.EMPTY_LIST ? [] : value?.items ?? [],
        inKindRedemptionOnly: newValue === VaultConfigPolicyListOption.EMPTY_LIST,
      };

      setValue(VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY, newFieldValue, { shouldValidate: true });
    },
    [setValue, value?.items],
  );

  const tokenSelectInput = useMemo(
    () => (
      <TokenSelect
        isDisabled={inKindRedemptionOnly}
        isExpandable={true}
        isMulti={true}
        label={allowedAssetsForRedemptionPolicy.label}
        labelHidden={true}
        name={`${VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY}.items`}
        options={options}
      />
    ),
    [inKindRedemptionOnly, options],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () => [
      {
        value: VaultConfigPolicyListOption.CUSTOM_LIST,
        label: allowedAssetsForRedemptionPolicy.label,
        description: inKindRedemptionOnly ? <></> : tokenSelectInput,
      },
      {
        value: VaultConfigPolicyListOption.EMPTY_LIST,
        label: "Only allow in-kind redemption",
        description: <p className="text-sm">This setting can be changed later</p>,
      },
    ],
    [inKindRedemptionOnly, tokenSelectInput],
  );

  const selectedListOption = useMemo(
    () =>
      inKindRedemptionOnly
        ? listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.EMPTY_LIST)
        : listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [inKindRedemptionOnly, listSelectOptions],
  );

  if (listOptions?.action) {
    // If this FormFields component is used from PolicyEditList, render just the component that handles the list items
    return tokenSelectInput;
  }

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label={allowedAssetsForRedemptionPolicy.label}
        labelHidden={true}
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function allowedAssetsForRedemptionPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY>) {
  return <PolicyTokenList addresses={settings.items.map((setting) => setting.id)} />;
}

function allowedAssetsForRedemptionPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY>) {
  const addresses = settings.addressLists.flatMap((list) => list.items);

  return <PolicyTokenList addresses={addresses} />;
}

export const allowedAssetsForRedemptionPolicy: VaultConfig<VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY> = {
  address: (contracts) => contracts.AllowedAssetsForRedemptionPolicy,
  disableable: true,
  display: allowedAssetsForRedemptionPolicyDisplay,
  displaySubgraph: allowedAssetsForRedemptionPolicyDisplaySubgraph,
  editable: false,
  encode: (settings, encodeArgs) => {
    const unique = [...new Set(settings.items.map((item) => toAddress(item.id)))];

    if (
      encodeArgs?.context === VaultConfigContext.RECONFIGURATION ||
      encodeArgs?.context === VaultConfigContext.MIGRATION
    ) {
      const previousSettingsSet = new Set(
        encodeArgs.previousSettings?.items.map((item) => item.id.toLowerCase()) ?? [],
      );

      if (
        encodeArgs.previousSettings?.listId &&
        previousSettingsSet.size === unique.length &&
        unique.every((item) => previousSettingsSet.has(item))
      ) {
        // New and old values contain the same items => reuse the list ID

        return Policies.AllowedAssetsForRedemption.encodeSettings({
          existingListIds: [BigInt(encodeArgs.previousSettings.listId)],
          newListsArgs: [],
        });
      }
    }

    const args = Policies.AllowedAssetsForRedemption.encodeSettings({
      existingListIds: [],
      newListsArgs: [
        {
          initialItems: unique,
          updateType: Constants.AddressListUpdateType.AddAndRemove,
        },
      ],
    });

    return args;
  },
  fetch: async () => undefined,
  formFields: allowedAssetsForRedemptionPolicyFormFields,
  formInitialValues: { inKindRedemptionOnly: false, items: [], listId: "" },
  label: "Restrict Assets For Redemption",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Restricts the assets for which a depositor may redeem their vault shares. If{" "}
        <InlineLink
          to="https://specs.enzyme.finance/topics/policies#allowedassetsforredemptionpolicy"
          appearance="link"
        >
          this policy
        </InlineLink>{" "}
        is not enabled, depositors may redeem their shares in-kind or in any arbitrary combination of assets held by
        your vault. There are two common implementations of this policy to control how your depositors can redeem their
        shares:
      </p>
      <ol>
        <li>
          To limit depositors&apos; redemption options to <strong>in-kind</strong> or <strong>specific-asset</strong>{" "}
          redemptions where you control which assets are available, toggle this policy on and add assets to the list.
          Note that if you use this implementation, your depositors can materially change the composition of your
          portfolio without notice. Implementing an accompanying Single Asset Redemption Threshold (below) can mitigate
          this risk.
        </li>
        <li>
          To limit depositors&apos; redemption options to <strong>in-kind</strong> only, select &apos;Only allow in-kind
          redemption&apos;.
        </li>
      </ol>
    </div>
  ),

  type: VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY,
  validationSchema: allowedAssetsForRedemptionPolicySchema,
};

interface ListSelectOption {
  description: ReactNode;
  label: string;
  value: string;
}
