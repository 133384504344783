import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";
import { createContext, createElement, useContext } from "react";

import { ErrorMessage } from "../feedback/ErrorMessage.js";
import { ScreenReaderText } from "../typography/ScreenReaderText.js";

interface FieldGroupContextValues {
  ariaProps: { "aria-invalid"?: boolean; "aria-describedby"?: string };
  cornerHint?: ReactNode;
  description?: ReactNode;
  error?: string[] | boolean | string;
  isError: boolean;
  label?: ReactNode;
}

const FieldGroupContext = createContext<FieldGroupContextValues | undefined>(undefined);

export function useFieldGroup() {
  const context = useContext(FieldGroupContext);

  if (!context) {
    throw new Error("Missing field group context");
  }

  return context;
}

export interface FieldGroupProps extends Pick<ComponentPropsWithoutRef<"div">, "children" | "className"> {
  cornerHint?: ReactElement | string | null;
  description?: ReactElement | string | null;
  error?: string[] | boolean | string;
  id: string;
  label: ReactElement | string | null;
  labelHidden?: boolean;
  name?: string;
  appearance?: "checkbox" | "default" | "switch";
}

export function FieldGroup({
  children,
  cornerHint = null,
  error,
  description = null,
  id,
  appearance = "default",
  label,
  labelHidden,
  ...props
}: FieldGroupProps) {
  const labelElement =
    label === null ? null : (
      <label htmlFor={id} className="text-base-content block flex-1 text-sm font-medium">
        {labelHidden ? <ScreenReaderText>{label}</ScreenReaderText> : label}
      </label>
    );

  const descriptionClasses = "text-sm text-base-content";
  const descriptionId = `${id}-description`;
  const descriptionElement =
    description === null
      ? null
      : createElement(
          typeof description === "string" ? "p" : "div",
          { className: descriptionClasses, id: descriptionId },
          description,
        );

  const wrapperClasses = classNames({ "space-y-1": !labelHidden || cornerHint });
  const errorId = `${id}-error`;
  const cornerHintId = `${id}-corner-hint`;
  const isError = Array.isArray(error) ? error.length > 0 : error !== undefined && error !== false;
  const isTextError = typeof error === "string";
  const ariaProps = {
    "aria-describedby":
      isTextError && (appearance === "checkbox" || appearance === "switch") && description === null
        ? errorId
        : description !== null
          ? descriptionId
          : cornerHint !== null
            ? cornerHintId
            : undefined,
    "aria-invalid": isError ? true : undefined,
  };

  const groupedElement =
    appearance === "checkbox" || appearance === "switch" ? (
      <div className="relative flex items-start space-x-3">
        <div className="flex h-5 items-center">{children}</div>
        <div className="text-sm">
          {labelElement}
          {descriptionElement}
        </div>
      </div>
    ) : (
      <div className={wrapperClasses}>
        {cornerHint === null ? (
          labelElement
        ) : (
          <div className="flex max-w-full items-end justify-between space-x-2">
            {/* This keeps the corner hint aligned to the right in case we only use that with a label that is `null` */}
            {labelElement ?? <span />}
            <span className="text-base-content inline-flex min-w-0 text-sm" id={cornerHintId}>
              {cornerHint}
            </span>
          </div>
        )}
        {children}
      </div>
    );

  return (
    <FieldGroupContext.Provider value={{ ariaProps, cornerHint, description, error, isError, label }}>
      <div className="flex-1 space-y-2" {...props}>
        {groupedElement}
        {typeof error === "string" ||
        (Array.isArray(error) && error.every((item): item is string => typeof item === "string")) ? (
          <ErrorMessage id={errorId} appearance="simple">
            {error}
          </ErrorMessage>
        ) : appearance === "default" ? (
          descriptionElement
        ) : null}
      </div>
    </FieldGroupContext.Provider>
  );
}
