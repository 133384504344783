import type { Asset } from "@enzymefinance/environment";
import { networks } from "@enzymefinance/environment";
import { sizeEdgeLengths } from "@enzymefinance/utils";
import classNames from "classnames";

import type { BaseIconProps } from "../base-icon/BaseIcon.js";
import { BaseIcon } from "../base-icon/BaseIcon.js";

export interface TokenIconProps extends Omit<BaseIconProps, "id" | "network" | "symbol" | "url"> {
  asset: Asset | undefined;
}

export function TokenIcon({ asset, title, size = 10, className, ...props }: TokenIconProps) {
  const { id, name, symbol } = asset ?? {};

  const classes = classNames("rounded-full overflow-hidden bg-base-300", className);
  const slug = asset?.network !== undefined ? networks[asset.network].slug : undefined;
  const url =
    slug !== undefined && id !== undefined ? `/asset/${slug}/${id}/icon?size=${sizeEdgeLengths[size]}` : undefined;

  return <BaseIcon {...props} className={classes} size={size} title={title ?? name ?? symbol} url={url} />;
}
