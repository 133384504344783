import type { MutationResult } from "@apollo/client";
import { Alert } from "@enzymefinance/ui";
import type { UserErrorFragmentFragment } from "queries/backend";
import { useMemo } from "react";

interface MutationErrorProps<TData = any> {
  resultPath?: keyof TData;
  mutationResult: MutationResult<TData>;
}

export function MutationError<TData = any>({ mutationResult, resultPath }: MutationErrorProps<TData>) {
  const { serverError, userErrors } = useMemo(
    () => ({
      serverError: mutationResult.error ?? undefined,
      userErrors:
        resultPath === undefined
          ? undefined
          : ((mutationResult.data?.[resultPath] as any)?.errors as UserErrorFragmentFragment[]),
    }),
    [mutationResult, resultPath],
  );

  if (!(serverError?.message || userErrors?.length)) {
    return null;
  }

  return (
    <div className="space-y-3">
      {serverError?.message ? (
        <Alert appearance="error" title="Server error">
          {serverError.message}
        </Alert>
      ) : null}
      {userErrors?.map((error, index) => (
        <MutationErrorDisplay error={error} key={index} />
      ))}
    </div>
  );
}

interface MutationErrorDisplayProps {
  error: UserErrorFragmentFragment;
}

export function MutationErrorDisplay({ error }: MutationErrorDisplayProps) {
  switch (error.__typename) {
    case "ArgValidationError":
      return (
        <>
          {error.issues.map((issue, index) => (
            <Alert key={index} appearance="error" title={issue.message} />
          ))}
        </>
      );
    case "GenericUserError":
    case "RateLimitError":
      return <Alert appearance="error" title={error.message} />;
  }
}
