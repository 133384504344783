import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

export type TitleProps = ComponentPropsWithoutRef<"h2">;

export function Title({ children, className, ...props }: TitleProps) {
  const classes = classNames("heading-lg", className);

  return (
    <h2 className={classes} {...props}>
      {children}
    </h2>
  );
}

type TitleHighlightProps = ComponentPropsWithoutRef<"span">;

function titleHighlight({ children, className, ...props }: TitleHighlightProps) {
  const classes = classNames(
    "bg-clip-text text-transparent bg-gradient-to-r inline-block from-primary to-secondary leading-[1.125]",
    className,
  );

  return (
    <span className={classes} {...props}>
      {children}
    </span>
  );
}

Title.Highlight = titleHighlight;
