import type { FieldGroupProps, Option } from "@enzymefinance/ui";
import { FieldGroup } from "@enzymefinance/ui";
import type { GroupBase, Props } from "react-select";
import type { CreatableProps as ReactCreatableProps } from "react-select/creatable";
import ReactCreatable from "react-select/creatable";

import { useProps } from "../../hooks/useProps";
import { CustomPropsProvider } from "../../shared/context";

export interface BaseCreatableProps<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>
  extends Props<TOption, TMulti>,
    ReactCreatableProps<TOption, TMulti, GroupBase<TOption>>,
    Pick<FieldGroupProps, "label"> {
  isExpandable?: boolean;
  itemSize?: number;
}

export function BaseCreatable<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>({
  isSearchable = true,
  ...props
}: BaseCreatableProps<TOption, TMulti>) {
  const { isExpandable, itemSize, label, ...creatableProps } = useProps<"creatable", TOption, TMulti>({
    isSearchable,
    ...props,
  });

  return (
    <CustomPropsProvider value={{ isExpandable, itemSize, label }}>
      <ReactCreatable<TOption, TMulti> {...creatableProps} />
    </CustomPropsProvider>
  );
}

export interface CreatableProps<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>
  extends Omit<FieldGroupProps, "kind">,
    Omit<BaseCreatableProps<TOption, TMulti>, "id"> {}

export function Creatable<TOption extends Record<string, any> = Option, TMulti extends boolean = boolean>({
  cornerHint,
  error,
  description,
  labelHidden = false,
  ...props
}: CreatableProps<TOption, TMulti>) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={props.label}
      labelHidden={labelHidden}
      id={`react-select-${props.id}-input`}
    >
      <BaseCreatable<TOption, TMulti> {...props} />
    </FieldGroup>
  );
}
