import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";

import { Tools } from "@enzymefinance/sdk";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useSharesSplitterTokenBalClaimableForUser = createUseValidatedClientQuery(
  ["shares-splitter-token-bal-claimable-for-user"],
  {
    paramsSchema: z.object({
      userAddress: address({ allowZeroAddress: false }),
      splitterId: address({ allowZeroAddress: false }),
      tokenAddress: address({ allowZeroAddress: false }),
    }),
    responseSchema: bigint(),
    queryFn: (params, client) =>
      Tools.SharesSplitter.getClaimableTokenBalance(client, {
        splitter: params.splitterId,
        token: params.tokenAddress,
        user: params.userAddress,
      }),
  },
);
