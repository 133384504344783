import { useMemo } from "react";
import { getAddress, isAddress } from "viem";

export function useAddressLike(address?: string, checksum = false) {
  return useMemo(() => {
    if (address === undefined) {
      return undefined;
    }
    if (isAddress(address)) {
      const addr = getAddress(address);

      return checksum ? addr : addr.toLowerCase();
    }

    return undefined;
  }, [address, checksum]);
}
