import type { Address, Asset, Environment, Version } from "@enzymefinance/environment";
import type { Adapter, Optional, ReleaseContracts } from "@enzymefinance/utils";
import { invariant, isTruthy, toAdapter } from "@enzymefinance/utils";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext } from "react";
import type { ResolvedExternalPositionDefinition } from "utils/externalPositions";
import { useGetExternalPositionByAddress } from "utils/hooks/useGetExternalPositionByAddress";

import { useNetwork } from "./NetworkProvider";

export interface GlobalsContext {
  environment: Environment<Version.SULU>;
  contracts: ReleaseContracts;
  currentReleaseId: string;
  getAssetsByAddresses: (addresses?: readonly (Address | string | undefined)[]) => Asset[];
  getAssets: Environment<Version.SULU>["getAssets"];
  getAdapterByAddress: (address: Address | string | undefined) => Adapter | undefined;
  getExternalPositionByAddress: (
    address: Address | string | undefined,
  ) => ResolvedExternalPositionDefinition | undefined;
}

const GlobalsContext = createContext<Optional<GlobalsContext>>(undefined);

export function useGlobals() {
  const context = useContext(GlobalsContext);

  if (!context) {
    throw new Error("Missing globals context");
  }

  return context;
}

interface GlobalsProviderProps {
  children?: ReactNode;
}

export function GlobalsProvider({ children }: GlobalsProviderProps) {
  const { environment } = useNetwork();

  const getAssetByAddress = useCallback(
    (addressLike?: Address | string) => {
      if (addressLike === undefined) {
        return;
      }

      return environment.getAsset(addressLike);
    },
    [environment],
  );

  const getAssetsByAddresses = useCallback(
    (addresses?: readonly (Address | string | undefined)[]) =>
      (addresses ?? []).map(getAssetByAddress).filter(isTruthy),
    [getAssetByAddress],
  );

  const getAdapterByAddress = useCallback(
    (address: Address | string | undefined) => {
      if (address && address.toLowerCase() in environment.adapters) {
        const adapter = environment.adapters[address.toLowerCase()];

        invariant(adapter !== undefined, "Adapter is undefined");

        return toAdapter(adapter);
      }

      return undefined;
    },
    [environment.adapters],
  );

  const getAssets = useCallback<GlobalsContext["getAssets"]>(
    (...args) => environment.getAssets(...args),
    [environment],
  );

  const getExternalPositionByAddress = useGetExternalPositionByAddress(environment.contracts);

  return (
    <GlobalsContext.Provider
      value={{
        currentReleaseId: environment.release.address,
        contracts: environment.contracts,
        environment,
        getAdapterByAddress,
        getAssets,
        getExternalPositionByAddress,
        getAssetsByAddresses,
      }}
    >
      {children}
    </GlobalsContext.Provider>
  );
}
