import type { Network } from "@enzymefinance/environment";
import { networks } from "@enzymefinance/environment";
import { Address, AddressWithLabel } from "@enzymefinance/ethereum-ui";
import { ChevronDownIcon, ChevronUpIcon } from "@enzymefinance/icons/solid";
import { CopyToClipboard, Icon } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useToggle } from "react-use";
import { addressToContractLabel } from "utils/contracts";
import { decodeTransactionData } from "utils/functions";

import { Assertion } from "@enzymefinance/sdk/Utils";
import { generateTenderlyParams } from "./TenderlyTransactionLink";
import { TransactionInputDetails } from "./TransactionInputDetails";
import type { TxData } from "./TransactionModalMachine";

interface TransactionDetailsProps {
  comptrollerProxy?: string;
  from?: string;
  network: Network;
  txData: TxData;
  vaultName?: string;
  vaultProxy?: string;
}

export function TransactionDetails({
  comptrollerProxy,
  from,
  network,
  txData,
  vaultName,
  vaultProxy,
}: TransactionDetailsProps) {
  const { contracts } = useGlobals();
  const [showInput, toggleInput] = useToggle(false);

  const to = txData.to;
  const tenderlyParams = generateTenderlyParams({
    from,
    network,
    txData,
  });

  const encodedTenderlyParams = btoa(tenderlyParams);

  const contractLabel = addressToContractLabel({
    address: to,
    comptrollerProxy,
    contracts,
    vaultName,
    vaultProxy,
  });

  const metadata = decodeTransactionData(txData.data);
  Assertion.invariant(metadata?.fragment.type === "function", "fragment is not a function");

  return (
    <div className="text-base-content divide-base-300 space-y-4 divide-y text-sm font-normal">
      <div className="space-y-6">
        <p className="flex items-center justify-between">
          <span>Network</span>
          <span>{networks[network].label}</span>
        </p>
        <p className="flex items-center justify-between">
          <span>Function</span>
          <span className="font-mono">{metadata?.fragment.name}</span>
        </p>
        <p className="flex items-center justify-between">
          <span>Input</span>
          <button
            className="text-primary flex cursor-pointer items-center space-x-1 text-sm focus:outline-none"
            onClick={toggleInput}
            type="button"
          >
            <span>Show input</span> <Icon size={4} icon={showInput ? ChevronUpIcon : ChevronDownIcon} />
          </button>
        </p>
        {showInput ? <TransactionInputDetails inputs={metadata.inputs} /> : null}
        <p className="flex items-center justify-between">
          <span>Debug data</span>
          <span className="flex items-center space-x-2">
            <CopyToClipboard value={encodedTenderlyParams}>
              <CopyToClipboard.Content>Copy Debug Data</CopyToClipboard.Content>
              <CopyToClipboard.Icon title="Copy debug data to clipboard" />
            </CopyToClipboard>
          </span>
        </p>
      </div>
      <div className="space-y-4 pt-4">
        <div className="flex items-center justify-between">
          <span>Contract</span>
          <AddressWithLabel
            size={6}
            description={<Address address={to} copy={true} network={network} trimmed={true} />}
            name={contractLabel}
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="text-base-content text-sm font-normal leading-5">Sender</span>
          <Address address={from} copy={true} icon={true} iconSize={6} network={network} trimmed={true} />
        </div>
      </div>
    </div>
  );
}
