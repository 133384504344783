import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import type { FunctionConfig } from "utils/functionsTypes";
import type { Address } from "viem";

export const setBypassableSharesThreshold: FunctionConfig<{ nextSharesThreshold: Address }> = {
  signature: "function setBypassableSharesThreshold(uint256 nextSharesThreshold)",

  Description({ inputs }) {
    return (
      <div className="flex items-center justify-between pt-2">
        <p>Change bypassable shares threshold to: </p>{" "}
        <BigIntDisplay
          value={BigInt(inputs.nextSharesThreshold)}
          decimals={18}
          numberFormat={{
            currency: "shares",
            minimumSignificantDigits: 1,
            style: "decimal",
          }}
        />
      </div>
    );
  },

  Label() {
    return <>Reconfigure redemption asset</>;
  },
};
