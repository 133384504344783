import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { invariant } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { IntegrationHandler } from "./types";

export const uniswapV3TakeOrder: IntegrationHandler<Integrations.UniswapV3.TakeOrderArgs> = {
  Description({ args: { pathAddresses, outgoingAssetAmount, minIncomingAssetAmount } }) {
    const { environment } = useGlobals();
    const currency = useCurrency();
    const { assetPrices } = useAssetPrices();

    const sellAssetAddress = pathAddresses[0];
    const buyAssetAddress = pathAddresses[pathAddresses.length - 1];

    invariant(sellAssetAddress !== undefined, "sellAssetAddress is undefined");
    invariant(buyAssetAddress !== undefined, "buyAssetAddress is undefined");

    const sellAsset = environment.getAsset(sellAssetAddress);
    const buyAsset = environment.getAsset(buyAssetAddress);

    const title = "Uniswap V3";
    const exchangeIcon = getIntegrationIconUrl("uniswap-v3.svg");

    const sellValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: sellAsset,
          value: BigInt(outgoingAssetAmount.toString()),
        }),
      [assetPrices, outgoingAssetAmount, sellAsset],
    );

    const buyValueInCurrency = useMemo(
      () =>
        findTokenValue({
          assetPrices,
          token: buyAsset,
          value: BigInt(minIncomingAssetAmount.toString()),
        }),
      [assetPrices, buyAsset, minIncomingAssetAmount],
    );

    return (
      <TokenSwapTransactionSummary
        currency={currency}
        buyAsset={buyAsset}
        sellAsset={sellAsset}
        minIncomingAssetAmount={BigInt(minIncomingAssetAmount.toString())}
        outgoingAssetAmount={BigInt(outgoingAssetAmount.toString())}
        buyValueInCurrency={buyValueInCurrency}
        sellValueInCurrency={sellValueInCurrency}
        title={title}
        exchangeIcon={exchangeIcon}
      />
    );
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.UniswapV3.takeOrderDecode(encodedCallArgs),
};
