import type { FunctionConfig } from "utils/functionsTypes";

export const removeNominatedOwner: FunctionConfig = {
  signature: "function removeNominatedOwner()",

  Description() {
    return <>Remove nominated vault owner.</>;
  },

  Label() {
    return <>Remove Nominated Owner</>;
  },
};
