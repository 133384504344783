import type { FixedSize } from "@enzymefinance/utils";
import { sizeAttributes, sizeClasses } from "@enzymefinance/utils";
import classNames from "classnames";
import { useMemo } from "react";

import { Image } from "./Image.js";

export interface AvatarProps {
  className?: string;
  photo?: string;
  displayName?: string;
  circular?: boolean;
  size?: Extract<FixedSize, 6 | 8 | 9 | 10 | 12 | 14 | 16 | 20>;
  notification?: "neutral" | "offline" | "online";
}

export function Avatar({ circular = true, className, displayName = "", notification, photo, size = 8 }: AvatarProps) {
  // Initials
  const initialsClasses = classNames("font-medium leading-none text-white", {
    "text-2xl": size === 20,
    "text-base": size === 10,
    "text-lg": size === 12,
    "text-sm": size === 8 || size === 9,
    "text-xl": size === 14 || size === 16,
    "text-xs": size === 6,
  });
  const initials = useMemo(
    () =>
      displayName
        .match(/(^\S\S?|\b\S)?/g)
        ?.join("")
        .match(/(^\S|\S$)?/g)
        ?.join("")
        .toUpperCase(),
    [displayName],
  );

  const classes = classNames(
    "flex-none",
    sizeClasses[size],
    {
      "inline-block overflow-hidden bg-gray-100 dark:bg-gray-700": photo,
      "inline-flex items-center justify-center bg-gray-400 dark:bg-gray-600": !photo,
      "rounded-full": circular,
      "rounded-lg": !circular,
    },
    className,
  );
  const initialsWrapperClasses = classNames(
    "flex-none inline-flex items-center justify-center bg-gray-400 dark:bg-gray-600",
    sizeClasses[size],
    { "rounded-full": circular, "rounded-lg": !circular },
    className,
  );
  const svgClasses = classNames("h-full w-full text-gray-300 dark:text-gray-500", {
    "rounded-full": circular,
    "rounded-lg": !circular,
  });
  const placeholder = (
    <span className={classes}>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg className={svgClasses} fill="currentColor" viewBox="0 0 24 24" {...sizeAttributes[size]}>
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
  const avatar =
    photo !== undefined ? (
      <Image className={classes} src={photo} alt={displayName} {...sizeAttributes[size]} />
    ) : displayName ? (
      <span className={initialsWrapperClasses}>
        <span className={initialsClasses}>{initials}</span>
      </span>
    ) : (
      placeholder
    );

  // Notification
  const notificationClasses = classNames(
    "absolute bottom-0 right-0 block rounded-full ring-2 ring-white dark:ring-gray-900",
    {
      "bg-gray-300 dark:bg-gray-500": notification === "neutral",
      "bg-green-400 dark:bg-green-600": notification === "online",
      "bg-error dark:bg-error": notification === "offline",
      "h-1.5 w-1.5": size === 6,
      "h-2 w-2": size === 8,
      "h-2.5 w-2.5": size === 10,
      "h-3 w-3": size === 12,
      "h-3.5 w-3.5": size === 14,
      "h-4 w-4": size === 16,
      "translate-y-1/2 translate-x-1/2": !circular,
    },
  );

  return notification ? (
    <span className="relative inline-flex">
      {avatar}
      <span className={notificationClasses} />
    </span>
  ) : (
    avatar
  );
}
