import { type Address, toAddress } from "@enzymefinance/environment";
import { useToasts } from "@enzymefinance/toast";
import { Button, Markdown, Modal } from "@enzymefinance/ui";

import { LoadingScreen } from "components/common/LoadingScreen";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import {
  IsDepositorSignatureRequiredDocument,
  useSubmitDepositorSignatureMutation,
  useVaultProfileQuery,
} from "queries/backend";
import type { VaultDetailsFragment } from "queries/core";
import { useCallback } from "react";
import { client } from "utils/backend";
import { useSignMessage } from "wagmi";
import { log } from "../../../logger/logger";
import { MutationError } from "../../common/MutationError";

interface VaultDepositSignatureFormProps {
  close: () => void;
  signerAddress: Address;
  vault: VaultDetailsFragment;
}

export function VaultDepositSignatureForm({ close, signerAddress, vault }: VaultDepositSignatureFormProps) {
  const { toast } = useToasts();
  const { slug: network } = useNetwork();
  const { signMessageAsync } = useSignMessage();

  const vaultProfileQuery = useVaultProfileQuery({ client, variables: { vaultAddress: toAddress(vault.id) } });
  const [submitDepositorSignature, submitDepositorSignatureResult] = useSubmitDepositorSignatureMutation({
    awaitRefetchQueries: true,
    client,
    refetchQueries: [IsDepositorSignatureRequiredDocument],
  });

  const depositorSignatureMessage = vaultProfileQuery.data?.vaultProfile?.depositorSignatureMessage;

  const handleSignMessage = useCallback(async () => {
    try {
      if (!depositorSignatureMessage) {
        toast.error({
          title: "Missing message",
          message: "Can't sign message. Failed to sign message. The signature was cancelled by the wallet.",
        });
        return;
      }

      const signature = await signMessageAsync({ message: depositorSignatureMessage });

      // Send to backend
      const { data } = await submitDepositorSignature({
        variables: {
          input: {
            network,
            signature: signature ?? "",
            vaultAddress: toAddress(vault.id),
            walletAddress: signerAddress,
          },
        },
      });

      const errors = data?.submitDepositorSignature.errors;

      if (errors) {
        for (const error of errors) {
          toast.error(error);
        }
        close();
      }
      toast("Successfully accepted conditions!", { description: "You can now deposit into this vault." });
    } catch (error) {
      log.error({ category: "VaultDepositSignatureForm", message: error.message });
      toast.error(error);
    }
  }, [depositorSignatureMessage, signerAddress, vault.id]);

  if (vaultProfileQuery.loading || depositorSignatureMessage !== "string") {
    <LoadingScreen />;
  }

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col space-y-6">
          <p>
            This vault requires you to agree to certain conditions to deposit. To enable depositing into this vault,
            please read and agree to the following conditions by signing a message with your wallet.
          </p>
          <div className="rounded-lg bg-base-300 p-4">
            <Markdown>{depositorSignatureMessage ?? ""}</Markdown>
          </div>
          <p>
            Having issues signing? See our{" "}
            <InlineLink to="https://docs.enzyme.finance/managers/vaults-for-organisations/gnosis-safe-ethereum#i-cant-sign-the-special-t-and-cs-with-my-gnosis-safe.-how-do-i-do-it">
              FAQs
            </InlineLink>{" "}
            for help.
          </p>
          <MutationError mutationResult={submitDepositorSignatureResult} />
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button onClick={handleSignMessage}>Accept & Sign</Button>
      </Modal.Actions>
    </>
  );
}
