import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrencySlug } from "components/settings/Settings";
import { useAssetPricesQuery } from "queries/backend";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";
import { client } from "utils/backend";

export interface AssetPrice {
  id: Address;
  price?: number;
  change24h?: number;
}

interface AssetPricesContextValue {
  assetPrices: AssetPrice[];
  loading: boolean;
}

const AssetPricesContext = createContext<AssetPricesContextValue>({
  assetPrices: [],
  loading: false,
});

export function useAssetPrices() {
  return useContext(AssetPricesContext);
}

interface AssetPricesProps {
  children?: ReactNode;
}

export function AssetPricesProvider({ children }: AssetPricesProps) {
  const { deployment } = useNetwork();
  const currency = useCurrencySlug();

  const { loading, refetch, data } = useAssetPricesQuery({
    client,
    variables: { currency, network: deployment },
  });

  // TODO: This shouldn't be necessary (refetch is automatically triggered whenever the variables
  // of the graphql query change.
  useEffect(() => {
    refetch();
  }, [currency, deployment, refetch]);

  const value = useMemo<AssetPricesContextValue>(() => {
    const assetPrices =
      data?.assetPrices.map((assetPrice) => ({
        change24h: assetPrice.change24h ?? undefined,
        id: toAddress(assetPrice.id),
        price: assetPrice.price ?? undefined,
      })) ?? [];

    return {
      assetPrices,
      loading,
    };
  }, [data?.assetPrices, loading]);

  return <AssetPricesContext.Provider value={value}>{children}</AssetPricesContext.Provider>;
}
