import { Helmet } from "react-helmet-async";

import { useWhiteLabel } from "./WhiteLabelProvider";

export function WhiteLabelVaultFavicon() {
  const { logoUrl } = useWhiteLabel();

  if (!logoUrl) {
    return null;
  }

  return (
    <Helmet>
      <link rel="icon" type="image/png" sizes="32x32" href={logoUrl} />
      <link rel="icon" type="image/png" sizes="16x16" href={logoUrl} />
    </Helmet>
  );
}
