import type { ComponentPropsWithoutRef } from "react";

export type BrokenBulbProps = ComponentPropsWithoutRef<"svg">;

export function BrokenBulb(props: BrokenBulbProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width="100" height="104" viewBox="0 0 100 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.8406 32.4021L58.1468 25.9709L45.1789 29.7977L34.4338 22.6658L20.7032 23.1722L22.9512 35.7181L7.25511 35.6477L6.71541 45.1987L30.9792 60.9972L53.571 58.7421L64.534 48.1519L65.8406 32.4021Z"
        fill="#E46C62"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2879 45.1665L28.5872 41.0911L25.9004 41.0046C25.0884 38.5944 25.0293 35.8999 27.6006 34.6185C28.1543 34.3425 28.7098 34.1703 29.2622 34.0855C32.2892 33.6208 35.2338 35.7695 37.4526 37.7056C39.3115 39.3278 41.0624 41.0707 42.7793 42.8412L39.2879 45.1665ZM59.8825 41.6076L54.9791 39.9245C53.27 37.8046 51.5894 35.6625 49.9485 33.4882C48.9219 32.1273 47.8292 30.7484 46.8365 29.3088L58.147 25.9709L59.7051 27.2732L57.6203 30.247L61.1048 36.416L61.2104 36.6419C61.1748 36.9425 61.132 37.2423 61.0818 37.5394L59.9416 41.4682C59.9219 41.5153 59.9022 41.5611 59.8825 41.6076ZM44.1111 41.9538C42.527 40.3157 40.9103 38.7067 39.2221 37.1766C36.4718 34.6831 33.181 32.0478 29.2236 32.5193C29.1421 32.5292 29.0607 32.5404 28.9796 32.5529C27.385 32.7977 25.8356 33.5747 24.8679 34.8904C23.7204 36.4497 23.612 38.4984 24.0422 40.3272C24.0915 40.5362 24.1458 40.7438 24.2049 40.95L21.8867 40.8759L18.6324 43.697L9.70483 39.2242L8.40216 35.6531L22.9513 35.7181L20.7034 23.1722L34.434 22.6659L45.1791 29.7977L45.2701 29.7709C46.8216 32.1284 48.6078 34.3635 50.324 36.5749C50.9476 37.3786 51.5762 38.1777 52.2095 38.9736L49.8181 38.1525L44.1111 41.9538Z"
        fill="#FCA5A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.4092 74.7371C63.8764 64.3917 55.033 53.4262 45.3342 43.2302C43.3581 41.1527 41.3467 39.1029 39.222 37.1765C36.4717 34.6831 33.1809 32.0478 29.2235 32.5193C27.5441 32.7196 25.8852 33.507 24.8678 34.8904C23.7202 36.4497 23.6119 38.4984 24.0421 40.3271C24.8839 43.9089 27.2556 47.1197 30.015 49.4808C35.6134 54.2686 43.5422 55.7933 50.3958 52.9607C57.1677 50.1618 62.3065 43.6579 62.8707 36.2803C63.4161 29.1387 59.9091 21.1416 53.5101 17.5519C50.5601 15.8966 46.1867 15.0686 43.612 17.8512C41.0199 20.6537 42.2998 24.717 43.9596 27.6391C45.756 30.8015 48.0994 33.7075 50.3239 36.5748C52.8303 39.8058 55.4227 42.9687 58.0457 46.1058C63.4238 52.538 68.9468 58.8482 74.3176 65.2866C75.5357 66.7465 76.7448 68.2142 77.9385 69.6938C78.576 70.4839 79.8049 69.4923 79.1651 68.6993C69.5883 56.8307 59.1387 45.6691 49.9484 33.4881C47.9201 30.8002 45.6339 28.0381 44.3732 24.8861C43.797 23.4465 43.3965 21.7409 43.9606 20.2342C44.5411 18.6809 45.9467 17.7956 47.5545 17.6325C51.0534 17.2787 54.3361 19.6073 56.5413 22.0911C58.8106 24.6475 60.3575 27.8246 61.0035 31.1796C62.3484 38.1588 59.4601 45.078 53.7586 49.265C48.0967 53.4229 40.7319 53.916 34.5287 50.6526C31.5118 49.0651 28.8659 46.6317 27.1359 43.6852C25.5683 41.014 23.9859 36.4197 27.6005 34.6185C31.1889 32.8295 34.8293 35.4163 37.4525 37.7056C39.4183 39.4206 41.263 41.2711 43.0752 43.1465C52.2686 52.6636 60.6718 62.9128 69.4935 72.7669C70.3833 73.761 71.2788 74.7504 72.1826 75.7316C72.8678 76.4748 74.0999 75.4871 73.4092 74.7371Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.0961 93.6484C77.091 93.6486 77.0866 93.6486 77.0827 93.6486L77.6294 93.1481C77.4239 93.3407 77.2464 93.5078 77.0972 93.647C77.0967 93.6477 77.096 93.6478 77.0961 93.6484Z"
        fill="#8C8F8B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7919 74.8549L65.768 74.8369C66.168 74.4752 66.5798 74.1034 66.9998 73.7227L65.7919 74.8549Z"
        fill="#E46C62"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8822 41.6076L54.9789 39.9245L59.8822 41.6076ZM59.9413 41.4682L61.0815 37.5394C60.8529 38.8924 60.4687 40.2099 59.9413 41.4682ZM52.2092 38.9736L49.8178 38.1525L52.2092 38.9736ZM61.2101 36.6419L61.1045 36.416L61.2134 36.6088C61.2126 36.6204 61.211 36.6309 61.2101 36.6419Z"
        fill="#E3695B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17879 26.8158C2.1101 39.543 5.23361 53.5183 14.7594 63.8808C25.4119 75.4699 41.2351 79.4521 55.4389 75.5653C58.974 79.2925 63.0262 83.3838 63.0262 83.3838L89.2505 59.2787C89.2505 59.2787 85.5715 55.2752 82.3122 51.7305C88.0592 37.6154 85.5974 20.8388 74.6006 8.87513C72.454 6.54008 70.0921 4.52494 67.5819 2.80917C66.4039 5.01448 64.0986 10.297 64.0986 10.297L67.7532 21.4604L61.1148 25.2623L57.6203 30.247L61.104 36.4142L61.1048 36.416L61.2137 36.6088C61.2137 36.6087 61.2137 36.6085 61.2137 36.6084L61.881 37.7897L60.7166 41.8941L59.8824 41.6077C59.8825 41.6077 59.8825 41.6076 59.8825 41.6076L57.0252 40.6268L50.0821 38.2431L49.8181 38.1525L49.8181 38.1525L44.1111 41.9538L39.2879 45.1665L28.5873 41.0911L28.5872 41.0911L28.4905 41.088L25.9005 41.0046C25.9005 41.0047 25.9005 41.0047 25.9005 41.0048L24.205 40.9504C24.205 40.9502 24.205 40.9501 24.2049 40.95L21.9265 40.8772L21.8867 40.8759L21.8867 40.8759L18.6324 43.697L9.7048 39.2241L5.17879 26.8158Z"
        fill="url(#paint0_linear_5115_46200)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.6618 71.8354C62.9351 65.405 57.3704 58.8226 51.6332 52.399C51.2271 52.5999 50.8151 52.7871 50.3958 52.9607C48.9955 53.5392 47.5507 53.936 46.0896 54.1603C40.3995 55.0339 34.4691 53.2899 30.015 49.4808C27.4167 47.2579 25.1619 44.2802 24.2048 40.95L25.9003 41.0046C26.23 41.9825 26.6834 42.914 27.1359 43.6852C28.8659 46.6317 31.5118 49.0651 34.5287 50.6526C38.0841 52.5229 42.0199 53.1593 45.7996 52.579C47.4179 52.3306 49.0083 51.8584 50.5271 51.1652C48.0862 48.4525 45.6106 45.7719 43.0752 43.1465C42.9765 43.0447 42.8778 42.9429 42.7791 42.8412L44.111 41.9538C44.521 42.3777 44.9281 42.8032 45.3341 43.2302C47.5829 45.5943 49.7864 48.0005 51.9628 50.4299C52.5774 50.0807 53.1764 49.6923 53.7586 49.265C55.2243 48.1891 56.5039 46.9322 57.5736 45.5401C55.7634 43.3694 53.969 41.1853 52.2093 38.9736L54.979 39.9245C56.1412 41.3655 57.3159 42.7963 58.4997 44.2193C59.0321 43.385 59.4947 42.5116 59.8823 41.6076L59.8992 41.6133L59.9414 41.4682C60.4689 40.2098 60.853 38.8924 61.0817 37.5394L61.2914 36.8166L61.2103 36.6419C61.2111 36.6309 61.2127 36.6204 61.2135 36.6088C61.4178 34.8353 61.356 33.0094 61.0035 31.1796C60.7478 29.8511 60.3507 28.5501 59.8229 27.3049L60.8448 25.689C62.418 29.0038 63.1402 32.747 62.8707 36.2803C62.6153 39.619 61.4235 42.779 59.5666 45.4974C65.0493 52.0406 70.7018 58.4453 76.1504 65.0161C75.7657 65.3652 75.3744 65.7204 74.9779 66.0803C74.7584 65.8156 74.5383 65.5511 74.3176 65.2866C69.1348 59.0743 63.812 52.9818 58.6128 46.7828C57.0648 48.7089 55.1677 50.3628 53.0375 51.634C58.6769 57.9743 64.1575 64.4578 69.817 70.7796C69.4259 71.1367 69.041 71.4884 68.6618 71.8354Z"
        fill="#523DCB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.7077 65.1102C90.8029 65.2081 94.0702 70.7987 96.4519 74.1134C97.4294 75.4738 97.7028 77.2388 97.0582 78.7897C96.8229 79.356 96.4646 79.9205 95.9279 80.4202C93.7795 82.4187 84.9663 90.6205 84.9663 90.6205C84.9663 90.6205 82.1018 93.2867 79.3062 91.6882C76.3637 90.0068 69.1498 85.1723 69.1498 85.1723L90.7077 65.1102Z"
        fill="#27303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.249 88.4677L93.9189 75.3196C94.1792 75.0675 94.1831 74.6876 93.9275 74.4708L93.8766 74.4279C93.6209 74.2111 93.2025 74.2398 92.9422 74.4919L79.2723 87.64C79.0119 87.8922 79.0082 88.2725 79.2638 88.4893L79.3147 88.5322C79.5703 88.749 79.9888 88.7203 80.249 88.4677Z"
        fill="#9BA5C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.925 89.5499C68.6518 89.7454 67.1357 89.5769 66.1864 88.7031L58.5521 81.2496L69.6372 71.0756C70.7822 70.0368 71.9798 68.949 73.2122 67.8264C79.4846 62.1151 84.8019 57.2192 86.8078 55.3167L87.441 54.7353C87.6374 54.9492 91.8498 59.6929 93.9844 62.5115C94.4162 63.0815 94.6393 64.4885 94.7437 65.1771C94.8293 65.7389 94.8021 66.3193 94.6557 66.8841L94.1772 68.7278L91.9431 70.7784C92.3629 70.2712 92.7671 69.7499 93.1543 69.2094L72.9805 87.6601C72.9951 87.6598 73.0115 87.6585 73.0296 87.6557C73.2456 87.6226 73.6958 87.3925 74.1968 87.0652L71.844 89.2247L69.9923 89.5389C69.97 89.543 69.9475 89.5465 69.925 89.5499Z"
        fill="#374151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5974 89.7125L94.5958 69.6836C94.919 69.3895 94.9425 68.8891 94.6475 68.5663L94.5895 68.5024C94.2952 68.1795 93.7944 68.1563 93.4712 68.451L71.4728 88.4793C71.1496 88.774 71.1261 89.2738 71.4204 89.5967L71.479 89.6605C71.7733 89.9834 72.2742 90.0066 72.5974 89.7125Z"
        fill="#9BA5C6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5115_46200"
          x1="54.7249"
          y1="51.4801"
          x2="1.78072"
          y2="56.9717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F56FD" />
          <stop offset="1" stopColor="#00CABE" />
          <stop offset="1" stopColor="#00CABE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
