import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { useToggle, useUpdateEffect, useWindowSize } from "react-use";

interface MobileMenuContextValues {
  isOpen: boolean;
  toggle: (open?: boolean) => void;
}

const MobileMenuContext = createContext<MobileMenuContextValues>({
  isOpen: false,
  toggle: () => {},
});

export function useMobileMenu() {
  return useContext(MobileMenuContext);
}

interface MobileMenuProviderProps {
  children?: ReactNode;
}

export function MobileMenuProvider(props: MobileMenuProviderProps) {
  const [isOpen, toggle] = useToggle(false);
  const { width, height } = useWindowSize();

  useUpdateEffect(() => {
    // Auto close when when window orientation changes.
    // Also prevents from keeping open state when switching to desktop menu.
    if (isOpen) {
      toggle(false);
    }
  }, [width, height]);

  return <MobileMenuContext.Provider {...props} value={{ isOpen, toggle }} />;
}
