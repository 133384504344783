import { XMarkIcon } from "@enzymefinance/icons/solid";
import classNames from "classnames";
import type { HTMLMotionProps } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";

import { useMotionPresets } from "../../hooks/useMotionPresets.js";
import type { ButtonProps } from "../elements/Button.js";
import { Button } from "../elements/Button.js";

export interface NotificationProps extends Pick<HTMLMotionProps<"div">, "className" | "onMouseEnter" | "onMouseLeave"> {
  actions?: ReactElement | null;
  dismiss?: ButtonProps["onClick"];
  icon?: ReactElement;
  message: ReactElement | string;
  description?: ReactElement | string;
  children?: ReactNode;
}

export function Notification({
  actions,
  children,
  className,
  description,
  dismiss,
  icon,
  message,
  ...props
}: NotificationProps) {
  const motionPresets = useMotionPresets();
  const classes = classNames(
    "max-w-sm flex flex-col bg-base-200 border border-gray-300/25 dark:border-gray-700/25 shadow-lg rounded-lg pointer-events-auto overflow-hidden",
    className,
  );
  const isTextDescription = typeof description === "string";
  const isTextMessage = typeof message === "string";

  return (
    <motion.div {...motionPresets.notification} className={classes} {...props}>
      <div className="flex flex-1 flex-col p-4">
        <div className="flex flex-1 space-x-3">
          <AnimatePresence>{icon ? <div className="flex-none">{icon}</div> : null}</AnimatePresence>
          <div className="flex w-0 flex-1 flex-col">
            <div className="space-y-1">
              {isTextMessage ? <p className="title-sm">{message}</p> : message}
              {description === undefined ? null : isTextDescription ? (
                <p className="text-sm">{description}</p>
              ) : (
                description
              )}
            </div>
            {children}
            {actions ? <Notification.Actions>{actions}</Notification.Actions> : null}
          </div>
          {dismiss ? (
            <div className="flex-none">
              <Button className="-mr-1 -mt-1" icon={XMarkIcon} size="xs" appearance="quaternary" onClick={dismiss}>
                Close
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
}

export type NotificationActionsProps = ComponentPropsWithoutRef<"div">;

function notificationActions(props: NotificationActionsProps) {
  return <div className="mt-3 space-x-3" {...props} />;
}

Notification.Actions = notificationActions;

export type NotificationButtonProps = Omit<ButtonProps, "size">;

function notificationButton(props: NotificationButtonProps) {
  return <Button size="xs" {...props} />;
}

Notification.Button = notificationButton;
