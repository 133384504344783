import { Address } from "@enzymefinance/ethereum-ui";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { AddressCreatable, BigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Fee, Fees } from "@enzymefinance/sdk/Configuration";
import { Alert, Badge, NumberDisplay, StackedList, Tooltip } from "@enzymefinance/ui";
import { isNonEmptyString } from "@enzymefinance/utils";
import { address } from "@enzymefinance/validation";
import { bigint } from "@enzymefinance/validation";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import { AnimatePresence, motion } from "framer-motion";
import { PerformanceFeeType } from "queries/core";
import { useEffect, useMemo } from "react";
import { supportEmailAddress } from "utils/constants";
import { parseUnits } from "viem";
import { z } from "zod";
import { VaultConfigFieldName } from "../VaultConfig";
import type { PerformanceFeeSettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

const oneDayInSeconds = 24 * 60 * 60;
const rateThreshold = parseUnits("0.5", 4);

export const performanceFeeSchema = z.object({
  rate: bigint()
    // Higher than 0%
    .refine((value?: PerformanceFeeSettings["rate"]) => typeof value === "bigint" && value > 0n, {
      message: "The rate must be greater than 0%.",
    })

    // Less than 100%
    .refine((value?: PerformanceFeeSettings["rate"]) => typeof value === "bigint" && value < parseUnits("1", 4), {
      message: "The rate must be less than 100%.",
    }),
  recipient: address({ caseInsensitive: true }).optional(),
});

interface PerformanceFeeFormFieldsValues {
  [VaultConfigFieldName.PERFORMANCE_FEE]?: Partial<PerformanceFeeSettings>;
}

function performanceFeeFormFields() {
  const { getFieldState, setValue, watch } = useFormContext<PerformanceFeeFormFieldsValues>();
  const fieldName = VaultConfigFieldName.PERFORMANCE_FEE;
  const { error } = getFieldState(fieldName);
  const [value] = watch([fieldName]) as [PerformanceFeeFormFieldsValues[VaultConfigFieldName.PERFORMANCE_FEE]];
  const isAboveThreshold = useMemo(() => typeof value?.rate === "bigint" && value?.rate > rateThreshold, [value?.rate]);

  // This effect is required to have the underlying components update when DefaultFieldValues is triggered in the FeesStep
  useEffect(() => {
    if (value?.rate !== undefined) {
      setValue("performanceFee.rate", value.rate as never);
    }

    if (value?.recipient !== undefined) {
      setValue("performanceFee.recipient", value.recipient as never);
    }
  }, [value]);

  return (
    <div className="space-y-3">
      <div className="flex flex-col space-y-3">
        <div className="flex space-x-3">
          <BigIntInput
            decimals={2}
            label="Performance Fee Rate"
            name={`${fieldName}.rate`}
            numberFormat={{ style: "percent" }}
          />
        </div>
        <div>
          <AddressCreatable
            label="Recipient Address (optional)"
            name={`${fieldName}.recipient`}
            cornerHint="By default, the fee recipient is the vault owner"
          />
        </div>
      </div>

      <AnimatePresence>
        {isAboveThreshold && error === undefined ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <Alert appearance="warning">
              This fee setting is considerably higher than average and may deter potential depositors. Consider setting
              a lower fee.
            </Alert>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function performanceFeeDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.PERFORMANCE_FEE>) {
  const { network } = useNetwork();

  return (
    <div className="flex flex-col space-y-3">
      <div>
        <BigIntDisplay value={settings.rate ?? undefined} numberFormat={{ style: "percent" }} decimals={4} />
      </div>
      {settings.recipient ? (
        <div>
          Recipient: <Address address={settings.recipient} network={network} icon={true} iconSize={5} />
        </div>
      ) : null}
    </div>
  );
}

function performanceFeeDisplaySubgraph({
  settings,
  denominationAsset,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.PERFORMANCE_FEE>) {
  const highWaterMark = isNonEmptyString(settings.highWaterMark) ? Number(settings.highWaterMark) : Number.NaN;

  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Rate">
        <NumberDisplay value={Number(settings.rate)} numberFormat={{ style: "percent" }} />
      </StackedList.ItemDataBox>

      {settings.performanceFeeType === PerformanceFeeType.CRYSTALLIZATION_PERIOD ? (
        <StackedList.ItemDataBox label="Crystallization Period">
          <NumberDisplay value={settings.period / oneDayInSeconds} numberFormat={{ unit: "day" }} />
        </StackedList.ItemDataBox>
      ) : null}
      {Number.isNaN(highWaterMark) ? null : (
        <StackedList.ItemDataBox label="High watermark">
          <div className="flex space-x-1">
            <NumberDisplay value={highWaterMark} numberFormat={{ currency: denominationAsset?.symbol }} />
            <Tooltip>This is the share price under which performance fees cannot be claimed.</Tooltip>
          </div>
        </StackedList.ItemDataBox>
      )}
    </StackedList.ItemDataBoxList>
  );
}

export const performanceFee: VaultConfig<VaultConfigType.PERFORMANCE_FEE> = {
  address: (contracts) => contracts.PerformanceFee,
  disableable: false,
  display: performanceFeeDisplay,
  displaySubgraph: performanceFeeDisplaySubgraph,
  editable: false,
  encode: (settings) => {
    return Fees.Performance.encodePerformanceFeeSettings({
      rateInBps: settings.rate ?? 0n,
      recipient: settings.recipient ?? undefined,
    });
  },
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    try {
      const [rate, recipient] = await Promise.all([
        Fees.Performance.getInfo(client, { performanceFee: vaultConfigAddress, comptrollerProxy: comptroller }),
        Fee.getRecipient(client, { fee: vaultConfigAddress, comptrollerProxy: comptroller }),
      ]);

      return { rate: rate.rate, recipient };
    } catch {
      return undefined;
    }
  },
  formFields: performanceFeeFormFields,
  label: "Performance Fee",
  managerDescription: (
    <div className="space-y-4">
      <p>
        If enabled, measured based on the vault&apos;s performance. The performance fee is subject to a high-water mark.
        The fee recipient is the vault manager by default, or any other wallet. If you wish to split fee amounts among
        several wallets, please contact our sales team at{" "}
        <InlineLink appearance="tertiary" to={`mailto:${supportEmailAddress}`}>
          {supportEmailAddress}
        </InlineLink>
        . For an in-depth description of how this fee is calculated, please refer to the{" "}
        <InlineLink to="https://docs.enzyme.finance/managers/setup/fees#performance-fees" appearance="link">
          User Documentation
        </InlineLink>
        .
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.PERFORMANCE_FEE,
  validationSchema: performanceFeeSchema,
};
