import { HomeIcon } from "@enzymefinance/icons/solid";
import { Button } from "@enzymefinance/ui";
import { NotFound } from "components/images/NotFound";
import { Link } from "components/routing/Link";
import { usePageTitle } from "utils/hooks/usePageTitle";

import type { FeedbackPageProps } from "../feedback/FeedbackPage";
import { FeedbackPage } from "../feedback/FeedbackPage";

export type NotFoundPageProps = FeedbackPageProps;

export function NotFoundPage({
  title = "Oh no! Page not found.",
  description = (
    <>
      <p>The page you are looking for does not exist.</p>
      <p>Proceed with one of the actions below.</p>
    </>
  ),
  actions = (
    <Button as={Link} leadingIcon={HomeIcon} size="xl" to="/">
      Take me Home
    </Button>
  ),
}: NotFoundPageProps) {
  usePageTitle("Page Not Found");

  return <FeedbackPage actions={actions} description={description} image={NotFound} title={title} />;
}
