import type { FunctionConfig } from "utils/functionsTypes";

export const redemptionQueueRedeemFromQueue: FunctionConfig<{}> = {
  signature: "function redeemFromQueue(uint256 endId, uint256[] calldata idsToBypass)",

  Description() {
    return <>Execute edemption requests from redemption queue.</>;
  },

  Label() {
    return <>Execute Redemption Requests</>;
  },
};
