import { Button, ErrorMessage as ErrorMessageBase, useMotionPresets } from "@enzymefinance/ui";
import { ErrorMessage } from "@hookform/error-message";
import { AnimatePresence, motion } from "framer-motion";
import type { ComponentPropsWithoutRef } from "react";
import type { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { useFormState } from "react-hook-form";

import type { FormFieldValue } from "../../types";

export function FormErrorMessage() {
  const { errors, isSubmitting, isValidating } = useFormState<FormFieldValue>({ name: "form" });
  const type: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | string | undefined = errors.form?.type ?? "";
  const motionPresets = useMotionPresets();

  return (
    <AnimatePresence>
      {isSubmitting || isValidating ? null : (
        <div className="mt-4">
          <ErrorMessage
            errors={errors}
            name="form"
            render={({ message }) => (
              <motion.div {...motionPresets.default}>
                <ErrorMessageBase
                  reportAction={
                    typeof type === "string" && type.length > 0 && type === "report" ? (
                      <Button<ComponentPropsWithoutRef<"a">>
                        as="a"
                        size="xs"
                        href="mailto:"
                        target="_blank"
                        rel="noreferrer"
                        className="ml-4"
                      >
                        Send report
                      </Button>
                    ) : null
                  }
                >
                  {message}
                </ErrorMessageBase>
              </motion.div>
            )}
          />
        </div>
      )}
    </AnimatePresence>
  );
}
