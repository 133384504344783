import { useTitle } from "components/providers/TitleProvider";
import { useTitle as useTitleBase } from "react-use";

export function usePageTitle(pageTitle?: string[] | string) {
  const { title } = useTitle();
  const pageName = Array.isArray(pageTitle) ? pageTitle.join(" — ") : pageTitle;
  const fullTitle = pageName ? [pageName, title].join(" | ") : title;

  useTitleBase(fullTitle);
}
