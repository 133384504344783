import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";
import { isAddressEqual } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const arbitraryLoanConfigureLoan: ExternalPositionHandler<Integrations.ArbitraryLoan.ConfigureLoanArgs> = {
  Description({ args: { borrower, asset: loanAsset, amount, accountingModule, description } }) {
    const { environment, contracts } = useGlobals();
    const asset = environment.getAsset(loanAsset);

    const accountingModuleType = isAddressEqual(accountingModule, contracts.ArbitraryLoanTotalNominalDeltaOracleModule)
      ? "Total nominal delta oracle"
      : "None";

    return (
      <span className="flex flex-col space-y-2">
        <span>Loan config</span>
        <span>Borrower: {borrower}</span>
        <span>Loan asset: {asset.symbol}</span>
        <span>
          Borrowable amount:{" "}
          <BigIntDisplay value={amount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />
        </span>
        {description === "" ? null : <span>Description: {description}</span>}
        <span>Accounting module type: {accountingModuleType}</span>
      </span>
    );
  },
  Label() {
    return <>Configure Loan</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.ArbitraryLoan.configureLoanDecode(encodedCallArgs),
};

export const arbitraryLoanUpdateBorrowableAmount: ExternalPositionHandler<Integrations.ArbitraryLoan.UpdateBorrowableAmountArgs> =
  {
    Description() {
      return null;
    },
    Label() {
      return <>Update Borrowable Amount</>;
    },
    decodeExternalPositionArgs: (encodedCallArgs) =>
      Integrations.ArbitraryLoan.updateBorrowableAmountDecode(encodedCallArgs),
  };

export const arbitraryLoanReconcile: ExternalPositionHandler<Integrations.ArbitraryLoan.ReconcileArgs> = {
  Description({ args: { extraAssetsToSweep } }) {
    const { environment } = useGlobals();

    const assets = useMemo(() => {
      return extraAssetsToSweep.map((assetAddress) => environment.getAsset(assetAddress));
    }, [environment, extraAssetsToSweep]);

    return (
      <span className="flex flex-col space-y-2">
        <span>You will reconcile loan asset </span>
        {assets.length > 0 ? (
          <>
            <span>and extra assets:</span>
            {assets.map((asset) => (
              <span key={asset.id}>{asset.symbol}</span>
            ))}
          </>
        ) : null}
      </span>
    );
  },
  Label() {
    return <>Reconcile</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.ArbitraryLoan.reconcileDecode(encodedCallArgs),
};

export const arbitraryLoanCloseLoan: ExternalPositionHandler<Integrations.ArbitraryLoan.CloseLoanArgs> = {
  Description({ args: { extraAssetsToSweep } }) {
    const { environment } = useGlobals();

    const assets = useMemo(() => {
      return extraAssetsToSweep.map((assetAddress) => environment.getAsset(assetAddress));
    }, [environment, extraAssetsToSweep]);

    return (
      <span className="flex flex-col space-y-2">
        <span>You will reconcile loan asset </span>
        {assets.length > 0 ? (
          <>
            <span>and extra assets:</span>
            {assets.map((asset) => (
              <span key={asset.id}>{asset.symbol}</span>
            ))}
          </>
        ) : null}
      </span>
    );
  },
  Label() {
    return <>Close Loan</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.ArbitraryLoan.closeLoanDecode(encodedCallArgs),
};

export const createArbitraryLoanExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Flexible Loan external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = arbitraryLoanConfigureLoan.decodeExternalPositionArgs(actionArgs);

    return <arbitraryLoanConfigureLoan.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Flexible Loan external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = arbitraryLoanConfigureLoan.decodeExternalPositionArgs(actionArgs);

    return <arbitraryLoanConfigureLoan.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Flexible Loan external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = arbitraryLoanConfigureLoan.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(
      arbitraryLoanConfigureLoan.Label,
      arbitraryLoanConfigureLoan.Description,
    );

    return <Summary args={args} />;
  },
};
